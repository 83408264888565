import axios from 'axios/index';
import moment from 'moment';
import { notification } from 'antd';
import to from 'await-to-js';

import store from '../store/store.js';
import { SWITCH_LANG, SYNC_DPN_MODE } from '../actions/types';

let deviceId = null;

export const X86_ARCH = 'x86_64';

export const EXTRACT_DOMAIN_REGEX = /^(?:https?:\/\/)?([^/:.]+(\.[^/:.]+)+)(?:\/|$)/i;
export const EXTRACT_IP_REGEX = /^(?:https?:\/\/)?((?:[0-9]{1,3}\.){3}[0-9]{1,3})/i;

/**
 * @param {number} delay ms
 */
export const sleep = delay => {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
};

export const getDeviceId = async function () {
  if (deviceId) {
    return deviceId;
  }

  deviceId = await new Promise(resolve => {
    axios
      .get('/api/admin/getDeviceId')
      .then(res => {
        resolve(res.data && res.data.deviceId);
      })
      .catch(err => {
        console.error('Error getting device ID: ' + err);
        resolve(null);
      });
  });

  return deviceId;
};

export const getMyCountry = async function () {
  const [err, res] = await to(axios.get('/api/admin/getMyCountry'));
  if (err) {
    return null;
  } else {
    return res.data?.myCountry;
  }
};

function isDprSeries(sn) {
  return sn.includes('-DPR-') || sn.includes('-DPRA-') || sn.includes('-DPRB-') || sn.includes('-DPRC-');
}

export const isDprCn = async () => {
  const deviceId = await getDeviceId();
  if (deviceId && !isDprSeries(deviceId)) {
    // SN available and non-DPR series
    return false;
  }

  // SN unavailable or DPR series, check country
  const country = await getMyCountry();
  if (country && country !== 'CN') {
    // country available and non-CN country
    return false;
  }

  return true;
};

export const isDprb = async () => {
  const deviceId = await getDeviceId();
  if (!deviceId) {
    // SN unavailable, treat as DPRB
    return true;
  }

  return deviceId.includes('-DPRB-');
};

export const isAdsc = async () => {
  const deviceId = await getDeviceId();
  if (!deviceId) {
    // SN unavailable, treat as ADSC
    return true;
  }

  return deviceId.includes('-ADSC-');
};

export const isDfs = async () => {
  const deviceId = await getDeviceId();
  if (!deviceId) {
    // SN unavailable, treat as DFS
    return true;
  }

  return deviceId.includes('-DFS-');
};

/**
 * check if is clouda system
 */
export const isClouda = async () => {
  const deviceId = await getDeviceId();
  if (!deviceId) {
    // SN unavailable, treat as CLOUDA
    return true;
  }

  return deviceId.includes('-CLOUDA-');
};

export const download = (target, name, content) => {
  if (!target) {
    return;
  }
  let blob = new Blob([content]);
  let href = window.URL.createObjectURL(blob);
  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(blob, name);
    } catch (e) {
      console.error(e);
    }
  } else {
    let downloadElement = document.createElement('a');
    downloadElement.href = href;
    downloadElement.target = '_blank';
    downloadElement.download = name;
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
    window.URL.revokeObjectURL(href);
  }
};

export const saveResAsFile = (res, fallbackFilename, target) => {
  const contentDisposition = res.headers['content-disposition'] || '';
  const match = contentDisposition.match(/filename="(.+?)"/);
  const filename = match?.[1] || fallbackFilename;

  download(target, filename, res.data);
};

/**
 * @param {string} originStr - 包含`ts:[timestamp]:ts`格式的字符串
 * @returns {string} - 格式化之后的字符串
 */
export const formatTimestampStr = originStr => {
  const timestampReg = /ts:\d{13}:ts/g;
  const timestampArr = originStr.match(timestampReg);

  if (timestampArr) {
    const [timestampStr] = timestampArr;
    const timestamp = parseInt(timestampStr.replace(/\D/g, ''));
    const formatedTime = moment(timestamp).format('HH:mm:ss');

    return originStr.replace(timestampReg, formatedTime);
  }

  return originStr;
};

/**
 * 检测用户platform
 */
export const checkPlatform = () => {
  if (window) {
    const ua = navigator.userAgent.toLowerCase();
    // ios13及之后的userAgent被修改，需要换一种方式判断
    const platform = navigator.platform;
    const maxTouchPoints = navigator.maxTouchPoints;

    // 检测是否为移动端
    const isMobile = /mobile/.test(ua);
    const isIPhone = isMobile && ua.indexOf('iphone') > -1;
    const isIPad = ua.indexOf('ipad') > -1 || (platform === 'MacIntel' && maxTouchPoints > 1);

    // 检测系统是mac还是windows or linux or android
    const isMacOS = ua.indexOf('mac os') > -1 && maxTouchPoints < 1;
    const isWindows = ua.indexOf('windows') > -1;
    const isLinux = ua.indexOf('linux') > -1;
    const isAndroid = ua.indexOf('android') > -1;
    return {
      isMobile: isIPhone || isIPad,
      isMacOS,
      isWindows,
      isLinux,
      isAndroid,
    };
  } else {
    console.error('make sure the window is loaded');
  }
};

export const checkWifiEnable = async () => {
  const [err, res] = await to(axios.get('/api/wifi/enable', {}));
  if (err) {
    console.error(`/api/wifi/enable request failed`);
    return false;
  }
  const { data } = res;
  const { success, enable } = data;
  if (success) {
    return enable;
  }
};

export const APP_LANG_KEY = 'appLang';

export const setAppLang = langVal => {
  store.dispatch({
    type: SWITCH_LANG,
    payload: {
      langValue: langVal,
    },
  });
  localStorage.setItem(APP_LANG_KEY, langVal);
};

export const setDPNMode = mode => {
  store.dispatch({
    type: SYNC_DPN_MODE,
    payload: {
      dpnMode: mode,
    },
  });
};

export const fetchHardwareInfo = async () => {
  const [err, res] = await to(axios.get('/api/system-info/hardware-info'));
  if (err) {
    console.error('/api/system-info/hardware-info request failed');
    return;
  }
  return res.data;
};

/**
 * global notification
 * @param {React.ReactNode} message
 * @param {React.ReactNode} description
 */
export const globalNotification = (message, description) => {
  notification.open({
    message,
    description,
    duration: null,
  });
};

export const parseIntWithin = (int, min, max) => {
  int = parseInt(int);

  if (isNaN(int) || int < min || int > max) {
    return null;
  }

  return int;
};

export const extractFromUrl = (url, regex) => {
  let match = url.match(regex);
  if (match && match[1] && match[1].length > 0) {
    return match[1];
  } else {
    return null;
  }
};

export const DEFAULT_AVATAR = 'defaultAvatar';
export const SELECTED_AVATAR_KEY = 'selectedAvatar';

export const copyToClipboard = async str => {
  if (navigator.clipboard) {
    const [err] = await to(navigator.clipboard.writeText(str));
    return !err;
  } else {
    /* fallback */
    const textArea = document.createElement('textarea');
    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';
    textArea.value = str;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    const success = document.execCommand('copy');
    document.body.removeChild(textArea);
    return success;
  }
};

/* Also in common-js/util.js */
export const compareVerStr = async (a, b) => {
  const aParts = validateVerStr(a);
  const bParts = validateVerStr(b);

  for (let i = 0; i < 3; i++) {
    if (aParts[i] !== bParts[i]) {
      return aParts[i] - bParts[i];
    }
  }

  // same major, minor and revision, compare build
  if (aParts[3] === 'rel' && bParts[3] === 'rel') {
    // both release build, a = b
    return 0;
  } else if (aParts[3] === 'rel') {
    // a is release build and b is NOT, a > b
    return 1;
  } else if (bParts[3] === 'rel') {
    // b is release build and a is NOT, a < b
    return -1;
  }
  // both non-release build
  return compareNonRelBuild(aParts[3], bParts[3]);
};

function validateVerStr(verStr) {
  const parts = verStr.split('.');
  if (parts.length !== 4) {
    throw new Error(`Invalid version string ${verStr}`);
  }

  const convertedParts = [];

  for (let i = 0; i < 3; i++) {
    if (isNaN(parts[i])) {
      throw new Error(`Invalid version string ${verStr}`);
    }

    convertedParts.push(parseInt(parts[i]));
  }

  convertedParts.push(parts[3]);
  return convertedParts;
}

function compareNonRelBuild(aBuild, bBuild) {
  // first compare the leading character
  if (aBuild.charAt(0) !== bBuild.charAt(0)) {
    return aBuild.charAt(0) < bBuild.charAt(0) ? -1 : 1;
  }

  aBuild = aBuild.substring(1);
  bBuild = bBuild.substring(1);

  // then compare int values if applicable
  if (!isNaN(aBuild) && !isNaN(bBuild)) {
    return parseInt(aBuild) - parseInt(bBuild);
  }

  // at least one build is not a number
  return aBuild < bBuild ? -1 : 1;
}
