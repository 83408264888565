import { languageType } from '../utils/languageType';
export default {
  type: 'russian',
  internationalize: {
    type: 'russian',
    common: {
      'tip_text': 'Совет',
      'search': 'Поиск',
      'add': 'Добавить',
      'delete': 'Удалить',
      'confirm': 'Подтвердить',
      'cancel': 'Отмена',
      'warning': 'Предупреждение',
      'utcDisclaimer': 'Даты указаны в формате UTC',
      'toOpen': 'открыть',
    },
    certificateDialog: {
      title: 'Новый корневой сертификат CA',
      content: 'Корневой сертификат CA был заменен. Пожалуйста, скачайте и установите его заново.',
      dontShowAgain: 'Больше не показывать',
    },
    request_tip: {
      'error': 'Ошибка запроса~',
    },
    header: {
      logout: 'Выход',

      changePassword: 'Изменить пароль',
      userManual: 'Руководство пользователя',
      defaultAvatar: 'Заголовок по умолчанию',
      ...languageType(),
    },
    home: {
      'name': 'Панель приборов',
      'children': {},
      'content': {
        'tipInput': 'Введите',
        'ok': 'Подтвердить',
        'cancel': 'Отмена',
        'currentStatus': 'Текущий статус',
        'statusMap': {
          'Trial': 'Пробная версия',
          'Valid': 'Действительная',
          'Expired': 'Истекла',
        },
        'btnActive': 'Активировать',
        'btnEdit': 'Изменить',
        'expireDate': 'Дата истечения срока',
        'licenseKey': 'Номер лицензии',
      },
    },
    accessControl: {
      name: 'Контроль доступа',
    },
    tpProxy: {
      'name': 'Прозрачный прокси',
      'children': {
        'customRules': {
          'name': 'Пользовательские правила',
        },
        'builtinRules': {
          'name': 'Фильтрация рекламы',
        },
        'certificate': {
          'name': 'Установка сертификата',
        },
      },
    },
    help: {
      'name': 'Помощь',
      'children': {
        'techSupport': {
          'name': 'Техническая поддержка',
        },
      },
    },
    device: {
      'name': 'Связанное с устройством',
      'children': {
        'system': {
          'name': 'Информация о системе',
        },
        'traffic': {
          'name': 'Трафик',
        },
        'notification': {
          'name': 'Журнал',
        },
      },
      'content': {},
    },
    dpn: {
      'name': 'DPN',
      'content': {},
      'children': {
        'smartRoute': {
          'name': 'Режим',
        },
        'tunnel': {
          'name': 'Туннель',
        },
        'routingTable': {
          'name': 'Умная таблица маршрутизации',
          'content': {},
          'children': {
            'domainConfig': {
              'name': 'Настройка домена',
            },
            'ipConfig': {
              'name': 'Настройка IP',
            },
            'queryRouting': {
              'name': 'Запрос',
            },
          },
        },
      },
    },
    'appRelocator': {
      'name': 'Перемещение приложений',
    },
    'adsBlock': {
      'name': 'Блокировка рекламы',
      'children': {
        'domainFilter': {
          'name': 'Фильтр DNS',
        },
        'httpsFilter': {
          'name': 'Фильтр HTTPS',
        },
      },
      httpssucceed: 'Фильтрация HTTPS успешно включена',
      httpslose: 'Фильтрация HTTPS успешно отключена',
      saverror: 'Операция не удалась',
      usedByAccessControl: 'Фильтр HTTPS используется контролем доступа',
    },
    'dpr': {
      'name': 'Майнинг',
      'children': {
        'dpr': {
          'name': 'DPR',
          'children': {
            'stakingTutorial': {
              'name': 'Учебник по стейкингу',
            },
            'wallet': {
              'name': 'Кошелек',
              'manager': 'Управление кошельком',
              'balance': 'Баланс кошелька',
            },
            'Staking Mining': 'Стейкинг-майнинг',
            'transaction': {
              'id': 'ID транзакции',
              'from': 'Отправитель',
              'to': 'Получатель',
              'time': 'Время перевода (UTC)',
              'rewardTime': 'Время получения награды (UTC)',
              'amount': 'Количество DPR',
              'Reward in selected period': 'Награда за выбранный период',
              'Total reward': 'Общая награда',
              'Balance Transfer': 'Обычная транзакция',
              'Staking Reward': 'Награда за ставку',
              'PoS Reward': 'Награда PoS',
              'Micro Payment': 'Микро-платеж',
              'stakeRelease': 'Транзакция линейного выпуска стейкинга',
              'Transaction': 'История транзакций',
              'Mining': 'Майнинг',
              'Traffic Mining': 'Майнинг трафика',
              'Staking Mining': 'Стейкинг-майнинг',
            },
          },
        },
      },
    },
    'security': {
      'name': 'Безопасность',
      'children': {
        'applications': 'Управление приложениями',
        'traffic': 'Управление трафиком',
        'webfilter': {
          'name': 'Фильтр веб-страниц',
          'children': {
            'report': {
              'name': 'Стандартная конфигурация',
            },
            'config': {
              'name': 'Фильтр DNS-рекламы',
            },
          },
        },
        'sslFilter': {
          'name': 'Фильтр SSL',
          'children': {
            'customDNSrules': {
              'name': 'Пользовательские правила DNS',
            },
            'customHTTPSRules': {
              'name': 'Пользовательские правила HTTPS',
            },
          },
        },
      },
      'content': {},
    },
    'parentalControl': {
      'name': 'Родительский контроль',
    },
    regionCountryTexts: {
      'LL': 'не проходить тунель',
      'ANY': 'любая страна или регион',
      'AMER': '---Америка---',
      'ASIA': '---Азия---',
      'AFRI': '---Африка---',
      'EURO': '---Европа---',
      'OCEA': '---Океания---',
      'AMN': 'Северная Америка',
      'AMC': 'Карибский бассейн',
      'AMM': 'Центральная Америка',
      'AMS': 'Южная Америка',
      'ASC': 'Центральная Азия',
      'ASE': 'Восточная Азия',
      'ASW': 'Западная Азия',
      'ASS': 'Южная Азия',
      'ASD': 'Юго-Восточная Азия',
      'AFN': 'Северная Африка',
      'AFM': 'Центральная Африка',
      'AFE': 'Восточная Африка',
      'AFW': 'Западная Африка',
      'AFS': 'Южная Африка',
      'EUN': 'Северная Европа',
      'EUE': 'Восточная Европа',
      'EUW': 'Западная Европа',
      'EUS': 'Южная Европа',
      'OCP': 'Полинезия',
      'OCA': 'Австралия и Новая Зеландия',
      'OCM': 'Меланезия',
      'OCN': 'Микронезия',
      'AD': 'Андорра',
      'AE': 'Объединенные Арабские Эмираты',
      'AF': 'Афганистан',
      'AG': 'Антигуа и Барбуда',
      'AI': 'Ангилья',
      'AL': 'Албания',
      'AM': 'Армения',
      'AO': 'Ангола',
      'AR': 'Аргентина',
      'AS': 'Американское Самоа',
      'AT': 'Австрия',
      'AU': 'Австралия',
      'AW': 'Аруба',
      'AX': 'Аландские острова',
      'AZ': 'Азербайджан',
      'BA': 'Босния и Герцеговина',
      'BB': 'Барбадос',
      'BD': 'Бангладеш',
      'BE': 'Бельгия',
      'BF': 'Буркина-Фасо',
      'BG': 'Болгария',
      'BH': 'Бахрейн',
      'BI': 'Бурунди',
      'BJ': 'Бенин',
      'BL': 'Сен-Бартелеми',
      'BM': 'Бермуды',
      'BN': 'Бруней',
      'BO': 'Боливия',
      'BQ': 'Карибская Нидерландия',
      'BR': 'Бразилия',
      'BS': 'Багамы',
      'BT': 'Бутан',
      'BW': 'Ботсвана',
      'BY': 'Беларусь',
      'BZ': 'Белиз',
      'CA': 'Канада',
      'CC': 'Кокосовые (Килинг) острова',
      'CD': 'Конго (Киншаса)',
      'CF': 'Центрально-Африканская Республика',
      'CG': 'Конго (Браззавиль)',
      'CH': 'Швейцария',
      'CI': "Кот-д'Ивуар",
      'CK': 'Острова Кука',
      'CL': 'Чили',
      'CM': 'Камерун',
      'CN': 'Китай',
      'CO': 'Колумбия',
      'CR': 'Коста-Рика',
      'CU': 'Куба',
      'CV': 'Кабо-Верде',
      'CW': 'Кюрасао',
      'CX': 'Остров Рождества',
      'CY': 'Кипр',
      'CZ': 'Чехия',
      'DE': 'Германия',
      'DJ': 'Джибути',
      'DK': 'Дания',
      'DM': 'Доминика',
      'DO': 'Доминиканская Республика',
      'DZ': 'Алжир',
      'EC': 'Эквадор',
      'EE': 'Эстония',
      'EG': 'Египет',
      'ER': 'Эритрея',
      'ES': 'Испания',
      'ET': 'Эфиопия',
      'FI': 'Финляндия',
      'FJ': 'Фиджи',
      'FK': 'Фолклендские острова',
      'FM': 'Федеративные Штаты Микронезии',
      'FO': 'Фарерские острова',
      'FR': 'Франция',
      'GA': 'Габон',
      'GB': 'Великобритания',
      'GD': 'Гренада',
      'GE': 'Грузия',
      'GF': 'Французская Гвиана',
      'GG': 'Гернси',
      'GH': 'Гана',
      'GI': 'Гибралтар',
      'GL': 'Гренландия',
      'GM': 'Гамбия',
      'GN': 'Гвинея',
      'GP': 'Гваделупа',
      'GQ': 'Экваториальная Гвинея',
      'GR': 'Греция',
      'GS': 'Южная Джорджия и Южные Сандвичевы острова',
      'GT': 'Гватемала',
      'GU': 'Гуам',
      'GW': 'Гвинея-Бисау',
      'GY': 'Гайана',
      'HK': 'Гонконг (Китай)',
      'HN': 'Гондурас',
      'HR': 'Хорватия',
      'HT': 'Гаити',
      'HU': 'Венгрия',
      'ID': 'Индонезия',
      'IE': 'Ирландия',
      'IL': 'Израиль',
      'IM': 'Остров Мэн',
      'IN': 'Индия',
      'IO': 'Британская территория в Индийском океане',
      'IQ': 'Ирак',
      'IR': 'Иран',
      'IS': 'Исландия',
      'IT': 'Италия',
      'JE': 'Джерси',
      'JM': 'Ямайка',
      'JO': 'Иордания',
      'JP': 'Япония',
      'KE': 'Кения',
      'KG': 'Киргизия',
      'KH': 'Камбоджа',
      'KI': 'Кирибати',
      'KM': 'Коморы',
      'KN': 'Сент-Китс и Невис',
      'KR': 'Южная Корея',
      'KW': 'Кувейт',
      'KY': 'Каймановы острова',
      'KZ': 'Казахстан',
      'KP': 'Северная Корея',
      'LA': 'Лаос',
      'LB': 'Ливан',
      'LC': 'Сент-Люсия',
      'LI': 'Лихтенштейн',
      'LK': 'Шри-Ланка',
      'LR': 'Либерия',
      'LS': 'Лесото',
      'LT': 'Литва',
      'LU': 'Люксембург',
      'LV': 'Латвия',
      'LY': 'Ливия',
      'MA': 'Марокко',
      'MC': 'Монако',
      'MD': 'Молдавия',
      'ME': 'Черногория',
      'MF': 'Сен-Мартен (фр.)',
      'MG': 'Мадагаскар',
      'MH': 'Маршалловы острова',
      'MK': 'Северная Македония',
      'ML': 'Мали',
      'MM': 'Мьянма (Бирма)',
      'MN': 'Монголия',
      'MO': 'Макао (Китай)',
      'MP': 'Северные Марианские острова',
      'MQ': 'Мартиника',
      'MR': 'Мавритания',
      'MS': 'Монтсеррат',
      'MT': 'Мальта',
      'MU': 'Маврикий',
      'MV': 'Мальдивы',
      'MW': 'Малави',
      'MX': 'Мексика',
      'MY': 'Малайзия',
      'MZ': 'Мозамбик',
      'NA': 'Намибия',
      'NC': 'Новая Каледония',
      'NE': 'Нигер',
      'NF': 'Остров Норфолк',
      'NG': 'Нигерия',
      'NI': 'Никарагуа',
      'NL': 'Нидерланды',
      'NO': 'Норвегия',
      'NP': 'Непал',
      'NR': 'Науру',
      'NU': 'Ниуэ',
      'NZ': 'Новая Зеландия',
      'OM': 'Оман',
      'PA': 'Панама',
      'PE': 'Перу',
      'PF': 'Французская Полинезия',
      'PG': 'Папуа — Новая Гвинея',
      'PH': 'Филиппины',
      'PK': 'Пакистан',
      'PL': 'Польша',
      'PM': 'Сен-Пьер и Микелон',
      'PN': 'Острова Питкэрн',
      'PR': 'Пуэрто-Рико',
      'PS': 'Палестинские территории',
      'PT': 'Португалия',
      'PW': 'Палау',
      'PY': 'Парагвай',
      'QA': 'Катар',
      'RE': 'Реюньон',
      'RO': 'Румыния',
      'RS': 'Сербия',
      'RU': 'Россия',
      'RW': 'Руанда',
      'SA': 'Саудовская Аравия',
      'SB': 'Соломоновы острова',
      'SC': 'Сейшельские Острова',
      'SD': 'Судан',
      'SE': 'Швеция',
      'SG': 'Сингапур',
      'SH': 'Острова Святой Елены, Вознесения и Тристан-да-Кунья',
      'SI': 'Словения',
      'SJ': 'Шпицберген и Ян-Майен',
      'SK': 'Словакия',
      'SL': 'Сьерра-Леоне',
      'SM': 'Сан-Марино',
      'SN': 'Сенегал',
      'SO': 'Сомали',
      'SR': 'Суринам',
      'SS': 'Южный Судан',
      'ST': 'Сан-Томе и Принсипи',
      'SV': 'Сальвадор',
      'SX': 'Синт-Мартен',
      'SY': 'Сирия',
      'SZ': 'Эсватини',
      'TC': 'Теркс и Кайкос',
      'TD': 'Чад',
      'TF': 'Французские Южные и Антарктические Территории',
      'TG': 'Того',
      'TH': 'Таиланд',
      'TJ': 'Таджикистан',
      'TK': 'Токелау',
      'TL': 'Восточный Тимор',
      'TM': 'Туркменистан',
      'TN': 'Тунис',
      'TO': 'Тонга',
      'TR': 'Турция',
      'TT': 'Тринидад и Тобаго',
      'TV': 'Тувалу',
      'TW': 'Тайвань',
      'TZ': 'Танзания',
      'UA': 'Украина',
      'UB': 'Запад США',
      'UC': 'Средний Запад США',
      'UD': 'Юго-Запад США',
      'UE': 'Северо-Восток США',
      'UF': 'Юго-Восток США',
      'UG': 'Уганда',
      'US': 'Соединенные Штаты',
      'UY': 'Уругвай',
      'UZ': 'Узбекистан',
      'VA': 'Ватикан',
      'VC': 'Сент-Винсент и Гренадины',
      'VE': 'Венесуэла',
      'VG': 'Британские Виргинские острова',
      'VI': 'Американские Виргинские острова',
      'VN': 'Вьетнам',
      'VU': 'Вануату',
      'WF': 'Уоллис и Футуна',
      'WS': 'Самоа',
      'XK': 'Косово',
      'YE': 'Йемен',
      'YT': 'Майотта',
      'ZA': 'Южная Африка',
      'ZM': 'Замбия',
      'ZW': 'Зимбабве',
    },
    setting: {
      'name': 'Настройки',
      'children': {
        'wifi': {
          'name': 'Wi-Fi связь',
        },
        'sharing': {
          'name': 'Общий доступ',
          'children': {
            'configure': {
              'name': 'Настройки общего доступа',
            },
            'blacklist': {
              'name': 'Делимся черным списком',
            },
            'record': {
              'name': 'Журнал общего доступа',
            },
            'traffic': {
              'name': 'Трафик общего доступа',
            },
          },
          'content': {},
        },
        'rustdesk': {
          'name': 'РустДеск',
          'children': {
            'config': {
              'name': 'Настройки',
            },
            'log': {
              'name': 'Журнал',
            },
          },
        },
        'admin': {
          'name': 'Управление аккаунтом',
          'content': {},
          'children': {
            'changePassword': {
              'name': 'Пароль',
            },
            'reboot': {
              'name': 'Перезагрузка',
              'content': {
                'confirmText': 'Подтвердить перезагрузку?',
                'rebootLoading':
                  'Устройство будет перезагружено, это может занять несколько минут, ваше подключение к сети будет временно прервано...',
              },
            },
          },
        },
      },
    },
  },
  // dashboard
  dashboard: {
    'name': 'Главная страница',
    'children': {},
    'content': {
      'backupTunnelLabel': 'Резервный туннель',
      'tipInput': 'Пожалуйста, введите',
      'hotspot': 'Глобальное подключение',
      'realnetworkflow': 'Статистика реального трафика',
      'memory': 'Использование памяти',
      'cpu': 'Использование ЦП',
      'notification': 'Системные уведомления',
      'systeminfo': 'Информация о системе',
      'upload': 'Загрузка',
      'download': 'Скачивание',
      'systemuptime': 'Время работы системы',
      'uploadtotal': 'Общий объем загрузки',
      'downloadtotal': 'Общий объем скачивания',
      'softwareVersion': 'Версия программного обеспечения',
      'publicIp': 'Публичный IP',
      'unit': 'Единица',
      'currentDpnMode': 'Текущий режим DPN',
      'ok': 'ОК',
      'cancel': 'Отмена',
      'currentStatus': 'Текущий статус',
      'statusMap': {
        'Trial': 'Пробный',
        'Valid': 'Действительный',
        'Expired': 'Истекший',
      },
      'btnActive': 'Активировать',
      'btnEdit': 'Изменить',
      'expireDate': 'Дата истечения срока',
      'licenseKey': 'Ключ лицензии',
      'systemTraffic': 'Статистика трафика системы в реальном времени',
      'appTraffic': 'Статистика трафика приложений в реальном времени',
    },
  },
  accessControl: {
    'content': {
      banner: {
        title: 'Контроль доступа',
        description:
          'Этот переключатель используется для единого управления включением или отключением различных функций устройства локальной сети.',
      },
      'onlineDevice': 'Онлайн-устройство',
      'offlineDevice': 'Офлайн-устройство',
      'deviceName': 'Имя устройства',
      unknownDevice: 'Неизвестный',
      'macAddress': 'MAC-адрес',
      'ipAddress': 'IP-адрес',
      'enableHttpsFilter': 'Включить фильтр HTTPS',
      'routeMode': 'Режим DPN',
      'routeModeOptions': {
        'smart': 'Режим умного маршрутизации',
        'full': 'Полная маршрутизация',
        'disabled': 'Отключено',
        blackHole: 'Черная дыра',
      },
      'remark': 'Примечание',
      pinned: 'Fixar no topo',
      'httpsFilterTip': 'После включения фильтрации HTTPS все рекламные материалы будут отфильтрованы',
      'routeModeTip': 'Этот параметр переключает режим работы DPN',
      'tunnelTip': 'Выберите туннель полной маршрутизации',
      'success': {
        getList: 'Список устройств успешно обновлен',
        'httpsFilter': 'Фильтр HTTPS успешно включен',
        'routeMode': 'Режим DPN успешно изменен',
        'regionCode': 'Режим DPN успешно изменен',
        'remark': 'Примечание успешно сохранено',
        pinned: 'Успешно закреплено / откреплено. Пожалуйста, обновите страницу',
        deleteDevices: 'Удаление устройства выполнено успешно',
      },
      'failure': {
        'getSwitch': 'Не удалось получить переключатель контроля доступа',
        'setSwitch': 'Не удалось настроить переключатель контроля доступа',
        'getList': 'Не удалось получить список контроля доступа',
        'getTunnels': 'Не удалось получить туннели',
        'setItem': 'Не удалось установить элемент контроля доступа',
        'httpsFilter': 'Не удалось включить фильтр HTTPS',
        'routeMode': 'Не удалось переключить режим DPN',
        'regionCode': 'Не удалось переключить режим DPN',
        'remark': 'Не удалось сохранить примечание',
        'noSuchTunnel': 'Туннель не существует, выберите другой туннель.',
        pinned: 'Не удалось закрепить / открепить',
        deleteDevices: 'Не удалось удалить устройство',
        macActive: 'Не удалось удалить, поскольку устройство активно.',
      },
      'remarkLengthLimit': 'Длина не более 10 символов',
      'httpsNotEnabled':
        'Основной выключатель фильтрации HTTPS выключен, пожалуйста, нажмите, чтобы перейти на эту страницу и включить его!',
      'tips': {
        'opened':
          'Когда контроль доступа включен, онлайн-устройства преимущественно используют режим DPN для контроля доступа.',
      },
    },
  },
  //dpnMode
  dpnMode: {
    'title': 'Выбор режима DPN',
    'selectTunnel': 'Выберите туннель',
    'recommended': 'Рекомендуется',
    'apply': 'Применить',
    'missingInfo': 'Отсутствуют данные',
    'selectionTip': {
      'smart': 'Вы уверены, что хотите изменить режим на интеллектуальную маршрутизацию?',
      'full':
        'Весь трафик будет проходить через выбранный туннель. Вы уверены, что хотите изменить режим на полную маршрутизацию?',
      'disabled': 'Этот режим временно отключает сеть DPN. Вы уверены, что хотите изменить режим на отключенный DPN?',
    },
    'dpnModeShortList': {
      'smart': 'Интеллектуальная маршрутизация DPN',
      'disabled': 'Отключенный DPN',
    },
    'dpnModeLongList': {
      'smart': 'Интеллектуальная маршрутизация',
      'full': 'Полная маршрутизация',
      'disabled': 'Отключен',
    },
    'killSwitchLabel': 'Защита от отключения',
    'none': 'Выключено',
    'backupTunnelLabel': 'Резервный туннель',
    'backupTunnelTip':
      'Когда туннель, настроенный в режиме интеллектуальной маршрутизации, недоступен, система автоматически переключается на резервный туннель, пока основной туннель не восстановится.',
    'tips': {
      'killSwitch':
        'Функция защиты от отключения VPN означает, что при разрыве VPN-соединения она автоматически прерывает соединение вашего компьютера, телефона или планшета с Интернетом. Это означает, что если ваш VPN перестает работать, ваш IP-адрес и другая конфиденциальная информация не будут раскрыты. Таким образом, безопасность и анонимность вашего интернет-соединения не пострадают.',
      'smart': [
        '1. На основе встроенных правил AtomOS, интеллектуально выбирается узел для доступа к текущему веб-сайту, обеспечивая быстродействие локального доступа к сети и ускорение доступа к услугам в других регионах и странах.',
        '2. В режиме интеллектуальной маршрутизации вы можете избежать необходимости постоянно переключаться между странами, как это требуется при использовании традиционного VPN, и получить более быстрый доступ в Интернет.',
        '3. Deeper Connect имеет встроенные правила маршрутизации для некоторых известных сайтов и сервисов. Если вы хотите настроить собственные правила для интеллектуальной маршрутизации, вы можете сделать это в разделе "Перенаправление приложений" и "DPN-Таблица маршрутизации".',
        '4. Мы также будем добавлять новые встроенные правила маршрутизации и поддержку большего количества приложений в разделе "Перенаправление приложений" на основе отзывов пользователей.',
      ],
      'full': [
        '1. Весь сетевой трафик, обрабатываемый AtomOS, будет использовать указанный туннель, что аналогично традиционному использованию VPN.',
        '2. Обратите внимание: режим полной маршрутизации перезапишет ваши настройки в разделах "Перенаправление приложений" и "DPN-Таблица маршрутизации".',
        '3. Из-за децентрализованной природы сети, узлы в сети представлены устройствами пользователей со всего мира, а не высокоскоростными серверами. Это может повлиять на скорость вашего интернет-соединения.',
        '4. Если вы хотите использовать режим полной маршрутизации и желаете получить высокую скорость интернета, мы рассмотрим возможность предоставления определенных быстрых узлов для VIP-пользователей в будущем.',
      ],
      'disabled': [
        '1. "Отключенный DPN" отключает функцию DPN, и весь сетевой трафик, обрабатываемый AtomOS, будет использовать локальный трафик.',
        '2. Остальные функции безопасности сети Deeper Connect останутся активными.',
        '3. Вы можете отключить DPN для проверки, является ли проблема с доступом к веб-сайту проблемой самого сайта или проблемой функции DPN.',
        '4. Если у вас возникли проблемы с функцией DPN, пожалуйста, сообщите о них, следуя этой ссылке: https://support.deeper.network/hc/en-us/requests/new',
      ],
    },
    'success': 'Успешное действие',
    'failure': 'Не удалось выполнить действие',
  },
  // DefaultadBlock
  DefaultadBlock: {
    'browserCacheWarning':
      'Если при включенной фильтрации рекламы DNS не удается правильно фильтровать рекламу, попробуйте очистить кэш браузера и перезагрузить устройство, на котором применяется фильтрация.',
    'queriesFor24Hours': 'Запросы за последние 24 часа',
    'totalQuery': 'Общее количество запросов',
    'blockedQuery': 'Заблокированные запросы',
    'blockedPercentage': 'Процент заблокированных',
    'ruleTotal': 'Правила',
    'adQuery': 'Заблокированные рекламные запросы',
    'trackerQuery': 'Заблокированные запросы отслеживания',
    'maliciousQuery': 'Заблокированные вредоносные запросы',
    'pornQuery': 'Заблокированные запросы для взрослых',
    'legendTotal': 'Всего',
    'legendBlocked': 'Заблокировано',
    'categoryStateEnabled': 'Включено',
    'categoryStateDisabled': 'Отключено',
    'categoryState2Hours': 'Отключено на 2 часа',
    'categoryState4Hours': 'Отключено на 4 часа',
    'categoryState8Hours': 'Отключено на 8 часов',
    'setCategoryStatesErr': 'Ошибка при изменении состояния фильтрации',
    'enableAll': 'Включить все фильтры',
    'disableAll': 'Отключить все фильтры',
  },
  // CustomadBlock
  CustomadBlock: {
    'missingInfoWarning': 'Отсутствует доменное имя',
    'invalidDomainError': 'Неверный формат доменного имени или слишком длинное имя',
    'whitelist': 'Белый список',
    'blacklist': 'Черный список',
    'domain': 'Доменное имя',
    'whitelistAddSuccess': 'Успешно добавлено в белый список',
    'whitelistEntryExist': 'Элемент уже существует в белом списке',
    'reachedWhiteMax': 'Достигнуто максимальное количество элементов в белом списке',
    'whitelistAddError': 'Не удалось добавить элемент в белый список',
    'blacklistAddSuccess': 'Успешно добавлено в черный список',
    'blacklistEntryExist': 'Элемент уже существует в черном списке',
    'reachedBlackMax': 'Достигнуто максимальное количество элементов в черном списке',
    'blacklistAddError': 'Не удалось добавить элемент в черный список',
    'whitelistDelSuccess': 'Элемент успешно удален из белого списка',
    'whitelistDelError': 'Не удалось удалить элемент из белого списка',
    'blacklistDelSuccess': 'Элемент успешно удален из черного списка',
    'blacklistDelError': 'Не удалось удалить элемент из черного списка',
    'import': 'Импорт',
    'fileButtonText': 'Нажмите, чтобы загрузить',
    'fileSizeOverLimit': 'Размер загружаемого файла превышает максимальный размер 2 МБ',
    'whitelistImportSuccess': 'Белый список успешно импортирован. Импортировано {0} элементов',
    'whitelistImportError': 'Не удалось импортировать белый список',
    'blacklistImportSuccess': 'Черный список успешно импортирован. Импортировано {0} элементов',
    'blacklistImportError': 'Не удалось импортировать черный список',
    'noValidEntry': 'Загруженный файл не содержит действительных доменных имен',
    'zeroImported': 'Не удалось импортировать ни одного элемента',
  },
  // DohGuideComp
  DohGuideComp: {
    'closeButton': 'Закрыть',
    'guideButton': 'Нажмите для просмотра',
    'dohMessageClickTips': "Нажмите 'Просмотр' для просмотра инструкций по отключению DoH в вашем конкретном браузере",
    'dohMessageTxt':
      'Для использования этой функции {0}. DoH (DNS через HTTPS) шифрует запросы DNS и может повлиять на некоторые функции.',
    'dohMessage0': 'Отключите настройки DoH в вашем браузере',
    'guideBoxTitle': 'Настройки браузера для DoH',
    'guide': {
      'chromeGuideImg': '/dohGuide/chrome-guide-zh.png',
      'chrome': [
        {
          'label': 'Шаг 1',
          'tip': 'Введите в адресной строке: chrome://settings/security и нажмите Enter.',
        },
        {
          'label': 'Шаг 2',
          'tip': "Найдите раздел 'Расширенные настройки безопасности'.",
        },
        {
          'label': 'Шаг 3',
          'tip': "Отключите опцию 'Использовать безопасный DNS'.",
        },
      ],
      'firefox': [
        {
          'label': 'Шаг 1',
          'tip': 'Введите в адресной строке: about:config и нажмите Enter.',
        },
        {
          'label': 'Шаг 2',
          'tip': "Если появится предупреждение, нажмите 'Принять риск и продолжить'.",
        },
        {
          'label': 'Шаг 3',
          'tip': 'Введите в поле поиска: network.trr.mode и нажмите Enter.',
        },
        {
          'label': 'Шаг 4',
          'tip': "Дважды щелкните на найденной настройке, измените значение на 5 (отключение DoH) и нажмите 'ОК'.",
        },
      ],
      'edge': [
        {
          'label': 'Шаг 1',
          'tip':
            "Введите в адресной строке: edge://settings/privacy и нажмите Enter, затем найдите раздел 'Безопасность'.",
        },
        {
          'label': 'Шаг 2',
          'tip': "Отключите опцию 'Использовать безопасный DNS для обеспечения безопасности сети'.",
        },
      ],
      'operaGuideImg': '/dohGuide/opera-guide-zh.png',
      'opera': [
        {
          'label': 'Шаг 1',
          'tip': 'Введите opera://settings/?search=DNS в адресной строке браузера и нажмите Enter.',
        },
        {
          'label': 'Шаг 2',
          'tip':
            "Найдите опцию 'Использовать DNS через HTTPS вместо системных настроек DNS' и убедитесь, что она отключена.",
        },
      ],
    },
  },
  // appRelocatorData
  appRelocatorData: {
    guide: {
      youtube: {
        UA: 'https://www.deeper.network/pages/app-relocator?app=youtube&lang=zh',
      },
      nbaLeaguePass: 'https://www.deeper.network/pages/app-relocator?app=nbaLeaguePass&lang=zh',
      netflix: 'https://www.deeper.network/pages/app-relocator?app=netflix&lang=zh',
      dazn: 'https://www.deeper.network/pages/app-relocator?app=dazn&lang=zh',
      fubo: 'https://www.deeper.network/pages/app-relocator?app=fubo&lang=zh',
      tencentVideo: 'https://www.deeper.network/pages/app-relocator?app=tencentVideo&lang=zh',
    },
    'countryLabel': 'Страна',
    'tagLabel': 'Тег',
    'typeLabel': 'Тип',
    'country': {
      'all-countries': 'Весь мир',
      'CN': 'Китай',
      'US': 'США',
      'IN': 'Индия',
      'CA': 'Канада',
      'GB': 'Великобритания',
      'NA': 'Другие',
      'NO': 'Норвегия',
      'SG': 'Сингапур',
      'JP': 'Япония',
    },
    'tag': {
      'all-tags': 'Все теги',
      'nba': 'НБА',
      'nfl': 'НФЛ',
      'nhl': 'НХЛ',
      'mlb': 'МЛБ',
      'epl': 'АПЛ',
      'ipl': 'ИПЛ',
    },
    'category': {
      'all-category': 'Все типы',
      'video': 'Видео',
      'music': 'Музыка',
      'im': 'Мгновенные сообщения',
      'flightAndHotel': 'Авиабилеты и отели',
      'subscription': 'Подписка',
    },
    'filter': 'Фильтр',
    'reset': 'Сбросить',
    'submit': 'Готово',
    'subcategory': {
      'popular': 'Популярное',
      'sport': 'Спорт',
      'movie': 'Фильм',
      'tv': 'Телевидение',
    },
    app: {
      'acornTv': 'Acorn TV',
      'amazonPrimeVideo': 'Amazon Prime Video',
      'beInSports': 'beIN Sports',
      'crunchyroll': 'Crunchyroll',
      'dazn': 'DAZN',
      'disneyPlus': 'Disney+',
      'netflix': 'Netflix',
      'plex': 'Plex',
      'roku': 'Roku',
      'shudder': 'Shudder',
      'zattoo': 'Zattoo',
      'discoveryPlus': 'Discovery Plus',
      'paramountPlus': 'Paramount Plus',
      'sportsnetNow': 'Sportsnet NOW',
      'tsn': 'TSN',
      'bbcIPlayer': 'BBC iPlayer',
      'hulu': 'Hulu',
      'itv': 'ITVX',
      'slingTv': 'Sling TV',
      'crackle': 'Crackle',
      'pandora': 'Pandora',
      'theCw': 'The CW',
      'abc': 'ABC',
      'nbc': 'NBC',
      'pluto': 'Pluto TV',
      'starz': 'Starz',
      'tbs': 'TBS',
      'attTv': 'AT&T TV',
      'channel4': 'Channel 4',
      'showtimeAnytime': 'Showtime',
      'nbcSports': 'NBC Sports',
      'epixNow': 'Epix Now',
      'mgmPlus': 'MGM Plus',
      'xumo': 'Xumo',
      'channel5': 'Channel 5',
      'usaNetwork': 'USA Network',
      'fxNow': 'FX Now',
      'vudu': 'Vudu',
      'fubo': 'Fubo TV',
      'espn': 'ESPN',
      'tubiTv': 'Tubi TV',
      'ukTvPlay': 'UKTV Play',
      'tvPlayer': 'TVPlayer',
      'peacock': 'Peacock',
      'pbs': 'PBS',
      'philo': 'Philo',
      'vrv': 'VRV',
      'showmax': 'Showmax',
      'hboMax': 'HBO Max',
      'nhl': 'NHL',
      'dsTv': 'DStv',
      'sSportsPlus': 'S Sports Plus',
      'filmrise': 'FilmRise',
      'cbsSports': 'CBS Sports',
      'nowTV': 'Now TV',
      'molotovTv': 'Molotov TV',
      'mlb': 'MLB',
      'britBox': 'BritBox',
      'stirr': 'Stirr',
      'skyGo': 'Sky Go',
      'hotstar': 'Disney+ Hotstar',
      'sonyLiv': 'SonyLIV',
      'nbaLeaguePass': 'NBA League Pass',
      'amcPlus': 'AMC+',
      'f1Tv': 'F1TV',
      'frndlyTv': 'Frndly TV',
      'myTf1': 'MYTF1',
      'qqMusic': 'QQ Музыка',
      'spotify': 'Spotify',
      'tidal': 'Tidal',
      'youtube': 'YouTube',
      'youTubeMusic': 'YouTube Музыка',
      'weChat': 'WeChat',
      'whatsApp': 'WhatsApp',
      'line': 'Line',
      'signal': 'Signal',
      'agoda': 'Agoda',
      'expedia': 'Expedia',
      'flyScanner': 'Fly Scanner',
      'googleFlights': 'Google Flights',
      'qqSports': 'QQ Спорт',
      'skySports': 'Sky Sports',
      'rakutenTv': 'Rakuten TV',
      'fox': 'FOX',
      'nflNetwork': 'NFL Network',
      'peacocktv': 'Peacock TV',
      'zee5': 'Zee5',
      'mxPlayer': 'MX Player',
      'tencentVideo': 'Tencent Video',
      'iqiyi': 'IQIYI Inc - ADR',
      'mangoTV': 'Mango TV',
      'migu': 'Migu',
      'bilibili': 'BiliBili',
      'youku': 'YouKu',
      'altBalaji': 'ALT Balaji',
      'willowTv': 'Willow TV',
      'foxSports': 'Fox Sports',
      'crave': 'Crave',
      'cbcGem': 'CBC Gem',
      'jiocinema': 'JioCinema',
      'Telegram': 'Telegram',
      'Messenger': 'Messenger',
      'ChatGPT': 'ChatGPT',
      'CanalPlus': 'CanalPlus',
    },
    tip: {
      'video': 'Туннель для популярных видеосервисов',
      'music': 'Туннель для популярных музыкальных сервисов',
      'im': 'Туннель для популярных мессенджеров',
      'flightAndHotel': 'Туннель для популярных сервисов бронирования авиабилетов и отелей',
      'subscription': 'Туннель для популярных сервисов онлайн-подписки',
      'backupTunnel':
        'Резервный туннель активирован. Если в интеллектуальном маршрутизаторе отсутствуют доступные узлы для добавленного туннеля, система будет использовать резервный туннель - {0}',
      'fullTunnel': 'В режиме полного маршрутизатора все приложения будут использовать туннель {0} по умолчанию.',
      'noTunnelNodes': 'Отсутствуют узлы для туннеля. Перейдите на страницу туннелей и обновите узлы для туннеля {0}.',
      'noTunnelNodesOkText': 'Обновить',
      'noTunnelNodesCancelText': 'Закрыть',
      'noTunnelAdded':
        'Вы еще не добавили этот туннель. Перейдите на страницу туннелей и добавьте туннель {0} для использования.',
      'noTunnelAddedOkText': 'Добавить',
      'noTunnelAddedCancelText': 'Отмена',
      'tunnelDeletedText': 'Туннель {0} - {1} был удален',
      'netflix': 'Решение проблем с воспроизведением на Netflix',
      'fubo': 'Fubo TV позволяет вам смотреть прямые трансляции английского футбола по очень низкой цене',
      'tencentVideo': 'Смотрите матчи НБА всего за 1 доллар',
      'dazn': 'DAZN позволяет вам смотреть NFL, NFL, MLB, NBA, EPL по очень низкой цене - всего 17.18 долларов',
      'youtube': 'Как получить доступ к Youtube Premium по очень низкой цене',
    },
    intro: {
      'acornTv':
        'Acorn TV предлагает потоковое видео с мировыми классическими детективными, драматическими и комедийными сериалами из Великобритании и других стран. Вы можете непрерывно смотреть классические сериалы или открыть для себя новые любимые программы.',
      'abc': 'Смотрите программы ABC онлайн на abc.com. Получите эксклюзивные видео и бесплатные сериалы.',
      'f1Tv':
        'F1 TV теперь доступен на веб-сайте, приложении, Google TV, Apple TV, Roku и Amazon Fire TV. Прямая трансляция всех гонок Формулы 1. Прямая трансляция тренировок на трассе всех Гран-при.',
      'youtube':
        'Официальный канал YouTube поможет вам узнать о последних и популярных контентах со всего мира. Смотрите необходимые видео, охватывающие музыку, культуру, интернет-феномены и другие области.',
      'netflix':
        'Netflix - это потоковый сервис, предлагающий широкий выбор награжденных телесериалов, фильмов, аниме, документальных фильмов и другого контента, доступного для просмотра на тысячах устройств, подключенных к Интернету.',
      'fubo':
        'Самый простой способ смотреть Fubo на телевизоре - это использовать Roku, Apple TV, Amazon Fire TV или Chromecast. Просто установите приложение Fubo на своем устройстве для потокового воспроизведения.',
      'disneyPlus':
        'От новых релизов до ваших любимых классических произведений - прошлых, настоящих и будущих. Все, что вы ждете и не ожидаете.',
      'crunchyroll':
        "Исследуйте лучший потоковый контент аниме и манги на Crunchyroll. Наслаждайтесь популярными произведениями, такими как 'Demon Slayer', 'One Piece', 'Attack on Titan' и 'My Hero Academia'.",
      'hulu':
        "Смотрите телесериалы и фильмы онлайн. Потоковое воспроизведение популярных телесериалов, таких как 'Анатомия страсти', 'Это мы', 'Бобовый бургер', 'Бруклин 9-9', 'Империя', 'Субботним вечером в прямом эфире' и других популярных фильмов.",
      'nbaLeaguePass':
        'Подписка на NBA League Pass* предоставляет болельщикам доступ к прямым трансляциям, видеозаписям матчей и другому контенту на протяжении всего сезона и межсезонья.',
      'peacock':
        'Получите Peacock и потоково воспроизводите спортивные события в прямом эфире, эксклюзивный оригинальный контент, популярные шоу и крупные фильмы. Лучшие хиты, классические коллекции, прямые трансляции и многое другое на Peacock. Документальные фильмы, фильмы и шоу для детей, круглосуточные каналы Peacock, Hallmark и другие.',
      'qqSports':
        'Tencent Sports - известный китайский спортивный портал, предлагающий следующие разделы: китайский футбол, международный футбол, NBA, CBA, общий спорт, Олимпиада, прямые трансляции, лотерея, ставки и другие...',
      'rakutenTv':
        'Прямая трансляция телевидения | Бесплатные первые 15 каналов · Бесплатно | Бесплатный просмотр популярных фильмов · Магазин | Покупка или аренда популярных фильмов · Категории · Бесплатно | Недавно добавленные бесплатные контенты · Бесплатно',
      'espn':
        'Посетите ESPN, чтобы получить актуальные результаты, захватывающие моменты и спортивные новости. Смотрите эксклюзивные события на ESPN+ и участвуйте в фэнтези-играх.',
      'beInSports':
        'beIN SPORTS USA раскрывает мир спорта. Получайте эксклюзивные великолепные повторы главных футбольных лиг, последние новости, статистику, результаты и расписание.',
      'dazn':
        'DAZN - это первая в мире потоковая платформа, полностью посвященная прямым трансляциям спортивных событий...',
      'cbsSports':
        'Бесплатная 24-часовая спортивная новостная сеть. Смотрите CBS Sports HQ для получения всех важных сведений о матчах. Еще больше лучших моментов, верхних ставок и успешных фантазийных советов.',
      'fox':
        'Смотрите FOX Sports и получайте актуальные результаты, коэффициенты, новости о командах и игроках, прямые трансляции, видео, статистику, рейтинги и расписание, охватывающие NFL, MLB, NASCAR, WWE, NBA, NHL и другие виды спорта.',
      'nflNetwork':
        'Получите информацию о канале NFL и обновлениях программы! NFL Network - единственная круглогодичная операционная сеть, принадлежащая и управляемая NFL.',
      'peacocktv':
        'Смотрите полные эпизоды текущих и классических программ NBC на NBC.com. Кроме того, вы найдете видеоролики, трейлеры, фотографии и эксклюзивные онлайн-функции.',
      'sportsnetNow':
        'MLB Top Plays - Видео Sportsnet: Спрингер отмечает день рождения ведущим ударом вдали от дома в игре с командой Blue Jays · Видео Sportsnet: Акуниа младший ведет Warriors с превосходящим ударом от трассы · Видео Sportsnet:...',
      'nbcSports': 'Получите последние спортивные новости и результаты NBC Sports, чтобы быть в курсе событий.',
      'tsn':
        'Посетите веб-сайт TSN для получения последних спортивных новостей, результатов, захватывающих моментов и комментариев о турнире Australian Open, плей-офф NFL, Супербоуле, дедлайне сделок в НХЛ и т.д.',
      'nhl':
        'Официальный веб-сайт Национальной хоккейной лиги (National Hockey League) включает новости, ростеры, статистику, расписание, команды и видео.',
      'hotstar':
        'Disney+ Hotstar - это крупнейшая премиальная потоковая платформа в Индии с более чем 100 000 часами театральных постановок и фильмов на 17 языках, а также прямыми трансляциями всех основных спортивных событий.',
      'disneyPlus':
        'От новых релизов до ваших любимых классических фильмов, прошлое, настоящее и будущее - все это ваше. Все, чего вы ожидаете, и даже то, чего не ожидаете. © 2023 Disney. Бренды: Marvel, Pixar, Disney, National Geographic, Star Wars.',
      'skySports':
        'Смотрите лучшие прямые трансляции ваших любимых спортивных событий: футбол, гольф, регби, крикет, автогонки Формулы-1, бокс, американский футбол, баскетбол и последние спортивные новости...',
      'sonyLiv':
        'Премиальная платформа потокового видео, где вы можете смотреть популярные телешоу, фильмы, спортивные события и веб-сериалы. Наслаждайтесь просмотром ваших любимых телеканалов в прямом эфире онлайн.',
      'jiocinema':
        'Смотрите последние фильмы, телевизионные шоу, программы Voot, прямые спортивные события и захватывающие моменты на различных языках, включая хинди, английский и другие региональные языки.',
      'amazonPrimeVideo':
        'Потоковая передача фильмов, шоу, награжденного контента от Amazon, премиальных каналов, спортивных событий и многое другое. Смотрите захватывающие сериалы, блокбастеры, популярные шоу и т.д. с помощью бесплатной пробной версии. Поддержка контента 4K+HDR. Популярные сериалы и фильмы. Новые релизы. Смотрите на любом устройстве.',
      'zee5':
        'Теперь вы можете приобрести ваши любимые Desi контент на ZEE5™ со скидкой 50%. Покупайте сейчас и наслаждайтесь просмотром ваших любимых контентов на ZEE5™. Годовой пакет со скидкой 50%. Ссылка для покупки. Более 4000 популярных фильмов, более 1600 программ и 260 оригинальных произведений. Поддержка 18 языков.',
      'mxPlayer':
        'MX Player - это премиальный сервис потокового видео в Индии, где вы можете смотреть последние музыкальные видео, новые и старые фильмы, телевизионные шоу, комедийные передачи, популярные песни и многое другое.',
      'tencentVideo':
        'Tencent Video - ведущая китайская онлайн-платформа видео, предлагающая богатый контент, идеальный пользовательский опыт, удобные способы входа в систему, 24-часовую мультиплатформенную безшовную работу и удобную функцию обмена.',
      'iqiyi':
        'iQIYI.COM - это крупный видеосервис с огромным количеством высококачественного видео контента, включая фильмы, телевизионные шоу, анимацию, развлекательные программы и многое другое.',
      'youku': 'Youku - предоставляет доступ к большому количеству официального контента в высоком разрешении.',
      'migu':
        'Migu Video - полностью транслирует такие крупные спортивные события, как Олимпийские игры в Пекине, матчи КБА, Лиги чемпионов, Ла Лиги, Бундеслиги, Серии А, Лиги Европы, Чемпионата мира по волейболу, теннису, фигурному катанию, снукеру, WWE, UFC и многое другое.',
      'mangoTV':
        'Mango TV - популярный веб-сайт для просмотра популярных развлекательных шоу, последних фильмов и телевизионных драм онлайн.',
      'nbc':
        'Смотрите полные эпизоды текущих и классических шоу NBC на NBC.com. Кроме того, здесь вы найдете клипы, трейлеры, фотографии и эксклюзивный контент онлайн.',
      'foxSports':
        'Смотрите FOX Sports и получайте актуальные счета, коэффициенты, новости о командах и спортсменах, прямые трансляции, видео, статистику, рейтинги и расписание, охватывающие NFL, MLB, NASCAR, WWE, NBA, NHL и другие события.',
      'nfl':
        'Получайте информацию о канале NFL, обновлениях программы и многое другое! NFL Network - это единственная круглогодичная операционная сеть, принадлежащая и управляемая NFL.',
      'willowTv':
        'После успешного входа в систему вы можете смотреть прямые трансляции, лучшие моменты и повторы на Willow. Службы, такие как Spectrum, Optimum, Fios, Dish, Optimum TV, Xfinity и Time Warner, поддерживаются.',
      'mlb':
        'Наша отчетность включает аудио- и видеофрагменты, интервью, статистику, расписание и эксклюзивные репортажи.',
      'hboMax':
        'HBO Max - это самостоятельная потоковая платформа, объединяющая всю контентную библиотеку HBO с популярными телевизионными шоу, кассовыми фильмами и новыми оригинальными сериалами Max для всех категорий зрителей.',
      'amcPlus':
        'Исследуйте более уникальный и увлекательный опыт на AMC+. Эпические сериалы, захватывающие фильмы и классические сериалы - все это в эксклюзивных сериях AMC+. Качественные драмы. Видео по требованию. Эксклюзивный контент. Эксклюзивные фильмы. Новые сериалы. Без рекламы.',
      'attTv':
        'Получите наши лучшие предложения по телевизорам и Интернету, включая предложения от DIRECTV и домашнего Интернета, включая AT&T Fiber.',
      'britBox':
        'Наслаждайтесь непрерывным просмотром множества британских телесериалов, включая различные жанры, такие как детективы, комедии, драмы, документальные фильмы, стиль жизни и многое другое. Это самая большая коллекция британских телесериалов на сегодняшний день.',
      'crackle':
        'Смотрите бесплатные голливудские фильмы на Crackle - неконвертированные и неизмененные. Здесь вы найдете широкий выбор различных жанров, включая экшн, ужасы, научную фантастику, криминал и комедии. Просто нажмите кнопку воспроизведения.',
      'discoveryPlus':
        'Вы можете потоково воспроизводить контент на мобильных устройствах, планшетах, компьютерах, игровых приставках и подключенных телевизорах. Здесь есть что-то для каждого! discovery+ - это потоковый сервис в области питания, дома и других областей.',
      'mgmPlus':
        'Подписавшись на MGM+, вы сможете наслаждаться тысячами фильмов и телесериалов без рекламы. Отмена подписки в любое время.',
      'filmrise':
        'Мы активно адаптируемся к постоянно меняющимся особенностям отрасли и стремимся предоставить лучший фильм и телевизионный контент.',
      'frndlyTv':
        'С помощью Roku, Amazon Fire TV или Frndly TV вы можете смотреть более 40 прямых телеканалов и контент по требованию, включая все три канала Hallmark, A&E и многое другое. Вы также можете смотреть на мобильных устройствах в любое время и в любом месте.',
      'fxNow': 'Смотрите оригинальные телевизионные шоу, фильмы и прямые трансляции на сетях FX, FXX и FXM.',
      'paramountPlus':
        'Потоковое воспроизведение тысяч эпизодов сериалов, прямых спортивных событий и эксклюзивного оригинального контента на одной платформе. Просмотр сотен культовых фильмов, начиная от награжденных классических фильмов до популярных новинок. Откройте для себя новые сериалы для непрерывного просмотра. Популярные реалити-шоу. Популярные шоу MTV. Более 30 000 эпизодов сериалов. Важные новости.',
      'pbs':
        'Фильмы POV известны своими запоминающимися и своевременными рассказами. Смотрите прямо сейчас! POV - самое долгоживущее телевизионное шоу о независимом документальном кино. Узнайте больше! Независимые неписьменные произведения. Более 500 фильмов в наличии. Запоминающиеся истории.',
      'philo':
        'Почему платить больше за телевизор? Philo всего за 25 долларов в месяц предлагает прямую трансляцию телевизионных программ и контента по запросу. Вы можете потоково воспроизводить свои любимые шоу, фильмы и т. д. на всех устройствах.',
      'plex':
        'Plex - это бесплатное приложение для потоковой передачи контента, которое не требует кредитной карты, абонентской платы или скрытых платежей. Чтобы сохранить наши услуги бесплатными, мы включаем рекламу для получения прибыли.',
      'pluto':
        'У нас есть все - от последних популярных фильмов до полных сезонов ваших любимых сериалов. Сотни каналов. Бесплатно. Доступно на всех устройствах. Начните смотреть прямо сейчас! Доступно на всех устройствах. Непрерывный просмотр. Контент по запросу. Сотни каналов. Потоковое воспроизведение прямо сейчас. Без платы.',
      'roku':
        'Устройства Roku делают потоковое телевидение простым. От плееров и умных телевизоров до устройств умного дома - найдите идеальный продукт Roku для вашего дома, чтобы сделать его более безопасным.',
      'showtimeAnytime':
        'Попробуйте SHOWTIME бесплатно, чтобы потоково воспроизводить оригинальные сериалы, фильмы, спортивные события, документальные фильмы и т. д. Кроме того, вы можете заказать платные бои без подписки.',
      'shudder':
        'Начните с пакета Shudder всего за 4,99 доллара в месяц, чтобы получить полный доступ к контенту Shudder и смотреть популярные сериалы, такие как Волчонок, Аннализ Кид и другие. Попробуйте Shudder бесплатно...',
      'slingTv':
        'Sling TV - это первый приложениями основанный телевизионный сервис, который позволяет потоково воспроизводить прямые телевизионные программы и контент по запросу через интернет. Вы можете смотреть прямые телепередачи, где бы вы ни находились...',
      'starz':
        'STARZ представляет разнообразные точки зрения на жизнь через смелое повествование. Зарегистрируйтесь, чтобы потоково воспроизводить оригинальные сериалы, фильмы, дополнительный контент и смотреть по запросу без рекламы.',
      'stirr':
        '...и развлекательные шоу. Прямая трансляция и контент по запросу. STIRR City и National настроены на ваш местный канал. Начните смотреть. 00:05. Более 100 каналов, более 5000 часов телевизионных и...',
      'philo':
        'Почему платить больше за телевизор? Philo всего за 25 долларов в месяц предлагает прямую трансляцию телевизионных программ и контента по запросу. Вы можете потоково воспроизводить свои любимые шоу, фильмы и т. д. на всех устройствах.',
      'plex':
        'Plex - это бесплатное приложение для потоковой передачи контента, которое не требует кредитной карты, абонентской платы или скрытых платежей. Чтобы сохранить наши услуги бесплатными, мы включаем рекламу для получения прибыли.',
      'pluto':
        'У нас есть все - от последних популярных фильмов до полных сезонов ваших любимых сериалов. Сотни каналов. Бесплатно. Доступно на всех устройствах. Начните смотреть прямо сейчас! Доступно на всех устройствах. Непрерывный просмотр. Контент по запросу. Сотни каналов. Потоковое воспроизведение прямо сейчас. Без платы.',
      'roku':
        'Устройства Roku делают потоковое телевидение простым. От плееров и умных телевизоров до устройств умного дома - найдите идеальный продукт Roku для вашего дома, чтобы сделать его более безопасным.',
      'showtimeAnytime':
        'Попробуйте SHOWTIME бесплатно, чтобы потоково воспроизводить оригинальные сериалы, фильмы, спортивные события, документальные фильмы и т. д. Кроме того, вы можете заказать платные бои без подписки.',
      'shudder':
        'Начните с пакета Shudder всего за 4,99 доллара в месяц, чтобы получить полный доступ к контенту Shudder и смотреть популярные сериалы, такие как Волчонок, Аннализ Кид и другие. Попробуйте Shudder бесплатно...',
      'slingTv':
        'Sling TV - это первый приложениями основанный телевизионный сервис, который позволяет потоково воспроизводить прямые телевизионные программы и контент по запросу через интернет. Вы можете смотреть прямые телепередачи, где бы вы ни находились...',
      'starz':
        'STARZ представляет разнообразные точки зрения на жизнь через смелое повествование. Зарегистрируйтесь, чтобы потоково воспроизводить оригинальные сериалы, фильмы, дополнительный контент и смотреть по запросу без рекламы.',
      'stirr':
        '...и развлекательные шоу. Прямая трансляция и контент по запросу. STIRR City и National настроены на ваш местный канал. Начните смотреть. 00:05. Более 100 каналов, более 5000 часов телевизионных и...',
      'myTf1':
        'Смотрите прямые трансляции программ TF1, TMC, TFX, TF1 Séries Films и LCI, более 50 эксклюзивных тематических каналов и более 10 000 часов видеоконтента только на MYTF1...',
      'sSportsPlus':
        'С Sports Plus вы можете наслаждаться более 700 часами прямых трансляций в месяц и получить доступ к сотням видов спортивного контента, от Серии А до Ла Лиги, от Формулы-1 до НБА, от Евролиги до UFC - все у вас под рукой...',
      'showmax':
        'Стоимость подписки на Showmax различается, так как Showmax предлагает различные планы, чтобы удовлетворить разные предпочтения и бюджеты, с ценой, начиная от R39...',
      'itv':
        'ITVX - это самый свежий потоковый сервис Великобритании, предлагающий эксклюзивные новые шоу, крупные фильмы, прямые события и тысячи сериалов, все на одной платформе.',
      'pandora':
        'Проигрывайте свои любимые песни, альбомы, плейлисты и подкасты на новом Pandora. Подпишитесь на план подписки и наслаждайтесь безрекламным прослушиванием по требованию.',
      'qqMusic':
        'QQ Music - это сервис по онлайн-прослушиванию музыки, предоставляемый компанией Tencent. Здесь вы найдете огромное количество музыки для прослушивания, новинки, тексты песен, загрузку мелодий для мобильных телефонов, прослушивание музыки высокого качества без потерь, огромную библиотеку музыки и официальную загрузку музыки.',
      'spotify': 'Spotify - это цифровой сервис для прослушивания музыки, который дает вам доступ к миллионам песен.',
      'tidal':
        'TIDAL - это музыкальная платформа, ориентированная на художников и фанатов, предлагающая более 100 миллионов песен высокого качества.',
      'weChat':
        'Используйте WeChat на различных платформах; наслаждайтесь групповыми чатами; поддерживает голосовые сообщения, фотографии, видео и текстовые сообщения.',
      'whatsApp':
        'Оставайтесь на связи с друзьями и семьей с помощью WhatsApp Messenger. WhatsApp - бесплатное приложение, предлагающее простые, безопасные и надежные сообщения и звонки, доступные в...',
      'line':
        'Новое приложение для общения, которое позволяет бесплатно звонить и отправлять сообщения в любое время и в любом месте - круглосуточно!',
      'signal':
        'Бесплатное обмен текстовыми сообщениями, голосовыми сообщениями, фотографиями, видео, GIF и файлами. Signal использует данные вашего телефона, чтобы избежать платы за SMS и MMS.',
      'agoda':
        'Бронируйте сейчас, платите позже, бесплатная отмена. Круглосуточное обслуживание на вашем языке. От экономичных до роскошных отелей и жилья. Ваш надежный партнер по комнатам и авиабилетам.',
      'expedia':
        'Добро пожаловать на вашу универсальную путеводительскую веб-страницу, где сбываются ваши мечты о отпуске. Сэкономьте больше, объединив проживание, аренду автомобиля или полет. Ищите гибкие варианты, чтобы найти наиболее подходящий для вас...',
      'flyScanner':
        'Легко получайте скидки на авиабилеты от $19. Сравните и забронируйте все в одном поиске, быстро и удобно. Сравнивайте дешевые авиабилеты, отели и аренду автомобилей. Найдите тысячи выгодных предложений и получите лучшее из них!',
      'nowTV':
        'Сколько стоит членство NOW? Членство NOW позволяет вам смотреть желаемый контент по своему усмотрению. Вы можете подписаться на развлекательные, кинотеатральные, спортивные и Hayu.',
      'TVPlayer':
        'TVPlayer - самое популярное место для просмотра телеканалов и качественных программ по требованию, доступных только на TVPlayer. Смотрите более 40 прямых каналов и тысячи...',
      'ukTvPlay':
        'UKTV Play - это дом для каналов Dave, Drama, W и Yesterday. Смотрите прямые трансляции телевизионных программ, потоковое видео и догоняйте ваши любимые шоу.',
      'zattoo':
        'Смотрите прямые трансляции и записи ваших любимых телевизионных программ на всех устройствах. Лучшее HD-качество изображения. Лучше, чем кабельное телевидение. Предлагает видео по требованию и прямую трансляцию телевизионного контента.',
      'skyGo':
        'С помощью пакета Sky вы можете смотреть прямые трансляции, программы по требованию и отложенный просмотр телевизионных передач на своем смартфоне, планшете, ноутбуке или компьютере, а также легко переключаться между различными устройствами через устройство Apple TV.',
      'Telegram':
        'Telegram стремится предоставить безопасный, быстрый и краткий опыт общения и известен своими мощными функциями шифрования и богатыми функциями.',
      'Messenger':
        'Messenger - это приложение для мгновенного обмена сообщениями, предоставляемое Facebook, которое позволяет пользователям общаться с другими посредством текстовых сообщений, голосовых вызовов, видеозвонков, отправки изображений, эмодзи и т. д. Messenger изначально был функцией веб-сайта Facebook, позже он превратился в отдельное мобильное приложение и предоставляет больше функций и услуг.',
      'ChatGPT':
        'ChatGPT - это модель языка искусственного интеллекта, разработанная OpenAI, основанная на архитектуре GPT (Generative Pre-trained Transformer). Он обучается на больших объемах данных обработки естественного языка и может понимать и генерировать текст на естественном языке.',
      'CanalPlus':
        'CANAL+ - это французский платный телеканал, одна из крупнейших платных телесетей во Франции и один из самых важных провайдеров платного телевидения в Европе. Канал предлагает разнообразные программы, включая фильмы, спорт, новости, документальные фильмы, телесериалы и т. д.',
    },
    'selectTunnel': 'Выберите туннель',
    notSetLabel: 'Не задано',
    'directAccessLabel': 'Прямой доступ',
    'otherTunnelLabel': 'Другой туннель',
    'recommendedTunnelLabel': 'Рекомендуемый туннель',
    'error': {
      'getApps': 'Не удалось получить приложения',
      'getTunnels': 'Не удалось получить список туннелей',
      'invalidApp': 'Недопустимое приложение',
      'tunnelNotExist': 'Туннель не существует',
      'set': 'Не удалось установить туннель',
      'unset': 'Не удалось отменить установку туннеля',
    },
    'success': {
      'set': 'Туннель успешно установлен',
      'unset': 'Туннель успешно отменен',
    },
    searchInputPlaceholder: 'Поиск нужного вам сервиса',
    discountIntro: percent => `Экономия в размере ${percent} на ежемесячную подписку`,
    discountPercent: percent => `-${percent} (долларов)/месяц`,
    guideButtonLabel: 'Подробнее',
  },
  // betanet
  betanet: {
    'bindWalletSuccess': 'Успешное привязывание кошелька',
    'deviceBound': 'Устройство уже привязано к другому кошельку',
    'walletTaken': 'Кошелек уже используется другим устройством',
    'deviceTypeIncompatible': 'Устройство несовместимо с кошельком',
    'invalidMnemonic': 'Недействительное мнемоническое слово кошелька',
    'invalidPrivateKey': 'Недействительный приватный ключ кошелька',
    'bindWalletError': 'Ошибка привязывания кошелька',
    'notBound': 'Пользователь не привязал кошелек',
    'unbindWalletSuccess': 'Успешное отвязывание кошелька',
    'noActiveBinding': 'Устройство не привязано к указанному кошельку',
    'unbindWalletError': 'Ошибка отвязывания кошелька',
    'walletManagement': 'Управление кошельком',
    'bindWallet': 'Привязать кошелек',
    'downloadPrivateKey': 'Скачать приватный ключ',
    'downloadKeyError': 'Ошибка скачивания приватного ключа',
    'unbindBtnText': 'Отвязать кошелек',
    'unbindWarning': "Убедитесь, что вы скачали приватный ключ. Введите 'Я скачал приватный ключ' и продолжайте.",
    'unbindWarningWords': 'Я скачал приватный ключ',
    'generateWallet': 'Создать кошелек',
    'verifyMnemonic': 'Проверить мнемоническое слово',
    'mnemonicMismatch': 'Ошибка проверки мнемонического слова',
    'bindBtn1Text': 'Привязать кошелек (мнемоническое слово)',
    'bindBtn2Text': 'Привязать кошелек (приватный ключ)',
    'dprManagement': 'Управление DPR',
    'balanceLabel': 'Баланс DPR',
    'balance': 'Баланс',
    'creditLabel': 'Кредит',
    'operationLabel': 'Операция',
    'amtOwingLabel': 'Система предоставила вам кредит',
    'dailyConsumedLabel': 'Ежедневное потребление трафика',
    'dailySharedLabel': 'Ежедневное совместное использование трафика',
    'deposit': 'Внести',
    'withdraw': 'Вывести',
    'publicKey': 'Публичный ключ',
    'mnemonicInstruction': 'Запишите мнемоническое слово. Вам потребуется его для проверки на следующем шаге.',
    'enterMnemonic': 'Введите мнемоническое слово',
    'enterMnemonicTip': 'Пожалуйста, введите мнемоническое слово в правильном порядке.',
    'resetMnemonic': 'Сбросить',
    'uploadPrivateKey': 'Загрузить приватный ключ',
    'getWalletError': 'Ошибка получения информации о кошельке',
    'deviceWalletMismatch': 'Устройство не соответствует кошельку',
    'walletNotFound': 'Кошелек не найден',
    'generateWalletError': 'Ошибка создания кошелька',
    'missingMnemonic': 'Отсутствует мнемоническое слово',
    'missingPrivateKeyFile': 'Отсутствует файл приватного ключа',
    'privateKeyFileOversize': 'Файл приватного ключа превышает допустимый размер 10 КБ',
    'transferTitle': 'Перевод',
    'transferHistoryTitle': 'История переводов',
    'transferTo': 'Получатель',
    'transferAmt': 'Сумма перевода (до 6 знаков после запятой)',
    'invalidTo': 'Недействительный получатель',
    'invalidAmt': 'Недействительная сумма перевода',
    'amtOutOfRange': 'Сумма перевода должна быть положительной и не превышать 1000000',
    'transferSuccess': 'Перевод выполнен успешно',
    'senderRecipientSame': 'Отправитель и получатель не могут быть одним и тем же лицом',
    'recipientNotFound': 'Получатель не найден',
    'insufficientFund': 'Перевод не выполнен из-за недостатка средств',
    'transferError': 'Ошибка перевода',
    'votingResNotRetrieved': 'Результаты голосования не получены',
    'candidateSelected': 'Выбранный вами кандидат валидатора был выбран',
    'candidateNotSelected': 'Выбранный вами кандидат валидатора не был выбран',
    'votingResNotReady': 'Результаты голосования в данный момент недоступны',
    'notVoted': 'Вы не проголосовали за ни одного кандидата валидатора',
    'candidateNotFound': 'Кандидат валидатора не найден',
    'votingResRetrievalErr': 'Ошибка получения результатов голосования',
    'from': 'Отправитель',
    'to': 'Получатель',
    'type': 'Тип',
    'amount': 'Количество',
    'time': 'Время',
    'txnTypes': {
      '101': 'Начальное вознаграждение',
      '201': 'Ручной перевод',
      '301': 'Вознаграждение за делегирование',
      '401': 'Микроплатеж',
    },
    'walletAddress': 'Адрес кошелька',
    'notInGenesisNodeList': 'Генезис-узел может быть привязан только к генезис-майнеру.',
    'onChainStatusLabel': 'Статус на блокчейне',
    'onChainStatusDesc': {
      'unknown': '-',
      'onChain': 'Да',
      'notOnChain': 'Нет',
    },
    'pwMgmtSection': {
      'title': 'Управление паролем',
      'setBtnText': 'Установить пароль',
      'resetBtnText': 'Сбросить пароль',
    },
    'passwordModal': {
      'title': 'Установить пароль для кошелька',
      'onePlaceholder': 'Введите пароль',
      'twoPlaceholder': 'Повторите пароль',
      'btnText': 'Подтвердить',
    },
    'setPasswordMsg': {
      'mismatch': 'Пароли не совпадают',
      'weak': 'Длина пароля должна быть не менее 8 символов и содержать строчные и заглавные буквы, а также цифры',
      'alreadySet': 'Пароль для кошелька уже установлен',
      'genericErr': 'Не удалось установить пароль для кошелька',
      'success': 'Пароль для кошелька успешно установлен',
    },
    'authModal': {
      'title': 'Пароль для кошелька',
      'inputPlaceholder': 'Введите пароль для кошелька',
      'btnText': 'Продолжить',
    },
    'passwordNotSet': 'Пароль для кошелька не установлен',
    'passwordIncorrect': 'Неверный пароль для кошелька',
    'resetModal': {
      'disclaimer':
        "После сброса пароля для кошелька ваше устройство будет отвязано от текущего привязанного кошелька. Введите 'Я подтверждаю сброс пароля для кошелька', чтобы продолжить",
      'consent': 'Я подтверждаю сброс пароля для кошелька',
    },
    'resetPasswordMsg': {
      'noActiveBinding': 'Устройство не привязано к указанному кошельку',
      'unbindErr': 'Не удалось отвязать кошелек',
      'genericErr': 'Не удалось сбросить пароль для кошелька',
      'success': 'Пароль для кошелька успешно сброшен',
    },
    'unstaking': {
      'label': 'Информация о стейкинге',
      'getErr': 'Не удалось получить выбор остановки стейкинга',
      'alreadyChosen': 'Вы уже выбрали остановку стейкинга',
      'expired': 'Период остановки стейкинга истек',
      'invalidCreditData': 'У данного кошелька нет данных о кредите',
      'phase1IsNotOver': 'Первый период стейкинга для данного кошелька еще не завершен',
      'setErr': 'Не удалось установить выбор остановки стейкинга',
      'announcementTitle': 'Уважаемый пользователь узла стейкинга:',
      'announcement': {
        'continueStaking':
          'Продолжение стейкинга: Ваша стейкинг-активность автоматически переходит во второй период; второй период стейкинга длится 6 месяцев. APY для базового стейкинга (Basic Mining) и генезис-узла (Genesis) остается неизменным во втором периоде.',
        'duringStaking':
          'Во время второго периода стейкинга: Вы можете в любое время нажать эту кнопку, чтобы остановить стейкинг; эта операция необратима!',
        'stopStaking': 'Остановка стейкинга: Вы перейдете в 3-месячную фазу линейного выпуска;',
        'releaseStaking':
          'Выпуск стейкинга: DPR будет ежедневно автоматически выплачиваться на ваш кошелек DeeperChain.',
      },
      'tipTitle': 'Напоминание:',
      'tip': {
        'afterStopStaking_1':
          '1. После остановки стейкинга, полученный кредит будет удален (кредит, накопленный во время стейкинга за счет совместного использования пропускной способности, будет сохранен)',
        'afterStopStaking_2':
          '2. Отмена стейкинга генезис-узла приведет к потере его генезис-позиции и навсегда лишит вас возможности стать генезис-узлом. Ваше устройство не сможет немедленно участвовать в базовом стейкинге 2.0, но органический рейтинг кредита, полученный за счет совместного использования пропускной способности, будет сохранен.',
        'afterStopStaking_3':
          '3. После остановки стейкинга, вознаграждение за стейкинг DPR будет немедленно прекращено',
        'afterStopStaking_4':
          '4. После остановки стейкинга, немедленно не будет возможности повторно стейкинга (необходимо дождаться дальнейших объявлений)',
        'afterStopStaking_5':
          '5. После остановки стейкинга, остановка будет произведена только после достижения всех 270 вознаграждений первого периода',
      },
      'btn': {
        'cancel': 'Понятно, продолжить стейкинг',
        'ok': 'Выйти из стейкинга',
      },
    },
    staking: {
      'walletInfo': 'Информация о кошельке',
      'campaignLabel': 'Тип стейкинг-кампании',
      'campaignText': {
        '0': 'Золотой генезис-узел',
        '1': 'Серебряный генезис-узел',
        '2': 'Стейкинг 1.0',
        '3': 'DPRB майнинг',
        '4': 'Стейкинг 2.0',
        '7': 'Стейкинг 2.0',
        '8': 'Стейкинг 2.0',
      },
      'creditLabel': 'Текущий кредитный рейтинг',
      'creditMaxLabel': 'Максимальный кредитный рейтинг',
      'creditLevelLabel': 'Уровень кредитного рейтинга',
      'stakeAndBurn': 'Стейкинг и сжигание',
      'stakeForCredit': 'Увеличить стейкинг для получения кредитного рейтинга',
      'dailyReward': 'Ежедневное вознаграждение',
      view: 'Проверять',
      'payment': 'Необходимая оплата',
      'insufficientBalance': 'Недостаточный баланс',
      'stakeSuccess': 'Запрос успешно отправлен. Пожалуйста, подождите обновления кредитного рейтинга.',
      'stakeError':
        'Не удалось отправить запрос. Пожалуйста, проверьте информацию о кошельке или обратитесь в службу поддержки.',
      'burnForCredit': 'Сжечь DPR для увеличения кредитного рейтинга',
      'burnSuccess': 'Запрос успешно отправлен. Пожалуйста, подождите обновления кредитного рейтинга.',
      'burnError':
        'Не удалось отправить запрос. Пожалуйста, проверьте информацию о кошельке или обратитесь в службу поддержки.',
    },
  },
  // ChangePasswordData
  ChangePasswordData: {
    'successMsg': 'Пароль успешно изменен',
    'newPasswordMismatch': 'Новые пароли не совпадают',
    'oldPasswordMismatch': 'Неверный старый пароль',
    'samePasswordError': 'Новый пароль совпадает со старым паролем',
    'genericError': 'Ошибка при изменении пароля',
    'title': 'Изменение пароля',
    'oldPwd': 'Введите старый пароль',
    'newPwd': 'Введите новый пароль',
    'newPwdAgain': 'Повторите новый пароль',
    'okText': 'Подтвердить',
  },
  //dailyTraffic
  dailyTraffic: {
    'historySize': 'Дни',
    'chartTitle': 'Трафик',
    'uptimeChartTitle': 'Время работы',
    'hour': 'час',
    'minute': 'минута',
    'second': 'секунда',
  },
  // flow
  flow: {
    'totalTrafficLabel': 'Общий трафик',
    'localTraffic': 'Локальный трафик',
    'clientTraffic': 'Трафик клиента',
    'serverTraffic': 'Трафик сервера',
    'upload': 'Отправка',
    'download': 'Загрузка',
    'sessionSpeed': 'Скорость сеанса',
  },
  // login
  login: {
    'pageTitle': 'Добро пожаловать в Deeper Network!',
    'username': 'Имя пользователя',
    'password': 'Пароль',
    'btnText': 'Войти',
    'successMsg': 'Успешный вход',
    'missingField': 'Отсутствует имя пользователя или пароль',
    'wrongField': 'Неправильное имя пользователя или пароль',
    'invalidToken': 'Недействительные данные для входа',
    'expiredToken': 'Срок действия данных для входа истек',
    'genericError': 'Ошибка входа',
    'tooManyAttempts': 'Слишком много попыток входа',
  },
  //notification
  notification: {
    'search': 'Поиск',
    'CP': 'Плоскость управления',
    'DP': 'Плоскость данных',
    'MP': 'Плоскость управления',
  },
  // DomainConfig
  domainConfig: {
    'whiteList': 'Список умных маршрутов',
    'blackList': 'Список прямых маршрутов',
    'domain': 'Домен',
    'tunnel': 'Туннель',
    'selectTunnel': 'Выберите туннель',
    'region': 'Регион',
    'country': 'Страна или регион',
    'noTunnel': 'Без туннеля',
    'convertedTip': 'URL автоматически преобразован в доменное имя',
    'urlInputPlaceholder': 'Например: https://www.google.com или google.com',
    'missingInfoWarning': 'Пожалуйста, заполните все поля',
    'invalidDomainError': 'Неверный формат домена или слишком длинное имя домена',
    'whitelistAddSuccess': 'Успешно добавлено в список умных маршрутов',
    'existInBlacklist': 'Элемент уже присутствует в списке прямых маршрутов',
    'whitelistEntryExist': 'Элемент уже присутствует в списке умных маршрутов',
    'tunnelNotExist': 'Туннель не существует, невозможно добавить',
    'whitelistAddError': 'Не удалось добавить элемент в список умных маршрутов',
    'blacklistAddSuccess': 'Успешно добавлено в список прямых маршрутов',
    'existInWhitelist': 'Элемент уже присутствует в списке умных маршрутов',
    'blacklistEntryExist': 'Элемент уже присутствует в списке прямых маршрутов',
    'blacklistAddError': 'Не удалось добавить элемент в список прямых маршрутов',
    'conflict': 'Невозможно добавить элемент из-за конфликта',
    'whitelistDelSuccess': 'Элемент из списка умных маршрутов успешно удален',
    'domainNotExist': 'Выбранный домен не существует',
    'whitelistDelError': 'Не удалось удалить элемент из списка умных маршрутов',
    'blacklistDelSuccess': 'Элемент из списка прямых маршрутов успешно удален',
    'blacklistDelError': 'Не удалось удалить элемент из списка прямых маршрутов',
    'backupTips':
      'Резервный туннель включен. Если в режиме умного маршрута нет доступных узлов, система будет использовать резервный туннель - {0}.',
    'tips': {
      'smartRouteList':
        'В соответствии с вашими потребностями вы можете вручную добавить домен, к которому вы хотите получить доступ, и соответствующую страну.',
      'directRouteList':
        'Домены, добавленные здесь, не будут проходить через туннель DPN, а будут использовать локальную сеть.',
    },
  },
  // ipConfig
  ipConfig: {
    'whiteList': 'Список умных IP',
    'blackList': 'Список прямых IP',
    'ip': 'IP',
    'tunnel': 'Туннель',
    'selectTunnel': 'Выберите туннель',
    'region': 'Регион',
    'country': 'Страна или регион',
    'noTunnel': 'Без туннеля',
    'convertedTip': 'URL автоматически преобразован в IP-адрес',
    'urlInputPlaceholder': 'Например: https://xxx.xxx.xxx.xxx или xxx.xxx.xxx.xxx',
    'missingInfoWarning': 'Пожалуйста, заполните все поля',
    'invalidIPError': 'Неверный формат IP-адреса',
    'whitelistAddSuccess': 'Успешно добавлено в список умных IP',
    'existInBlacklist': 'Элемент уже присутствует в списке прямых IP',
    'whitelistEntryExist': 'Элемент уже присутствует в списке умных IP',
    'tunnelNotExist': 'Туннель не существует, невозможно добавить',
    'whitelistAddError': 'Не удалось добавить элемент в список умных IP',
    'blacklistAddSuccess': 'Успешно добавлено в список прямых IP',
    'existInWhitelist': 'Элемент уже присутствует в списке умных IP',
    'blacklistEntryExist': 'Элемент уже присутствует в списке прямых IP',
    'blacklistAddError': 'Не удалось добавить элемент в список прямых IP',
    'whitelistDelSuccess': 'Элемент из списка умных IP успешно удален',
    'ipNotExist': 'Выбранный IP не существует',
    'whitelistDelError': 'Не удалось удалить элемент из списка умных IP',
    'blacklistDelSuccess': 'Элемент из списка прямых IP успешно удален',
    'blacklistDelError': 'Не удалось удалить элемент из списка прямых IP',
    'backupTips':
      'Резервный туннель включен. Если в режиме умного маршрута нет доступных узлов, система будет использовать резервный туннель - {0}.',
    'tips': {
      'smartRouteList':
        'В соответствии с вашими потребностями вы можете вручную добавить IP-адрес, к которому вы хотите получить доступ, и соответствующую страну.',
      'directRouteList':
        'IP-адреса, добавленные здесь, не будут проходить через туннель DPN, а будут использовать локальную сеть.',
    },
  },
  // queryRouting
  queryRouting: {
    'title': 'Поиск маршрута',
    'searchLabel': 'Поиск настроек',
    'search': 'Поиск',
    'searchResult': 'Результат поиска: ',
    'invalidRoutingError': 'Неверный формат запроса',
    'defaultConfig': 'Конфигурация по умолчанию',
    'userConfig': 'Пользовательская конфигурация',
    'routingNotFound': 'Маршрут не найден',
    'queryRoutingError': 'Ошибка при запросе маршрута',
    'convertedTip': 'URL автоматически преобразован в доменное имя или IP-адрес',
    'urlInputPlaceholder': 'Например: google.com или xxx.xxx.xxx.xxx',
  },
  // rustdesk
  rustdesk: {
    'ipLabel': 'Ваш IP-адрес',
    'noPubIp': 'У вас нет общедоступного IP-адреса.',
    'vpnUse':
      'Вы можете использовать перенаправление портов Wireguard VPN для получения общедоступного IP-адреса. Обратите внимание, что этот общедоступный IP-адрес может использоваться только для сервиса RustDesk и не предоставляет услуги DPN.',
    'config': {
      'title': 'Настройки RustDesk',
      'switch': 'Вкл/Выкл',
      'forceKey': 'Требовать совпадение публичного ключа',
      'relay': 'Серверы ретрансляции через запятую (хост:порт), например www.example.com:21116,192.168.1.1:21117',
      'port': 'Порт (2-65532)',
      'saveBtnText': 'Сохранить',
      'pubKey': 'Публичный ключ',
      'emptyPubKey': 'Нет публичного ключа',
      'resetBtnText': 'Сбросить публичный ключ',
    },
    'vpn': {
      'title': 'Перенаправление портов Wireguard VPN',
      'switch': 'Вкл/Выкл',
      'ip': 'IP-адрес сервера Wireguard',
      'port': 'Порт VPN перенаправления',
    },
    'guide': {
      'title': 'Руководство по настройке',
      'stepOne': 'Щелкните на кнопке, как показано на рисунке',
      'stepTwoA': 'Заполните соответствующие поля в форме и нажмите "Сохранить"',
      'idAndRelay': 'ID/Ретрансляционный сервер',
      'idServer': 'ID-сервер:',
      'relayServer': 'Ретрансляционный сервер:',
      'apiServer': 'API-сервер:',
      'serverKey': 'Ключ:',
      'stepTwoB': 'Или вы можете скопировать конфигурацию в буфер обмена и импортировать ее в RustDesk',
      'copyBtnText': 'Скопировать конфигурацию сервера в буфер обмена',
    },
    'success': {
      'setSwitch': 'Успешно переключено на RustDesk',
      'setForced': 'Успешно установлено требование совпадения публичного ключа',
      'setRelayAndPort': 'Успешно сохранены ретрансляция и порт',
      'resetPubKey': 'Успешно сброшен публичный ключ',
      'setVpnSwitch': 'Успешно переключено на Wireguard VPN',
      'copyToClipboard': 'Успешно скопировано в буфер обмена',
    },
    'error': {
      'getNetworkAddress': 'Не удалось получить IP-адрес',
      'getConfig': 'Не удалось получить настройки RustDesk',
      'setSwitch': 'Не удалось переключить на RustDesk',
      'setForced': 'Не удалось установить требование совпадения публичного ключа',
      'invalidPort': 'Недопустимый порт',
      'invalidRelay': 'Недопустимый ретрансляционный сервер',
      'setRelayAndPort': 'Не удалось сохранить ретрансляцию и порт',
      'resetPubKey': 'Не удалось сбросить публичный ключ',
      'getVpnConfig': 'Не удалось получить настройки Wireguard VPN',
      'setVpnSwitch': 'Не удалось переключить на Wireguard VPN',
      'copyToClipboard': 'Не удалось скопировать в буфер обмена',
      'getVersion': 'Не удалось получить номер версии',
      'getHbbrLog': 'Не удалось получить журнал HBBR',
      'getHbbsLog': 'Не удалось получить журнал HBBS',
    },
  },
  // sharing
  sharing: {
    'sharingTitle': 'Общий доступ: ',
    'btSharingTitle': 'Общий доступ BitTorrent: ',
    'enabled': 'Включено',
    'disabled': 'Выключено',
    'sharingTrafficLimit': 'Лимит общего трафика в месяц',
    'sharingBandwidthLimit': 'Ограничение пропускной способности общего доступа',
    'unlimited': 'Без ограничений',
    'limited': 'Ограничено',
    'setLabel': 'Установить',
    'checkStartEndTime': 'Выберите время начала и окончания',
    'time': 'Время',
    'record': 'Запись',
    'startTime': 'Время начала',
    'endTime': 'Время окончания',
    'keyword': 'Ключевое слово',
    'search': 'Поиск',
    'webToSharingTitle': 'Правила фильтрации веб-страниц для общего доступа',
    webToSharingDesc:
      'Примените встроенную фильтрацию рекламы DNS устройства и пользовательские правила фильтрации DNS.',
    'yes': 'Да',
    'no': 'Нет',
    'download': 'Открытый журнал',
    'export': 'Зашифрованный журнал',
    'success': {
      enableTrafficLimit: 'Ежемесячный лимит общего трафика включен. Пожалуйста, установите лимит!',
      'setSharingTrafficLimit': 'Успешно установлен лимит общего трафика',
      'enableBandwidthLimit':
        'Ограничение пропускной способности общего доступа включено. Пожалуйста, установите лимит между 15 Мбит/с и 1024 Мбит/с!',
      'setSharingBandwidthLimit': 'Успешно установлено ограничение пропускной способности общего доступа',
    },
    'errors': {
      'getSharingState': 'Не удалось получить состояние общего доступа',
      'switchSharingState': 'Не удалось переключить состояние общего доступа',
      'exceedsLimit': 'Не удалось включить общий доступ, превышен лимит трафика',
      'getSharingTrafficLimit': 'Не удалось получить лимит общего трафика',
      'setSharingTrafficLimit': 'Не удалось установить лимит общего трафика',
      'invalidTrafficLimit': 'Лимит трафика должен быть положительным числом.',
      'setSharingBandwidthLimit': 'Не удалось установить ограничение пропускной способности общего доступа',
      'invalidBandwidthLimit': 'Ограничение пропускной способности должно быть между 15 Мбит/с и 1024 Мбит/с',
      'getSharingFilterConfig': 'Не удалось получить конфигурацию фильтрации общего доступа',
      'setSharingFilterConfig': 'Не удалось установить конфигурацию фильтрации общего доступа',
    },
  },
  // system
  system: {
    'hardwareInfo': 'Информация об оборудовании',
    'softwareInfo': 'Информация о программном обеспечении',
    'networkAddress': 'Сетевой адрес',
    'sessionInfo': 'Информация о сеансе',
    'upgradeInfo': 'Информация об обновлении',
    'sn': 'Серийный номер',
    'cpu': 'ЦПУ',
    'cores': 'Ядра',
    'memory': 'Использовано/Доступно',
    'systemVersion': 'Версия системы',
    'appidSignVersion': 'Библиотека идентификации протокола трафика',
    'urlFilterSignVersion': 'Библиотека фильтрации веб-страниц',
    'https': 'HTTPS',
    'dns': 'DNS',
    'rules': 'Правила',
    'ip': 'IP-адрес',
    'routerMac': 'MAC-адрес маршрутизатора',
    'gatewayMac': 'MAC-адрес шлюза',
    'maxSession': 'Максимальное количество сеансов',
    'currentSession': 'Текущее количество сеансов',
    'tcpUdpIcmp': 'TCP/UDP/ICMP/TUNNEL',
    'autoUpdate': 'Автоматическое обновление',
    'manualUpgrade': 'Ручное обновление',
    'latestVersion': 'Последняя версия',
    'upgrading': 'Выполняется обновление...',
    'systemStatusHistory': 'История состояния системы',
    'cpuUsage': 'Загрузка ЦПУ за 24 часа',
    'memoryUsage': 'Использование памяти за 24 часа',
    'off': 'Выкл',
    'on': 'Вкл',
    'nowCheckVersion': 'Проверка текущей версии',
    'nowLatestVersion': 'У вас уже установлена последняя версия',
    'nowNewTestVersion': 'Вы используете новую тестовую версию',
    'upgradeLoadingText':
      'Идет обновление прошивки. Пожалуйста, не обновляйте страницу, дождитесь завершения обновления~',
    'upgradeSuccess': 'Обновление прошло успешно. Страница будет обновлена~',
    'upgradeError': {
      'noImage': 'Отсутствует файл обновления',
      'invalidImage': 'Недопустимый файл обновления',
      'imageArchMismatch': 'Файл обновления не соответствует устройству',
      'noResponse':
        'Устройство перезагружается. Пожалуйста, обновите страницу позже, чтобы убедиться в успешном обновлении',
      'alreadyLatest': 'У вас уже установлена последняя версия. Обновление не требуется~',
      'generic': 'Ошибка обновления~',
    },
    'copySuccess': 'Успешно скопировано',
    'copyFail': 'Не удалось скопировать',
  },
  // techSupport
  techSupport: {
    'remoteConnection': {
      'title': 'Удаленное подключение',
      'loading': 'Подключение...',
      'popupTitle': 'Код удаленного подключения',
      'intro':
        "Применимо в следующих случаях: <span>когда ваше устройство имеет нормальное сетевое подключение, но возникают проблемы с другими функциями.</span><br />Сгенерируйте 'Код удаленного подключения' и отправьте его нашей службе поддержки, чтобы наша техническая команда могла быстро определить и помочь вам решить проблему. Чтобы обеспечить действительность кода подключения, пожалуйста, избегайте повторной генерации в течение короткого времени.",
      'buttonText': 'Сгенерировать код',
      'generateFeatureCodeFailure': 'Не удалось сгенерировать код подключения',
      'tips':
        'Если у вас возникли проблемы, пожалуйста, отправьте нам подробную информацию в службу поддержки. Мы стремимся помочь вам и обеспечить решение вашей проблемы.',
      'confirm': 'Скопировать и закрыть',
      'copySuccess': 'Успешно скопировано в буфер обмена',
      'copyFailure': 'Не удалось скопировать в буфер обмена',
      'connectFailure': 'Не удалось установить удаленное подключение',
    },
    'logDownload': {
      'title': 'Загрузка журнала',
      'loading': 'Загрузка...',
      'intro':
        'Применимо в следующих случаях: <span>когда вы можете войти в панель управления, но возникают проблемы с сетевым подключением.</span><br />Вручную загрузите файл журнала и отправьте его нашей службе поддержки, чтобы мы могли понять вашу конкретную ситуацию и предоставить более эффективную помощь.',
      'buttonText': 'Загрузить',
      'downloadSuccess': 'Успешно загружено',
      'downloadFailure': 'Не удалось загрузить. Пожалуйста, повторите попытку позже.',
    },
    'supportServices': {
      'title': 'Получите необходимую помощь',
      'intro': 'Пожалуйста, предоставьте нам некоторую конкретную информацию, и мы предоставим вам подходящее решение.',
      'buttonText': 'Получить помощь',
    },
    'promiseTips': {
      'title': 'Обещание обеспечения безопасности:',
      'intro':
        'Будьте уверены, мы <span>строго ограничиваем функции удаленного подключения и загрузки журнала</span> только для диагностики и решения проблем, и никогда не используем их для других целей, чтобы обеспечить максимальную конфиденциальность и безопасность.',
    },
  },
  // builtinRules
  builtinRules: [
    {
      'label': 'Включить фильтрацию HTTPS (включая фильтрацию HTTP)',
      'sslbypass': 'Включить обход SSL',
      'value': 'youtube',
    },
  ],
  // customrules
  customrules: [
    {
      'label': 'Включить фильтрацию HTTPS (включая фильтрацию HTTP)',
      'value': 'youtube',
    },
  ],
  // tproxy
  tproxy: {
    'browserCacheWarning':
      'Если включен фильтр рекламы через HTTPS и реклама не фильтруется должным образом, попробуйте очистить кэш браузера. Если вы включили только фильтр HTTPS без установки сертификата, это не только не позволит фильтровать рекламу, но также может повлиять на нормальный доступ к веб-сайтам в списке.',
    'triggerRulesTipsTitle': 'Советы',
    'buttonAddRule': 'Добавить правило',
    'buttonInstallCertificate': 'Установить сертификат',
    'missingInfoWarning': 'Проверьте правильность заполнения',
    'missingInfoSuccess': 'Операция выполнена успешно',
    'missingInfoError': 'Ошибка операции',
    'certificateTip': 'Для нормального использования убедитесь, что сертификат установлен',
    'remind': 'Не напоминать',
    'tip': 'Для правильной блокировки рекламы необходимо выполнить следующие шаги:',
    'triggerRulesTips':
      'После включения фильтрации HTTPS для фильтрации рекламы на веб-сайтах из списка, вам необходимо установить и доверять корневой сертификат Deeper Network на своем компьютере.',
    'triggerRulesTips2':
      'Если вы включили только фильтрацию HTTPS без установки сертификата, это не только не позволит фильтровать рекламу, но также может повлиять на нормальный доступ к веб-сайтам в списке.',
    'mobileDownloadCertTips': 'Используйте браузер Safari для загрузки и установки сертификата',
    'androidTVTip': 'Если вы используете Android TV, загрузите приложение для использования',
    'errorMessage': {
      'maxLength': 'Максимальное количество правил - 1024',
      'ruleExisted': 'Правило с таким же доменом и выражением для блокировки уже существует',
    },
    'label': {
      'domain': 'Домен',
      'Pattern': 'Выражение для блокировки',
      'ruleType': 'Тип правила',
      'web': 'Веб-браузер',
      'app': 'Приложение',
      'all': 'Все',
      'Rewrite_Pattern': 'Выражение для перезаписи',
      'Rewrite_Info': 'Информация для перезаписи',
      'Rewrite_Action': 'Действие',
      'enable': 'Включить',
      'operation': 'Операция',
      'order': 'Порядок',
    },
    'operation': {
      'delete': 'Удалить',
      'edit': 'Редактировать',
    },
    'modal': {
      'title': 'Добавить правило',
      'confirmText': 'Подтвердить',
      'cancelText': 'Отмена',
    },
    'placeholder': {
      'domain': 'Введите правильный домен',
      'Pattern': 'Введите правильное выражение для блокировки',
      'ruleType': 'Выберите тип правила',
      'Rewrite_Pattern': 'Введите правильное выражение для перезаписи',
      'Rewrite_Info': 'Введите информацию для перезаписи',
      'Rewrite_Action': 'Выберите действие',
    },
    'builtinRules': {
      'title': 'Фильтрация рекламы',
      'filterListtitle': 'Список фильтрации HTTPS',
      'platform': 'Поддерживаемые платформы',
      'segOptions': ['Инструкция по установке сертификата', 'Список поддерживаемых веб-сайтов'],
    },
    'customerRules': {
      'title': 'Пользовательские HTTPS-правила',
    },
    'guide': {
      'buttonDownloadCertificate': 'Нажмите, чтобы скачать сертификат',
      'buttonDownloadApp': 'Нажмите, чтобы скачать SkyTube',
      'tab': {
        'mobileInstall': 'iPhone/iPad',
        'macInstall': 'Mac OS',
        'windowsInstall': 'Windows',
        'androidInstall': 'Android',
        'linuxInstall': 'Linux',
      },
      'mobile': {
        'videoTab': 'Видео',
        'textTab': 'Текст',
        'step1': {
          'label': 'Шаг 1',
        },
        'step2': {
          'label': 'Шаг 2',
          'tip': 'Нажмите Разрешить',
        },
        'step3': {
          'label': 'Шаг 3',
          'tip': 'Откройте Настройки на своем телефоне и нажмите Загруженные профили',
        },
        'step4': {
          'label': 'Шаг 4',
          'tip': 'Нажмите Установить и введите пароль от телефона',
        },
        'step5': {
          'label': 'Шаг 5',
          'tip': 'Нажмите Установить',
        },
        'step6': {
          'label': 'Шаг 6',
          'tip': 'Установка завершена',
        },
        'step7': {
          'label': 'Шаг 7',
          'tip':
            'Откройте Настройки > Общие > О телефоне > Настройки доверия сертификатам и включите установленный сертификат',
        },
      },
      'windows': {
        'step1': {
          'label': 'Шаг 1',
        },
        'step2': {
          'label': 'Шаг 2',
          'tip': "Нажмите на скачанный сертификат, затем нажмите 'Установить сертификат'.",
        },
        'step3': {
          'label': 'Шаг 3',
          'tip': "Выберите 'Место хранения' как 'Локальный компьютер' и нажмите 'Далее'.",
        },
        'step4': {
          'label': 'Шаг 4',
          'tip': "Выберите 'Поместить все сертификаты в следующем хранилище' и нажмите 'Обзор'.",
        },
        'step5': {
          'label': 'Шаг 5',
          'tip': "Выберите 'Центры сертификации' и нажмите 'ОК'.",
        },
        'step6': {
          'label': 'Шаг 6',
          'tip': "Нажмите 'Далее'.",
        },
        'step7': {
          'label': 'Шаг 7',
          'tip': "Нажмите 'Готово', установка сертификата завершена.",
        },
      },
      'mac': {
        'step1': {
          'label': 'Шаг 1',
        },
        'step2': {
          'label': 'Шаг 2',
          'tip':
            "Нажмите на скачанный сертификат, система покажет всплывающее окно 'Добавление сертификата', затем выберите 'Логин' в 'Ключевые цепочки' и нажмите 'Добавить'.",
        },
        'step3': {
          'label': 'Шаг 3',
          'tip': "В окне 'Доступ к ключам' выберите 'Логин' в боковой панели.",
        },
        'step4': {
          'label': 'Шаг 4',
          'tip':
            "Найдите сертификат 'deeper.network' и щелкните правой кнопкой мыши, затем выберите 'Показать информацию'.",
        },
        'step5': {
          'label': 'Шаг 5',
          'tip':
            "В разделе 'Информация' нажмите 'Доверие' и выберите 'Всегда доверять' для 'Использовать системные настройки'.",
        },
        'step6': {
          'label': 'Шаг 6',
          'tip': "Закройте окно информации, введите пароль компьютера и нажмите 'Обновить настройки'.",
        },
        'step7': {
          'label': 'Шаг 7',
          'tip': 'Установка сертификата завершена.',
        },
      },
      'linux': {
        'rootTab': 'Учебник по импорту корневого сертификата системы',
        'root': {
          'step1': {
            'label': 'Шаг 1',
          },
          'step2': {
            'label': 'Шаг 2',
            'tip':
              '<p>Скопируйте файл сертификата в /usr/local/share/ca-certificates/</p>\
              <p>Этот каталог специально используется для хранения дополнительных локально установленных CA сертификатов. Файл сертификата должен быть в формате PEM с расширением .crt.</p>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /usr/local/share/ca-certificates/</code></div>',
          },
          'step3': {
            'label': 'Шаг 3',
            'tip':
              '<p>Обновите хранилище сертификатов</p>\
              <p>Используйте команду update-ca-certificates для обновления хранилища сертификатов, это автоматически обновит цепочку доверия сертификатов в системе.</p>\
              <div class="code"><code>sudo update-ca-certificates</code></div>\
              <p>Эта команда прочитает файлы сертификатов в каталоге /usr/local/share/ca-certificates/ и добавит их в список доверенных системой.</p>\
              <em><em class="warning">Внимание:</em> В различных дистрибутивах Linux могут быть разные места хранения сертификатов и команды обновления.</em><br>\
              <em>Например, в системах на базе Red Hat\
              (таких как Fedora, CentOS) вам может потребоваться скопировать сертификат в /etc/pki/ca-trust/source/anchors/,</em><br>\
              <em>а затем выполнить команду update-ca-trust для обновления хранилища сертификатов.</em><br>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /etc/pki/ca-trust/source/anchors/</code><br>\
              <code>sudo update-ca-trust</code></div>\
              <p>После выполнения этих шагов установленный вами корневой сертификат должен быть доверен системой.</p>',
          },
        },
        'chrome': {
          'step1': {
            'label': 'Шаг 1',
          },
          'step2': {
            'label': 'Шаг 2',
            'tip': "Введите chrome://settings/certificates в адресной строке, затем выберите вкладку '<b>Власти</b>'.",
          },
          'step3': {
            'label': 'Шаг 3',
            'tip': "Нажмите 'Импорт', найдите ранее загруженный сертификат и нажмите 'Открыть'.",
          },
          'step4': {
            'label': 'Шаг 4',
            'tip':
              "В окне '<b>Сертификаты удостоверяющих центров</b>' отметьте все опции и нажмите 'ОК', чтобы завершить установку сертификата.",
          },
        },
        'firefox': {
          'step1': {
            'label': 'Шаг 1',
          },
          'step2': {
            'label': 'Шаг 2',
            'tip':
              "Введите about:preferences#privacy в адресной строке, найдите 'Сертификаты' и нажмите 'Просмотр сертификатов'.",
          },
          'step3': {
            'label': 'Шаг 3',
            'tip': "Выберите 'Удостоверяющие центры' и нажмите 'Импорт'.",
          },
          'step4': {
            'label': 'Шаг 4',
            'tip': "Выберите ранее загруженный сертификат и нажмите 'Открыть'.",
          },
          'step5': {
            'label': 'Шаг 5',
            'tip': "Отметьте все опции и нажмите 'ОК', установка сертификата завершена.",
          },
        },
      },
      'android': {
        'appTab': 'Приложение',
        'browserTab': 'Браузер',
        'app': {
          'step1': {
            'label': 'Шаг 1',
          },
          'step2': {
            'label': 'Шаг 2',
            'tip': 'Установите SkyTube и наслаждайтесь видео без рекламы.',
          },
        },
        'browser': {
          'step1': {
            'label': 'Шаг 1',
          },
          'step2': {
            'label': 'Шаг 2',
            'tip': "Откройте 'Настройки' на своем телефоне и найдите 'Безопасность'.",
          },
          'step3': {
            'label': 'Шаг 3',
            'tip': "Войдите в 'Безопасность' и найдите 'Шифрование и учетные данные', затем нажмите для входа.",
          },
          'step4': {
            'label': 'Шаг 4',
            'tip': "Нажмите 'Установить сертификат'.",
          },
          'step5': {
            'label': 'Шаг 5',
            'tip': 'Выберите сертификат Центра сертификации (CA).',
          },
          'step6': {
            'label': 'Шаг 6',
            'tip': "Нажмите 'Установить все равно' и введите пароль от телефона.",
          },
          'step7': {
            'label': 'Шаг 7',
            'tip': 'Выберите загруженный сертификат.',
          },
          'step8': {
            'label': 'Шаг 8',
            'tip': "Появится сообщение 'Установлен сертификат ЦС', установка сертификата успешно завершена.",
          },
        },
      },
    },
  },
  // SSLBypassCheckbox
  SSLBypassCheckbox: {
    'sslBypass': 'Включить функцию обхода SSL',
    'sslBypassTips': 'Разрешить подключение устройств без установленного сертификата к сети.',
    'sslBypassWaring': 'При включении функции обхода SSL может возникнуть пропускание рекламы.',
    'success': 'Успешно',
    'failed': 'Не удалось',
  },
  // tunnel
  tunnel: {
    'configuredTunnel': 'Настроенный туннель',
    countryTooltip:
      'Нечеткий поиск названия страны или введите код страны (заглавными буквами) и нажмите Enter, чтобы найти нужную страну.',
    'region': 'Регион',
    'country': 'Страна или регион',
    'activeTunnelNum': 'Количество активных IP',
    'activeIp': 'Активный IP',
    'switchNode': 'Переключить активный IP',
    'switchIpTitle': 'Переключить IP',
    'initTunnelTitle': 'Переинициализировать туннель',
    'initTunnelTitleTips':
      'У каждого туннеля есть 3 IP-адреса. При обновлении туннеля будут предоставлены три новых IP-адреса.',
    'selectRegion': 'Выберите регион',
    'selectCountry': 'Выберите страну или регион',
    'missingInfoWarning': 'Пожалуйста, заполните все поля',
    'tunnelAddSuccess': 'Туннель успешно добавлен. Страница будет обновлена через 3 секунды.',
    'tunnelExist': 'Туннель уже существует',
    'reachedMax': 'Достигнуто максимальное количество туннелей',
    'tunnelConflict': 'Туннель, который вы хотите добавить, конфликтует с существующим туннелем {0} - {1}',
    'tunnelAddError': 'Ошибка при добавлении туннеля',
    'tunnelDelSuccess': 'Туннель успешно удален. Страница будет обновлена через 3 секунды.',
    'tunnelNotExist': 'Туннель не существует',
    'usedByFullMode': 'Туннель используется полным маршрутом DPN и не может быть удален',
    usedByAccessControl: 'Туннели, подлежащие удалению, используются системой контроля доступа.',
    'usedByBackup': 'Резервный туннель использует этот туннель и не может быть удален',
    'tunnelInUse': 'Туннель используется в таблице маршрутизации и не может быть удален',
    'tunnelDelError': 'Ошибка при удалении туннеля',
    'switchNodeSuccess': 'Успешно переключено на другой узел. Страница будет обновлена через 3 секунды.',
    'noActiveIp': 'Нет доступных активных IP для переключения',
    'ipNotChanged': 'IP не был изменен',
    'switchNodeError': 'Ошибка при переключении узла',
    'refreshLabel': 'Обновить',
    'getNewIPLabel': 'Получить новый IP',
    'refreshError': 'Ошибка при обновлении туннеля',
    'refreshSuccess': 'Запрос на обновление туннеля успешно отправлен. Страница будет обновлена через 3 секунды.',
    'tips': {
      'refreshTunnel': 'Обновить активные туннели.',
      'configuredTunnel':
        'После добавления региональных туннелей, AtomOS будет искать узлы, соответствующие вашим требованиям, во всем мире.',
    },
    nodeLbMode: {
      'label': 'Режим выбора узла',
      'none': {
        'label': 'Обычный',
        'desc':
          'Внутри туннеля все сетевые подключения проходят через один активный узел, который можно изменить в любое время.',
      },
      'random': {
        'label': 'Случайный',
        'desc': 'Система автоматически выбирает случайный узел для каждого подключения.',
      },
      'domain': {
        'label': 'Учет домена',
        'desc':
          'Система использует несколько узлов внутри туннеля для доступа к сети, но запоминает и всегда маршрутизирует доступ к определенному домену через один узел, чтобы уменьшить риск ошибок при доступе к веб-сайтам.',
      },
      'rr': {
        'label': 'Поочередный выбор',
        'desc':
          'При каждом подключении система выбирает узел в порядке очереди из нескольких узлов, чтобы обеспечить лучшую сетевую производительность.',
      },
      'failure': 'Не удалось сохранить режим балансировки нагрузки узлов',
      'success': 'Режим балансировки нагрузки узлов успешно сохранен',
    },
  },
  // userConsent
  userConsent: {
    'notice':
      'Внимание: Общие функции отключены до вашего согласия с условиями использования и политикой конфиденциальности.',
    'consentStart': 'Я прочитал(а) и согласен(на) с ',
    'termsOfUseNotRead': 'Вы еще не ознакомились с условиями использования',
    'termsOfUseLink': 'https://shop.deeper.network/terms-of-service?lang=ru',
    'termsOfUseTitle': 'условиями использования',
    'consentAnd': ' и ',
    'privacyPolicyNotRead': 'Вы еще не ознакомились с политикой конфиденциальности',
    'privacyPolicyLink': 'https://shop.deeper.network/privacy-policy?lang=ru',
    'privacyPolicyTitle': 'политикой конфиденциальности',
    'confirmBtn': 'Подтвердить',
    'consentError': 'Ошибка подтверждения согласия',
  },
  // customConfigLang
  customConfigLang: {
    'missingInfoWarning': 'Отсутствует доменное имя',
    'invalidDomainError': 'Неверный формат доменного имени или слишком длинное имя',
    'whitelist': 'Белый список',
    'blacklist': 'Черный список',
    'domain': 'Доменное имя',
    'whitelistAddSuccess': 'Успешно добавлено в белый список',
    'whitelistEntryExist': 'Элемент уже присутствует в белом списке',
    'reachedWhiteMax': 'Достигнут максимальный размер белого списка',
    'whitelistAddError': 'Не удалось добавить элемент в белый список',
    'blacklistAddSuccess': 'Успешно добавлено в черный список',
    'blacklistEntryExist': 'Элемент уже присутствует в черном списке',
    'reachedBlackMax': 'Достигнут максимальный размер черного списка',
    'blacklistAddError': 'Не удалось добавить элемент в черный список',
    'whitelistDelSuccess': 'Элемент белого списка успешно удален',
    'whitelistDelError': 'Не удалось удалить элемент белого списка',
    'blacklistDelSuccess': 'Элемент черного списка успешно удален',
    'blacklistDelError': 'Не удалось удалить элемент черного списка',
    'import': 'Импорт',
    'fileButtonText': 'Нажмите, чтобы загрузить',
    'fileSizeOverLimit': 'Размер загружаемого файла превышает максимальный размер 2 МБ',
    'whitelistImportSuccess': 'Успешно импортировано в белый список. Импортировано {0} элементов',
    'whitelistImportError': 'Не удалось импортировать белый список',
    'blacklistImportSuccess': 'Успешно импортировано в черный список. Импортировано {0} элементов',
    'blacklistImportError': 'Не удалось импортировать черный список',
    'noValidEntry': 'Загруженный файл не содержит допустимых доменных имен',
    'zeroImported': 'Не удалось импортировать ни одного элемента',
  },
  // defaultConfigLang
  defaultConfigLang: {
    'browserCacheWarning':
      'Если при включении DNS - фильтра не удается должным образом отфильтровать рекламу, необходимо очистить кэш браузера и перезапустить отфильтрованное устройство.',
    'queriesFor24Hours': 'Запросы за последние 24 часа',
    'totalQuery': 'Общее количество запросов',
    'blockedQuery': 'Заблокированные запросы',
    'blockedPercentage': 'Процент заблокированных',
    'ruleTotal': 'Всего правил',
    'adQuery': 'Блокировка рекламных запросов',
    'trackerQuery': 'Блокировка запросов трекеров',
    'maliciousQuery': 'Блокировка вредоносных запросов',
    'pornQuery': 'Блокировка запросов порно',
    'legendTotal': 'Всего',
    'legendBlocked': 'Заблокировано',
    'categoryStateEnabled': 'Включено',
    'categoryStateDisabled': 'Отключено',
    'categoryState2Hours': 'Отключено на 2 часа',
    'categoryState4Hours': 'Отключено на 4 часа',
    'categoryState8Hours': 'Отключено на 8 часов',
    'setCategoryStatesErr': 'Ошибка установки состояний категорий',
    'enableAll': 'Включить все фильтры',
    'disableAll': 'Отключить все фильтры',
  },
  // wifiSettings
  wifiSettings: {
    'workMode': 'Режим работы',
    'currentWorkMode': 'Текущий режим работы',
    'upstreamTitle': 'Настройки входящего потока',
    'backHome': 'Вернуться на главную',
    'wire-ap': 'Проводное подключение WAN',
    'wlan-ap': 'Беспроводное подключение WAN',
    'downstreamTitle': 'Беспроводное подключение LAN',
    'notConnect': 'Нет подключения к Интернету',
    'apConfigWarning':
      'После сохранения SSID и пароля Wi-Fi, если вы хотите подключиться к Интернету через устройство Deeper, вам нужно будет повторно сканировать точку доступа Deeper и применить пароль',
    'attention': 'Внимание',
    'buttons': {
      'scanWifi': 'Сканировать',
      'connectStatus': 'Статус',
      add: 'Добавить',
      'save': 'Сохранить',
      'cancel': 'Отменить',
      'downloadLog': 'Скачать журнал',
    },
    'connect': 'Подключить',
    'forget': 'Забыть',
    'waiting': {
      'content': 'Подключение, пожалуйста, подождите...',
      'cancelBtn': 'Отменить подключение',
    },
    'modals': {
      'hiddenWifi': {
        'title': 'Добавить скрытую Wi-Fi',
        'okBtn': 'Добавить',
      },
      'scanWifi': {
        'title': 'Предупреждение',
        'content': 'Сеть будет временно отключена',
        'ok': 'Oklahoma',
        'cancel': 'Отменить',
      },
    },
    'label': {
      'ssid': 'Имя Wi-Fi',
      'password': 'Пароль Wi-Fi',
      'onlyUse': 'Только использовать',
      'hidden': 'Скрытый SSID',
      'freqBand': 'Частотный диапазон',
    },
    'placeholder': {
      'ssid': 'Введите SSID',
      'password': 'Введите пароль',
    },
    'message': {
      'saveSuccess': 'Сохранено успешно',
      'saveError': 'Не удалось сохранить, попробуйте снова',
      'connectSuccess': 'Подключение успешно',
      'forgetSuccess': 'Забыто успешно',
      'inputError': 'Проверьте введенные данные',
      'invalidWifiDev': 'В настоящее время нет доступных устройств Wi-Fi, невозможно выполнить настройку Wi-Fi',
      'illegalWifiDev': 'Настройка Wi-Fi доступна только в режимах AP Router и Wireless Relay',
      'invalidSsid': 'Поддерживаются только буквы, цифры, _ и -',
      'invalidPassword': 'Введите допустимый пароль',
      'notConnect': 'Нет подключения к Интернету',
      'retry': 'Пожалуйста, повторите попытку',
      'connectionError': 'Ошибка подключения',
      'forgetError': 'Ошибка при забывании',
      'wifiNotSupported': 'Wi-Fi не поддерживается',
      'networkNotFound': 'Сеть не найдена',
      'pskNotGenerated': 'PSK не сгенерирован',
      'wrongPassword': 'Неправильный пароль',
      'timeout': 'Время подключения истекло',
      'logDownloadError': 'Ошибка загрузки журнала',
      'hasBeenConnected': 'Подключено.',
      'dhcpSuccess': 'Успешно',
      'dhcpFailure': 'Ошибка',
    },
    'connectionRequestedTitle': 'Запрос на подключение',
    'connectionRequestedContent':
      'Проверьте свое состояние подключения в разделе «Статус». Если вы отключены от Deeper Connect, попробуйте переподключиться к DeeperWiFi.',
    'gotIpFromDhcpLabel': 'Получен IP-адрес от DHCP',
  },
  // parentalControlData
  parentalControlData: {
    'browserCacheWarning':
      'Если функция родительского контроля не может правильно блокировать сайты для взрослых или социальные медиа, вам нужно очистить кэш браузера и перезагрузить отфильтрованное устройство.',
    'customerRule':
      'Здесь вы можете заблокировать большинство сайтов для взрослых и социальных сетей для своих детей. Если вы хотите заблокировать другие сайты, вы можете использовать функцию блокировки пользовательских доменов для добавления дополнительных сайтов.',
    'customerRuleClickTips': 'Пожалуйста, {0}, чтобы перейти на страницу блокировки пользовательских доменов',
    'toCustomerRuleButtonText': 'нажмите здесь',
    'parentalControl': 'Родительский контроль',
    'pornQuery': 'Блокировка порнографии',
    'socialQuery': 'Блокировка социальных сетей',
    'legendBlocked': 'Заблокировано',
    'socialStateEnabled': 'Включено',
    'socialStateDisabled': 'Отключено',
    'socialState2Hours': 'Отключено на 2 часа',
    'socialState4Hours': 'Отключено на 4 часа',
    'socialState8Hours': 'Отключено на 8 часов',
    'pornStateEnabled': 'Включено',
    'pornStateDisabled': 'Отключено',
    'pornState2Hours': 'Отключено на 2 часа',
    'pornState4Hours': 'Отключено на 4 часа',
    'pornState8Hours': 'Отключено на 8 часов',
    'setCategoryStatesErr': 'Ошибка при установке состояний категорий',
  },
  reboot: {
    success: {
      saveConfig: 'Успешно сохранено',
    },
    failure: {
      getConfig: 'Не удалось получить конфигурацию автоматической перезагрузки',
      saveConfig: 'Не удалось сохранить конфигурацию автоматической перезагрузки',
    },
    buttons: {
      reboot: 'Нажмите, чтобы перезагрузить',
      save: 'Сохранить',
    },
    week: {
      0: 'Каждый день',
      1: 'Понедельник',
      2: 'Вторник',
      3: 'Среда',
      4: 'Четверг',
      5: 'Пятница',
      6: 'Суббота',
      7: 'Воскресенье',
    },
    title: {
      manual: 'Перезагрузить сейчас',
      auto: 'Запланированная перезагрузка',
    },
    label: {
      period: 'Период',
      time: 'Время',
    },
  },
};
