export default {
  type: 'spain',
  internationalize: {
    type: 'spain',
    common: {
      tip_text: 'Información',
      search: 'Buscar',
      add: 'Añadir',
      delete: 'Eliminar',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
      warning: 'Advertencia',
      utcDisclaimer: 'La fecha es en UTC',
      toOpen: 'abrir',
    },
    certificateDialog: {
      title: 'Nuevo certificado de CA raíz',
      content: 'El certificado de CA raíz ha sido reemplazado. Por favor, descárguelo de nuevo e instálelo.',
      dontShowAgain: 'No mostrar de nuevo',
    },
    request_tip: {
      error: 'Error de solicitud~',
    },
    header: {
      logout: 'Salida',
      changePassword: 'Cambio de contraseña',
      userManual: 'Guía del usuario',
      defaultAvatar: 'Avatar por defecto',
      'english': 'Inglés',
      'german': 'Alemán',
      'korean': 'Coreano',
      'french': 'Francés',
      'spain': 'Español',
      'portugal': 'Portugués',
      'japanese': 'Japonés',
      'Tchinese': 'Chino tradicional',
      'russian': 'Ruso',
      'hindi': 'Hindi',
      'simplechinese': 'Chino simplificado',
    },
    home: {
      name: 'Inicio',
      children: {},
      content: {
        tipInput: 'Por favor ingrese',
        ok: 'Aceptar',
        cancel: 'Cancelar',
        currentStatus: 'Estado actual',
        statusMap: {
          Trial: 'Prueba',
          Valid: 'Válido',
          Expired: 'Caducado',
        },
        btnActive: 'Activar',
        btnEdit: 'Modificar',
        expireDate: 'Fecha de caducidad',
        licenseKey: 'Número de licencia',
      },
    },
    accessControl: {
      name: 'Control de acceso',
    },
    'tpProxy': {
      'name': 'Transparencia del agente',
      'children': {
        'customRules': {
          'name': 'Reglas personalizadas',
        },
        'builtinRules': {
          'name': 'Filtro de anuncios',
        },
        'certificate': {
          'name': 'Instalar certificado',
        },
      },
    },
    'help': {
      'name': 'Ayuda',
      'children': {
        'techSupport': {
          'name': 'Soporte técnico',
        },
      },
    },
    'device': {
      'name': 'Dispositivo local',
      'children': {
        'system': {
          'name': 'Información del sistema',
        },
        'traffic': {
          'name': 'Tráfico',
        },
        'notification': {
          'name': 'Registros',
        },
      },
      'content': {},
    },
    'dpn': {
      'name': 'DPN',
      'content': {},
      'children': {
        'smartRoute': {
          'name': 'Modo de trabajo',
        },
        'tunnel': {
          'name': 'Túnel',
        },
        'routingTable': {
          'name': 'Tabla de enrutamiento inteligente',
          'content': {},
          'children': {
            'domainConfig': {
              'name': 'Configuración de dominio personalizado',
            },
            'ipConfig': {
              'name': 'Configuración de IP personalizada',
            },
            'queryRouting': {
              'name': 'Búsqueda',
            },
          },
        },
      },
    },
    'appRelocator': {
      'name': 'Reubicación de aplicaciones',
    },
    'adsBlock': {
      'name': 'Bloqueo de anuncios',
      'children': {
        'domainFilter': {
          'name': 'Filtrado DNS',
        },
        'httpsFilter': {
          'name': 'Filtrado HTTPS',
        },
      },
      httpssucceed: 'El filtrado HTTPS se ha habilitado correctamente',
      httpslose: 'El filtrado HTTPS se ha deshabilitado correctamente',
      saverror: 'Operación fallida',
      usedByAccessControl: 'El control de acceso utiliza el filtro HTTPS',
    },
    'dpr': {
      'name': 'Minería',
      'children': {
        'dpr': {
          'name': 'DPR',
          'children': {
            'stakingTutorial': {
              'name': 'Tutorial de participación',
            },
            'wallet': {
              'name': 'Billetera',
              'manager': 'Administración de billetera',
              'balance': 'Saldo de la billetera',
            },
            'Staking Mining': 'Minería de participación',
            'transaction': {
              'id': 'ID de transacción',
              'from': 'Dirección de envío',
              'to': 'Dirección de recepción',
              'time': 'Hora de transferencia (UTC)',
              'rewardTime': 'Hora de recompensa (UTC)',
              'amount': 'Cantidad de DPR',
              'Reward in selected period': 'Recompensa en el período seleccionado',
              'Total reward': 'Recompensa total',
              'Balance Transfer': 'Transferencia de saldo',
              'Staking Reward': 'Recompensa de participación',
              'PoS Reward': 'Recompensa de PoS',
              'Micro Payment': 'Microtransacción',
              'stakeRelease': 'Transacción de liberación de participación',
              'Transaction': 'Historial de transacciones',
              'Mining': 'Minería',
              'Traffic Mining': 'Minería de tráfico',
              'Staking Mining': 'Minería de participación',
            },
          },
        },
      },
    },
    'security': {
      'name': 'Seguridad',
      'children': {
        'applications': 'Control de aplicaciones',
        'traffic': 'Control de tráfico',
        'webfilter': {
          'name': 'Filtro web',
          'children': {
            'report': {
              'name': 'Configuración predeterminada',
            },
            'config': {
              'name': 'Filtro de anuncios DNS',
            },
          },
        },
        'sslFilter': {
          'name': 'Filtro SSL',
          'children': {
            'customDNSrules': {
              'name': 'Reglas DNS personalizadas',
            },
            'customHTTPSRules': {
              'name': 'Reglas HTTPS personalizadas',
            },
          },
        },
      },
      'content': {},
    },
    'parentalControl': {
      'name': 'Control parental',
    },
    regionCountryTexts: {
      'LL': 'no pasa nada',
      'ANY': 'cualquier país o región',
      // regions
      'AMER': '--- América ---',
      'ASIA': '--- Asia ---',
      'AFRI': '--- África ---',
      'EURO': '--- Europa ---',
      'OCEA': '--- Océano ---',
      'AMN': 'Norte América',
      'AMC': 'el Caribe',
      'AMM': 'Centroamérica',
      'AMS': 'Sudamérica',
      'ASC': 'Asia Central',
      'ASE': 'Asia Oriental',
      'ASW': 'Asia Occidental',
      'ASS': 'Asia del Sur',
      'ASD': 'Asia del Sudeste',
      'AFN': 'Norte de África',
      'AFM': 'Centro de África',
      'AFE': 'Este de África',
      'AFW': 'Oeste de África',
      'AFS': 'Sur de África',
      'EUN': 'Noroeste Europeo',
      'EUE': 'Europa del Este',
      'EUW': 'Europa del Oeste',
      'EUS': 'Europa del Sur',
      'OCP': 'Polinésio de Polynésie',
      'OCA': 'Oceánia Austral y Antártica',
      'OCM': 'Melanesia',
      'OCN': 'Micronesia',
      // countries
      'AD': 'Andorra',
      'AE': 'Futbol Argentino',
      'AF': 'Afganistán',
      'AG': 'Antigua y Barbuda',
      'AI': 'Anguilla',
      'AL': 'Albania',
      'AM': 'Armenia',
      'AO': 'Angola',
      'AR': 'Argentina',
      'AS': 'Samoa Americana',
      'AT': 'Austria',
      'AU': 'Australia',
      'AW': 'Aruba',
      'AX': 'Islas de Malvinas',
      'AZ': 'Azerbaiyán',
      'BA': 'Bosnia y Herzegovina',
      'BB': 'Barbados',
      'BD': 'Bangladesh',
      'BE': 'Bélgica',
      'BF': 'Burkina Faso',
      'BG': 'Bulgaria',
      'BH': 'Baréin',
      'BI': 'Burundi',
      'BJ': 'Benín',
      'BL': 'Saint Barthélemy',
      'BM': 'Bermuda',
      'BN': 'Brunéi Darussalam',
      'BO': 'Bolivia',
      'BQ': 'Caribbean Netherlands',
      'BR': 'Brasil',
      'BS': 'Bahamas',
      'BT': 'Bhutan',
      'BW': 'Botswana',
      'BY': 'Bielorrusia',
      'BZ': 'Belice',
      'CA': 'Canadá',
      'CC': 'Islas Cocos (Keeling)',
      'CD': 'Congo (Kinshasa)',
      'CF': 'Centroafrica',
      'CG': 'Congo (Brazzaville)',
      'CH': 'Suiza',
      'CI': 'Costa de Marfil',
      'CK': 'Islas Cook',
      'CL': 'Chile',
      'CM': 'Camerún',
      'CN': 'República Popular China',
      'CO': 'Colombia',
      'CR': 'Costa Rica',
      'CU': 'Cuba',
      'CV': 'Cabo Verde',
      'CW': 'Curazao',
      'CX': 'Isla Christmas',
      'CY': 'Síria',
      'CZ': 'Checa',
      'DE': 'Alemania',
      'DJ': 'Djibouti',
      'DK': 'Dinamarca',
      'DM': 'Dominica',
      'DO': 'Dominica',
      'DZ': 'Algeria',
      'EC': 'Ecuador',
      'EE': 'Estonia',
      'EG': 'Egipto',
      'ER': 'Eritrea',
      'ES': 'España',
      'ET': 'Etiopía',
      'FI': 'Finlandia',
      'FJ': 'Fiji',
      'FK': 'Islas Malvinas',
      'FM': 'Micronesia',
      'FO': 'Islas Faroe',
      'FR': 'Francia',
      'GA': 'Gabón',
      'GB': 'Reino Unido',
      'GD': 'Granada',
      'GE': 'Georgia',
      'GF': 'Guayana Francesa',
      'GG': 'Guernsey',
      'GH': 'Ghana',
      'GI': 'Xristina',
      'GL': 'Grencia',
      'GM': 'Gambia',
      'GN': 'Guinea',
      'GP': 'Guadalupe',
      'GQ': 'Guinea Ecuatorial',
      'GR': 'Grecia',
      'GS': 'Georgia del Sur y Sandwich del Sur',
      'GT': 'Guatemala',
      'GU': 'Guam',
      'GW': 'Guinea-Bissau',
      'GY': 'Guyana',
      'HK': 'Hong Kong',
      'HN': 'Honduras',
      'HR': 'Croacia',
      'HT': 'Haití',
      'HU': 'Hungría',
      'ID': 'Indonesia',
      'IE': 'Irlanda',
      'IL': 'Israel',
      'IM': '马恩岛',
      'IM': 'Islas Malvinas',
      'IN': 'India',
      'IO': 'Territorio Británico del Océano Ingles',
      'IQ': 'Iraq',
      'IR': 'Irán',
      'IS': 'Islandia',
      'IT': 'Italia',
      'JE': 'Jersey',
      'JM': 'Jamaica',
      'JO': 'Jordania',
      'JP': 'Japón',
      'KE': 'Kenia',
      'KG': 'Kirgizistán',
      'KH': 'Camboya',
      'KI': 'Kiribati',
      'KM': 'Comoras',
      'KN': 'San Cristóbal y Nieves',
      'KR': 'Corea Sur',
      'KW': 'Kuwait',
      'KY': 'Islas Caimán',
      'KZ': 'Kazajistán',
      'KP': 'Corea del Norte',
      'LA': 'Laos',
      'LB': 'Líbano',
      'LC': 'Santa Lucía',
      'LI': 'Liechtenstein',
      'LK': 'Sri Lanka',
      'LR': 'Liberia',
      'LS': 'Lesoto',
      'LT': 'Lituania',
      'LU': 'Luxemburgo',
      'LV': 'Letonia',
      'LY': 'Libia',
      'MA': 'Marruecos',
      'MC': 'Mónaco',
      'MD': 'Moldavia',
      'ME': 'Montenegro',
      'MF': 'Saint-Martin (partie française)',
      'MG': 'Madagascar',
      'MH': 'Islas Marshall',
      'MK': 'Macedonia del Norte',
      'ML': 'Mali',
      'MM': 'Myanmar',
      'MN': 'Mongolia',
      'MO': 'Macao',
      'MP': 'Islas Marianas del Norte',
      'MQ': 'Martinica',
      'MR': 'Mauritania',
      'MS': 'Montserrat',
      'MT': 'Malta',
      'MU': 'Mauricio',
      'MV': 'Maldivas',
      'MW': 'Malawi',
      'MX': 'México',
      'MY': 'Malasia',
      'MZ': 'Mozambique',
      'NA': 'Namibia',
      'NC': 'Nueva Caledonia',
      'NE': 'Níger',
      'NF': 'Islas Norteaméricas del Sur',
      'NG': 'Nigeria',
      'NI': 'Nicaragua',
      'NL': 'Países Bajos',
      'NO': 'Noruega',
      'NP': 'Nepal',
      'NR': 'Nauru',
      'NU': 'Niue',
      'NZ': 'Nueva Zelanda',
      'OM': 'Omán',
      'PA': 'Panamá',
      'PE': 'Perú',
      'PF': 'Islas Francesas del Pacífico',
      'PG': 'Papúa Nueva Guinea',
      'PH': 'Filipinas',
      'PK': 'Pakistan',
      'PL': 'Polonia',
      'PM': 'Saint Pierre et Miquelon',
      'PN': 'Islas Pitcairn',
      'PR': 'Puerto Rico',
      'PS': 'Palestina',
      'PT': 'Portugal',
      'PW': 'Palau',
      'PY': 'Paraguay',
      'QA': 'Qatar',
      'RE': 'Reunión',
      'RO': 'Rumanía',
      'RS': 'Serbia',
      'RU': 'Rusia',
      'RW': 'Ruanda',
      'SA': 'Arabia Saudita',
      'SB': 'Islas Salomón',
      'SC': 'Seychelles',
      'SD': 'Sudan',
      'SE': 'Suecia',
      'SG': 'Singapur',
      'SH': 'Santa Elena, Ascensión y Tristán da Cunha',
      'SI': 'Eslovaquia',
      'SJ': 'Svalbard y Jan Mayen',
      'SK': 'Eslovaquia',
      'SL': 'Sierra Leone',
      'SM': 'San Marino',
      'SN': 'Senegal',
      'SO': 'Somalia',
      'SR': 'Surinam',
      'SS': 'El Sudán del Sur',
      'ST': 'Santo Tomé y Príncipe',
      'SV': 'El Salvador',
      'SX': 'Sint Maarten',
      'SY': 'Siria',
      'SZ': 'Eswatini',
      'TC': 'Islas Turks y Caicos',
      'TD': 'Chad',
      'TF': 'Territorios franceses del Sur y Antártida',
      'TG': 'Togo',
      'TH': 'Tailandia',
      'TJ': 'Tayikistán',
      'TK': 'Tokelau',
      'TL': 'Timor Oriental',
      'TM': 'Turkmenistán',
      'TN': 'Túnez',
      'TO': 'Tonga',
      'TR': 'Turquía',
      'TT': 'Trinidad y Tobago',
      'TV': 'Tuvalu',
      'TW': 'Taiwán',
      'TZ': 'Tanzania',
      'UA': 'Ucrania',
      'UB': 'Oeste de EE. UU.',
      'UC': 'Medio Oeste de EE. UU.',
      'UD': 'Suroeste de EE. UU.',
      'UE': 'Noreste de EE. UU.',
      'UF': 'Sureste de EE. UU.',
      'UG': 'Uganda',
      'US': 'EE. UU.',
      'UY': 'Uruguay',
      'UZ': 'Uzbekistán',
      'VA': 'Ciudad del Vaticano',
      'VC': 'San Vicente y las Granadinas',
      'VE': 'Venezuela',
      'VG': 'Islas Vírgenes del Caribe',
      'VI': 'Islas Vírgenes de los Estados Unidos',
      'VN': 'Vietnam',
      'VU': 'Vanuatu',
      'WF': 'Wallis y Futuna',
      'WS': 'Samoa',
      'XK': 'República de Kosovo',
      'YE': 'Yemen',
      'YT': 'Mayotte',
      'ZA': 'Sudáfrica',
      'ZM': 'Zambia',
      'ZW': 'Zimbabwe',
    },
    setting: {
      'name': 'Configuración',
      'children': {
        'wifi': {
          'name': 'Wi-Fi relacionados',
        },
        'sharing': {
          'name': 'Compartición relacionadas',
          'children': {
            'configure': {
              'name': 'Configuración de compartición',
            },
            'blacklist': {
              'name': 'Compartir lista negra',
            },
            'record': {
              'name': 'Registro de compartición',
            },
            'traffic': {
              'name': 'Tráfico de compartición',
            },
          },
          'content': {},
        },
        'rustdesk': {
          'name': 'RustDesk',
          'children': {
            'config': {
              'name': 'Configuración',
            },
            'log': {
              'name': 'Registro',
            },
          },
        },
        'admin': {
          'name': 'Gestión de cuentas',
          'content': {},
          'children': {
            'changePassword': {
              'name': 'Contraseña',
            },
            'reboot': {
              'name': 'Reiniciar',
              'content': {
                'confirmText': '¿Confirmar reiniciar?',
                'rebootLoading':
                  'El dispositivo necesitará unos minutos para reiniciarse y su conexión a la red se verá interrumpida...',
              },
            },
          },
        },
      },
    },
  },
  // dashboard
  dashboard: {
    'name': 'Inicio',
    'children': {},
    'content': {
      'backupTunnelLabel': 'Túnel de reserva',
      'tipInput': 'Por favor, introduzca',
      'hotspot': 'Conexión global',
      'realnetworkflow': 'Flujo de red real',
      'memory': 'Memoria ocupada',
      'cpu': 'Uso del CPU',
      'notification': 'Notificaciones del sistema',
      'systeminfo': 'Información del sistema',
      'upload': 'Subir',
      'download': 'Descargar',
      'systemuptime': 'Tiempo de funcionamiento del sistema',
      'uploadtotal': 'Total de subidas',
      'downloadtotal': 'Total de descargas',
      'softwareVersion': 'Versión del software',
      'publicIp': 'Dirección IP pública',
      'unit': 'Unidad',
      'currentDpnMode': 'Modo DPN actual',
      'ok': 'Aceptar',
      'cancel': 'Cancelar',
      'currentStatus': 'Estado actual',
      'statusMap': {
        'Trial': 'Prueba',
        'Valid': 'Válido',
        'Expired': 'Caducado',
      },
      'btnActive': 'Activar',
      'btnEdit': 'Editar',
      'expireDate': 'Fecha de caducidad',
      'licenseKey': 'Número de clave de licencia',
      'systemTraffic': 'Flujo de red del sistema en tiempo real',
      'appTraffic': 'Flujo de red de la aplicación en tiempo real',
    },
  },
  accessControl: {
    'content': {
      banner: {
        title: 'Control de acceso',
        description:
          'Este interruptor se utiliza para controlar uniformemente la activación o desactivación de varias funciones del dispositivo LAN.',
      },
      'onlineDevice': 'Dispositivo en línea',
      'offlineDevice': 'Dispositivo fuera de línea',
      'deviceName': 'Nombre del dispositivo',
      'unknownDevice': 'Desconocido',
      'macAddress': 'Dirección MAC',
      'ipAddress': 'Dirección IP',
      'enableHttpsFilter': 'Habilitar filtro HTTPS',
      'routeMode': 'Modo DPN',
      'routeModeOptions': {
        'smart': 'Modo de enrutamiento inteligente',
        'full': 'Enrutamiento completo',
        'disabled': 'Deshabilitado',
        blackHole: 'Agujero negro',
      },
      'remark': 'Observación',
      pinned: 'Anclar en la parte superior',
      'httpsFilterTip': 'Una vez habilitado el filtro HTTPS, se filtrarán todos los anuncios.',
      'routeModeTip': 'Esta opción permite cambiar el modo de funcionamiento de DPN.',
      'tunnelTip': 'Seleccione un túnel de enrutamiento completo.',
      'success': {
        getList: 'Lista de dispositivos actualizada correctamente',
        'httpsFilter': 'Filtro HTTPS cambiado correctamente',
        'routeMode': 'Modo DPN cambiado correctamente',
        'regionCode': 'Modo DPN cambiado correctamente',
        'remark': 'Observación guardada correctamente',
        pinned: 'Anclado / Desanclado con éxito. Por favor, actualiza',
        'deleteDevices': 'Eliminación exitosa del dispositivo',
      },
      'failure': {
        'getSwitch': 'Error al obtener el interruptor de control de acceso',
        'setSwitch': 'Error al establecer el interruptor de control de acceso',
        'getList': 'Error al obtener la lista de control de acceso',
        'getTunnels': 'Error al obtener los túneles',
        'setItem': 'Error al establecer el elemento de control de acceso',
        'httpsFilter': 'Error al habilitar el filtro HTTPS',
        'routeMode': 'Error al cambiar el modo DPN',
        'regionCode': 'Error al cambiar el modo DPN',
        'remark': 'Error al guardar la observación',
        'noSuchTunnel': 'El túnel no existe, por favor elige otro túnel',
        pinned: 'Error al anclar / desanclar',
        'deleteDevices': 'Error al eliminar el dispositivo',
        'macActive': 'No se puede eliminar debido a que el dispositivo está activo',
      },
      'remarkLengthLimit': 'La longitud no debe exceder los 10 caracteres',
      'httpsNotEnabled':
        'El interruptor principal para el filtrado HTTPS está apagado. ¡Haga clic aquí para ir a esta página y encenderlo!',
      'tips': {
        'opened':
          'Cuando el control de acceso está activado, los dispositivos en línea utilizan preferentemente el modo DPN en el control de acceso.',
      },
    },
  },
  //dpnMode
  dpnMode: {
    title: 'Seleccionar modo DPN',
    selectTunnel: 'Seleccionar túnel',
    recommended: 'Recomendado',
    apply: 'Aplicar',
    missingInfo: 'Falta información',
    selectionTip: {
      smart: '¿Está seguro de que desea cambiar al modo de enrutamiento inteligente DPN?',
      full:
        'Todo el tráfico pasará por el túnel seleccionado. ¿Está seguro de que desea cambiar al modo de enrutamiento completo?',
      disabled:
        'Este modo desactivará temporalmente la red DPN. ¿Está seguro de que desea cambiar al modo de desactivación de DPN?',
    },
    dpnModeShortList: {
      smart: 'Enrutamiento inteligente DPN',
      disabled: 'DPN desactivado',
    },
    dpnModeLongList: {
      smart: 'Enrutamiento inteligente',
      full: 'Enrutamiento completo',
      disabled: 'Desactivado',
    },
    killSwitchLabel: 'Interruptor de apagado',
    none: 'Apagado',
    backupTunnelLabel: 'Túnel de respaldo',
    backupTunnelTip:
      'Cuando se produce un problema con el túnel configurado en el modo de enrutamiento inteligente, el sistema cambiará automáticamente al túnel de respaldo hasta que se restablezca el túnel predeterminado.',
    tips: {
      killSwitch:
        'La función de protección contra la desconexión de VPN se refiere a que cuando se desconecta la conexión VPN, se desconectará automáticamente la conexión a Internet de su computadora, teléfono móvil, tableta u otros dispositivos. Esto significa que si su VPN deja de funcionar, su dirección IP y otra información confidencial no se expondrán. Por lo tanto, la seguridad y el anonimato de su conexión a Internet no se verán afectados.',
      smart: [
        '1. Según las reglas integradas de AtomOS, se asigna un nodo inteligente al sitio web que necesita visitar actualmente para garantizar la velocidad de acceso a la red local y acelerar el acceso a los servicios en otras regiones del mundo.',
        '2. En el modo de enrutamiento inteligente, no solo se puede evitar la molestia de cambiar constantemente de país como en una VPN tradicional, sino que también se puede proporcionar a los usuarios una experiencia de acceso a Internet más rápida.',
        '3. Deeper Connect tiene algunas reglas de enrutamiento para sitios y servicios conocidos integrados. Si desea personalizar las reglas de enrutamiento inteligente, puede configurarlas en “Reubicación de aplicaciones” y “Tabla de enrutamiento DPN”.',
        '4. Continuaremos agregando reglas de enrutamiento integradas según los comentarios de los usuarios y admitiendo más aplicaciones en “Reubicación de aplicaciones”.',
      ],
      full: [
        '1. Todos los datos de red procesados por AtomOS utilizarán el túnel especificado, lo que es similar al modo de uso de una VPN tradicional.',
        '2. Nota: El uso del modo de enrutamiento completo anulará la configuración en “Reubicación de aplicaciones” y “Tabla de enrutamiento DPN”.',
        '3. Debido a las características de la red descentralizada, los nodos en la red provienen de dispositivos de usuarios de todo el mundo, no de servidores de alta velocidad. Por lo tanto, la velocidad de su conexión a Internet puede verse afectada.',
        '4. Si desea utilizar el modo de enrutamiento completo y desea tener una experiencia de navegación de alta velocidad, consideraremos proporcionar nodos de alta velocidad específicos para usuarios VIP en el futuro.',
      ],
      disabled: [
        '1. “DPN desactivado” desactivará la función DPN y todos los datos de red procesados por AtomOS utilizarán el tráfico local.',
        '2. Las demás funciones de seguridad de red de Deeper Connect seguirán activas.',
        '3. Si encuentra obstáculos al acceder a ciertos sitios web, puede desactivar DPN para confirmar si se trata de un problema del sitio web o de la función DPN.',
        '4. Si encuentra algún problema con la función DPN, puede informarnos del problema a través del siguiente enlace: https://support.deeper.network/hc/en-us/requests/new',
      ],
    },
    success: 'Operación exitosa',
    failure: '¡Operación fallida!',
  },
  // DefaultadBlock
  DefaultadBlock: {
    browserCacheWarning:
      'Si se habilita la filtración de anuncios DNS y no se puede filtrar correctamente los anuncios, es necesario limpiar la caché del navegador y reiniciar el dispositivo filtrado.',
    queriesFor24Hours: 'Solicitudes de las últimas 24 horas',
    totalQuery: 'Total de solicitudes',
    blockedQuery: 'Solicitudes bloqueadas',
    blockedPercentage: 'Porcentaje de solicitudes bloqueadas',
    ruleTotal: 'Reglas',
    adQuery: 'Anuncios bloqueados',
    trackerQuery: 'Rastreadores bloqueados',
    maliciousQuery: 'Bloqueo de malware',
    pornQuery: 'Contenido para adultos bloqueado',
    legendTotal: 'Total',
    legendBlocked: 'Bloqueado',
    categoryStateEnabled: 'Habilitado',
    categoryStateDisabled: 'Deshabilitado',
    categoryState2Hours: 'Deshabilitado por 2 horas',
    categoryState4Hours: 'Deshabilitado por 4 horas',
    categoryState8Hours: 'Deshabilitado por 8 horas',
    setCategoryStatesErr: 'Error al habilitar/deshabilitar la filtración',
    enableAll: 'Habilitar todas las filtraciones',
    disableAll: 'Deshabilitar todas las filtraciones',
  },
  // CustomadBlock
  CustomadBlock: {
    'missingInfoWarning': 'Falta el dominio',
    'invalidDomainError': 'El formato del dominio es inválido o el dominio es demasiado largo',
    'whitelist': 'Lista blanca',
    'blacklist': 'Lista negra',
    'domain': 'Dominio',
    'whitelistAddSuccess': 'Añadido a la lista blanca con éxito',
    'whitelistEntryExist': 'La entrada ya está en la lista blanca',
    'reachedWhiteMax': 'Se alcanzó el máximo de la lista blanca',
    'whitelistAddError': 'Error al añadir a la lista blanca',
    'blacklistAddSuccess': 'Añadido a la lista negra con éxito',
    'blacklistEntryExist': 'La entrada ya está en la lista negra',
    'reachedBlackMax': 'Se alcanzó el máximo de la lista negra',
    'blacklistAddError': 'Error al añadir a la lista negra',
    'whitelistDelSuccess': 'Entradas de la lista blanca eliminadas con éxito',
    'whitelistDelError': 'Error al eliminar entradas de la lista blanca',
    'blacklistDelSuccess': 'Entradas de la lista negra eliminadas con éxito',
    'blacklistDelError': 'Error al eliminar entradas de la lista negra',
    'import': 'Importar',
    'fileButtonText': 'Clic para subir',
    'fileSizeOverLimit': 'El archivo subido supera el límite de 2MB',
    'whitelistImportSuccess': 'Lista blanca importada con éxito, {0} entradas importadas',
    'whitelistImportError': 'Error al importar la lista blanca',
    'blacklistImportSuccess': 'Lista negra importada con éxito, {0} entradas importadas',
    'blacklistImportError': 'Error al importar la lista negra',
    'noValidEntry': 'No hay una entrada de dominio válida en el archivo subido',
    'zeroImported': 'Error al importar cualquier entrada de dominio',
  },
  // DohGuideComp
  DohGuideComp: {
    'closeButton': 'Cerrar',
    'guideButton': 'Haga clic para ver',
    'dohMessageClickTips':
      "Haga clic en 'Ver' para ver las instrucciones sobre cómo desactivar DoH en su navegador específico",
    'dohMessageTxt':
      'Para utilizar esta función, {0}. DoH encripta las solicitudes DNS, lo que puede interferir con algunas funciones.',
    'dohMessage0': 'Desactive la configuración de DoH en su navegador',
    'guideBoxTitle': 'Configuración del navegador para DoH',
    'guide': {
      'chromeGuideImg': '/dohGuide/chrome-guide-zh.png',
      'chrome': [
        {
          'label': 'Paso 1',
          'tip': 'Ingrese en la barra de direcciones: chrome://settings/security y presione Enter.',
        },
        {
          'label': 'Paso 2',
          'tip': "Encuentre la sección 'Configuración de seguridad avanzada'.",
        },
        {
          'label': 'Paso 3',
          'tip': "Desactive la opción 'Usar DNS seguro'.",
        },
      ],
      'firefox': [
        {
          'label': 'Paso 1',
          'tip': 'Ingrese en la barra de direcciones: about:config y presione Enter.',
        },
        {
          'label': 'Paso 2',
          'tip': "Si aparece una página de advertencia, haga clic en 'Aceptar el riesgo y continuar'.",
        },
        {
          'label': 'Paso 3',
          'tip': "Ingrese 'network.trr.mode' en el cuadro de búsqueda y presione Enter.",
        },
        {
          'label': 'Paso 4',
          'tip':
            "Haga doble clic en la configuración encontrada, cambie el valor a 5 (desactivar DoH) y luego haga clic en 'Aceptar'.",
        },
      ],
      'edge': [
        {
          'label': 'Paso 1',
          'tip':
            "Ingrese en la barra de direcciones: edge://settings/privacy y presione Enter, luego encuentre la sección 'Seguridad'.",
        },
        {
          'label': 'Paso 2',
          'tip': "Desactive la opción 'Usar DNS seguro para garantizar la seguridad de la red'.",
        },
      ],
      'operaGuideImg': '/dohGuide/opera-guide-zh.png',
      'opera': [
        {
          'label': 'Paso 1',
          'tip': 'Ingrese en la barra de direcciones del navegador: opera://settings/?search=DNS y presione Enter.',
        },
        {
          'label': 'Paso 2',
          'tip':
            "Encuentre la opción 'Usar DNS-over-HTTPS en lugar de la configuración DNS del sistema' y asegúrese de que esté desactivada.",
        },
      ],
    },
  },
  // appRelocatorData
  appRelocatorData: {
    guide: {
      youtube: {
        UA: 'https://www.deeper.network/pages/app-relocator?app=youtube&lang=zh',
      },
      nbaLeaguePass: 'https://www.deeper.network/pages/app-relocator?app=nbaLeaguePass&lang=zh',
      netflix: 'https://www.deeper.network/pages/app-relocator?app=netflix&lang=zh',
      dazn: 'https://www.deeper.network/pages/app-relocator?app=dazn&lang=zh',
      fubo: 'https://www.deeper.network/pages/app-relocator?app=fubo&lang=zh',
      tencentVideo: 'https://www.deeper.network/pages/app-relocator?app=tencentVideo&lang=zh',
    },
    countryLabel: 'País',
    tagLabel: 'Etiqueta',
    typeLabel: 'Tipo',
    country: {
      'all-countries': 'Todo el mundo',
      CN: 'China',
      US: 'Estados Unidos',
      IN: 'India',
      CA: 'Canadá',
      GB: 'Reino Unido',
      NA: 'Otros',
      NO: 'Noruega',
      SG: 'Singapur',
      JP: 'Japón',
    },
    tag: {
      'all-tags': 'Todas las etiquetas',
      nba: 'NBA',
      nfl: 'NFL',
      nhl: 'NHL',
      mlb: 'MLB',
      epl: 'EPL',
      ipl: 'IPL',
    },
    category: {
      'all-category': 'Todas las categorías',
      video: 'Video',
      music: 'Música',
      im: 'Mensajería instantánea',
      flightAndHotel: 'Vuelo y Hotel',
      subscription: 'Suscripción',
    },
    filter: 'Filtrar',
    reset: 'Restablecer',
    submit: 'Enviar',
    subcategory: {
      popular: 'Popular',
      sport: 'Deportes',
      movie: 'Película',
      tv: 'Televisión',
    },
    app: {
      'acornTv': 'Acorn TV',
      'amazonPrimeVideo': 'Amazon Prime Video',
      'beInSports': 'beIN Sports',
      'crunchyroll': 'Crunchyroll',
      'dazn': 'DAZN',
      'disneyPlus': 'Disney+',
      'netflix': 'Netflix',
      'plex': 'Plex',
      'roku': 'Roku',
      'shudder': 'Shudder',
      'zattoo': 'Zattoo',
      'discoveryPlus': 'Discovery+',
      'paramountPlus': 'Paramount+',
      'sportsnetNow': 'Sportsnet Now',
      'tsn': 'TSN',
      'bbcIPlayer': 'BBC iPlayer',
      'hulu': 'Hulu',
      'itv': 'ITV',
      'slingTv': 'Sling TV',
      'crackle': 'Crackle',
      'pandora': 'Pandora',
      'theCw': 'The CW',
      'abc': 'ABC',
      'nbc': 'NBC',
      'pluto': 'Pluto TV',
      'starz': 'Starz',
      'tbs': 'TBS',
      'attTv': 'AT&T TV',
      'channel4': 'Channel 4',
      'showtimeAnytime': 'Showtime Anytime',
      'nbcSports': 'NBC Sports',
      'epixNow': 'Epix Now',
      'mgmPlus': 'MGM+',
      'xumo': 'Xumo',
      'channel5': 'Channel 5',
      'usaNetwork': 'USA Network',
      'fxNow': 'FX Now',
      'vudu': 'Vudu',
      'fubo': 'fubo',
      'espn': 'ESPN',
      'tubiTv': 'Tubi TV',
      'ukTvPlay': 'UKTV Play',
      'tvPlayer': 'TVPlayer',
      'peacock': 'Peacock',
      'pbs': 'PBS',
      'philo': 'Philo',
      'vrv': 'VRV',
      'showmax': 'Showmax',
      'hboMax': 'HBO Max',
      'nhl': 'NHL',
      'dsTv': 'DStv',
      'sSportsPlus': 'S Sports+',
      'filmrise': 'FilmRise',
      'cbsSports': 'CBS Sports',
      'nowTV': 'Now TV',
      'molotovTv': 'Molotov TV',
      'mlb': 'MLB',
      'britBox': 'BritBox',
      'stirr': 'Stirr',
      'skyGo': 'Sky Go',
      'hotstar': 'Hotstar',
      'sonyLiv': 'SonyLIV',
      'nbaLeaguePass': 'NBA League Pass',
      'amcPlus': 'AMC+',
      'f1Tv': 'F1TV',
      'frndlyTv': 'Frndly TV',
      'myTf1': 'MYTF1',
      'qqMusic': 'QQ Music',
      'spotify': 'Spotify',
      'tidal': 'Tidal',
      'youtube': 'YouTube',
      'youTubeMusic': 'YouTube Music',
      'weChat': 'WeChat',
      'whatsApp': 'WhatsApp',
      'line': 'Line',
      'signal': 'Signal',
      'agoda': 'Agoda',
      'expedia': 'Expedia',
      'flyScanner': 'Fly Scanner',
      'googleFlights': 'Google Flights',
      'qqSports': 'QQ Sports',
      'skySports': 'Sky Sports',
      'rakutenTv': 'Rakuten TV',
      'fox': 'FOX',
      'nflNetwork': 'NFL Network',
      'peacocktv': 'Peacock TV',
      'zee5': 'Zee5',
      'mxPlayer': 'MX Player',
      'tencentVideo': 'Tencent Video',
      'iqiyi': 'iQiyi',
      'mangoTV': 'Mango TV',
      'migu': 'Migu',
      'bilibili': 'Bilibili',
      'youku': 'Youku',
      'altBalaji': 'ALT Balaji',
      'willowTv': 'Willow TV',
      'foxSports': 'FOX Sports',
      'crave': 'Crave',
      'cbcGem': 'CBC Gem',
      'jiocinema': 'JioCinema',
      'Telegram': 'Telegrama',
      'Messenger': 'Mensajero',
      'ChatGPT': 'ChatGPT',
      'CanalPlus': 'CanalMás',
    },
    tip: {
      'video': 'Túnel de servicios de video comunes actuales',
      'music': 'Túnel de servicios de música comunes actuales',
      'im': 'Túnel de mensajería instantánea común actual',
      'flightAndHotel': 'Túnel de reserva de vuelos y hoteles comunes actuales',
      'subscription': 'Túnel de servicios de suscripción en línea comunes actuales',
      'backupTunnel':
        'El túnel de respaldo está activado. Si no hay nodos disponibles temporalmente para el túnel agregado en el modo de enrutamiento inteligente, se utilizará automáticamente el túnel de respaldo - {0}',
      'fullTunnel':
        'En el modo de enrutamiento completo, todas las aplicaciones utilizarán automáticamente el túnel {0}.',
      'noTunnelNodes':
        'No hay nodos disponibles para el túnel. Por favor, ve a la página de túneles y actualiza los nodos del túnel {0}.',
      'noTunnelNodesOkText': 'Actualizar',
      'noTunnelNodesCancelText': 'Cerrar',
      'noTunnelAdded':
        'No has agregado este túnel. Por favor, ve a la página de túneles y agrega el túnel {0} para usarlo.',
      'noTunnelAddedOkText': 'Agregar',
      'noTunnelAddedCancelText': 'Cancelar',
      'tunnelDeletedText': 'El túnel {0} - {1} ha sido eliminado',
      'netflix': 'Solucionar problemas de reproducción al usar Netflix',
      'fubo': 'Fubo TV te permite ver transmisiones de fútbol inglés a un costo muy bajo',
      'tencentVideo': 'Ver un juego de la NBA por alrededor de $1',
      'dazn': 'DAZN te permite ver NFL, NFL, MLB, NBA, EPL a un costo muy bajo, solo $17.18',
      'youtube': 'Cómo disfrutar de YouTube Premium a un costo muy bajo',
    },
    intro: {
      'acornTv':
        'Acorn TV ofrece servicios de transmisión de programas de suspense, drama y comedia de clase mundial de Reino Unido y otros lugares. Puedes ver series clásicas de forma continua o descubrir tus nuevos programas favoritos en ellas.',
      'abc': 'Mira programas de ABC en línea en abc.com. Obtén videos exclusivos y series gratuitas.',
      'f1Tv':
        'F1 TV ahora está disponible en la web, aplicaciones, Google TV, Apple TV, Roku y Amazon Fire TV. Transmisión en vivo de todas las carreras de F1. Retransmisión en vivo de todas las sesiones de entrenamiento de los Grandes Premios.',
      'youtube':
        'El canal oficial de YouTube te ayuda a descubrir el contenido más reciente y popular de todo el mundo. Mira videos imprescindibles que abarcan música, cultura y fenómenos de Internet, entre otros temas.',
      'netflix':
        'Netflix es un servicio de transmisión que ofrece una amplia variedad de galardonadas series de televisión, películas, animaciones, documentales y más. Puedes verlo en miles de dispositivos conectados a Internet.',
      'fubo':
        'La forma más sencilla de ver Fubo en tu televisor es usando Roku, Apple TV, Amazon Fire TV o Chromecast. Solo tienes que instalar la aplicación de Fubo en tu dispositivo para transmitir contenido.',
      'disneyPlus':
        'Desde obras recién lanzadas hasta tus clásicos favoritos, el pasado, el presente y el futuro son tuyos. Todo lo que esperas y lo que nunca imaginaste.',
      'crunchyroll':
        'Explore the best anime and manga streaming content with Crunchyroll. Enjoy hits like Demon Slayer, One Piece, Attack on Titan, and My Hero Academia.',
      'hulu':
        "Watch TV shows and movies online. Stream popular TV series like Grey's Anatomy, This Is Us, Bob's Burgers, Brooklyn Nine-Nine, Empire, and Saturday Night Live, as well as popular movies.",
      'nbaLeaguePass':
        'NBA League Pass* offers fans access to live games, on-demand, and more throughout the regular season and off-season.',
      'peacock':
        "Get Peacock and stream live sports, exclusive original content, popular shows, and blockbuster movies. The best hits, classic collections, live events, and more are available now on Peacock. Documentaries, kids' movies and shows, 24/7 Peacock channels, Hallmark Channel, and more.",
      'qqSports':
        'QQSports is a well-known sports portal in China, mainly providing you with the following columns: domestic football, international football, NBA, CBA, comprehensive sports, Olympics, live broadcasts, lottery, betting, etc.',
      'rakutenTv':
        'Live TV | 15 free channels · Free | Free to watch popular movies · Store | Buy or rent popular movies · Genre · Free | Recently added free content · Free',
      'espn':
        'Access ESPN for live scores, highlights, and sports news. Watch exclusive events on ESPN+ and participate in fantasy sports games.',
      'beInSports':
        'Discover the world of sports through beIN SPORTS USA. Get exclusive replays of major football leagues and more, breaking news, data, scores, and schedules.',
      'dazn': "DAZN is the world's first truly dedicated live sports streaming platform...",
      'cbsSports':
        'Red de noticias deportivas las 24 horas gratuita. Mira CBS Sports HQ para obtener toda la información importante sobre los partidos. Más destacados emocionantes, apuestas de primer nivel y consejos exitosos para fantasía.',
      'fox':
        'Mira FOX Deportes y consulta los resultados en tiempo real, las probabilidades, las noticias de los equipos, las noticias de los jugadores, las transmisiones en vivo, los videos, las estadísticas, las clasificaciones y los horarios, que cubren la NFL, MLB, NASCAR, WWE, NBA, NHL y más.',
      'nflNetwork':
        '¡Obtén información y actualizaciones de programas del canal de la NFL! NFL Network es la única red de operación anual propiedad y operada por la NFL.',
      'peacocktv':
        'Mira episodios completos de programas actuales y clásicos de NBC en NBC.com. Además, puedes encontrar clips, avances, fotos y funciones exclusivas en línea.',
      'sportsnetNow':
        'MLB Top Performances - Video de MLB Sportsnet: Springer de los Azulejos celebra su cumpleaños liderando el bateo con un jonrón que se va lejos del campo - Video de MLB Sportsnet: Acuña Jr. de los Bravos.',
      'nbcSports': 'Obtén las últimas noticias deportivas y los resultados de NBC Sports para mantenerte actualizado.',
      'tsn':
        'Visita el sitio web de TSN para obtener las últimas noticias deportivas, puntuaciones, momentos destacados y comentarios sobre el Abierto de Australia, los playoffs de la NFL, el Super Bowl, la fecha límite de intercambios de la NHL y más.',
      'nhl':
        'El sitio web oficial de la National Hockey League (Liga Nacional de Hockey) incluye noticias, listas, estadísticas, horarios, equipos y videos, entre otros contenidos.',
      'hotstar':
        'Disney+ Hotstar es la plataforma de streaming premium más grande de la India, con más de 100,000 horas de contenido de teatro y películas en 17 idiomas, y ofrece transmisiones en vivo de todos los principales eventos deportivos.',
      'disneyPlus':
        'Desde nuevos lanzamientos hasta tus clásicos favoritos, el pasado, el presente y el futuro son tuyos. Todo lo que esperabas y todo lo que no. © 2023 Disney. Marcas: Marvel, Pixar, Disney, National Geographic, Star Wars.',
      'skySports':
        'Disfruta de las mejores coberturas en vivo de tus eventos deportivos favoritos: fútbol, golf, rugby, cricket, Fórmula 1, boxeo, fútbol americano, baloncesto, y las últimas noticias deportivas...',
      'sonyLiv':
        'Una plataforma de streaming premium de primer nivel donde puedes ver programas de televisión populares, películas, eventos deportivos y series web. Disfruta de la experiencia de ver en línea tus canales de televisión en vivo favoritos.',
      'jiocinema':
        'Mira las últimas películas, programas de televisión, programas de Voot, eventos deportivos en vivo y clips emocionantes en varios idiomas, incluyendo hindi, inglés y otros idiomas regionales.',
      'amazonPrimeVideo':
        'Transmite películas, programas, contenido original de Amazon premiado, canales premium, eventos deportivos, y más. Disfruta de series de televisión adictivas, películas populares y programas destacados con una prueba gratuita. Contenido compatible con 4K+HDR. Series y películas populares. Contenido recién lanzado. Disponible en cualquier dispositivo.',
      'zee5':
        'Ahora puedes comprar tu contenido Desi favorito en ZEE5™ con un descuento exclusivo del 50%. Compra ahora y disfruta de tus contenidos favoritos en ZEE5™. Descuento del 50% en paquetes anuales. Enlace de compra. Con más de 4,000 películas superhit, más de 1,600 programas y 260 obras originales. Compatible con 18 idiomas.',
      'mxPlayer':
        'MX Player es el servicio OTT más premium de la India, donde puedes reproducir los últimos videos musicales, ver películas antiguas y nuevas, programas de televisión, programas de comedia, canciones populares, y más.',
      'tencentVideo':
        'Tencent Video se dedica a crear una plataforma líder de medios de video en línea en China, con contenido enriquecedor, una experiencia de visualización excepcional, formas de inicio de sesión convenientes, una experiencia de aplicación sin problemas en múltiples plataformas las 24 horas del día y características de uso compartido rápido.',
      'iqiyi':
        'iQIYI.COM es un sitio web de video en línea de gran envergadura que tiene una gran cantidad de videos en línea de alta calidad. Es una plataforma profesional de reproducción de video en línea. iQIYI ofrece una amplia variedad de contenido de películas, dramas de televisión, animación, programas de variedades, estilo de vida, etc.',
      'youku':
        'Youku: Haciendo todo lo posible por buen contenido. Mira videos en alta definición en línea con licencia.',
      'migu':
        'Migu Video transmite en vivo eventos deportivos importantes nacionales e internacionales como los Juegos Olímpicos de Invierno de Beijing, transmisiones en vivo de la CBA, la Liga de Campeones de Asia, la Superliga China, la Liga Española, la Bundesliga, la Serie A, la UEFA Europa League, la UEFA Champions League, voleibol femenino, tenis de mesa, patinaje sobre hielo, snooker, WWE, UFC, etc.',
      'mangoTV':
        'Mango TV: El sitio web de video en línea que todos están viendo. Mira programas de variedades populares, las últimas películas y dramas de televisión en línea.',
      'nbc':
        'Mira episodios completos de programas actuales y clásicos de NBC en NBC.com. Además, puedes encontrar clips, avances, fotos y contenido exclusivo en línea.',
      'foxSports':
        'Mira FOX Sports y obtén resultados en tiempo real, probabilidades, noticias de equipos, noticias de jugadores, transmisiones en vivo, videos, estadísticas, clasificaciones y calendarios que cubren la NFL, MLB, NASCAR, WWE, NBA, NHL, etc.',
      'nfl':
        'Obtén información sobre el canal de la NFL y las actualizaciones de programas. NFL Network es la única red operada durante todo el año por la NFL.',
      'willowTv':
        'Una vez que hayas iniciado sesión correctamente, puedes ver transmisiones en vivo, destacados y repeticiones en Willow. Los servicios de Spectrum, Optimum, Fios, Dish, Optimum TV, Xfinity y Time Warner son compatibles.',
      'mlb':
        'Nuestra cobertura incluye clips de audio y video, entrevistas, estadísticas, horarios y noticias exclusivas.',
      'hboMax':
        'HBO Max es una plataforma independiente de transmisión de medios que combina todo el contenido de HBO con más series de televisión populares, películas destacadas y nuevos programas originales de Max, adecuados para todo tipo de audiencias.',
      'amcPlus':
        'Escapa de lo común y descubre experiencias únicas y cautivadoras en AMC+. Contenido exclusivo, películas emocionantes y series clásicas: encuentra todo en la serie épica de AMC+. Películas de calidad. Servicio bajo demanda. Contenido exclusivo. Películas exclusivas. Nuevas series. Sin publicidad.',
      'attTv':
        'Obtén nuestras mejores ofertas de televisión e internet, incluidas ofertas de DIRECTV y servicios de internet residencial de AT&T Fiber.',
      'britBox':
        'Disfruta de una gran colección de series de televisión británicas para ver de forma continua, que incluyen misterio, comedia, drama, documentales, estilo de vida y muchos otros géneros. Esta es la colección más grande de series de televisión británicas en streaming hasta ahora.',
      'crackle':
        'Mira películas de Hollywood gratuitas y sin editar en Crackle. Cubrimos una amplia variedad de géneros, como acción, terror, ciencia ficción, crimen y comedia. Solo haz clic para reproducir.',
      'discoveryPlus':
        'Puedes hacer streaming en dispositivos móviles, tabletas, computadoras, consolas de juegos y televisores conectados. ¡Aquí hay contenido para todos! discovery+ es un proveedor de servicios de streaming en áreas como alimentos, hogar, etc.',
      'mgmPlus':
        'Suscríbete a MGM+ para disfrutar de miles de películas y series de televisión sin publicidad. Puedes cancelar la suscripción en cualquier momento.',
      'filmrise':
        'Nos adaptamos activamente a las características cambiantes de la industria actual, utilizando una estrategia de múltiples niveles para ofrecer el mejor contenido de películas y televisión.',
      'frndlyTv':
        'Con Roku, Amazon Fire TV o Frndly TV, puedes ver más de 40 canales en vivo y contenido bajo demanda, incluidos los tres canales de Hallmark, A&E, etc. También puedes verlo en dispositivos móviles en cualquier momento y lugar.',
      'fxNow':
        'Mira programas de televisión originales de FX, FXX y FXM, películas y televisión en vivo en las redes de FX.',
      'paramountPlus':
        'En una plataforma de transmisión en continuo, puedes disfrutar de miles de episodios de series, eventos deportivos en vivo y contenido original exclusivo. Accede a cientos de películas icónicas, desde clásicos galardonados hasta los últimos estrenos. Descubre nuevas series para ver de forma continua. Programas de reality populares. Programas populares de MTV. Más de 30,000 episodios de series. Informes de noticias de última hora. Programas populares de CBS.',
      'pbs':
        'Las películas POV son conocidas por sus narrativas memorables y actualidad. ¡Mira ahora! POV es el programa de documentales independientes más antiguo de la televisión. ¡Aprende más! Obras de no ficción independientes. Más de 500 películas para disfrutar. Historias inolvidables.',
      'philo':
        '¿Por qué pagar más por televisión? Philo ofrece televisión en vivo y contenido a la carta por solo $25 al mes. Transmite tus programas y películas favoritas en todos tus dispositivos.',
      'plex':
        'Plex es una aplicación de transmisión gratuita que no requiere tarjeta de crédito, suscripción ni cargos ocultos. Para mantener nuestro servicio gratuito, incluimos anuncios para generar ingresos.',
      'pluto':
        'Desde las últimas películas populares hasta temporadas completas de tus series favoritas, lo tenemos todo. Cientos de canales. Sin costo alguno. Disponible en todos los dispositivos. ¡Comienza a ver ahora! Disponible en todos los dispositivos. Reproducción continua. Televisión y películas a la carta. Cientos de canales. Transmisión instantánea. Sin pagos.',
      'roku':
        'Los dispositivos Roku hacen que la televisión en streaming sea fácil. Desde reproductores y televisores inteligentes hasta productos para el hogar inteligente, encuentra el producto Roku perfecto para ti y haz que tu hogar sea más seguro.',
      'showtimeAnytime':
        'Prueba SHOWTIME gratis y accede a series originales, películas, eventos deportivos, documentales y más en streaming. Además, puedes comprar combates de boxeo a la carta sin necesidad de suscripción.',
      'shudder':
        'Comienza desde solo $4.99 al mes y obtén acceso a todo el contenido de Shudder en una sola plataforma, incluyendo las populares series del universo de The Walking Dead, Ann Rice, y más. Prueba Shudder gratis...',
      'slingTv':
        'Sling TV es el primer servicio de televisión basado en aplicaciones que te permite transmitir televisión en vivo y contenido a la carta a través de Internet. Mira programas en vivo sin importar dónde te encuentres...',
      'starz':
        'STARZ presenta una variedad de perspectivas de la vida a través de narrativas audaces. Regístrate para transmitir series originales, películas, contenido adicional y disfruta de reproducción a la carta sin anuncios.',
      'stirr':
        '... y programas de entretenimiento. Transmisión en vivo y a la carta. STIRR City y National están configurados como tus canales locales. Comienza a ver. 00:05. Más de 100 canales y más de 5000 horas de televisión...',
      'tbs':
        "'In the Heights' · 'Dynamite' esta semana en TBS · Avance de los playoffs de la MLB en TBS · El multiverso diverso de American Dad (Temporada 18, Episodio 14)",
      'theCw':
        "Sitio web oficial de la cadena CW, donde encontrarás emocionantes series como 'All American', 'Superman & Lois', 'Walker', 'FBoy Island', películas, eventos deportivos y más.",
      'tubiTv':
        'Tubi es la principal aplicación de transmisión de video a pedido gratuita y premium. Tenemos la biblioteca de contenido más grande con más de 50,000 películas y programas de televisión, ofreciendo lo mejor...',
      'usaNetwork':
        'Puede ver episodios completos de programas actuales y clásicos de USA en USANetwork.com. Además, puede encontrar clips de episodios, avances, fotos y contenido exclusivo en línea.',
      'vudu':
        'Alquile, compre y vea películas y programas de televisión en Vudu. Puede ver en línea en la aplicación de Vudu o en sus dispositivos favoritos. Sin suscripción, registro gratuito.',
      'xumo':
        'Xumo fue creado para desarrollar y proporcionar la próxima generación de plataformas de transmisión para toda la industria del entretenimiento. Conozca nuestra misión y únase a nosotros...',
      'jiocinema':
        'Vea las últimas películas, programas de televisión, programas de Voot, eventos deportivos en vivo y momentos destacados emocionantes en varios idiomas, incluyendo hindi, inglés y otros idiomas regionales, en línea a través de una variedad de géneros.',
      'altBalaji':
        'Vea series web, obras originales y películas en alta definición en línea. Elija entre una variedad de géneros como suspense, acción, adultos, comedia, dramas familiares, y admite varios idiomas.',
      'bilibili':
        'Bilibili (bilibili.com) es un conocido sitio web de videos con comentarios en tiempo real en China. Aquí encontrarás los últimos episodios de anime, un ambiente ACG activo y creadores de contenido creativos. Aquí puedes encontrar mucha diversión.',
      'crave':
        'Una colección cuidadosamente curada de películas clásicas de terror, series de televisión aterradoras y programas especiales de Halloween... una reputación inesperada en el mundo de la comedia y presiones sociales.',
      'cbcGem':
        "Mira programas populares como Schitt's Creek, Luther, Kim's Convenience y películas en CBC Gem en cualquier dispositivo, así como televisión en vivo.",
      'bbcIPlayer':
        'Mira canales de televisión de la BBC en vivo, disfruta de programas de televisión que te hayas perdido y mira contenido exclusivo en BBC iPlayer.',
      'channel4': 'Mira tus programas favoritos en línea de Channel 4, E4 y Walter Presents.',
      'channel5':
        'Bienvenido a My5, donde puedes poner al día y transmitir tus series favoritas de canales como Channel 5, 5STAR, 5USA, 5SELECT, 5ACTION, y más.',
      'dsTv':
        'El mejor entretenimiento televisivo al alcance de tu mano. Mira los últimos eventos deportivos, películas, series y reality shows, y accede a la guía de televisión de DStv.',
      'molotovTv':
        'Molotov es gratuito. Siéntete libre de agregar otras funciones. · Molotov representa el futuro de la televisión · El Spotify de la televisión · La televisión puede ser...',
      'myTf1':
        'Mira programas en vivo de TF1, TMC, TFX, TF1 Séries Films y LCI, con más de 50 canales temáticos exclusivos y más de 10,000 horas de contenido de video, solo en MYTF1...',
      'sSportsPlus':
        'Con Sports Plus, puedes disfrutar de más de 700 horas de transmisión en vivo cada mes, con cientos de contenido deportivo, desde Serie A hasta La Liga, desde Formula 1 hasta NBA, desde EuroLeague hasta UFC, todo al alcance de tus dedos...',
      'showmax':
        '¿Cuánto cuesta una suscripción a Showmax? Showmax ofrece una variedad de planes que se adaptan a diferentes preferencias y presupuestos, con precios desde solo R39 por...',
      'itv':
        'ITVX es el servicio de transmisión más nuevo del Reino Unido, que ofrece programas exclusivos, películas de éxito, eventos en vivo y miles de series, todo en una sola plataforma.',
      'pandora':
        'Reproduce tus canciones, álbumes, listas de reproducción y podcasts favoritos en el nuevo Pandora. Regístrate en un plan de suscripción y disfruta de reproducción sin anuncios y bajo demanda.',
      'qqMusic':
        'QQ Music es un producto de servicio de música basado en la web lanzado por Tencent, que ofrece una amplia gama de características, incluyendo transmisión de música en línea, lanzamientos exclusivos de canciones nuevas y populares, traducción de letras, descargas de tonos de llamada móviles, transmisión de música de alta calidad sin pérdidas, extensa biblioteca de música sin pérdidas, descargas legales de música y espacio personal...',
      'spotify': 'Spotify es un servicio de música digital que te permite acceder a millones de canciones.',
      'tidal':
        'TIDAL es una plataforma de transmisión de música centrada en los artistas y los fanáticos, que ofrece más de 100 millones de canciones en calidad de audio de alta fidelidad.',
      'weChat':
        'Disponible en varias plataformas; disfruta de chats grupales; admite mensajes de voz, fotos, videos y texto.',
      'whatsApp':
        'Mantente en contacto con amigos y familiares utilizando WhatsApp Messenger. WhatsApp es gratuito y ofrece funciones de mensajería y llamadas simples, seguras y confiables...',
      'line':
        'Una nueva aplicación de comunicación que te permite realizar llamadas de voz gratuitas y enviar mensajes gratuitos en cualquier momento y lugar, las 24 horas del día.',
      'signal':
        'Comparte mensajes de texto, mensajes de voz, fotos, videos, GIF y archivos de forma gratuita. Signal utiliza la conexión de datos de tu teléfono para evitar cargos de SMS y MMS.',
      'agoda':
        'Reserva ahora, paga después, cancelación gratuita. Servicio al cliente en tiempo real las 24 horas, en tu idioma. Desde económicos hasta lujosos, hoteles y alojamientos. Tu compañero confiable para viajes de habitaciones y vuelos.',
      'expedia':
        'Tu sitio de viajes todo en uno para tus vacaciones soñadas. Combina tu estancia con un alquiler de coche o vuelo y podrás ahorrar más. Busca nuestras opciones flexibles para combinar...',
      'flyScanner':
        'Obtén vuelos con descuento desde $19. Compara y reserva en una búsqueda. Es rápido y fácil. Compara vuelos baratos, hoteles y alquiler de coches. Encuentra miles de ofertas y obtén la mejor.',
      'nowTV':
        '¿Cuánto cuesta una membresía de NOW? Una membresía de NOW te permite ver lo que quieras, en tus propios términos. Puedes unirte con Entretenimiento, Cine, Deportes y Hayu.',
      'tvPlayer':
        'TVPlayer es el hogar de los canales de televisión más populares y programas a la carta de calidad premium, exclusivos de TVPlayer. Mira más de 40 canales en vivo y miles ...',
      'ukTvPlay':
        'UKTV Play es el hogar bajo demanda de los canales Dave, Drama, W y Yesterday. Mira televisión en vivo, transmite series y ponte al día con tus programas favoritos.',
      'zattoo':
        'Transmite tus programas de televisión favoritos en vivo y diferido en todos los dispositivos. Mejor calidad de imagen en Full-HD. Mejor que la televisión por cable. Contenido On Demand + Live TV.',
      'skyGo':
        'Transmite televisión en vivo, bajo demanda y programas para ponerse al día de tu paquete de Sky en tu teléfono, tableta, portátil o computadora, o dispositivo Apple TV, y cambia entre ellos fácilmente.',
      'Telegram':
        'Telegram tiene como objetivo proporcionar una experiencia de comunicación segura, rápida y concisa, y es conocido por sus potentes características de cifrado y funciones ricas.',
      'Messenger':
        'Messenger es una aplicación de mensajería instantánea proporcionada por Facebook, que permite a los usuarios comunicarse con otros a través de mensajes de texto, llamadas de voz, videollamadas, envío de imágenes, emojis, etc. Messenger inicialmente era una característica del sitio web de Facebook, luego se desarrolló en una aplicación móvil independiente, y proporciona más funciones y servicios.',
      'ChatGPT':
        'ChatGPT es un modelo de lenguaje de inteligencia artificial desarrollado por OpenAI, basado en la arquitectura GPT (Generative Pre-trained Transformer). Se entrena a través de datos de procesamiento de lenguaje natural a gran escala y puede entender y generar texto en lenguaje natural.',
      'CanalPlus':
        'CANAL+ es un canal de televisión de pago francés, una de las mayores redes de televisión de pago en Francia, y uno de los proveedores de servicios de televisión de pago más importantes de Europa. El canal ofrece una variedad de programas, incluyendo películas, deportes, noticias, documentales, dramas de televisión, etc.',
    },
    'selectTunnel': 'Seleccionar túnel',
    notSetLabel: 'No establecido',
    'directAccessLabel': 'Sin túnel',
    'otherTunnelLabel': 'Otro túnel',
    'recommendedTunnelLabel': 'Túnel recomendado',
    'error': {
      'getApps': 'Error al obtener aplicaciones',
      'getTunnels': 'Error al obtener la lista de túneles',
      'invalidApp': 'Aplicación no válida',
      'tunnelNotExist': 'El túnel no existe',
      'set': 'Error al establecer el túnel',
      'unset': 'Error al cancelar el túnel establecido',
    },
    'success': {
      'set': 'Túnel establecido correctamente',
      'unset': 'Túnel cancelado correctamente',
    },
    'searchInputPlaceholder': 'Buscar el servicio que necesitas',
    'discountIntro': 'Ahorra un %{percent} en la tarifa de suscripción mensual',
    'discountPercent': '-%{percent} (USD)/mes',
    'guideButtonLabel': 'Ver detalles',
  },
  // betanet
  betanet: {
    bindWalletSuccess: 'Billetera vinculada exitosamente',
    deviceBound: 'El dispositivo ya está vinculado a otra billetera',
    walletTaken: 'La billetera ya está en uso por otro dispositivo',
    deviceTypeIncompatible: 'El dispositivo no es compatible con la billetera',
    invalidMnemonic: 'Frase de recuperación de la billetera inválida',
    invalidPrivateKey: 'Clave privada de la billetera inválida',
    bindWalletError: 'Error al vincular la billetera',
    notBound: 'El usuario actual no tiene una billetera vinculada',
    unbindWalletSuccess: 'Billetera desvinculada exitosamente',
    noActiveBinding: 'El dispositivo no está vinculado a la billetera especificada',
    unbindWalletError: 'Error al desvincular la billetera',
    walletManagement: 'Gestión de billetera',
    bindWallet: 'Vincular billetera',
    downloadPrivateKey: 'Descargar clave privada',
    downloadKeyError: 'Error al descargar la clave privada',
    unbindBtnText: 'Desvincular billetera',
    unbindWarning:
      'Asegúrate de haber descargado la clave privada. Ingresa "He descargado la clave privada" y continúa.',
    unbindWarningWords: 'He descargado la clave privada',
    generateWallet: 'Generar billetera',
    verifyMnemonic: 'Verificar frase de recuperación',
    mnemonicMismatch: 'Frase de recuperación incorrecta',
    bindBtn1Text: 'Vincular billetera (frase de recuperación)',
    bindBtn2Text: 'Vincular billetera (clave privada)',
    dprManagement: 'Gestión de DPR',
    balanceLabel: 'Saldo de DPR',
    balance: 'Saldo',
    creditLabel: 'Crédito',
    operationLabel: 'Operación',
    amtOwingLabel: 'Monto adelantado por el sistema',
    dailyConsumedLabel: 'Uso diario de datos',
    dailySharedLabel: 'Uso diario compartido',
    deposit: 'Depositar',
    withdraw: 'Retirar',
    publicKey: 'Clave pública',
    mnemonicInstruction: 'Toma nota de la frase de recuperación. La necesitarás para verificar en el siguiente paso.',
    enterMnemonic: 'Ingresa la frase de recuperación',
    enterMnemonicTip: 'Haz clic en las palabras de la frase de recuperación en orden.',
    resetMnemonic: 'Restablecer',
    uploadPrivateKey: 'Cargar clave privada',
    getWalletError: 'Error al obtener la información de la billetera',
    deviceWalletMismatch: 'El dispositivo no coincide con la billetera',
    walletNotFound: 'Billetera no encontrada',
    generateWalletError: 'Error al generar la billetera',
    missingMnemonic: 'Falta la frase de recuperación',
    missingPrivateKeyFile: 'Falta el archivo de clave privada',
    privateKeyFileOversize: 'El archivo de clave privada supera el límite de 10 KB',
    transferTitle: 'Transferencia',
    transferHistoryTitle: 'Historial de transferencias',
    transferTo: 'Destinatario',
    transferAmt: 'Monto de transferencia (hasta 6 decimales)',
    invalidTo: 'Destinatario inválido',
    invalidAmt: 'Monto de transferencia inválido',
    amtOutOfRange: 'El monto de transferencia debe ser positivo y no puede superar 1 millón',
    transferSuccess: 'Transferencia exitosa',
    senderRecipientSame: 'El remitente no puede ser el mismo que el destinatario',
    recipientNotFound: 'Destinatario no encontrado',
    insufficientFund: 'Transferencia fallida debido a fondos insuficientes',
    transferError: 'Error en la transferencia',
    votingResNotRetrieved: 'No se pudo obtener el resultado de la delegación',
    candidateSelected: 'El candidato de validación seleccionado para tu delegación',
    candidateNotSelected: 'El candidato de validación seleccionado para tu delegación',
    votingResNotReady: 'No se puede consultar el resultado de la delegación en este momento',
    notVoted: 'No has delegado en ningún candidato de validación',
    candidateNotFound: 'Candidato de validación no encontrado',
    votingResRetrievalErr: 'Error al obtener el resultado de la delegación',
    from: 'Desde',
    to: 'Hacia',
    type: 'Tipo',
    amount: 'Cantidad',
    time: 'Tiempo',
    txnTypes: {
      101: 'Recompensa inicial',
      201: 'Transferencia manual',
      301: 'Recompensa de delegación',
      401: 'Microtransacción',
    },
    walletAddress: 'Dirección de la billetera',
    notInGenesisNodeList: 'Solo se puede vincular el nodo de génesis a una máquina minera de génesis.',
    onChainStatusLabel: 'Estado en la cadena',
    onChainStatusDesc: {
      unknown: '-',
      onChain: 'Sí',
      notOnChain: 'No',
    },
    pwMgmtSection: {
      title: 'Gestión de contraseñas',
      setBtnText: 'Establecer contraseña',
      resetBtnText: 'Restablecer contraseña',
    },
    passwordModal: {
      title: 'Establecer contraseña de la billetera',
      onePlaceholder: 'Ingrese la contraseña',
      twoPlaceholder: 'Vuelva a ingresar la contraseña',
      btnText: 'Confirmar',
    },
    setPasswordMsg: {
      mismatch: 'Las contraseñas no coinciden',
      weak: 'La contraseña debe tener al menos 8 caracteres y contener letras minúsculas, mayúsculas y números',
      alreadySet: 'La contraseña de la billetera ya ha sido establecida',
      genericErr: 'Error al establecer la contraseña de la billetera',
      success: 'La contraseña de la billetera se ha establecido correctamente',
    },
    authModal: {
      title: 'Contraseña de la billetera',
      inputPlaceholder: 'Ingrese la contraseña de la billetera',
      btnText: 'Continuar',
    },
    passwordNotSet: 'La contraseña de la billetera no ha sido establecida',
    passwordIncorrect: 'Contraseña de la billetera incorrecta',
    resetModal: {
      disclaimer:
        'Una vez que restablezca la contraseña de la billetera, su dispositivo se desvinculará de cualquier billetera actualmente vinculada. Ingrese "Confirmo restablecer la contraseña de la billetera" para continuar',
      consent: 'Confirmo restablecer la contraseña de la billetera',
    },
    resetPasswordMsg: {
      noActiveBinding: 'El dispositivo no está vinculado a la billetera especificada',
      unbindErr: 'Error al desvincular la billetera',
      genericErr: 'Error al restablecer la contraseña de la billetera',
      success: 'Contraseña de la billetera restablecida con éxito',
    },
    unstaking: {
      label: 'Información de staking',
      getErr: 'Error al obtener la selección de terminación del staking',
      alreadyChosen: 'Ya has seleccionado la terminación del staking',
      expired: 'El período de terminación del staking ha expirado',
      invalidCreditData: 'La billetera no está registrada en la red',
      phase1IsNotOver: 'El staking de la primera fase de esta billetera no ha terminado',
      setErr: 'Error al establecer la selección de terminación del staking',
      announcementTitle: 'Estimado usuario de nodo de staking:',
      announcement: {
        continueStaking:
          'Renovación de staking: Su actividad de staking ha pasado automáticamente a la segunda fase; la segunda fase de staking tiene una duración de 6 meses. El APY de Minería Básica (Basic Mining) y Nodo de Génesis (Genesis) se mantiene sin cambios en la segunda fase.',
        duringStaking:
          'Durante la segunda fase de staking: Puede hacer clic en este botón en cualquier momento para terminar el staking; ¡esta selección no se puede deshacer!',
        stopStaking: 'Terminación del staking: Pasará a una fase de liberación lineal de 3 meses;',
        releaseStaking:
          'Liberación del staking: DPR se liberará automáticamente a su billetera de DeeperChain todos los días.',
      },
      tipTitle: 'Recordatorio amistoso:',
      tip: {
        afterStopStaking_1:
          '1. Después de terminar el staking, se eliminará el crédito obtenido del staking (el crédito acumulado por compartir ancho de banda durante el staking se conservará)',
        afterStopStaking_2:
          '2. Cancelar el staking del Nodo de Génesis resultará en la pérdida de su posición de Génesis y la oportunidad de ser un Nodo de Génesis. Su dispositivo no podrá participar de inmediato en el staking básico 2.0, pero su calificación de crédito orgánico generada por compartir ancho de banda continuará.',
        afterStopStaking_3:
          '3. Después de terminar el staking, sus recompensas de staking en DPR se detendrán de inmediato',
        afterStopStaking_4:
          '4. Después de terminar el staking, no podrá volver a staking de inmediato (espere anuncios futuros)',
        afterStopStaking_5:
          '5. Después de terminar el staking, solo se detendrá cuando se hayan alcanzado todas las 270 recompensas de la primera fase',
      },
      btn: {
        cancel: 'Entendido, mantener el staking',
        ok: 'Salir del staking',
      },
    },
    staking: {
      walletInfo: 'Información de la billetera',
      campaignLabel: 'Tipo de actividad de staking',
      campaignText: {
        0: 'Nodo de Génesis de Oro',
        1: 'Nodo de Génesis de Plata',
        2: 'Staking 1.0',
        3: 'Minería DPRB',
        4: 'Staking 2.0',
        7: 'Staking 2.0',
        8: 'Staking 2.0',
      },
      creditLabel: 'Crédito actual',
      creditMaxLabel: 'Valor máximo de crédito',
      creditLevelLabel: 'Nivel de crédito',
      stakeAndBurn: 'Staking y Quemado',
      stakeForCredit: 'Aumentar el staking',
      dailyReward: 'Recompensa diaria',
      view: 'Controlar',
      payment: 'Pago',
      insufficientBalance: 'Saldo insuficiente',
      stakeSuccess: 'La solicitud se ha enviado. Por favor, espera pacientemente a que se actualice el crédito.',
      stakeError:
        'Error al enviar la solicitud. Por favor, verifica la información de la billetera o consulta al servicio de atención al cliente.',
      burnForCredit: 'Quemar DPR para obtener crédito',
      burnSuccess: 'La solicitud se ha enviado. Por favor, espera pacientemente a que se actualice el crédito.',
      burnError:
        'Error al enviar la solicitud. Por favor, verifica la información de la billetera o consulta al servicio de atención al cliente.',
    },
  },
  // ChangePasswordData
  ChangePasswordData: {
    successMsg: 'Contraseña modificada correctamente',
    newPasswordMismatch: 'Las nuevas contraseñas ingresadas no coinciden',
    oldPasswordMismatch: 'Contraseña anterior incorrecta',
    samePasswordError: 'La nueva contraseña es igual a la contraseña anterior',
    genericError: 'Error al modificar la contraseña',
    title: 'Modificar contraseña',
    oldPwd: 'Ingrese la contraseña anterior',
    newPwd: 'Ingrese la nueva contraseña',
    newPwdAgain: 'Ingrese la nueva contraseña nuevamente',
    okText: 'Confirmar',
  },
  //dailyTraffic
  dailyTraffic: {
    historySize: 'Días',
    chartTitle: 'tráfico web',
    uptimeChartTitle: 'Tiempo de actividad',
    hour: 'Hora',
    minute: 'Minuto',
    second: 'Segundo',
  },
  // flow
  flow: {
    'totalTrafficLabel': 'Total de tráfico',
    'localTraffic': 'Tráfico local',
    'clientTraffic': 'Tráfico del cliente',
    'serverTraffic': 'Tráfico del servidor',
    'upload': 'Subida',
    'download': 'Descarga',
    'sessionSpeed': 'Velocidad de sesión',
  },
  // login
  login: {
    'pageTitle': '¡Bienvenido a Deeper Network!',
    'username': 'Nombre de usuario',
    'password': 'Contraseña',
    'btnText': 'Iniciar sesión',
    'successMsg': 'Inicio de sesión exitoso',
    'missingField': 'Falta nombre de usuario o contraseña',
    'wrongField': 'Nombre de usuario o contraseña incorrectos',
    'invalidToken': 'Datos de inicio de sesión no válidos',
    'expiredToken': 'Datos de inicio de sesión caducados',
    'genericError': 'Error de inicio de sesión',
    'tooManyAttempts': 'Demasiados intentos de inicio de sesión',
  },
  //notification
  notification: {
    search: 'Buscar',
    CP: 'Capa de control',
    DP: 'Capa de datos',
    MP: 'Capa de gestión',
  },
  // DomainConfig
  domainConfig: {
    whiteList: 'Lista de enrutamiento inteligente',
    blackList: 'Lista de enrutamiento directo',
    domain: 'Dominio',
    tunnel: 'Túnel',
    selectTunnel: 'Seleccionar túnel',
    region: 'Región',
    country: 'País o región',
    noTunnel: 'Sin túnel',
    convertedTip: 'La URL se ha convertido automáticamente en un dominio',
    urlInputPlaceholder: 'Por ejemplo: https://www.google.com o google.com',
    missingInfoWarning: 'Por favor, complete la información requerida',
    invalidDomainError: 'Formato de dominio inválido o dominio demasiado largo',
    whitelistAddSuccess: 'Añadido correctamente a la lista de enrutamiento inteligente',
    existInBlacklist: 'El elemento a añadir ya existe en la lista de enrutamiento directo',
    whitelistEntryExist: 'El elemento ya existe en la lista de enrutamiento inteligente',
    tunnelNotExist: 'El túnel no existe, no se puede añadir',
    whitelistAddError: 'Error al añadir el elemento a la lista de enrutamiento inteligente',
    blacklistAddSuccess: 'Añadido correctamente a la lista de enrutamiento directo',
    existInWhitelist: 'El elemento a añadir ya existe en la lista de enrutamiento inteligente',
    blacklistEntryExist: 'El elemento ya existe en la lista de enrutamiento directo',
    blacklistAddError: 'Error al añadir el elemento a la lista de enrutamiento directo',
    conflict: 'No se puede añadir el elemento debido a un conflicto',
    whitelistDelSuccess: 'Elemento eliminado correctamente de la lista de enrutamiento inteligente',
    domainNotExist: 'El dominio seleccionado no existe',
    whitelistDelError: 'Error al eliminar el elemento de la lista de enrutamiento inteligente',
    blacklistDelSuccess: 'Elemento eliminado correctamente de la lista de enrutamiento directo',
    blacklistDelError: 'Error al eliminar el elemento de la lista de enrutamiento directo',
    backupTips:
      'El túnel de respaldo está activado. Si no hay nodos disponibles temporalmente para los túneles agregados en el modo de enrutamiento inteligente, se utilizará el túnel de respaldo - {0}.',
    tips: {
      smartRouteList:
        'Según sus necesidades, puede agregar manualmente los dominios que desea visitar junto con el país correspondiente.',
      directRouteList:
        'Los dominios agregados aquí no pasarán por el túnel DPN, sino que utilizarán directamente la red local.',
    },
  },
  ipConfig: {
    'whiteList': 'Lista de IP inteligentes',
    'blackList': 'Lista de IP directas',
    'ip': 'IP',
    'tunnel': 'Túnel',
    'selectTunnel': 'Seleccionar túnel',
    'region': 'Región',
    'country': 'País',
    'noTunnel': 'Sin túnel',
    'convertedTip': 'La URL se ha convertido automáticamente a IP',
    'urlInputPlaceholder': 'por ejemplo: https://xxx.xxx.xxx.xxx o xxx.xxx.xxx.xxx',
    'missingInfoWarning': 'Falta información',
    'invalidIPError': 'El formato de IP es inválido',
    'whitelistAddSuccess': 'Agregado a la lista de IP inteligentes con éxito',
    'existInBlacklist': 'No se pudo agregar a la lista de IP inteligentes porque existe en la lista de IP directas',
    'whitelistEntryExist': 'La entrada ya está en la lista de IP inteligentes',
    'tunnelNotExist': 'No se pudo agregar a la lista de IP inteligentes porque el túnel no existe',
    'whitelistAddError': 'No se pudo agregar a la lista de IP inteligentes',
    'blacklistAddSuccess': 'Agregado a la lista de IP directas con éxito',
    'existInWhitelist': 'No se pudo agregar a la lista de IP directas porque existe en la lista de IP inteligentes',
    'blacklistEntryExist': 'La entrada ya está en la lista de IP directas',
    'blacklistAddError': 'No se pudo agregar a la lista de IP directas',
    'whitelistDelSuccess': 'Las entradas de la lista de IP inteligentes se eliminaron con éxito',
    'ipNotExist': 'La(s) IP seleccionada(s) no existe(n)',
    'whitelistDelError': 'No se pudo eliminar las entradas de la lista de IP inteligentes',
    'blacklistDelSuccess': 'Las entradas de la lista de IP directas se eliminaron con éxito',
    'blacklistDelError': 'No se pudo eliminar las entradas de la lista de IP directas',
    'backupTips':
      'El túnel de respaldo está ahora activo. En modo de enrutamiento inteligente, si el túnel agregado carece temporalmente de nodos disponibles, el sistema usará por defecto el túnel de reserva - {0}.',
    'tips': {
      'smartRouteList':
        'De acuerdo a sus necesidades, puede agregar manualmente las direcciones IP que desea acceder junto con sus países correspondientes.',
      'directRouteList':
        'Las direcciones IP añadidas aquí no pasarán por el túnel DPN sino que se accederán directamente usando la red local.',
    },
  },
  // queryRouting
  queryRouting: {
    title: 'Consultar enrutamiento',
    searchLabel: 'Configuración de consulta',
    search: 'Buscar',
    searchResult: 'Resultado de búsqueda: ',
    invalidRoutingError: 'Formato de consulta incorrecto',
    defaultConfig: 'Configuración predeterminada',
    userConfig: 'Configuración de usuario',
    routingNotFound: 'Enrutamiento no encontrado',
    queryRoutingError: 'Error al consultar el enrutamiento',
    convertedTip: 'La URL se ha convertido automáticamente en un dominio o IP',
    urlInputPlaceholder: 'Por ejemplo: google.com o xxx.xxx.xxx.xxx',
  },
  // rustdesk
  rustdesk: {
    'ipLabel': 'Tu dirección IP es',
    'noPubIp': 'No tienes una IP pública.',
    'vpnUse':
      'Puedes usar el reenvío de puerto de Wireguard VPN para obtener una IP pública. Ten en cuenta que esta IP pública solo se puede utilizar para el servicio RustDesk y no proporciona servicios de DPN.',
    'config': {
      'title': 'Configuración de RustDesk',
      'switch': 'Activar/Desactivar',
      'forceKey': 'Requerir clave pública',
      'relay':
        'Servidores de retransmisión separados por comas (host:puerto), por ejemplo www.example.com:21116,192.168.1.1:21117',
      'port': 'Puerto (2-65532)',
      'saveBtnText': 'Guardar',
      'pubKey': 'Clave pública',
      'emptyPubKey': 'Sin clave pública',
      'resetBtnText': 'Restablecer clave pública',
    },
    'vpn': {
      'title': 'Reenvío de puerto de Wireguard VPN',
      'switch': 'Activar/Desactivar',
      'ip': 'IP del servidor Wireguard',
      'port': 'Puerto de reenvío VPN',
    },
    'guide': {
      'title': 'Tutorial de configuración',
      'stepOne': 'Haz clic en el botón como se muestra en la imagen',
      'stepTwoA': 'Completa los campos correspondientes en el formulario y haz clic en Guardar',
      'idAndRelay': 'ID/Servidor de retransmisión',
      'idServer': 'Servidor ID:',
      'relayServer': 'Servidor de retransmisión:',
      'apiServer': 'Servidor API:',
      'serverKey': 'Clave:',
      'stepTwoB': 'O puedes copiar la configuración al portapapeles e importarla en RustDesk',
      'copyBtnText': 'Copiar configuración del servidor al portapapeles',
    },
    'success': {
      'setSwitch': 'RustDesk cambiado con éxito',
      'setForced': 'Clave pública requerida establecida con éxito',
      'setRelayAndPort': 'Relé y puerto guardados con éxito',
      'resetPubKey': 'Clave pública restablecida con éxito',
      'setVpnSwitch': 'Wireguard VPN cambiado con éxito',
      'copyToClipboard': 'Copiado al portapapeles con éxito',
    },
    'error': {
      'getNetworkAddress': 'Error al obtener la dirección IP',
      'getConfig': 'Error al obtener la configuración de RustDesk',
      'setSwitch': 'Error al cambiar RustDesk',
      'setForced': 'Error al establecer la clave pública requerida',
      'invalidPort': 'Puerto no válido',
      'invalidRelay': 'Relé no válido',
      'setRelayAndPort': 'Error al guardar el relé y el puerto',
      'resetPubKey': 'Error al restablecer la clave pública',
      'getVpnConfig': 'Error al obtener la configuración de Wireguard VPN',
      'setVpnSwitch': 'Error al cambiar Wireguard VPN',
      'copyToClipboard': 'Error al copiar al portapapeles',
      'getVersion': 'Error al obtener el número de versión',
      'getHbbrLog': 'Error al obtener el registro de HBBR',
      'getHbbsLog': 'Error al obtener el registro de HBBS',
    },
  },
  // sharing
  sharing: {
    'sharingTitle': 'Compartir: ',
    'btSharingTitle': 'Compartir BitTorrent: ',
    'enabled': 'Activado',
    'disabled': 'Desactivado',
    'sharingTrafficLimit': 'Límite de tráfico compartido mensual',
    'sharingBandwidthLimit': 'Límite de ancho de banda compartido',
    'unlimited': 'Sin límite',
    'limited': 'Limitado',
    'setLabel': 'Configurar',
    'checkStartEndTime': 'Selecciona la hora de inicio y la hora de finalización',
    'time': 'Tiempo',
    'record': 'Registro',
    'startTime': 'Hora de inicio',
    'endTime': 'Hora de finalización',
    'keyword': 'Palabra clave',
    'search': 'Buscar',
    'webToSharingTitle': 'Reglas de filtrado web de la aplicación',
    webToSharingDesc:
      'Aplique el filtrado de anuncios DNS integrado del dispositivo y las reglas de filtrado DNS personalizadas',
    'yes': 'Sí',
    'no': 'No',
    'download': 'Registro en texto claro',
    'export': 'Registro encriptado',
    'success': {
      enableTrafficLimit: 'Límite de tráfico compartido mensual habilitado. ¡Por favor, establezca el límite!',
      'setSharingTrafficLimit': 'Límite de tráfico compartido configurado correctamente',
      'enableBandwidthLimit':
        'Límite de ancho de banda compartido habilitado. ¡Establece un límite entre 15Mbps y 1024Mbps!',
      'setSharingBandwidthLimit': 'Límite de ancho de banda compartido configurado correctamente',
    },
    'errors': {
      'getSharingState': 'Error al obtener el estado de compartición',
      'switchSharingState': 'Error al cambiar el estado de compartición',
      'exceedsLimit': 'Error al habilitar la compartición, se ha superado el límite de tráfico',
      'getSharingTrafficLimit': 'Error al obtener el límite de tráfico compartido',
      'setSharingTrafficLimit': 'Error al configurar el límite de tráfico compartido',
      'invalidTrafficLimit': 'El límite de tráfico debe ser un número positivo.',
      'setSharingBandwidthLimit': 'Error al configurar el límite de ancho de banda compartido',
      'invalidBandwidthLimit': 'El límite de ancho de banda debe estar entre 15Mbps y 1024Mbps',
      'getSharingFilterConfig': 'Error al obtener la configuración de filtrado compartido',
      'setSharingFilterConfig': 'Error al configurar la configuración de filtrado compartido',
    },
  },
  // system
  system: {
    hardwareInfo: 'Información de hardware',
    softwareInfo: 'Información de software',
    networkAddress: 'Dirección de red',
    sessionInfo: 'Información de sesión',
    upgradeInfo: 'Información de actualización',
    sn: 'Número de serie',
    cpu: 'CPU',
    cores: 'Núcleos',
    memory: 'Usado/Disponible',
    systemVersion: 'Versión del sistema',
    appidSignVersion: 'Biblioteca de funciones de identificación de protocolo de tráfico',
    urlFilterSignVersion: 'Biblioteca de filtrado de URL',
    https: 'HTTPS',
    dns: 'DNS',
    rules: 'Reglas',
    ip: 'Dirección IP',
    routerMac: 'Mac del enrutador',
    gatewayMac: 'Mac de la puerta de enlace',
    maxSession: 'Número máximo de sesiones',
    currentSession: 'Sesiones actuales',
    tcpUdpIcmp: 'TCP/UDP/ICMP/TUNNEL',
    autoUpdate: 'Actualización automática',
    manualUpgrade: 'Actualización manual',
    latestVersion: 'Última versión',
    upgrading: 'Actualizando...',
    systemStatusHistory: 'Historial de estado del sistema',
    cpuUsage: 'Uso de CPU en 24 horas',
    memoryUsage: 'Uso de memoria en 24 horas',
    off: 'Apagado',
    on: 'Encendido',
    nowCheckVersion: 'Verificando la versión actual',
    nowLatestVersion: 'Ya tienes la última versión',
    nowNewTestVersion: 'Versión actual en uso',
    upgradeLoadingText: 'Actualizando el firmware, por favor espera sin refrescar la página~',
    upgradeSuccess: 'Actualización exitosa, la página se actualizará pronto~',
    upgradeError: {
      noImage: 'Paquete de actualización faltante',
      invalidImage: 'Paquete de actualización inválido',
      imageArchMismatch: 'El paquete de actualización no coincide con el dispositivo',
      noResponse:
        'El dispositivo se ha reiniciado, por favor, actualiza la página más tarde para confirmar si la actualización fue exitosa',
      alreadyLatest: 'La versión actual es la más reciente, no es necesario actualizar~',
      generic: 'Error durante la actualización~',
    },
    copySuccess: 'Copiado exitoso',
    copyFail: 'Error al copiar',
  },
  // techSupport
  techSupport: {
    remoteConnection: {
      title: 'Conexión remota',
      loading: 'Conectando...',
      popupTitle: 'Código de conexión remota',
      intro:
        'Aplicable en situaciones en las que tu dispositivo tiene conectividad de red normal, pero experimenta problemas con otras funciones.<br />Genera manualmente un "Código de conexión remota" y envíalo a nuestro equipo de soporte al cliente. Nuestro equipo técnico te ayudará a identificar y resolver rápidamente el problema. Para garantizar la validez del código de conexión, evita generar repetidamente en un corto período de tiempo.',
      buttonText: 'Generar código de conexión',
      generateFeatureCodeFailure: 'Error al generar el código de conexión',
      tips:
        'Si tienes algún problema, envía detalles a nuestro equipo de soporte. Estamos comprometidos en ayudarte y asegurarnos de que se resuelvan tus problemas.',
      confirm: 'Copiar y cerrar',
      copySuccess: 'Copiado exitoso al portapapeles',
      copyFailure: 'Error al copiar al portapapeles',
      connectFailure: 'Error en la conexión remota',
    },
    logDownload: {
      title: 'Descargar registros',
      loading: 'Descargando...',
      intro:
        'Aplicable en situaciones en las que tu dispositivo puede acceder al panel de administración, pero experimenta problemas de conectividad de red.<br />Descarga manualmente el archivo de registros y envíalo a nuestro equipo de soporte al cliente. Esto nos permitirá comprender mejor tu situación y brindarte una ayuda más efectiva.',
      buttonText: 'Descargar',
      downloadSuccess: 'Descarga exitosa',
      downloadFailure: 'Error en la descarga, por favor inténtalo de nuevo más tarde',
    },
    supportServices: {
      title: 'Obtener la asistencia que necesitas',
      intro: 'Proporciona información específica para que podamos ofrecerte una solución adecuada.',
      buttonText: 'Obtener ayuda',
    },
    promiseTips: {
      title: 'Compromiso de seguridad:',
      intro:
        'Puedes estar tranquilo, limitamos estrictamente las funciones de conexión remota y descarga de registros a fines de diagnóstico y resolución de problemas. Nunca se utilizarán para ningún otro propósito, asegurando la máxima protección de tu privacidad y seguridad.',
    },
  },
  // builtinRules
  builtinRules: [
    {
      label: 'Activar filtrado HTTPS (incluye filtrado HTTP)',
      sslbypass: 'Activar bypass SSL',
      value: 'youtube',
    },
  ],
  // customrules
  customrules: [
    {
      label: 'Activar filtrado HTTPS (incluye filtrado HTTP)',
      value: 'youtube',
    },
  ],
  // tproxy
  tproxy: {
    'browserCacheWarning':
      'Si habilitas el filtrado de anuncios HTTPS y no se filtran los anuncios correctamente, es necesario limpiar la caché del navegador. Si solo habilitas el filtrado HTTPS sin instalar el certificado, no solo no se filtrarán los anuncios, sino que también puede afectar el acceso normal a los sitios web de la lista.',
    'triggerRulesTipsTitle': 'Consejo',
    'buttonAddRule': 'Agregar regla',
    'buttonInstallCertificate': 'Instalar certificado',
    'missingInfoWarning': 'Por favor, verifica si has completado la información correctamente.',
    'missingInfoSuccess': 'Operación exitosa',
    'missingInfoError': 'Error al realizar la operación',
    'certificateTip': 'Para garantizar un uso correcto, asegúrate de haber instalado el certificado.',
    'remind': 'No mostrar de nuevo',
    'tip': 'Debes seguir los siguientes pasos para bloquear los anuncios correctamente:',
    'triggerRulesTips':
      'Después de habilitar el filtrado HTTPS, para que Deeper Connect pueda filtrar los anuncios de los sitios web de la lista, debes instalar y confiar en el certificado raíz de Deeper Network en tu computadora.',
    'triggerRulesTips2':
      'Si solo habilitas el filtrado HTTPS sin instalar el certificado, no solo no se filtrarán los anuncios, sino que también puede afectar el acceso normal a los sitios web de la lista.',
    'mobileDownloadCertTips': 'Descarga e instala el certificado utilizando el navegador Safari.',
    'androidTVTip': 'Si estás utilizando Android TV, descarga la aplicación para usarla.',
    'errorMessage': {
      'maxLength': 'Se permiten como máximo 1024 reglas.',
      'ruleExisted': 'Ya existe una regla de dominio y expresión de bloqueo similares.',
    },
    'label': {
      'domain': 'Dominio',
      'Pattern': 'Expresión de bloqueo',
      'ruleType': 'Tipo de regla',
      'web': 'Navegador',
      'app': 'Aplicación',
      'all': 'Todos',
      'Rewrite_Pattern': 'Expresión de reescritura',
      'Rewrite_Info': 'Información de reescritura',
      'Rewrite_Action': 'Acción',
      'enable': 'Habilitar',
      'operation': 'Operación',
      'order': 'Orden',
    },
    'operation': {
      'delete': 'Eliminar',
      'edit': 'Editar',
    },
    'modal': {
      'title': 'Agregar regla',
      'confirmText': 'Enviar',
      'cancelText': 'Cancelar',
    },
    'placeholder': {
      'domain': 'Ingresa un dominio válido',
      'Pattern': 'Ingresa una expresión de bloqueo válida',
      'ruleType': 'Selecciona un tipo de regla',
      'Rewrite_Pattern': 'Ingresa una expresión de reescritura válida',
      'Rewrite_Info': 'Ingresa la información de reescritura',
      'Rewrite_Action': 'Selecciona una acción',
    },
    'builtinRules': {
      'title': 'Filtrado de anuncios',
      'filterListtitle': 'Lista de filtrado HTTPS',
      'platform': 'Plataforma compatible',
      'segOptions': ['Tutorial de instalación del certificado', 'Lista de sitios web compatibles'],
    },
    'customerRules': {
      'title': 'Reglas HTTPS personalizadas',
    },
    'guide': {
      'buttonDownloadCertificate': 'Haz clic para descargar el certificado',
      'buttonDownloadApp': 'Haz clic para descargar SkyTube',
      'tab': {
        'mobileInstall': 'iPhone/iPad',
        'macInstall': 'Mac OS',
        'windowsInstall': 'Windows',
        'androidInstall': 'Android',
        'linuxInstall': 'Linux',
      },
      'mobile': {
        'videoTab': 'Video',
        'textTab': 'Texto',
        'step1': {
          'label': 'Paso 1',
        },
        'step2': {
          'label': 'Paso 2',
          'tip': 'Haz clic en Permitir',
        },
        'step3': {
          'label': 'Paso 3',
          'tip': "Abre la configuración de tu teléfono y haz clic en 'Perfil descargado'",
        },
        'step4': {
          'label': 'Paso 4',
          'tip': 'Haz clic en Instalar e ingresa la contraseña de tu teléfono',
        },
        'step5': {
          'label': 'Paso 5',
          'tip': 'Haz clic en Instalar',
        },
        'step6': {
          'label': 'Paso 6',
          'tip': 'Instalación completada',
        },
        'step7': {
          'label': 'Paso 7',
          'tip':
            'Abre la configuración de tu teléfono > General > Información > Configuración de confianza de certificados y habilita el certificado que acabas de instalar',
        },
      },
      'windows': {
        'step1': {
          'label': 'Paso 1',
        },
        'step2': {
          'label': 'Paso 2',
          'tip': "Haz clic en el certificado descargado y luego en 'Instalar certificado'",
        },
        'step3': {
          'label': 'Paso 3',
          'tip': "Selecciona 'Equipo local' como 'Ubicación de almacenamiento' y haz clic en 'Siguiente'",
        },
        'step4': {
          'label': 'Paso 4',
          'tip': "Selecciona 'Colocar todos los certificados en el siguiente almacén' y haz clic en 'Examinar'",
        },
        'step5': {
          'label': 'Paso 5',
          'tip': "Selecciona 'Autoridades de certificación raíz de confianza' y haz clic en 'Aceptar'",
        },
        'step6': {
          'label': 'Paso 6',
          'tip': "Haz clic en 'Siguiente'",
        },
        'step7': {
          'label': 'Paso 7',
          'tip': "Haz clic en 'Finalizar'. La instalación del certificado está completa",
        },
      },
      'mac': {
        'step1': {
          'label': 'Paso 1',
        },
        'step2': {
          'label': 'Paso 2',
          'tip':
            "Haz clic en el certificado descargado. Aparecerá una ventana emergente 'Agregar certificado' y selecciona 'Iniciar sesión' en 'Acceso a llaveros'",
        },
        'step3': {
          'label': 'Paso 3',
          'tip': "En la ventana 'Acceso a llaveros', selecciona 'Inicio de sesión' en la barra lateral",
        },
        'step4': {
          'label': 'Paso 4',
          'tip': "Encuentra el certificado 'deeper.network' y haz clic derecho en 'Mostrar información'",
        },
        'step5': {
          'label': 'Paso 5',
          'tip':
            "Haz clic en 'Confiar' en la información y marca 'Usar este certificado como confiable' como 'Siempre confiar'",
        },
        'step6': {
          'label': 'Paso 6',
          'tip':
            "Haz clic en el botón de cierre en la esquina superior izquierda de la información, ingresa la contraseña de tu computadora y haz clic en 'Actualizar configuración'",
        },
        'step7': {
          'label': 'Paso 7',
          'tip': 'La instalación del certificado está completa',
        },
      },
      'linux': {
        'rootTab': 'Tutorial de importación de certificado raíz del sistema',
        'root': {
          'step1': {
            'label': 'paso 1',
          },
          'step2': {
            'label': 'paso 2',
            'tip':
              '<p>Copie el archivo de certificado a /usr/local/share/ca-certificates/</p>\
              <p>Este directorio se utiliza específicamente para almacenar los certificados CA adicionales instalados localmente. El archivo de certificado debe ser un archivo en formato PEM con la extensión .crt.</p>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /usr/local/share/ca-certificates/</code></div>',
          },
          'step3': {
            'label': 'paso 3',
            'tip':
              '<p>Actualizar el almacenamiento de certificados</p>\
              <p>Utilice el comando update-ca-certificates para actualizar el almacenamiento de certificados, esto actualizará automáticamente la cadena de confianza de los certificados en el sistema.</p>\
              <div class="code"><code>sudo update-ca-certificates</code></div>\
              <p>Este comando leerá los archivos de certificado en el directorio /usr/local/share/ca-certificates/ y los agregará a la lista de confianza del sistema.</p>\
              <em><em class="warning">Nota:</em> Diferentes distribuciones de Linux pueden tener diferentes ubicaciones de almacenamiento de certificados y comandos de actualización.</em><br>\
              <em>Por ejemplo, en los sistemas basados en Red Hat\
              (como Fedora, CentOS), es posible que necesite copiar el certificado a /etc/pki/ca-trust/source/anchors/,</em><br>\
              <em>y luego ejecute el comando update-ca-trust para actualizar el almacenamiento de certificados.</em><br>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /etc/pki/ca-trust/source/anchors/</code><br>\
              <code>sudo update-ca-trust</code></div>\
              <p>Después de ejecutar estos pasos, el certificado raíz que instaló debería haber sido confiado por el sistema.</p>',
          },
        },
        'chrome': {
          'step1': {
            'label': 'paso 1',
          },
          'step2': {
            'label': 'paso 2',
            'tip':
              "Ingresa chrome://settings/certificates en la barra de direcciones y luego selecciona la pestaña '<b>Autoridades</b>'.",
          },
          'step3': {
            'label': 'paso 3',
            'tip': 'Haz clic en Importar, encuentra el certificado que acabas de descargar, haz clic en Abrir',
          },
          'step4': {
            'label': 'paso 4',
            'tip':
              "Marca todas las opciones y haz clic en Ok en la ventana '<b>Autoridad Certificadora</b>' para completar la instalación del certificado.",
          },
        },
        'firefox': {
          'step1': {
            'label': 'paso 1',
          },
          'step2': {
            'label': 'paso 2',
            'tip':
              "Ingresa 'about:preferences#privacy' en la barra de direcciones, busca 'Certificados' y haz clic en 'Ver Certificados'",
          },
          'step3': {
            'label': 'paso 3',
            'tip': 'Selecciona Autoridades, haz clic en Importar',
          },
          'step4': {
            'label': 'paso 4',
            'tip': 'Selecciona el certificado que acabas de descargar y haz clic en Abrir',
          },
          'step5': {
            'label': 'paso 5',
            'tip': 'Marca todas las opciones, haz clic en OK, la instalación del certificado está completa',
          },
        },
      },
      'android': {
        'appTab': 'APP',
        'browserTab': 'Navegador',
        'app': {
          'step1': {
            'label': 'paso 1',
          },
          'step2': {
            'label': 'paso 2',
            'tip': 'Instala la aplicación descargada y disfruta del video sin anuncios',
          },
        },
        'browser': {
          'step1': {
            'label': 'paso 1',
          },
          'step2': {
            'label': 'paso 2',
            'tip': "Abre los 'Ajustes' del teléfono y busca 'Seguridad'",
          },
          'step3': {
            'label': 'paso 3',
            'tip': "Entra en 'Seguridad', encuentra 'Cifrado y credenciales', haz clic para entrar",
          },
          'step4': {
            'label': 'paso 4',
            'tip': "Haz clic en 'Instalar un certificado'",
          },
          'step5': {
            'label': 'paso 5',
            'tip': "Haz clic en 'Certificado CA'",
          },
          'step6': {
            'label': 'paso 6',
            'tip': "Haz clic en 'Instalar de todos modos', luego ingresa la contraseña de tu teléfono",
          },
          'step7': {
            'label': 'paso 7',
            'tip': 'Selecciona el certificado descargado',
          },
          'step8': {
            'label': 'paso 8',
            'tip': 'Mensaje de que el certificado CA ha sido instalado y el certificado se ha instalado con éxito',
          },
        },
      },
    },
  },
  // SSLBypassCheckbox
  SSLBypassCheckbox: {
    'sslBypass': 'Habilitar la función de bypass SSL',
    'sslBypassTips': 'Permite que los dispositivos sin un certificado instalado se conecten a la red.',
    'sslBypassWaring':
      'Si habilita la función de bypass SSL, es posible que el filtrado de anuncios no funcione correctamente.',
    'success': 'Operación exitosa',
    'failed': 'Operación fallida',
  },
  // tunnel
  tunnel: {
    'configuredTunnel': 'Túnel personalizado',
    countryTooltip:
      'Busque de forma aproximada el nombre del país o ingrese el código del país (en mayúsculas) y presione Entrar para buscar el país deseado.',
    'region': 'Región',
    'country': 'País o región',
    'activeTunnelNum': 'Número de IP activas',
    'activeIp': 'IP activa',
    'switchNode': 'Cambiar IP activa',
    'switchIpTitle': 'Cambiar IP',
    'initTunnelTitle': 'Reiniciar túnel',
    'initTunnelTitleTips': 'Un túnel tiene 3 IP, actualizar el túnel le cambiará 3 IP nuevas.',
    'selectRegion': 'Seleccionar región',
    'selectCountry': 'Seleccionar país o región',
    'missingInfoWarning': 'Ingrese toda la información',
    'tunnelAddSuccess': 'El túnel se agregó correctamente, la página se actualizará en 3 segundos.',
    'tunnelExist': 'El túnel ya existe',
    'reachedMax': 'El número de túneles ha alcanzado el límite',
    'tunnelConflict': 'El túnel que desea agregar está en conflicto con el túnel existente {0} - {1}',
    'tunnelAddError': 'El túnel no se pudo agregar',
    'tunnelDelSuccess': 'El túnel se eliminó correctamente, la página se actualizará en 3 segundos.',
    'tunnelNotExist': 'El túnel no existe',
    'usedByFullMode': 'El túnel se está utilizando para la ruta DPN completa y no se puede eliminar',
    usedByAccessControl: 'El control de acceso utiliza los túneles que se van a eliminar.',
    'usedByBackup': 'El túnel está siendo utilizado por el túnel de respaldo y no se puede eliminar',
    'tunnelInUse': 'El túnel se está utilizando en la tabla de rutas y no se puede eliminar',
    'tunnelDelError': 'El túnel no se pudo eliminar',
    'switchNodeSuccess': 'El cambio de nodo se realizó correctamente, la página se actualizará en 3 segundos.',
    'noActiveIp': 'No hay IP activas disponibles para cambiar',
    'ipNotChanged': 'La IP no ha cambiado',
    'switchNodeError': 'El cambio de nodo no se pudo realizar',
    'refreshLabel': 'Actualizar',
    'getNewIPLabel': 'Obtener nueva IP',
    'refreshError': 'No se pudo actualizar el túnel',
    'refreshSuccess': 'Se envió la solicitud de actualización del túnel, la página se actualizará en 3 segundos.',
    'tips': {
      'refreshTunnel': 'Actualiza el túnel activo para ti.',
      'configuredTunnel':
        'Después de agregar manualmente el túnel de región que necesita, AtomOS buscará nodos que cumplan con sus requisitos en todo el mundo.',
    },
    'nodeLbMode': {
      'label': 'Modo de selección de nodo',
      'none': {
        'label': 'Normal',
        'desc':
          'En el túnel, todas las conexiones de red pasan a través de un nodo activo, que los usuarios pueden cambiar en cualquier momento.',
      },
      'random': {
        'label': 'Aleatorio',
        'desc': 'El sistema selecciona un nodo automáticamente para cada conexión.',
      },
      'domain': {
        'label': 'Percepción de dominio',
        'desc':
          'El sistema usa varios nodos en el túnel para acceder a la red, pero el sistema recuerda y siempre enruta el acceso a un dominio específico al mismo nodo, lo que reduce el riesgo de errores al acceder a un sitio web.',
      },
      'rr': {
        'label': 'Round Robin',
        'desc':
          'Cada vez que se conecta, el sistema selecciona un nodo en orden, lo que ayuda a mejorar la aceleración de la red.',
      },
      'failure': 'No se pudo guardar el modo de equilibrio de carga del nodo',
      'success': 'Se guardó correctamente el modo de equilibrio de carga del nodo',
    },
  },
  // userConsent
  userConsent: {
    'notice':
      'Aviso: La función de intercambio está desactivada hasta que aceptes los términos y condiciones y la política de privacidad.',
    'consentStart': 'He leído y acepto los ',
    'termsOfUseNotRead': 'Aún no has leído los términos y condiciones de uso',
    'termsOfUseLink': 'https://shop.deeper.network/terms-of-service?lang=es',
    'termsOfUseTitle': 'Términos de uso',
    'consentAnd': ' y ',
    'privacyPolicyNotRead': 'Aún no has leído la política de privacidad',
    'privacyPolicyLink': 'https://shop.deeper.network/privacy-policy?lang=es',
    'privacyPolicyTitle': 'Política de privacidad',
    'confirmBtn': 'Confirmar',
    'consentError': 'Error al confirmar el consentimiento',
  },
  // customConfigLang
  customConfigLang: {
    'missingInfoWarning': 'Falta el nombre de dominio',
    'invalidDomainError': 'Formato de dominio incorrecto o el dominio es demasiado largo',
    'whitelist': 'Lista blanca',
    'blacklist': 'Lista negra',
    'domain': 'Dominio',
    'whitelistAddSuccess': 'Añadido correctamente a la lista blanca',
    'whitelistEntryExist': 'La entrada ya existe en la lista blanca',
    'reachedWhiteMax': 'Se ha alcanzado el límite de la lista blanca',
    'whitelistAddError': 'Error al añadir la entrada a la lista blanca',
    'blacklistAddSuccess': 'Añadido correctamente a la lista negra',
    'blacklistEntryExist': 'La entrada ya existe en la lista negra',
    'reachedBlackMax': 'Se ha alcanzado el límite de la lista negra',
    'blacklistAddError': 'Error al añadir la entrada a la lista negra',
    'whitelistDelSuccess': 'Entrada de la lista blanca eliminada correctamente',
    'whitelistDelError': 'Error al eliminar la entrada de la lista blanca',
    'blacklistDelSuccess': 'Entrada de la lista negra eliminada correctamente',
    'blacklistDelError': 'Error al eliminar la entrada de la lista negra',
    'import': 'Importar',
    'fileButtonText': 'Haga clic para cargar',
    'fileSizeOverLimit': 'El archivo cargado supera el tamaño máximo de 2 MB',
    'whitelistImportSuccess': 'Importación de lista blanca exitosa, se han importado {0} entradas',
    'whitelistImportError': 'Error al importar la lista blanca',
    'blacklistImportSuccess': 'Importación de lista negra exitosa, se han importado {0} entradas',
    'blacklistImportError': 'Error al importar la lista negra',
    'noValidEntry': 'El archivo cargado no contiene ningún dominio válido',
    'zeroImported': 'No se han importado entradas',
  },
  // defaultConfigLang
  defaultConfigLang: {
    'browserCacheWarning':
      'Si el filtrado de anuncios DNS no se puede filtrar correctamente, es necesario limpiar la caché del navegador y reiniciar el dispositivo filtrado.',
    'queriesFor24Hours': 'Consultas en las últimas 24 horas',
    'totalQuery': 'Total de consultas',
    'blockedQuery': 'Consultas bloqueadas',
    'blockedPercentage': 'Porcentaje de consultas bloqueadas',
    'ruleTotal': 'Reglas',
    'adQuery': 'Consultas de publicidad bloqueadas',
    'trackerQuery': 'Consultas de rastreo bloqueadas',
    'maliciousQuery': 'Consultas maliciosas bloqueadas',
    'pornQuery': 'Consultas de contenido para adultos bloqueadas',
    'legendTotal': 'Total',
    'legendBlocked': 'Bloqueado',
    'categoryStateEnabled': 'Habilitado',
    'categoryStateDisabled': 'Deshabilitado',
    'categoryState2Hours': 'Deshabilitado durante 2 horas',
    'categoryState4Hours': 'Deshabilitado durante 4 horas',
    'categoryState8Hours': 'Deshabilitado durante 8 horas',
    'setCategoryStatesErr': 'Error al habilitar/deshabilitar las categorías de filtrado',
    'enableAll': 'Habilitar todas las categorías de filtrado',
    'disableAll': 'Deshabilitar todas las categorías de filtrado',
  },
  // wifiSettings
  wifiSettings: {
    'workMode': 'Modo de trabajo',
    'currentWorkMode': 'Modo de trabajo actual',
    'upstreamTitle': 'Configuración de conexión ascendente',
    'backHome': 'Volver a inicio',
    'wire-ap': 'WAN con cable',
    'wlan-ap': 'WAN inalámbrica',
    'downstreamTitle': 'LAN inalámbrica',
    'notConnect': 'No conectado a Internet',
    'apConfigWarning':
      'Después de guardar el SSID y la contraseña de Wi-Fi, si desea acceder a Internet a través del dispositivo Deeper, deberá volver a escanear el punto de acceso del dispositivo Deeper y aplicar la contraseña.',
    'attention': 'Atención',
    'buttons': {
      'scanWifi': 'Escanear',
      'connectStatus': 'Estado',
      add: 'Añadir',
      'save': 'Guardar',
      'cancel': 'Cancelar',
      'downloadLog': 'Descargar registro',
    },
    'connect': 'Conectar',
    'forget': 'Olvidar',
    'waiting': {
      'content': 'Conectando, esto puede llevar algo de tiempo. Por favor, espere...',
      'cancelBtn': 'Cancelar conexión',
    },
    'modals': {
      'hiddenWifi': {
        'title': 'Agregar Wi-Fi oculta',
        'okBtn': 'Agregar',
      },
      'scanWifi': {
        'title': 'Advertencia',
        'content': 'La red se desconectará temporalmente',
        'ok': 'Aceptar',
        'cancel': 'Cancelar',
      },
    },
    'label': {
      'ssid': 'Nombre de Wi-Fi',
      'password': 'Contraseña de Wi-Fi',
      'onlyUse': 'Solo usar',
      'hidden': 'SSID oculta',
      'freqBand': 'Banda de frecuencia',
    },
    'placeholder': {
      'ssid': 'Ingrese el SSID',
      'password': 'Ingrese la contraseña',
    },
    'message': {
      'saveSuccess': 'Guardado exitoso',
      'saveError': 'Error al guardar. Por favor, inténtelo nuevamente',
      'connectSuccess': 'Conexión exitosa',
      'forgetSuccess': 'Olvido exitoso',
      'inputError': 'Verifique su entrada',
      'invalidWifiDev':
        'No hay dispositivos Wi-Fi disponibles actualmente. No se puede realizar la configuración de Wi-Fi',
      'illegalWifiDev':
        'La configuración de Wi-Fi solo se puede usar en Modo AP Router y Modo de repetidor inalámbrico',
      'invalidSsid': 'Solo se admiten letras, números, _ y -',
      'invalidPassword': 'Ingrese una contraseña válida',
      'notConnect': 'No conectado a Internet',
      'retry': 'Inténtelo nuevamente',
      'connectionError': 'Error de conexión',
      'forgetError': 'Error al olvidar',
      'wifiNotSupported': 'Wi-Fi no compatible',
      'networkNotFound': 'Red no encontrada',
      'pskNotGenerated': 'Clave no generada',
      'wrongPassword': 'Contraseña incorrecta',
      'timeout': 'Tiempo de conexión agotado',
      'logDownloadError': 'Error al descargar el registro',
      'hasBeenConnected': 'Ya está conectado.',
      'dhcpSuccess': 'Éxito',
      'dhcpFailure': 'Error',
    },
    'connectionRequestedTitle': 'Solicitud de conexión recibida',
    'connectionRequestedContent':
      "Por favor, verifique el estado de su conexión en la opción 'Estado'. Si se desconecta de Deeper Connect, intente conectarse nuevamente a DeeperWiFi.",
    'gotIpFromDhcpLabel': 'Obtener dirección IP de DHCP',
  },
  // parentalControlData
  parentalControlData: {
    'browserCacheWarning':
      'Si no se pueden bloquear los sitios web para adultos o las redes sociales mientras se habilita la función de control parental, intente limpiar la caché del navegador y reiniciar los dispositivos filtrados.',
    'customerRule':
      'Aquí puede bloquear la mayoría de los sitios web para adultos y redes sociales para sus hijos. Si desea bloquear otros sitios web, puede utilizar la función de bloqueo de dominios personalizados para agregar más sitios web.',
    'customerRuleClickTips': 'Haga clic aquí {0} para acceder a la página de bloqueo de dominios personalizados',
    'toCustomerRuleButtonText': 'haga clic aquí',
    'parentalControl': 'Control parental',
    'pornQuery': 'Bloquear contenido para adultos',
    'socialQuery': 'Bloquear redes sociales',
    'legendBlocked': 'Bloqueado',
    'socialStateEnabled': 'Habilitado',
    'socialStateDisabled': 'Deshabilitado',
    'socialState2Hours': 'Deshabilitado durante 2 horas',
    'socialState4Hours': 'Deshabilitado durante 4 horas',
    'socialState8Hours': 'Deshabilitado durante 8 horas',
    'pornStateEnabled': 'Habilitado',
    'pornStateDisabled': 'Deshabilitado',
    'pornState2Hours': 'Deshabilitado durante 2 horas',
    'pornState4Hours': 'Deshabilitado durante 4 horas',
    'pornState8Hours': 'Deshabilitado durante 8 horas',
    'setCategoryStatesErr': 'Error al habilitar/deshabilitar la filtración de categorías',
  },
  reboot: {
    success: {
      saveConfig: 'Guardado exitosamente',
    },
    failure: {
      getConfig: 'Error al obtener la configuración de reinicio automático',
      saveConfig: 'Error al guardar la configuración de reinicio automático',
    },
    buttons: {
      reboot: 'Haz clic para reiniciar',
      save: 'Guardar',
    },
    week: {
      0: 'Todos los días',
      1: 'Lunes',
      2: 'Martes',
      3: 'Miércoles',
      4: 'Jueves',
      5: 'Viernes',
      6: 'Sábado',
      7: 'Domingo',
    },
    title: {
      manual: 'Reiniciar ahora',
      auto: 'Reinicio programado',
    },
    label: {
      period: 'Periodo',
      time: 'Hora',
    },
  },
};
