export default {
  type: 'chinese',
  internationalize: {
    type: 'chinese',
    common: {
      tip_text: '提示',
      search: '查询',
      add: '添加',
      delete: '删除',
      confirm: '确认',
      cancel: '取消',
      warning: '警告',
      utcDisclaimer: '日期为 UTC',
      toOpen: '去开启',
    },
    certificateDialog: {
      title: '新的根CA证书',
      content: '根CA证书已更换。请下载并重新安装。',
      dontShowAgain: '不再显示',
    },
    request_tip: {
      error: '请求错误~',
    },
    header: {
      logout: '退出',
      changePassword: '修改密码',
      userManual: '用户手册',
      defaultAvatar: '默认头像',
      simplechinese: '简体中文',
      english: '英语',
      german: '德语',
      korean: '韩语',
      french: '法语',
      spain: '西班牙语',
      portugal: '葡萄牙语',
      japanese: '日语',
      Tchinese: '繁体中文',
      russian: '俄罗斯语',
      hindi: '印地语',
      simplechinese: '简体中文',
    },
    home: {
      name: '首页',
      children: {},
      content: {
        tipInput: '请输入',
        ok: '确认',
        cancel: '取消',
        currentStatus: '目前状态',
        statusMap: {
          Trial: '试用',
          Valid: '有效',
          Expired: '过期',
        },
        btnActive: '激活',
        btnEdit: '修改',
        expireDate: '失效日期',
        licenseKey: '许可证序号',
      },
    },
    accessControl: {
      name: '访问控制',
    },
    tpProxy: {
      name: '透明代理',
      children: {
        customRules: {
          name: '自定义规则',
        },
        builtinRules: {
          name: '广告过滤',
        },
        certificate: {
          name: '安装证书',
        },
      },
    },
    help: {
      name: '帮助',
      children: {
        techSupport: {
          name: '技术支持',
        },
      },
    },
    device: {
      name: '本机相关',
      children: {
        system: {
          name: '系统信息',
        },
        traffic: {
          name: '流量',
        },
        notification: {
          name: '日志',
        },
      },
      content: {},
    },
    dpn: {
      name: 'DPN',
      content: {},
      children: {
        smartRoute: {
          name: '模式',
        },
        tunnel: {
          name: '隧道',
        },
        routingTable: {
          name: '智能路由表',
          content: {},
          children: {
            domainConfig: {
              name: '自定义域名',
            },
            ipConfig: {
              name: '自定义IP',
            },
            queryRouting: {
              name: '查询',
            },
          },
        },
      },
    },
    appRelocator: {
      name: '应用重定位',
    },
    adsBlock: {
      name: '广告拦截',
      children: {
        domainFilter: {
          name: 'DNS 过滤',
        },
        httpsFilter: {
          name: 'HTTPS 过滤',
        },
      },
      httpssucceed: 'HTTPS 过滤开启成功',
      httpslose: 'HTTPS 过滤关闭成功',
      saverror: '保存失败',
      usedByAccessControl: '访问控制使用HTTPS过滤器',
    },
    dpr: {
      name: '挖矿',
      children: {
        dpr: {
          name: 'DPR',
          children: {
            stakingTutorial: {
              name: '质押教程',
            },
            wallet: {
              name: '钱包',
              manager: '钱包管理',
              balance: '钱包余额',
            },
            'Staking Mining': 'Staking 挖矿',
            transaction: {
              id: '交易 ID',
              from: '转出地址',
              to: '转入地址',
              time: '转账时间 (UTC)',
              rewardTime: '奖励时间 (UTC)',
              amount: 'DPR数量',
              'Reward in selected period': '选定期间奖励',
              'Total reward': '总奖励',
              'Balance Transfer': '普通交易',
              'Staking Reward': 'Staking 奖励',
              'PoS Reward': 'PoS奖励',
              'Micro Payment': '微支付交易',
              stakeRelease: '线性释放交易',
              Transaction: '交易记录',
              Mining: '挖矿',
              'Traffic Mining': '流量挖矿',
              'Staking Mining': 'Staking 挖矿',
            },
          },
        },
      },
    },
    security: {
      name: '安全相关',
      children: {
        applications: '应用控制',
        traffic: '流量控制',
        webfilter: {
          name: '网页过滤',
          children: {
            report: {
              name: '默认配置',
            },
            config: {
              name: 'DNS 广告过滤',
            },
          },
        },
        sslFilter: {
          name: 'SSL过滤',
          children: {
            customDNSrules: {
              name: '自定义 DNS 规则',
            },
            customHTTPSRules: {
              name: '自定义 HTTPS 规则',
            },
          },
        },
      },
      content: {},
    },
    parentalControl: {
      name: '家长控制',
    },
    regionCountryTexts: {
      'LL': '不走隧道',
      'ANY': '任意国家或地区',
      // regions
      'AMER': '---美洲---',
      'ASIA': '---亚洲---',
      'AFRI': '---非洲---',
      'EURO': '---欧洲---',
      'OCEA': '---大洋洲---',
      'AMN': '北美',
      'AMC': '加勒比海地区',
      'AMM': '中美洲',
      'AMS': '南美',
      'ASC': '中亚',
      'ASE': '东亚',
      'ASW': '西亚',
      'ASS': '南亚',
      'ASD': '东南亚',
      'AFN': '北非',
      'AFM': '中非',
      'AFE': '东非',
      'AFW': '西非',
      'AFS': '南非',
      'EUN': '北欧',
      'EUE': '东欧',
      'EUW': '西欧',
      'EUS': '南欧',
      'OCP': '波利尼西亚',
      'OCA': '澳新地区',
      'OCM': '美拉尼西亚',
      'OCN': '密克罗尼西亚',
      // countries
      'AD': '安道尔共和国',
      'AE': '阿拉伯联合酋长国',
      'AF': '阿富汗',
      'AG': '安提瓜和巴布达',
      'AI': '安圭拉',
      'AL': '阿尔巴尼亚',
      'AM': '亚美尼亚',
      'AO': '安哥拉',
      'AR': '阿根廷',
      'AS': '美属萨摩亚',
      'AT': '奥地利',
      'AU': '澳大利亚',
      'AW': '阿鲁巴',
      'AX': '奥兰',
      'AZ': '阿塞拜疆',
      'BA': '波斯尼亚和黑塞哥维那',
      'BB': '巴巴多斯',
      'BD': '孟加拉国',
      'BE': '比利时',
      'BF': '布吉纳法索',
      'BG': '保加利亚',
      'BH': '巴林',
      'BI': '布隆迪',
      'BJ': '贝宁',
      'BL': '圣巴泰勒米',
      'BM': '百慕大',
      'BN': '文莱',
      'BO': '玻利维亚',
      'BQ': '荷兰加勒比区',
      'BR': '巴西',
      'BS': '巴哈马',
      'BT': '不丹',
      'BW': '博茨瓦纳',
      'BY': '白俄罗斯',
      'BZ': '伯利兹',
      'CA': '加拿大',
      'CC': '科科斯（基林）群岛',
      'CD': '刚果（金）',
      'CF': '中非',
      'CG': '刚果（布）',
      'CH': '瑞士',
      'CI': '科特迪瓦',
      'CK': '库克群岛',
      'CL': '智利',
      'CM': '喀麦隆',
      'CN': '中华人民共和国',
      'CO': '哥伦比亚',
      'CR': '哥斯达黎加',
      'CU': '古巴',
      'CV': '佛得角',
      'CW': '库拉索',
      'CX': '圣诞岛',
      'CY': '塞浦路斯',
      'CZ': '捷克',
      'DE': '德国',
      'DJ': '吉布提',
      'DK': '丹麦',
      'DM': '多米尼克',
      'DO': '多米尼加',
      'DZ': '阿尔及利亚',
      'EC': '厄瓜多尔',
      'EE': '爱沙尼亚',
      'EG': '埃及',
      'ER': '厄立特里亚',
      'ES': '西班牙',
      'ET': '埃塞俄比亚',
      'FI': '芬兰',
      'FJ': '斐济',
      'FK': '福克兰群岛',
      'FM': '密克罗尼西亚联邦',
      'FO': '法罗群岛',
      'FR': '法国',
      'GA': '加彭',
      'GB': '英国',
      'GD': '格瑞那达',
      'GE': '格鲁吉亚',
      'GF': '法属圭亚那',
      'GG': '根西',
      'GH': '加纳',
      'GI': '直布罗陀',
      'GL': '格陵兰',
      'GM': '冈比亚',
      'GN': '几内亚',
      'GP': '瓜德罗普',
      'GQ': '赤道几内亚',
      'GR': '希腊',
      'GS': '南乔治亚和南桑威奇群岛',
      'GT': '危地马拉',
      'GU': '关岛',
      'GW': '几内亚比绍',
      'GY': '圭亚那',
      'HK': '中国香港',
      'HN': '洪都拉斯',
      'HR': '克罗地亚',
      'HT': '海地',
      'HU': '匈牙利',
      'ID': '印尼',
      'IE': '爱尔兰',
      'IL': '以色列',
      'IM': '马恩岛',
      'IN': '印度',
      'IO': '英属印度洋领地',
      'IQ': '伊拉克',
      'IR': '伊朗',
      'IS': '冰岛',
      'IT': '意大利',
      'JE': '泽西',
      'JM': '牙买加',
      'JO': '约旦',
      'JP': '日本',
      'KE': '肯尼亚',
      'KG': '吉尔吉斯斯坦',
      'KH': '柬埔寨',
      'KI': '基里巴斯',
      'KM': '科摩罗',
      'KN': '圣基茨和尼维斯',
      'KR': '韩国',
      'KW': '科威特',
      'KY': '开曼群岛',
      'KZ': '哈萨克斯坦',
      'KP': '北韩',
      'LA': '老挝',
      'LB': '黎巴嫩',
      'LC': '圣卢西亚',
      'LI': '列支敦士登',
      'LK': '斯里兰卡',
      'LR': '利比里亚',
      'LS': '莱索托',
      'LT': '立陶宛',
      'LU': '卢森堡',
      'LV': '拉脱维亚',
      'LY': '利比亚',
      'MA': '利比亚',
      'MC': '摩纳哥',
      'MD': '摩尔多瓦',
      'ME': '黑山',
      'MF': '法属圣马丁',
      'MG': '马达加斯加',
      'MH': '马绍尔群岛',
      'MK': '北马其顿',
      'ML': '马里',
      'MM': '缅甸',
      'MN': '蒙古',
      'MO': '中国澳门',
      'MP': '北马里亚纳群岛',
      'MQ': '马提尼克',
      'MR': '毛里塔尼亚',
      'MS': '蒙特塞拉特',
      'MT': '马尔他',
      'MU': '毛里塔尼亚',
      'MV': '马尔代夫',
      'MW': '马拉维',
      'MX': '墨西哥',
      'MY': '马来西亚',
      'MZ': '莫桑比克',
      'NA': '纳米比亚',
      'NC': '新喀里多尼亚',
      'NE': '尼日尔',
      'NF': '诺福克岛',
      'NG': '尼日利亚',
      'NI': '尼加拉瓜',
      'NL': '荷兰',
      'NO': '挪威',
      'NP': '尼泊尔',
      'NR': '瑙鲁',
      'NU': '纽埃',
      'NZ': '新西兰',
      'OM': '阿曼',
      'PA': '巴拿马',
      'PE': '秘鲁',
      'PF': '法属玻里尼西亚',
      'PG': '巴布亚新几内亚',
      'PH': '菲律宾',
      'PK': '巴基斯坦',
      'PL': '波兰',
      'PM': '圣皮埃尔和密克隆',
      'PN': '皮特凯恩群岛',
      'PR': '波多黎各',
      'PS': '巴勒斯坦',
      'PT': '葡萄牙',
      'PW': '帕劳',
      'PY': '巴拉圭',
      'QA': '卡塔尔',
      'RE': '留尼汪',
      'RO': '罗马尼亚',
      'RS': '塞尔维亚',
      'RU': '俄罗斯',
      'RW': '卢旺达',
      'SA': '沙特阿拉伯',
      'SB': '所罗门群岛',
      'SC': '塞席尔',
      'SD': '苏丹',
      'SE': '瑞典',
      'SG': '新加坡',
      'SH': '圣赫勒拿、阿森松和特里斯坦-达库尼亚',
      'SI': '斯洛文尼亚',
      'SJ': '斯瓦尔巴和扬马延',
      'SK': '斯洛文尼亚',
      'SL': '塞拉利昂',
      'SM': '圣马力诺',
      'SN': '塞内加尔',
      'SO': '索马里',
      'SR': '苏里南',
      'SS': '南苏丹',
      'ST': '圣多美和普林西比',
      'SV': '萨尔瓦多',
      'SX': '荷属圣马丁',
      'SY': '叙利亚',
      'SZ': '斯威士兰',
      'TC': '特克斯和凯科斯群岛',
      'TD': '乍得',
      'TF': '法属南方和南极洲领地',
      'TG': '多哥',
      'TH': '泰国',
      'TJ': '塔吉克斯坦',
      'TK': '多哥',
      'TL': '东帝汶',
      'TM': '土库曼斯坦',
      'TN': '突尼西亚',
      'TO': '汤加',
      'TR': '土耳其',
      'TT': '特立尼达和多巴哥',
      'TV': '图瓦卢',
      'TW': '中国台湾',
      'TZ': '坦桑尼亚',
      'UA': '乌克兰',
      'UB': '美国西部',
      'UC': '美国中西部',
      'UD': '美国西南部',
      'UE': '美国东北部',
      'UF': '美国东南部',
      'UG': '乌干达',
      'US': '美国',
      'UY': '乌拉圭',
      'UZ': '乌兹别克斯坦',
      'VA': '梵蒂冈',
      'VC': '圣文森特和格林纳丁斯',
      'VE': '委内瑞拉',
      'VG': '英属维尔京群岛',
      'VI': '美属维尔京群岛',
      'VN': '越南',
      'VU': '瓦努阿图',
      'WF': '瓦利斯和富图纳',
      'WS': '萨摩亚',
      'XK': '科索沃',
      'YE': '也门',
      'YT': '马约特',
      'ZA': '南非',
      'ZM': '赞比亚',
      'ZW': '津巴布韦',
    },
    setting: {
      name: '设置',
      children: {
        wifi: {
          name: 'Wi-Fi相关',
        },
        sharing: {
          name: '共享相关',
          children: {
            configure: {
              name: '共享配置',
            },
            blacklist: {
              name: '共享黑名單',
            },
            record: {
              name: '共享日志',
            },
            traffic: {
              name: '共享流量',
            },
          },
          content: {},
        },
        rustdesk: {
          name: 'RustDesk',
          children: {
            config: {
              name: '配置',
            },
            log: {
              name: '日志',
            },
          },
        },
        admin: {
          name: '账户管理',
          content: {},
          children: {
            changePassword: {
              name: '重置密码',
            },
            reboot: {
              name: '重启',
              content: {
                confirmText: '确认重启?',
                rebootLoading: '设备需要几分钟时间重启，您的网络会暂时断开...',
              },
            },
          },
        },
      },
    },
  },
  // dashboard
  dashboard: {
    name: '首页',
    children: {},
    content: {
      backupTunnelLabel: '备用隧道',
      tipInput: '请输入',
      hotspot: '全球连接',
      realnetworkflow: '实时流量统计',
      memory: '内存占用',
      cpu: 'CPU占用',
      notification: '系统通知',
      systeminfo: '系统信息',
      upload: '上传',
      download: '下载',
      systemuptime: '系统运行时间',
      uploadtotal: '总上传量',
      downloadtotal: '总下载量',
      softwareVersion: '软件版本',
      publicIp: '公网IP',
      unit: '单位',
      currentDpnMode: '当前DPN模式',
      ok: '确认',
      cancel: '取消',
      currentStatus: '目前状态',
      statusMap: {
        Trial: '试用',
        Valid: '有效',
        Expired: '过期',
      },
      btnActive: '激活',
      btnEdit: '修改',
      expireDate: '失效日期',
      licenseKey: '许可证序号',
      systemTraffic: '实时系统流量统计',
      appTraffic: '实时应用流量统计',
    },
  },
  accessControl: {
    content: {
      banner: {
        title: '访问控制',
        description: '这个开关用于统一控制启用或禁用局域网设备的各项功能。',
      },
      onlineDevice: '在线设备',
      offlineDevice: '离线设备',
      deviceName: '设备名称',
      unknownDevice: '未知',
      macAddress: 'MAC地址',
      ipAddress: 'IP地址',
      enableHttpsFilter: '启用HTTPS过滤',
      routeMode: 'DPN模式',
      routeModeOptions: {
        smart: '智能路由模式',
        full: '全路由',
        disabled: '禁用',
        blackHole: '禁用互联网',
      },
      remark: '备注',
      pinned: '置顶',
      httpsFilterTip: '开启HTTPS过滤之后，会过滤所有的广告',
      routeModeTip: '该选项可以切换DPN的运行模式',
      tunnelTip: '请选择全路由隧道',
      success: {
        getList: '刷新设备列表成功',
        httpsFilter: 'HTTPS过滤切换成功',
        routeMode: 'DPN模式切换成功',
        regionCode: 'DPN模式切换成功',
        remark: '备注修改成功',
        pinned: '成功置顶/取消置顶。请刷新页面',
        deleteDevices: '删除设备成功',
      },
      failure: {
        'getSwitch': '获取访问控制开关失败',
        'setSwitch': '设置访问控制开关失败',
        getList: '获取设备列表失败',
        getTunnels: '无法获得隧道',
        setItem: '设置设备项失败',
        httpsFilter: '禁用HTTPS过滤成功',
        routeMode: 'DPN模式切换失败',
        regionCode: 'DPN模式切换失败',
        remark: '备注修改失败',
        noSuchTunnel: '隧道不存在,请选择其他隧道',
        pinned: '固定 / 取消固定失败',
        deleteDevices: '删除设备失败',
        macActive: '由于设备处于活动状态，无法删除',
      },
      remarkLengthLimit: '长度不超过 10 个字符',
      httpsNotEnabled: 'HTTPS过滤总开关处于关闭状态，请点击跳转到该页面开启!',
      tips: {
        opened: '访问控制开启的情况下，在线设备优先使用访问控制里的DPN模式',
      },
    },
  },
  //dpnMode
  dpnMode: {
    title: '选择DPN模式',
    selectTunnel: '选择隧道',
    recommended: '推荐',
    apply: '应用',
    missingInfo: '缺少信息',
    selectionTip: {
      smart: '确认要改成智能路由模式吗？',
      full: '所有流量都将通过所选隧道。确认要改成全路由模式吗？',
      disabled: '该模式将暂时关闭DPN网络，确认要改成DPN停用模式吗？',
    },
    dpnModeShortList: {
      smart: 'DPN智能路由',
      disabled: 'DPN停用',
    },
    dpnModeLongList: {
      smart: '智能路由',
      full: '全路由',
      disabled: '停用',
    },
    killSwitchLabel: '终止开关',
    none: '关闭',
    backupTunnelLabel: '备用隧道',
    backupTunnelTip: '当智能路由模式配置的隧道出现问题时，系统会自动切换到备用隧道，直到默认隧道恢复正常。',
    tips: {
      killSwitch:
        'VPN断网保护功能是指当你的VPN连接断开时，它会自动断开你的电脑、手机或平板电脑等设备与互联网的连接。这意味着如果你的VPN停止工作，你的IP地址和其他敏感信息不会被暴露出来。因此，你的互联网连接的安全性和匿名性不会受到影响。',
      smart: [
        '1. 根据 AtomOS 内置的规则，智能匹配节点给当前需要访问的网站，保证本地访问网络速度的同时，加速访问其他国家地区的服务。',
        '2. 在智能路由模式下，不仅能够省去传统 VPN 需要不停切换国家的麻烦，也能给用户带来更加快速的网络访问体验。',
        '3. Deeper Connect 内置了一些知名站点和服务的路由规则，如果您想自定义智能路由的规则，则可以在"应用重定位”以及"DPN-路由表”里进行配置。',
        '4. 我们还会继续根据用户的反馈来增加我们的内置路由规则以及在"应用重定位”中支持更多的应用。',
      ],
      full: [
        '1. 所有经过 AtomOS 处理的网络数据都将使用指定隧道，这种使用模式和传统的 VPN 的使用方式类似。',
        '2. 注意：采用全路由模式将覆盖您在"应用重定位"和"DPN-路由表"中的配置。',
        '3. 由于去中心化网络的特点，网络中的节点来自世界各地用户设备，并非高速服务器，因此您的上网速度可能会受到影响。',
        '4. 如果您一定要使用全路由模式并且希望拥有高速的上网体验，我们将来会考虑为 VIP 用户提供特定的高速节点。',
      ],
      disabled: [
        '1. "DPN停用”将会关闭DPN功能，所有经过AtomOS处理的网络数据就会使用本地流量。',
        '2. Deeper Connect的其他网络安全功能依旧会开启。',
        '3. 您在访问某些网站遇到障碍可以停用DPN来确认是网站的问题还是DPN功能的问题。',
        '4. 如果您发现任何DPN功能的问题，欢迎您通过下面的链接来上报问题：https://support.deeper.network/hc/en-us/requests/new',
      ],
    },
    success: '操作成功',
    failure: '操作失败!',
  },
  // DefaultadBlock
  DefaultadBlock: {
    browserCacheWarning:
      '如果开启DNS广告过滤的情况下无法正常过滤广告，需要清理一下浏览器缓存并且重启一下被过滤的设备。',
    queriesFor24Hours: '过去24小时的请求',
    totalQuery: '请求总和',
    blockedQuery: '拦截请求',
    blockedPercentage: '拦截百分比',
    ruleTotal: '规则',
    adQuery: '拦截广告',
    trackerQuery: '拦截追踪',
    maliciousQuery: '拦截恶意',
    pornQuery: '拦截成人片',
    legendTotal: '总和',
    legendBlocked: '拦截',
    categoryStateEnabled: '启用',
    categoryStateDisabled: '禁用',
    categoryState2Hours: '禁用2小时',
    categoryState4Hours: '禁用4小时',
    categoryState8Hours: '禁用8小时',
    setCategoryStatesErr: '启用/禁用过滤出错',
    enableAll: '启用全部过滤',
    disableAll: '禁用全部过滤',
  },
  // CustomadBlock
  CustomadBlock: {
    missingInfoWarning: '域名缺失',
    invalidDomainError: '域名格式有误或域名太长',
    whitelist: '白名单',
    blacklist: '黑名单',
    domain: '域名',
    whitelistAddSuccess: '成功添加至白名单',
    whitelistEntryExist: '白名单中已有添加项',
    reachedWhiteMax: '已达白名单上限',
    whitelistAddError: '白名单项添加失败',
    blacklistAddSuccess: '成功添加至黑名单',
    blacklistEntryExist: '黑名单中已有添加项',
    reachedBlackMax: '已达黑名单上限',
    blacklistAddError: '黑名单项添加失败',
    whitelistDelSuccess: '白名单项删除成功',
    whitelistDelError: '白名单项删除失败',
    blacklistDelSuccess: '黑名单项删除成功',
    blacklistDelError: '黑名单项删除失败',
    import: '导入',
    fileButtonText: '点击上传',
    fileSizeOverLimit: '上传文件超过最大尺寸2MB',
    whitelistImportSuccess: '导入白名单成功, 已导入{0}项',
    whitelistImportError: '导入白名单失败',
    blacklistImportSuccess: '导入黑名单成功, 已导入{0}项',
    blacklistImportError: '导入黑名单失败',
    noValidEntry: '上传文件不含任何有效域名',
    zeroImported: '未能导入任何域名项',
  },
  // DohGuideComp
  DohGuideComp: {
    closeButton: '关闭',
    guideButton: '点击查看',
    dohMessageClickTips: '请点击"查看"以查看如何为您特定的浏览器关闭DoH的说明',
    dohMessageTxt: '为使用此功能，{0}。DoH加密DNS请求，可能会干扰某些功能。',
    dohMessage0: '请在您的浏览器中禁用DoH设置',
    guideBoxTitle: 'DoH 浏览器设置',
    guide: {
      chromeGuideImg: '/dohGuide/chrome-guide-zh.png',
      chrome: [
        {
          label: '步骤1',
          tip: '在地址栏输入：chrome://settings/security 并按回车键。',
        },
        {
          label: '步骤2',
          tip: '找到 "高级安全设置" 部分',
        },
        {
          label: '步骤3',
          tip: '禁用 "使用安全 DNS" 选项',
        },
      ],
      firefox: [
        {
          label: '步骤1',
          tip: '在地址栏输入：about:config 并按回车键。',
        },
        {
          label: '步骤2',
          tip: ' 如果出现警告页面，请点击 "接受风险并继续"。',
        },
        {
          label: '步骤3',
          tip: '在搜索框中输入：network.trr.mode 并按回车键。',
        },
        {
          label: '步骤4',
          tip: '双击找到的设置项，将数值更改为 5（禁用 DoH），然后点击 "确定"。',
        },
      ],
      edge: [
        {
          label: '步骤1',
          tip: '在地址栏输入：edge://settings/privacy 并按回车键,并找到 "安全" 部分。',
        },
        {
          label: '步骤2',
          tip: '关闭 "使用安全 DNS 来确保网络安全" 选项。',
        },
      ],
      operaGuideImg: '/dohGuide/opera-guide-zh.png',
      opera: [
        {
          label: '步骤1',
          tip: '在浏览器地址栏中输入opera://settings/?search=DNS，并按下回车键。',
        },
        {
          label: '步骤2',
          tip: '找到"使用DNS-over-HTTPS而不是系统的DNS设置"选项，确保该选项关闭。',
        },
      ],
    },
  },
  // appRelocatorData
  appRelocatorData: {
    guide: {
      youtube: {
        UA: 'https://www.deeper.network/pages/app-relocator?app=youtube&lang=zh',
      },
      nbaLeaguePass: 'https://www.deeper.network/pages/app-relocator?app=nbaLeaguePass&lang=zh',
      netflix: 'https://www.deeper.network/pages/app-relocator?app=netflix&lang=zh',
      dazn: 'https://www.deeper.network/pages/app-relocator?app=dazn&lang=zh',
      fubo: 'https://www.deeper.network/pages/app-relocator?app=fubo&lang=zh',
      tencentVideo: 'https://www.deeper.network/pages/app-relocator?app=tencentVideo&lang=zh',
    },
    countryLabel: '国家',
    tagLabel: '标签',
    typeLabel: '类型',
    country: {
      'all-countries': '全世界',
      CN: '中国',
      US: '美国',
      IN: '印度',
      CA: '加拿大',
      GB: '英国',
      NA: '其他',
      NO: '挪威',
      SG: '新加坡',
      JP: '日本',
    },
    tag: {
      'all-tags': '所有标签',
      nba: 'NBA',
      nfl: 'NFL',
      nhl: 'NHL',
      mlb: 'MLB',
      epl: 'EPL',
      ipl: 'IPL',
    },
    category: {
      'all-category': '全部类型',
      video: '视频',
      music: '音乐',
      im: '即时通讯',
      flightAndHotel: '航班&酒店',
      subscription: '订阅',
    },
    filter: '筛选',
    reset: '重置',
    submit: '完成',
    subcategory: {
      popular: '热门',
      sport: '体育',
      movie: '电影',
      tv: '电视',
    },
    app: {
      acornTv: 'Acorn TV',
      amazonPrimeVideo: '亚马逊Prime视频',
      beInSports: '贝因体育',
      crunchyroll: 'Crunchyroll',
      dazn: 'DAZN',
      disneyPlus: '迪士尼+',
      netflix: '网飞',
      plex: 'Plex',
      roku: 'Roku',
      shudder: 'Shudder',
      zattoo: 'Zattoo',
      discoveryPlus: '探索+',
      paramountPlus: '派拉蒙+',
      sportsnetNow: '罗杰斯体育网',
      tsn: 'TSN',
      bbcIPlayer: '英国广播公司iPlayer',
      hulu: '互录',
      itv: '独立电视网',
      slingTv: 'Sling',
      crackle: 'Crackle',
      pandora: 'Pandora',
      theCw: 'CW电视网',
      abc: '美国广播公司',
      nbc: '全国广播公司',
      pluto: 'Pluto TV',
      starz: 'Starz有线台',
      tbs: '美国电视频道',
      attTv: 'AT&T TV',
      channel4: '第四台',
      showtimeAnytime: 'Showtime',
      nbcSports: 'NBC体育',
      epixNow: 'Epix Now',
      mgmPlus: 'MGM Plus',
      xumo: 'Xumo',
      channel5: '第五台',
      usaNetwork: 'USA电视台',
      fxNow: 'FX Now',
      vudu: 'Vudu',
      fubo: '富波电视',
      espn: '娱乐与体育节目电视网',
      tubiTv: 'Tubi',
      ukTvPlay: 'UKTV Play',
      tvPlayer: 'TVPlayer',
      peacock: '孔雀',
      pbs: '公共广播电视',
      philo: 'Philo',
      vrv: 'VRV',
      showmax: 'Showmax',
      hboMax: 'HBO Max',
      nhl: '全国冰球联盟',
      dsTv: 'DStv',
      sSportsPlus: 'S Sports +',
      filmrise: 'FilmRise',
      cbsSports: 'CBS体育',
      nowTV: 'Now TV',
      molotovTv: 'Molotov TV',
      mlb: '美国职业棒球大联盟',
      britBox: 'BritBox',
      stirr: 'Stirr',
      skyGo: 'Sky Go',
      hotstar: '迪士尼+ Hotstar',
      sonyLiv: 'SonyLIV',
      nbaLeaguePass: 'NBA联盟通行证',
      amcPlus: 'AMC+',
      f1Tv: 'F1TV',
      frndlyTv: 'Frndly TV',
      myTf1: '法国电视一台',
      qqMusic: 'QQ音乐',
      spotify: 'Spotify',
      tidal: 'Tidal',
      youtube: 'YouTube',
      youTubeMusic: 'YouTube音乐',
      weChat: '微信',
      whatsApp: 'WhatsApp',
      line: 'Line',
      signal: 'Signal',
      agoda: 'Agoda',
      expedia: '智游网',
      flyScanner: 'Fly Scanner',
      googleFlights: 'Google Flights',
      qqSports: '腾讯体育',
      skySports: '天空体育',
      rakutenTv: '乐天电视',
      fox: 'FOX',
      nflNetwork: 'NFL Network',
      peacocktv: '英超',
      zee5: 'Zee5',
      mxPlayer: 'MX Player',
      tencentVideo: '腾讯视频',
      iqiyi: '爱奇艺',
      mangoTV: '芒果TV',
      migu: '咪咕视频',
      bilibili: '哔哩哔哩',
      youku: '优酷',
      altBalaji: 'ALT Balaji',
      willowTv: 'Willow TV',
      foxSports: 'FOX体育',
      crave: 'Crave',
      cbcGem: 'CBC Gem',
      jiocinema: 'JioCinema',
      Telegram: 'Telegram',
      Messenger: 'Messenger',
      ChatGPT: 'ChatGPT',
      CanalPlus: 'CanalPlus',
    },
    tip: {
      video: '常用的当前视频服务隧道',
      music: '常用的当前音乐服务隧道',
      im: '常用的当前即时通讯隧道',
      flightAndHotel: '常用的当前订机票酒店隧道',
      subscription: '常用的当前网上订阅服务隧道',
      backupTunnel: '备用隧道已开启，智能路由模式下添加的隧道如果暂时没有可用节点，系统将默认使用备用隧道 - {0}',
      fullTunnel: '在全路由模式下，所有应用程序都将默认使用{0}隧道。',
      noTunnelNodes: '节点数为0，无法使用。请转至隧道页面并刷新{0}隧道的节点。',
      noTunnelNodesOkText: '去刷新',
      noTunnelNodesCancelText: '关闭',
      noTunnelAdded: '您尚未添加此隧道。请转至隧道页面并添加{0}隧道以进行使用。',
      noTunnelAddedOkText: '去添加',
      noTunnelAddedCancelText: '取消',
      tunnelDeletedText: '{0} - {1} 隧道已被删除',
      netflix: '解决你使用Netflix时遇到的播放错误的问题',
      fubo: 'Fubo TV可以让你以极低成本观看观看英格兰足球直播',
      tencentVideo: '观看一场NBA比赛约$1',
      dazn: 'DAZN可以让你以极低成本观看NFL、NFL、MLB、NBA、EPL，仅需17.18美元',
      youtube: '如何让你以极低成本享受Youtube Premium',
    },
    intro: {
      acornTv:
        'Acorn TV提供来自英国和其他地方的世界级悬疑、剧情和喜剧节目的流媒体服务。您可以连续观看经典系列剧集，或在其中发现您新的最爱节目。',
      abc: '在abc.com上在线观看ABC节目。获取独家视频和免费剧集。',
      f1Tv:
        'F1 TV现已在Web、应用程序、Google TV、Apple TV、Roku和Amazon Fire TV上提供。全程直播所有F1比赛。直播转播所有大奖赛的赛道训练。',
      youtube:
        'YouTube的官方频道帮助你了解全球的最新和热门内容。观看必看的视频，涵盖音乐、文化和互联网现象等各个领域。',
      netflix:
        'Netflix是一项流媒体服务，提供了广泛的屡获殊荣的电视剧、电影、动漫、纪录片等内容，可通过数千个互联网连接设备进行观看。',
      fubo:
        '在电视上观看Fubo的最简单方法是使用Roku、Apple TV、Amazon Fire TV或Chromecast。只需在您的设备上安装Fubo应用程序即可进行流媒体播放。',
      disneyPlus: '从新发布的作品到你最喜爱的经典之作，过去、现在和未来都属于你。所有你期待的和你未曾预料的',
      crunchyroll:
        '通过Crunchyroll探索最佳的动漫和漫画流媒体内容。享受《鬼灭之刃》、《海贼王》、《进击的巨人》和《我的英雄学院》等热门作品。',
      hulu:
        '在线观看电视剧和电影。流媒体播放《实习医生格蕾》、《我们这一天》、《鲍勃汉堡店》、《布鲁克林九九》、《帝国》、《周六夜现场》等热门电视剧集，以及流行电影。',
      nbaLeaguePass: 'NBA League Pass*的订阅为球迷提供了整个赛季和休赛期的比赛直播、点播以及更多内容的访问权限。',
      peacock:
        '获取Peacock并流媒体直播体育赛事、独家原创内容、热门节目和大片电影。顶级热门作品、经典收藏、现场精彩以及更多内容，现在在Peacock上流媒体播放。纪录片、儿童电影和节目、全天候的Peacock频道、Hallmark频道等',
      qqSports:
        '腾讯体育是中国知名的体育门户网站,主要为您提供以下栏目:国内足球、国际足球、NBA、CBA、综合体育、奥运、直播、彩票、竞猜等...',
      rakutenTv:
        '直播电视 | 免费的前15个频道 · 免费 | 免费观看热门电影 · 商店 | 热门电影购买或租赁 · 类型 · 免费 | 最近免费添加的内容 · 免费',
      espn: '访问ESPN获取实时比分、精彩瞬间和体育新闻。在ESPN+上观看独家赛事，并参与幻想体育游戏',
      beInSports:
        '透过beIN SPORTS USA揭开体育世界的面纱。获取主要足球联赛等的独家精彩回放、最新新闻、数据、比分和赛程。',
      dazn: 'DAZN是全球首个真正专注于实时体育直播的流媒体平台...',
      cbsSports:
        '免费的24小时体育新闻网络。观看CBS Sports HQ，提供关于比赛的所有重要信息。更多精彩集锦、顶级投注和成功的幻想建议。',
      fox:
        '观看FOX体育，并查看实时比分、赔率、球队新闻、选手新闻、直播、视频、统计数据、排名和赛程，涵盖NFL、MLB、NASCAR、WWE、NBA、NHL等内容。',
      nflNetwork: '获取NFL频道的信息、节目更新等！NFL Network是唯一由NFL拥有和运营的全年运营网络。',
      peacocktv: '在NBC.com上观看当前和经典NBC节目的完整集数。此外，您还可以找到剪辑、预告片、照片和独家在线功能。',
      sportsnetNow:
        '《MLB顶级表现》·MLBSportsnet视频：蓝鸟队的斯普林格以领先击球的方式庆祝生日，打出远离本垒方向的全垒打·MLBSportsnet视频：勇士队的阿库尼亚 Jr.',
      nbcSports: '获取NBC Sports的最新体育新闻和比分，保持与时俱进。',
      tsn:
        '请访问TSN网站以获取关于澳网、NFL季后赛、超级碗、NHL交易截止日期等的最新体育新闻报道、比分、精彩瞬间和评论。',
      nhl: '官方的国家冰球联盟（National Hockey League）网站包括新闻、名单、统计数据、赛程、球队和视频等内容。',
      hotstar:
        'Disney+ Hotstar是印度最大的高级流媒体平台，拥有超过10万小时的戏剧和电影资源，涵盖了17种语言，并提供所有主要体育赛事的直播。',
      disneyPlus:
        '从新版本到您最喜欢的经典，过去、现在和未来都是您的。 你所期望的一切，以及你所没有的一切。 © 2023 迪士尼。 品牌：漫威、皮克斯、迪士尼、国家地理、星球大战。',
      skySports:
        '观看您最喜爱的体育赛事的最佳直播报道：足球、高尔夫、橄榄球、板球、F1赛车、拳击、美式足球、篮球，以及最新的体育新闻...',
      sonyLiv:
        '顶级高级流媒体平台，您可以在此观看热门电视节目、电影、体育赛事和网络剧。尽情享受您最喜爱的直播电视频道的在线观看体验。',
      jiocinema:
        '观看最新的电影、电视节目、Voot节目、现场体育赛事和精彩片段，使用印地语、英语和其他地区语言跨各种类型在线观看。',
      amazonPrimeVideo:
        '流式传输电影、节目、获奖的亚马逊原创内容、高级频道、体育赛事等。用免费试用来观看令人上瘾的电视剧、大片、热门节目等。支持4K+HDR的内容。热门剧集和电影。新发布的内容。在任何设备上观看。',
      zee5:
        '您现在可以以独家50％的折扣购买 ZEE5™ 上的您最喜爱的 Desi 内容。立即购买，尽情观看您在 ZEE5™ 上的最爱内容。年度套餐享受50％的折扣。购买链接。拥有4000部以上的超级爆款电影，1600个以上的节目，以及260部原创作品。支持18种语言。',
      mxPlayer:
        'MX Player 是印度最高级的 OTT 服务，您可以在其中播放最新的音乐视频，观看新旧电影、电视节目、喜剧节目，流行歌曲等。',
      tencentVideo:
        '腾讯视频致力于打造中国领先的在线视频媒体平台，以丰富的内容、极致的观看体验、便捷的登录方式、24小时多平台无缝应用体验以及快捷分享的产品特性，主要满足用户在线 …',
      iqiyi:
        '爱奇艺（iQIYI.COM）是拥有海量、优质、高清的网络视频的大型视频网站，专业的网络视频播放平台。爱奇艺影视内容丰富多元，涵盖电影、电视剧、动漫、综艺、生活、 ...',
      youku: '优酷- 为好内容全力以赴- 海量正版高清视频在线观看。',
      migu:
        '咪咕视频全程直播北京冬奥会等国内外各大体育赛事，CBA直播，亚冠、中超、西甲、德甲、意甲、欧联、欧冠直播，女排、乒乓、滑冰、斯诺克直播，WWE、UFC直播， ...',
      mangoTV:
        'Mango TV - The Online Video Website Everyone is Watching - Watch Popular Variety Shows, Latest Movies, and TV Dramas Online.',
      nbc: '在NBC.com上观看当前和经典NBC节目的完整剧集。此外，您还可以找到片段、预告片、照片和独家在线内容。',
      foxSports:
        '观看FOX体育，并查看实时比分、赔率、球队新闻、选手新闻、直播、视频、统计数据、排名和赛程，涵盖NFL、MLB、NASCAR、WWE、NBA、NHL等内容。',
      nfl: '获取NFL频道的信息、节目更新等！NFL Network是唯一由NFL拥有和运营的全年运营网络。',
      willowTv:
        '成功登录后，您可以在Willow上观看直播、精彩集锦和重播。Spectrum（斯佩克特鲁姆），Optimum（奥普蒂姆），Fios（菲奥斯），Dish（迪什），Optimum TV（奥普蒂姆电视），Xfinity（康卡斯特）和Time Warner（时代华纳）等服务商都可使用。',
      mlb: '我们的报道内容包括音频和视频片段、采访、统计数据、赛程安排以及独家报道故事。',
      hboMax:
        'HBO Max是一个独立的流媒体平台，将所有HBO内容与更多受欢迎的电视剧、大片电影和全新的Max Originals捆绑在一起，适合所有人观看。',
      amcPlus:
        '摆脱平凡，今天在AMC+上探索更多独特、引人入胜的体验。独家内容，扣人心弦的电影和经典系列剧集 - 在AMC+的史诗系列中找到一切。优质剧情片。点播服务。独家内容。独家电影。新剧集。无广告。',
      attTv: '获取我们最佳的电视和互联网优惠，包括DIRECTV和家庭互联网的优惠，包括AT&T Fiber。',
      britBox:
        '畅享大量英国电视剧的连续播放，包括悬疑、喜剧、剧情、纪录片、生活方式等多种类型，这是迄今最大的英国电视剧流媒体收藏。',
      crackle:
        '在Crackle上，免费观看好莱坞电影-未经剪辑和修改。涵盖您喜爱的动作、恐怖、科幻、犯罪和喜剧等各种类型。只需点击播放即可。',
      discoveryPlus:
        '您可以在移动设备、平板电脑、计算机、游戏机和连接电视上进行流媒体播放。· 这里有适合每个人的内容！· discovery+是食品、家居等领域的流媒体服务提供商。',
      mgmPlus: '订阅MGM+，即可畅享数千部无广告的电影和电视剧。随时取消订阅。',
      filmrise: '我们积极应对当今不断变化的行业特性，采用多层次策略，致力于提供最佳的电影和电视内容。',
      frndlyTv:
        '使用Roku、Amazon Fire TV或Frndly TV，您可以观看40多个直播频道和点播内容。其中包括全部三个Hallmark频道、A&E等。您还可以在移动设备上随时随地观看。',
      fxNow: '在FX、FXX和FXM网络上观看FX原创电视节目、电影和直播电视。',
      paramountPlus:
        '在一个平台上流媒体播放数千集剧集、现场体育赛事和独家原创内容。访问数百部标志性电影，从屡获殊荣的经典电影到热门新片应有尽有。发现新剧集进行连续观看。热门真人秀系列。热门MTV节目。超过30,000集剧集。突发新闻报道。热门CBS节目。',
      pbs:
        'POV电影以其难忘的叙事和及时性而闻名。立即观看！POV是电视上历史最悠久的独立纪录片展示节目。了解更多！独立非虚构作品。超过500部电影呈现。难以忘怀的故事。',
      philo:
        '为什么要为电视付更多钱？Philo每月仅需25美元，提供直播电视和点播内容。您可以在所有设备上流媒体播放您喜爱的节目、电影等。',
      plex:
        'Plex是一款免费的流媒体应用，无需信用卡、订阅费用或隐藏费用。为了保持我们的服务免费，我们包含广告以实现盈利。',
      pluto:
        '从最新的热门电影到您喜爱的剧集完整季度，应有尽有。数百个频道。零花费。适用于所有设备。立即开始观看！适用于所有设备。连续观看。点播电视和电影。数百个频道。立即流媒体播放。永不付费。',
      roku:
        'Roku设备使得流媒体电视变得简单。从播放器、智能电视，甚至智能家居产品，让您的家庭更加安全，找到适合您的完美Roku产品...',
      showtimeAnytime:
        '免费试用SHOWTIME，即可流媒体播放原创系列剧、电影、体育赛事、纪录片等内容。此外，您还可以订购按次付费的拳击比赛-无需订阅。',
      shudder:
        '从每月仅4.99美元的套餐开始，您可以在一个平台上获得Shudder的全部内容，并观看《行尸走肉》宇宙、安·赖斯等热门系列剧。免费试用Shudder...',
      slingTv:
        'Sling TV是第一个基于应用的电视服务，可让您通过互联网流媒体播放直播电视和点播内容。无论您身在何处，都可以观看直播节目...',
      starz:
        'STARZ通过大胆的叙事将多样的视角呈现生活。注册以流媒体播放原创系列剧、电影、额外内容等，随时点播，无广告。',
      stirr:
        '...和娱乐节目。直播和点播。STIRR City和National已设定为您的本地频道。开始观看。00:05。100多个频道，超过5000小时的电视和...',
      tbs:
        '《在高地上》（In the Heights）· 《Dynamite》本周节目 · TBS上的MLB季后赛预告 · 美国老爸的多元宇宙（第18季第14集）',
      theCw:
        'CW网络的官方主页，提供《全美足球队》（All American）、《超人与洛伊丝》（Superman & Lois）、《行侠仗义》（Walker）、《FBoy Island》等精彩系列剧集、电影、体育赛事等。',
      tubiTv:
        'Tubi是领先的免费高级点播视频流媒体应用程序。我们拥有超过50,000部电影和电视节目的最大内容库，提供最佳的...',
      usaNetwork:
        '您可以在USANetwork.com上观看当前和经典的USA节目的完整剧集。此外，您还可以找到剧集片段、预告片、照片和独家在线特色内容.',
      vudu:
        '使用Vudu租赁、购买和观看电影和电视节目。您可以在Vudu应用上在线观看，或使用您喜爱的连接设备观看。无需订阅，免费注册。',
      xumo: 'Xumo成立的目的是为整个娱乐行业开发和提供下一代流媒体平台。了解我们的使命，并参与其中...',
      jiocinema:
        '观看最新的电影、电视节目、Voot节目、现场体育赛事和精彩片段，使用印地语、英语和其他地区语言跨各种类型在线观看。',
      altBalaji:
        '在线高清观看最新的网络剧、原创作品和电影。可从惊悚、动作、成人、喜剧、家庭剧等多种类型中选择，并支持多种语言。',
      bilibili:
        '哔哩哔哩（bilibili.com)是国内知名的视频弹幕网站，这里有及时的动漫新番，活跃的ACG氛围，有创意的Up主。大家可以在这里找到许多欢乐。',
      crave: '一个由恐怖电影经典、令人恐惧的电视剧系列和万圣节剧集精心策划的合集...喜剧界中的意外名声和社会压力。',
      cbcGem: "在任何设备上观看 Schitt's Creek、Luther、Kim's Convenience 等热门节目以及电影和直播电视。",
      bbcIPlayer: '观看直播 BBC 电视频道、欣赏您错过的电视节目并在BBC iPlayer上观看独家内容。',
      channel4: '在线观看您最喜欢的节目，来自Channel 4、E4 和 Walter Presents',
      channel5:
        '欢迎来到My5，您可以在这里追赶和流媒体观看您最喜欢的来自Channel 5、5STAR、5USA、5SELECT、5ACTION等频道的剧集合集。',
      dsTv: '最好的电视娱乐节目触手可及。 观看最新的体育赛事、电影、连续剧和真人秀，并访问 DStv 电视指南。',
      molotovTv: 'Molotov是免费的。请随意增加其他功能。·Molotov象征着电视的未来·电视界的Spotify·电视可以是...',
      myTf1:
        '观看TF1、TMC、TFX、TF1 Séries Films和LCI等直播节目，超过50个独家专题频道以及超过10,000小时的视频内容，仅在MYTF1上...',
      sSportsPlus:
        '通过Sports Plus，您可以每月享受超过700小时的现场直播，并获得从Serie A到La Liga，从Formula 1到NBA，从EuroLeague到UFC的数百种体育内容，一切尽在您的指尖...',
      showmax: 'Showmax的订阅费用各不相同，因为Showmax提供了各种计划以满足不同的偏好和预算，价格仅从R39开始...',
      itv: 'ITVX是英国最新鲜的流媒体服务，提供独家新节目、大片电影、现场活动和成千上万部剧集，全部在一个平台上。',
      pandora: '在全新的Pandora上播放你喜爱的歌曲、专辑、播放列表和播客。注册订阅计划，畅享无广告、按需播放。',
      qqMusic:
        'QQ音乐是腾讯公司推出的一款网络音乐服务产品，海量音乐在线试听、新歌热歌在线首发、歌词翻译、手机铃声下载、高品质无损音乐试听、海量无损曲库、正版音乐下载、空间 ...',
      spotify: 'Spotify是一种数字音乐服务，让您可以访问数百万首歌曲。',
      tidal: 'TIDAL是一个以艺术家为先、以粉丝为中心的音乐流媒体平台，为全球音乐社区提供超过1亿首高保真音质的歌曲。',
      weChat: '可在各种平台上使用；享受群聊；支持语音、照片、视频和文字消息。',
      whatsApp:
        '使用WhatsApp Messenger与朋友和家人保持联系。WhatsApp是免费的，提供简单、安全、可靠的消息和通话功能，可在...',
      line: '新的通讯应用程序，可以让您在任何时间和任何地点免费进行语音通话和发送免费消息，全天候24小时！',
      signal: '免费分享文本、语音消息、照片、视频、GIF和文件。Signal使用您手机的数据连接，以避免短信和彩信费用。',
      agoda:
        '立即预订，稍后付款，免费取消。24/7提供您语言的实时客户服务。从经济型到豪华型，酒店和住宅。您可信赖的房间和航班旅行伙伴。',
      expedia:
        '欢迎来到您的一站式旅行网站，实现您梦寐以求的假期。将您的住宿与租车或航班捆绑在一起，可以节省更多。搜索我们的灵活选项以找到最适合您的…',
      flyScanner:
        '轻松获取从19美元起的折扣航班。在一次搜索中比较并预订，快捷方便。比较便宜的航班，酒店和租车。找到数以千计的优惠交易，获取最好的一个！',
      nowTV:
        '一个NOW会员资格的费用是多少? NOW会员资格让您能按照自己的方式观看想看的内容。 您可以加入娱乐，影院，体育和Hayu。',
      TVPlayer:
        'TVPlayer是最受欢迎的电视频道和高质量点播节目的所在地，这些节目只在TVPlayer上。观看40多个现场频道和数千...',
      ukTvPlay: 'UKTV Play 是 Dave，Drama，W & Yesterday 频道的点播之家。观看直播电视，流媒体和追赶您喜欢的节目。',
      zattoo: '在所有设备上直播和时移您喜欢的电视节目。最佳的全高清图像质量。比有线电视更好。提供点播和直播电视内容',
      skyGo:
        '通过Sky包在您的手机、平板电脑、笔记本电脑或电脑上，或者通过Apple TV设备，随时随地观看直播、按需节目和追播电视，并且可以轻松在不同设备间切换',
      Telegram: 'Telegram旨在提供安全、快速、简洁的通讯体验，并以其强大的加密功能和丰富的功能而闻名。',
      Messenger:
        'Messenger是Facebook提供的即时通讯应用程序，它允许用户通过文字消息、语音通话、视频通话、发送图片、表情符号等方式与他人进行沟通。Messenger最初是作为Facebook网站的一个功能，后来发展成为一个独立的移动应用程序，并提供了更多的功能和服务。',
      ChatGPT:
        'ChatGPT是由OpenAI开发的人工智能语言模型，基于GPT（Generative Pre-trained Transformer）架构。它通过大规模的自然语言处理数据进行训练，能够理解和生成自然语言文本。',
      CanalPlus:
        'CANAL+是一家法国的付费电视频道，它是法国最大的付费电视网络之一，也是欧洲最重要的付费电视服务提供商之一。该频道提供了各种各样的节目，包括电影、体育、新闻、纪录片、电视剧等',
    },
    selectTunnel: '选择隧道',
    notSetLabel: '不设置',
    directAccessLabel: '不走隧道',
    otherTunnelLabel: '其他隧道',
    recommendedTunnelLabel: '推荐隧道',
    error: {
      getApps: '获取应用失败',
      getTunnels: '获取隧道列表失败',
      invalidApp: '无效的应用',
      tunnelNotExist: '隧道不存在',
      set: '设置隧道失败',
      unset: '取消设置隧道失败',
    },
    success: {
      set: '设置隧道成功',
      unset: '取消设置隧道成功',
    },
    searchInputPlaceholder: '搜索你需要的服务',
    discountIntro: percent => `每月节省 ${percent} 订阅费`,
    discountPercent: percent => `-${percent} (美元)/月`,
    guideButtonLabel: '查看详情',
  },
  // betanet
  betanet: {
    bindWalletSuccess: '成功绑定钱包',
    deviceBound: '设备当前已与另一钱包绑定',
    walletTaken: '钱包已被另一设备占用',
    deviceTypeIncompatible: '设备与钱包不兼容',
    invalidMnemonic: '无效的钱包助记词',
    invalidPrivateKey: '无效的钱包私钥',
    bindWalletError: '绑定钱包失败',
    notBound: '当前用户未绑定钱包',
    unbindWalletSuccess: '成功解绑钱包',
    noActiveBinding: '设备未与指定钱包绑定',
    unbindWalletError: '解绑钱包失败',
    walletManagement: '钱包管理',
    bindWallet: '绑定钱包',
    downloadPrivateKey: '下载私钥',
    downloadKeyError: '下载私钥失败',
    unbindBtnText: '解绑钱包',
    unbindWarning: '务必确保您已下载私钥，请输入"我已下载私钥"并继续。',
    unbindWarningWords: '我已下载私钥',
    generateWallet: '生成钱包',
    verifyMnemonic: '验证助记词',
    mnemonicMismatch: '助记词验证失败',
    bindBtn1Text: '绑定钱包(助记词)',
    bindBtn2Text: '绑定钱包(私钥)',
    dprManagement: 'DPR管理',
    balanceLabel: 'DPR余额',
    balance: '余额',
    creditLabel: '信用',
    operationLabel: '操作',
    amtOwingLabel: '系统已为您垫付',
    dailyConsumedLabel: '当日使用流量',
    dailySharedLabel: '当日共享流量',
    deposit: '存入',
    withdraw: '提取',
    publicKey: '公钥',
    mnemonicInstruction: '请记下助记词. 您需要在下一步中进行验证.',
    enterMnemonic: '输入助记词',
    enterMnemonicTip: '请按顺序点击助记词。',
    resetMnemonic: '重置',
    uploadPrivateKey: '上传私钥',
    getWalletError: '获取钱包信息失败',
    deviceWalletMismatch: '设备与钱包不匹配',
    walletNotFound: '未找到钱包',
    generateWalletError: '生成钱包失败',
    missingMnemonic: '助记词缺失',
    missingPrivateKeyFile: '私钥文件缺失',
    privateKeyFileOversize: '私钥文件超过10KB限额',
    transferTitle: '转账',
    transferHistoryTitle: '转账记录',
    transferTo: '收款人',
    transferAmt: '转账数额 (最多到6位小数)',
    invalidTo: '无效的收款人',
    invalidAmt: '无效的转账数额',
    amtOutOfRange: '转账金额必须为正且不得超过100万',
    transferSuccess: '转账成功',
    senderRecipientSame: '发款人不可与收款人相同',
    recipientNotFound: '未找到收款人',
    insufficientFund: '因余额不足而转账失败',
    transferError: '转账失败',
    votingResNotRetrieved: '未获取委托结果',
    candidateSelected: '您委托的验证候选人被选中',
    candidateNotSelected: '您委托的验证候选人未被选中',
    votingResNotReady: '当前不可查询委托结果',
    notVoted: '您未委托任何验证候选人',
    candidateNotFound: '验证候选人未找到',
    votingResRetrievalErr: '获取委托结果失败',
    from: '转出地址',
    to: '转入地址',
    type: '类型',
    amount: '数量',
    time: '时间',
    txnTypes: {
      101: '初始奖励',
      201: '手动转账',
      301: '委托报酬',
      401: '微支付',
    },
    walletAddress: '钱包地址',
    notInGenesisNodeList: '创世节点只能绑定在创世矿机上。',
    onChainStatusLabel: '上链状态',
    onChainStatusDesc: {
      unknown: '-',
      onChain: '是',
      notOnChain: '否',
    },
    pwMgmtSection: {
      title: '密码管理',
      setBtnText: '设置密码',
      resetBtnText: '重置密码',
    },
    passwordModal: {
      title: '设置钱包密码',
      onePlaceholder: '输入密码',
      twoPlaceholder: '重复输入密码',
      btnText: '确认',
    },
    setPasswordMsg: {
      mismatch: '密码不匹配',
      weak: '密码长度必须至少为 8 个字符, 并且包含小写, 大写和数字字符',
      alreadySet: '钱包密码已设置过',
      genericErr: '设置钱包密码失败',
      success: '设置钱包密码成功',
    },
    authModal: {
      title: '钱包密码',
      inputPlaceholder: '输入钱包密码',
      btnText: '继续',
    },
    passwordNotSet: '钱包密码未设置',
    passwordIncorrect: '钱包密码错误',
    resetModal: {
      disclaimer: '一旦重置钱包密码, 您的设备将与当前绑定的任何钱包解绑. 请输入 "我确认重置钱包密码" 以便继续',
      consent: '我确认重置钱包密码',
    },
    resetPasswordMsg: {
      noActiveBinding: '设备未与指定钱包绑定',
      unbindErr: '解绑钱包失败',
      genericErr: '重置钱包密码失败',
      success: '重置钱包密码成功',
    },
    unstaking: {
      label: '质押信息',
      getErr: '获取质押终止选择失败',
      alreadyChosen: '您已经选择过终止质押',
      expired: '质押终止期已结束',
      invalidCreditData: '该钱包未入网',
      phase1IsNotOver: '该钱包第一期质押活动未结束',
      setErr: '设置质押终止选择失败',
      announcementTitle: '亲爱的staking节点用户：',
      announcement: {
        continueStaking:
          '质押续约：您的staking活动已自动进入第二期;第二期质押期为6个月。基础质押(Basic Mining) & 创世节点 (Genesis) 的 APY 于第二个期仍保持不变。',
        duringStaking: '第二期质押期间：您可以随时点击该按钮终止质押; 这个选择是不可逆！',
        stopStaking: '质押终止：您将进入3个月的线性释放阶段；',
        releaseStaking: '质押释放：DPR将每天自动释放到您的DeeperChain钱包。',
      },
      tipTitle: '友情提醒：',
      tip: {
        afterStopStaking_1: '1. 终止质押后，质押获得的信用将会被抹除（质押期间因带宽分享积累的信用会被保留）',
        afterStopStaking_2:
          '2. 取消创世节点的质押将失去其创世位置，将永远失去作为创世节点的机会。您的设备将无法立即参与基础质押 2.0，但通过带宽共享产生的有机信用评分将继续。',
        afterStopStaking_3: '3. 终止质押后，您DPR的质押奖励将会立即停止',
        afterStopStaking_4: '4. 终止质押后，将无法立即获得重新质押的机会（需等待后续公告）',
        afterStopStaking_5: '5. 终止质押后，第一期270次奖励全部达到后才会终止',
      },
      btn: {
        cancel: '明白，保持质押',
        ok: '退出质押',
      },
    },
    staking: {
      walletInfo: '钱包信息',
      campaignLabel: '质押活动类型',
      campaignText: {
        0: '黄金创世节点',
        1: '白银创世节点',
        2: '质押挖矿1.0',
        3: 'DPRB 挖矿',
        4: '质押挖矿2.0',
        7: '质押挖矿2.0',
        8: '质押挖矿2.0',
      },
      creditLabel: '目前信用分',
      creditMaxLabel: '信用分最大值',
      creditLevelLabel: '信用等级',
      stakeAndBurn: '质押与燃烧',
      stakeForCredit: '增加质押',
      dailyReward: '日收益',
      view: '查看',
      payment: '需支付',
      insufficientBalance: '余额不足',
      stakeSuccess: '请求已发送，请耐心等待信用分更新。',
      stakeError: '请求发送失败，请检查钱包相关信息或咨询客服。',
      burnForCredit: '燃烧DPR补信用分',
      burnSuccess: '请求已发送，请耐心等待信用分更新。',
      burnError: '请求发送失败，请检查钱包相关信息或咨询客服。',
    },
  },
  // ChangePasswordData
  ChangePasswordData: {
    successMsg: '修改密码成功',
    newPasswordMismatch: '两次输入的新密码不匹配',
    oldPasswordMismatch: '旧密码错误',
    samePasswordError: '新密码与旧密码相同',
    genericError: '修改密码出错',
    title: '修改密码',
    oldPwd: '请输入旧密码',
    newPwd: '请输入新密码',
    newPwdAgain: '再次输入新密码',
    okText: '确认',
  },
  //dailyTraffic
  dailyTraffic: {
    historySize: '天数',
    chartTitle: '流量',
    uptimeChartTitle: '运行时间',
    hour: '小时',
    minute: '分',
    second: '秒',
  },
  // flow
  flow: {
    totalTrafficLabel: '流量总计',
    localTraffic: '本地流量',
    clientTraffic: '客户端流量',
    serverTraffic: '服务器流量',
    upload: '上传',
    download: '下载',
    sessionSpeed: '会话速度',
  },
  // login
  login: {
    pageTitle: '欢迎来到 Deeper Network!',
    username: '用户名',
    password: '密码',
    btnText: '登录',
    successMsg: '登录成功',
    missingField: '用户名或密码缺失',
    wrongField: '用户名或密码错误',
    invalidToken: '无效登录数据',
    expiredToken: '登录数据已过期',
    genericError: '登录错误',
    tooManyAttempts: '登录尝试次数过多',
  },
  //notification
  notification: {
    search: '搜索',
    CP: '控制层',
    DP: '数据层',
    MP: '管理层',
  },
  // DomainConfig
  domainConfig: {
    whiteList: '智能路由列表',
    blackList: '直达路由列表',
    domain: '域名',
    tunnel: '隧道',
    selectTunnel: '选择隧道',
    region: '区域',
    country: '国家或地区',
    noTunnel: '不走隧道',
    convertedTip: '网址已自动转换成域名',
    urlInputPlaceholder: '例如: https://www.google.com 或 google.com',
    missingInfoWarning: '请填写完整信息',
    invalidDomainError: '域名格式有误或域名太长',
    whitelistAddSuccess: '成功添加至智能路由列表',
    existInBlacklist: '添加项存在于直达路由列表中',
    whitelistEntryExist: '智能路由列表中已有添加项',
    tunnelNotExist: '隧道不存在，无法添加',
    whitelistAddError: '智能路由列表项添加失败',
    blacklistAddSuccess: '成功添加至直达路由列表',
    existInWhitelist: '添加项存在于智能路由列表中',
    blacklistEntryExist: '直达路由列表中已有添加项',
    blacklistAddError: '直达路由列表项添加失败',
    conflict: '由于冲突无法添加项',
    whitelistDelSuccess: '智能路由列表项删除成功',
    domainNotExist: '所选域名不存在',
    whitelistDelError: '智能路由列表项删除失败',
    blacklistDelSuccess: '直达路由列表项删除成功',
    blacklistDelError: '直达路由列表项删除失败',
    backupTips: '备用隧道已开启，智能路由模式下添加的隧道如果暂时没有可用节点，系统将默认使用备用隧道 - {0}。',
    tips: {
      smartRouteList: '根据您的需求，您可以手动添加您想要访问的域名以及对应的国家。',
      directRouteList: '在此处添加的域名将不通过DPN隧道，而是直接使用本地网络。',
    },
  },
  // ipConfig
  ipConfig: {
    whiteList: '智能IP列表',
    blackList: '直达IP列表',
    ip: 'IP',
    tunnel: '隧道',
    selectTunnel: '选择隧道',
    region: '区域',
    country: '国家或地区',
    noTunnel: '不走隧道',
    convertedTip: '网址已自动转换成IP',
    urlInputPlaceholder: '例如：https://xxx.xxx.xxx.xxx 或 xxx.xxx.xxx.xxx',
    missingInfoWarning: '请填写完整信息',
    invalidIPError: 'IP格式有误',
    whitelistAddSuccess: '成功添加至智能IP列表',
    existInBlacklist: '添加项存在于直达IP列表中',
    whitelistEntryExist: '智能IP列表中已有添加项',
    tunnelNotExist: '隧道不存在，无法添加',
    whitelistAddError: '智能IP列表项添加失败',
    blacklistAddSuccess: '成功添加至直达IP列表',
    existInWhitelist: '添加项存在于智能IP列表中',
    blacklistEntryExist: '直达IP列表中已有添加项',
    blacklistAddError: '直达IP列表项添加失败',
    whitelistDelSuccess: '智能IP列表项删除成功',
    ipNotExist: '所选IP不存在',
    whitelistDelError: '智能IP列表项删除失败',
    blacklistDelSuccess: '直达IP列表项删除成功',
    blacklistDelError: '直达IP列表项删除失败',
    backupTips: '备用隧道已开启，智能路由模式下添加的隧道如果暂时没有可用节点，系统将默认使用备用隧道 - {0}。',
    tips: {
      smartRouteList: '根据您的需求，您可以手动添加您想要访问的IP以及对应的国家。',
      directRouteList: '在此处添加的IP将不通过DPN隧道，而是直接使用本地网络。',
    },
  },
  // queryRouting
  queryRouting: {
    title: '查询路由',
    searchLabel: '查询配置',
    search: '搜索',
    searchResult: '搜索结果: ',
    invalidRoutingError: '查询格式有误',
    defaultConfig: '默认配置',
    userConfig: '用户配置',
    routingNotFound: '路由未找到',
    queryRoutingError: '查询路由失败',
    convertedTip: '网址已自动转换成域名或IP',
    urlInputPlaceholder: '例如: google.com 或 xxx.xxx.xxx.xxx',
  },
  // rustdesk
  rustdesk: {
    ipLabel: '您的IP地址是',
    noPubIp: '您没有公共 IP.',
    vpnUse: '您可以使用Wireguard VPN端口转发来获得公共IP. 请注意这个public IP只能用于RustDesk服务而不能提供DPN服务.',
    config: {
      title: 'RustDesk配置',
      switch: '开/关',
      forceKey: '要求公钥一致',
      relay: '英文逗号分隔的中继服务器 (host:port), 例如 www.example.com:21116,192.168.1.1:21117',
      port: '端口 (2-65532)',
      saveBtnText: '保存',
      pubKey: '公钥',
      emptyPubKey: '没有公钥',
      resetBtnText: '重置公钥',
    },
    vpn: {
      title: 'Wireguard VPN 端口转发',
      switch: '开/关',
      ip: 'Wireguard 服务器 IP',
      port: 'VPN 转发端口',
    },
    guide: {
      title: '配置教程',
      stepOne: '点击如图所示按钮',
      stepTwoA: '将对应栏目填写到表单中并点击保存',
      idAndRelay: 'ID/中继服务器',
      idServer: 'ID服务器:',
      relayServer: '中继服务器:',
      apiServer: 'API服务器:',
      serverKey: 'Key:',
      stepTwoB: '或者您还可以复制配置到剪切板并在RustDesk中导入',
      copyBtnText: '复制服务器配置到剪切板',
    },
    success: {
      setSwitch: '切换 RustDesk 成功',
      setForced: '要求公钥一致成功',
      setRelayAndPort: '中继和端口保存成功',
      resetPubKey: '重置公钥成功',
      setVpnSwitch: '切换 Wireguard VPN 成功',
      copyToClipboard: '复制到剪贴板成功',
    },
    error: {
      getNetworkAddress: '获取 IP 失败',
      getConfig: '获取 RustDesk 配置失败',
      setSwitch: '切换 RustDesk 失败',
      setForced: '要求公钥一致失败',
      invalidPort: '无效端口',
      invalidRelay: '无效中继',
      setRelayAndPort: '保存中继和端口失败',
      resetPubKey: '重置公钥失败',
      getVpnConfig: '获取 Wireguard VPN 配置失败',
      setVpnSwitch: '切换 Wireguard VPN 失败',
      copyToClipboard: '复制到剪贴板失败',
      getVersion: '获取版本号失败',
      getHbbrLog: '获取HBBR日志失败',
      getHbbsLog: '获取HBBS日志失败',
    },
  },
  // sharing
  sharing: {
    sharingTitle: '共享: ',
    btSharingTitle: 'Bit Torrent 共享: ',
    enabled: '启用',
    disabled: '停止',
    sharingTrafficLimit: '月共享流量上限',
    sharingBandwidthLimit: '共享带宽限速',
    unlimited: '不限速',
    limited: '限速',
    setLabel: '设置',
    checkStartEndTime: '请选择开始时间和结束时间',
    time: '时间',
    record: '记录',
    startTime: '开始时间',
    endTime: '结束时间',
    keyword: '关键词',
    search: '搜索',
    webToSharingTitle: '应用网页过滤规则',
    webToSharingDesc: '应用设备内置的DNS广告过滤和自定义DNS过滤规则',
    yes: '是',
    no: '否',
    download: '明文日志',
    export: '加密日志',
    success: {
      enableTrafficLimit: '已启用每月共享流量限制。 请设定限制！',
      setSharingTrafficLimit: '共享流量上限设置成功',
      enableBandwidthLimit: '共享带宽限速已开启. 请设置上限于15Mbps和1024Mbps之间!',
      setSharingBandwidthLimit: '共享带宽限速设置成功',
    },
    errors: {
      getSharingState: '获取共享状态失败',
      switchSharingState: '切换共享状态失败',
      exceedsLimit: '启用共享失败，已超流量上限',
      getSharingTrafficLimit: '获取共享流量上限失败',
      setSharingTrafficLimit: '设置共享流量上限失败',
      invalidTrafficLimit: '流量限制必须为正数',
      setSharingBandwidthLimit: '设置共享带宽上限失败',
      invalidBandwidthLimit: '带宽上限需在15Mbps和1024Mbps之间',
      getSharingFilterConfig: '获取共享过滤配置失败',
      setSharingFilterConfig: '设置共享过滤配置失败',
    },
  },
  // system
  system: {
    hardwareInfo: '硬件信息',
    softwareInfo: '软件信息',
    networkAddress: '网络地址',
    sessionInfo: '会话信息',
    upgradeInfo: '升级信息',
    sn: '序列号',
    cpu: 'CPU',
    cores: '核心',
    memory: '已用/可用',
    systemVersion: '系统版本',
    appidSignVersion: '流量协议识别特征库',
    urlFilterSignVersion: '网页过滤特征库',
    https: 'HTTPS',
    dns: 'DNS',
    rules: '规 则',
    ip: 'IP地址',
    routerMac: '路由器Mac',
    gatewayMac: '网关Mac',
    maxSession: '最大会话数',
    currentSession: '当前会话数',
    tcpUdpIcmp: 'TCP/UDP/ICMP/TUNNEL',
    autoUpdate: '自动更新',
    manualUpgrade: '手动升级',
    latestVersion: '最新版本',
    upgrading: '正在升级...',
    systemStatusHistory: '系统历史状态',
    cpuUsage: '24小时CPU使用率',
    memoryUsage: '24小时内存使用率',
    off: '否',
    on: '是',
    nowCheckVersion: '当前正在检查版本',
    nowLatestVersion: '当前已是最新版本',
    nowNewTestVersion: '当前正在使用版本',
    upgradeLoadingText: '正在更新固件，请不要刷新页面，耐心等待更新完成~',
    upgradeSuccess: '升级成功，即将刷新页面~',
    upgradeError: {
      noImage: '升级包缺失',
      invalidImage: '无效的升级包',
      imageArchMismatch: '升级包与设备不符',
      noResponse: '设备已经重启，请您稍后刷新页面来确认升级是否成功',
      alreadyLatest: '当前版本是最新的，不必升级~',
      generic: '升级出错~',
    },
    copySuccess: '复制成功',
    copyFail: '复制失败',
  },
  // techSupport
  techSupport: {
    remoteConnection: {
      title: '远程连接',
      loading: '连接中...',
      popupTitle: '远程连接码',
      intro:
        '适用情况：<span>当你的设备网络运行正常，但遇到其他功能问题时。</span><br />手动生成一个"远程连接连接码"，将此连接码发送给我们的客服团队，我们的技术团队会快速定位并帮助您解决问题。为了确保连接码的有效性，请避免在短时间内重复生成。',
      buttonText: '生成连接码',
      generateFeatureCodeFailure: '生成连接码失败',
      tips: '如果您遇到任何问题，请将详细信息发送给我们的客户支持。我们致力于帮助您，并确保您的问题得到解决。',
      confirm: '复制并关闭',
      copySuccess: '成功复制到剪贴板',
      copyFailure: '复制到剪贴板失败',
      connectFailure: '远程连接失败',
    },
    logDownload: {
      title: '下载日志',
      loading: '下载中...',
      intro:
        '适用情况：<span>当你的设备能够登录后台，但网络连接出现问题时。</span><br />手动下载日志文件并发送给我们的客服团队，我们会了解你面临的具体情况，并据此提供更有效的帮助。',
      buttonText: '下载',
      downloadSuccess: '下载成功',
      downloadFailure: '下载失败，请稍后重试',
    },
    supportServices: {
      title: '获取你所需的协助',
      intro: '请告诉我们一些具体信息，我们会为你提供适用的解决方案。',
      buttonText: '获取帮助',
    },
    promiseTips: {
      title: '安全保障承诺：',
      intro:
        '请放心，我们<span>严格限制远程连接和日志下载功能</span>仅用于问题诊断和解决，绝不用于其他任何目的，确保您的隐私和安全得到最严格的保护。',
    },
  },
  // builtinRules
  builtinRules: [
    {
      label: '开启HTTPS过滤（包含HTTP过滤）',
      sslbypass: '开启SSL旁路',
      value: 'youtube',
    },
  ],
  // customrules
  customrules: [
    {
      label: '开启HTTPS过滤（包含HTTP过滤）',
      value: 'youtube',
    },
  ],
  // tproxy
  tproxy: {
    browserCacheWarning:
      '如果开启https广告过滤的情况下无法正常过滤广告，需要清理一下浏览器缓存；仅开启HTTPS过滤而未安装证书，不仅不能过滤广告，还可能影响列表中网站的正常访问。',
    triggerRulesTipsTitle: '提示',
    buttonAddRule: '添加规则',
    buttonInstallCertificate: '安装证书',
    missingInfoWarning: '请检查是否填写正确',
    missingInfoSuccess: '操作成功',
    missingInfoError: '操作失败',
    certificateTip: '为了保证正常使用，请确保已经安装证书',
    remind: '不再提示',
    tip: '需要按照以下步骤，才能正确的拦截广告',
    triggerRulesTips:
      '启用HTTPS过滤后，为了使Deeper Connect能对列表内的网站广告进行过滤，您需要在您的电脑上安装并信任Deeper Network的根证书。',
    triggerRulesTips2: '仅开启HTTPS过滤而未安装证书，不仅不能过滤广告，还可能影响列表中网站的正常访问。',
    mobileDownloadCertTips: '请使用Safari浏览器下载安装证书',
    androidTVTip: '如果你正在使用Android TV，请下载APP来进行使用',
    errorMessage: {
      maxLength: '规则最多1024条',
      ruleExisted: '已存在相同的域名和拦截规则表达式',
    },
    label: {
      domain: '域名',
      Pattern: '拦截规则表达式',
      ruleType: '规则类型',
      web: '浏览器',
      app: 'APP',
      all: '全部',
      Rewrite_Pattern: '复写规则表达式',
      Rewrite_Info: '复写规则信息',
      Rewrite_Action: '行为',
      enable: '开启',
      operation: '操作',
      order: '序号',
    },
    operation: {
      delete: '删除',
      edit: '编辑',
    },
    modal: {
      title: '添加规则',
      confirmText: '提交',
      cancelText: '取消',
    },
    placeholder: {
      domain: '请输入正确域名',
      Pattern: '请输入正确拦截规则表达式',
      ruleType: '请选择规则类型',
      Rewrite_Pattern: '请输入正确复写规则表达式',
      Rewrite_Info: '请输入复写规则信息',
      Rewrite_Action: '请选择行为',
    },
    builtinRules: {
      title: '广告过滤',
      filterListtitle: 'HTTPS 过滤列表',
      platform: '支持平台',
      segOptions: ['证书安装教程', '支持列表'],
    },
    customerRules: {
      title: '自定义 HTTPS 规则',
    },
    guide: {
      buttonDownloadCertificate: '点击下载证书',
      buttonDownloadApp: '点击下载SkyTube',
      tab: {
        mobileInstall: 'iPhone/iPad',
        macInstall: 'Mac OS',
        windowsInstall: 'Windows',
        androidInstall: 'Android',
        linuxInstall: 'Linux',
      },
      mobile: {
        videoTab: '视频',
        textTab: '文字',
        step1: {
          label: '步骤1',
        },
        step2: {
          label: '步骤2',
          tip: '点击允许',
        },
        step3: {
          label: '步骤3',
          tip: '打开手机 "设置"，点击 "已下载描述文件"',
        },
        step4: {
          label: '步骤4',
          tip: '点击安装，输入手机密码',
        },
        step5: {
          label: '步骤5',
          tip: '点击安装',
        },
        step6: {
          label: '步骤6',
          tip: '安装完成',
        },
        step7: {
          label: '步骤7',
          tip: '打开手机 "设置" > "通用" > "关于本机" > "证书信任设置"，启用刚才安装的证书',
        },
      },
      windows: {
        step1: {
          label: '步骤1',
        },
        step2: {
          label: '步骤2',
          tip: '点击下载的证书，点击 "安装证书"',
        },
        step3: {
          label: '步骤3',
          tip: '选择 "存储位置" 为 "本地计算机"，点击"下一步"',
        },
        step4: {
          label: '步骤4',
          tip: '选择 "将所有的证书都放入下列存储"，点击 "浏览"',
        },
        step5: {
          label: '步骤5',
          tip: '选择 "受信任的根证书颁发机构"，点击 "确定"',
        },
        step6: {
          label: '步骤6',
          tip: '点击 "下一步"',
        },
        step7: {
          label: '步骤7',
          tip: '点击 "完成"，证书安装完成',
        },
      },
      mac: {
        step1: {
          label: '步骤1',
        },
        step2: {
          label: '步骤2',
          tip: '点击下载的证书，系统会出现"添加证书"弹窗，并在"钥匙串"中选择"登录"后按"添加"。',
        },
        step3: {
          label: '步骤3',
          tip: '在"钥匙串访问"窗口中，在侧栏中"默认钥匙串"选择"登录"。',
        },
        step4: {
          label: '步骤4',
          tip: '找到 "deeper.network" 证书并选中，右键点击"显示简介"。',
        },
        step5: {
          label: '步骤5',
          tip: '点击简介中的"信任"，将"使用系统默认"勾选为"始终信任"。',
        },
        step6: {
          label: '步骤6',
          tip: '点击简介左上角关闭，输入电脑密码，点击更新设置。',
        },
        step7: {
          label: '步骤7',
          tip: '证书安装完成。',
        },
      },
      linux: {
        rootTab: '系统根证书导入教程',
        root: {
          step1: {
            label: '步骤1',
          },
          step2: {
            label: '步骤2',
            tip:
              '<p>将证书文件复制到 /usr/local/share/ca-certificates/<p>\
              <p>这个目录是专门用于存放本地安装的额外 CA 证书的。证书文件应该是以 .crt 为扩展名的 PEM 格式文件。</p>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /usr/local/share/ca-certificates/</code></div>',
          },
          step3: {
            label: '步骤3',
            tip:
              '<p>更新证书存储</p>\
               <p>使用 update-ca-certificates 命令来更新证书存储，这会自动刷新系统中的证书信任链。</p>\
               <div class="code"><code>sudo update-ca-certificates</code></div>\
               <p>这个命令会读取 /usr/local/share/ca-certificates/ 目录下的证书文件，并将它们添加到系统的信任列表中。</p>\
               <em><em class="warning">注意：</em>不同的 Linux 发行版可能会有不同的证书存储位置和更新命令。</em><br>\
               <em>例如，在基于 Red Hat 的系统\
               （如 Fedora、CentOS）上，您可能需要将证书复制到 /etc/pki/ca-trust/source/anchors/，</em><br>\
               <em>然后运行 update-ca-trust 命令来更新证书存储。</em><br>\
               <div class="code"><code>sudo cp DeeperNetwork.crt /etc/pki/ca-trust/source/anchors/</code><br>\
               <code>sudo update-ca-trust</code></div>\
               <p>在执行这些步骤之后，您安装的根证书应该已经被系统信任了。</p>',
          },
        },
        chrome: {
          step1: {
            label: '步骤1',
          },
          step2: {
            label: '步骤2',
            tip: '输入 chrome://settings/certificates 在地址栏，然后选择"<b>Authorities</b>"选项卡。',
          },
          step3: {
            label: '步骤3',
            tip: '点击 Import，找到刚才下载的证书，点击 Open',
          },
          step4: {
            label: '步骤4',
            tip: '在 "<b>证书授权中心</b>" 窗口中勾选所有选项，然后点击确定以完成证书安装。',
          },
        },
        firefox: {
          step1: {
            label: '步骤1',
          },
          step2: {
            label: '步骤2',
            tip: '在地址栏输入 about:preferences#privacy，找到 Certificates，点击 View Certificates',
          },
          step3: {
            label: '步骤3',
            tip: '选中 Authorities，点击 Import',
          },
          step4: {
            label: '步骤4',
            tip: '选中刚才下载的证书，点击 Open',
          },
          step5: {
            label: '步骤5',
            tip: '全部勾选，点击OK，证书安装完成',
          },
        },
      },
      android: {
        appTab: 'APP',
        browserTab: '浏览器',
        app: {
          step1: {
            label: '步骤1',
          },
          step2: {
            label: '步骤2',
            tip: '安装SkyTube，开始享受没有广告打扰的视频',
          },
        },
        browser: {
          step1: {
            label: '步骤1',
          },
          step2: {
            label: '步骤2',
            tip: '打开手机"设置"，找到"安全"',
          },
          step3: {
            label: '步骤3',
            tip: '进入 "安全"，找到 "加密和凭据"，点击进入',
          },
          step4: {
            label: '步骤4',
            tip: '点击安装证书',
          },
          step5: {
            label: '步骤5',
            tip: '选择CA证书',
          },
          step6: {
            label: '步骤6',
            tip: '点击仍然安装，输入手机密码',
          },
          step7: {
            label: '步骤7',
            tip: '选择下载好的证书',
          },
          step8: {
            label: '步骤8',
            tip: '提示 已安装CA证书，证书安装成功',
          },
        },
      },
    },
  },
  // SSLBypassCheckbox
  SSLBypassCheckbox: {
    sslBypass: '开启SSL旁路功能',
    sslBypassTips: '允许没有安装证书的设备连接网络。',
    sslBypassWaring: '若启用了SSL绕过功能，广告过滤可能会存在遗漏情况。',
    success: '操作成功',
    failed: '操作失败',
  },
  // tunnel
  tunnel: {
    configuredTunnel: '自定义隧道',
    countryTooltip: '模糊搜索国家名称或输入国家代码（大写），回车即可搜索所需国家',
    region: '区域',
    country: '国家或地区',
    activeTunnelNum: '活跃IP数',
    activeIp: '活跃IP',
    switchNode: '切换活跃IP',
    switchIpTitle: '切换IP',
    initTunnelTitle: '重新初始化隧道',
    initTunnelTitleTips: '一个隧道有3个IP，刷新隧道，会为您更换三个新的IP。',
    selectRegion: '选择区域',
    selectCountry: '选择国家或地区',
    missingInfoWarning: '请填写完整信息',
    tunnelAddSuccess: '隧道添加成功，页面将在 3 秒内刷新。',
    tunnelExist: '隧道已存在',
    reachedMax: '隧道数量已达上限',
    tunnelConflict: '欲添加隧道与已有隧道 {0} - {1} 冲突',
    tunnelAddError: '隧道添加失败',
    tunnelDelSuccess: '隧道删除成功，页面将在 3 秒内刷新。',
    tunnelNotExist: '隧道不存在',
    usedByFullMode: '隧道正在被DPN全路由使用中，无法被删除',
    usedByAccessControl: '要删除的隧道由访问控制使用',
    usedByBackup: '备用隧道正在使用此隧道，无法删除',
    tunnelInUse: '隧道正在被路由表使用中，无法被删除',
    tunnelDelError: '隧道删除失败',
    switchNodeSuccess: '切换节点成功，页面将在 3 秒内刷新。',
    noActiveIp: '无活跃IP可切换',
    ipNotChanged: 'IP未改变',
    switchNodeError: '切换节点失败',
    refreshLabel: '刷新',
    getNewIPLabel: '获取新IP',
    refreshError: '刷新隧道失败',
    refreshSuccess: '已提交刷新隧道请求，页面将在 3 秒内刷新。',
    tips: {
      refreshTunnel: '为您刷新新的活跃隧道。',
      configuredTunnel: '手动添加您需要的区域隧道后，AtomOS会在全球范围内搜索符合您要求的节点。',
    },
    nodeLbMode: {
      label: '节点选择模式',
      none: {
        label: '常规',
        desc: '在隧道内，所有网络连接都通过一个活跃节点，用户可以随时切换此节点。',
      },
      random: {
        label: '随机',
        desc: '系统为每个连接自动随机选择一个节点。',
      },
      domain: {
        label: '域名感知',
        desc:
          '系统会使用隧道内的多个节点来访问网络，但是系统会记住并始终将特定域名的访问路由到同一个节点，降低网站访问错误的风险。',
      },
      rr: {
        label: '轮询',
        desc: '每次连接时，系统会在几个节点中按顺序选择，助您实现更佳的网络加速。',
      },
      failure: '保存节点负载均衡模式失败',
      success: '保存节点负载均衡模式成功',
    },
  },
  // userConsent
  userConsent: {
    notice: '注意: 共享功能在您同意使用条款和条件及隐私政策之前是关闭的。',
    consentStart: '我已阅读并同意Deeper Network的 ',
    termsOfUseNotRead: '您还没有阅读使用条款和条件',
    termsOfUseLink: 'https://shop.deeper.network/terms-of-service?lang=zh_cn',
    termsOfUseTitle: '使用条款和条件',
    consentAnd: ' 及 ',
    privacyPolicyNotRead: '您还没有阅读隐私政策',
    privacyPolicyLink: 'https://shop.deeper.network/privacy-policy?lang=zh_cn',
    privacyPolicyTitle: '隐私政策',
    confirmBtn: '确认',
    consentError: '确认同意失败',
  },
  // customConfigLang
  customConfigLang: {
    missingInfoWarning: '域名缺失',
    invalidDomainError: '域名格式有误或域名太长',
    whitelist: '白名单',
    blacklist: '黑名单',
    domain: '域名',
    whitelistAddSuccess: '成功添加至白名单',
    whitelistEntryExist: '白名单中已有添加项',
    reachedWhiteMax: '已达白名单上限',
    whitelistAddError: '白名单项添加失败',
    blacklistAddSuccess: '成功添加至黑名单',
    blacklistEntryExist: '黑名单中已有添加项',
    reachedBlackMax: '已达黑名单上限',
    blacklistAddError: '黑名单项添加失败',
    whitelistDelSuccess: '白名单项删除成功',
    whitelistDelError: '白名单项删除失败',
    blacklistDelSuccess: '黑名单项删除成功',
    blacklistDelError: '黑名单项删除失败',
    import: '导入',
    fileButtonText: '点击上传',
    fileSizeOverLimit: '上传文件超过最大尺寸2MB',
    whitelistImportSuccess: '导入白名单成功, 已导入{0}项',
    whitelistImportError: '导入白名单失败',
    blacklistImportSuccess: '导入黑名单成功, 已导入{0}项',
    blacklistImportError: '导入黑名单失败',
    noValidEntry: '上传文件不含任何有效域名',
    zeroImported: '未能导入任何域名项',
  },
  // defaultConfigLang
  defaultConfigLang: {
    'browserCacheWarning':
      '如果开启DNS广告过滤的情况下无法正常过滤广告，需要清理一下浏览器缓存并且重启一下被过滤的设备。',
    queriesFor24Hours: '过去24小时的请求',
    totalQuery: '请求总和',
    blockedQuery: '拦截请求',
    blockedPercentage: '拦截百分比',
    ruleTotal: '规则',
    adQuery: '拦截广告',
    trackerQuery: '拦截追踪',
    maliciousQuery: '拦截恶意',
    pornQuery: '拦截成人片',
    legendTotal: '总和',
    legendBlocked: '拦截',
    categoryStateEnabled: '启用',
    categoryStateDisabled: '禁用',
    categoryState2Hours: '禁用2小时',
    categoryState4Hours: '禁用4小时',
    categoryState8Hours: '禁用8小时',
    setCategoryStatesErr: '启用/禁用过滤出错',
    enableAll: '启用全部过滤',
    disableAll: '禁用全部过滤',
  },
  // wifiSettings
  wifiSettings: {
    workMode: '工作模式',
    currentWorkMode: '当前工作模式',
    upstreamTitle: '上行设置',
    backHome: '返回首页',
    'wire-ap': '有线 WAN',
    'wlan-ap': '无线 WAN',
    downstreamTitle: '无线 LAN',
    notConnect: '没有接入互联网',
    apConfigWarning: '保存Wi-Fi SSID和Wi-Fi Password后，如果想通过Deeper设备上网，需要重新扫描Deeper设备热点并应用密码',
    attention: '注意',
    buttons: {
      scanWifi: '扫描',
      connectStatus: '状态',
      add: '添加',
      save: '保存',
      cancel: '取消',
      downloadLog: '下载日志',
    },
    connect: '连接',
    forget: '忘记',
    waiting: {
      content: '正在连接，需要一点时间，请耐心等待...',
      cancelBtn: '取消连接',
    },
    modals: {
      hiddenWifi: {
        title: '添加隐藏Wi-Fi',
        okBtn: '添加',
      },
      scanWifi: {
        title: '警告',
        content: '网络会暂时断开',
        ok: '确定',
        cancel: '取消',
      },
    },
    label: {
      ssid: 'Wi-Fi名称',
      password: 'Wi-Fi密码',
      onlyUse: '仅使用',
      hidden: '隐藏SSID',
      freqBand: '频带',
    },
    placeholder: {
      ssid: '请输入SSID',
      password: '请输入密码',
    },
    message: {
      saveSuccess: '保存成功',
      saveError: '保存失败，请重试',
      connectSuccess: '连接成功',
      forgetSuccess: '忘记成功',
      inputError: '请检查您的输入',
      invalidWifiDev: '当前没有可用的Wi-Fi设备，无法进行Wi-Fi设置',
      illegalWifiDev: 'Wi-Fi 设置只可在 AP Router Mode 和 Wireless Relay Mode 下使用',
      invalidSsid: '只支持字母，数字，_和-',
      invalidPassword: '请输入可用的密码',
      notConnect: '没有连接到互联网',
      retry: '请重试',
      connectionError: '连接失败',
      forgetError: '忘记失败',
      wifiNotSupported: '不支持 Wi-Fi',
      networkNotFound: '未找到网络',
      pskNotGenerated: '未生成密钥',
      wrongPassword: '密码错误',
      timeout: '连接超时',
      logDownloadError: '下载日志失败',
      hasBeenConnected: '已连接。',
      dhcpSuccess: '成功',
      dhcpFailure: '失败',
    },
    connectionRequestedTitle: '已请求连接',
    connectionRequestedContent:
      '请在“状态”选项下检查您的连接状态。 如果您与 Deeper Connect 断开连接，请尝试重新连接 DeeperWiFi。',
    gotIpFromDhcpLabel: '从 DHCP 获取 IP 地址',
  },
  // parentalControlData
  parentalControlData: {
    browserCacheWarning:
      '如果启用家长控制功能的情况下无法正常拦截成人网站或社交媒体，需要清理一下浏览器缓存并且重启一下被过滤的设备。',
    customerRule: `在这里，您可以为您的孩子屏蔽绝大部分的成人和社交网站。如果您还想屏蔽其他网站，您可以使用自定义域名屏蔽功能来添加更多的网站。`,
    customerRuleClickTips: '请{0}以访问自定义域名屏蔽页面',
    toCustomerRuleButtonText: '点击此处',
    parentalControl: '家长控制',
    pornQuery: '拦截成人片',
    socialQuery: '拦截社交媒体',
    legendBlocked: '拦截',
    // social
    socialStateEnabled: '启用',
    socialStateDisabled: '禁用',
    socialState2Hours: '禁用2小时',
    socialState4Hours: '禁用4小时',
    socialState8Hours: '禁用8小时',

    // porn
    pornStateEnabled: '启用',
    pornStateDisabled: '禁用',
    pornState2Hours: '禁用2小时',
    pornState4Hours: '禁用4小时',
    pornState8Hours: '禁用8小时',
    setCategoryStatesErr: '启用/禁用过滤出错',
  },
  reboot: {
    success: {
      saveConfig: '保存成功',
    },
    failure: {
      getConfig: '获取自动重启配置失败',
      saveConfig: '保存自动重启配置失败',
    },
    buttons: {
      reboot: '点击重启',
      save: '保存',
    },
    week: {
      0: '每天',
      1: '星期一',
      2: '星期二',
      3: '星期三',
      4: '星期四',
      5: '星期五',
      6: '星期六',
      7: '星期日',
    },
    title: {
      manual: '立即重启',
      auto: '定时重启',
    },
    label: {
      period: '周期',
      time: '时间',
    },
  },
};
