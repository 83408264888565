import languageObj from './languageObj';

export function findLanguage(type) {
  return Object.values(languageObj).find(item => {
    return item.type === type;
  });
}

export function languageType() {
  return {
    english: 'English',
    german: 'German',
    korean: 'Korean',
    french: 'Französisch',
    spain: 'Spain',
    portugal: 'Portugal',
    japanese: 'Japanese',
    Tchinese: '繁体中文',
    russian: 'Russian',
    hindi: 'Hindi',
    simplechinese: '简体中文',
  };
}

export const langOptions = [
  {
    value: 'zh_cn',
    label: '简体中文',
  },
  {
    value: 'zh_TW',
    label: '繁體中文',
  },
  {
    value: 'en_us',
    label: 'English',
  },
  {
    value: 'de_DE',
    label: 'Deutsch',
  },
  {
    value: 'ko_KR',
    label: '한국어',
  },
  {
    value: 'fr_CA',
    label: 'Français',
  },
  {
    value: 'es_ES',
    label: 'Español',
  },
  {
    value: 'pt_PT',
    label: 'Português',
  },
  {
    value: 'ja_JP',
    label: '日本語',
  },
  {
    value: 'ru_RU',
    label: 'Русский',
  },
  {
    value: 'en_IN',
    label: 'नहीं',
  },
];
