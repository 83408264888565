export default {
  type: 'korea',
  internationalize: {
    type: 'korea',
    'common': {
      'tip_text': '안내',
      'search': '검색',
      'add': '추가',
      'delete': '삭제',
      'confirm': '확인',
      'cancel': '취소',
      'warning': '경고',
      'utcDisclaimer': '날짜는 UTC입니다.',
      'toOpen': '열기',
    },
    certificateDialog: {
      title: '새 루트 CA 인증서',
      content: '루트 CA 인증서가 교체되었습니다. 다시 다운로드하여 설치해 주세요.',
      dontShowAgain: '다시 보지 않기',
    },
    'request_tip': {
      'error': '요청 오류입니다.~',
    },
    'header': {
      'logout': '로그아웃',
      'changePassword': '비밀번호 변경',
      'userManual': '사용자 매뉴얼',
      'defaultAvatar': '기본 대화 사진',
      'english': '영어',
      'german': '독일어',
      'korean': '한국어',
      'french': '프랑스어',
      'spain': '스페인어',
      'portugal': '포르투갈어',
      'japanese': '일본어',
      'Tchinese': '번체 중국어',
      'russian': '러시아어',
      'hindi': '힌디어',
      'simplechinese': '간체 중국어',
    },
    'home': {
      'name': '홈',
      'children': {},
      'content': {
        'tipInput': '입력하세요',
        'ok': '확인',
        'cancel': '취소',
        'currentStatus': '현재 상태',
        'statusMap': {
          'Trial': '시험',
          'Valid': '유효',
          'Expired': '만료',
        },
        'btnActive': '활성화',
        'btnEdit': '수정',
        'expireDate': '만료 날짜',
        'licenseKey': '라이선스 키',
      },
    },
    accessControl: {
      name: '액세스 제어',
    },
    'tpProxy': {
      'name': '투명 프록시',
      'children': {
        'customRules': {
          'name': '사용자 정의 규칙',
        },
        'builtinRules': {
          'name': '광고 필터',
        },
        'certificate': {
          'name': '인증서 설치',
        },
      },
    },
    'help': {
      'name': '도움말',
      'children': {
        'techSupport': {
          'name': '기술 지원',
        },
      },
    },
    'device': {
      'name': '장비 관련',
      'children': {
        'system': {
          'name': '시스템 정보',
        },
        'traffic': {
          'name': '트래픽',
        },
        'notification': {
          'name': '로그',
        },
      },
      'content': {},
    },
    'dpn': {
      'name': 'DPN',
      'content': {},
      'children': {
        'smartRoute': {
          'name': '모드',
        },
        'tunnel': {
          'name': '터널',
        },
        'routingTable': {
          'name': '스마트 라우팅',
          'content': {},
          'children': {
            'domainConfig': {
              'name': '도메인 설정',
            },
            'ipConfig': {
              'name': 'IP 설정',
            },
            'queryRouting': {
              'name': '쿼리',
            },
          },
        },
      },
    },
    'appRelocator': {
      'name': '앱 리포터',
    },
    'adsBlock': {
      'name': '광고 차단',
      'children': {
        'domainFilter': {
          'name': 'DNS 필터링',
        },
        'httpsFilter': {
          'name': 'HTTPS 필터링',
        },
      },
      httpssucceed: 'HTTPS 필터링이 성공적으로 활성화되었습니다',
      httpslose: 'HTTPS 필터링이 성공적으로 비활성화되었습니다',
      saverror: '작업 실패',
      usedByAccessControl: 'HTTPS 필터는 액세스 제어에 사용됩니다.',
    },
    'dpr': {
      'name': '채굴',
      'children': {
        'dpr': {
          'name': 'DPR',
          'children': {
            'stakingTutorial': {
              'name': '스테이킹 튜토리얼',
            },
            'wallet': {
              'name': '지갑',
              'manager': '지갑 관리',
              'balance': '지갑 잔액',
            },
            'Staking Mining': '스테이킹 채굴',
            'transaction': {
              'id': '거래 ID',
              'from': '전송 출처 주소',
              'to': '전송 대상 주소',
              'time': '전송 시간 (UTC)',
              'rewardTime': '보상 시간 (UTC)',
              'amount': 'DPR 수량',
              'Reward in selected period': '선택 기간 보상',
              'Total reward': '총 보상',
              'Balance Transfer': '일반 거래',
              'Staking Reward': '스테이킹 보상',
              'PoS Reward': 'PoS 보상',
              'Micro Payment': '마이크로 결제 거래',
              'stakeRelease': '선형 해제 거래',
              'Transaction': '거래 기록',
              'Mining': '채굴',
              'Traffic Mining': '트래픽 채굴',
              'Staking Mining': '스테이킹 채굴',
            },
          },
        },
      },
    },
    'security': {
      'name': '안전에 관한 것들',
      'children': {
        'applications': '앱 제어',
        'traffic': '트래픽 제어',
        'webfilter': {
          'name': '웹 필터링',
          'children': {
            'report': {
              'name': '기본 설정',
            },
            'config': {
              'name': 'DNS 광고 필터링',
            },
          },
        },
        'sslFilter': {
          'name': 'SSL 필터링',
          'children': {
            'customDNSrules': {
              'name': '사용자 정의 DNS 규칙',
            },
            'customHTTPSRules': {
              'name': '사용자 정의 HTTPS 규칙',
            },
          },
        },
      },
      'content': {},
    },
    'parentalControl': {
      'name': '부모 제어',
    },
    regionCountryTexts: {
      'LL': '터널 안 가요.',
      'ANY': '모든 국가 또는 지역',
      // regions
      'AMER': '---아메리카---',
      'ASIA': '---아시아---',
      'AFRI': '---아프리카---',
      'EURO': '---유럽---',
      'OCEA': '---오스트레일리아---',
      'AMN': '북아메리카',
      'AMC': '카리브해',
      'AMM': '중미',
      'AMS': '남아메리카',
      'ASC': '중심아시아',
      'ASE': '동아시아',
      'ASW': '서아시아',
      'ASS': '남아시아',
      'ASD': '태국 연방',
      'AFN': '북非',
      'AFM': '중非',
      'AFE': '동非',
      'AFW': '서非',
      'AFS': '인도양',
      'EUN': '덴마크 지구',
      'EUE': '여의사 지구',
      'EUW': '유럽',
      'EUS': '이탈리아 지구',
      'OCP': '파리니아세아',
      'OCA': '호주·네스트워크 지역',
      'OCM': '미라니아세아',
      'OCN': '미크로네시아',
      // countries
      'AD': '안도ر 공화국',
      'AE': '아라비아 연합 공화국',
      'AF': '아프리카 연방',
      'AG': '앤트蘿드 및 바바드 섬',
      'AI': '아이니스틱',
      'AL': '알로리아',
      'AM': '아미노า 연방',
      'AO': '앙골라',
      'AR': '아르헨티나',
      'AS': '미주 사모다',
      'AT': '어바웃 연방',
      'AU': '오스트레일리아',
      'AW': '아루바',
      'AX': '올랜드 올바이킹',
      'AZ': '아제르바이잔',
      'BA': '보스니아와 헤르체고느 공화국',
      'BB': '바바롭 베이징',
      'BD': '방글라데시',
      'BE': '벨기에',
      'BF': '부리고나',
      'BG': '불가리아',
      'BH': '바레인',
      'BI': '버몀도우',
      'BJ': '베이징',
      'BL': '사바나디움',
      'BM': '벨마크',
      'BN': '브루나이',
      'BO': '볼리비아',
      'BQ': '네덜란드 카리브',
      'BR': '브라질',
      'BS': '바하마',
      'BT': '파타지',
      'BW': '보츠와나',
      'BY': '벨라루스',
      'BZ': '베트남',
      'CA': '캐나다',
      'CC': '코코스(킬링) 제도',
      'CD': '중앙 컨테이너 국가',
      'CF': '중앙 아프리카',
      'CG': '골든 브라치나',
      'CH': '스위스',
      'CI': '코트디부아비아',
      'CK': '쿡 제도',
      'CL': '칚레',
      'CM': '가마모니',
      'CN': '중화인민공화국',
      'CO': '코로나',
      'CR': '고르시아',
      'CU': '쿠바',
      'CV': '복드 섬',
      'CW': '퀴라소',
      'CX': '크리스마스 섬',
      'CY': '세포르',
      'CZ': '크로아티아',
      'DE': '독일',
      'DJ': '지부트리',
      'DK': '데일리안',
      'DM': '도미커니아',
      'DO': '도미닉즈',
      'DZ': '알제리아',
      'EC': '에콰도르',
      'EE': '이스라엘',
      'EG': '이집트',
      'ER': '이رتبيا',
      'ES': '스페인',
      'ET': '이스라치 Nigeria',
      'FI': '핀란드',
      'FJ': '피지',
      'FK': '포키 섬 자유 여객정부',
      'FM': '미크로네시아 연방',
      'FO': '페로 제도',
      'FR': '프랑스',
      'GA': '가나',
      'GB': '영국',
      'GD': '그레나다',
      'GE': '그리고아니아',
      'GF': '프랑스의 기네자나',
      'GG': '그린섬',
      'GH': '가나',
      'GI': '퀘이크로도',
      'GL': '그린랜드',
      'GM': '감바이아',
      'GN': '기니',
      'GP': '고드름도르',
      'GQ': '퀴라우아란',
      'GR': '그리스',
      'GS': '남喬治亞과 남사우디움 섬들',
      'GT': '위지컨',
      'GU': '갈다파쿠',
      'GW': '기니비사우',
      'GY': '가이아나',
      'HK': '중화인민공화국 항구',
      'HN': '호닉도ラ',
      'HR': '크로아티아',
      'HT': '하이데이',
      'HU': '헝가리',
      'ID': '인도',
      'IE': '이れ일',
      'IL': '이스라엘',
      'IM': '마ㄣ 섬',
      'IN': '인도네시아',
      'IO': '영국령 인도양 영지',
      'IQ': '이رتبیل',
      'IR': '이ریان',
      'IS': '북아일랜드',
      'IT': '이탈리아',
      'JE': '자이언츠',
      'JM': '야마카아',
      'JO': '요르단',
      'JP': '일본',
      'KE': '키나비아',
      'KG': '기르스텔스탄',
      'KH': '캄보디아',
      'KI': '키리스바토르',
      'KM': '코모로',
      'KN': '세이크노트와 니드움',
      'KR': '한국',
      'KW': '쿠웨이트',
      'KY': '케이맨 제도',
      'KZ': '카자흐스탈',
      'KP': '북한',
      'LA': '라오스',
      'LB': '레바논',
      'LC': '세인트루시아',
      'LI': '련제트',
      'LK': '스리랑카',
      'LR': '리비아',
      'LS': '레소토',
      'LT': '리투아니아',
      'LU': '룩셈부르크',
      'LV': '라트겐도시',
      'LY': '리비아',
      'MA': '리비아',
      'MC': '모나코',
      'MD': '모ルドバ',
      'ME': '몬테네그로',
      'MF': '프랑스령 세인트마틴',
      'MG': '마가사카',
      'MH': '마셜어 섬',
      'MK': '북마켓도니아',
      'ML': '말리',
      'MM': '미얀마',
      'MN': '몽골',
      'MO': '중국 마카오',
      'MP': '북마리아나 제도',
      'MQ': '마티니크',
      'MR': '모리타니',
      'MS': '몬세라트',
      'MT': '몰타',
      'MU': '모리셔스',
      'MV': '몰디브',
      'MW': '말라위',
      'MX': '멕시코',
      'MY': '말레이시아',
      'MZ': '모잠비크',
      'NA': '나미비아',
      'NC': '뉴칼레도니아',
      'NE': '니제르',
      'NF': '노퍽 섬',
      'NG': '나이지리아',
      'NI': '니카라과',
      'NL': '네덜란드',
      'NO': '노르웨이',
      'NP': '네팔',
      'NR': '나우루',
      'NU': '니우에',
      'NZ': '뉴질랜드',
      'OM': '오만',
      'PA': '파나마',
      'PE': '베이ル',
      'PF': '프랑스령 폴리네시아',
      'PG': '파푸아뉴기니',
      'PH': '필리핀',
      'PK': '파키스탄',
      'PL': '폴란드',
      'PM': '생피에르 미클론',
      'PN': '피트케인 제도',
      'PR': '푸에르토리코',
      'PS': '팔레스타인',
      'PT': '폴리아',
      'PW': '푸에이전시',
      'PY': '베라가',
      'QA': '카تروت',
      'RE': '레위니옹',
      'RO': '루마니아',
      'RS': '세르비아',
      'RU': '러시아',
      'RW': '르완다',
      'SA': '사우디 아라비아',
      'SB': '솔로몬 제도',
      'SC': '세이셸',
      'SD': '수단',
      'SE': '스웨덴',
      'SG': '싱가포르',
      'SH': '세인트헬레나, 앗센션 및 트리스탄 다 쿠냐',
      'SI': '슬로베니아',
      'SJ': '스발바르 얀 마웬',
      'SK': '슬로바키아',
      'SL': '시에라리온',
      'SM': '산마리노',
      'SN': '세네갈',
      'SO': '소말리아',
      'SR': '수리남',
      'SS': '남수단',
      'ST': '상투메 프린시페',
      'SV': '엘살바도르',
      'SX': '네덜란드령 생마르탱',
      'SY': '시리아',
      'SZ': '에스와티니',
      'TC': '터크스 케이커스 제도',
      'TD': '차드',
      'TF': '프랑스령 남부와 남극 지역',
      'TG': '토고',
      'TH': '태국',
      'TJ': '타지키스탄',
      'TK': '토켈라우',
      'TL': '동티모르',
      'TM': '투르크메니스탄',
      'TN': '튀니지',
      'TO': '통가',
      'TR': '터키',
      'TT': '트리니다드 토바고',
      'TV': '투발루',
      'TW': '대만',
      'TZ': '탄자니아',
      'UA': '우크라이나',
      'UB': '미국 서부',
      'UC': '미국 중서부',
      'UD': '미국 남서부',
      'UE': '미국 북동부',
      'UF': '미국 남동부',
      'UG': '우간다',
      'US': '미국',
      'UY': '우루과이',
      'UZ': '우즈베키스탄',
      'VA': '바티칸 시티',
      'VC': '세인트빈센트 그레나딘',
      'VE': '베네수엘라',
      'VG': '영국령 버진아일랜드',
      'VI': '미국령 버진아일랜드',
      'VN': '베트남',
      'VU': '바누아투',
      'WF': '왈리스 퓌튀나',
      'WS': '사모아',
      'XK': '코소보',
      'YE': '예멘',
      'YT': '마요트',
      'ZA': '남아프리카',
      'ZM': '잠비아',
      'ZW': '짐바브웨',
    },
    setting: {
      name: '설정',
      children: {
        wifi: {
          name: 'Wi-Fi 관련',
        },
        sharing: {
          name: '공유 관련',
          children: {
            configure: {
              name: '공유 설정',
            },
            blacklist: {
              name: '블랙리스트 공유',
            },
            record: {
              name: '공유 로그',
            },
            traffic: {
              name: '공유 트래픽',
            },
          },
          content: {},
        },
        rustdesk: {
          name: 'RustDesk',
          children: {
            config: {
              name: '환경 설정',
            },
            log: {
              name: '로그',
            },
          },
        },
        admin: {
          name: '계정 관리',
          content: {},
          children: {
            changePassword: {
              name: '비밀번호',
            },
            reboot: {
              name: '재시작',
              content: {
                confirmText: '재시작을 확인하시겠습니까？',
                rebootLoading: '디바이스를 재부팅하는 데 몇 분이 소요되며 네트워크가 일시적으로 끊어집니다....',
              },
            },
          },
        },
      },
    },
  },
  // dashboard
  dashboard: {
    name: '홈',
    children: {},
    content: {
      backupTunnelLabel: '보조 터널',
      tipInput: '입력하세요',
      hotspot: '전 세계 연결',
      realnetworkflow: '실시간 트래픽 통계',
      memory: '메모리 사용률',
      cpu: 'CPU 사용률',
      notification: '시스템 알림',
      systeminfo: '시스템 정보',
      upload: '업로드',
      download: '다운로드',
      systemuptime: '시스템 동작 시간',
      uploadtotal: '총 업로드량',
      downloadtotal: '총 다운로드량',
      softwareVersion: '소프트웨어 버전',
      publicIp: '공용 IP',
      unit: '단위',
      currentDpnMode: '현재 DPN모드',
      ok: '확인',
      cancel: '취소',
      currentStatus: '현재 상태',
      statusMap: {
        Trial: '시험 사용',
        Valid: '유효',
        Expired: '만료된',
      },
      btnActive: '활성화',
      btnEdit: '수정',
      expireDate: '만료일',
      licenseKey: '라이센스 번호',
      systemTraffic: '실시간 시스템 트래픽 통계',
      appTraffic: '실시간 앱 트래픽 통계',
    },
  },
  accessControl: {
    'content': {
      banner: {
        title: '접근 제어',
        description: '이 스위치는 LAN 장치의 다양한 기능 활성화 또는 비활성화를 균일하게 제어하는 데 사용됩니다.',
      },
      'onlineDevice': '온라인 장치',
      'offlineDevice': '오프라인 장치',
      'deviceName': '장치 이름',
      unknownDevice: '알려지지 않은',
      'macAddress': 'MAC 주소',
      'ipAddress': 'IP 주소',
      'enableHttpsFilter': 'HTTPS 필터 사용',
      'routeMode': 'DPN 모드',
      'routeModeOptions': {
        'smart': '스마트 라우팅 모드',
        'full': '전체 라우팅',
        'disabled': '비활성화',
        blackHole: '블랙홀',
      },
      'remark': '메모',
      pinned: '맨 위로 고정',
      'httpsFilterTip': 'HTTPS 필터를 사용하면 모든 광고가 필터링됩니다.',
      'routeModeTip': '이 옵션을 사용하면 DPN의 작동 모드를 변경할 수 있습니다.',
      'tunnelTip': '전체 라우팅 터널을 선택하세요.',
      'success': {
        getList: '기기 목록이 새로 고쳐졌습니다.',
        'httpsFilter': 'HTTPS 필터 사용 성공',
        'routeMode': 'DPN 모드 변경 성공',
        'regionCode': 'DPN 모드 변경 성공',
        'remark': '메모 저장 성공',
        pinned: '고정 / 고정 해제가 성공적으로 이루어졌습니다. 새로 고침 해주세요',
        'deleteDevices': '장치 삭제 성공',
      },
      'failure': {
        'getSwitch': '액세스 제어 스위치 가져오기 실패',
        'setSwitch': '액세스 제어 스위치 설정 실패',
        'getList': '액세스 제어 목록 가져오기 실패',
        'getTunnels': '터널 가져오기 실패',
        'setItem': '액세스 제어 항목 설정 실패',
        'httpsFilter': 'HTTPS 필터 사용 실패',
        'routeMode': 'DPN 모드 변경 실패',
        'regionCode': 'DPN 모드 변경 실패',
        'remark': '메모 저장 실패',
        'noSuchTunnel': '터널이 존재하지 않습니다. 다른 터널을 선택하세요.',
        pinned: '고정 / 고정 해제에 실패했습니다',
        'deleteDevices': '장치 삭제 실패',
        'macActive': '장치가 활성 상태이므로 삭제할 수 없습니다',
      },
      'remarkLengthLimit': '길이는 10자 이하여야 합니다.',
      'httpsNotEnabled': 'HTTPS 필터의 기본 스위치가 꺼져 있습니다. 이 페이지로 이동하여 켜십시오!',
      'tips': {
        'opened': '액세스 제어가 켜져 있으면 온라인 장치는 액세스 제어에서 DPN 모드를 우선적으로 사용합니다.',
      },
    },
  },
  //dpnMode
  dpnMode: {
    'title': '선택 DPN 모드',
    'selectTunnel': '구간 선택',
    'recommended': '추천',
    'apply': '적용',
    'missingInfo': '정보 없음',
    'selectionTip': {
      'smart': 'DPN 인공지능 라우팅 모드로 변경하시겠습니까？',
      'full': '모든 트래픽이 선택한 구간을 통과합니다. DPN 전체 라우팅 모드로 변경하시겠습니까？',
      'disabled': '이 모드는 DPN 네트워크를 일시적으로 비활성화합니다. DPN 비활성화 모드로 변경하시겠습니까？',
    },
    'dpnModeShortList': {
      'smart': 'DPN 인공지능 라우팅',
      'disabled': 'DPN 비활성화',
    },
    'dpnModeLongList': {
      'smart': '스마트 라우팅',
      'full': '전체 라우팅',
      'disabled': '비활성화',
    },
    'killSwitchLabel': '중단 스위치',
    'none': '닫기',
    'backupTunnelLabel': '보조 구간',
    'backupTunnelTip':
      '스마트 라우팅 모드에서 설정된 구간이 문제가 발생할 때， 시스템은 자동으로 보조 구간으로 전환하고， 기본 구간이 복구되면까지 계속합니다.',
    tips: {
      killSwitch:
        'VPN 차단 보호 기능은 VPN 연결이 끊어지면 컴퓨터, 휴대폰 또는 태블릿과 같은 장치와 인터넷의 연결이 자동으로 끊어지는 것을 말합니다.이것은 VPN이 작동하지 않으면 IP 주소 및 기타 민감한 정보가 노출되지 않는다는 것을 의미합니다.따라서 인터넷 연결의 보안과 익명성은 영향을 받지 않습니다.',
      smart: [
        '1. AtomOS에 내장된 규칙에 따라 스마트 매칭 노드는 현재 액세스해야 하는 웹 사이트에 로컬 액세스 네트워크 속도를 보장하는 동시에 다른 국가의 서비스에 대한 액세스를 가속화합니다.',
        '2. 스마트 라우팅 모드에서는 전통적인 VPN이 끊임없이 국가를 전환해야 하는 번거로움을 줄일 수 있을 뿐만 아니라 사용자에게 더욱 빠른 네트워크 액세스 경험을 가져다 줄 수 있다.',
        '3. Deeper Connect에는 유명 사이트 및 서비스의 라우팅 규칙이 내장되어 있습니다. 스마트 라우팅 규칙을 사용자 정의하려면 재배치 적용 및 DPN- 라우팅 테이블에서 구성할 수 있습니다.',
        '4.또한 사용자의 피드백에 따라 기본 제공 라우팅 규칙을 추가하고 애플리케이션 재배치에서 더 많은 애플리케이션을 지원합니다.',
      ],
      full: [
        '1. AtomOS를 통해 처리되는 모든 네트워크 데이터는 지정된 터널을 사용하며, 이는 기존 VPN과 유사한 방식으로 사용됩니다.',
        '2. 참고: 전체 라우팅 모드를 사용하면 재배치 적용 및 DPN-라우팅 테이블에서 구성이 무시됩니다.',
        '3. 탈중심화 네트워크의 특성상 네트워크의 노드는 세계 각지의 사용자 장치에서 온 것이지 고속 서버가 아니기 때문에 사용자의 인터넷 속도는 영향을 받을 수 있습니다.',
        '4. 전체 라우팅 모드를 사용해야 하고 고속 인터넷 환경을 원한다면 VIP 사용자에게 특정 고속 노드를 제공하는 것을 고려할 것입니다.',
      ],
      disabled: [
        '1. DPN 비활성화는 DPN 기능을 해제하고 AtomOS에서 처리된 모든 네트워크 데이터는 로컬 트래픽을 사용합니다.',
        '2. Deeper Connect의 다른 네트워크 보안 기능은 여전히 켜져 있습니다.',
        '3. 일부 웹 사이트를 액세스하는 데 장애가 발생하면 웹 사이트의 문제인지 DPN 기능의 문제인지 확인하기 위해 DPN을 비활성화할 수 있습니다.',
        '4. DPN 기능에 문제가 있는 경우 다음 링크를 통해 문제를 보고하십시오.https://support.deeper.network/hc/en-us/requests/new',
      ],
    },
    success: '작업 성공',
    failure: '작업 실패!',
  },
  // DefaultadBlock
  DefaultadBlock: {
    browserCacheWarning:
      'DNS 광고 필터링이 켜져 있는 상태에서 광고를 제대로 필터링할 수 없는 경우 브라우저 캐시를 정리하고 필터링된 장치를 다시 시작해야 합니다.',
    'queriesFor24Hours': '지난 24시간 동안의 조회',
    'totalQuery': '총 조회',
    'blockedQuery': '차단된 조회',
    'blockedPercentage': '차단 비율',
    'ruleTotal': '규칙',
    'adQuery': '광고 차단 조회',
    'trackerQuery': '추적 차단 조회',
    'maliciousQuery': '악성 차단 조회',
    'pornQuery': '성인 콘텐츠 차단 조회',
    'legendTotal': '전체',
    'legendBlocked': '차단됨',
    'categoryStateEnabled': '활성화',
    'categoryStateDisabled': '비활성화',
    'categoryState2Hours': '2시간 비활성화',
    'categoryState4Hours': '4시간 비활성화',
    'categoryState8Hours': '8시간 비활성화',
    'setCategoryStatesErr': '카테고리 상태 설정 오류',
    'enableAll': '모든 필터 활성화',
    'disableAll': '모든 필터 비활성화',
  },
  // CustomadBlock
  CustomadBlock: {
    missingInfoWarning: '도메인 누락',
    invalidDomainError: '도메인 형식이 잘못되었거나 너무 깁니다',
    whitelist: '화이트리스트',
    blacklist: '블랙리스트',
    domain: '도메인',
    whitelistAddSuccess: '화이트리스트에 성공적으로 추가되었습니다',
    whitelistEntryExist: '화이트리스트에 이미 추가된 항목입니다',
    reachedWhiteMax: '화이트리스트 한도에 도달했습니다',
    whitelistAddError: '화이트리스트 항목 추가 실패',
    blacklistAddSuccess: '블랙리스트에 성공적으로 추가되었습니다',
    blacklistEntryExist: '블랙리스트에 이미 추가된 항목입니다',
    reachedBlackMax: '블랙리스트 한도에 도달했습니다',
    blacklistAddError: '블랙리스트 항목 추가 실패',
    whitelistDelSuccess: '화이트리스트 항목이 성공적으로 삭제되었습니다',
    whitelistDelError: '화이트리스트 항목 삭제 실패',
    blacklistDelSuccess: '블랙리스트 항목이 성공적으로 삭제되었습니다',
    blacklistDelError: '블랙리스트 항목 삭제 실패',
    import: '가져오기',
    fileButtonText: '클릭하여 업로드',
    fileSizeOverLimit: '업로드 파일이 최대 크기 2MB를 초과합니다',
    whitelistImportSuccess: '화이트리스트 가져오기 성공, {0}개 항목 가져옴',
    whitelistImportError: '화이트리스트 가져오기 실패',
    blacklistImportSuccess: '블랙리스트 가져오기 성공, {0}개 항목 가져옴',
    blacklistImportError: '블랙리스트 가져오기 실패',
    noValidEntry: '업로드 파일에 유효한 도메인이 없습니다',
    zeroImported: '어떤 도메인 항목도 가져오지 못했습니다',
  },
  // DohGuideComp
  DohGuideComp: {
    closeButton: '닫기',
    guideButton: '보기',
    dohMessageClickTips: '특정 브라우저에서 DoH를 비활성화하는 방법을 보려면 "보기"를 클릭하세요.',
    dohMessageTxt: '이 기능을 사용하려면 {0}하세요. DoH는 DNS 요청을 암호화하여 일부 기능에 영향을 줄 수 있습니다.',
    dohMessage0: '브라우저에서 DoH 설정을 비활성화하세요.',
    guideBoxTitle: 'DoH 브라우저 설정',
    guide: {
      chromeGuideImg: '/dohGuide/chrome-guide-ko.png',
      chrome: [
        { label: '단계 1', tip: '주소 창에 chrome://settings/security를 입력하고 Enter 키를 누릅니다.' },
        { label: '단계 2', tip: '"고급 보안 설정" 섹션을 찾습니다.' },
        { label: '단계 3', tip: '"보안 DNS 사용" 옵션을 비활성화합니다.' },
      ],
      firefox: [
        { label: '단계 1', tip: '주소 창에 about:config를 입력하고 Enter 키를 누릅니다.' },
        { label: '단계 2', tip: '경고 페이지가 표시되면 "위험을 이해하고 계속하기"를 클릭합니다.' },
        { label: '단계 3', tip: '검색 상자에 network.trr.mode를 입력하고 Enter 키를 누릅니다.' },
        {
          label: '단계 4',
          tip: '찾은 설정 항목을 두 번 클릭하여 값을 5로 변경하고 확인 을 클릭합니다. (DoH 비활성화)',
        },
      ],
      edge: [
        {
          label: '단계 1',
          tip: '주소 창에 edge://settings/privacy를 입력하고 Enter 키를 누릅니다. "보안" 섹션을 찾습니다.',
        },
        { label: '단계 2', tip: '"네트워크 보안을 위해 안전한 DNS 사용" 옵션을 비활성화합니다.' },
      ],
      operaGuideImg: '/dohGuide/opera-guide-ko.png',
      opera: [
        { label: '단계 1', tip: '브라우저 주소 창에 opera://settings/?search=DNS를 입력하고 Enter 키를 누릅니다.' },
        { label: '단계 2', tip: '"시스템 DNS 설정 대신 DNS-over-HTTPS 사용" 옵션을 비활성화합니다.' },
      ],
    },
  },
  // appRelocatorData
  appRelocatorData: {
    guide: {
      youtube: {
        UA: 'https://www.deeper.network/pages/app-relocator?app=youtube&lang=zh',
      },
      nbaLeaguePass: 'https://www.deeper.network/pages/app-relocator?app=nbaLeaguePass&lang=zh',
      netflix: 'https://www.deeper.network/pages/app-relocator?app=netflix&lang=zh',
      dazn: 'https://www.deeper.network/pages/app-relocator?app=dazn&lang=zh',
      fubo: 'https://www.deeper.network/pages/app-relocator?app=fubo&lang=zh',
      tencentVideo: 'https://www.deeper.network/pages/app-relocator?app=tencentVideo&lang=zh',
    },
    'countryLabel': '국가',
    'tagLabel': '태그',
    'typeLabel': '타입',
    'country': {
      'all-countries': '전 세계',
      CN: '중국',
      US: '미국',
      IN: '인도',
      CA: '캐나다',
      GB: '영국',
      NA: '기타',
      NO: '노르웨이',
      SG: '싱가포르',
      JP: '일본',
    },
    tag: {
      'all-tags': '전체 태그',
      nba: 'NBA',
      nfl: 'NFL',
      nhl: 'NHL',
      mlb: 'MLB',
      epl: 'EPL',
      ipl: 'IPL',
    },
    'category': {
      'all-category': '모든 유형',
      video: '동영상',
      music: '음악',
      im: '실시간 채팅',
      flightAndHotel: '비행&숙박',
      subscription: '구독',
    },
    'filter': '필터',
    'reset': '리셋',
    submit: '완료',
    subcategory: {
      popular: '인기',
      sport: '스포츠',
      movie: '영화',
      tv: '텔레비전',
    },
    app: {
      acornTv: '아쿠어TV',
      amazonPrimeVideo: '아마존 프라임 비디오',
      beInSports: '비인스포츠',
      crunchyroll: '크루징런',
      dazn: '다즈엔',
      disneyPlus: '디妮콘+',
      netflix: '네이트빌리스',
      plex: '플렉스',
      roku: '로쿠',
      shudder: ' 전율',
      zattoo: '지토투',
      discoveryPlus: '디스커버리 플러스',
      paramountPlus: '파라ม즈+',
      sportsnetNow: '로저스 스포츠 네트워크',
      tsn: 'TSN',
      bbcIPlayer: '영국 방송사iPlayer',
      'hulu': '협력',
      'itv': '개별 TV',
      'slingTv': '슬링',
      'crackle': '크리커',
      'pandora': '판도라',
      'theCw': 'CW',
      'abc': '미국방송',
      'nbc': '네트워크비즈',
      'pluto': '플로토 TV',
      'starz': '스타즈',
      'tbs': '미국 TV',
      'attTv': 'AT&T TV',
      'channel4': '네번째',
      showtimeAnytime: '방영 시간',
      nbcSports: 'NBC 스포츠',
      epixNow: '에픽스나우',
      mgmPlus: 'MGM 플러스',
      xumo: 'Xumo',
      channel5: '다섯 번째',
      usaNetwork: '미국네트워크',
      fxNow: '지금 FX',
      vudu: 'Vudu',
      fubo: '부파 텔레비전',
      espn: '오락과 체육 프로그램 텔레비전망',
      tubiTv: '투비',
      ukTvPlay: 'UKTV 플레이',
      tvPlayer: 'TVPlayer',
      peacock: '공작새',
      pbs: '공중파 텔레비전',
      philo: '필로',
      vrv: 'VRV',
      showmax: '쇼맥스',
      hboMax: 'HBO 맥스',
      nhl: '전국 아이스하키 연맹',
      dsTv: 'DStv',
      sSportsPlus: '에스스포츠 +',
      filmrise: '필름라이즈',
      cbsSports: 'CBS 스포츠',
      nowTV: '나우 TV',
      molotovTv: '화염병 TV',
      mlb: '메이저리그',
      britBox: '브릿박스',
      stirr: '교반기',
      skyGo: '스카이고',
      hotstar: '디즈니+ 핫스타',
      sonyLiv: '소니LIV',
      nbaLeaguePass: 'NBA 리그 패스',
      amcPlus: 'AMC+',
      f1Tv: 'F1TV',
      frndlyTv: '친근한 TV',
      myTf1: '프랑스 텔레비전 한 대',
      qqMusic: 'QQ 음악',
      spotify: '스포티 파이',
      tidal: '갯벌',
      youtube: 'YouTube',
      youTubeMusic: 'YouTube 음악',
      weChat: '위챗',
      whatsApp: '왓츠앱',
      line: '선',
      signal: '신호',
      agoda: '아고다',
      expedia: '지유망',
      flyScanner: '스카이스캐너',
      googleFlights: '구글 항공편',
      qqSports: '텐센트 스포츠',
      skySports: '스카이스포츠',
      rakutenTv: '롯데 TV',
      fox: 'FOX',
      nflNetwork: 'NFL 네트워크',
      peacocktv: '잉글랜드 프리미어리그',
      zee5: 'Zee5',
      mxPlayer: 'MX 플레이어',
      tencentVideo: '텐센트 비디오',
      iqiyi: '아이치이',
      mangoTV: '망고TV',
      migu: '미구 비디오',
      bilibili: '삐리삐리',
      youku: '유쿠',
      altBalaji: 'ALT 발라지',
      willowTv: '윌로우TV',
      foxSports: 'FOX 스포츠',
      crave: '갈망하다',
      cbcGem: 'CBC 젬',
      jiocinema: '지오시네마',
      Telegram: '텔레그램',
      Messenger: '메신저',
      ChatGPT: '채팅GPT',
      CanalPlus: '카날플러스',
    },
    tip: {
      video: '현재 사용되는 일반 비디오 서비스 암호텍스트',
      music: '현재 사용되는 일반 음악 서비스 암호텍스트',
      im: '현재 IM의 인기 터널',
      flightAndHotel: '현재 사용되는 항공권과 숙박 예약을 위한 암호텍스트',
      subscription: '현재 사용되는 온라인 구독 서비스 암호텍스트',
      backupTunnel:
        '스마트 라우팅 모드에서 현재 터널에 사용 가능한 노드가 없으면 시스템은 {0}에 있는 백업 터널로 전환합니다.',
      fullTunnel: '전체 라우팅 모드에서는 모든 애플리케이션이 기본적으로 {0} 터널을 사용합니다.',
      noTunnelNodes: '노드 수가 0이므로 사용할 수 없습니다. 터널 페이지로 이동하여 {0} 터널의 노드를 새로 고치세요.',
      noTunnelNodesOkText: '새로 고치다',
      noTunnelNodesCancelText: '닫기',
      noTunnelAdded: '아직 이 터널을 추가하지 않았습니다. 사용하려면 터널 페이지로 이동하여 {0} 터널을 추가하세요.',
      noTunnelAddedOkText: '추가',
      noTunnelAddedCancelText: '취소',
      tunnelDeletedText: '{0} - {1} tunnel이삭제되었습니다',
      netflix: 'Netfix를사용할때발생하는播放오류문제를해결',
      fubo: 'Fubo TV를 사용하면 매우 저렴한 비용으로 프리미어 리그 라이브 스트리밍을 시청할 수 있습니다',
      tencentVideo: 'NBA 경기 관람 비용은 약 1달러입니다.',
      dazn: 'DAZN을 사용하면 단 $17.18의 매우 저렴한 비용으로 NFL, NFL, MLB, NBA, EPL을 시청할 수 있습니다.',
      youtube: '매우 저렴한 비용으로 YouTube Premium을 즐기는 방법',
    },
    intro: {
      'acornTv':
        'Acorn TV는 영국과 다른 곳에서 세계적인 미스터리， 드라마， 코미디 프로그램을 제공하는 스트리밍 서비스입니다. 당신은 클래식 시리즈를 연속으로 보거나， 새로운 사랑한 프로그램을 발견할 수 있습니다.',
      'abc': 'abc.com에서 온라인으로 ABC 쇼를 시청하세요. 독점 비디오와 무료 에피소드를 받아보세요.',
      'f1Tv':
        'F1 TV는 이제 웹, 앱, Google TV, Apple TV, Roku 및 Amazon Fire TV에서 사용할 수 있습니다. 모든 F1 라이브. 모든 F1 라이브. GP에서 모든 트랙 세션을 라이브 스트리밍합니다...',
      'youtube':
        'YouTube 공식 채널을 통해 전 세계적으로 새로운 소식과 트렌드를 알아볼 수 있습니다. 음악부터 문화, 인터넷 현상까지 꼭 봐야 할 비디오를 시청하세요.',
      'netflix':
        'Netflix는 많은 수상한 TV 프로그램， 영화， 애니메이션， 자막 비디오 등을 제공하는 스트리밍 서비스입니다. 수천의 인터넷 연결 장치를 통해 시청할 수 있습니다.',
      'fubo':
        'TV에서 Fubo를 시청하는 가장 쉬운 방법은 Roku, Apple TV, Amazon Fire TV 또는 Chromecast를 사용하는 것입니다. 스트리밍하려면 기기에 Fubo 앱을 설치하기만 하면 됩니다…',
      'disneyPlus':
        '신규작품부터 당신의 좋아하는 클래식작품까지， 과거， 지금， 미래는 모두 당신에게 belong합니다. 당신이 기대한것과도， 당신이 예상하지 못한것과도',
      crunchyroll:
        '크런치롤을 통해 최고의 애니메이션과 만화 스트리밍 콘텐츠를 탐색한다.귀멸의 칼날,해적왕,진격의 거인,나의 영웅학원등 히트작을 즐겼다',
      hulu:
        '온라인으로 드라마와 영화를 시청하다.인턴 그레이,우리들의 날,밥버거 가게,브루클린 구구,엠파이어,토요나이트 라이브등 인기 드라마와 유행 영화를 스트리밍한다.',
      'nbaLeaguePass':
        'NBA League Pass*를 구독하면 팬이 시즌 전체와 오프시즌 동안 실시간 및 주문형 게임 등을 즐길 수 있습니다.',
      'peacock':
        'Peacock을 구입하고 라이브 스포츠, 독점 오리지널, 히트 쇼, 블록버스터 영화를 스트리밍하세요. 인기 히트작, 인기 고전, 라이브 액션 등이 지금 Peacock에서 스트리밍됩니다. 다큐멘터리. 어린이 영화 및 쇼. 공작 채널은 연중무휴 24시간 운영됩니다. 홀마크 채널',
      'qqSports':
        'Tencent Sports는 중국의 유명한 스포츠 포털 웹사이트로 주로 국내 축구, 국제 축구, NBA, CBA 등의 섹션을 제공합니다.',
      'rakutenTv':
        '방송 TV | 무료 Top 15 채널 · 무료 | 인기 영화를 무료로 시청하세요 · 가게 | 热门 영화 구매 또는 대여 · 장르 · 무료 | 최근에 추가된 무료 콘텐츠 · 무료',
      'espn':
        'ESPN에서 실시간 스코어， 비지니스나 스포츠 소식을 얻으세요. ESPN+에서 개별 경기를 시청하고， 팀프로파크를 즐길 수 있습니다.',
      beInSports:
        'beIN SPORTS USA를 통해 스포츠 세계의 베일을 벗는다.주요 축구 리그 등의 독점 하이라이트 재생, 최신 뉴스, 데이터, 스코어 및 경기 일정을 얻습니다.',
      dazn: 'DAZN은 실시간 스포츠 생방송에 진정으로 집중하는 세계 최초의 스트리밍 플랫폼입니다...',
      cbsSports:
        '무료 24시간 스포츠 뉴스 네트워크.CBS Sports HQ를 시청하고 경기에 대한 모든 중요한 정보를 제공합니다.더 많은 멋진 컬렉션, 최고의 베팅, 성공에 대한 환상적인 조언',
      fox:
        'FOX Sports를 시청하고 NFL, MLB, NASCAR, WWE, NBA, NHL 등을 다루는 실시간 점수, 확률, 팀 뉴스, 선수 뉴스, 스트림, 비디오, 통계, 순위 및 일정을 확인하세요.',
      nflNetwork:
        'NFL 채널의 정보, 프로그램 업데이트 등을 얻으세요!NFL 네트워크는 NFL이 소유하고 운영하는 유일한 연중 운영 네트워크다.',
      peacocktv:
        'NBC.com에서 현재 및 클래식 NBC 프로그램의 전체 회차를 시청하십시오.또한 클립, 예고편, 사진 및 독점 온라인 기능을 찾을 수 있습니다.',
      sportsnetNow:
        'MLB 정상급 활약·MLB스포츠넷 영상: 블루제이스의 스프링어가 선두타자로 생일을 축하하며 홈에서 멀리 떨어진 홈런을 날리고 ·MLB스포츠넷 영상: 브레이브스의 아쿠냐 Jr.',
      nbcSports: 'NBC Sports의 최신 스포츠 뉴스와 점수를 획득하여 시대와 함께 발전하고 있습니다.',
      tsn:
        'TSN 웹사이트를 방문하여 호주오픈, NFL 플레이오프, 슈퍼볼, NHL 거래 마감일 등에 관한 최신 스포츠 뉴스 보도, 스코어, 멋진 순간, 리뷰를 얻으십시오.',
      nhl:
        '공식 내셔널 하키 리그(National Hockey League) 웹사이트에는 뉴스, 명단, 통계, 경기 일정, 팀, 동영상 등이 포함된다.',
      hotstar:
        'Disney+ Hotstar는 17개 언어로 100,000시간 이상의 드라마와 영화를 제공하고 모든 주요 콘텐츠를 다루는 인도 최대의 프리미엄 스트리밍 플랫폼입니다.',
      disneyPlus:
        '신작부터 좋아하는 클래식까지, 과거, 현재, 미래가 여러분의 것입니다. 당신이 기대했던 모든 것과 기대하지 않았던 모든 것. © 2023 디즈니. 브랜드: 마블, 픽사, 디즈니, 내셔널 지오그래픽, 스타워즈.',
      skySports:
        '축구, 골프, 럭비, 크리켓, F1 레이싱, 복싱, 미식축구, 농구, 최신 스포츠 뉴스를 즐겨보는 스포츠 이벤트.',
      sonyLiv:
        '인기 TV 쇼, 영화, 스포츠, 웹 시리즈를 시청할 수 있는 최고의 프리미엄 스트리밍 플랫폼입니다. 좋아하는 라이브 TV 채널을 온라인으로 즐겨보세요.',
      jiocinema:
        '최신 영화， 드라마， Voot 프로그램， 실시간 스포츠 경기， 특이한 샷을 보려면， 인도어， 영어와 다른 지역어를 사용하여 다양한 형식으로 온라인 시청하세요.',
      amazonPrimeVideo:
        '스트리밍되는 영화, 프로그램, 수상 경력을 자랑하는 아마존 오리지널 콘텐츠, 프리미엄 채널, 체험할 가치가 있는 스포츠 이벤트 등을 제공합니다. 무료 체험을 통해 중독성 있는 드라마, 대작 영화, 인기 프로그램을 감상하세요. 4K+HDR 콘텐츠를 지원하며, 언제 어디서나 다양한 기기에서 시청할 수 있습니다.',
      zee5:
        'ZEE5™에서 당신이 좋아하는 Desi 콘텐츠를 독점 50% 할인된 가격으로 구매하세요. 즉시 구매하고 ZEE5™에서 최애 콘텐츠를 즐기세요. 연간 패키지는 50% 할인 혜택이 적용되며, 4000여 개의 인기 영화, 1600여 개의 프로그램, 그리고 260여 개의 오리지널 작품을 소유하고 있습니다. 또한 18개 언어를 지원합니다.',
      mxPlayer:
        'MX 플레이어는 인도의 최고급 OTT 서비스로, 최신 음악 비디오를 재생하고 새로운/이전 영화, TV 프로그램, 코미디 프로그램, 인기 노래 등을 시청할 수 있습니다.',
      tencentVideo:
        '텐센트 비디오는 중국 선두의 온라인 비디오 미디어 플랫폼으로 다양한 콘텐츠, 최고의 시청 경험, 편리한 로그인 방식, 24시간 다양한 플랫폼 간 원활한 응용 프로그램 경험 및 빠른 공유 제품 특징을 제공하며 사용자의 온라인 요구를 주로 충족합니다.',
      iqiyi:
        '아이치이 (iQIYI.COM)는 대규모 비디오 웹 사이트로 대량, 우수하고 고화질의 온라인 비디오를 소유한 전문적인 온라인 비디오 재생 플랫폼입니다. 아이치이 영화 및 TV 프로그램 콘텐츠는 다양하며, 영화, 드라마, 애니메이션, 예능, 생활 등을 포괄합니다.',
      youku: 'Youku는 우수한 콘텐츠에 전념하며 대량의 정품 고화질 비디오를 온라인으로 시청할 수 있도록 합니다.',
      migu:
        '미구 비디오는 중국 내외에서 다양한 스포츠 이벤트 및 CBA, 아시아 챔피언스 리그, 중국 프로축구 등의 대형 스포츠 이벤트를 전체 방송합니다. 여자 배구, 탁구, 스케이팅, 스누커, WWE, UFC 등을 실시간 중계합니다.',
      mangoTV:
        'Mango TV - 온라인 동영상 웹사이트, 모두가 시청 중 - 인기 예능 프로그램, 최신 영화, TV 드라마를 온라인으로 시청하세요.',
      nbc:
        'NBC.com에서 현재 및 고전 NBC 프로그램의 완전한 에피소드를 시청하세요. 또한 클립, 예고편, 사진 및 독점 온라인 콘텐츠를 찾을 수 있습니다.',
      foxSports:
        'FOX 스포츠를 시청하고 실시간 스코어, 배당률, 팀 뉴스, 선수 뉴스, 라이브, 비디오, 통계 데이터, 순위 및 일정을 확인하세요. NFL, MLB, NASCAR, WWE, NBA, NHL 등을 포함합니다.',
      nfl:
        'NFL 채널의 정보 및 프로그램 업데이트를 얻으세요! NFL Network는 NFL이 소유하고 운영하는 연중 운영 네트워크입니다.',
      willowTv:
        '로그인 후 Willow에서 실시간 방송, 하이라이트 및 리플레이를 시청할 수 있습니다. Spectrum, Optimum, Fios, Dish, Optimum TV, Xfinity 및 Time Warner와 같은 서비스 제공 업체에서 사용할 수 있습니다.',
      mlb:
        '우리의 보도 콘텐츠에는 오디오 및 비디오 클립, 인터뷰, 통계 데이터, 일정 및 독점 보도 스토리가 포함되어 있습니다.',
      hboMax:
        'HBO Max는 모든 HBO 콘텐츠와 인기 있는 TV 드라마, 대작 영화 및 새로운 Max Originals를 결합한 독립적인 스트리밍 플랫폼으로 모든 이에게 적합합니다.',
      amcPlus:
        '평범함에서 벗어나 오늘 AMC+에서 독특하고 매혹적인 경험을 탐험하세요. AMC+의 에픽 시리즈에서 모든 것을 찾을 수 있는 독점 컨텐츠, 마음을 사로잡는 영화 및 클래식 시리즈. 고품질 드라마. 온디맨드 서비스. 독점 컨텐츠. 독점 영화. 신작 시리즈. 광고 없음.',
      attTv: 'DIRECTV 및 가정 인터넷을 포함한 최상의 TV 및 인터넷 혜택을 얻으세요. AT&T Fiber를 포함한 특별 혜택.',
      britBox:
        '미스터리, 코미디, 드라마, 다큐멘터리, 라이프 스타일 등 다양한 유형의 영국 TV 드라마를 포함한 최대의 영국 TV 드라마 스트리밍 컬렉션을 즐기세요.',
      crackle:
        'Crackle에서는 할리우드 영화를 무료로 시청할 수 있습니다. 편집되지 않은 액션, 공포, SF, 범죄, 코미디 등 다양한 장르를 다룹니다. 재생 버튼만 클릭하면 됩니다.',
      discoveryPlus:
        '모바일 기기, 태블릿, 컴퓨터, 게임기 및 연결된 TV에서 스트리밍을 즐길 수 있습니다. 여기에는 모든 사람에게 적합한 콘텐츠가 있습니다! discovery+는 음식, 가정 등 다양한 분야의 스트리밍 서비스 제공 업체입니다.',
      mgmPlus: 'MGM+를 구독하고 수천 편의 광고 없는 영화와 TV 시리즈를 즐기세요. 언제든지 구독을 취소할 수 있습니다.',
      filmrise:
        '오늘날의 변화무쌍한 산업 특성에 대응하여 최고의 영화와 TV 콘텐츠를 제공하기 위해 다층 전략을 채택하고 있습니다.',
      frndlyTv:
        'Roku, Amazon Fire TV 또는 Frndly TV를 사용하여 40개 이상의 라이브 채널 및 온디맨드 컨텐츠를 시청하세요. 이 중에는 Hallmark 채널, A&E 등이 포함되어 있습니다. 모바일 기기에서 언제 어디서나 시청할 수도 있습니다.',
      fxNow: 'FX, FXX 및 FXM 네트워크에서 FX의 오리지널 TV 프로그램, 영화 및 라이브 TV를 시청하세요.',
      paramountPlus:
        '수천 시리즈, 라이브 스포츠 이벤트 및 독점 오리지널 콘텐츠를 하나의 플랫폼에서 스트리밍하세요. 수많은 훌륭한 영화에 액세스하여 수상 경력에 빛나는 클래식부터 인기 신작까지 모두 즐겨보세요. 새로운 시리즈를 찾아 연속해서 시청하세요. 인기 리얼리티 쇼. 인기 MTV 프로그램. 30,000개가 넘는 시리즈. 급작스러운 뉴스 보도. 인기 CBS 프로그램.',
      pbs:
        'POV는 그 감동적인 내러티브와 적시성으로 유명한 영화입니다. 지금 시청하세요! POV는 TV 역사상 가장 오래된 독립 다큐멘터리 프로젝트입니다. 더 알아보세요! 독립적이고 비허구 작품. 500편이 넘는 영화. 잊을 수 없는 이야기.',
      philo:
        'TV에 더 많은 돈을 지불해야 할 이유가 있을까요? Philo는 월 25달러만 지불하면 라이브 TV와 온디맨드 콘텐츠를 제공합니다. 좋아하는 프로그램, 영화 등을 모든 기기에서 스트리밍하세요.',
      plex:
        'Plex는 신용 카드, 구독료 또는 숨겨진 비용이 없는 무료 스트리밍 앱입니다. 우리의 서비스를 무료로 유지하기 위해 광고가 포함되어 있습니다.',
      pluto:
        '최신 인기 영화에서 좋아하는 TV 시리즈까지 모든 것이 있습니다. 수백 개의 채널. 무료. 모든 기기에서 사용 가능. 지금 바로 시청하세요! 모든 기기 호환. 연속 시청. TV 및 영화 온디맨드. 수백 개의 채널. 즉시 스트리밍. 영원한 무료 이용.',
      roku:
        'Roku 장치를 사용하면 스트리밍 TV가 간단해집니다. 플레이어, 스마트 TV, 심지어 스마트 홈 제품에서 가장 완벽한 Roku 제품을 찾아 가정을 보다 안전하게 만듭니다...',
      showtimeAnytime:
        '무료로 SHOWTIME을 체험하고 오리지널 시리즈, 영화, 스포츠 이벤트, 다큐멘터리 등을 실시간 스트리밍하세요. 또한 구독이 필요하지 않은 별도로 지불하는 방식으로 복싱 경기도 주문할 수 있습니다.',
      shudder:
        '매월 $4.99만 지불하면 Shudder의 모든 콘텐츠를 한 플랫폼에서 이용할 수 있으며 "워킹 데드" 우주, 안 라이스 등 인기 시리즈를 시청할 수 있습니다. 무료로 Shudder를 체험해보세요...',
      slingTv:
        'Sling TV는 앱 기반 TV 서비스로 인터넷을 통해 실시간 TV 및 온디맨드 콘텐츠를 시청할 수 있습니다. 어디에서나 라이브 프로그램을 시청할 수 있습니다...',
      starz:
        'STARZ는 대담한 스토리텔링으로 다양한 시각을 삶에 제시합니다. 원래 시리즈, 영화, 추가 콘텐츠 등을 스트리밍하려면 가입하고 언제든지 시청할 수 있습니다. 광고 없음.',
      stirr:
        '... 및 엔터테인먼트 프로그램. 라이브 및 온디맨드. STIRR City 및 National이 지역 채널로 설정되어 있습니다. 시청을 시작하세요. 00:05. 100개 이상의 채널, 5000시간 이상의 TV 및...',
      tbs:
        '인 더 하이츠 · 이번 주 다이너마이트 · TBS 미리보기의 MLB 포스트시즌 · S18 | E14 아메리칸 대니스의 멀티버스.',
      theCw:
        'All American, Superman & Lois, Walker, FBoy Island, 프리미엄 스트리밍 시리즈, 영화, 스포츠 등을 갖춘 CW Network의 공식 홈입니다.',
      tubiTv:
        'Tubi는 선도적인 무료 고급 온디맨드 비디오 스트리밍 애플리케이션입니다. 50,000여 개 이상의 영화 및 TV 프로그램으로 최대 콘텐츠 라이브러리를 자랑하며 최고의...',
      usaNetwork:
        'USANetwork.com에서 현재 및 고전적인 USA 프로그램의 완전한 시리즈를 시청할 수 있습니다. 또한 프로그램 단편, 예고편, 사진 및 독점 온라인 특징 콘텐츠를 찾을 수 있습니다.',
      vudu:
        'Vudu를 사용하여 영화 및 TV 프로그램을 렌탈, 구매 및 시청하세요. Vudu 앱에서 온라인으로 시청하거나 좋아하는 연결된 장치를 사용하세요. 구독이 필요하지 않고 무료로 등록하세요.',
      xumo:
        'Xumo는 엔터테인먼트 산업 전반에 대한 차세대 스트리밍 플랫폼을 개발하고 제공하기 위해 설립되었습니다. 우리의 미션을 이해하고 참여하세요...',
      jiocinema:
        '최신 영화, TV 프로그램, Voot 프로그램, 라이브 스포츠 및 멋진 클립을 인도어, 영어 및 기타 지역 언어로 다양한 유형으로 온라인으로 시청하세요.',
      altBalaji:
        '온라인에서 최신 웹 시리즈, 원작 작품 및 영화를 고화질로 시청하세요. 스릴러, 액션, 성인, 코미디, 가족 드라마 등 다양한 유형을 선택할 수 있으며 여러 언어를 지원합니다.',
      bilibili:
        'Bilibili(bilibili.com)는 중국의 유명한 비디오 탄막 웹사이트로 시기적절한 애니메이션 출시, 활발한 ACG 커뮤니티, 재능 있는 콘텐츠 제작자의 창의적인 콘텐츠를 제공합니다. 사용자가 즐거움과 즐거움을 찾을 수 있는 곳입니다.',
      crave:
        '호러 영화 클래식, 무서운 TV 드라마 시리즈 및 할로윈 특집이 정성스럽게 계획된 컬렉션... 의외로 이름이 있는 코미디계 및 사회 압박.',
      cbcGem:
        "Schitt's Creek, Luther, Kim's Convenience 등 인기 프로그램 및 영화를 언제 어디서나 모든 기기에서 시청하세요.",
      bbcIPlayer: '라이브 BBC TV 채널 시청, 놓친 TV 프로그램 감상 및 BBC iPlayer에서 독점 콘텐츠 시청.',
      channel4: 'Channel 4, E4 및 Walter Presents에서 제공하는 최고의 프로그램을 온라인으로 시청하세요.',
      channel5:
        'My5에 오신 것을 환영합니다. Channel 5, 5STAR, 5USA, 5SELECT, 5ACTION 등의 채널에서 제공하는 인기 시리즈 컬렉션을 따라잡고 스트리밍하세요.',
      dsTv:
        '최고의 TV 엔터테인먼트 프로그램이 가까이 있습니다. 최신 스포츠 이벤트, 영화, TV 시리즈 및 리얼리티 쇼를 시청하고 DStv TV 가이드에 액세스하세요.',
      molotovTv:
        'Molotov는 무료입니다. 기타 기능을 자유롭게 추가하세요. · Molotov는 TV의 미래를 상징합니다. · TV 산업의 Spotify · TV는...',
      myTf1:
        'TF1, TMC, TFX, TF1 Séries Films, LCI 등의 라이브 프로그램을 시청하고, 50개 이상의 독점 특별 채널과 10,000시간 이상의 비디오 콘텐츠를 MYTF1에서만 즐기세요...',
      sSportsPlus:
        "Serie A'dan LaLiga'ya, Formula 1'den NBA'e, EuroLeague'den UFC'ye 그녀는 700번째 우승을 차지할 수 있으며 S Sport Plus와 같은 스포츠 플러스에서도 우승할 수 있습니다. …",
      showmax:
        'Showmax 구독 비용은 얼마입니까? Showmax는 다양한 선호도와 예산에 맞는 다양한 플랜을 제공하며 가격은 1인당 R39부터 시작합니다.',
      itv:
        'ITVX는 영국에서 제공하는 최신 스트리밍 서비스로 독점 프로그램, 대작 영화, 라이브 이벤트 및 수천 시간의 드라마를 하나의 플랫폼에서 제공합니다.',
      pandora:
        'Pandora에서 좋아하는 노래, 앨범, 플레이리스트 및 팟캐스트를 재생하세요. 광고 없이 온디맨드로 즐길 수 있도록 구독 플랜을 등록하세요...',
      qqMusic:
        'QQ 음악은 텐센트에서 제공하는 온라인 음악 서비스로, 대량의 음악을 온라인으로 청취하고, 신곡을 최초로 접하며, 가사 번역, 핸드폰 벨소리 다운로드, 고음질 음악 청취, 대량의 무손실 음원, 정품 음악 다운로드, 공간...',
      spotify: 'Spotify는 수백만 곡에 액세스할 수 있는 디지털 음악 서비스입니다.',
      tidal:
        'TIDAL은 아티스트 중심의 음악 스트리밍 플랫폼으로 전 세계 음악 커뮤니티에 1억 개 이상의 고품질 음원을 제공합니다.',
      weChat: '다양한 플랫폼에서 사용 가능하며, 그룹 채팅을 즐기고 음성, 사진, 비디오, 텍스트 메시지를 지원합니다.',
      whatsApp:
        'WhatsApp Messenger를 사용하여 친구와 가족과 연락을 유지하세요. WhatsApp은 무료로 제공되며 간편하고 안전하며 신뢰할 수 있는 메시지 및 통화 기능을 제공하며...',
      line: '어디서나 언제나 무료로 음성 통화 및 무료 메시지를 보낼 수 있는 새로운 통신 응용 프로그램!',
      signal:
        '무료로 텍스트, 음성 메시지, 사진, 비디오, GIF 및 파일을 공유하세요. Signal은 당신의 핸드폰 데이터 연결을 사용하여 문자 메시지 및 멀티미디어 메시지...',
      agoda:
        '즉시 예약하고 나중에 지불하며 무료로 취소하세요. 24/7 언어로 제공되는 실시간 고객 서비스. 경제적인 숙소부터 고급스러운 호텔 및 레지던스까지. 믿을 수 있는 객실 및 항공 여행 파트너...',
      expedia:
        '여러분이 꿈꾸던 휴가를 실현하는 여행 웹 사이트에 오신 것을 환영합니다. 숙소를 차량이나 항공편과 번들로 구매하여 더 많은 비용을 절약할 수 있습니다. 유연한 옵션을 검색하여 여러분에게 가장 적합한 것을 찾으세요...',
      flyScanner:
        '19달러부터 할인 항공편을 손쉽게 얻으세요. 비교하고 예약하는 것이 빠르고 편리한 한 번의 검색으로 저렴한 항공편, 호텔 및 렌터카를 찾아 수천 건의 특가를 찾아 최고의 거래를 얻으세요!',
      nowTV:
        'NOW 멤버십 비용은 얼마인가요? NOW 멤버십을 통해 원하는 콘텐츠를 원하는 방식으로 시청할 수 있습니다. 엔터테인먼트, 시네마, 스포츠 및 Hayu에 가입할 수 있습니다.',
      TVPlayer:
        'TVPlayer는 가장 인기있는 TV 채널과 고품질의 온디맨드 프로그램이 모이는 곳입니다. 40개 이상의 라이브 채널 및 수천 개의 프로그램을 시청하세요...',
      ukTvPlay:
        'UKTV Play는 Dave, Drama, W 및 Yesterday 채널의 온디맨드 허브입니다. 원하는 프로그램을 시청하는 데 사용되는 라이브 TV, 스트리밍 및 catch-up 기능을 제공합니다.',
      zattoo:
        '모든 장치에서 원하는 TV 프로그램을 실시간 및 타임 시프트로 시청하세요. 최고의 풀 HD 이미지 품질. 캐이블 TV보다 더 나은. 온디맨드 및 라이브 TV 콘텐츠를 제공합니다.',
      skyGo:
        'Sky 패키지를 통해 모바일, 태블릿, 노트북 또는 컴퓨터에서 또는 Apple TV 기기를 통해 언제 어디서나 라이브, 온디맨드 프로그램 및 catch-up TV를 시청하고 다양한 기기 간에 쉽게 전환할 수 있습니다.',
      Telegram:
        'Telegram은 안전하고 빠르며 간결한 커뮤니케이션 경험을 제공하려고 하며, 강력한 암호화 기능과 풍부한 기능으로 알려져 있습니다.',
      Messenger:
        'Messenger는 Facebook이 제공하는 즉시 메시지 전송 애플리케이션으로, 사용자가 텍스트 메시지, 음성 통화, 비디오 통화, 사진 전송, 이모티콘 등을 통해 다른 사람과 소통할 수 있습니다. Messenger는 처음에는 Facebook 웹사이트의 기능이었으나, 나중에는 독립적인 모바일 애플리케이션으로 발전하여 더 많은 기능과 서비스를 제공하고 있습니다.',
      ChatGPT:
        'ChatGPT는 OpenAI가 개발한 인공지능 언어 모델로, GPT (생성적 사전 훈련된 변환기) 아키텍처를 기반으로 합니다. 대규모 자연어 처리 데이터를 통해 훈련되어 자연어 텍스트를 이해하고 생성할 수 있습니다.',
      CanalPlus:
        'CANAL+는 프랑스의 유료 TV 채널로, 프랑스에서 가장 큰 유료 TV 네트워크 중 하나이며, 유럽에서 가장 중요한 유료 TV 서비스 제공자 중 하나입니다. 이 채널은 영화, 스포츠, 뉴스, 다큐멘터리, TV 드라마 등 다양한 프로그램을 제공합니다.',
    },
    selectTunnel: '터널 선택',
    notSetLabel: '설정되지 않음',
    directAccessLabel: '터널 없이 직접 접근',
    otherTunnelLabel: '다른 터널',
    recommendedTunnelLabel: '추천 터널',
    error: {
      getApps: '앱 가져오기 실패',
      getTunnels: '터널 목록 가져오기 실패',
      invalidApp: '유효하지 않은 앱',
      tunnelNotExist: '터널이 존재하지 않음',
      set: '터널 설정 실패',
      unset: '터널 설정 취소 실패',
    },
    success: {
      set: '터널 설정 성공',
      unset: '터널 설정 취소 성공',
    },
    searchInputPlaceholder: '필요한 서비스 검색',
    discountIntro: percent => `매월 ${percent} 구독료 절약`,
    discountPercent: percent => `-${percent} (미국 달러)/월`,
    guideButtonLabel: '세부 정보 확인',
  },
  // betanet
  betanet: {
    bindWalletSuccess: '지갑 바인딩 성공',
    deviceBound: '현재 장치가 다른 지갑에 이미 바인딩되어 있습니다',
    walletTaken: '지갑이 다른 장치에서 이미 사용 중입니다',
    deviceTypeIncompatible: '장치가 지갑과 호환되지 않습니다',
    invalidMnemonic: '유효하지 않은 지갑 니모닉',
    invalidPrivateKey: '유효하지 않은 지갑 개인 키',
    bindWalletError: '지갑 바인딩 실패',
    notBound: '현재 사용자가 지갑에 바인딩되지 않았습니다',
    unbindWalletSuccess: '지갑 바인딩 해제 성공',
    noActiveBinding: '장치가 지정된 지갑에 바인딩되어 있지 않습니다',
    unbindWalletError: '지갑 바인딩 해제 실패',
    walletManagement: '지갑 관리',
    bindWallet: '지갑 바인딩',
    downloadPrivateKey: '개인 키 다운로드',
    downloadKeyError: '개인 키 다운로드 실패',
    unbindBtnText: '지갑 바인딩 해제',
    unbindWarning:
      '반드시 개인 키를 다운로드했는지 확인하고 "나는 이미 개인 키를 다운로드했다"를 입력하고 계속 진행하십시오.',
    unbindWarningWords: '나는 이미 개인 키를 다운로드했다',

    generateWallet: '지갑 생성',
    verifyMnemonic: '니모닉 검증',
    mnemonicMismatch: '니모닉 검증 실패',
    bindBtn1Text: '지갑 바인딩 (니모닉)',
    bindBtn2Text: '지갑 바인딩 (개인 키)',
    dprManagement: 'DPR 관리',
    balanceLabel: 'DPR 잔액',
    balance: '잔액',
    creditLabel: '신용',
    operationLabel: '조작',
    amtOwingLabel: '시스템이 대신 대출한 금액',
    dailyConsumedLabel: '당일 사용된 데이터',
    dailySharedLabel: '당일 공유된 데이터',
    deposit: '입금',
    withdraw: '인출',
    publicKey: '공개 키',
    mnemonicInstruction: '니모닉을 기록하세요. 다음 단계에서 확인이 필요합니다.',
    enterMnemonic: '니모닉 입력',
    enterMnemonicTip: '니모닉을 순서대로 클릭하세요.',
    resetMnemonic: '니모닉 재설정',
    uploadPrivateKey: '개인 키 업로드',
    getWalletError: '지갑 정보 가져오기 실패',
    deviceWalletMismatch: '장치와 지갑이 일치하지 않습니다',
    walletNotFound: '지갑을 찾을 수 없습니다',
    generateWalletError: '지갑 생성 실패',
    missingMnemonic: '니모닉 누락',
    missingPrivateKeyFile: '개인 키 파일 누락',
    privateKeyFileOversize: '개인 키 파일이 10KB 제한을 초과합니다',
    transferTitle: '이체',
    transferHistoryTitle: '이체 기록',
    transferTo: '수령인',
    transferAmt: '이체 금액 (최대 6자리 소수)',
    invalidTo: '유효하지 않은 수령인',
    invalidAmt: '유효하지 않은 이체 금액',
    amtOutOfRange: '이체 금액은 양수이어야 하며 100만을 초과할 수 없습니다',
    transferSuccess: '이체 성공',
    senderRecipientSame: '발송인과 수령인이 동일할 수 없습니다',
    recipientNotFound: '수령인을 찾을 수 없습니다',
    insufficientFund: '잔액 부족으로 이체 실패',
    transferError: '이체 실패',
    votingResNotRetrieved: '위임 결과를 가져올 수 없음',
    candidateSelected: '위임한 검증 후보가 선정됨',
    candidateNotSelected: '위임한 검증 후보가 선정되지 않음',
    votingResNotReady: '현재 위임 결과를 조회할 수 없음',
    notVoted: '위임한 검증 후보가 없습니다',
    candidateNotFound: '검증 후보를 찾을 수 없음',
    votingResRetrievalErr: '위임 결과 가져오기 실패',
    from: '송금 주소',
    to: '수령 주소',
    type: '유형',
    amount: '양',
    time: '시간',
    txnTypes: {
      101: '초기 보상',
      201: '수동 전송',
      301: '위임 보상',
      401: '마이크로 결제',
    },
    walletAddress: '지갑 주소',
    notInGenesisNodeList: '창시 노드는 창시 채굴기에만 바인딩할 수 있습니다.',
    onChainStatusLabel: '체인 상태',
    onChainStatusDesc: {
      unknown: '-',
      onChain: '예',
      notOnChain: '아니오',
    },
    pwMgmtSection: {
      title: '비밀번호 관리',
      setBtnText: '비밀번호 설정',
      resetBtnText: '비밀번호 재설정',
    },
    passwordModal: {
      title: '지갑 비밀번호 설정',
      onePlaceholder: '비밀번호 입력',
      twoPlaceholder: '비밀번호 재입력',
      btnText: '확인',
    },
    setPasswordMsg: {
      mismatch: '비밀번호가 일치하지 않습니다',
      weak: '비밀번호는 최소 8자 이상이어야하며 소문자, 대문자 및 숫자를 포함해야합니다',
      alreadySet: '이미 지갑 비밀번호가 설정되었습니다',
      genericErr: '지갑 비밀번호 설정 실패',
      success: '지갑 비밀번호 설정 성공',
    },
    unstaking: {
      'label': '증권 정보',
      'getErr': '증권 중지 선택을 가져오는데 실패했습니다.',
      'alreadyChosen': '당신은 이미 스테이크 해제를 선택했습니다',
      'expired': '증권 중지 기간이 종료되었습니다.',
      'invalidCreditData': '이 지갑은 Deeper Chain에 위치하지 않습니다.',
      'phase1IsNotOver': '이 지갑의 스테이킹 기간은 아직 만료되지 않았습니다.',
      'setErr': '증권 중지 선택을 설정하는데 실패했습니다.',
      'announcementTitle': '스테이킹 이용 약관',
      'announcement': {
        'continueStaking':
          '스테이킹 갱신: 귀하의 지분은 6개월의 고정되지 않은 스테이킹 기간인 두 번째 기간에 자동으로 입력되었습니다. 기본 채굴 및 Genesis APY는 두 번째 기간 동안 동일하게 유지됩니다.',
        'duringStaking':
          '언스테이킹: 언스테이크 버튼을 클릭하면 두 번째 학기 중 언제든지 언스테이킹을 할 수 있습니다. 이 작업은 되돌릴 수 없습니다!',
        'stopStaking':
          '배포: 일단 언스테이킹되면 3개월의 선형 릴리스/배포 단계에 들어가게 됩니다. $DPR은 매일 Deeper Chain 지갑에 자동으로 배포됩니다.',
        'releaseStaking': '스테이킹 해제: DPR은 매일 DeeperChain 지갑에 자동으로 해제됩니다.',
      },
      'tipTitle': '친절 알림：',
      'tip': {
        'afterStopStaking_1':
          '1. 스테이크를 해제한 제네시스 노드는 제네시스 상태를 잃고 제네시스 노드가 될 수 있는 기회를 영원히 잃게 됩니다. 귀하의 장치는 기본 채굴 2.0에 즉시 참여할 수 없지만 대역폭 공유를 통한 유기적 신용 점수 누적은 계속됩니다.',
        'afterStopStaking_2': '2. 일단 언스테이킹되면 더 이상 즉시 유효한 $DPR 채굴 보상을 받을 수 없습니다.',
        'afterStopStaking_3':
          '3. 스테이킹을 해제하면 스테이킹된 크레딧이 신용 점수에서 차감되며, 대역폭 공유를 통해 얻은 유기 크레딧은 그대로 유지됩니다.',
        'afterStopStaking_4':
          '4. 일단 스테이킹을 해제하면 사용자는 즉시 다시 스테이킹할 수 없습니다(향후 공지를 기다려야 함).',
        'afterStopStaking_5':
          '5. 일단 스테이킹을 해제하면 사용자는 즉시 다시 스테이킹할 수 없습니다(향후 공지를 기다려야 함).',
        afterStopStaking_6: '6) 채굴에 대한 정보가 필요하시면 Medium 블로그에서 추가 리소스를 확인하세요.',
        mediumText1: '클릭하세요',
        mediumText2: 'Deeper Network 기본 마이닝 2.0',
        mediumText3: '상세 사항은',
        mediumLink:
          'https://deeper-network.medium.com/deeper-network-basic-mining-2-0-mining-updates-for-genesis-and-basic-mining-2ec2f112cfd4',
      },
      'btn': {
        'cancel': '이해하였습니다. 증권을 유지합니다.',
        'ok': '증권 중지',
      },
    },
    authModal: {
      title: '지갑 비밀번호',
      inputPlaceholder: '지갑 비밀번호 입력',
      btnText: '계속',
    },
    passwordNotSet: '지갑 비밀번호가 설정되지 않았습니다',
    passwordIncorrect: '지갑 비밀번호가 올바르지 않습니다',
    resetModal: {
      disclaimer:
        '지갑 비밀번호를 재설정하면 장치가 현재 바인딩된 모든 지갑과의 바인딩이 해제됩니다. 계속하려면 "지갑 비밀번호를 재설정합니다"를 입력하세요.',
      consent: '지갑 비밀번호 재설정에 동의합니다',
    },
    resetPasswordMsg: {
      noActiveBinding: '장치가 지정된 지갑에 바인딩되지 않았습니다',
      unbindErr: '지갑 바인딩 해제 실패',
      genericErr: '지갑 비밀번호 재설정 실패',
      success: '지갑 비밀번호 재설정 성공',
    },
    resetPasswordMsg: {
      noActiveBinding: '장치가 지정된 지갑에 바인딩되지 않았습니다',
      unbindErr: '지갑 바인딩 해제 실패',
      genericErr: '지갑 비밀번호 재설정 실패',
      success: '지갑 비밀번호 재설정 성공',
    },
    staking: {
      walletInfo: '지갑 정보',
      campaignLabel: '스테이킹 활동 유형',
      campaignText: {
        0: '골드 제네시스 노드',
        1: '실버 제네시스 노드',
        2: '스테이킹 채굴 1.0',
        3: 'DPRB 채굴',
        4: '스테이킹 채굴 2.0',
        7: '스테이킹 채굴 2.0',
        8: '스테이킹 채굴 2.0',
      },
      creditLabel: '현재 신용 점수',
      creditMaxLabel: '최대 신용 점수',
      creditLevelLabel: '신용 등급',
      stakeAndBurn: '스테이킹 및 연소',
      stakeForCredit: '스테이킹 증가',
      dailyReward: '일일 수익',
      view: '확인하다',
      payment: '지불 필요',
      insufficientBalance: '잔액 부족',
      stakeSuccess: '요청이 전송되었습니다. 신용 점수 업데이트를 기다려주십시오.',
      stakeError: '요청 전송 실패. 지갑 관련 정보를 확인하거나 고객 서비스에 문의하십시오.',
      burnForCredit: 'DPR 연소로 신용 점수 보충',
      burnSuccess: '요청이 전송되었습니다. 신용 점수 업데이트를 기다려주십시오.',
      burnError: '요청 전송 실패. 지갑 관련 정보를 확인하거나 고객 서비스에 문의하십시오.',
    },
  },
  // ChangePasswordData
  ChangePasswordData: {
    successMsg: '비밀번호가 성공적으로 변경되었습니다',
    newPasswordMismatch: '새 비밀번호가 일치하지 않습니다',
    oldPasswordMismatch: '기존 비밀번호가 올바르지 않습니다',
    samePasswordError: '새 비밀번호와 이전 비밀번호가 동일합니다',
    genericError: '비밀번호 변경 중 오류가 발생했습니다',
    title: '비밀번호 변경',
    oldPwd: '이전 비밀번호를 입력하세요',
    newPwd: '새 비밀번호를 입력하세요',
    newPwdAgain: '새 비밀번호를 다시 입력하세요',
    okText: '확인',
  },
  //dailyTraffic
  dailyTraffic: {
    historySize: '일 수',
    chartTitle: '트래픽',
    uptimeChartTitle: '가동 시간',
    hour: '시간',
    minute: '분',
    second: '초',
  },
  // flow
  flow: {
    totalTrafficLabel: '총 트래픽',
    localTraffic: '로컬 트래픽',
    clientTraffic: '클라이언트 트래픽',
    serverTraffic: '서버 트래픽',
    upload: '업로드',
    download: '다운로드',
    sessionSpeed: '세션 속도',
  },
  // login
  login: {
    pageTitle: '디퍼 네트워크에 오신 것을 환영합니다!',
    username: '사용자명',
    password: '암호',
    btnText: '로그인',
    successMsg: '로그인 성공',
    missingField: '사용자명 또는 암호가 누락되었습니다',
    wrongField: '사용자명 또는 암호가 올바르지 않습니다',
    invalidToken: '유효하지 않은 로그인 데이터',
    expiredToken: '로그인 데이터가 만료되었습니다',
    genericError: '로그인 오류',
    tooManyAttempts: '로그인 시도 횟수가 너무 많습니다',
  },
  //notification
  notification: {
    search: '검색',
    CP: '제어층',
    DP: '데이터층',
    MP: '관리층',
  },
  // DomainConfig
  domainConfig: {
    whiteList: '스마트 라우팅 목록',
    blackList: '직접 라우팅 목록',
    domain: '도메인',
    tunnel: '터널',
    selectTunnel: '터널 선택',
    region: '지역',
    country: '국가 또는 지역',
    noTunnel: '터널 사용 안 함',
    convertedTip: '웹 주소가 자동으로 도메인으로 변환되었습니다',
    urlInputPlaceholder: '예: https://www.google.com 또는 google.com',
    missingInfoWarning: '정보를 모두 입력하세요',
    invalidDomainError: '잘못된 도메인 형식 또는 도메인이 너무 깁니다',
    whitelistAddSuccess: '스마트 라우팅 목록에 성공적으로 추가되었습니다',
    existInBlacklist: '추가 항목이 직접 라우팅 목록에 이미 존재합니다',
    whitelistEntryExist: '스마트 라우팅 목록에 이미 추가 항목이 있습니다',
    tunnelNotExist: '터널이 존재하지 않아 추가할 수 없습니다',
    whitelistAddError: '스마트 라우팅 목록 항목 추가 실패',
    blacklistAddSuccess: '직접 라우팅 목록에 성공적으로 추가되었습니다',
    existInWhitelist: '추가 항목이 스마트 라우팅 목록에 이미 존재합니다',
    blacklistEntryExist: '직접 라우팅 목록에 이미 추가 항목이 있습니다',
    blacklistAddError: '직접 라우팅 목록 항목 추가 실패',
    conflict: '충돌로 인해 항목을 추가할 수 없습니다',
    whitelistDelSuccess: '스마트 라우팅 목록 항목이 성공적으로 삭제되었습니다',
    domainNotExist: '선택한 도메인이 존재하지 않습니다',
    whitelistDelError: '스마트 라우팅 목록 항목 삭제 실패',
    blacklistDelSuccess: '직접 라우팅 목록 항목이 성공적으로 삭제되었습니다',
    blacklistDelError: '직접 라우팅 목록 항목 삭제 실패',
    backupTips:
      '예비 터널이 활성화되어 있으며 스마트 라우팅 모드에서 추가한 터널이 일시적으로 사용 가능한 노드가 없는 경우 시스템은 기본적으로 예비 터널을 사용합니다 - {0}。',
    tips: {
      smartRouteList: '요구에 따라 원하는 도메인 및 해당 국가를 수동으로 추가할 수 있습니다',
      directRouteList: '이곳에 추가한 도메인은 DPN 터널을 통과하지 않고 로컬 네트워크를 직접 사용합니다',
    },
  },
  // ipConfig
  ipConfig: {
    whiteList: '스마트 IP 목록',
    blackList: '직접 IP 목록',
    ip: 'IP',
    tunnel: '터널',
    selectTunnel: '터널 선택',
    region: '지역',
    country: '국가 또는 지역',
    noTunnel: '터널 사용 안 함',
    convertedTip: '웹 주소가 자동으로 IP로 변환되었습니다',
    urlInputPlaceholder: '예: https://xxx.xxx.xxx.xxx 또는 xxx.xxx.xxx.xxx',
    missingInfoWarning: '정보를 모두 입력하세요',
    invalidIPError: '잘못된 IP 형식',
    whitelistAddSuccess: '스마트 IP 목록에 성공적으로 추가되었습니다',
    existInBlacklist: '추가 항목이 직접 IP 목록에 이미 존재합니다',
    whitelistEntryExist: '스마트 IP 목록에 이미 추가 항목이 있습니다',
    tunnelNotExist: '터널이 존재하지 않아 추가할 수 없습니다',
    whitelistAddError: '스마트 IP 목록 항목 추가 실패',
    blacklistAddSuccess: '직접 IP 목록에 성공적으로 추가되었습니다',
    existInWhitelist: '추가 항목이 스마트 IP 목록에 이미 존재합니다',
    blacklistEntryExist: '직접 IP 목록에 이미 추가 항목이 있습니다',
    blacklistAddError: '직접 IP 목록 항목 추가 실패',
    whitelistDelSuccess: '스마트 IP 목록 항목이 성공적으로 삭제되었습니다',
    ipNotExist: '선택한 IP가 존재하지 않습니다',
    whitelistDelError: '스마트 IP 목록 항목 삭제 실패',
    blacklistDelSuccess: '직접 IP 목록 항목이 성공적으로 삭제되었습니다',
    blacklistDelError: '직접 IP 목록 항목 삭제 실패',
    backupTips:
      '예비 터널이 활성화되어 있으며 스마트 라우팅 모드에서 추가한 터널이 일시적으로 사용 가능한 노드가 없는 경우 시스템은 기본적으로 예비 터널을 사용합니다 - {0}。',
    tips: {
      smartRouteList: '요구에 따라 원하는 IP 및 해당 국가를 수동으로 추가할 수 있습니다',
      directRouteList: '이곳에 추가한 IP는 DPN 터널을 통과하지 않고 로컬 네트워크를 직접 사용합니다',
    },
  },
  // queryRouting
  queryRouting: {
    title: '라우팅 조회',
    searchLabel: '조회 구성',
    search: '검색',
    searchResult: '검색 결과: ',
    invalidRoutingError: '조회 형식이 잘못되었습니다',
    defaultConfig: '기본 구성',
    userConfig: '사용자 구성',
    routingNotFound: '라우팅을 찾을 수 없습니다',
    queryRoutingError: '라우팅 조회 실패',
    convertedTip: '웹 주소가 자동으로 도메인이나 IP로 변환되었습니다',
    urlInputPlaceholder: '예: google.com 또는 xxx.xxx.xxx.xxx',
  },
  // rustdesk
  rustdesk: {
    ipLabel: '귀하의 IP 주소는',
    noPubIp: '공용 IP가 없습니다.',
    vpnUse:
      'Wireguard VPN 포트 전달을 사용하여 공용 IP를 얻을 수 있습니다. 이 공인 IP는 RustDesk 서비스에만 사용할 수 있으며 DPN 서비스 제공에는 사용할 수 없습니다.',
    config: {
      title: 'RustDesk 설정',
      switch: '켜기/끄기',
      forceKey: '키 일치 필요',
      relay: '쉼표로 구분된 릴레이 서버 (호스트:포트), 예: www.example.com:21116,192.168.1.1:21117',
      port: '포트 (2-65532)',
      saveBtnText: '저장',
      pubKey: '공개 키',
      emptyPubKey: '공개 키가 없습니다',
      resetBtnText: '공개 키 재설정',
    },
    vpn: {
      title: 'Wireguard VPN 포트 포워딩',
      switch: '켜기/끄기',
      ip: 'Wireguard 서버 IP',
      port: 'VPN 포워딩 포트',
    },
    guide: {
      title: '설정 안내',
      stepOne: '아래 버튼을 클릭하세요',
      stepTwoA: '해당 열에 정보를 입력하고 저장 버튼을 클릭하세요',
      idAndRelay: 'ID/릴레이 서버',
      idServer: 'ID 서버:',
      relayServer: '릴레이 서버:',
      apiServer: 'API 서버:',
      serverKey: '키:',
      stepTwoB: '또는 설정을 복사하여 클립보드에 저장하고 RustDesk에서 가져올 수 있습니다',
      copyBtnText: '서버 설정 복사하기',
    },
    success: {
      setSwitch: 'RustDesk 전환 성공',
      setForced: '키 일치 요구 성공',
      setRelayAndPort: '릴레이와 포트 저장 성공',
      resetPubKey: '공개 키 재설정 성공',
      setVpnSwitch: 'Wireguard VPN 전환 성공',
      copyToClipboard: '클립보드에 복사 성공',
    },
    error: {
      getNetworkAddress: 'IP 가져오기 실패',
      getConfig: 'RustDesk 설정 가져오기 실패',
      setSwitch: 'RustDesk 전환 실패',
      setForced: '키 일치 요구 실패',
      invalidPort: '유효하지 않은 포트',
      invalidRelay: '유효하지 않은 릴레이',
      setRelayAndPort: '릴레이와 포트 저장 실패',
      resetPubKey: '공개 키 재설정 실패',
      getVpnConfig: 'Wireguard VPN 설정 가져오기 실패',
      setVpnSwitch: 'Wireguard VPN 전환 실패',
      copyToClipboard: '클립보드에 복사 실패',
      getVersion: '버전 번호 가져오기 실패',
      getHbbrLog: 'HBBR 로그 가져오기 실패',
      getHbbsLog: 'HBBS 로그 가져오기 실패',
    },
  },
  // sharing
  sharing: {
    sharingTitle: '공유: ',
    btSharingTitle: 'Bit Torrent 공유: ',
    enabled: '활성화',
    disabled: '비활성화',
    sharingTrafficLimit: '월 공유 트래픽 제한',
    sharingBandwidthLimit: '공유 대역폭 제한',
    unlimited: '무제한',
    limited: '제한',
    setLabel: '설정',
    checkStartEndTime: '시작 시간과 종료 시간을 선택하세요',
    time: '시간',
    record: '기록',
    startTime: '시작 시간',
    endTime: '종료 시간',
    keyword: '키워드',
    search: '검색',
    webToSharingTitle: '웹에서 공유 규칙 적용',
    webToSharingDesc: '기기에 내장된 DNS 광고 필터링 및 맞춤 DNS 필터링 규칙을 적용하세요.',
    yes: '예',
    no: '아니오',
    download: '평문 로그',
    export: '암호화된 로그',
    success: {
      enableTrafficLimit: '월별 공유 트래픽 제한이 활성화되었습니다. 한도를 설정해주세요!',
      setSharingTrafficLimit: '공유 트래픽 제한 설정 성공',
      enableBandwidthLimit: '공유 대역폭 제한이 활성화되었습니다. 15Mbps와 1024Mbps 사이의 제한을 설정하세요!',
      setSharingBandwidthLimit: '공유 대역폭 제한 설정 성공',
    },
    errors: {
      getSharingState: '공유 상태 가져오기 실패',
      switchSharingState: '공유 상태 전환 실패',
      exceedsLimit: '공유 활성화 실패. 이미 트래픽 제한을 초과했습니다',
      getSharingTrafficLimit: '공유 트래픽 제한 가져오기 실패',
      setSharingTrafficLimit: '공유 트래픽 제한 설정 실패',
      invalidTrafficLimit: '트래픽 한도는 양수여야 합니다.',
      setSharingBandwidthLimit: '공유 대역폭 제한 설정 실패',
      invalidBandwidthLimit: '유효하지 않은 대역폭 제한. 15Mbps와 1024Mbps 사이여야 합니다',
      getSharingFilterConfig: '공유 필터 구성 가져오기 실패',
      setSharingFilterConfig: '공유 필터 구성 설정 실패',
    },
  },
  // system
  system: {
    hardwareInfo: '하드웨어 정보',
    softwareInfo: '소프트웨어 정보',
    networkAddress: '네트워크 주소',
    sessionInfo: '세션 정보',
    upgradeInfo: '업그레이드 정보',
    sn: '일련번호',
    cpu: 'CPU',
    cores: '코어',
    memory: '사용 중/사용 가능',
    systemVersion: '시스템 버전',
    appidSignVersion: '트래픽 프로토콜 식별 특성 라이브러리',
    urlFilterSignVersion: '웹 페이지 필터 특성 라이브러리',
    https: 'HTTPS',
    dns: 'DNS',
    rules: '규칙',
    ip: 'IP 주소',
    routerMac: '라우터 맥 주소',
    gatewayMac: '게이트웨이 맥 주소',
    maxSession: '최대 세션 수',
    currentSession: '현재 세션 수',
    tcpUdpIcmp: 'TCP/UDP/ICMP/TUNNEL',
    autoUpdate: '자동 업데이트',
    manualUpgrade: '수동 업그레이드',
    latestVersion: '최신 버전',
    upgrading: '업그레이드 진행 중...',
    systemStatusHistory: '시스템 이력 상태',
    cpuUsage: '24시간 CPU 사용률',
    memoryUsage: '24시간 메모리 사용률',
    off: '아니오',
    on: '예',
    nowCheckVersion: '현재 버전 확인 중',
    nowLatestVersion: '현재 최신 버전입니다',
    nowNewTestVersion: '현재 사용 중인 테스트 버전입니다',
    upgradeLoadingText:
      '펌웨어 업데이트 중입니다. 페이지를 새로 고치지 마시고 업데이트가 완료될 때까지 기다려주십시오~',
    upgradeSuccess: '업그레이드 성공. 곧 페이지가 새로 고쳐집니다~',
    upgradeError: {
      noImage: '업그레이드 패키지 누락',
      invalidImage: '잘못된 업그레이드 패키지',
      imageArchMismatch: '업그레이드 패키지와 장치가 일치하지 않음',
      noResponse: '장치가 이미 다시 시작되었습니다. 잠시 후에 페이지를 새로 고쳐 업그레이드가 성공했는지 확인하십시오',
      alreadyLatest: '현재 버전은 최신 버전입니다. 업그레이드할 필요가 없습니다~',
      generic: '업그레이드 중 오류 발생~',
    },
    copySuccess: '복사 성공',
    copyFail: '복사 실패',
  },
  // techSupport
  techSupport: {
    remoteConnection: {
      title: '원격 연결',
      loading: '연결 중...',
      popupTitle: '원격 연결 코드',
      intro:
        '적용 사례: <span>장치의 네트워크가 정상이지만 다른 기능 문제가 발생한 경우.</span><br />"원격 연결 코드"를 수동으로 생성하고 이 코드를 우리 고객 서비스 팀에 전송하면 기술 팀이 신속하게 문제를 해결하는 데 도움을 줄 것입니다. 코드의 유효성을 보장하기 위해 짧은 시간 내에 반복 생성을 피해주십시오.',
      buttonText: '연결 코드 생성',
      generateFeatureCodeFailure: '연결 코드 생성 실패',
      tips:
        '문제가 발생하면 자세한 정보를 고객 지원팀에 보내주십시오. 우리는 여러분을 돕고 문제가 해결되도록 최선을 다하겠습니다.',
      confirm: '복사 및 닫기',
      copySuccess: '클립보드에 성공적으로 복사됨',
      copyFailure: '클립보드로 복사하지 못했습니다',
      connectFailure: '원격 연결 실패',
    },
    logDownload: {
      title: '로그 다운로드',
      loading: '다운로드 중...',
      intro:
        '적용 사례: <span>장치가 대시 보드에 로그인할 수 있지만 네트워크 연결에 문제가 발생한 경우.</span><br />로그 파일을 수동으로 다운로드하고 이를 고객 서비스 팀에 전송하면 당신이 직면한 구체적인 상황을 이해하고 더 효과적인 도움을 제공할 것입니다.',
      buttonText: '다운로드',
      downloadSuccess: '다운로드 성공',
      downloadFailure: '다운로드 실패, 나중에 다시 시도하세요',
    },
    supportServices: {
      title: '필요한 도움 얻기',
      intro: '우리에게 몇 가지 구체적인 정보를 알려주십시오. 우리는 당신에게 적합한 해결책을 제공할 것입니다.',
      buttonText: '도움 받기',
    },
    promiseTips: {
      title: '보안 보증서:',
      intro:
        '안심하세요. 우리는 <span>원격 연결 및 로그 다운로드 기능을 엄격하게 제한</span>하여 문제 진단 및 해결 용도로만 사용하며 다른 어떤 목적에도 사용하지 않습니다. 당신의 개인 정보와 안전을 가장 엄격하게 보호합니다.',
    },
  },
  // builtinRules
  builtinRules: [
    {
      label: 'HTTPS 필터링 활성화 (HTTP 필터링 포함)',
      sslbypass: 'SSL 바이패스 활성화',
      value: 'YouTube',
    },
  ],
  // customrules
  customrules: [
    {
      label: 'HTTPS 필터링 활성화 (HTTP 필터링 포함)',
      value: 'YouTube',
    },
  ],
  // tproxy
  tproxy: {
    'browserCacheWarning':
      'HTTPS 광고 필터를 사용하면 광고를 필터링 할 수 없을 수 있으며, 브라우저 캐시를 정리해야합니다. HTTPS 필터만 활성화되어 있고 인증서가 설치되지 않은 경우 광고 필터링이 되지 않을뿐만 아니라 목록의 웹 사이트에 정상적으로 액세스하는 데도 영향을 줄 수 있습니다.',
    'triggerRulesTipsTitle': '팁',
    'buttonAddRule': '규칙 추가',
    'buttonInstallCertificate': '인증서 설치',
    'missingInfoWarning': '정보를 올바르게 입력했는지 확인하십시오.',
    'missingInfoSuccess': '작업 성공',
    'missingInfoError': '작업 실패',
    'certificateTip': '정상적인 사용을 보장하려면 인증서가 설치되었는지 확인하십시오.',
    'remind': '다시보지 않기',
    'tip': '광고 차단을 올바르게 하려면 다음 단계를 따르십시오.',
    'triggerRulesTips':
      'HTTPS 필터를 활성화하면 Deeper Connect가 목록의 웹 사이트 광고를 필터링하려면 컴퓨터에 Deeper Network의 루트 인증서를 설치하고 신뢰해야합니다.',
    'triggerRulesTips2':
      'HTTPS 필터만 활성화되어 있고 인증서가 설치되지 않은 경우 광고 필터링이 되지 않을뿐만 아니라 목록의 웹 사이트에 정상적으로 액세스하는 데도 영향을 줄 수 있습니다.',
    'mobileDownloadCertTips': 'Safari 브라우저를 사용하여 인증서를 다운로드하십시오.',
    'androidTVTip': 'Android TV를 사용하는 경우 앱을 다운로드하여 사용하십시오.',
    'errorMessage': {
      'maxLength': '규칙은 최대 1024 개까지입니다.',
      'ruleExisted': '이미 동일한 도메인 및 차단 규칙 표현이 있습니다.',
    },
    'label': {
      'domain': '도메인',
      'Pattern': '차단 규칙 표현',
      'ruleType': '규칙 유형',
      'web': '웹 브라우저',
      'app': '앱',
      'all': '전체',
      'Rewrite_Pattern': '다시 쓰기 규칙 표현',
      'Rewrite_Info': '다시 쓰기 규칙 정보',
      'Rewrite_Action': '행동',
      'enable': '활성화',
      'operation': '작업',
      'order': '순서',
    },
    'operation': {
      'delete': '삭제',
      'edit': '편집',
    },
    'modal': {
      'title': '규칙 추가',
      'confirmText': '제출',
      'cancelText': '취소',
    },
    'placeholder': {
      'domain': '올바른 도메인을 입력하십시오.',
      'Pattern': '올바른 차단 규칙 표현을 입력하십시오.',
      'ruleType': '규칙 유형을 선택하십시오.',
      'Rewrite_Pattern': '올바른 다시 쓰기 규칙 표현을 입력하십시오.',
      'Rewrite_Info': '다시 쓰기 규칙 정보를 입력하십시오.',
      'Rewrite_Action': '행동을 선택하십시오.',
    },
    'builtinRules': {
      'title': '광고 차단',
      'filterListtitle': 'HTTPS 필터 목록',
      'platform': '지원 플랫폼',
      'segOptions': ['인증서 설치 안내', '지원 목록'],
    },
    'customerRules': {
      'title': '사용자 정의 HTTPS 규칙',
    },
    guide: {
      'buttonDownloadCertificate': '인증서 다운로드 클릭',
      'buttonDownloadApp': 'SkyTube 다운로드 클릭',
      'tab': {
        'mobileInstall': '아이폰/아이패드',
        'macInstall': '맥 OS',
        'windowsInstall': '윈도우',
        'androidInstall': '기계적 인조 인간',
        'linuxInstall': '리눅스',
      },
      'mobile': {
        'videoTab': '비디오',
        'textTab': '텍스트',
        'step1': {
          'label': '단계 1',
        },
        'step2': {
          'label': '단계 2',
          'tip': '허용을 클릭',
        },
        'step3': {
          'label': '단계 3',
          'tip': "휴대폰 '설정' 열기, '이미 다운로드한 설명 파일' 클릭",
        },
        'step4': {
          'label': '단계 4',
          'tip': '설치 클릭, 휴대폰 비밀번호 입력',
        },
        'step5': {
          'label': '단계 5',
          'tip': '설치 클릭',
        },
        'step6': {
          'label': '단계 6',
          'tip': '설치 완료',
        },
        'step7': {
          'label': '단계 7',
          'tip': "휴대폰 '설정' > '일반' > '내 기기 정보' > '인증서 신뢰 설정'에서 방금 설치한 인증서 활성화",
        },
      },
      'windows': {
        'step1': {
          'label': '단계 1',
        },
        'step2': {
          'label': '단계 2',
          'tip': "다운로드한 인증서를 클릭하고 '인증서 설치'를 클릭",
        },
        'step3': {
          'label': '단계 3',
          'tip': "'저장 위치'를 '로컬 컴퓨터'로 선택하고 '다음'을 클릭",
        },
        'step4': {
          'label': '단계 4',
          'tip': "'모든 인증서를 다음 저장소에 넣으려면'을 선택하고 '찾아보기'를 클릭",
        },
        'step5': {
          'label': '단계 5',
          'tip': "'신뢰할 수 있는 루트 인증 기관'을 선택하고 '확인'을 클릭",
        },
        'step6': {
          'label': '단계 6',
          'tip': "'다음'을 클릭",
        },
        'step7': {
          'label': '단계 7',
          'tip': "'완료'를 클릭하여 인증서 설치 완료",
        },
      },
      'mac': {
        'step1': {
          'label': '단계 1',
        },
        'step2': {
          'label': '단계 2',
          'tip':
            '다운로드한 인증서를 클릭하면 시스템에 "인증서 추가" 팝업 창이 표시됩니다. "키체인"에서 "로그인"을 선택한 후 "추가"를 누르세요."',
        },
        'step3': {
          'label': '단계 3',
          'tip': '키체인 액세스 창의 사이드바에 있는 기본 키체인에서 로그인을 선택합니다.',
        },
        'step4': {
          'label': '단계 4',
          'tip': "'deeper.network' 인증서를 찾아 선택하고, 마우스 오른쪽 버튼을 클릭하여 '소개 표시'를 선택합니다.",
        },
        'step5': {
          'label': '단계 5',
          'tip': "'소개'에서 '신뢰'를 클릭하고, '시스템 기본값 사용'을 '항상 신뢰'로 체크합니다.",
        },
        'step6': {
          'label': '단계 6',
          'tip': "'소개' 창의 좌상단을 닫고, 컴퓨터 비밀번호를 입력하고 '설정 업데이트'를 클릭합니다.",
        },
        'step7': {
          'label': '단계 7',
          'tip': '인증서 설치가 완료되었습니다.',
        },
      },
      'linux': {
        'rootTab': '시스템 루트 인증서 가져오기 튜토리얼',
        'root': {
          'step1': {
            'label': '단계 1',
          },
          'step2': {
            'label': '단계 2',
            'tip':
              '<p>인증서 파일을 /usr/local/share/ca-certificates/로 복사하십시오.</p>\
              <p>이 디렉터리는 추가로 로컬에 설치된 CA 인증서를 저장하기 위한 것입니다. 인증서 파일은 .crt 확장자를 가진 PEM 형식 파일이어야 합니다.</p>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /usr/local/share/ca-certificates/</code></div>',
          },
          'step3': {
            'label': '단계 3',
            'tip':
              '<p>인증서 저장소 업데이트</p>\
              <p>update-ca-certificates 명령어를 사용하여 인증서 저장소를 업데이트하세요. 이는 시스템 내의 인증서 신뢰 체인을 자동으로 새로고침합니다.</p>\
              <div class="code"><code>sudo update-ca-certificates</code></div>\
              <p>이 명령어는 /usr/local/share/ca-certificates/ 디렉토리 내의 인증서 파일들을 읽고, 시스템의 신뢰 목록에 추가합니다.</p>\
              <em><em class="warning">주의：</em>리눅스 배포판에 따라 인증서 저장소 위치와 업데이트 명령어가 다를 수 있습니다.</em>\
              <em>예를 들어, Red Hat 기반 시스템(예: Fedora, CentOS)에서는 인증서를 /etc/pki/ca-trust/source/anchors/로 복사해야 할 수 있습니다,</em>\
              <em>그리고 update-ca-trust 명령어를 실행하여 인증서 저장소를 업데이트해야 합니다.</em>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /etc/pki/ca-trust/source/anchors/</code>\
              <code>sudo update-ca-trust</code></div>\
              <p>이 단계들을 수행한 후에, 설치한 루트 인증서는 시스템에 의해 신뢰될 것입니다.</p>',
          },
        },
        'chrome': {
          'step1': {
            'label': '단계 1',
          },
          'step2': {
            'label': '단계 2',
            'tip': "주소 표시줄에 chrome://settings/certificates를 입력하고 '<b>Authorities</b>' 탭을 선택합니다.",
          },
          'step3': {
            'label': '단계 3',
            'tip': "'Import'를 클릭하고 방금 다운로드한 인증서를 찾아 'Open'을 클릭합니다.",
          },
          'step4': {
            'label': '단계 4',
            'tip': "'<b>인증서 관리자</b>' 창에서 모든 옵션을 선택하고 '확인'을 클릭하여 인증서 설치를 완료합니다.",
          },
        },
        'firefox': {
          'step1': {
            'label': '단계 1',
          },
          'step2': {
            'label': '단계 2',
            'tip':
              "주소 표시줄에 about:preferences#privacy를 입력하고 'Certificates'를 찾아 'View Certificates'를 클릭합니다.",
          },
          'step3': {
            'label': '단계 3',
            'tip': 'Select Store Location as Local Machine, click Next',
          },
          'step4': {
            'label': '단계 4',
            'tip': "방금 다운로드한 인증서를 선택하고 'Open'을 클릭합니다.",
          },
          'step5': {
            'label': '단계 5',
            'tip': "모두 선택하고 'OK'를 클릭하여 인증서 설치를 완료합니다.",
          },
        },
      },
      'android': {
        'appTab': '앱',
        'browserTab': '브라우저',
        'app': {
          'step1': {
            'label': '단계 1',
          },
          'step2': {
            'label': '단계 2',
            'tip': 'SkyTube를 설치하고 광고 없이 동영상을 즐기세요.',
          },
        },
        'browser': {
          'step1': {
            'label': '단계 1',
          },
          'step2': {
            'label': '단계 2',
            'tip': "휴대폰 '설정'을 열고 '보안'을 찾습니다.",
          },
          'step3': {
            'label': '단계 3',
            'tip': "'보안'으로 이동하고 '암호화 및 자격 증명'을 찾아 클릭합니다.",
          },
          'step4': {
            'label': '단계 4',
            'tip': "'인증서 설치'를 클릭합니다.",
          },
          'step5': {
            'label': '단계 5',
            'tip': 'CA 인증서를 선택합니다.',
          },
          'step6': {
            'label': '단계 6',
            'tip': "'계속 설치'를 클릭하고 휴대폰 비밀번호를 입력합니다.",
          },
          'step7': {
            'label': '단계 7',
            'tip': '다운로드한 인증서를 선택합니다.',
          },
          'step8': {
            'label': '단계 8',
            'tip': "'CA 인증서가 이미 설치되었습니다.' 안내가 나오면 설치가 완료된 것입니다.",
          },
        },
      },
    },
  },
  // SSLBypassCheckbox
  SSLBypassCheckbox: {
    'sslBypass': 'SSL 바이패스 기능 활성화',
    'sslBypassTips': '인증서가 설치되지 않은 기기도 네트워크에 연결할 수 있도록 허용합니다.',
    'sslBypassWaring': 'SSL 바이패스 기능을 사용하는 경우 광고 필터링이 부분적으로 작동하지 않을 수 있습니다.',
    'success': '작업 성공',
    'failed': '작업 실패',
  },
  // tunnel
  tunnel: {
    'configuredTunnel': '사용자 정의 터널',
    countryTooltip: '국가명을 퍼지 검색하거나 국가 코드(대문자)를 입력하고 Enter를 눌러 원하는 국가를 검색하세요.',
    'region': '지역',
    'country': '국가 또는 지역',
    'activeTunnelNum': '활성 IP 수',
    'activeIp': '활성 IP',
    'switchNode': '활성 IP 전환',
    'switchIpTitle': 'IP 전환',
    'initTunnelTitle': '터널 재초기화',
    'initTunnelTitleTips': '한 터널에는 3 개의 IP가 있으며 터널을 새로 고침하면 세 개의 새 IP로 교체됩니다.',
    'selectRegion': '지역 선택',
    'selectCountry': '국가 또는 지역 선택',
    'missingInfoWarning': '모든 정보를 입력하십시오',
    'tunnelAddSuccess': '터널이 성공적으로 추가되었습니다. 페이지가 3 초 안에 새로 고쳐집니다.',
    'tunnelExist': '터널이 이미 있습니다',
    'reachedMax': '터널 수가 최대로 도달했습니다',
    'tunnelConflict': '추가하려는 터널이 이미 있는 터널과 충돌합니다 {0} - {1}',
    'tunnelAddError': '터널 추가 실패',
    'tunnelDelSuccess': '터널이 성공적으로 삭제되었습니다. 페이지가 3 초 안에 새로 고쳐집니다.',
    'tunnelNotExist': '터널이 존재하지 않습니다',
    'usedByFullMode': '터널이 DPN 전체 경로에서 사용 중이어서 삭제할 수 없습니다',
    usedByAccessControl: '삭제할 터널은 액세스 제어에서 사용됩니다.',
    'usedByBackup': '백업 터널이이 터널을 사용 중이어서 삭제할 수 없습니다',
    'tunnelInUse': '터널이 라우팅 테이블에서 사용 중이어서 삭제할 수 없습니다',
    'tunnelDelError': '터널 삭제 실패',
    'switchNodeSuccess': '노드 전환 성공. 페이지가 3 초 안에 새로 고쳐집니다.',
    'noActiveIp': '전환할 활성 IP가 없습니다',
    'ipNotChanged': 'IP가 변경되지 않았습니다',
    'switchNodeError': '노드 전환 실패',
    'refreshLabel': '새로 고침',
    'getNewIPLabel': '새 IP 가져오기',
    'refreshError': '터널 새로 고침 실패',
    'refreshSuccess': '터널 새로 고침 요청이 제출되었습니다. 페이지가 3 초 안에 새로 고쳐집니다.',
    'tips': {
      'refreshTunnel': '새로 고칠 새로운 활성 터널을 위해',
      'configuredTunnel':
        '원하는 지역 터널을 수동으로 추가한 후 AtomOS는 요구 사항과 일치하는 노드를 전 세계에서 검색합니다.',
    },
    'nodeLbMode': {
      'label': '노드 선택 모드',
      'none': {
        'label': '일반',
        'desc':
          '터널 내에서 모든 네트워크 연결이 하나의 활성 노드를 통과하며 사용자는 언제든지 이 노드로 전환할 수 있습니다.',
      },
      'random': {
        'label': '랜덤',
        'desc': '시스템은 각 연결에 자동으로 무작위로 노드를 선택합니다.',
      },
      'domain': {
        'label': '도메인 감지',
        'desc':
          '시스템은 여러 노드를 사용하여 네트워크에 액세스하지만 특정 도메인의 액세스를 항상 동일한 노드로 라우팅하여 웹 사이트 액세스 오류의 위험을 감소시킵니다.',
      },
      'rr': {
        'label': '라운드 로빈',
        'desc': '각 연결 시스템은 여러 노드 중에서 순서대로 선택하여 더 나은 네트워크 가속을 지원합니다.',
      },
      'failure': '노드 로드 밸런싱 모드 저장 실패',
      'success': '노드 로드 밸런싱 모드 저장 성공',
    },
  },
  // userConsent
  userConsent: {
    'notice': '주의: 동의하기 전에는 사용 약관 및 개인 정보 보호 정책이 비활성화됩니다.',
    'consentStart': '나는 Deeper Network의 ',
    'termsOfUseNotRead': '이용 약관을 아직 읽지 않았습니다.',
    'termsOfUseLink': 'https://shop.deeper.network/terms-of-service?lang=ko',
    'termsOfUseTitle': '이용 약관',
    'consentAnd': ' 및 ',
    'privacyPolicyNotRead': '개인 정보 보호 정책을 아직 읽지 않았습니다.',
    'privacyPolicyLink': 'https://shop.deeper.network/privacy-policy?lang=ko',
    'privacyPolicyTitle': '개인 정보 보호 정책',
    'confirmBtn': '확인',
    'consentError': '동의 확인 실패',
  },
  // customConfigLang
  customConfigLang: {
    'missingInfoWarning': '도메인 누락',
    'invalidDomainError': '도메인 형식이 잘못되었거나 도메인이 너무 깁니다',
    'whitelist': '화이트리스트',
    'blacklist': '블랙리스트',
    'domain': '도메인',
    'whitelistAddSuccess': '화이트리스트에 성공적으로 추가되었습니다',
    'whitelistEntryExist': '화이트리스트에 이미 추가된 항목이 있습니다',
    'reachedWhiteMax': '화이트리스트 한도에 도달했습니다',
    'whitelistAddError': '화이트리스트 항목 추가 실패',
    'blacklistAddSuccess': '블랙리스트에 성공적으로 추가되었습니다',
    'blacklistEntryExist': '블랙리스트에 이미 추가된 항목이 있습니다',
    'reachedBlackMax': '블랙리스트 한도에 도달했습니다',
    'blacklistAddError': '블랙리스트 항목 추가 실패',
    'whitelistDelSuccess': '화이트리스트 항목이 성공적으로 삭제되었습니다',
    'whitelistDelError': '화이트리스트 항목 삭제 실패',
    'blacklistDelSuccess': '블랙리스트 항목이 성공적으로 삭제되었습니다',
    'blacklistDelError': '블랙리스트 항목 삭제 실패',
    'import': '가져오기',
    'fileButtonText': '클릭하여 업로드',
    'fileSizeOverLimit': '업로드 파일이 최대 크기 2MB를 초과합니다',
    'whitelistImportSuccess': '화이트리스트 가져오기 성공, {0} 항목이 가져와졌습니다',
    'whitelistImportError': '화이트리스트 가져오기 실패',
    'blacklistImportSuccess': '블랙리스트 가져오기 성공, {0} 항목이 가져와졌습니다',
    'blacklistImportError': '블랙리스트 가져오기 실패',
    'noValidEntry': '업로드 파일에 유효한 도메인 항목이 없습니다',
    'zeroImported': '도메인 항목을 가져오지 못했습니다',
  },
  // defaultConfigLang
  defaultConfigLang: {
    'browserCacheWarning':
      '부모 관리 기능이 활성화된 경우 성인 웹 사이트나 소셜 미디어를 정상적으로 차단할 수 없다면, 브라우저 캐시를 정리하고 필터링된 장치를 다시 시작해야 합니다.',
    'queriesFor24Hours': '지난 24시간 동안의 쿼리',
    'totalQuery': '전체 쿼리',
    'blockedQuery': '차단된 쿼리',
    'blockedPercentage': '차단 비율',
    'ruleTotal': '규칙',
    'adQuery': '광고 차단',
    'trackerQuery': '추적 차단',
    'maliciousQuery': '악성 차단',
    'pornQuery': '성인 콘텐츠 차단',
    'legendTotal': '총합',
    'legendBlocked': '차단됨',
    'categoryStateEnabled': '활성화',
    'categoryStateDisabled': '비활성화',
    'categoryState2Hours': '2시간 비활성화',
    'categoryState4Hours': '4시간 비활성화',
    'categoryState8Hours': '8시간 비활성화',
    'setCategoryStatesErr': '카테고리 상태 설정 오류',
    'enableAll': '모든 필터 활성화',
    'disableAll': '모든 필터 비활성화',
  },
  // wifiSettings
  wifiSettings: {
    'workMode': '작업 모드',
    'currentWorkMode': '현재 작업 모드',
    'upstreamTitle': '상향 링크 설정',
    'backHome': '홈으로 돌아가기',
    'wire-ap': '유선 WAN',
    'wlan-ap': '무선 WAN',
    'downstreamTitle': '무선 LAN',
    'notConnect': '인터넷에 연결되지 않음',
    'apConfigWarning':
      'Wi-Fi SSID 및 Wi-Fi 비밀번호를 저장한 후 Deeper 기기를 통해 인터넷에 연결하려면 Deeper 기기 핫스팟을 다시 스캔하고 비밀번호를 적용해야 합니다',
    'attention': '주의',
    'buttons': {
      'scanWifi': '스캔',
      'connectStatus': '상태',
      add: '추가',
      'save': '저장',
      'cancel': '취소',
      'downloadLog': '로그 다운로드',
    },
    'connect': '연결',
    'forget': '잊어버리다',
    'waiting': {
      'content': '연결 중이며 시간이 필요합니다. 기다려 주십시오...',
      'cancelBtn': '연결 취소',
    },
    'modals': {
      'hiddenWifi': {
        'title': '숨겨진 Wi-Fi 추가',
        'okBtn': '추가',
      },
      'scanWifi': {
        'title': '경고',
        'content': '네트워크가 일시적으로 끊어집니다',
        'ok': '확인',
        'cancel': '취소',
      },
    },
    'label': {
      'ssid': 'Wi-Fi 이름',
      'password': 'Wi-Fi 비밀번호',
      'onlyUse': '사용 전용',
      'hidden': 'SSID 숨김',
      'freqBand': '주파수 대역',
    },
    'placeholder': {
      'ssid': 'SSID를 입력하세요',
      'password': '비밀번호를 입력하세요',
    },
    'message': {
      'saveSuccess': '성공적으로 저장됨',
      'saveError': '저장에 실패했습니다. 다시 시도하십시오',
      'connectSuccess': '성공적으로 연결됨',
      'forgetSuccess': '성공적으로 잊어버림',
      'inputError': '입력을 확인하십시오',
      'invalidWifiDev': '현재 사용 가능한 Wi-Fi 장치가 없어 Wi-Fi 설정을 수행할 수 없습니다',
      'illegalWifiDev': 'Wi-Fi 설정은 AP 라우터 모드 및 무선 중계 모드에서만 사용할 수 있습니다',
      'invalidSsid': '알파벳, 숫자, _ 및 -만 지원됩니다',
      'invalidPassword': '유효한 비밀번호를 입력하십시오',
      'notConnect': '인터넷에 연결되지 않음',
      'retry': '다시 시도하십시오',
      'connectionError': '연결 실패',
      'forgetError': '잊어버리기 실패',
      'wifiNotSupported': 'Wi-Fi를 지원하지 않음',
      'networkNotFound': '네트워크를 찾을 수 없음',
      'pskNotGenerated': 'PSK가 생성되지 않음',
      'wrongPassword': '잘못된 비밀번호',
      'timeout': '연결 시간 초과',
      'logDownloadError': '로그 다운로드 실패',
      'hasBeenConnected': '연결되었습니다',
      'dhcpSuccess': '성공',
      'dhcpFailure': '실패',
    },
    'connectionRequestedTitle': '연결 요청됨',
    'connectionRequestedContent':
      "연결 상태를 확인하려면 '상태' 옵션에서 확인하십시오. Deeper Connect와 연결이 끊어진 경우 DeeperWiFi에 다시 연결하십시오.",
    'gotIpFromDhcpLabel': 'DHCP에서 IP 주소 획득',
  },
  // parentalControlData
  parentalControlData: {
    'browserCacheWarning':
      '부모 관리 기능이 활성화된 경우 성인 웹 사이트나 소셜 미디어를 정상적으로 차단할 수 없다면, 브라우저 캐시를 정리하고 필터링된 장치를 다시 시작해야 합니다.',
    'customerRule':
      '여기에서 아이의 대부분의 성인 및 소셜 웹 사이트를 차단할 수 있습니다. 다른 웹 사이트를 차단하려면 사용자 정의 도메인 차단 기능을 사용하여 더 많은 웹 사이트를 추가할 수 있습니다.',
    'customerRuleClickTips': '사용자 정의 도메인 차단 페이지에 액세스하려면 {0}하십시오.',
    'toCustomerRuleButtonText': '여기를 클릭하세요',
    'parentalControl': '부모 관리',
    'pornQuery': '성인 콘텐츠 차단',
    'socialQuery': '소셜 미디어 차단',
    'legendBlocked': '차단됨',
    'socialStateEnabled': '활성화',
    'socialStateDisabled': '비활성화',
    'socialState2Hours': '2시간 동안 비활성화',
    'socialState4Hours': '4시간 동안 비활성화',
    'socialState8Hours': '8시간 동안 비활성화',
    'pornStateEnabled': '활성화',
    'pornStateDisabled': '비활성화',
    'pornState2Hours': '2시간 동안 비활성화',
    'pornState4Hours': '4시간 동안 비활성화',
    'pornState8Hours': '8시간 동안 비활성화',
    'setCategoryStatesErr': '필터링 상태 설정 오류',
  },
  reboot: {
    success: {
      saveConfig: '성공적으로 저장 되었음',
    },
    failure: {
      getConfig: '자동 재부팅 구성을 가져오지 못했습니다',
      saveConfig: '자동 재부팅 구성을 저장하지 못했습니다',
    },
    buttons: {
      reboot: '재부팅하려면 클릭',
      save: '저장',
    },
    week: {
      0: '매일',
      1: '월요일',
      2: '화요일',
      3: '수요일',
      4: '목요일',
      5: '금요일',
      6: '토요일',
      7: '일요일',
    },
    title: {
      manual: '지금 다시 시작',
      auto: '예약된 재부팅',
    },
    label: {
      period: '기간',
      time: '시간',
    },
  },
};
