import { SYNC_DPN_MODE } from '../actions/types';

const initState = {
  dpnMode: 'disabled',
};

const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SYNC_DPN_MODE:
      return {
        ...state,
        dpnMode: action.payload.dpnMode,
      };
    default:
      return state;
  }
};

export default { initState, reducer };
