export default function windowResize() {
  window.$$resizeFunctionMap = {};

  let resizeTimer = null;
  window.onresize = async function () {
    if (resizeTimer) {
      clearTimeout(resizeTimer);
    }
    resizeTimer = setTimeout(() => {
      Object.keys(window.$$resizeFunctionMap).forEach(key => {
        let fn = window.$$resizeFunctionMap[key];
        if (fn) {
          if (!fn()) {
            window.$$resizeFunctionMap[key] = null;
          }
        }
      });
    }, 1000);
  };
}
