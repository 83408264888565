export default {
  type: 'english',
  internationalize: {
    type: 'english',
    common: {
      tip_text: 'Tip',
      search: 'Search',
      add: 'ADD',
      delete: 'DEL',
      confirm: 'OK',
      cancel: 'Cancel',
      warning: 'Warning',
      utcDisclaimer: 'Dates are in UTC',
      toOpen: 'To Open',
    },
    certificateDialog: {
      title: 'New root CA certificate',
      content: 'The root CA certificate has been replaced. Please download it again and install.',
      dontShowAgain: 'Do not show again',
    },
    request_tip: {
      error: 'request Error~',
    },
    header: {
      logout: 'Logout',
      changePassword: 'Change Password',
      userManual: 'User Manual',
      defaultAvatar: 'Default Avatar',
      'english': 'English',
      'german': 'German',
      'korean': 'Korean',
      'french': 'French',
      'spain': 'Spanish',
      'portugal': 'Portuguese',
      'japanese': 'Japanese',
      'Tchinese': 'Traditional Chinese',
      'russian': 'Russian',
      'hindi': 'Hindi',
      'simplechinese': 'Simplified Chinese',
    },
    home: {
      name: 'Dashboard',
      children: {},
      content: {
        tipInput: 'Please input ',
        ok: 'ok',
        cancel: 'cancel',
        currentStatus: 'Current status',
        statusMap: {
          Trial: 'Trial',
          Valid: 'Valid',
          Expired: 'Expired',
        },
        btnActive: 'Activate',
        btnEdit: 'Modify',
        expireDate: 'Expiration date',
        licenseKey: 'License Key',
      },
    },
    accessControl: {
      name: 'Access Control',
    },
    tpProxy: {
      name: 'Transparent Proxy',
      children: {
        customRules: {
          name: 'Custom Rules',
        },
        builtinRules: {
          name: 'Ads Filter',
        },
        certificate: {
          name: 'Install Certificate',
        },
      },
    },
    setting: {
      name: 'Setting',
      children: {
        wifi: {
          name: 'Wi-Fi',
        },
        sharing: {
          name: 'Sharing',
          children: {
            configure: {
              name: 'Sharing Config',
            },
            blacklist: {
              name: 'Sharing Blacklist',
            },
            record: {
              name: 'Sharing Log',
            },
            traffic: {
              name: 'Sharing Traffic',
            },
          },
          content: {},
        },
        rustdesk: {
          name: 'RustDesk',
          children: {
            config: {
              name: 'Config',
            },
            log: {
              name: 'Log',
            },
          },
        },
        admin: {
          name: 'Administration',
          content: {},
          children: {
            changePassword: {
              name: 'Password',
            },
            reboot: {
              name: 'Reboot',
              content: {
                confirmText: 'Confirm to reboot?',
                rebootLoading:
                  'Your device will need a few minutes to reboot, during which your network connection will be temporarily interrupted.',
              },
            },
          },
        },
      },
    },
    help: {
      name: 'Help',
      children: {
        techSupport: {
          name: 'Tech Support',
        },
      },
    },
    device: {
      name: 'Device',
      children: {
        system: {
          name: 'System',
        },
        traffic: {
          name: 'Traffic',
        },
        notification: {
          name: 'Log',
        },
      },
      content: {},
    },
    dpn: {
      name: 'DPN',
      content: {},
      children: {
        smartRoute: {
          name: 'Mode',
        },
        tunnel: {
          name: 'Tunnel',
        },
        routingTable: {
          name: 'Smart Routing',
          content: {},
          children: {
            domainConfig: {
              name: 'Domain Config',
            },
            ipConfig: {
              name: 'IP Config',
            },
            queryRouting: {
              name: 'Query',
            },
          },
        },
      },
    },
    appRelocator: {
      name: 'App Relocator',
    },
    adsBlock: {
      name: 'Ads Blocker',
      children: {
        domainFilter: {
          name: 'DNS Filter',
        },
        httpsFilter: {
          name: 'HTTPS Filter',
        },
      },
      httpssucceed: 'HTTPS filtering has been successfully enabled',
      httpslose: 'HTTPS filtering has been successfully disabled',
      saverror: 'Operation Failed',
      usedByAccessControl: 'HTTPS filter is used by Access Control',
    },
    wallets: {
      name: 'Wallets',
      children: {
        wallets: 'Wallets',
        keys: 'Keys',
        funds: 'Funds',
      },
      content: {},
    },
    dpr: {
      name: 'Mining',
      children: {
        dpr: {
          name: 'DPR',
          children: {
            stakingTutorial: {
              name: 'Staking Tutorial',
            },
            wallet: {
              name: 'Wallet',
              manager: 'Wallet Manager',
              balance: 'Wallet Balance',
            },
            'Staking Mining': 'Staking Mining',
            transaction: {
              id: 'transaction ID',
              from: 'from',
              to: 'to',
              time: 'time (UTC)',
              rewardTime: 'reward time (UTC)',
              amount: 'DPR amount',
              'Reward in selected period': 'Reward in selected period',
              'Total reward': 'Total reward',
              'Balance Transfer': 'Balance Transfer',
              'Staking Reward': 'Staking Reward',
              'PoS Reward': 'PoS Reward',
              'Micro Payment': 'Micropayments',
              stakeRelease: 'Stake Release',
              Transaction: 'Transactions',
              Mining: 'Mining',
              'Traffic Mining': 'Traffic Mining',
              'Staking Mining': 'Staking Mining',
            },
          },
        },
      },
    },
    security: {
      name: 'Security',
      children: {
        applications: 'Apps Control',
        traffic: 'Traffic Shaping',
        webfilter: {
          name: 'Web Filter',
          children: {
            report: {
              name: 'Dashboard',
            },
            config: {
              name: 'Customized DNS rules',
            },
          },
        },
        sslFilter: {
          name: 'SSL Filter',
          children: {
            customDNSrules: {
              name: 'Customized DNS rules',
            },
            customHTTPSRules: {
              name: 'Customized HTTPS rules',
            },
          },
        },
      },
      content: {},
    },
    parentalControl: {
      name: 'Parental Control',
    },
    regionCountryTexts: {
      'LL': 'Direct Access',
      'ANY': 'Any Country',
      // regions
      'AMER': '---America---',
      'ASIA': '---Asia---',
      'AFRI': '---Africa---',
      'EURO': '---Europe---',
      'OCEA': '---Oceania---',
      'AMN': 'North America',
      'AMC': 'the Caribbean',
      'AMM': 'Central America',
      'AMS': 'South America',
      'ASC': 'Central Asia',
      'ASE': 'East Asia',
      'ASW': 'West Asia',
      'ASS': 'South Asia',
      'ASD': 'Southeast Asia',
      'AFN': 'North Africa',
      'AFM': 'Middle Africa',
      'AFE': 'East Africa',
      'AFW': 'West Africa',
      'AFS': 'South Africa',
      'EUN': 'North Europe',
      'EUE': 'East Europe',
      'EUW': 'West Europe',
      'EUS': 'South Europe',
      'OCP': 'Polynesia',
      'OCA': 'Australia & New Zealand',
      'OCM': 'Melanesia',
      'OCN': 'Micronesia',
      // countries
      'AD': 'Andorra',
      'AE': 'United Arab Emirates',
      'AF': 'Afghanistan',
      'AG': 'Antigua and Barbuda',
      'AI': 'Anguilla',
      'AL': 'Albania',
      'AM': 'Armenia',
      'AO': 'Angola',
      'AR': 'Argentina',
      'AS': 'American Samoa',
      'AT': 'Austria',
      'AU': 'Australia',
      'AW': 'Aruba',
      'AX': 'Åland Islands',
      'AZ': 'Azerbaijan',
      'BA': 'Bosnia and Herzegovina',
      'BB': 'Barbados',
      'BD': 'Bangladesh',
      'BE': 'Belgium',
      'BF': 'Burkina Faso',
      'BG': 'Bulgaria',
      'BH': 'Bahrain',
      'BI': 'Burundi',
      'BJ': 'Benin',
      'BL': 'Saint Barthélemy',
      'BM': 'Bermuda',
      'BN': 'Brunei Darussalam',
      'BO': 'Bolivia',
      'BQ': 'Bonaire, Sint Eustatius and Saba',
      'BR': 'Brazil',
      'BS': 'Bahamas',
      'BT': 'Bhutan',
      'BW': 'Botswana',
      'BY': 'Belarus',
      'BZ': 'Belize',
      'CA': 'Canada',
      'CC': 'Cocos (Keeling) Islands',
      'CD': 'Congo, Democratic Republic of the',
      'CF': 'Central African Republic',
      'CG': 'Congo',
      'CH': 'Switzerland',
      'CI': "Côte d'Ivoire",
      'CK': 'Cook Islands',
      'CL': 'Chile',
      'CM': 'Cameroon',
      'CN': 'China',
      'CO': 'Colombia',
      'CR': 'Costa Rica',
      'CU': 'Cuba',
      'CV': 'Cabo Verde',
      'CW': 'Curaçao',
      'CX': 'Christmas Island',
      'CY': 'Cyprus',
      'CZ': 'Czechia',
      'DE': 'Germany',
      'DJ': 'Djibouti',
      'DK': 'Denmark',
      'DM': 'Dominica',
      'DO': 'Dominican Republic',
      'DZ': 'Algeria',
      'EC': 'Ecuador',
      'EE': 'Estonia',
      'EG': 'Egypt',
      'ER': 'Eritrea',
      'ES': 'Spain',
      'ET': 'Ethiopia',
      'FI': 'Finland',
      'FJ': 'Fiji',
      'FK': 'Falkland Islands (Malvinas)',
      'FM': 'Micronesia (Federated States of)',
      'FO': 'Faroe Islands',
      'FR': 'France',
      'GA': 'Gabon',
      'GB': 'United Kingdom of Great Britain and Northern Ireland',
      'GD': 'Grenada',
      'GE': 'Georgia',
      'GF': 'French Guiana',
      'GG': 'Guernsey',
      'GH': 'Ghana',
      'GI': 'Gibraltar',
      'GL': 'Greenland',
      'GM': 'Gambia',
      'GN': 'Guinea',
      'GP': 'Guadeloupe',
      'GQ': 'Equatorial Guinea',
      'GR': 'Greece',
      'GS': 'South Georgia and the South Sandwich Islands',
      'GT': 'Guatemala',
      'GU': 'Guam',
      'GW': 'Guinea-Bissau',
      'GY': 'Guyana',
      'HK': 'Hong Kong (China)',
      'HN': 'Honduras',
      'HR': 'Croatia',
      'HT': 'Haiti',
      'HU': 'Hungary',
      'ID': 'Indonesia',
      'IE': 'Ireland',
      'IL': 'Israel',
      'IM': 'Isle of Man',
      'IN': 'India',
      'IO': 'British Indian Ocean Territory',
      'IQ': 'Iraq',
      'IR': 'Iran',
      'IS': 'Iceland',
      'IT': 'Italy',
      'JE': 'Jersey',
      'JM': 'Jamaica',
      'JO': 'Jordan',
      'JP': 'Japan',
      'KE': 'Kenya',
      'KG': 'Kyrgyzstan',
      'KH': 'Cambodia',
      'KI': 'Kiribati',
      'KM': 'Comoros',
      'KN': 'Saint Kitts and Nevis',
      'KR': 'South Korea',
      'KW': 'Kuwait',
      'KY': 'Cayman Islands',
      'KZ': 'Kazakhstan',
      'KP': 'North korea',
      'LA': "Lao People's Democratic Republic",
      'LB': 'Lebanon',
      'LC': 'Saint Lucia',
      'LI': 'Liechtenstein',
      'LK': 'Sri Lanka',
      'LR': 'Liberia',
      'LS': 'Lesotho',
      'LT': 'Lithuania',
      'LU': 'Luxembourg',
      'LV': 'Latvia',
      'LY': 'Libya',
      'MA': 'Morocco',
      'MC': 'Monaco',
      'MD': 'Moldova, Republic of',
      'ME': 'Montenegro',
      'MF': 'Saint Martin (French part)',
      'MG': 'Madagascar',
      'MH': 'Marshall Islands',
      'MK': 'North Macedonia',
      'ML': 'Mali',
      'MM': 'Myanmar',
      'MN': 'Mongolia',
      'MO': 'Macao (China)',
      'MP': 'Northern Mariana Islands',
      'MQ': 'Martinique',
      'MR': 'Mauritania',
      'MS': 'Montserrat',
      'MT': 'Malta',
      'MU': 'Mauritius',
      'MV': 'Maldives',
      'MW': 'Malawi',
      'MX': 'Mexico',
      'MY': 'Malaysia',
      'MZ': 'Mozambique',
      'NA': 'Namibia',
      'NC': 'New Caledonia',
      'NE': 'Niger',
      'NF': 'Norfolk Island',
      'NG': 'Nigeria',
      'NI': 'Nicaragua',
      'NL': 'Netherlands',
      'NO': 'Norway',
      'NP': 'Nepal',
      'NR': 'Nauru',
      'NU': 'Niue',
      'NZ': 'New Zealand',
      'OM': 'Oman',
      'PA': 'Panama',
      'PE': 'Peru',
      'PF': 'French Polynesia',
      'PG': 'Papua New Guinea',
      'PH': 'Philippines',
      'PK': 'Pakistan',
      'PL': 'Poland',
      'PM': 'Saint Pierre and Miquelon',
      'PN': 'Pitcairn',
      'PR': 'Puerto Rico',
      'PS': 'Palestine, State of',
      'PT': 'Portugal',
      'PW': 'Palau',
      'PY': 'Paraguay',
      'QA': 'Qatar',
      'RE': 'Réunion',
      'RO': 'Romania',
      'RS': 'Serbia',
      'RU': 'Russian Federation',
      'RW': 'Rwanda',
      'SA': 'Saudi Arabia',
      'SB': 'Solomon Islands',
      'SC': 'Seychelles',
      'SD': 'Sudan',
      'SE': 'Sweden',
      'SG': 'Singapore',
      'SH': 'Saint Helena, Ascension and Tristan da Cunha',
      'SI': 'Slovenia',
      'SJ': 'Svalbard and Jan Mayen',
      'SK': 'Slovakia',
      'SL': 'Sierra Leone',
      'SM': 'San Marino',
      'SN': 'Senegal',
      'SO': 'Somalia',
      'SR': 'Suriname',
      'SS': 'South Sudan',
      'ST': 'Sao Tome and Principe',
      'SV': 'El Salvador',
      'SX': 'Sint Maarten (Dutch part)',
      'SY': 'Syrian Arab Republic',
      'SZ': 'Eswatini',
      'TC': 'Turks and Caicos Islands',
      'TD': 'Chad',
      'TF': 'French Southern Territories',
      'TG': 'Togo',
      'TH': 'Thailand',
      'TJ': 'Tajikistan',
      'TK': 'Togo',
      'TL': 'Timor-Leste',
      'TM': 'Turkmenistan',
      'TN': 'Tunisia',
      'TO': 'Tonga',
      'TR': 'Turkey',
      'TT': 'Trinidad and Tobago',
      'TV': 'Tuvalu',
      'TW': 'Taiwan (China)',
      'TZ': 'Tanzania, United Republic of',
      'UA': 'Ukraine',
      'UB': 'US West',
      'UC': 'US Midwest',
      'UD': 'US Southwest',
      'UE': 'US Northeast',
      'UF': 'US Southeast',
      'UG': 'Uganda',
      'US': 'United States of America',
      'UY': 'Uruguay',
      'UZ': 'Uzbekistan',
      'VA': 'Holy See',
      'VC': 'Saint Vincent and the Grenadines',
      'VE': 'Venezuela',
      'VG': 'Virgin Islands (British)',
      'VI': 'Virgin Islands (U.S.)',
      'VN': 'Vietnam',
      'VU': 'Vanuatu',
      'WF': 'Wallis and Futuna',
      'WS': 'Samoa',
      'XK': 'Kosovo',
      'YE': 'Yemen',
      'YT': 'Mayotte',
      'ZA': 'South Africa',
      'ZM': 'Zambia',
      'ZW': 'Zimbabwe',
    },
  },

  // dashboard
  dashboard: {
    name: 'Dashboard',
    children: {},
    content: {
      backupTunnelLabel: 'Backup Tunnel',
      tipInput: 'Please input ',
      hotspot: 'Global Connection',
      realnetworkflow: 'Real-time traffic statistics',
      memory: 'Memory',
      cpu: 'CPU',
      notification: 'Notifications',
      systeminfo: 'System Info',
      upload: 'Upload',
      download: 'Download',
      systemuptime: 'System Up-time',
      uploadtotal: 'Total of Uploads',
      downloadtotal: 'Total of Downloads',
      softwareVersion: 'Software Version',
      publicIp: 'Public IP',
      unit: 'Unit',
      currentDpnMode: 'Current DPN mode',
      ok: 'ok',
      cancel: 'cancel',
      currentStatus: 'Current status',
      statusMap: {
        Trial: 'Trial',
        Valid: 'Valid',
        Expired: 'Expired',
      },
      btnActive: 'Activate',
      btnEdit: 'Modify',
      expireDate: 'Expiration date',
      licenseKey: 'License Key',
      systemTraffic: 'Real-time System Traffic Statistics',
      appTraffic: 'Real-time Application Traffic Statistic',
    },
  },
  accessControl: {
    content: {
      banner: {
        title: 'Access Control',
        description:
          'This switch is used to uniformly control the enabling or disabling of various functions of the LAN device.',
      },
      onlineDevice: 'Online Device',
      offlineDevice: 'Offline Device',
      deviceName: 'Device Name',
      unknownDevice: 'Unknown',
      macAddress: 'MAC Address',
      ipAddress: 'IP Address',
      enableHttpsFilter: 'Enable HTTPS Filter',
      routeMode: 'DPN Mode',
      routeModeOptions: {
        smart: 'Smart Routing Mode',
        full: 'Full Routing',
        disabled: 'Disabled',
        blackHole: 'Black Hole',
      },
      remark: 'Remark',
      pinned: 'Pin to Top',
      httpsFilterTip: 'After enabling HTTPS filtering, all ads will be filtered',
      routeModeTip: 'This option can switch the operating mode of DPN',
      tunnelTip: 'Please select full routing tunnel',
      success: {
        getList: 'Device list refreshed successfully',
        httpsFilter: 'HTTPS filter switched successfully',
        routeMode: 'DPN mode switched successfully',
        regionCode: 'DPN mode switched successfully',
        remark: 'Successfully saved remark',
        pinned: 'Pinned / Unpinned successfully. Please refresh',
        deleteDevices: 'Device deletion successful',
      },
      failure: {
        getSwitch: 'Failed to get Access Control switch',
        setSwitch: 'Failed to set Access Control switch',
        getList: 'Failed to get Access Control list',
        getTunnels: 'Failed to get tunnels',
        setItem: 'Failed to set Access Control item',
        httpsFilter: 'Failed to switch HTTPS filter',
        routeMode: 'Failed to switch DPN mode',
        regionCode: 'Failed to switch DPN mode',
        remark: 'Failed to save remark',
        noSuchTunnel: 'The tunnel does not exist, please choose another tunnel',
        pinned: 'Failed to pin / unpin',
        deleteDevices: 'Failed to delete device',
        macActive: 'Failed to delete since device is active',
      },
      remarkLengthLimit: 'Length not more than 10 characters',
      httpsNotEnabled:
        'The main switch for HTTPS filtering is turned off, please click to jump to this page to turn it on!',
      tips: {
        opened: 'When access control is turned on, online devices preferentially use the DPN mode in access control.',
      },
    },
  },
  //dpnMode
  dpnMode: {
    title: 'Select DPN mode',
    selectTunnel: 'Select tunnel',
    recommended: 'Recommended',
    apply: 'Apply',
    missingInfo: 'Missing info',
    selectionTip: {
      smart: 'Do you want to switch to Smart route mode?',
      full: 'All traffic will go through the selected tunnel. Do you want to switch to Full Route mode?',
      disabled: 'No traffic will go through DPN. Do you want to switch to DPN disabled mode?',
    },
    dpnModeShortList: {
      smart: 'DPN smart route',
      disabled: 'DPN disabled',
    },
    dpnModeLongList: {
      smart: 'Smart Route',
      full: 'Full Route',
      disabled: 'Disabled',
    },
    killSwitchLabel: 'Kill Switch',
    none: 'Disable',
    backupTunnelLabel: 'Backup tunnel',
    backupTunnelTip:
      'When a problem occurs with the tunnel configured in smart routing mode, the system will automatically switch to the backup tunnel until the default tunnel resumes normal operation.',
    tips: {
      killSwitch:
        'A VPN kill switch is a feature that automatically disconnects your computer, phone, or tablet from the internet if your VPN connection drops out. This means there’s no chance that your IP address and other sensitive information could be exposed if your VPN stops working. So the security and anonymity of your internet connection won’t be compromised.',
      smart: [
        '1. According to the built-in rules in AtomOS, intelligent matching nodes are assigned to the currently accessed websites, ensuring local network speed while accelerating access to services in other countries and regions.',
        '2. In the "DPN smart route" mode, not only does it eliminate the hassle of constantly switching countries like traditional VPNs, but it also provides users with a faster network browsing experience.',
        '3. Deeper Connect has built-in routing rules for some well-known websites and services. If you want to customize the rules for smart routing, you can configure them in the "App Relocator" and "DPN - Routing".',
        '4. We will continue to enhance our built-in routing rules based on user feedback and expand support for more applications in the "App Relocator" feature.',
      ],
      full: [
        '1. All network data processed through AtomOS will be routed through a specified tunnel, similar to the usage pattern of traditional VPNs.',
        '2. Note: Adopting the "DPN full route" mode will override the configurations you have made in "App Relocator" and "DPN - Routing".',
        '3. Due to the decentralized nature of the network, the nodes in the network come from user devices around the world, rather than high-speed servers. As a result, your internet speed may be affected.',
        '4. If you insist on using the "DPN full route" mode and desire a high-speed internet experience, we will consider providing specific high-speed nodes for VIP users in the future.',
      ],
      disabled: [
        '1. The "DPN disabled" mode will turn off the DPN feature, causing all network data processed through AtomOS to use local traffic.',
        '2. Other network security features of Deeper Connect will remain enabled.',
        '3. If you encounter obstacles when accessing certain websites, you can disable DPN to determine whether the issue is with the website or the DPN feature.',
        '4. If you encounter any issues with the DPN feature, please feel free to report them using the following link: https://support.deeper.network/hc/en-us/requests/new',
      ],
    },
    success: 'Operation Successful',
    failure: 'Operation Failed!',
  },
  // DefaultadBlock
  DefaultadBlock: {
    browserCacheWarning:
      'If ads cannot be properly filtered when DNS ad filtering is enabled, you need to clear the browser cache and restart the filtered device.',
    queriesFor24Hours: 'Queries over last 24 hours',
    totalQuery: 'Total Queries',
    blockedQuery: 'Blocked Queries',
    blockedPercentage: 'Percentage of blocked',
    ruleTotal: 'Rules',
    adQuery: 'Blocked Ads',
    trackerQuery: 'Blocked Trackers',
    maliciousQuery: 'Blocked Malicious',
    pornQuery: 'Blocked Porn',
    legendTotal: 'Total',
    legendBlocked: 'Blocked',
    categoryStateEnabled: 'on',
    categoryStateDisabled: 'off',
    categoryState2Hours: 'disabled for 2 hours',
    categoryState4Hours: 'disabled for 4 hours',
    categoryState8Hours: 'disabled for 8 hours',
    setCategoryStatesErr: 'Error enabling/disabling the filter',
    enableAll: 'Enable all filters',
    disableAll: 'Disable all filters',
  },
  // CustomadBlock
  CustomadBlock: {
    missingInfoWarning: 'Domain is missing',
    invalidDomainError: 'Domain format is invalid or domain is too long',
    whitelist: 'Whitelist',
    blacklist: 'Blacklist',
    domain: 'Domain',
    whitelistAddSuccess: 'Added to whitelist successfully',
    whitelistEntryExist: 'Entry already in whitelist',
    reachedWhiteMax: 'Reached whitelist maximum',
    whitelistAddError: 'Failed to add to whitelist',
    blacklistAddSuccess: 'Added to blacklist successfully',
    blacklistEntryExist: 'Entry already in blacklist',
    reachedBlackMax: 'Reached blacklist maximum',
    blacklistAddError: 'Failed to add to blacklist',
    whitelistDelSuccess: 'Whitelist entries deleted successfully',
    whitelistDelError: 'Failed to delete whitelist entries',
    blacklistDelSuccess: 'Blacklist entries deleted successfully',
    blacklistDelError: 'Failed to delete blacklist entries',
    import: 'Import',
    fileButtonText: 'Click to Upload',
    fileSizeOverLimit: 'Uploaded file exceeds limit of 2MB',
    whitelistImportSuccess: 'Whitelist imported successfully, {0} entries imported',
    whitelistImportError: 'Failed to import whitelist',
    blacklistImportSuccess: 'blacklist imported successfully, {0} entries imported',
    blacklistImportError: 'Failed to import blacklist',
    noValidEntry: 'There is no valid domain entry in the uploaded file',
    zeroImported: 'Failed to import any domain entry',
  },
  // DohGuideComp
  DohGuideComp: {
    closeButton: 'Close',
    guideButton: 'View',
    guideBoxTitle: 'DoH Browser Setting',
    dohMessageClickTips: 'Click on "View" to see instructions on how to turn off DoH for your particular browser.',
    dohMessageTxt: `To utilize this feature, {0}. DoH encrypts DNS requests and may cause interference with certain functionalities. `,
    dohMessage0: 'please disable the DoH setting in your browser',
    guide: {
      chromeGuideImg: '/dohGuide/chrome-guide-en.png',
      chrome: [
        {
          label: 'Step 1',
          tip: 'Type chrome://settings/security in the address bar and press Enter.',
        },
        { label: 'Step 2', tip: 'Locate the "Advanced security settings" section.' },
        { label: 'Step 3', tip: 'Disable the "Use secure DNS" option.' },
      ],
      firefox: [
        {
          label: 'Step 1',
          tip: 'Type about:config in the address bar and press Enter.',
        },
        { label: 'Step 2', tip: 'If a warning page appears, click "Accept the Risk and Continue".' },
        { label: 'Step 3', tip: 'Type network.trr.mode in the search box and press Enter.' },
        {
          label: 'Step 4',
          tip: 'Double-click the search result, change the value to 5 (disable DoH), then click "OK".',
        },
      ],
      edge: [
        {
          label: 'Step 1',
          tip: 'Type edge://settings/privacy in the address bar and press Enter. Then find the "Security" section.',
        },
        {
          label: 'Step 2',
          tip: 'Turn off the "Use secure DNS to protect against malicious activity and deception" option.',
        },
      ],
      operaGuideImg: '/dohGuide/opera-guide-en.png',
      opera: [
        {
          label: 'Step 1',
          tip: 'Enter opera://settings/?search=DNS in the browser address bar and press Enter.',
        },
        {
          label: 'Step 2',
          tip:
            'Find the "Use DNS-over-HTTPS instead of the system\'s DNS settings" option and make sure it is turned off.',
        },
      ],
    },
  },
  // appRelocatorData
  appRelocatorData: {
    guide: {
      youtube: {
        UA: 'https://www.deeper.network/pages/app-relocator?app=youtube&lang=en',
      },
      nbaLeaguePass: 'https://www.deeper.network/pages/app-relocator?app=nbaLeaguePass&lang=en',
      netflix: 'https://www.deeper.network/pages/app-relocator?app=netflix&lang=en',
      dazn: 'https://www.deeper.network/pages/app-relocator?app=dazn&lang=en',
      fubo: 'https://www.deeper.network/pages/app-relocator?app=fubo&lang=en',
      tencentVideo: 'https://www.deeper.network/pages/app-relocator?app=tencentVideo&lang=en',
    },
    countryLabel: 'Country',
    tagLabel: 'Tag',
    typeLabel: 'Type',
    country: {
      'all-countries': 'All World',
      CN: 'CN',
      US: 'US',
      IN: 'IN',
      CA: 'CA',
      GB: 'GB',
      NA: 'Others',
      NO: 'NO',
      SG: 'SG',
      JP: 'JP',
    },
    tag: {
      'all-tags': 'All Tags',
      nba: 'NBA',
      nfl: 'NFL',
      nhl: 'NHL',
      mlb: 'MLB',
      epl: 'EPL',
      ipl: 'IPL',
    },
    category: {
      'all-category': 'All Types',
      video: 'Video',
      music: 'Music',
      im: 'IM',
      flightAndHotel: 'Flight & Hotel',
      subscription: 'Subscription',
    },
    subcategory: {
      popular: 'Popular',
      sport: 'Sport',
      movie: 'Movie',
      tv: 'TV',
    },
    filter: 'Filter',
    reset: 'Reset',
    submit: 'Submit',
    app: {
      acornTv: 'Acorn TV',
      amazonPrimeVideo: 'Amazon Prime Video',
      beInSports: 'beIN Sports',
      crunchyroll: 'Crunchyroll',
      dazn: 'DAZN',
      disneyPlus: 'Disney+',
      netflix: 'Netflix',
      plex: 'Plex',
      roku: 'Roku',
      shudder: 'Shudder',
      zattoo: 'Zattoo',
      discoveryPlus: 'Discovery Plus',
      paramountPlus: 'Paramount +',
      sportsnetNow: 'Sportsnet NOW',
      tsn: 'TSN',
      bbcIPlayer: 'BBC iPlayer',
      hulu: 'Hulu',
      itv: 'ITVX',
      slingTv: 'Sling',
      crackle: 'Crackle',
      pandora: 'Pandora',
      theCw: 'The CW',
      abc: 'ABC',
      nbc: 'NBC',
      pluto: 'Pluto TV',
      starz: 'Starz',
      tbs: 'TBS',
      attTv: 'AT&T TV',
      channel4: 'Channel 4',
      showtimeAnytime: 'Showtime',
      nbcSports: 'NBC Sports',
      epixNow: 'Epix Now',
      mgmPlus: 'MGM Plus',
      xumo: 'Xumo',
      channel5: 'Channel 5',
      usaNetwork: 'USANetwork',
      fxNow: 'FX Now',
      vudu: 'Vudu',
      fubo: 'Fubo TV',
      espn: 'ESPN',
      tubiTv: 'Tubi',
      ukTvPlay: 'UKTV Play',
      tvPlayer: 'TVPlayer',
      peacock: 'Peacock',
      pbs: 'PBS',
      philo: 'Philo',
      vrv: 'VRV',
      showmax: 'Showmax',
      hboMax: 'HBO Max',
      nhl: 'NHL',
      dsTv: 'DStv',
      sSportsPlus: 'S Sports +',
      filmrise: 'FilmRise',
      cbsSports: 'CBS Sports',
      nowTV: 'Now TV',
      molotovTv: 'Molotov TV',
      mlb: 'MLB',
      britBox: 'BritBox',
      stirr: 'Stirr',
      skyGo: 'Sky Go',
      hotstar: 'Disney+ Hotstar',
      sonyLiv: 'SonyLIV',
      nbaLeaguePass: 'NBA League Pass',
      amcPlus: 'AMC+',
      f1Tv: 'F1TV',
      frndlyTv: 'Frndly TV',
      myTf1: 'MYTF1',
      qqMusic: 'QQ Music',
      spotify: 'Spotify',
      tidal: 'Tidal',
      youtube: 'YouTube',
      youTubeMusic: 'YouTube Music',
      weChat: 'WeChat',
      whatsApp: 'WhatsApp',
      line: 'Line',
      signal: 'Signal',
      agoda: 'Agoda',
      expedia: 'Expedia',
      flyScanner: 'Fly Scanner',
      googleFlights: 'Google Flights',
      qqSports: 'QQ Sports',
      skySports: 'Sky Sports',
      rakutenTv: 'Rakuten TV',
      fox: 'FOX',
      nflNetwork: 'NFL Network',
      peacocktv: 'Peacock TV',
      zee5: 'Zee5',
      mxPlayer: 'MX Player',
      tencentVideo: 'Tencent Video',
      iqiyi: 'IQIYI Inc - ADR',
      mangoTV: 'Mango TV',
      migu: 'Migu',
      bilibili: 'BiliBili',
      youku: 'YouKu',
      altBalaji: 'ALT Balaji',
      willowTv: 'Willow TV',
      foxSports: 'Fox Sports',
      crave: 'Crave',
      cbcGem: 'CBC Gem',
      jiocinema: 'JioCinema',
      Telegram: 'Telegram',
      Messenger: 'Messenger',
      ChatGPT: 'ChatGPT',
      CanalPlus: 'CanalPlus',
    },
    tip: {
      video: 'Popular tunnel for current video service',
      music: 'Popular tunnel for current music service',
      im: 'Popular tunnle for current IM',
      flightAndHotel: 'Popular tunnel for current flight & hotel service',
      subscription: 'Popular tunnel for current subscription',
      backupTunnel:
        'In Smart Routing mode, if there are no available nodes in the current tunnel, the system will switch to the backup tunnel located in the {0}',
      fullTunnel: 'In Full Routing mode, all applications will default to using the {0} tunnel.',
      noTunnelNodes:
        'Node count is zero, cannot be used, please go to the tunnel page and refresh the nodes for the {0} tunnel.',
      noTunnelNodesOkText: 'Refresh',
      noTunnelNodesCancelText: 'Close',
      noTunnelAdded:
        "You haven't added this tunnel yet. To use it, please go to the tunnel page and add the {0} tunnel.",
      noTunnelAddedOkText: 'Add',
      noTunnelAddedCancelText: 'Cancel',
      tunnelDeletedText: '{0} - {1} tunnel has been deleted',
      netflix: 'Solving playback error issues you encounter while using Netflix',
      fubo: 'Fubo TV allows you to watch Premier League live streams at a very low cost.',
      tencentVideo: 'Watching an NBA game costs about $1.',
      dazn: 'DAZN allows you to watch NFL, NFL, MLB, NBA, EPL at a very low cost, only $17.18.',
      youtube: 'How to enjoy YouTube Premium at a very low cost.',
    },
    intro: {
      acornTv:
        'Acorn TV streams world-class mysteries, dramas, and comedies from Britain and beyond. Binge-watch a classic series or discover your new favorite show among …',
      abc: 'Watch the ABC Shows online at abc.com. Get exclusive videos and free episodes.',
      f1Tv:
        'F1 TV is now available on Web, Apps, Google TV, Apple TV, Roku and Amazon Fire TV. ALL F1 LIVE. ALL F1 LIVE. Live stream all track sessions from the GP …',
      youtube:
        "YouTube's Official Channel helps you discover what's new & trending globally. Watch must-see videos, from music to culture to Internet phenomena.",
      netflix:
        'Netflix is a streaming service that offers a wide variety of award-winning TV shows, movies, anime, documentaries, and more on thousands of internet-connected …',
      fubo:
        'The easiest way to watch Fubo on your TV is with a Roku, Apple TV, Amazon Fire TV or Chromecast. Simply install the Fubo app on your device to stream on …',
      disneyPlus:
        "From new releases, to your favorite classics, the past, present, and future are yours. Everything you expected, and everything you didn't. © 2023 Disney. Brands: Marvel, Pixar, Disney, National Geographic, Star Wars.",
      crunchyroll:
        'Explore the best in anime and manga streaming with Crunchyroll. Enjoy hit titles such as Demon Slayer, One Piece, Attack on Titan, and My Hero Academia …',
      hulu:
        "Watch TV shows and movies online. Stream TV episodes of Grey's Anatomy, This Is Us, Bob's Burgers, Brooklyn Nine-Nine, Empire, SNL, and popular movies on …",
      nbaLeaguePass:
        'A subscription to NBA League Pass* gives fans access to games, live and on-demand, and more for the entire season and offseason.',
      qqSports:
        'Tencent Sports is a well-known sports portal website in China, primarily providing the following sections: domestic football, international football, NBA, CBA…',
      skySports:
        'Watch the best live coverage of your favourite sports: Football, Golf, Rugby, ipl, F1, Boxing, NFL, NBA, plus the latest sports news, …',
      rakutenTv:
        'LIVE TV | Top 15 Channels for Free · FREE | Top Movies for Free · STORE | Hottest Movies to Buy or Rent · Genres · FREE | Recently Added for Free · FREE | Rakuten',
      espn:
        'Visit ESPN for live scores, highlights and sports news. Stream exclusive games on ESPN+ and play fantasy sports.',
      beInSports:
        'Unveil the world of sports with beIN SPORTS USA. Get exclusive highlights, latest news, stats, scores, and schedules for major football leagues and more.',
      dazn: "DAZN is the world's first truly dedicated live sports streaming ...",
      cbsSports:
        'Free 24-Hour Sports News Network. Watch CBS Sports HQ. Delivering everything that matters about the game. More highlights, top bets, and winning fantasy advice.',
      fox:
        'Watch FOX Sports and view live scores, odds, team news, player news, streams, videos, stats, standings & schedules covering NFL, MLB, NASCAR, WWE, NBA, NHL, …',
      nflNetwork:
        'Get NFL channel information, show updates & more! NFL Network is the only year-round network owned and operated by the NFL.',
      peacock:
        'Get Peacock and stream live sports, exclusive Originals, hit shows and blockbuster movies. Top hits, classic faves, LIVE action and so much more, streaming now on Peacock. Documentaries. Kids Movies & Shows. Peacock Channels 24/7. Hallmark Channel.',
      nbcSports: 'Stay up-to-date with the latest sports news and scores from NBC Sports.',
      sportsnetNow:
        "MLB Top Plays · MLBSportsnet VideoBlue Jays' Springer celebrates his birthday in a big way with leadoff oppo-taco dinger · MLBSportsnet VideoBraves' Acuna Jr.",
      tsn:
        'Visit TSN to get the latest sports news coverage, scores, highlights and commentary for the Australian Open, NFL playoffs, Super Bowl, NHL Trade Deadline',
      nhl: ' The official National Hockey League website including news, rosters, stats, schedules, teams, and video.',
      hotstar:
        "Disney+ Hotstar is India's largest premium streaming platform with more than 100000 hours of drama and movies in 17 languages, and coverage of every major …",
      espn:
        'Visit ESPN for live scores, highlights and sports news. Stream exclusive games on ESPN+ and play fantasy sports.',
      sonyLiv:
        'Top premium streaming platform where you can watch popular TV Shows, Movies, Sports, Web Series. Enjoy your favourite Live TV Channels online.',
      jiocinema:
        'Watch the latest movies, TV shows, Voot shows, live sports, and highlights online in Hindi, English, and other regional languages across genres, …',
      amazonPrimeVideo:
        'Stream movies, shows, award-winning Amazon Originals, premium channels, sports & more. Watch binge-worthy TV, blockbuster movies, hit shows and more with a free trial. 4K+HDR compatible content. Hit shows and movies. New Releases. Watch on any device.',
      zee5:
        'Your favourite Desi content on ZEE5™ at an exclusive 50% discount. Buy Now. Watch your favorite content on ZEE5™. Get 50% off on annual pack. Buy Now. 4000+ Blockbuster Movies. 1600+ Shows. 260+ Originals. Available in 18 languages.',
      mxPlayer:
        "MX Player is India's Most Premium OTT Service Where You Can Play The Latest Music Videos, Watch Old & New Movies, TV Shows, Comedy TV Shows, Trending Songs, …",
      tencentVideo:
        "Tencent Video is dedicated to creating China's leading online video media platform. With its rich content, ultimate viewing experience, convenient login methods, 24-hour seamless multi-platform application experience, and quick sharing features, it primarily caters to users online video streaming needs.",
      iqiyi:
        'iQIYI (iQIYI.COM) is a large-scale video streaming website that offers a vast collection of high-quality, high-definition online videos. It is a professional platform for streaming various types of content, including movies, TV shows, animations, variety shows, lifestyle programs, and more. iQIYI provides a diverse range of entertainment options to cater to different audience preferences.',
      youku:
        'Youku - Dedicated to Excellent Content - Watch a Vast Collection of High-Quality Licensed HD Videos Online.',
      mangoTV:
        'Mango TV - The Online Video Website Everyone is Watching - Watch Popular Variety Shows, Latest Movies, and TV Dramas Online.',
      migu:
        "Migu Video provides live streaming of major domestic and international sports events, including the Beijing Winter Olympics. It offers live broadcasts of CBA (Chinese Basketball Association), AFC Champions League, Chinese Super League, La Liga, Bundesliga, Serie A, UEFA Europa League, UEFA Champions League, as well as live coverage of women's volleyball, table tennis, figure skating, snooker, WWE, UFC, and more.",
      bilibili:
        "Bilibili (bilibili.com) is a renowned video danmaku website in China, offering timely anime releases, a vibrant ACG community, and creative content from talented content creators. It's a place where users can find joy and entertainment.",
      nbc:
        'Watch full episodes of current and classic NBC shows online. Plus find clips, previews, photos and exclusive online features on NBC.com.',
      foxSports:
        'Watch FOX Sports and view live scores, odds, team news, player news, streams, videos, stats, standings & schedules covering NFL, MLB, NASCAR, WWE, NBA, NHL...',
      nfl:
        'Get NFL channel information, show updates & more! NFL Network is the only year-round network owned and operated by the NFL.',
      willowTv:
        'You can watch Live streaming, highlights and replays on Willow once you are successfully logged in. Spectrum Optimum Fios Dish Optimum TV Xfinity. * Time Warner …',
      mlb: 'Coverage includes audio and video clips, interviews, statistics, schedules and exclusive stories.',
      disneyPlus:
        "From new releases, to your favorite classics, the past, present, and future are yours. Everything you expected, and everything you didn't. © 2023 Disney. Brands: Marvel, Pixar, Disney, National Geographic, Star Wars.",
      hboMax:
        'HBO Max is a stand-alone streaming platform that bundles all of HBO together with even more TV favorites, blockbuster movies, and new Max Originals for everyone …',
      amcPlus:
        'scape the Ordinary and Discover More Unique, Captivating Experiences on AMC+ Today. Exclusive Content, Gripping Movies, and Timeless Series - Find it All on AMC+ Epic Series. Premium Dramas. On Demand. Exclusive Content. Exclusive Movies. New Episodes. Ad Free.',
      attTv:
        'Get our best deals for TV and Internet, including offers for DIRECTV and home internet, including AT&T Fiber.',
      britBox:
        'Binge mystery, comedy, drama, docs, lifestyle and more, from the biggest streaming collection of British TV ever.',
      crackle:
        'On Crackle, watch Hollywood movies for free-uncut and unedited. From your favorite genres like Action, Horror, Sci-Fi, Crime and Comedy. Just press Play.',
      discoveryPlus:
        "Stream on your mobile device, tablet, computer, game console and connected TV. · There's something for everyone! · discovery+ is the streaming home of Food, Home, …",
      mgmPlus: 'Get MGM+ and get 1000s of movies and TV series, ad-free. Cancel anytime.',
      filmrise:
        "We embrace the nature of today's ever-changing industry, applying a multi-tiered strategy to deliver the best in film and television.",
      frndlyTv:
        'Watch 40+ live channels, plus On Demand, on your Roku, Amazon Fire TV or on the go with Frndly TV. Includes all 3 Hallmark Channels, A&E, …',
      fxNow: 'Watch FX original TV shows, movies and live TV on FX, FXX and FXM networks.',
      paramountPlus:
        'Stream thousands of episodes, live sports & exclusive originals – all in one place. Access hundreds of iconic movies, from award-winning classics to hit releases. Find new shows to binge. Hit reality series. Hit MTV shows. 30,000+ episodes. Breaking News. Hit CBS shows.',
      pbs:
        'POV Films Are Known For Their Unforgettable Storytelling And Their Timeliness. Watch Now! POV Is Television’s Longest-Running Showcase For Independent Documentaries. Learn More! Independent Nonfiction. Over 500 Films Presented. Unforgettable Stories.',
      philo:
        'Why pay more for TV? Philo offers live TV and on-demand content for just $25/month. Stream your favorite shows, movies, and more across all your devices.',
      plex:
        'Plex is a free streaming app for everyone, no credit cards or subscription costs or hidden fees. To keep our service free we include ads which monetize all of …',
      pluto:
        'Everything from the Latest Hit Movies to Full Seasons of Your Favorite Shows. 100s of Channels. Zeros of Dollars. All the Devices. Start Watching Now! All the Devices. Binge Watch. On Demand TV and Movies. 100s of Channels. Stream Now. Pay Never.',
      roku:
        'Roku devices make streaming TV easy. From players, smart TVs, & even smart home products that make your home feel secure, find the perfect Roku product ...',
      showtimeAnytime:
        'Try SHOWTIME free and stream original series, movies, sports, documentaries, and more. Plus, order pay-per-view fights - no subscription needed.',
      shudder:
        'With plans starting at just $4.99/mo, get all of Shudder plus hit series from The Walking Dead Universe, Anne Rice and more — all in one place. Try Shudder Free …',
      slingTv:
        'Sling TV is the first app-based TV service letting you stream live television and on-demand content over the internet. Watch live shows wherever you are, at …',
      starz:
        'STARZ brings diverse perspectives to life through bold storytelling. Sign-up to stream original series, movies, extras, and more—on-demand and ad-free.',
      stirr:
        '... & Entertainment. live and on-demand. STIRR City, National has been set as your local channel. Start watching. 00:05. 100+ channels, 5,000+ hours of tv and …',
      tbs:
        'In the Heights · This Week on Dynamite · MLB Postseason on tbs preview · S18 | E14 Multiverse of American Dadness.',
      theCw:
        'Official home of The CW Network, featuring All American, Superman & Lois, Walker, FBoy Island, premium streaming series, movies, sports and more.',
      tubiTv:
        'Tubi is the leading free, premium, on demand video streaming app. We have the largest library of content with over 50,000 movies and television shows, the best …',
      usaNetwork:
        'Watch full episodes of current and classic USA shows online. Plus find clips, previews, photos and exclusive online features on USANetwork.com.',
      vudu:
        'Rent, buy, and watch movies and TV shows with Vudu. Watch online or on your favorite connected device with the Vudu app. No subscription, free sign up.',
      xumo:
        'Xumo was formed to develop and offer a next-generation streaming platform for the entire entertainment industry. Learn about our mission and get engaged with ...',
      jiocinema:
        'Watch the latest movies, TV shows, Voot shows, live sports, and highlights online in Hindi, English, and other regional languages across genres, …',
      altBalaji:
        'Watch Latest Web Series, Originals & Movies in HD Online. Choose from Genres like Thriller, Action, Adult, Comedy, Family Drama & more in multiple languages ...',
      crave:
        'A curated collection of horror movie classics, terrifying TV series and Halloween episodes. ... The unexpected fame and societal pressures in the comedy world.',
      cbcGem:
        "Watch popular shows like Schitt's Creek, Luther, Kim's Convenience, plus Films and Live TV on any device.",
      bbcIPlayer:
        'Watch live BBC TV channels, enjoy TV programmes you missed and view exclusive content on BBC iPlayer.',
      channel4: 'Watch your favourite shows online, from Channel 4, E4 and Walter Presents.',
      channel5:
        'Welcome to My5, where you can catch up and stream box sets of your favourite shows from Channel 5, 5STAR, 5USA, 5SELECT, 5ACTION and more.',
      dsTv:
        'The best in television entertainment at your fingertips. Watch the latest sport, movies, series and reality shows and access the DStv TV guide.',
      molotovTv:
        'Molotov is complimentary. Feel free to append additional features. · Molotov symbolizes the future of television · The Spotify of TV · Can television be…',
      myTf1:
        'Regardez en direct TF1, TMC, TFX, TF1 Séries Films et LCI, plus de 50 chaînes thématiques en exclu, et plus de 10 000 heures de vidéos, uniquement sur MYTF1 ...',
      sSportsPlus:
        "Serie A'dan LaLiga'ya, Formula 1'den NBA'e, EuroLeague'den UFC'ye her ay 700 saatten fazla canlı yayın ve yüzlerce spor içeriği S Sport Plus ile elinizin …",
      showmax:
        'How much does a Showmax subscription cost? Showmax offers a variety of plans to suit different preferences and budgets, with prices starting at just R39 per ...',
      itv:
        "ITVX is the UK's freshest streaming service, with exclusive new shows, blockbuster films, live events and thousands of boxsets all in one place.",
      pandora:
        'Play the songs, albums, playlists and podcasts you love on the all-new Pandora. Sign up for a subscription plan to stream ad-free and on-demand.',
      qqMusic:
        'QQ Music is a web-based music service product launched by Tencent, offering a wide range of features including online music streaming, exclusive releases of new and popular songs, lyrics translation, mobile ringtone downloads, high-quality lossless music streaming, extensive lossless music library, legal music downloads, and personal space...',
      spotify: 'Spotify is a digital music service that gives you access to millions of songs.',
      tidal:
        'TIDAL is an artist-first, fan-centered music streaming platform that delivers over 100 million songs in HiFi sound quality to the global music community.',
      weChat: 'Available for all kinds of platforms; enjoy group chat; support voice,photo,video and text messages.',
      whatsApp:
        'Use WhatsApp Messenger to stay in touch with friends and family. WhatsApp is free and offers simple, secure, reliable messaging and calling, available on …',
      line:
        'New communication app which allows you to make FREE voice calls and send FREE messages whenever and wherever you are, 24 hours a day!',
      signal:
        "Share text, voice messages, photos, videos, GIFs and files for free. Signal uses your phone's data connection so you can avoid SMS and MMS fees.",
      agoda:
        'Book Now Pay Later, Cancel Free. 24 7 Live Customer Service In Your Language. Budget To Luxury, Hotels and Homes. Your Trusted Travel Partner for Rooms & Flights.',
      expedia:
        'Your one-stop travel site for your dream vacation. Bundle your stay with a car rental or flight and you can save more. Search our flexible options to match ...',
      flyScanner:
        "Get discounted flights starting from $19. Compare & Book in 1 search. It's quick and easy. Compare Cheap Flights, Hotels and Car Hire. Find thousands of deals and get the Best one!",
      nowTV:
        'How much does a NOW membership cost? A NOW membership lets you watch what you want, on your terms. You can join with Entertainment, Cinema, Sports and Hayu,.',
      tvPlayer:
        'TVPlayer is home to the most popular TV channels and premium quality on-demand programmes, exclusive to TVPlayer. Watch over 40 live channels and thousands ...',
      ukTvPlay:
        'UKTV Play is the on demand home of Dave, Drama, W & Yesterday channels. Watch live TV, stream box sets and catch up on your favourite shows.',
      zattoo:
        'Stream your favorite TV programs live and time-shifted on all devices. Best image quality in Full-HD. Better than cable TV. On Demand + Live TV content.',
      skyGo:
        'Stream live, on demand and catch up TV from your Sky package on your phone, tablet, laptop or computer, or Apple TV device, and switch between them easily.',
      Telegram:
        'Telegram aims to provide a secure, fast, and concise communication experience, and is known for its powerful encryption features and rich functions.',
      Messenger:
        'Messenger is an instant messaging application provided by Facebook, which allows users to communicate with others through text messages, voice calls, video calls, sending pictures, emojis, etc. Messenger was initially a feature of the Facebook website, later developed into a standalone mobile application, and provides more features and services.',
      ChatGPT:
        'ChatGPT is an artificial intelligence language model developed by OpenAI, based on the GPT (Generative Pre-trained Transformer) architecture. It is trained through large-scale natural language processing data and can understand and generate natural language text.',
      CanalPlus:
        'CANAL+ is a French pay-TV channel, one of the largest pay-TV networks in France, and one of the most important pay-TV service providers in Europe. The channel offers a variety of programs, including movies, sports, news, documentaries, TV dramas, etc.',
    },
    selectTunnel: 'Select tunnel',
    notSetLabel: 'Not Set',
    directAccessLabel: 'Direct Access',
    otherTunnelLabel: 'Other Tunnel',
    recommendedTunnelLabel: 'Recommended Tunnel',
    error: {
      getApps: 'Failed to get apps',
      getTunnels: 'Failed to get tunnel list',
      invalidApp: 'Invalid app',
      tunnelNotExist: 'Tunnel not exist',
      set: 'Failed to set tunnel',
      unset: 'Failed to unset tunnel',
    },
    success: {
      set: 'Set tunnel successfully',
      unset: 'Unset tunnel successfully',
    },
    searchInputPlaceholder: 'Look up the apps you need!',
    discountIntro: percent => `Save ${percent} on monthly subscription fees`,
    discountPercent: percent => `-${percent} ($)/month`,
    guideButtonLabel: 'Learn More',
  },
  // betanet
  betanet: {
    bindWalletSuccess: 'Wallet bound successfully',
    deviceBound: 'Device is currently bound to another wallet',
    walletTaken: 'Wallet is already taken by another device',
    deviceTypeIncompatible: 'Device is incompatible with wallet',
    invalidMnemonic: 'Invalid wallet mnemonic',
    invalidPrivateKey: 'Invalid wallet private key',
    bindWalletError: 'Failed to bind wallet',
    notBound: 'No wallet bound to the current user',
    unbindWalletSuccess: 'Wallet unbound successfully',
    noActiveBinding: 'Device not bound to the specified wallet',
    unbindWalletError: 'Failed to unbind wallet',
    walletManagement: 'Wallet Management',
    bindWallet: 'Bind Wallet',
    downloadPrivateKey: 'Download private key',
    downloadKeyError: 'Failed to download private key',
    unbindBtnText: 'Unbind wallet',
    unbindWarning:
      'Make sure you have downloaded the private key. Please type "I have downloaded the private key" and continue.',
    unbindWarningWords: 'I have downloaded the private key',
    generateWallet: 'Generate wallet',
    verifyMnemonic: 'Verify mnemonic',
    mnemonicMismatch: 'Mnemonic verification failed',
    bindBtn1Text: 'Bind wallet (mnemonic)',
    bindBtn2Text: 'Bind wallet (private key)',
    dprManagement: 'DPR Management',
    balanceLabel: 'DPR Balance',
    balance: 'Balance',
    creditLabel: 'Credit',
    operationLabel: 'Operations',
    amtOwingLabel: 'System paid for you',
    dailyConsumedLabel: 'Daily consumed traffic',
    dailySharedLabel: 'Daily shared traffic',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    publicKey: 'Public key',
    mnemonicInstruction: 'Please note down the mnemonic. You will need to verify in the next step.',
    enterMnemonic: 'Enter mnemonic',
    enterMnemonicTip: 'Select each word in the order it was presented to you.',
    resetMnemonic: 'reset',
    uploadPrivateKey: 'Upload private key',
    getWalletError: 'Failed to get wallet info',
    deviceWalletMismatch: 'Device and wallet mismatch',
    walletNotFound: 'Wallet not found',
    generateWalletError: 'Failed to generate wallet',
    missingMnemonic: 'Missing mnemonic',
    missingPrivateKeyFile: 'Missing private key file',
    privateKeyFileOversize: 'Private key file exceeds limit of 10KB',
    transferTitle: 'Transfer',
    transferHistoryTitle: 'Transfer History',
    transferTo: 'Recipient public key',
    transferAmt: 'Transfer amount (up to 6 decimal places)',
    invalidTo: 'Invalid recipient',
    invalidAmt: 'Invalid transfer amount',
    amtOutOfRange: 'Transfer amount must be positive and capped at 1 million',
    transferSuccess: 'Fund transferred successfully',
    senderRecipientSame: 'Sender cannnot be the same as recipient',
    recipientNotFound: 'Recipient not found',
    insufficientFund: 'Transfer failed due to insufficient fund',
    transferError: 'Failed to transfer fund',
    votingResNotRetrieved: 'Entrustment result has not been retrieved.',
    candidateSelected: 'The validator candidate you entrusted was selected.',
    candidateNotSelected: 'The validator candidate you entrusted was not selected.',
    votingResNotReady: 'Entrustment result is not available at the moment.',
    notVoted: 'You have not entrusted a validator candidate.',
    candidateNotFound: 'Validator candidate not found',
    votingResRetrievalErr: 'Failed to retrieve entrustment result',
    from: 'from',
    to: 'to',
    type: 'type',
    amount: 'amount',
    time: 'time',
    txnTypes: {
      101: 'initial reward',
      201: 'manual transfer',
      301: 'entrustment reward',
      401: 'micro payment',
    },
    walletAddress: 'Wallet Address',
    notInGenesisNodeList: 'The genesis node can only be bound to the genesis mining machine.',
    onChainStatusLabel: 'On-Chain Status',
    onChainStatusDesc: {
      unknown: '-',
      onChain: 'Yes',
      notOnChain: 'No',
    },
    pwMgmtSection: {
      title: 'Password Management',
      setBtnText: 'Set password',
      resetBtnText: 'Reset password',
    },
    passwordModal: {
      title: 'Set Wallet Password',
      onePlaceholder: 'Input password',
      twoPlaceholder: 'Repeat password',
      btnText: 'Confirm',
    },
    setPasswordMsg: {
      mismatch: 'Passwords do not match',
      weak: 'Password must be at least 8 characters long and contain lowercase, uppercase and numeric characters',
      alreadySet: 'Wallet password has already been set before',
      genericErr: 'Failed to set wallet password',
      success: 'Successfully set wallet password',
    },
    authModal: {
      title: 'Wallet Password',
      inputPlaceholder: 'Input wallet password',
      btnText: 'Continue',
    },
    passwordNotSet: 'Wallet password not set',
    passwordIncorrect: 'Wallet password incorrect',
    resetModal: {
      disclaimer:
        'Once the wallet password is reset, your device will be disconnected from any wallet it is currently bound to. Please type "I confirm to reset wallet password" and continue.',
      consent: 'I confirm to reset wallet password',
    },
    resetPasswordMsg: {
      noActiveBinding: 'Device not bound to the specified wallet',
      unbindErr: 'Failed to unbind wallet',
      genericErr: 'Failed to reset wallet password',
      success: 'Successfully reset wallet password',
    },
    unstaking: {
      label: 'Staking Info',
      getErr: 'Failed to get unstaking choice',
      alreadyChosen: 'You have already chosen to unstake',
      expired: 'The unstaking period has ended',
      invalidCreditData: 'This wallet is not placed on the Deeper Chain.',
      phase1IsNotOver: 'The staking period of this wallet has not yet expired.',
      setErr: 'Failed to set unstaking choice',
      announcementTitle: 'Staking Terms & Conditions',
      announcement: {
        continueStaking:
          'Staking renewal: Your stake has been automatically entered into the second term, which will be a six-month non-locked staking period. Basic Mining & Genesis APY will remain the same for the second term.',
        duringStaking:
          'Unstaking: You can click the unstake button to unstake at any time during your 2nd term. This action is irreversible!',
        stopStaking:
          'Distribution: Once unstaked, you will enter a 3-month linear release/distribution phase. $DPR will be automatically distributed daily to your Deeper Chain wallet.',
        releaseStaking: '',
      },
      tipTitle: 'Important Reminders:',
      tip: {
        afterStopStaking_1:
          '1) Genesis nodes who unstake will lose their Genesis status and will forever lose the opportunity to be a Genesis node. Your device will not be able to participate in basic mining 2.0 immediately but organic credit score accrual via bandwidth sharing will continue.',
        afterStopStaking_2: '2) Once unstaked, you will no longer receive $DPR mining rewards effective immediately.',
        afterStopStaking_3:
          '3) Once unstaked, the staked credit will be deducted from your credit score. The organic credits earned from bandwidth sharing, if any, will remain.',
        afterStopStaking_4:
          '4) Once unstaked, the user can’t re-stake immediately (need to wait for the future announcements)',
        afterStopStaking_5:
          '5) Once unstaked, the first term will not end until a total of 270 rewards are distributed.',
        afterStopStaking_6:
          '6) If you need any info on mining, please check out our Medium blog for further resources.',
        mediumText1: 'Please click ',
        mediumText2: 'Deeper Network Basic Mining 2.0',
        mediumText3: ' for more details',
        mediumLink:
          'https://deeper-network.medium.com/deeper-network-basic-mining-2-0-mining-updates-for-genesis-and-basic-mining-2ec2f112cfd4',
      },
      btn: {
        cancel: 'Understood, Continue Staking',
        ok: 'Unstake',
      },
    },
    staking: {
      walletInfo: 'Wallet Information',
      campaignLabel: 'Staking Type',
      campaignText: {
        0: 'Genesis Node Gold',
        1: 'Genesis Node Silver',
        2: 'Basic Mining 1.0',
        3: 'DPRB Mining',
        4: 'Basic Mining 2.0',
        7: 'Basic Mining 2.0',
        8: 'Basic Mining 2.0',
      },
      creditLabel: 'Current Credit',
      creditMaxLabel: 'Max Credit',
      creditLevelLabel: 'Credit Tier',
      stakeAndBurn: 'Stake and Burn',
      stakeForCredit: 'Increase Stake',
      dailyReward: 'Daily Reward',
      view: 'View',
      payment: 'Payment',
      insufficientBalance: 'Insufficient balance',
      stakeSuccess: 'The request has been sent. Please wait patiently for the credit to update.',
      stakeError: 'Failed to send request. Please check the wallet related information or consult customer service.',
      burnForCredit: 'Burn DPR for Credit',
      burnSuccess: 'The request has been sent. Please wait patiently for the credit to update.',
      burnError: 'Failed to send request. Please check the wallet related information or consult customer service.',
    },
  },
  // ChangePasswordData
  ChangePasswordData: {
    successMsg: 'Password changed successfully',
    newPasswordMismatch: 'New password and confirmed new password do not match',
    oldPasswordMismatch: 'Old password does not match the record',
    samePasswordError: 'The new password is the same as the old one',
    genericError: 'Error changing password',
    title: 'Change Password',
    oldPwd: 'Please enter the old password',
    newPwd: 'Please enter the new password',
    newPwdAgain: 'Enter the new password again',
    okText: 'OK',
  },
  // dailyTraffic
  dailyTraffic: {
    historySize: 'Number of Days',
    chartTitle: 'Traffic',
    uptimeChartTitle: 'Uptime',
    hour: 'h',
    minute: 'm',
    second: 's',
  },
  // flow
  flow: {
    totalTrafficLabel: 'Total Traffic',
    localTraffic: 'Local Traffic',
    clientTraffic: 'Client Traffic',
    serverTraffic: 'Server Traffic',
    upload: 'Upload',
    download: 'Download',
    sessionSpeed: 'Session Speed',
  },
  // login
  login: {
    pageTitle: 'Welcome to A Deeper Network!',
    username: 'username',
    password: 'password',
    btnText: 'login',
    successMsg: 'Login success',
    missingField: 'Username or password missing',
    wrongField: 'Username or password is wrong',
    invalidToken: 'Invalid login data',
    expiredToken: 'Login data expired',
    genericError: 'Login error',
    tooManyAttempts: 'Too many login attempts',
  },
  //notification
  notification: {
    search: 'Search',
    CP: 'Control Plane',
    DP: 'Data Plane',
    MP: 'Management Plane',
  },
  // DomainConfig
  domainConfig: {
    whiteList: 'Smart Route List',
    blackList: 'Direct Route List',
    domain: 'Domain',
    tunnel: 'Tunnel',
    selectTunnel: 'Select tunnel',
    region: 'Region',
    country: 'Country',
    noTunnel: 'Direct Access',
    convertedTip: 'The URL has been automatically converted to a domain name.',
    urlInputPlaceholder: 'eg: https://www.google.com or google.com',
    missingInfoWarning: 'Missing info',
    invalidDomainError: 'Domain format is invalid or domain is too long',
    whitelistAddSuccess: 'Added to Smart Route List successfully',
    existInBlacklist: 'Failed to add to Smart Route List because it exists in Direct Route List',
    whitelistEntryExist: 'Entry already in Smart Route List',
    tunnelNotExist: 'Failed to add to Smart Route List because tunnel does not exist',
    whitelistAddError: 'Failed to add to Smart Route List',
    blacklistAddSuccess: 'Added to Direct Route List successfully',
    existInWhitelist: 'Failed to add to Direct Route List because it exists in Smart Route List',
    blacklistEntryExist: 'Entry already in Direct Route List',
    blacklistAddError: 'Failed to add to Direct Route List',
    conflict: 'Failed to add entry due to conflict',
    whitelistDelSuccess: 'Smart Route List entries deleted successfully',
    domainNotExist: 'Selected domain(s) do not exist',
    whitelistDelError: 'Failed to delete Smart Route List entries',
    blacklistDelSuccess: 'Direct Route List entries deleted successfully',
    blacklistDelError: 'Failed to delete Direct Route List entries',
    backupTips:
      'The backup tunnel is now active. In smart routing mode, if the added tunnel temporarily lacks available nodes, the system will default to using the standby tunnel - {0}.',
    tips: {
      smartRouteList:
        'According to your needs, you can manually add the domain names you want to access along with their corresponding countries.',
      directRouteList:
        'The domain names added here will not go through the DPN tunnel but will be directly accessed using the local network.',
    },
  },
  // ipConfig
  ipConfig: {
    whiteList: 'Smart IP List',
    blackList: 'Direct IP List',
    ip: 'IP',
    tunnel: 'Tunnel',
    selectTunnel: 'Select tunnel',
    region: 'Region',
    country: 'Country',
    noTunnel: 'No Tunnel',
    convertedTip: 'The URL has been automatically converted to IP',
    urlInputPlaceholder: 'eg: https://xxx.xxx.xxx.xxx or xxx.xxx.xxx.xxx',
    missingInfoWarning: 'Missing info',
    invalidIPError: 'IP format is invalid',
    whitelistAddSuccess: 'Added to Smart IP List successfully',
    existInBlacklist: 'Failed to add to Smart IP List because it exists in Direct IP List',
    whitelistEntryExist: 'Entry already in Smart IP List',
    tunnelNotExist: 'Failed to add to Smart IP List because tunnel does not exist',
    whitelistAddError: 'Failed to add to Smart IP List',
    blacklistAddSuccess: 'Added to Direct IP List successfully',
    existInWhitelist: 'Failed to add to Direct IP List because it exists in Smart IP List',
    blacklistEntryExist: 'Entry already in Direct IP List',
    blacklistAddError: 'Failed to add to Direct IP List',
    whitelistDelSuccess: 'Smart IP List entries deleted successfully',
    ipNotExist: 'Selected IP(s) do not exist',
    whitelistDelError: 'Failed to delete Smart IP List entries',
    blacklistDelSuccess: 'Direct IP List entries deleted successfully',
    blacklistDelError: 'Failed to delete Direct IP List entries',
    backupTips:
      'The backup tunnel is now active. In smart routing mode, if the added tunnel temporarily lacks available nodes, the system will default to using the standby tunnel - {0}.',
    tips: {
      smartRouteList:
        'According to your needs, you can manually add the IP addresses you want to access along with their corresponding countries.',
      directRouteList:
        'The IP addresses added here will not go through the DPN tunnel but will be directly accessed using the local network.',
    },
  },
  // queryRouting
  queryRouting: {
    title: 'Query Routing',
    searchLabel: 'Query Config',
    search: 'Search',
    searchResult: 'Search result: ',
    invalidRoutingError: 'Routing format is invalid',
    defaultConfig: 'Default Config',
    userConfig: 'User Config',
    routingNotFound: 'Routing not found',
    queryRoutingError: 'Failed to query routing',
    convertedTip: 'The URL has been automatically converted to domain name or IP.',
    urlInputPlaceholder: 'eg: google.com or xxx.xxx.xxx.xxx',
  },
  // rustdesk
  rustdesk: {
    ipLabel: 'Your IP address is',
    noPubIp: 'You do not have a public IP.',
    vpnUse:
      'You can use Wireguard VPN port forward to get a public IP. Please note that this public IP can only be used for RustDesk services and cannot be used to provide DPN services.',
    config: {
      title: 'RustDesk configuration',
      switch: 'On/Off',
      forceKey: 'Force client to use the same public key',
      relay: 'Comma-separated relay servers (host:port), eg. www.example.com:21116,192.168.1.1:21117',
      port: 'Port (2-65532)',
      saveBtnText: 'Save',
      pubKey: 'Public key',
      emptyPubKey: 'No public key',
      resetBtnText: 'Reset public key',
    },
    vpn: {
      title: 'Wireguard VPN port forward',
      switch: 'On/Off',
      ip: 'Wireguard server IP',
      port: 'VPN forward port',
    },
    guide: {
      title: 'Configuration guide',
      stepOne: 'Click buttons as shown',
      stepTwoA: 'Copy corresponding field into the form then hit save',
      idAndRelay: 'ID/Relay server',
      idServer: 'ID Server:',
      relayServer: 'Relay Server:',
      apiServer: 'API Server:',
      serverKey: 'Key:',
      stepTwoB: 'Or you can copy the configuration to clipboard and import it in RustDesk',
      copyBtnText: 'Copy server conf to clipboard',
    },
    success: {
      setSwitch: 'Switched RustDesk successfully',
      setForced: 'Forced client to use the same public key successfully',
      setRelayAndPort: 'Saved relay and port successfully',
      resetPubKey: 'Reset public key successfully',
      setVpnSwitch: 'Switched Wireguard VPN successfully',
      copyToClipboard: 'Copied to clipboard successfully',
    },
    error: {
      getNetworkAddress: 'Failed to get IP',
      getConfig: 'Failed to load RustDesk configuration',
      setSwitch: 'Failed to switch RustDesk',
      setForced: 'Failed to force client to use the same public key',
      invalidPort: 'Invalid port',
      invalidRelay: 'Invalid relay',
      setRelayAndPort: 'Failed to save relay and port',
      resetPubKey: 'Failed to reset public key',
      getVpnConfig: 'Failed to get Wireguard VPN configuration',
      setVpnSwitch: 'Failed to switch Wireguard VPN',
      copyToClipboard: 'Failed to copy to clipboard',
      getVersion: 'Failed to get version',
      getHbbrLog: 'Failed to get HBBR log',
      getHbbsLog: 'Failed to get HBBS log',
    },
  },
  // sharing
  sharing: {
    sharingTitle: 'Sharing: ',
    btSharingTitle: 'Bit Torrent Sharing: ',
    enabled: 'Enabled',
    disabled: 'Disabled',
    sharingTrafficLimit: 'Monthly Sharing Traffic Limit',
    sharingBandwidthLimit: 'Sharing Bandwidth Limit',
    unlimited: 'Unlimited',
    limited: 'Limited',
    setLabel: 'Set',
    checkStartEndTime: 'Please select the start time and end time',
    time: 'Time',
    record: 'Record',
    startTime: 'Start Time',
    endTime: 'End Time',
    keyword: 'Keyword',
    search: 'Search',
    webToSharingTitle: 'Apply Web Filter Rules',
    webToSharingDesc: "Apply the device's built-in DNS ad filtering and custom DNS filtering rules",
    yes: 'Yes',
    no: 'No',
    download: 'Plaintext Logs',
    export: 'Encrypted Logs',
    success: {
      enableTrafficLimit: 'Monthly Sharing Traffic Limit enabled. Please set the limit!',
      setSharingTrafficLimit: 'Sharing traffic limit successfully set',
      enableBandwidthLimit: 'Sharing bandwidth limit enabled. Please set the limit between 15Mbps and 1024Mbps!',
      setSharingBandwidthLimit: 'Sharing bandwidth limit successfully set',
    },
    errors: {
      getSharingState: 'Failed to get sharing state',
      switchSharingState: 'Failed to switch sharing state',
      exceedsLimit: 'Failed to enable sharing since monthly traffic exceeded limit',
      getSharingTrafficLimit: 'Failed to get sharing traffic limit',
      setSharingTrafficLimit: 'Failed to set sharing traffic limit',
      invalidTrafficLimit: 'Traffic limit must be a positive number',
      setSharingBandwidthLimit: 'Failed to set sharing bandwidth limit',
      invalidBandwidthLimit: 'Bandwidth limit must be between 15Mbps and 1024Mbps',
      getSharingFilterConfig: 'Failed to get sharing filter config',
      setSharingFilterConfig: 'Failed to set sharing filter config',
    },
  },
  // system
  system: {
    hardwareInfo: 'Hardware Information',
    softwareInfo: 'Software Information',
    networkAddress: 'Network Address',
    sessionInfo: 'Session Information',
    upgradeInfo: 'Firmware Update',
    sn: 'SN',
    cpu: 'CPU',
    cores: 'Cores',
    memory: 'Usage/Total',
    systemVersion: 'System Version',
    appidSignVersion: 'Traffic Protocol Identification Feature Library',
    urlFilterSignVersion: 'Web Filtering Feature Library',
    https: 'HTTPS',
    dns: 'DNS',
    rules: 'Rules',
    ip: 'IP',
    routerMac: 'Router Mac',
    gatewayMac: 'Gateway Mac',
    maxSession: 'Max Session Number',
    currentSession: 'Current Session Number',
    tcpUdpIcmp: 'TCP/UDP/ICMP/TUNNEL',
    autoUpdate: 'Auto Update',
    manualUpgrade: 'Manual Upgrade',
    upgrading: 'Upgrading...',
    latestVersion: 'Latest Version',
    systemStatusHistory: 'System Status History',
    cpuUsage: '24 hours CPU usage',
    memoryUsage: '24 hours memory usage',
    off: 'Off',
    on: 'On',
    nowCheckVersion: 'Now is checking version',
    nowLatestVersion: 'Most Updated Version',
    nowNewTestVersion: 'Now using version',
    upgradeLoadingText:
      "Updating firmware. This may take several minutes. Please don't refresh until update is complete.",
    upgradeSuccess: 'upgrade OK, refreshing page soon',
    upgradeError: {
      noImage: 'Upgrade image missing',
      invalidImage: 'Invalid upgrade image',
      imageArchMismatch: 'Upgrade image and device arch mismatch',
      noResponse: 'The device has restarted. Please refresh the page later to check if the upgrade was successful.',
      alreadyLatest: 'current version is new, no need to upgrade',
      generic: 'upgrade Error',
    },
    copySuccess: 'Copy Success',
    copyFail: 'Copy Fail',
  },
  // techSupport
  techSupport: {
    remoteConnection: {
      title: 'Remote Connection',
      loading: 'Connecting...',
      popupTitle: 'Remote Connection Code',
      intro: `Applicable Scenario: <span>When your device\'s network is functioning normally, but you encounter issues with other features</span>.<br/>Generate a "Remote Connection Code" manually and send it to our customer service team. Our technical team will swiftly locate and assist you in resolving the issue.Please be aware that to maintain the effectiveness of feature code, it\'s advisable to refrain from generating it multiple times within a short span.`,
      buttonText: 'Generate a code',
      generateFeatureCodeFailure: 'Failed to generate code.',
      tips:
        'If you face any issues, please send the details to our customer support. We are dedicated to helping you and ensuring your problems are resolved.',
      confirm: 'Copy and Close',
      copySuccess: 'Successfully copied to clipboard',
      copyFailure: 'Failed to copy to clipboard',
      connectFailure: 'Failed to connect',
    },
    logDownload: {
      title: 'Log Download',
      loading: 'Downloading...',
      intro:
        'Applicable Scenario: <span>When your device can access the backend but is experiencing network issues</span><br />.Please manually download the log files and send them to our customer service team. We will understand your specific situation and provide more effective assistance.',
      buttonText: 'Download',
      downloadSuccess: 'Download success',
      downloadFailure: 'Download failed, please try again later',
    },
    supportServices: {
      title: 'Get the help you need.',
      intro: 'Give us a few details and we’ll offer the best solution.',
      buttonText: 'Get the help',
    },
    promiseTips: {
      title: 'Security Assurance Promise',
      intro:
        'Rest assured, we strictly <span>limit the use of remote connection and log download features solely for problem diagnosis and resolution</span>, and never for any other purpose, ensuring your privacy and security are protected with the utmost rigor.',
    },
  },
  // builtinRules
  builtinRules: [
    {
      label: 'Enable HTTPS filtering (include HTTP filter)',
      sslbypass: 'Enable SSL Bypass',
      value: 'youtube',
    },
  ],
  // customrules
  customrules: [
    {
      label: 'Enable HTTPS filtering (include HTTP filter)',
      value: 'youtube',
    },
  ],
  // tproxy
  tproxy: {
    browserCacheWarning:
      'If HTTPS ad filtering fails to properly filter ads after being enabled, you need to clear the browser cache. Only enabling HTTPS filtering without installing the certificate will not only fail to filter ads, but may also affect normal access to websites on the list.',
    triggerRulesTipsTitle: 'Tips',
    buttonAddRule: 'Add Rule',
    buttonInstallCertificate: 'Install Certificate',
    missingInfoWarning: 'Please checkout your input',
    missingInfoSuccess: 'Operation Successful',
    missingInfoError: 'Operation Failed',
    certificateTip: 'In order to ensure normal use, please ensure that the certificate has been installed',
    remind: 'Do not remind again',
    tip: 'You need to follow the steps below to block ads correctly',
    triggerRulesTips:
      "After enabling HTTPS filtering, in order for Deeper Connect to filter advertisements on websites listed, you need to install and trust Deeper Network's root certificate on your computer. ",
    triggerRulesTips2:
      'Simply enabling HTTPS filtering without installing the certificate may not only fail to filter ads, but may also affect normal access to websites on the list.',
    mobileDownloadCertTips: 'Please download and install the certificate using Safari browser.',
    androidTVTip: 'If you are using Android TV, please download the APP to watch the video without ads',
    errorMessage: {
      maxLength: 'Maximum number of rules is 1024',
      ruleExisted: 'domain and pattern already existed',
    },
    label: {
      domain: 'Domain',
      Pattern: 'Pattern',
      ruleType: 'Rule Type',
      web: 'Browser',
      app: 'APP',
      all: 'ALL',
      Rewrite_Pattern: 'Rewrite Pattern',
      Rewrite_Info: 'Rewrite Info',
      Rewrite_Action: 'Action',
      enable: 'Enable',
      operation: 'Operation',
      order: 'Order Number',
    },
    operation: {
      delete: 'Delete',
      edit: 'Edit',
    },
    modal: {
      title: 'Add Rule',
      confirmText: 'Submit',
      cancelText: 'Cancel',
    },
    placeholder: {
      domain: 'Please enter correct domain',
      Pattern: 'Please enter correct pattern',
      ruleType: 'Please select a rule type',
      Rewrite_Pattern: 'Please enter correct rewrite pattern',
      Rewrite_Info: 'Please enter rewrite info',
      Rewrite_Action: 'Please select an action',
    },
    builtinRules: {
      title: 'Ads Filter',
      filterListtitle: 'HTTPS Filter List',
      platform: 'Support Platform',
      segOptions: ['Certificate Installation Tutorial', 'Support List'],
    },
    customerRules: {
      title: 'Customized HTTPS rules',
    },
    guide: {
      buttonDownloadCertificate: 'Click Download Certificate',
      buttonDownloadApp: 'Click Download SkyTube',
      tab: {
        mobileInstall: 'iPhone/iPad',
        macInstall: 'MacOS',
        windowsInstall: 'Windows',
        androidInstall: 'Android',
        linuxInstall: 'Linux',
      },
      mobile: {
        videoTab: 'Video',
        textTab: 'Text',
        step1: {
          label: 'step 1',
        },
        step2: {
          label: 'step 2',
          tip: 'Click Allow',
        },
        step3: {
          label: 'step 3',
          tip: 'Open iPhone Settings，click "Profile Downloaded"',
        },
        step4: {
          label: 'step 4',
          tip: 'Click Install，enter phone password',
        },
        step5: {
          label: 'step 5',
          tip: 'Click Install',
        },
        step6: {
          label: 'step 6',
          tip: 'Installation complete',
        },
        step7: {
          label: 'step 7',
          tip:
            'Open phone "Settings" > "General" > "About" > "Certificate Trust Settings", enable the installed certificate.',
        },
      },
      mac: {
        step1: {
          label: 'step 1',
        },
        step2: {
          label: 'step 2',
          tip:
            'Click on the downloaded certificate, the system will display an "Add Certificate" pop-up window, and select "Login" in the "Keychain" and then press "Add".',
        },
        step3: {
          label: 'step 3',
          tip: 'In the "Keychain Access" window, choose "Login" from the "Default Keychain" in the sidebar.',
        },
        step4: {
          label: 'step 4',
          tip: 'Find the "deeper.network" certificate and select it, then right-click and choose "Get Info".',
        },
        step5: {
          label: 'step 5',
          tip: 'Click on "Trust" in the info, change "Use System Defaults" to "Always Trust".',
        },
        step6: {
          label: 'step 6',
          tip: 'Click on the top left corner to close the info, enter the password, and click on "Update Settings".',
        },
        step7: {
          label: 'step 7',
          tip: 'Certificate installation is complete.',
        },
      },
      windows: {
        step1: {
          label: 'step 1',
        },
        step2: {
          label: 'step 2',
          tip: 'Click the downloaded certificate, click "Install Certificate"',
        },
        step3: {
          label: 'step 3',
          tip: 'Select "Store Location" as "Local Machine", click "Next"',
        },
        step4: {
          label: 'step 4',
          tip: 'Select "Place all certificates in the following store", click "Browse"',
        },
        step5: {
          label: 'step 5',
          tip: 'Select "Trusted Root Certification Authorities" and click "OK"',
        },
        step6: {
          label: 'step 6',
          tip: 'Click "Finish", certificate installation is complete',
        },
      },
      linux: {
        rootTab: 'System root certificate import tutorial',
        root: {
          step1: {
            label: 'step 1',
          },
          step2: {
            label: 'step 2',
            tip:
              '<p>Copy the certificate file to /usr/local/share/ca-certificates/</p>\
              <p>This directory is specifically for storing additional CA certificates installed locally. The certificate file should be a PEM format file with a .crt extension.</p>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /usr/local/share/ca-certificates/</code></div>',
          },
          step3: {
            label: 'step 3',
            tip:
              '<p>Update the certificate store</p>\
              <p>Use the update-ca-certificates command to update the certificate store, which will automatically refresh the certificate trust chain in the system.</p>\
              <div class="code"><code>sudo update-ca-certificates</code></div>\
              <p>This command reads the certificate files in the /usr/local/share/ca-certificates/ directory and adds them to the system\'s trust list.</p>\
              <em><em class="warning">Note:</em> Different Linux distributions may have different certificate store locations and update commands.</em><br>\
              <em>For example, on Red Hat-based systems\
              (such as Fedora, CentOS), you may need to copy the certificate to /etc/pki/ca-trust/source/anchors/,</em><br>\
              <em>and then run the update-ca-trust command to update the certificate store.</em><br>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /etc/pki/ca-trust/source/anchors/</code><br>\
              <code>sudo update-ca-trust</code></div>\
              <p>After performing these steps, the root certificate you installed should now be trusted by the system.</p>',
          },
        },
        chrome: {
          step1: {
            label: 'step 1',
          },
          step2: {
            label: 'step 2',
            tip:
              'Enter chrome://settings/certificates in the address bar, and then select the "<b>Authorities</b>" tab.',
          },
          step3: {
            label: 'step 3',
            tip: 'Click Import，find the certificate just downloaded，click Open',
          },
          step4: {
            label: 'step 4',
            tip:
              'Check all options and click Ok in the "<b>Certificate Authority</b>" window to complete the certificate installation.',
          },
        },
        firefox: {
          step1: {
            label: 'step 1',
          },
          step2: {
            label: 'step 2',
            tip:
              "Enter 'about:preferences#privacy' in the address bar, find 'Certificates', and click 'View Certificates'",
          },
          step3: {
            label: 'step 3',
            tip: 'Select Authorities，click Import',
          },
          step4: {
            label: 'step 4',
            tip: 'Select the certificate just downloaded and click Open',
          },
          step5: {
            label: 'step 5',
            tip: 'Check all options, click OK, the certificate installation is complete',
          },
        },
      },
      android: {
        appTab: 'APP',
        browserTab: 'Browser',
        app: {
          step1: {
            label: 'step 1',
          },
          step2: {
            label: 'step 2',
            tip: 'Install the downloaded app and enjoy the video without ads',
          },
        },
        browser: {
          step1: {
            label: 'step 1',
          },
          step2: {
            label: 'step 2',
            tip: 'Open the phone "Settings" and find "Security"',
          },
          step3: {
            label: 'step 3',
            tip: 'Enter "Security", find "Encryption & Credentials", click to enter',
          },
          step4: {
            label: 'step 4',
            tip: 'Click "Install a certificate"',
          },
          step5: {
            label: 'step 5',
            tip: 'Click "CA certificate"',
          },
          step6: {
            label: 'step 6',
            tip: 'click "Install anyway"，then enter your phone password',
          },
          step7: {
            label: 'step 7',
            tip: 'Select the downloaded certificate',
          },
          step8: {
            label: 'step 8',
            tip: 'Prompt that the CA certificate has been installed and the certificate is successfully installed',
          },
        },
      },
    },
  },
  // SSLBypassCheckbox
  SSLBypassCheckbox: {
    sslBypass: 'Enable SSL Bypass',
    sslBypassTips: 'Allow devices without certificates to connect to the network.',
    sslBypassWaring: 'If SSL bypass is enabled, ad filtering may be missed.',
    success: 'Operation Successful',
    failed: 'Operation Failed',
  },
  // tunnel
  tunnel: {
    configuredTunnel: 'Configured Tunnel',
    countryTooltip:
      'Fuzzy search for the country name or enter the country code (in capital letters) and press Enter to search for the desired country',
    region: 'Region',
    country: 'Country',
    activeTunnelNum: 'Number of active IPs',
    activeIp: 'Active IP',
    switchNode: 'Switch active IP',
    switchIpTitle: 'Switch IP',
    initTunnelTitle: 'Reinitialize the tunnel',
    initTunnelTitleTips:
      'A tunnel has 3 IP addresses. When you refresh the tunnel, it will switch to 3 new IP addresses for you.',
    selectRegion: 'Select region',
    selectCountry: 'Select country',
    missingInfoWarning: 'Missing info',
    tunnelAddSuccess: 'Tunnel successfully added. The page will refresh within 3 seconds.',
    tunnelExist: 'Tunnel already exists',
    reachedMax: 'Number of tunnels reached maximum',
    tunnelConflict: 'Tunnel to be added conflicts with tunnel {0} - {1}',
    tunnelAddError: 'Failed to add tunnel',
    tunnelDelSuccess: 'Tunnels successfully deleted. The page will refresh within 3 seconds.',
    tunnelNotExist: 'Tunnel(s) do not exist',
    usedByFullMode: 'Tunnel(s) to be deleted are used by DPN full route',
    usedByAccessControl: 'Tunnel(s) to be deleted are used by Access Control',
    usedByBackup: 'The backup tunnel is using this tunnel, therefore it cannot be deleted.',
    tunnelInUse: 'Tunnel(s) to be deleted are used by routing table',
    tunnelDelError: 'Failed to delete tunnels',
    switchNodeSuccess: 'Node successfully switched. The page will refresh within 3 seconds.',
    noActiveIp: 'No active IP available for switching',
    ipNotChanged: 'IP not changed',
    switchNodeError: 'Failed to switch ip',
    refreshLabel: 'Refresh',
    getNewIPLabel: 'Get new IPs',
    refreshError: 'Failed to refresh tunnel',
    refreshSuccess: 'Tunnel refresh request submitted. The page will refresh within 3 seconds.',
    tips: {
      refreshTunnel: 'Refresh new active tunnels for you.',
      configuredTunnel:
        'After manually adding the regional tunnels you need, AtomOS will search for nodes that meet your requirements globally.',
    },
    nodeLbMode: {
      label: 'Node Selection Mode',
      none: {
        label: 'Regular',
        desc:
          'In this tunnel mode, all network connections pass through one active node. Users can switch this node at any time.',
      },
      random: {
        label: 'Random',
        desc: 'The system automatically selects a node at random for each connection.',
      },
      domain: {
        label: 'Domain-Aware',
        desc:
          'The system utilizes multiple nodes within the tunnel to access the network. However, it remembers and consistently routes access to specific domain names through the same node, thus lowering the risk of errors in website access.',
      },
      rr: {
        label: 'Round-Robin',
        desc:
          'With each connection, the system sequentially selects from a set of nodes, helping you achieve better network acceleration.',
      },
      failure: 'Failed to save Node Load Balancing Mode',
      success: 'Successfully saved Node Load Balancing Mode',
    },
  },
  // userConsent
  userConsent: {
    notice: 'Notice: Sharing is disabled before you agree to TERMS AND CONDITIONS OF USE and PRIVACY POLICY.',
    consentStart: 'I have read and agree to Deeper Network’s ',
    termsOfUseNotRead: "You haven't read TERMS AND CONDITIONS OF USE",
    termsOfUseLink: 'https://shop.deeper.network/terms-of-service',
    termsOfUseTitle: 'TERMS AND CONDITIONS OF USE',
    consentAnd: ' and ',
    privacyPolicyNotRead: "You haven't read PRIVACY POLICY",
    privacyPolicyLink: 'https://shop.deeper.network/privacy-policy',
    privacyPolicyTitle: 'PRIVACY POLICY',
    confirmBtn: 'Confirm',
    consentError: 'Failed to confirm consent',
  },
  // customConfigLang
  customConfigLang: {
    missingInfoWarning: 'Domain is missing',
    invalidDomainError: 'Domain format is invalid or domain is too long',
    whitelist: 'Whitelist',
    blacklist: 'Blacklist',
    domain: 'Domain',
    whitelistAddSuccess: 'Added to whitelist successfully',
    whitelistEntryExist: 'Entry already in whitelist',
    reachedWhiteMax: 'Reached whitelist maximum',
    whitelistAddError: 'Failed to add to whitelist',
    blacklistAddSuccess: 'Added to blacklist successfully',
    blacklistEntryExist: 'Entry already in blacklist',
    reachedBlackMax: 'Reached blacklist maximum',
    blacklistAddError: 'Failed to add to blacklist',
    whitelistDelSuccess: 'Whitelist entries deleted successfully',
    whitelistDelError: 'Failed to delete whitelist entries',
    blacklistDelSuccess: 'Blacklist entries deleted successfully',
    blacklistDelError: 'Failed to delete blacklist entries',
    import: 'Import',
    fileButtonText: 'Click to Upload',
    fileSizeOverLimit: 'Uploaded file exceeds limit of 2MB',
    whitelistImportSuccess: 'Whitelist imported successfully, {0} entries imported',
    whitelistImportError: 'Failed to import whitelist',
    blacklistImportSuccess: 'blacklist imported successfully, {0} entries imported',
    blacklistImportError: 'Failed to import blacklist',
    noValidEntry: 'There is no valid domain entry in the uploaded file',
    zeroImported: 'Failed to import any domain entry',
  },
  // defaultConfigLang
  defaultConfigLang: {
    browserCacheWarning:
      'If ads cannot be properly filtered when DNS ad filtering is enabled, you need to clear the browser cache and restart the filtered device.',
    queriesFor24Hours: 'Queries over last 24 hours',
    totalQuery: 'Total Queries',
    blockedQuery: 'Blocked Queries',
    blockedPercentage: 'Percentage of blocked',
    ruleTotal: 'Rules',
    adQuery: 'Blocked Ads',
    trackerQuery: 'Blocked Trackers',
    maliciousQuery: 'Blocked Malicious',
    pornQuery: 'Blocked Porn',
    legendTotal: 'Total',
    legendBlocked: 'Blocked',
    categoryStateEnabled: 'on',
    categoryStateDisabled: 'off',
    categoryState2Hours: 'disabled for 2 hours',
    categoryState4Hours: 'disabled for 4 hours',
    categoryState8Hours: 'disabled for 8 hours',
    setCategoryStatesErr: 'Error enabling/disabling the filter',
    enableAll: 'Enable all filters',
    disableAll: 'Disable all filters',
  },
  // wifiSettings
  wifiSettings: {
    workMode: 'Working Mode',
    currentWorkMode: 'Working mode detected',
    'wire-ap': 'Wire WAN',
    'wlan-ap': 'Wireless WAN',
    notConnect: 'Network is not connected',
    upstreamTitle: 'Upstream Settings',
    downstreamTitle: 'Wireless LAN',
    backHome: 'Back Dashboard',
    apConfigWarning:
      'After saving the new Wi-Fi SSID and Wi-Fi password, if you want to access the Internet through the Deeper device, you need to rescan the Deeper device hotspot and apply the new password',
    attention: 'Attention',
    buttons: {
      scanWifi: 'Scan',
      connectStatus: 'Status',
      add: 'Add',
      save: 'save',
      cancel: 'cancel',
      downloadLog: 'Download Log',
    },
    connect: 'connect',
    forget: 'forget',
    waiting: {
      content: 'Connecting, it may take a while, please wait...',
      cancelBtn: 'Cancel Connect',
    },
    modals: {
      hiddenWifi: {
        title: 'Add Hidden Wi-Fi',
        okBtn: 'add',
      },
      scanWifi: {
        title: 'Warning',
        content: 'The network will be disconnected for a while',
        ok: 'OK',
        cancel: 'Cancel',
      },
    },
    label: {
      ssid: 'Wi-Fi SSID',
      password: 'Wi-Fi Password',
      hidden: 'Hide SSID',
      onlyUse: 'Only Use',
      freqBand: 'Frequency Band',
    },
    placeholder: {
      ssid: 'please enter SSID',
      password: 'please enter password',
    },
    message: {
      saveSuccess: 'saved successfully',
      saveError: 'save failed, please retry',
      connectSuccess: 'Connected successfully',
      forgetSuccess: 'Forgotten successfully',
      inputError: 'Please check your input',
      invalidWifiDev: 'Without a valid Wi-Fi device, Wi-Fi settings cant be performed',
      illegalWifiDev: 'Wi-Fi setup is only available in AP Router Mode and Wireless Relay Mode',
      invalidSsid: 'only support letters, numbers, _ and -',
      invalidPassword: 'password is at least 8 characters and cannot contain spaces',
      notConnect: 'Your network is not connected',
      retry: 'please try again',
      connectionError: 'Failed to connect',
      forgetError: 'Failed to forget',
      wifiNotSupported: 'Wi-Fi not supported',
      networkNotFound: 'Network not found',
      pskNotGenerated: 'Key not generated',
      wrongPassword: 'Wrong password',
      timeout: 'Connection timed out',
      logDownloadError: 'Failed to download log',
      hasBeenConnected: 'has been connected.',
      dhcpSuccess: 'Succeeded',
      dhcpFailure: 'Failed',
    },
    connectionRequestedTitle: 'Connection requested',
    connectionRequestedContent:
      'Please check your connection status under the “Status” tab. If you are disconnected from Deeper Connect, please try to connect to DeeperWiFi again.',
    gotIpFromDhcpLabel: 'Got IP from DHCP',
  },
  // parentalControlData
  parentalControlData: {
    browserCacheWarning:
      'If adult websites or social media cannot be properly blocked when parental controls are enabled, you need to clear the browser cache and restart the filtered device.',
    customerRule:
      'Here, you can block the vast majority of adult and social websites for your kids. If you have other websites you want to block, you can use the custom domain blocking feature to add more sites.',
    customerRuleClickTips: 'Please {0} to access the custom domain blocking page.',
    toCustomerRuleButtonText: 'click here',
    parentalControl: 'Parental Control',
    pornQuery: 'Blocked Porn',
    socialQuery: 'Blocked Social Media',
    legendBlocked: 'Blocked',

    // social
    socialStateEnabled: 'block social media',
    socialStateDisabled: 'unblock social media',
    socialState2Hours: 'unblock social media for 2 hours',
    socialState4Hours: 'unblock social media for 4 hours',
    socialState8Hours: 'unblock social media for 8 hours',
    // porn
    pornStateEnabled: 'block porn',
    pornStateDisabled: 'unblock porn',
    pornState2Hours: 'unblock porn for 2 hours',
    pornState4Hours: 'unblock porn for 4 hours',
    pornState8Hours: 'unblock porn for 8 hours',
    setCategoryStatesErr: 'Error enabling/disabling the filter',
  },
  reboot: {
    success: {
      saveConfig: 'Saved successfully',
    },
    failure: {
      getConfig: 'Failed to get Auto Reboot config',
      saveConfig: 'Failed to save Auto Reboot config',
    },
    buttons: {
      reboot: 'Click to Reboot',
      save: 'Save',
    },
    week: {
      0: 'Everyday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      7: 'Sunday',
    },
    title: {
      manual: 'Restart now',
      auto: 'Scheduled Reboot',
    },
    label: {
      period: 'Period',
      time: 'Time',
    },
  },
};
