export default {
  type: 'hindi',
  internationalize: {
    type: 'hindi',
    'common': {
      'tip_text': 'सूचना',
      'search': 'खोजें',
      'add': 'जोड़ें',
      'delete': 'हटाएँ',
      'confirm': 'पुष्टि करें',
      'cancel': 'रद्द करें',
      'warning': 'चेतावनी',
      'utcDisclaimer': 'तारीख UTC में है',
      'toOpen': 'खोलें',
    },
    certificateDialog: {
      title: 'नई रूट CA प्रमाणपत्र',
      content: 'रूट CA प्रमाणपत्र बदल दिया गया है। कृपया इसे फिर से डाउनलोड करें और स्थापित करें।',
      dontShowAgain: 'फिर से न दिखाएँ',
    },
    'request_tip': {
      'error': 'अनुरोध त्रुटि~',
    },
    header: {
      'logout': 'लॉगआउट',
      'changePassword': 'पासवर्ड बदलें',
      'userManual': 'उपयोगकर्ता मैनुअल',
      'defaultAvatar': 'डिफ़ॉल्ट अवतार',
      'english': 'अंग्रेज़ी',
      'german': 'जर्मन',
      'korean': 'कोरियाई',
      'french': 'फ्रेंच',
      'spain': 'स्पेनिश',
      'portugal': 'पुर्तगाली',
      'japanese': 'जापानी',
      'Tchinese': 'चीनी (ट्रेडिशनल)',
      'russian': 'रूसी',
      'hindi': 'हिंदी',
      'simplechinese': 'चीनी (सिंपल)',
    },
    'home': {
      'name': 'डैशबोर्ड',
      'children': {},
      'content': {
        'tipInput': 'कृपया इनपुट करें',
        'ok': 'ठीक है',
        'cancel': 'रद्द करें',
        'currentStatus': 'वर्तमान स्थिति',
        'statusMap': {
          'Trial': 'परीक्षण',
          'Valid': 'मान्य',
          'Expired': 'समाप्त हो गया',
        },
        'btnActive': 'सक्रिय करें',
        'btnEdit': 'संशोधित करें',
        'expireDate': 'समाप्ति तिथि',
        'licenseKey': 'लाइसेंस कुंजी',
      },
    },
    accessControl: {
      name: 'अभिगम नियंत्रण',
    },
    'tpProxy': {
      'name': 'पारदर्शी प्रॉक्सी',
      'children': {
        'customRules': {
          'name': 'कस्टम नियम',
        },
        'builtinRules': {
          'name': 'विज्ञापन फिल्टर',
        },
        'certificate': {
          'name': 'प्रमाणपत्र स्थापित करें',
        },
      },
    },
    'help': {
      'name': 'मदद',
      'children': {
        'techSupport': {
          'name': 'तकनीकी सहायता',
        },
      },
    },
    'device': {
      'name': 'उपकरण',
      'children': {
        'system': {
          'name': 'सिस्टम',
        },
        'traffic': {
          'name': 'ट्रैफिक',
        },
        'notification': {
          'name': 'लॉग',
        },
      },
      'content': {},
    },
    'dpn': {
      'name': 'डीपीएन',
      'content': {},
      'children': {
        'smartRoute': {
          'name': 'मोड',
        },
        'tunnel': {
          'name': 'सुरंग',
        },
        'routingTable': {
          'name': 'स्मार्ट रूटिंग',
          'content': {},
          'children': {
            'domainConfig': {
              'name': 'डोमेन कॉन्फिग',
            },
            'ipConfig': {
              'name': 'आईपी कॉन्फिग',
            },
            'queryRouting': {
              'name': 'क्वेरी',
            },
          },
        },
      },
    },
    'appRelocator': {
      'name': 'ऐप रीलोकेटर',
    },
    'adsBlock': {
      'name': 'विज्ञापन ब्लॉकर',
      'children': {
        'domainFilter': {
          'name': 'डीएनएस फिल्टर',
        },
        'httpsFilter': {
          'name': 'एचटीटीपीएस फिल्टर',
        },
      },
      httpssucceed: 'HTTPS फ़िल्टरिंग सफलतापूर्वक सक्षम कर दी गई है',
      httpslose: 'HTTPS फ़िल्टरिंग सफलतापूर्वक अक्षम कर दी गई है',
      saverror: 'प्रचालन विफल रहा',
      usedByAccessControl: 'HTTPS फ़िल्टर का उपयोग एक्सेस कंट्रोल द्वारा किया जाता है',
    },
    'dpr': {
      'name': 'माइनिंग',
      'children': {
        'dpr': {
          'name': 'डीपीआर',
          'children': {
            'stakingTutorial': {
              'name': 'स्टेकिंग ट्यूटोरियल',
            },
            'wallet': {
              'name': 'वॉलेट',
              'manager': 'वॉलेट मैनेजर',
              'balance': 'वॉलेट बैलेंस',
            },
            'Staking Mining': 'स्टेकिंग माइनिंग',
            'transaction': {
              'id': 'लेनदेन आईडी',
              'from': 'से',
              'to': 'को',
              'time': 'समय (UTC)',
              'rewardTime': 'पुरस्कार समय (UTC)',
              'amount': 'डीपीआर राशि',
              'Reward in selected period': 'चयनित अवधि में पुरस्कार',
              'Total reward': 'कुल पुरस्कार',
              'Balance Transfer': 'बैलेंस ट्रांसफर',
              'Staking Reward': 'स्टेकिंग पुरस्कार',
              'PoS Reward': 'पीओएस पुरस्कार',
              'Micro Payment': 'माइक्रोपेमेंट्स',
              'stakeRelease': 'स्टेक रिलीज',
              'Transaction': 'लेनदेन',
              'Mining': 'माइनिंग',
              'Traffic Mining': 'ट्रैफिक माइनिंग',
              'Staking Mining': 'स्टेकिंग माइनिंग',
            },
          },
        },
      },
    },
    'security': {
      'name': 'सुरक्षा',
      'children': {
        'applications': 'ऐप्स कंट्रोल',
        'traffic': 'ट्रैफिक शेपिंग',
        'webfilter': {
          'name': 'वेब फिल्टर',
          'children': {
            'report': {
              'name': 'डैशबोर्ड',
            },
            'config': {
              'name': 'कस्टमाइज़्ड DNS नियम',
            },
          },
        },
        'sslFilter': {
          'name': 'एसएसएल फिल्टर',
          'children': {
            'customDNSrules': {
              'name': 'कस्टमाइज़्ड DNS नियम',
            },
            'customHTTPSRules': {
              'name': 'कस्टमाइज़्ड HTTPS नियम',
            },
          },
        },
      },
      'content': {},
    },
    'parentalControl': {
      'name': 'माता-पिता नियंत्रण',
    },
    regionCountryTexts: {
      'LL': 'प्रत्यक्ष पहुँच',
      'ANY': 'किसी भी देश',
      // regions
      'AMER': '---अमेरिका---',
      'ASIA': '---एशिया---',
      'AFRI': '---अफ्रीका---',
      'EURO': '---यूरोप---',
      'OCEA': '---ओशियानिया---',
      'AMN': 'उत्तरी अमेरिका',
      'AMC': 'कैरिबियन',
      'AMM': 'मध्य अमेरिका',
      'AMS': 'दक्षिण अमेरिका',
      'ASC': 'मध्य एशिया',
      'ASE': 'पूर्वी एशिया',
      'ASW': 'पश्चिमी एशिया',
      'ASS': 'दक्षिण एशिया',
      'ASD': 'दक्षिण पूर्व एशिया',
      'AFN': 'उत्तरी अफ्रीका',
      'AFM': 'मध्य अफ्रीका',
      'AFE': 'पूर्वी अफ्रीका',
      'AFW': 'पश्चिमी अफ्रीका',
      'AFS': 'दक्षिण अफ्रीका',
      'EUN': 'उत्तरी यूरोप',
      'EUE': 'पूर्वी यूरोप',
      'EUW': 'पश्चिमी यूरोप',
      'EUS': 'दक्षिण यूरोप',
      'OCP': 'पोलिनेशिया',
      'OCA': 'ऑस्ट्रेलिया और न्यूजीलैंड',
      'OCM': 'मेलानेशिया',
      'OCN': 'माइक्रोनेशिया',
      // countries
      'AD': 'अंडोरा',
      'AE': 'संयुक्त अरब अमीरात',
      'AF': 'अफगानिस्तान',
      'AG': 'एंटिगुआ और बरबुडा',
      'AI': 'एंगुइला',
      'AL': 'अल्बानिया',
      'AM': 'आर्मेनिया',
      'AO': 'अंगोला',
      'AR': 'अर्जेंटीना',
      'AS': 'अमेरिकी समोआ',
      'AT': 'ऑस्ट्रिया',
      'AU': 'ऑस्ट्रेलिया',
      'AW': 'अरूबा',
      'AX': 'ऑलैंड द्वीपसमूह',
      'AZ': 'अज़रबैजान',
      'BA': 'बोस्निया और हर्जेगोविना',
      'BB': 'बारबाडोस',
      'BD': 'बांग्लादेश',
      'BE': 'बेल्जियम',
      'BF': 'बुर्किना फासो',
      'BG': 'बुल्गारिया',
      'BH': 'बहरीन',
      'BI': 'बुरुंडी',
      'BJ': 'बेनिन',
      'BL': 'सेंट बार्थेलेमी',
      'BM': 'बरमूडा',
      'BN': 'ब्रुनेई',
      'BO': 'बोलीविया',
      'BQ': 'बोनेयर, सिंट यूस्टीशियस और साबा',
      'BR': 'ब्राजील',
      'BS': 'बहामास',
      'BT': 'भूटान',
      'BW': 'बोत्सवाना',
      'BY': 'बेलारूस',
      'BZ': 'बेलीज',
      'CA': 'कनाडा',
      'CC': 'कोकोस (कीलिंग) द्वीप',
      'CD': 'कांगो, गणतांत्रिक गणराज्य',
      'CF': 'मध्य अफ्रीकी गणराज्य',
      'CG': 'कांगो',
      'CH': 'स्विट्ज़रलैंड',
      'CI': "कोट ड'आइवर",
      'CK': 'कुक द्वीपसमूह',
      'CL': 'चिली',
      'CM': 'कैमरून',
      'CN': 'चीन',
      'CO': 'कोलम्बिया',
      'CR': 'कोस्टा रिका',
      'CU': 'क्यूबा',
      'CV': 'केप वर्ड',
      'CW': 'क्यूरासाओ',
      'CX': 'क्रिसमस द्वीप',
      'CY': 'साइप्रस',
      'CZ': 'चेकिया',
      'DE': 'जर्मनी',
      'DJ': 'जिबूती',
      'DK': 'डेनमार्क',
      'DM': 'डोमिनिका',
      'DO': 'डोमिनिकन गणराज्य',
      'DZ': 'अल्जीरिया',
      'EC': 'इक्वाडोर',
      'EE': 'एस्टोनिया',
      'EG': 'मिस्र',
      'ER': 'इरिट्रिया',
      'ES': 'स्पेन',
      'ET': 'इथियोपिया',
      'FI': 'फिनलैंड',
      'FJ': 'फिजी',
      'FK': 'फ़ॉकलैंड द्वीपसमूह (मालवीनास)',
      'FM': 'माइक्रोनेशिया (संघीय राज्य)',
      'FO': 'फ़ेरो द्वीपसमूह',
      'FR': 'फ़्रांस',
      'GA': 'गैबॉन',
      'GB': 'महान ब्रिटेन और उत्तरी आयरलैंड का संयुक्त राज्य',
      'GD': 'ग्रेनाडा',
      'GE': 'जॉर्जिया',
      'GF': 'फ़्रांसीसी गिआना',
      'GG': 'गर्नसी',
      'GH': 'घाना',
      'GI': 'जिब्राल्टर',
      'GL': 'ग्रीनलैंड',
      'GM': 'गाम्बिया',
      'GN': 'गिनी',
      'GP': 'ग्वाडेलूप',
      'GQ': 'इक्वेटोरियल गिनी',
      'GR': 'ग्रीस',
      'GS': 'दक्षिण जॉर्जिया और दक्षिण सैंडविच द्वीपसमूह',
      'GT': 'ग्वाटेमाला',
      'GU': 'गुआम',
      'GW': 'गिनी-बिसाउ',
      'GY': 'गुयाना',
      'HK': 'हॉन्ग कोंग (चीन)',
      'HN': 'होंडुरास',
      'HR': 'क्रोएशिया',
      'HT': 'हैती',
      'HU': 'हंगरी',
      'ID': 'इंडोनेशिया',
      'IE': 'आयरलैंड',
      'IL': 'इज़राइल',
      'IM': 'आइल ऑफ मैन',
      'IN': 'भारत',
      'IO': 'ब्रिटिश हिंद महासागरीय क्षेत्र',
      'IQ': 'इराक',
      'IR': 'ईरान',
      'IS': 'आइसलैंड',
      'IT': 'इटली',
      'JE': 'जर्सी',
      'JM': 'जमैका',
      'JO': 'जॉर्डन',
      'JP': 'जापान',
      'KE': 'केन्या',
      'KG': 'किर्गिज़स्तान',
      'KH': 'कम्बोडिया',
      'KI': 'किरिबाती',
      'KM': 'कोमोरोस',
      'KN': 'सेंट किट्स और नेविस',
      'KR': 'दक्षिण कोरिया',
      'KW': 'कुवैत',
      'KY': 'केमन द्वीपसमूह',
      'KZ': 'कजाखस्तान',
      'KP': 'उत्तर कोरिया',
      'LA': 'लाओसी लोकतांत्रिक गणराज्य',
      'LB': 'लेबनान',
      'LC': 'सेंट लूसिया',
      'LI': 'लिकटेंस्टीन',
      'LK': 'श्रीलंका',
      'LR': 'लाइबेरिया',
      'LS': 'लेसोथो',
      'LT': 'लिथुआनिया',
      'LU': 'लक्जमबर्ग',
      'LV': 'लात्विया',
      'LY': 'लीबिया',
      'MA': 'मोरक्को',
      'MC': 'मोनाको',
      'MD': 'मोल्दोवा, गणराज्य',
      'ME': 'मोंटेनेग्रो',
      'MF': 'सेंट मार्टिन (फ़्रांसीसी भाग)',
      'MG': 'मेडागास्कर',
      'MH': 'मार्शल द्वीपसमूह',
      'MK': 'उत्तर मकेडोनिया',
      'ML': 'माली',
      'MM': 'म्यांमार',
      'MN': 'मंगोलिया',
      'MO': 'मकाऊ (चीन)',
      'MP': 'उत्तरी मारियाना द्वीपसमूह',
      'MQ': 'मार्टीनिक',
      'MR': 'मॉरिटानिया',
      'MS': 'मोंटसेरात',
      'MT': 'माल्टा',
      'MU': 'मॉरीशस',
      'MV': 'मालदीव',
      'MW': 'मलावी',
      'MX': 'मेक्सिको',
      'MY': 'मलेशिया',
      'MZ': 'मोज़ांबिक',
      'NA': 'नामीबिया',
      'NC': 'न्यू कैलेडोनिया',
      'NE': 'नाइजर',
      'NF': 'नॉरफ़ॉक द्वीप',
      'NG': 'नाइजीरिया',
      'NI': 'निकारागुआ',
      'NL': 'नीदरलैंड',
      'NO': 'नॉर्वे',
      'NP': 'नेपाल',
      'NR': 'नाउरु',
      'NU': 'नीयू',
      'NZ': 'न्यूज़ीलैंड',
      'OM': 'ओमान',
      'PA': 'पनामा',
      'PE': 'पेरू',
      'PF': 'फ़्रांसीसी पॉलिनेशिया',
      'PG': 'पापुआ न्यू गिनी',
      'PH': 'फिलीपींस',
      'PK': 'पाकिस्तान',
      'PL': 'पोलैंड',
      'PM': 'सेंट पियरे और मिकेलान',
      'PN': 'पिटकैर्न',
      'PR': 'पोर्टो रिको',
      'PS': 'फ़िलिस्तीन, राज्य',
      'PT': 'पुर्तगाल',
      'PW': 'पलाऊ',
      'PY': 'पराग्वे',
      'QA': 'कतर',
      'RE': 'रीयूनियन',
      'RO': 'रोमानिया',
      'RS': 'सर्बिया',
      'RU': 'रूसी संघ',
      'RW': 'रवांडा',
      'SA': 'सऊदी अरब',
      'SB': 'सोलोमन द्वीपसमूह',
      'SC': 'सेशेल्स',
      'SD': 'सूडान',
      'SE': 'स्वीडन',
      'SG': 'सिंगापुर',
      'SH': 'सेंट हेलेना, आसेंशन और त्रिस्तान दा कुन्हा',
      'SI': 'स्लोवेनिया',
      'SJ': 'स्वालबार्ड और जान मायेन',
      'SK': 'स्लोवाकिया',
      'SL': 'सिएरा लियोन',
      'SM': 'सैन मरीनो',
      'SN': 'सेनेगल',
      'SO': 'सोमालिया',
      'SR': 'सूरीनाम',
      'SS': 'दक्षिण सूडान',
      'ST': 'साओ तोमे और प्रिंसिपे',
      'SV': 'अल सल्वाडोर',
      'SX': 'सिंट मार्टेन (डच भाग)',
      'SY': 'सीरियन अरब गणराज्य',
      'SZ': 'ईस्वातिनी',
      'TC': 'तुर्क्स और कैकोस द्वीपसमूह',
      'TD': 'चाड',
      'TF': 'फ़्रांसीसी दक्षिणी क्षेत्रों',
      'TG': 'टोगो',
      'TH': 'थाईलैंड',
      'TJ': 'ताजिकिस्तान',
      'TK': 'तोकेलाऊ',
      'TL': 'तिमोर-लेस्ते',
      'TM': 'तुर्कमेनिस्तान',
      'TN': 'ट्यूनिशिया',
      'TO': 'टोंगा',
      'TR': 'तुर्की',
      'TT': 'त्रिनिदाद और टोबैगो',
      'TV': 'तुवालु',
      'TW': 'ताइवान (चीन)',
      'TZ': 'तंज़ानिया, संयुक्त गणराज्य',
      'UA': 'यूक्रेन',
      'UB': 'यूएस पश्चिम',
      'UC': 'यूएस मध्य पश्चिम',
      'UD': 'यूएस दक्षिण-पश्चिम',
      'UE': 'यूएस उत्तर-पूर्व',
      'UF': 'यूएस दक्षिण-पूर्व',
      'UG': 'युगांडा',
      'US': 'संयुक्त राज्य अमेरिका',
      'UY': 'उरुग्वे',
      'UZ': 'उज़्बेकिस्तान',
      'VA': 'पवित्र सीट',
      'VC': 'सेंट विंसेंट और ग्रेनाडाइंस',
      'VE': 'वेनेजुएला',
      'VG': 'वर्जिन द्वीपसमूह (ब्रिटिश)',
      'VI': 'वर्जिन द्वीपसमूह (यू.एस.)',
      'VN': 'वियतनाम',
      'VU': 'वनुआतु',
      'WF': 'वालिस और फ़्यूचूना',
      'WS': 'समोआ',
      'XK': 'कोसोवो',
      'YE': 'यमन',
      'YT': 'मायोत्ते',
      'ZA': 'दक्षिण अफ्रीका',
      'ZM': 'ज़ाम्बिया',
      'ZW': 'ज़िम्बाब्वे',
    },
    'setting': {
      'name': 'सेटिंग',
      'children': {
        'wifi': {
          'name': 'वाई-फाई',
        },
        'sharing': {
          'name': 'शेयरिंग',
          'children': {
            'configure': {
              'name': 'शेयरिंग कॉन्फिग',
            },
            'blacklist': {
              'name': 'ब्लैकलिस्ट साझा करना',
            },
            'record': {
              'name': 'शेयरिंग लॉग',
            },
            'traffic': {
              'name': 'शेयरिंग ट्रैफिक',
            },
          },
          'content': {},
        },
        'rustdesk': {
          'name': 'रस्टडेस्क',
          'children': {
            'config': {
              'name': 'कॉन्फिग',
            },
            'log': {
              'name': 'लॉग',
            },
          },
        },
        'admin': {
          'name': 'प्रशासन',
          'content': {},
          'children': {
            'changePassword': {
              'name': 'पासवर्ड',
            },
            'reboot': {
              'name': 'रीबूट',
              'content': {
                'confirmText': 'रीबूट की पुष्टि करें?',
                'rebootLoading':
                  'आपके डिवाइस को रीबूट होने में कुछ मिनट लगेंगे, इस दौरान आपका नेटवर्क कनेक्शन अस्थायी रूप से बाधित हो सकता है।',
              },
            },
          },
        },
      },
    },
  },
  // dashboard
  dashboard: {
    name: 'डैशबोर्ड',
    children: {},
    content: {
      backupTunnelLabel: 'बैकअप टनल',
      tipInput: 'कृपया इनपुट दें ',
      hotspot: 'वैश्विक कनेक्शन',
      realnetworkflow: 'वास्तविक समय यातायात सांख्यिकी',
      memory: 'मेमोरी',
      cpu: 'सीपीयू',
      notification: 'सूचनाएं',
      systeminfo: 'सिस्टम जानकारी',
      upload: 'अपलोड',
      download: 'डाउनलोड',
      systemuptime: 'सिस्टम अप-समय',
      uploadtotal: 'अपलोड का कुल',
      downloadtotal: 'डाउनलोड का कुल',
      softwareVersion: 'सॉफ़्टवेयर संस्करण',
      publicIp: 'सार्वजनिक आईपी',
      unit: 'इकाई',
      currentDpnMode: 'वर्तमान डीपीएन मोड',
      ok: 'ठीक है',
      cancel: 'रद्द करें',
      // लाइसेंस
      currentStatus: 'वर्तमान स्थिति',
      statusMap: {
        Trial: 'परीक्षण',
        Valid: 'मान्य',
        Expired: 'समाप्त हो गई',
      },
      btnActive: 'सक्रिय करें',
      btnEdit: 'संशोधित करें',
      expireDate: 'समाप्ति तिथि',
      licenseKey: 'लाइसेंस कुंजी',
      systemTraffic: 'वास्तविक समय सिस्टम यातायात सांख्यिकी',
      appTraffic: 'वास्तविक समय एप्लिकेशन यातायात सांख्यिकी',
    },
  },
  accessControl: {
    'content': {
      banner: {
        title: 'अभिगम नियंत्रण',
        description:
          'इस स्विच का उपयोग LAN डिवाइस के विभिन्न कार्यों को सक्षम या अक्षम करने को समान रूप से नियंत्रित करने के लिए किया जाता है।',
      },
      'onlineDevice': 'ऑनलाइन डिवाइस',
      'offlineDevice': 'ऑफलाइन डिवाइस',
      'deviceName': 'डिवाइस का नाम',
      'unknownDevice': 'अज्ञात',
      'macAddress': 'MAC पता',
      'ipAddress': 'IP पता',
      'enableHttpsFilter': 'HTTPS फ़िल्टर चालू करें',
      'routeMode': 'DPN मोड',
      'routeModeOptions': {
        'smart': 'स्मार्ट रूटिंग मोड',
        'full': 'पूर्ण रूटिंग',
        'disabled': 'अक्षम',
        blackHole: 'ब्लैक होल',
      },
      'remark': 'टिप्पणी',
      pinned: 'टॉप पर पिन करें',
      'httpsFilterTip': 'HTTPS फ़िल्टर चालू करने के बाद, सभी विज्ञापनों को फ़िल्टर कर दिया जाएगा।',
      'routeModeTip': 'इस विकल्प से DPN की ऑपरेटिंग मोड बदली जा सकती है।',
      'tunnelTip': 'कृपया पूर्ण रूटिंग सुरंग का चयन करें।',
      'success': {
        getList: 'डिवाइस सूची सफलतापूर्वक ताज़ा हो गई',
        'httpsFilter': 'HTTPS फ़िल्टर सफलतापूर्वक बदला गया',
        'routeMode': 'DPN मोड सफलतापूर्वक बदला गया',
        'regionCode': 'DPN मोड सफलतापूर्वक बदला गया',
        'remark': 'टिप्पणी सफलतापूर्वक सहेजी गई',
        pinned: 'सफलतापूर्वक पिन / अनपिन किया गया। कृपया ताज़ा करें।',
        'deleteDevices': 'उपकरण सफलतापूर्वक हटाया गया',
      },
      'failure': {
        'getSwitch': 'एक्सेस कंट्रोल स्विच प्राप्त करने में विफल',
        'setSwitch': 'एक्सेस कंट्रोल स्विच सेट करने में विफल',
        'getList': 'एक्सेस नियंत्रण सूची प्राप्त करने में विफल',
        'getTunnels': 'सुरंग प्राप्त करने में विफल',
        'setItem': 'एक्सेस नियंत्रण आइटम सेट करने में विफल',
        'httpsFilter': 'HTTPS फ़िल्टर बदलने में विफल',
        'routeMode': 'DPN मोड बदलने में विफल',
        'regionCode': 'DPN मोड बदलने में विफल',
        'remark': 'टिप्पणी सहेजने में विफल',
        'noSuchTunnel': 'सुरंग मौजूद नहीं है, कृपया कोई अन्य सुरंग चुनें',
        pinned: 'पिन / अनपिन करने में विफल।',
        'deleteDevices': 'उपकरण हटाने में विफल',
        'macActive': 'उपकरण सक्रिय होने के कारण हटाने में असमर्थ',
      },
      'remarkLengthLimit': 'लंबाई 10 वर्ण से अधिक नहीं है',
      'httpsNotEnabled':
        'HTTPS फ़िल्टरिंग के लिए मुख्य स्विच बंद है। इसे चालू करने के लिए इस पृष्ठ पर जाने के लिए क्लिक करें!',
      'tips': {
        'opened':
          'जब एक्सेस कंट्रोल चालू होता है, तो ऑनलाइन डिवाइस एक्सेस कंट्रोल में डीपीएन मोड का प्राथमिकता से उपयोग करते हैं।',
      },
    },
  },
  //dpnMode
  dpnMode: {
    title: 'डीपीएन मोड चुनें',
    selectTunnel: 'सुरंग चुनें',
    recommended: 'सिफारिश की गई',
    apply: 'लागू करें',
    missingInfo: 'गुम जानकारी',
    selectionTip: {
      smart: 'क्या आप स्मार्ट रूट मोड में स्विच करना चाहते हैं?',
      full: 'सभी यातायात चयनित सुरंग के माध्यम से होगा। क्या आप पूर्ण रूट मोड में स्विच करना चाहते हैं?',
      disabled: 'डीपीएन के माध्यम से कोई यातायात नहीं होगा। क्या आप डीपीएन अक्षम मोड में स्विच करना चाहते हैं?',
    },
    dpnModeShortList: {
      smart: 'डीपीएन स्मार्ट रूट',
      disabled: 'डीपीएन अक्षम',
    },
    dpnModeLongList: {
      smart: 'स्मार्ट रूट',
      full: 'पूर्ण रूट',
      disabled: 'अक्षम',
    },
    killSwitchLabel: 'किल स्विच',
    none: 'अक्षम करें',
    backupTunnelLabel: 'बैकअप सुरंग',
    backupTunnelTip:
      'जब स्मार्ट रूटिंग मोड में कॉन्फ़िगर की गई सुरंग के साथ कोई समस्या होती है, तो सिस्टम स्वचालित रूप से बैकअप सुरंग पर स्विच करेगा जब तक कि डिफ़ॉल्ट सुरंग सामान्य कार्य करना शुरू नहीं कर देती।',
    tips: {
      killSwitch:
        'एक VPN किल स्विच एक सुविधा है जो आपके कंप्यूटर, फ़ोन, या टैबलेट को इंटरनेट से स्वत: डिस्कनेक्ट करती है यदि आपका VPN कनेक्शन बाहर निकल जाता है। इसका मतलब है कि अगर आपका VPN काम करना बंद कर देता है, तो आपके आईपी एड्रेस और अन्य संवेदनशील जानकारी का संभावना नहीं है कि वह संभावना हो सकती है। इसलिए आपके इंटरनेट कनेक्शन की सुरक्षा और गुमनामी संभावना पर प्रभाव नहीं पड़ेगा।',
      smart: [
        '1. AtomOS में निर्मित नियमों के अनुसार, वर्तमान में पहुंचे गए वेबसाइटों के लिए बुद्धिमान मिलान नोड्स निर्धारित किए जाते हैं, जो अन्य देशों और क्षेत्रों में सेवाओं की पहुंच को तेज करते हैं जबकि स्थानीय नेटवर्क की गति सुनिश्चित करते हैं।',
        '2. "डीपीएन स्मार्ट रूट" मोड में, यह न केवल पारंपरिक वीपीएन्स की तरह निरंतर देशों को स्विच करने की परेशानी को दूर करता है, बल्कि यह उपयोगकर्ताओं को तेज नेटवर्क ब्राउज़िंग अनुभव भी प्रदान करता है।',
        '3. डीपर कनेक्ट में कुछ प्रसिद्ध वेबसाइटों और सेवाओं के लिए अंतर्निहित रूटिंग नियम हैं। यदि आप स्मार्ट रूटिंग के नियमों को अनुकूलित करना चाहते हैं, तो आप "ऐप रिलोकेटर" और "डीपीएन - रूटिंग" में उन्हें कॉन्फ़िगर कर सकते हैं।',
        '4. हम उपयोगकर्ता प्रतिक्रिया के आधार पर अपने अंतर्निहित रूटिंग नियमों को बढ़ाने का निरंतर प्रयास करेंगे और "ऐप रिलोकेटर" सुविधा में अधिक अनुप्रयोगों के लिए समर्थन विस्तारित करेंगे।',
      ],
      full: [
        '1. सभी नेटवर्क डेटा जो AtomOS के माध्यम से संसाधित होता है, वह निर्दिष्ट सुरंग के माध्यम से रूट होगा, जो पारंपरिक वीपीएन्स के उपयोग पैटर्न के समान है।',
        '2. ध्यान दें: "डीपीएन पूर्ण रूट" मोड को अपनाने से "ऐप रिलोकेटर" और "डीपीएन - रूटिंग" में आपके द्वारा किए गए कॉन्फ़िगरेशन को ओवरराइड कर देगा।',
        '3. नेटवर्क की विकेंद्रीकृत प्रकृति के कारण, नेटवर्क में नोड्स दुनिया भर के उपयोगकर्ता उपकरणों से आते हैं, न कि उच्च-गति सर्वरों। इसके परिणामस्वरूप, आपकी इंटरनेट गति प्रभावित हो सकती है।',
        '4. यदि आप "डीपीएन पूर्ण रूट" मोड का उपयोग करने पर अड़े रहते हैं और एक उच्च-गति इंटरनेट अनुभव की इच्छा रखते हैं, तो हम भविष्य में वीआईपी उपयोगकर्ताओं के लिए विशिष्ट उच्च-गति नोड्स प्रदान करने पर विचार करेंगे।',
      ],
      disabled: [
        '1. "डीपीएन अक्षम" मोड डीपीएन सुविधा को बंद कर देगा, जिसके परिणामस्वरूप AtomOS के माध्यम से संसाधित सभी नेटवर्क डेटा स्थानीय यातायात का उपयोग करेगा।',
        '2. डीपर कनेक्ट की अन्य नेटवर्क सुरक्षा सुविधाएं सक्रिय रहेंगी।',
        '3. यदि आप किसी वेबसाइट की पहुंच में बाधा सामना कर रहे हैं, तो आप डीपीएन को अक्षम करके यह निर्धारित कर सकते हैं कि समस्या वेबसाइट के साथ है या डीपीएन सुविधा के साथ।',
        '4. यदि आपको डीपीएन सुविधा के साथ किसी भी समस्या का सामना करना पड़ता है, तो कृपया निम्नलिखित लिंक का उपयोग करके उन्हें रिपोर्ट करने में स्वतंत्र महसूस करें: https://support.deeper.network/hc/en-us/requests/new',
      ],
    },
    success: 'संचालन सफल',
    failure: 'संचालन विफल!',
  },
  // DefaultadBlock
  DefaultadBlock: {
    'browserCacheWarning':
      'यदि DNS विज्ञापन फ़िल्टर सक्षम होने के बावजूद विज्ञापन को ठीक से फ़िल्टर नहीं किया जा सकता है, तो ब्राउज़र कैश को साफ़ करें और फ़िल्टर किए गए उपकरण को पुनः आरंभ करें।',
    'queriesFor24Hours': 'पिछले 24 घंटों के क्वेरी',
    'totalQuery': 'कुल क्वेरी',
    'blockedQuery': 'अवरोधित क्वेरी',
    'blockedPercentage': 'अवरोधित प्रतिशत',
    'ruleTotal': 'नियम',
    'adQuery': 'विज्ञापन को अवरोधित करें',
    'trackerQuery': 'ट्रैकर को अवरोधित करें',
    'maliciousQuery': 'खतरनाक को अवरोधित करें',
    'pornQuery': 'अश्लील सामग्री को अवरोधित करें',
    'legendTotal': 'कुल',
    'legendBlocked': 'अवरोधित',
    'categoryStateEnabled': 'सक्षम',
    'categoryStateDisabled': 'अक्षम',
    'categoryState2Hours': '2 घंटे के लिए अक्षम',
    'categoryState4Hours': '4 घंटे के लिए अक्षम',
    'categoryState8Hours': '8 घंटे के लिए अक्षम',
    'setCategoryStatesErr': 'फ़िल्टर स्थिति सेट करने में त्रुटि',
    'enableAll': 'सभी फ़िल्टर सक्षम करें',
    'disableAll': 'सभी फ़िल्टर अक्षम करें',
  },
  // CustomadBlock
  CustomadBlock: {
    missingInfoWarning: 'डोमेन गायब है',
    invalidDomainError: 'डोमेन का स्वरूप अमान्य है या डोमेन बहुत लंबा है',
    whitelist: 'श्वेत सूची',
    blacklist: 'काली सूची',
    domain: 'डोमेन',
    whitelistAddSuccess: 'सफलतापूर्वक श्वेत सूची में जोड़ा गया',
    whitelistEntryExist: 'प्रविष्टि पहले से ही श्वेत सूची में है',
    reachedWhiteMax: 'श्वेत सूची की अधिकतम सीमा तक पहुंच गया',
    whitelistAddError: 'श्वेत सूची में जोड़ने में विफल',
    blacklistAddSuccess: 'सफलतापूर्वक काली सूची में जोड़ा गया',
    blacklistEntryExist: 'प्रविष्टि पहले से ही काली सूची में है',
    reachedBlackMax: 'काली सूची की अधिकतम सीमा तक पहुंच गया',
    blacklistAddError: 'काली सूची में जोड़ने में विफल',
    whitelistDelSuccess: 'श्वेत सूची की प्रविष्टियां सफलतापूर्वक हटाई गईं',
    whitelistDelError: 'श्वेत सूची की प्रविष्टियां हटाने में विफल',
    blacklistDelSuccess: 'काली सूची की प्रविष्टियां सफलतापूर्वक हटाई गईं',
    blacklistDelError: 'काली सूची की प्रविष्टियां हटाने में विफल',
    import: 'आयात',
    fileButtonText: 'अपलोड करने के लिए क्लिक करें',
    fileSizeOverLimit: 'अपलोड की गई फ़ाइल का आकार 2MB की सीमा से अधिक है',
    whitelistImportSuccess: 'श्वेत सूची सफलतापूर्वक आयात की गई, {0} प्रविष्टियां आयात की गईं',
    whitelistImportError: 'श्वेत सूची का आयात करने में विफल',
    blacklistImportSuccess: 'काली सूची सफलतापूर्वक आयात की गई, {0} प्रविष्टियां आयात की गईं',
    blacklistImportError: 'काली सूची का आयात करने में विफल',
    noValidEntry: 'अपलोड की गई फ़ाइल में कोई मान्य डोमेन प्रविष्टि नहीं है',
    zeroImported: 'किसी भी डोमेन प्रविष्टि का आयात करने में विफल',
  },
  // DohGuideComp
  DohGuideComp: {
    closeButton: 'बंद करें',
    guideButton: 'देखें',
    guideBoxTitle: 'DoH ब्राउज़र सेटिंग',
    dohMessageClickTips: '"देखें" पर क्लिक करें ताकि आपके विशेष ब्राउज़र के लिए DoH को बंद करने के निर्देश देख सकें।',
    dohMessageTxt: `इस सुविधा का उपयोग करने के लिए, {0}. DoH DNS अनुरोधों को एन्क्रिप्ट करता है और कुछ कार्यक्षमताओं के साथ हस्तक्षेप का कारण बन सकता है। `,
    dohMessage0: 'कृपया अपने ब्राउज़र में DoH सेटिंग को अक्षम करें',
    guide: {
      chromeGuideImg: '/dohGuide/chrome-guide-en.png',
      chrome: [
        {
          label: 'कदम 1',
          tip: 'पता पट्टी में chrome://settings/security टाइप करें और Enter दबाएं।',
        },
        { label: 'कदम 2', tip: '"उन्नत सुरक्षा सेटिंग्स" खंड को खोजें।' },
        { label: 'कदम 3', tip: '"सुरक्षित DNS का उपयोग करें" विकल्प को अक्षम करें।' },
      ],
      firefox: [
        {
          label: 'कदम 1',
          tip: 'पता पट्टी में about:config टाइप करें और Enter दबाएं।',
        },
        {
          label: 'कदम 2',
          tip: 'अगर एक चेतावनी पृष्ठ दिखाई देता है, तो "जोखिम स्वीकार करें और जारी रखें" पर क्लिक करें।',
        },
        { label: 'कदम 3', tip: 'खोज बॉक्स में network.trr.mode टाइप करें और Enter दबाएं।' },
        {
          label: 'कदम 4',
          tip: 'खोज परिणाम पर डबल-क्लिक करें, मूल्य को 5 (DoH अक्षम करें) में बदलें, फिर "ठीक" पर क्लिक करें।',
        },
      ],
      edge: [
        {
          label: 'कदम 1',
          tip: 'पता पट्टी में edge://settings/privacy टाइप करें और Enter दबाएं। फिर "सुरक्षा" खंड खोजें।',
        },
        {
          label: 'कदम 2',
          tip: '"दुर्भावनापूर्ण गतिविधि और भ्रामक के खिलाफ सुरक्षित DNS का उपयोग करें" विकल्प को बंद करें।',
        },
      ],
      operaGuideImg: '/dohGuide/opera-guide-en.png',
      opera: [
        {
          label: 'कदम 1',
          tip: 'ब्राउज़र पता पट्टी में opera://settings/?search=DNS दर्ज करें और Enter दबाएं।',
        },
        {
          label: 'कदम 2',
          tip:
            '"सिस्टम की DNS सेटिंग्स के बजाय DNS-over-HTTPS का उपयोग करें" विकल्प खोजें और सुनिश्चित करें कि यह बंद है।',
        },
      ],
    },
  },
  // appRelocatorData
  appRelocatorData: {
    guide: {
      youtube: {
        UA: 'https://www.deeper.network/pages/app-relocator?app=youtube&lang=zh',
      },
      nbaLeaguePass: 'https://www.deeper.network/pages/app-relocator?app=nbaLeaguePass&lang=zh',
      netflix: 'https://www.deeper.network/pages/app-relocator?app=netflix&lang=zh',
      dazn: 'https://www.deeper.network/pages/app-relocator?app=dazn&lang=zh',
      fubo: 'https://www.deeper.network/pages/app-relocator?app=fubo&lang=zh',
      tencentVideo: 'https://www.deeper.network/pages/app-relocator?app=tencentVideo&lang=zh',
    },
    'countryLabel': 'देश',
    'tagLabel': 'टैग',
    'typeLabel': 'प्रकार',
    'country': {
      'all-countries': 'सभी देश',
      'CN': 'चीन',
      'US': 'अमेरिका',
      'IN': 'भारत',
      'CA': 'कनाडा',
      'GB': 'यूनाइटेड किंगडम',
      'NA': 'अन्य',
      'NO': 'नॉर्वे',
      'SG': 'सिंगापुर',
      'JP': 'जापान',
    },
    'tag': {
      'all-tags': 'सभी टैग',
      'nba': 'एनबीए',
      'nfl': 'एनएफएल',
      'nhl': 'एनएचएल',
      'mlb': 'एमएलबी',
      'epl': 'ईपीएल',
      'ipl': 'आईपीएल',
    },
    'category': {
      'all-category': 'सभी प्रकार',
      'video': 'वीडियो',
      'music': 'संगीत',
      'im': 'तत्काल संवाद',
      'flightAndHotel': 'उड़ान और होटल',
      'subscription': 'अंशदान',
    },
    'filter': 'फ़िल्टर',
    'reset': 'रीसेट',
    'submit': 'पूर्ण',
    'subcategory': {
      'popular': 'लोकप्रिय',
      'sport': 'खेल',
      'movie': 'फिल्म',
      'tv': 'टीवी',
    },
    app: {
      'acornTv': 'बलूत का फल टीवी',
      'amazonPrimeVideo': 'अमेज़न प्राइम वीडियो',
      'beInSports': 'बी इन स्पोर्ट्स',
      'crunchyroll': 'क्रंचीरोल',
      'dazn': 'डाज़न',
      'disneyPlus': 'डिज़्नी+',
      'netflix': 'नेटफ्लिक्स',
      'plex': 'प्लेक्स',
      'roku': 'रोकू',
      'shudder': 'शडर',
      'zattoo': 'ज़ाटो',
      'discoveryPlus': 'डिस्कवरी+',
      'paramountPlus': 'पैरामाउंट+',
      'sportsnetNow': 'स्पोर्ट्सनेट अब',
      'tsn': 'टीएसएन',
      'bbcIPlayer': 'बीबीसी आईप्लेयर',
      'hulu': 'हूलू',
      'itv': 'आईटीवी',
      'slingTv': 'स्लिंग टीवी',
      'crackle': 'क्रैकल',
      'pandora': 'पैंडोरा',
      'theCw': 'दी सीडब्ल्यू',
      'abc': 'एबीसी',
      'nbc': 'एनबीसी',
      'pluto': 'प्लूटो टीवी',
      'starz': 'स्टार्ज़',
      'tbs': 'टीबीएस',
      'attTv': 'एटीटी टीवी',
      'channel4': 'चैनल 4',
      'showtimeAnytime': 'शोटाइम एनीटाइम',
      'nbcSports': 'एनबीसी स्पोर्ट्स',
      'epixNow': 'एपिक्स अब',
      'mgmPlus': 'एमजीएम प्लस',
      'xumo': 'ज़ूमो',
      'channel5': 'चैनल 5',
      'usaNetwork': 'यूएसए नेटवर्क',
      'fxNow': 'एफ़एक्स अब',
      'vudu': 'वूडू',
      'fubo': 'फ़ूबो',
      'espn': 'ईएसपीएन',
      'tubiTv': 'ट्यूबी टीवी',
      'ukTvPlay': 'यूके टीवी प्ले',
      'tvPlayer': 'टीवी प्लेयर',
      'peacock': 'पीकॉक',
      'pbs': 'पीबीएस',
      'philo': 'फ़िलो',
      'vrv': 'वीआरवी',
      'showmax': 'शोमैक्स',
      'hboMax': 'एचबीओ मैक्स',
      'nhl': 'एनएचएल',
      'dsTv': 'डीएस टीवी',
      'sSportsPlus': 'एस स्पोर्ट्स+',
      'filmrise': 'फिल्मराइज़',
      'cbsSports': 'सीबीएस स्पोर्ट्स',
      'nowTV': 'नाउ टीवी',
      'molotovTv': 'मोलोटॉव टीवी',
      'mlb': 'एमएलबी',
      'britBox': 'ब्रिटबॉक्स',
      'stirr': 'स्टर',
      'skyGo': 'स्काई गो',
      'hotstar': 'हॉटस्टार',
      'sonyLiv': 'सोनी लिव',
      'nbaLeaguePass': 'एनबीए लीग पास',
      'amcPlus': 'एएमसी+',
      'f1Tv': 'एफ1 टीवी',
      'frndlyTv': 'फ्रेंडली टीवी',
      'myTf1': 'माय टीएफ1',
      'qqMusic': 'क्यूक्यू संगीत',
      'spotify': 'स्पॉटिफ़ाई',
      'tidal': 'टाइडल',
      'youtube': 'यूट्यूब',
      'youTubeMusic': 'यूट्यूब संगीत',
      'weChat': 'वीचैट',
      'whatsApp': 'व्हाट्सएप',
      'line': 'लाइन',
      'signal': 'सिग्नल',
      'agoda': 'अगोडा',
      'expedia': 'एक्सपीडिया',
      'flyScanner': 'फ्लाई स्कैनर',
      'googleFlights': 'गूगल फ्लाइट्स',
      'qqSports': 'क्यूक्यू स्पोर्ट्स',
      'skySports': 'स्काई स्पोर्ट्स',
      'rakutenTv': 'राकुटेन टीवी',
      'fox': 'फ़ॉक्स',
      'nflNetwork': 'एनएफएल नेटवर्क',
      'peacocktv': 'पीकॉक टीवी',
      'zee5': 'ज़ी5',
      'mxPlayer': 'एमएक्स प्लेयर',
      'tencentVideo': 'टेंसेंट वीडियो',
      'iqiyi': 'आईक्यूआई',
      'mangoTV': 'मैंगो टीवी',
      'migu': 'मिगू वीडियो',
      'bilibili': 'बिलिबिलि',
      'youku': 'यूकू',
      'altBalaji': 'एलटी बालाजी',
      'willowTv': 'विलो टीवी',
      'foxSports': 'फ़ॉक्स स्पोर्ट्स',
      'crave': 'क्रेव',
      'cbcGem': 'सीबीसी जेम',
      'jiocinema': 'जियोसिनेमा',
      'Telegram': 'टेलीग्राम',
      'Messenger': 'मैसेंजर',
      'ChatGPT': 'चैटजीपीटी',
      'CanalPlus': 'कैनालप्लस',
    },
    tip: {
      'video': 'वीडियो सेवा के लिए उपयोग किए जाने वाले वर्तमान टनल',
      'music': 'संगीत सेवा के लिए उपयोग किए जाने वाले वर्तमान टनल',
      'im': 'तत्काल संवाद सेवा के लिए उपयोग किए जाने वाले वर्तमान टनल',
      'flightAndHotel': 'उड़ान और होटल बुक करने के लिए उपयोग किए जाने वाले वर्तमान टनल',
      'subscription': 'ऑनलाइन सदस्यता सेवा के लिए उपयोग किए जाने वाले वर्तमान टनल',
      'backupTunnel':
        'प्रतिलिपि टनल सक्रिय हो गया है, इंटेलीजेंट राउटिंग मोड में जोड़े गए टनल के पास कोई उपयुक्त नोड नहीं है, तो सिस्टम डिफ़ॉल्ट रूप से प्रतिलिपि टनल का उपयोग करेगा - {0}',
      'fullTunnel': 'पूर्ण मार्ग मोड में, सभी ऐप्लिकेशन डिफ़ॉल्ट रूप से {0} टनल का उपयोग करेंगे।',
      'noTunnelNodes':
        'अंक की संख्या 0 है, उपयोग नहीं कर सकते। कृपया टनल पृष्ठ पर जाएं और {0} टनल के नोड को रीफ़्रेश करें।',
      'noTunnelNodesOkText': 'रीफ़्रेश करें',
      'noTunnelNodesCancelText': 'बंद करें',
      'noTunnelAdded': 'आपने इस टनल को अभी तक जोड़ा नहीं है। कृपया टनल पृष्ठ पर जाएं और {0} टनल को जोड़ें।',
      'noTunnelAddedOkText': 'जोड़ें',
      'noTunnelAddedCancelText': 'रद्द करें',
      'tunnelDeletedText': '{0} - {1} टनल हटा दिया गया है',
      'netflix': 'Netflix का उपयोग करते समय आपको प्ले त्रुटि को कैसे ठीक करें',
      'fubo': 'Fubo TV आपको इंग्लैंड फुटबॉल की लाइव प्रसारण को बहुत कम खर्च में देखने की सुविधा प्रदान करता है',
      'tencentVideo': 'एक NBA मैच को लगभग $1 में कैसे देखें',
      'dazn': 'DAZN आपको बहुत कम कीमत पर केवल $17.18 पर एनएफएल, एनएफएल, एमएलबी, एनबीए, ईपीएल देखने की अनुमति देता है।',
      'youtube': 'बहुत ही कम कीमत में YouTube प्रीमियम का आनंद कैसे लें',
    },
    intro: {
      'acornTv':
        'एकोर्न टीवी ब्रिटेन और उसके बाहर से विश्व स्तरीय रहस्य, नाटक और कॉमेडी स्ट्रीम करता है। किसी क्लासिक सीरीज़ को बार-बार देखें या इनमें से अपना नया पसंदीदा शो खोजें...',
      'abc': 'ABC.com पर ABC शो को ऑनलाइन देखें। अनन्य वीडियो और मुफ्त शो प्राप्त करें।',
      'f1Tv':
        'F1 TV अब वेब, ऐप, Google TV, Apple TV, Roku और Amazon Fire TV पर उपलब्ध है। सभी F1 रेस को लाइव स्ट्रीम करें। सभी ग्रैंड प्री ट्रैनिंग को लाइव स्ट्रीम करें।',
      'youtube':
        'YouTube की आधिकारिक चैनल आपको वैश्विक नवीनतम और लोकप्रिय सामग्री के बारे में जानने में मदद करता है। संगीत, संस्कृति और इंटरनेट प्रभाव जैसे विभिन्न क्षेत्रों की आवश्यक वीडियो देखें।',
      'netflix':
        'नेटफ्लिक्स एक स्ट्रीमिंग सेवा है जो हजारों इंटरनेट से जुड़े पुरस्कार विजेता टीवी शो, फिल्में, एनीमे, वृत्तचित्र और बहुत कुछ प्रदान करती है...',
      'fubo':
        'अपने टीवी पर फ़ुबो देखने का सबसे आसान तरीका Roku, Apple TV, Amazon Fire TV या Chromecast है। स्ट्रीम करने के लिए बस अपने डिवाइस पर फ़ुबो ऐप इंस्टॉल करें...',
      'disneyPlus':
        'नए रिलीज़ किए गए कार्य से लेकर आपकी पसंदीदा क्लासिक कार्य तक, पश्चिमी और पूर्वी विचारधारा सभी आपके लिए है। जो आपकी उम्मीद से अधिक है और आपने कभी सोचा नहीं था।',
      'crunchyroll':
        "Crunchyroll के माध्यम से शीर्ष एनिमे और मैंगा स्ट्रीमिंग सामग्री की खोज करें। 'डेमन स्लेयर', 'वन पीस', 'अटैक ऑन टाइटन' और 'माई हीरो एकेडमिया' जैसे लोकप्रिय कार्यों का आनंद लें।",
      'hulu':
        "टीवी शो और फिल्मों को ऑनलाइन देखें। 'ग्रे'ज एनेटॉमी', 'दिस इज़ अस', 'बॉब्स बर्गर्स', 'ब्रुकलिन नाइन-नाइन', 'इंपीर' और 'सटरडे नाइट लाइव' जैसे लोकप्रिय टीवी शो और प्रसिद्ध फिल्मों को स्ट्रीम करें।",
      'nbaLeaguePass':
        'NBA League Pass * की सदस्यता बास्केटबॉल प्रशंसकों को पूरे सीजन और ऑफ़-सीजन में मैच की लाइव स्ट्रीमिंग, वीडियो ऑन डिमांड और अधिक सामग्री तक पहुंच प्रदान करती है।',
      'peacock':
        'पीकॉक प्राप्त करें और लाइव स्पोर्ट्स, एक्सक्लूसिव ओरिजिनल, हिट शो और ब्लॉकबस्टर फिल्में स्ट्रीम करें। शीर्ष हिट, क्लासिक पसंदीदा, लाइव एक्शन और बहुत कुछ, अब पीकॉक पर स्ट्रीमिंग। वृत्तचित्र. बच्चों की फिल्में और शो। मयूर चैनल 24/7। हॉलमार्क चैनल.',
      'qqSports':
        'टेंसेंट स्पोर्ट्स चीन की एक प्रमुख खेल पोर्टल वेबसाइट है, जो आपको निम्नलिखित क्षेत्रों की मुख्य खबरें प्रदान करता है: आंतरजातीय फुटबॉल, अंतर्राष्ट्रीय फुटबॉल, एनबीए, सीबीए, संयुक्त खेल, ओलंपिक, लाइव स्ट्रीम, लॉटरी, जुआघर आदि...',
      'rakutenTv':
        'लाइव टीवी | शीर्ष 15 चैनल मुफ़्त · मुफ़्त | मुफ़्त में शीर्ष फ़िल्में · स्टोर | खरीदने या किराए पर लेने के लिए सबसे लोकप्रिय फिल्में · शैलियाँ · मुफ़्त | हाल ही में मुफ़्त में जोड़ा गया · मुफ़्त | राकुटेन',
      'espn':
        'लाइव स्कोर, हाइलाइट्स और खेल समाचारों के लिए ईएसपीएन पर जाएं। ईएसपीएन+ पर विशेष गेम स्ट्रीम करें और फंतासी खेल खेलें।',
      'beInSports':
        'beIN SPORTS USA के माध्यम से खेल दुनिया की पर्दा उठाएं। मुख्य फुटबॉल लीग आदि के अनन्य दिलचस्प पुनरावृत्ति, ताजगी समाचार, डेटा, स्कोर और शेड्यूल प्राप्त करें।',
      'dazn': 'DAZN दुनिया की पहली सही मायने में समर्पित लाइव स्पोर्ट्स स्ट्रीमिंग है...',
      'cbsSports':
        'मुफ़्त 24 घंटे का खेल समाचार नेटवर्क। सीबीएस स्पोर्ट्स मुख्यालय देखें। खेल के बारे में जो कुछ भी मायने रखता है उसे प्रदान करना। अधिक हाइलाइट्स, शीर्ष दांव और जीतने की काल्पनिक सलाह।',
      'fox':
        'फॉक्स स्पोर्ट्स देखें और एनएफएल, एमएलबी, नासकार, डब्ल्यूडब्ल्यूई, एनबीए, एनएचएल, को कवर करने वाले लाइव स्कोर, ऑड्स, टीम समाचार, खिलाड़ी समाचार, स्ट्रीम, वीडियो, आंकड़े, स्टैंडिंग और शेड्यूल देखें।',
      'nflNetwork':
        'एनएफएल चैनल की जानकारी प्राप्त करें, अपडेट दिखाएं और बहुत कुछ! एनएफएल नेटवर्क एनएफएल के स्वामित्व और संचालन वाला एकमात्र साल भर चलने वाला नेटवर्क है।',
      'peacocktv':
        'NBC.com पर मौजूदा और क्लासिक NBC शोज़ के पूरे एपिसोड देखें। साथ ही, आप कटौती, ट्रेलर, फ़ोटो और विशेष ऑनलाइन फ़ीचर्स भी ढूंढ सकते हैं।',
      'sportsnetNow':
        'एमएलबी के शीर्ष नाटक · एमएलबीस्पोर्ट्सनेट वीडियोब्लू जेज़ के स्प्रिंगर ने लीडऑफ़ ओप्पो-टैको डिंगर के साथ बड़े पैमाने पर अपना जन्मदिन मनाया · एमएलबीस्पोर्ट्सनेट वीडियोब्रेव्स के एक्यूना जूनियर।',
      'nbcSports': 'एनबीसी स्पोर्ट्स से नवीनतम खेल समाचार और स्कोर के साथ अपडेट रहें।',
      'tsn':
        'ऑस्ट्रेलियन ओपन, एनएफएल प्लेऑफ़, सुपर बाउल, एनएचएल ट्रेड डेडलाइन के लिए नवीनतम खेल समाचार कवरेज, स्कोर, हाइलाइट्स और कमेंट्री प्राप्त करने के लिए टीएसएन पर जाएँ।',
      'nhl': 'समाचार, रोस्टर, आँकड़े, शेड्यूल, टीमें और वीडियो सहित आधिकारिक राष्ट्रीय हॉकी लीग वेबसाइट।',
      'hotstar':
        'डिज़्नी+ हॉटस्टार भारत का सबसे बड़ा प्रीमियम स्ट्रीमिंग प्लेटफ़ॉर्म है, जिसमें 17 भाषाओं में 100000 घंटे से अधिक नाटक और फिल्में हैं, और हर प्रमुख का कवरेज है ...',
      'disneyPlus':
        'नए रिलीज़, आपके पसंदीदा क्लासिक, भूतकाल, वर्तमान और भविष्य आपके हैं। जो कुछ आपने उम्मीद की थी, और जो कुछ आपने नहीं। © 2023 डिज़्नी। ब्रांड: मार्वल, पिक्सार, डिज़्नी, नेशनल ज्योग्राफिक, स्टार वॉर्स।',
      'skySports':
        'आपकी पसंदीदा खेल की श्रेष्ठ लाइव कवरेज देखें: फ़ुटबॉल, गोल्फ, रग्बी, क्रिकेट, एफ़-1 रेसिंग, मुक्केबाज़ी, अमेरिकी फ़ुटबॉल, बास्केटबॉल, और नवीनतम स्पोर्ट्स न्यूज़ ...',
      'sonyLiv':
        'शीर्ष प्रीमियम स्ट्रीमिंग प्लेटफ़ॉर्म, जहां आप प्रमुख टीवी शो, फ़िल्में, स्पोर्ट्स कार्यक्रम और वेब सीरीज़ देख सकते हैं। अपने पसंदीदा लाइव टेलीविजन चैनल का ऑनलाइन देखने का आनंद लें।',
      'jiocinema':
        'नवीनतम फ़िल्में, टीवी शो, Voot शो, लाइव स्पोर्ट्स इवेंट्स और बेहतरीन क्लिप्स देखें, भारतीय भाषा, अंग्रेजी और अन्य क्षेत्रीय भाषाओं में विभिन्न श्रेणियों की ऑनलाइन देखें।',
      'amazonPrimeVideo':
        'मूवीज़, शो, अवार्ड-विजेता अमेज़न ऑरिजिनल सामग्री, प्रीमियम चैनल, स्पोर्ट्स इवेंट्स आदि को स्ट्रीम करें। लता जीवन के लिए नि: शुल्क परीक्षण का उपयोग करें, जहां आप लता जीवन के टीवी शो, बड़ी फ़िल्में, लोकप्रिय शो आदि देख सकते हैं। 4K+HDR समर्थित सामग्री। लोकप्रिय शो और फ़िल्में। नवीनतम रिलीज़ की सामग्री। किसी भी उपकरण पर देखें।',
      'zee5':
        'अब आप अपनी पसंदीदा देसी सामग्री को ZEE5™ पर 50% की अनन्य छूट के साथ खरीद सकते हैं। तुरंत खरीदें और ZEE5™ पर अपनी पसंदीदा सामग्री का आनंद लें। वार्षिक पैकेज पर 50% की छूट का लाभ उठाएं। खरीदें लिंक। 4000 से अधिक सुपरहिट फिल्में, 1600 से अधिक शो और 260+ मूल उत्पादों का समर्थन करें। 18 भाषाओं का समर्थन किया गया है।',
      'mxPlayer':
        'MX Player भारत की सर्वोच्च OTT सेवा है, जहां पर आप नवीनतम संगीत वीडियो, नई और पुरानी फिल्में, टीवी शो, कॉमेडी शो, पॉपुलर गाने आदि देख सकते हैं।',
      'tencentVideo':
        'टेंसेंट वीडियो चीन की प्रमुख ऑनलाइन वीडियो मीडिया प्लेटफॉर्म बनाने के लिए प्रतिबद्ध है, जिसमें समृद्ध सामग्री, अत्यंत देखने का अनुभव, सुविधाजनक लॉगिन तरीके, 24 घंटे कई प्लेटफॉर्मों पर बिना रुकावट एप्लिकेशन अनुभव और त्वरित साझा करने की विशेषताएं हैं।',
      'iqiyi':
        'आईक्यूआई (iQIYI.COM) एक बड़ी वीडियो वेबसाइट है जिसमें बहुत सारी, उच्च गुणवत्ता और एचडी वीडियो हैं। यह एक पेशेवर ऑनलाइन वीडियो प्लेबैक प्लेटफॉर्म है। आईक्यूआई फिल्म, टीवी शो, कार्टून, विविधता, जीवन शैली आदि को शामिल करता है।',
      'youku': 'योकू - अच्छी सामग्री के लिए पूरी ताकत से काम करें - ऑनलाइन उच्च गुणवत्ता वीडियो देखें।',
      'migu':
        'मीगू वीडियो बीजिंग विंटर ओलंपिक और अन्य विश्वव्यापी खेल की पूरी कवरेज करता है, सीबीए लाइव, एशियाई चैंपियंस लीग, ला लिगा, बुंडेसलीगा, सीरी ए, यूईएफसी, यूएफसी लाइव, महिला वॉलीबॉल, पिंग पोंग, इस्केटिंग, स्नूकर, डब्ल्यूडब्ल्यूई, यूएफसी लाइव आदि।',
      'mangoTV':
        'Mango TV - वह ऑनलाइन वीडियो वेबसाइट जिसे सभी देख रहे हैं - लोकप्रिय वेअरिटी शो, नवीनतम फिल्में और टीवी नाटकों को ऑनलाइन देखें।',
      'nbc':
        'NBC.com पर वर्तमान और क्लासिक NBC शो के पूरे एपिसोड देखें। साथ ही, आप क्लिप्स, ट्रेलर, फ़ोटो और विशेष ऑनलाइन सामग्री भी खोज सकते हैं।',
      'foxSports':
        'फॉक्स स्पोर्ट्स देखें और लाइव स्कोर, ओड्ड्स, टीम समाचार, खिलाड़ी समाचार, लाइव स्ट्रीम, वीडियो, सांख्यिकी, रैंकिंग और शेड्यूल देखें। NFL, MLB, NASCAR, WWE, NBA, NHL जैसे खेलों को कवर करता है।',
      'nfl':
        'NFL चैनल की जानकारी, प्रोग्राम अपडेट आदि प्राप्त करें! NFL नेटवर्क NFL द्वारा संचालित एकमात्र वार्षिक संचालन नेटवर्क है।',
      'willowTv':
        'सफलतापूर्वक लॉगिन करने के बाद, विलो पर लाइव स्ट्रीम, हाइलाइट्स और पुनरावृत्ति देख सकते हैं। स्पेक्ट्रम (स्पेक्ट्रम), ऑप्टिमम (ओप्टिमम), फिओस (फिओस), डिश (डिश), ऑप्टिमम टीवी (ओप्टिमम टीवी), एक्सफिनिटी (कॉमकास्ट) और टाइम वॉर्नर (टाइम वॉर्नर) जैसे सेवा प्रदाता उपयोग कर सकते हैं।',
      'mlb':
        'हमारी रिपोर्टिंग में ऑडियो और वीडियो क्लिप, साक्षात्कार, सांख्यिकी, शेड्यूल और एक्सक्लूसिव रिपोर्टिंग स्टोरी शामिल है।',
      'hboMax':
        'HBO Max एक आलावा स्ट्रीमिंग प्लेटफ़ॉर्म है, जो सभी HBO सामग्री को लेकर और अधिक पसंदीदा टीवी शो, बड़ी फिल्में और नई Max Originals को एक साथ बांधता है, जो हर उम्र के लोगों के लिए उपयुक्त है।',
      'amcPlus':
        'आम से हटकर, AMC+ पर और अद्वितीय, रोमांचकारी अनुभव खोजें। एक्सक्लूसिव सामग्री, दिलचस्प फिल्में और क्लासिक सीरीज - AMC+ के इपिक सीरीज में सब कुछ पाएं। प्रीमियम ड्रामा फ़िल्में। ऑन-डिमांड सेवाएं। एक्सक्लूसिव सामग्री। एक्सक्लूसिव फ़िल्में। नई सीरीज। बिना विज्ञापन।',
      'attTv':
        'DIRECTV और गृह इंटरनेट समेत हमारे सर्वोत्तम टेलीविजन और इंटरनेट ऑफ़र प्राप्त करें, जिसमें AT&T फाइबर भी शामिल है।',
      'britBox':
        'यहाँ ब्रिटबॉक्स पर ब्रिटिश टेलीविजन शोज़ का बड़ा संग्रह शामिल है, जिसमें सस्पेंस, कॉमेडी, ड्रामा, डॉक्यूमेंटरी, लाइफस्टाइल आदि कई प्रकार की श्रेणियाँ हैं। यह अब तक का सबसे बड़ा ब्रिटिश टेलीविजन शो स्ट्रीमिंग संग्रह है।',
      'crackle':
        'क्रैकल पर हॉलीवुड फिल्में मुफ्त में देखें- बिना काट-छांट और बिना संपादित। एक्शन, हॉरर, साइंस-फिक्शन, क्राइम और कॉमेडी जैसी आपकी पसंदीदा शैलियों से। बस प्ले दबाएँ.',
      'discoveryPlus':
        'अपने मोबाइल डिवाइस, टैबलेट, कंप्यूटर, गेम कंसोल और कनेक्टेड टीवी पर स्ट्रीम करें। · हर किसी के लिए कुछ न कुछ है! · डिस्कवरी+ फ़ूड, होम,… का स्ट्रीमिंग होम है',
      'mgmPlus':
        'एमजीएम+ प्राप्त करें और हजारों फिल्में और टीवी श्रृंखलाएं विज्ञापन-मुक्त प्राप्त करें। किसी भी समय रद्द करें.',
      'filmrise':
        'हम आज की बदलती इंडस्ट्री के लिए उत्साहपूर्वक सामरिक रणनीति का उपयोग करके सर्वोत्तम फिल्मों और टीवी सामग्री प्रदान करने के लिए प्रयासरत हैं।',
      'frndlyTv':
        'अपने Roku, Amazon Fire TV या Frndly TV के साथ चलते-फिरते 40+ लाइव चैनल और ऑन डिमांड देखें। सभी 3 हॉलमार्क चैनल, A&E,… शामिल हैं',
      'fxNow': 'एफएक्स, एफएक्सएक्स और एफएक्सएम नेटवर्क पर एफएक्स मूल टीवी शो, फिल्में और लाइव टीवी देखें।',
      'paramountPlus':
        'हजारों एपिसोड, लाइव स्पोर्ट्स और एक्सक्लूसिव ओरिजिनल स्ट्रीम करें - सभी एक ही स्थान पर। पुरस्कार विजेता क्लासिक्स से लेकर हिट रिलीज़ तक, सैकड़ों प्रतिष्ठित फिल्मों तक पहुंचें। खूब मनोरंजन के लिए नए शो खोजें। हिट रियलिटी सीरीज़. हिट एमटीवी शो. 30,000+ एपिसोड। आज की ताजा खबर। हिट सीबीएस शो.',
      'pbs':
        'पीओवी फ़िल्में अपनी अविस्मरणीय कहानी कहने और अपनी समयबद्धता के लिए जानी जाती हैं। अब देखिए! पीओवी स्वतंत्र वृत्तचित्रों के लिए टेलीविजन का सबसे लंबे समय तक चलने वाला शोकेस है। और अधिक जानें! स्वतंत्र नॉनफिक्शन। 500 से अधिक फ़िल्में प्रस्तुत की गईं। अविस्मरणीय कहानियाँ.',
      'philo':
        'टीवी के लिए अधिक भुगतान क्यों करें? फिलो केवल $25/माह पर लाइव टीवी और ऑन-डिमांड सामग्री प्रदान करता है। अपने सभी डिवाइस पर अपने पसंदीदा शो, फिल्में और बहुत कुछ स्ट्रीम करें।',
      'plex':
        'Plex सभी के लिए एक निःशुल्क स्ट्रीमिंग ऐप है, इसमें कोई क्रेडिट कार्ड या सदस्यता लागत या छिपी हुई फीस नहीं है। अपनी सेवा को निःशुल्क बनाए रखने के लिए हम ऐसे विज्ञापन शामिल करते हैं जो सभी से कमाई करते हैं...',
      'pluto':
        'नवीनतम हिट फिल्मों से लेकर आपके पसंदीदा शोज़ के पूरे सीजन तक। सैकड़ों चैनल। शून्य डॉलर। सभी उपकरण। अभी देखना शुरू करें! सभी उपकरण। बिंज वॉच। ऑन डिमांड टीवी और फिल्में। सैकड़ों चैनल। अभी स्ट्रीम करें। कभी भुगतान नहीं।',
      'roku':
        'Roku उपकरण स्ट्रीमिंग टीवी को सरल बनाता है। प्लेयर, स्मार्ट टीवी से लेकर स्मार्ट होम प्रोडक्ट तक, अपने परिवार को सुरक्षित रखें, अपने परफ़ेक्ट Roku उत्पाद खोजें...',
      'showtimeAnytime':
        'मुफ़्त ट्रायल के साथ SHOWTIME स्ट्रीम करें और मूल श्रृंखला, फिल्म, स्पोर्ट्स इवेंट्स, डॉक्यूमेंटरी आदि का आनंद लें। इसके अलावा, आप पेमेंट के आधार पर बॉक्सिंग मैच भी खरीद सकते हैं - कोई सदस्यता की आवश्यकता नहीं है।',
      'shudder':
        "शडर: एक प्लेटफॉर्म पर पूरी शडर सामग्री को प्राप्त करने और लोकप्रिय सीरीज़ जैसे 'द वॉकिंग डेड' यूनिवर्स, एन राइस आदि को देखने के लिए मासिक 4.99 डॉलर से शुरू होने वाली पैकेज से शुरू करें। शडर का मुफ्त परीक्षण...",
      'slingTv':
        'स्लिंग टीवी: यह पहली ऐप-आधारित टीवी सेवा है जो आपको इंटरनेट स्ट्रीमिंग के माध्यम से लाइव टीवी और ऑन-डिमांड सामग्री प्ले करने की अनुमति देती है। आप कहीं भी हों, लाइव प्रोग्राम देख सकते हैं...',
      'starz':
        'स्टार्ज़: बोल्ड नारण्य के माध्यम से विविध दृष्टिकोणों का जीवन दिखाता है। ऑनलाइन स्ट्रीमिंग पर ओरिजिनल सीरीज़, फ़िल्में, अतिरिक्त सामग्री आदि देखने के लिए रजिस्टर करें, ऑन-डिमांड, कोई विज्ञापन नहीं।',
      'stirr':
        '... और मनोरंजन। लाइव और ऑन-डिमांड। STIRR सिटी, नेशनल आपके स्थानीय चैनल के रूप में सेट किया गया है। देखना शुरू करें। 00:05। 100+ चैनल, 5,000+ घंटे टीवी और ...',
      'tbs':
        'ऊंचाइयों में · डायनामाइट पर इस सप्ताह · टीबीएस पूर्वावलोकन पर एमएलबी पोस्टसीजन · एस18 | E14 मल्टीवर्स ऑफ़ अमेरिकन डैडनेस।',
      'theCw':
        'सीडब्ल्यू नेटवर्क का आधिकारिक घर, जिसमें ऑल अमेरिकन, सुपरमैन और लोइस, वॉकर, एफबॉय आइलैंड, प्रीमियम स्ट्रीमिंग श्रृंखला, फिल्में, खेल और बहुत कुछ शामिल है।',
      'tubiTv':
        'टुबी अग्रणी निःशुल्क, प्रीमियम, ऑन डिमांड वीडियो स्ट्रीमिंग ऐप है। हमारे पास 50,000 से अधिक फिल्मों और टेलीविजन शो के साथ सामग्री की सबसे बड़ी लाइब्रेरी है, सर्वश्रेष्ठ…',
      'usaNetwork':
        'आप USANetwork.com पर मौजूदा और क्लासिक USA शोज़ के पूरे एपिसोड देख सकते हैं। साथ ही, आप शो के टुकड़े, प्रीव्यू, फ़ोटो और वेब विशेषताओं को भी ढूंढ सकते हैं।',
      'vudu':
        'वूडू का उपयोग फ़िल्मों और टीवी शोज़ को किराए पर लेने, खरीदने और देखने के लिए किया जाता है। आप वूडू ऐप पर ऑनलाइन देख सकते हैं, या अपने पसंदीदा कनेक्शन उपकरण का उपयोग करके देख सकते हैं। सदस्यता की आवश्यकता नहीं है, मुफ्त रजिस्टर करें।',
      'xumo':
        'Xumo की स्थापना पूरे मनोरंजन उद्योग के लिए अगली पीढ़ी के स्ट्रीमिंग प्लेटफॉर्म को विकसित करने और पेश करने के लिए की गई थी। हमारे मिशन के बारे में जानें और ... के साथ जुड़ें',
      'jiocinema':
        'हिंदी, अंग्रेजी, और अन्य क्षेत्रीय भाषाओं में नवीनतम फिल्में, टीवी शो, वूट शो, लाइव स्पोर्ट्स और हाइलाइट्स ऑनलाइन देखें, विभिन्न शैलियों में…',
      'altBalaji':
        'एचडी ऑनलाइन में नवीनतम वेब सीरीज, ओरिजिनल्स और मूवीज देखें। थ्रिलर, एक्शन, एडल्ट, कॉमेडी, फैमिली ड्रामा और अधिक कई भाषाओं में शैलियों का चयन करें...',
      'crave':
        'हॉरर मूवी क्लासिक्स, डरावने टीवी सीरीज और हैलोवीन एपिसोड्स का एक क्यूरेटेड कलेक्शन। ... कॉमेडी दुनिया में अनपेक्षित प्रसिद्धि और सामाजिक दबावों का सामना।',
      'cbcGem':
        'किसी भी डिवाइस पर शिट्स क्रीक, लूथर, किम्स कन्वीनियंस, प्लस फिल्म्स और लाइव टीवी जैसे लोकप्रिय शो देखें।',
      'bbcIPlayer':
        'लाइव बीबीसी टीवी चैनल देखें, आपके द्वारा छूटे हुए टीवी कार्यक्रमों का आनंद लें और बीबीसी आईप्लेयर पर विशेष सामग्री देखें।',
      'channel4': 'चैनल 4, E4 और वाल्टर प्रस्तुत करते हैं ऑनलाइन अपने पसंदीदा शो देखें।',
      'channel5':
        'My5 में आपका स्वागत है, जहां आप चैनल 5, 5STAR, 5USA, 5SELECT, 5ACTION और अधिक से अपने पसंदीदा शो के बॉक्स सेट्स को कैच अप और स्ट्रीम कर सकते हैं।',
      'dsTv':
        'आपकी उंगलियों पर सर्वश्रेष्ठ टेलीविज़न मनोरंजन। नवीनतम खेल, फिल्में, सीरीज और रियलिटी शो देखें और DStv टीवी गाइड का उपयोग करें।',
      'molotovTv':
        'मोलोटोव मुफ्त है। अतिरिक्त विशेषताओं को जोड़ने के लिए स्वतंत्र रहें। · मोलोटोव टेलीविजन के भविष्य का प्रतीक है · टीवी का स्पॉटिफाई · क्या टेलीविजन हो सकता है...',
      'myTf1':
        'लाइव टीएफ1, टीएमसी, टीएफएक्स, टीएफ1 सीरीज फिल्में और एलसीआई, 50 से अधिक विशिष्ट विषयगत चैनल और 10,000 घंटे से अधिक वीडियो देखें, केवल MYTF1 पर...',
      'sSportsPlus':
        'Serie A से LaLiga तक, Formula 1 से NBA तक, EuroLeague से UFC तक हर महीने 700 से अधिक घंटे का लाइव प्रसारण और सैकड़ों खेल सामग्री S Sport Plus के साथ आपके हाथ में...',
      'showmax':
        'Showmax सब्सक्रिप्शन की कीमत क्या है? Showmax विभिन्न प्राथमिकताओं और बजटों के अनुसार विभिन्न योजनाएँ प्रदान करता है, कीमतें केवल R39 प्रति ... से शुरू होती हैं।',
      'itv':
        'ITVX यूके की सबसे ताजा स्ट्रीमिंग सेवा है, जिसमें एक्सक्लूसिव नए शो, ब्लॉकबस्टर फिल्में, लाइव इवेंट्स और हजारों बॉक्ससेट सब एक ही जगह पर।',
      'pandora':
        'ऑल-न्यू पेंडोरा पर आप जो गाने, एल्बम, प्लेलिस्ट और पॉडकास्ट पसंद करते हैं उन्हें प्ले करें। विज्ञापन-मुक्त और ऑन-डिमांड स्ट्रीमिंग के लिए एक सब्सक्रिप्शन प्लान के लिए साइन अप करें।',
      'qqMusic':
        'QQ Music टेनसेंट द्वारा लॉन्च की गई एक वेब-आधारित संगीत सेवा उत्पाद है, जो ऑनलाइन संगीत स्ट्रीमिंग, नए और लोकप्रिय गानों के विशेष रिलीज़, गीत अनुवाद, मोबाइल रिंगटोन डाउनलोड, उच्च-गुणवत्ता वाले लॉसलेस संगीत स्ट्रीमिंग, व्यापक लॉसलेस संगीत पुस्तकालय, कानूनी संगीत डाउनलोड, और व्यक्तिगत स्थान सहित एक व्यापक विशेषताओं की पेशकश करता है...',
      'spotify': 'Spotify एक डिजिटल संगीत सेवा है जो आपको लाखों गानों तक पहुंच प्रदान करती है।',
      'tidal':
        'TIDAL एक कलाकार-पहला, प्रशंसक-केंद्रित संगीत स्ट्रीमिंग प्लेटफॉर्म है जो HiFi ध्वनि गुणवत्ता में वैश्विक संगीत समुदाय को 100 मिलियन से अधिक गाने पहुंचाता है।',
      'weChat':
        'सभी प्रकार के प्लेटफार्मों के लिए उपलब्ध; ग्रुप चैट का आनंद लें; आवाज, फोटो, वीडियो और टेक्स्ट संदेशों का समर्थन करता है।',
      'whatsApp':
        'दोस्तों और परिवार के साथ संपर्क में रहने के लिए व्हाट्सएप मैसेंजर का उपयोग करें। व्हाट्सएप मुफ़्त है और सरल, सुरक्षित, विश्वसनीय मैसेजिंग और कॉलिंग प्रदान करता है, जो उपलब्ध है…!',
      'line':
        'नया संचार ऐप जो आपको दिन के 24 घंटे, जब भी और जहां भी हो, मुफ़्त वॉयस कॉल करने और मुफ़्त संदेश भेजने की अनुमति देता है!',
      'signal':
        'मुफ्त टेक्स्ट, आवाज संदेश, फ़ोटो, वीडियो, GIF और फ़ाइलें साझा करें। Signal आपके मोबाइल के डेटा कनेक्शन का उपयोग करता है ताकि SMS और MMS के लागत से बचा सके।',
      'agoda':
        'तत्काल बुक करें, बाद में भुगतान करें, मुफ्त रद्द करें। आपकी भाषा की वास्तविक समय में सहायता करने वाली सेवा, 24/7 उपलब्ध है। आर्थिक से सामरिक तक, होटल और आवास। आपके भरोसेमंद कमरे और उड़ान यात्रा साथी।',
      'expedia':
        'आपकी सपनों की छुट्टी को पूरा करने के लिए आपका एक-स्टॉप यात्रा वेबसाइट में आपका स्वागत है। आपकी आवास को कार या उड़ान से बंधित करके आप अधिक बचत कर सकते हैं। आपकी आवश्यकताओं के अनुरूप विकल्प खोजें...',
      'flyScanner':
        'सिर्फ़ $19 से शुरू होने वाली छूट वाली उड़ानें आसानी से प्राप्त करें। एक खोज में तुलना करें और बुक करें, तेजी से और सुविधाजनकता से। सस्ती उड़ानें, होटल और कार की तुलना करें। हजारों ऑफ़ डील्स में से बेहतर ढूंढ़ें, सबसे अच्छा चुनें!',
      'nowTV':
        'NOW सदस्यता का कितना खर्च होता है? NOW सदस्यता आपको अपनी पसंद की सामग्री को अपने तरीके से देखने की सुविधा प्रदान करता है। आप मनोरंजन, सिनेमा, खेल और Hayu में शामिल हो सकते हैं।',
      'TVPlayer':
        'TVPlayer वह स्थान है जहां सबसे लोकप्रिय टेलीविजन चैनल और उच्च गुणवत्ता के वीडियो ऑन डिमांड होते हैं। 40 से अधिक लाइव चैनल और हजारों...',
      'ukTvPlay':
        'UKTV Play वहां है जहां Dave, Drama, W & Yesterday चैनलों के वीडियो ऑन डिमांड होते हैं। लाइव टेलीविजन, स्ट्रीमिंग और आपकी पसंदीदा शो को देखने का पीछा करें।',
      'zattoo':
        'अपनी पसंदीदा टेलीविजन शो को सभी उपकरणों पर लाइव और टाइमशिफ्ट करें। सर्वश्रेष्ठ पूर्ण HD छवि गुणवत्ता। केबल टीवी से बेहतर। ऑन डिमांड और लाइव टेलीविजन सामग्री प्रदान करता है।',
      'skyGo':
        'Sky पैकेज के माध्यम से अपने मोबाइल, टैबलेट, लैपटॉप या कंप्यूटर पर, या Apple TV उपकरण के माध्यम से लाइव टेलीविजन, ऑन डिमांड शो और कैचअप टीवी देखें, और आसानी से विभिन्न उपकरणों के बीच स्विच करें।',
      'Telegram':
        'टेलीग्राम का लक्ष्य एक सुरक्षित, तेज़ और संक्षिप्त संचार अनुभव प्रदान करना है, और इसे इसकी शक्तिशाली एन्क्रिप्शन सुविधाओं और समृद्ध कार्यों के लिए जाना जाता है।',
      'Messenger':
        'मैसेंजर एक तत्काल संदेश सेवा है जो Facebook द्वारा प्रदान की जाती है, जो उपयोगकर्ताओं को पाठ संदेशों, आवाज़ कॉल, वीडियो कॉल, चित्र भेजने, इमोजी आदि के माध्यम से अन्य लोगों के साथ संवाद करने की अनुमति देता है। मैसेंजर शुरू में Facebook वेबसाइट की एक सुविधा थी, बाद में इसे एक स्वतंत्र मोबाइल एप्लिकेशन में विकसित किया गया, और यह अधिक सुविधाएं और सेवाएं प्रदान करता है।',
      'ChatGPT':
        'ChatGPT एक कृत्रिम बुद्धिमत्ता भाषा मॉडल है जिसे OpenAI द्वारा विकसित किया गया है, जो GPT (Generative Pre-trained Transformer) वास्तुकला पर आधारित है। इसे विशाल प्राकृतिक भाषा प्रसंस्करण डेटा के माध्यम से प्रशिक्षित किया गया है और यह प्राकृतिक भाषा पाठ को समझने और उत्पन्न करने में सक्षम है।',
      'CanalPlus':
        'CANAL+ एक फ्रांसीसी पेड टेलीविजन चैनल है, जो फ्रांस के सबसे बड़े पेड टीवी नेटवर्कों में से एक है, और यूरोप में सबसे महत्वपूर्ण पेड टीवी सेवा प्रदाताओं में से एक। चैनल विभिन्न कार्यक्रम प्रदान करता है, जिसमें फिल्में, खेल, समाचार, डॉक्यूमेंटरी, टीवी नाटक आदि शामिल हैं।',
    },
    'selectTunnel': 'टनल चुनें',
    notSetLabel: 'सेट नहीं',
    'directAccessLabel': 'टनल के बिना सीधा पहुंच',
    'otherTunnelLabel': 'अन्य टनल',
    'recommendedTunnelLabel': 'सिफारिशित टनल',
    'error': {
      'getApps': 'ऐप्स प्राप्त करने में विफल',
      'getTunnels': 'टनल सूची प्राप्त करने में विफल',
      'invalidApp': 'अमान्य ऐप',
      'tunnelNotExist': 'टनल मौजूद नहीं है',
      'set': 'टनल सेट करने में विफल',
      'unset': 'टनल सेटिंग को हटाने में विफल',
    },
    'success': {
      'set': 'टनल सेट करने में सफल',
      'unset': 'टनल सेटिंग को हटाने में सफल',
    },
    searchInputPlaceholder: 'आपकी आवश्यकता की सेवा खोजें',
    discountIntro: percent => `प्रति माह सदस्यता शुल्क ${percent} की बचत`,
    discountPercent: percent => `-${percent} (डॉलर)/माह`,
    guideButtonLabel: 'विवरण देखें',
  },
  // betanet
  betanet: {
    'bindWalletSuccess': 'वॉलेट सफलतापूर्वक बाइंड किया गया',
    'deviceBound': 'डिवाइस पहले से ही एक अन्य वॉलेट से बाइंड है',
    'walletTaken': 'वॉलेट पहले से ही दूसरे डिवाइस द्वारा उपयोग किया जा रहा है',
    'deviceTypeIncompatible': 'डिवाइस और वॉलेट के साथ संगत नहीं है',
    'invalidMnemonic': 'अमान्य वॉलेट म्नेमोनिक',
    'invalidPrivateKey': 'अमान्य वॉलेट निजी कुंजी',
    'bindWalletError': 'वॉलेट बाइंड करने में विफल',
    'notBound': 'वर्तमान उपयोगकर्ता ने कोई वॉलेट बाइंड नहीं की है',
    'unbindWalletSuccess': 'वॉलेट सफलतापूर्वक अनबाइंड किया गया',
    'noActiveBinding': 'डिवाइस द्वारा निर्दिष्ट वॉलेट के साथ कोई सक्रिय बाइंडिंग नहीं है',
    'unbindWalletError': 'वॉलेट अनबाइंड करने में विफल',
    'walletManagement': 'वॉलेट प्रबंधन',
    'bindWallet': 'वॉलेट बाइंड करें',
    'downloadPrivateKey': 'निजी कुंजी डाउनलोड करें',
    'downloadKeyError': 'निजी कुंजी डाउनलोड करने में विफल',
    'unbindBtnText': 'वॉलेट अनबाइंड करें',
    'unbindWarning':
      "कृपया सुनिश्चित करें कि आपने निजी कुंजी डाउनलोड कर ली है, कृपया 'मैंने निजी कुंजी डाउनलोड कर ली है' दर्ज करें और जारी रखें।",
    'unbindWarningWords': 'मैंने निजी कुंजी डाउनलोड कर ली है',
    'generateWallet': 'वॉलेट उत्पन्न करें',
    'verifyMnemonic': 'म्नेमोनिक को सत्यापित करें',
    'mnemonicMismatch': 'म्नेमोनिक सत्यापन विफल',
    'bindBtn1Text': 'वॉलेट बाइंड करें (म्नेमोनिक)',
    'bindBtn2Text': 'वॉलेट बाइंड करें (निजी कुंजी)',
    'dprManagement': 'DPR प्रबंधन',
    'balanceLabel': 'DPR शेष',
    'balance': 'शेष',
    'creditLabel': 'विश्वास',
    'operationLabel': 'कार्रवाई',
    'amtOwingLabel': 'सिस्टम द्वारा आपके लिए अग्रिम भुगतान किया गया',
    'dailyConsumedLabel': 'दैनिक उपयोगिता',
    'dailySharedLabel': 'दैनिक साझा उपयोगिता',
    'deposit': 'जमा करें',
    'withdraw': 'निकासी करें',
    'publicKey': 'सार्वजनिक कुंजी',
    'mnemonicInstruction': 'कृपया म्नेमोनिक को याद रखें। आपको अगले चरण में सत्यापित करने की आवश्यकता होगी।',
    'enterMnemonic': 'म्नेमोनिक दर्ज करें',
    'enterMnemonicTip': 'कृपया क्रमबद्ध रूप से म्नेमोनिक पर क्लिक करें।',
    'resetMnemonic': 'रीसेट',
    'uploadPrivateKey': 'निजी कुंजी अपलोड करें',
    'getWalletError': 'वॉलेट जानकारी प्राप्त करने में विफल',
    'deviceWalletMismatch': 'डिवाइस और वॉलेट मेल नहीं खाते',
    'walletNotFound': 'वॉलेट नहीं मिला',
    'generateWalletError': 'वॉलेट उत्पन्न करने में त्रुटि',
    'missingMnemonic': 'मनकी गुम है',
    'missingPrivateKeyFile': 'निजी कुंजी फ़ाइल गुम है',
    'privateKeyFileOversize': 'निजी कुंजी फ़ाइल 10KB सीमा से अधिक है',
    'transferTitle': 'ट्रांसफर',
    'transferHistoryTitle': 'ट्रांसफर इतिहास',
    'transferTo': 'प्राप्तकर्ता',
    'transferAmt': 'ट्रांसफर राशि (अधिकतम 6 दशमलव अंक तक)',
    'invalidTo': 'अमान्य प्राप्तकर्ता',
    'invalidAmt': 'अमान्य ट्रांसफर राशि',
    'amtOutOfRange': 'ट्रांसफर राशि सकारात्मक और 1000000 से अधिक नहीं होनी चाहिए',
    'transferSuccess': 'ट्रांसफर सफल',
    'senderRecipientSame': 'प्रेषक और प्राप्तकर्ता समान नहीं हो सकते',
    'recipientNotFound': 'प्राप्तकर्ता नहीं मिला',
    'insufficientFund': 'अपर्याप्त धन के कारण ट्रांसफर विफल',
    'transferError': 'ट्रांसफर त्रुटि',
    'votingResNotRetrieved': 'मतदान परिणाम प्राप्त नहीं हुए',
    'candidateSelected': 'आपके द्वारा चयनित सत्यापन उम्मीदवार चयनित हुआ है',
    'candidateNotSelected': 'आपके द्वारा चयनित सत्यापन उम्मीदवार चयनित नहीं हुआ है',
    'votingResNotReady': 'वर्तमान में मतदान परिणाम प्रश्नोत्तरी के लिए उपलब्ध नहीं है',
    'notVoted': 'आपने किसी भी सत्यापन उम्मीदवार को मत नहीं दिया है',
    'candidateNotFound': 'सत्यापन उम्मीदवार नहीं मिला',
    'votingResRetrievalErr': 'मतदान परिणाम प्राप्त करने में त्रुटि',
    'from': 'से',
    'to': 'को',
    'type': 'प्रकार',
    'amount': 'मात्रा',
    'time': 'समय',
    'txnTypes': {
      '101': 'प्राथमिक पुरस्कार',
      '201': 'मैन्युअल ट्रांसफर',
      '301': 'धनराशि धारक को धनराशि देने का माध्यम',
      '401': 'माइक्रो पेमेंट',
    },
    'walletAddress': 'वॉलेट पता',
    'notInGenesisNodeList': 'आदिम नोड केवल आदिम खदान मशीन पर बाइंड किया जा सकता है।',
    'onChainStatusLabel': 'चेन पर स्थिति',
    'onChainStatusDesc': {
      'unknown': '-',
      'onChain': 'हाँ',
      'notOnChain': 'नहीं',
    },
    'pwMgmtSection': {
      'title': 'पासवर्ड प्रबंधन',
      'setBtnText': 'पासवर्ड सेट करें',
      'resetBtnText': 'पासवर्ड रीसेट करें',
    },
    'passwordModal': {
      'title': 'वॉलेट पासवर्ड सेट करें',
      'onePlaceholder': 'पासवर्ड दर्ज करें',
      'twoPlaceholder': 'पासवर्ड फिर से दर्ज करें',
      'btnText': 'पुष्टि करें',
    },
    'setPasswordMsg': {
      'mismatch': 'पासवर्ड मेल नहीं खाते',
      'weak':
        'पासवर्ड की लंबाई कम से कम 8 वर्णों की होनी चाहिए और इसमें छोटे अक्षर, बड़े अक्षर और संख्यात्मक अक्षर होने चाहिए',
      'alreadySet': 'वॉलेट पासवर्ड पहले से ही सेट किया गया है',
      'genericErr': 'वॉलेट पासवर्ड सेट करने में त्रुटि',
      'success': 'वॉलेट पासवर्ड सेट करने में सफल',
    },
    'authModal': {
      'title': 'वॉलेट पासवर्ड',
      'inputPlaceholder': 'वॉलेट पासवर्ड दर्ज करें',
      'btnText': 'जारी रखें',
    },
    'passwordNotSet': 'वॉलेट पासवर्ड सेट नहीं है',
    'passwordIncorrect': 'वॉलेट पासवर्ड गलत है',
    'resetModal': {
      'disclaimer':
        "वॉलेट पासवर्ड रीसेट करने के बाद, आपकी डिवाइस को वर्तमान में बाइंड किए गए किसी भी वॉलेट से अनबाइंड कर दिया जाएगा। कृपया जारी रखने के लिए 'मैं वॉलेट पासवर्ड रीसेट करने की पुष्टि करता हूँ' दर्ज करें",
      'consent': 'मैं वॉलेट पासवर्ड रीसेट करने की पुष्टि करता हूँ',
    },
    'resetPasswordMsg': {
      'noActiveBinding': 'डिवाइस किसी निर्दिष्ट वॉलेट से बाइंड नहीं है',
      'unbindErr': 'वॉलेट से अनबाइंड करने में त्रुटि',
      'genericErr': 'वॉलेट पासवर्ड रीसेट करने में त्रुटि',
      'success': 'वॉलेट पासवर्ड रीसेट करने में सफल',
    },
    unstaking: {
      'label': 'प्रतिबंध जानकारी',
      'getErr': 'प्रतिबंध समाप्ति चयन प्राप्त करने में विफल',
      'alreadyChosen': 'आपने पहले से ही प्रतिबंध समाप्ति का चयन किया है',
      'expired': 'प्रतिबंध समाप्ति अवधि समाप्त हो गई है',
      'invalidCreditData': 'यह वॉलेट नेटवर्क में शामिल नहीं है',
      'phase1IsNotOver': 'इस वॉलेट का पहला चरण प्रतिबंध गतिविधि समाप्त नहीं हुआ है',
      'setErr': 'प्रतिबंध समाप्ति चयन सेट करने में विफल',
      'announcementTitle': 'प्रिय staking नोड उपयोगकर्ता:',
      'announcement': {
        'continueStaking':
          'प्रतिबंध जारी रखें: आपकी staking गतिविधि स्वचालित रूप से दूसरे चरण में प्रवेश कर गई है; दूसरे चरण की प्रतिबंध अवधि 6 महीने है। बेसिक माइनिंग और जेनेसिस नोड के APY दूसरे चरण में भी बरकरार रहेंगे।',
        'duringStaking':
          'दूसरे चरण की प्रतिबंध अवधि के दौरान: आप कभी भी इस बटन पर क्लिक करके प्रतिबंध समाप्ति कर सकते हैं; यह चयन अविलंब योग्य है!',
        'stopStaking': 'प्रतिबंध समाप्ति: आपको 3 महीने के रेखीय विमुक्ति चरण में प्रवेश करेंगे;',
        'releaseStaking': 'प्रतिबंध मुक्ति: DPR आपके DeeperChain वॉलेट में स्वचालित रूप से हर दिन मुक्ति करेगा।',
      },
      'tipTitle': 'मित्रता याद दिलाना:',
      'tip': {
        'afterStopStaking_1':
          '1. प्रतिबंध समाप्ति के बाद, प्राप्त किया गया विश्वास मिटा दिया जाएगा (बैंडविड्थ साझा करने से अभिप्रेत विश्वास संग्रहित रहेगा)',
        'afterStopStaking_2':
          '2. जेनेसिस नोड के प्रतिबंध को रद्द करने से उसकी जगह की हानि होगी, जिससे जेनेसिस नोड के रूप में होने का अवसर सदैव के लिए खो जाएगा। आपकी उपकरण तत्परता विमुक्ति 2.0 में सीधे शामिल होने की क्षमता खो देगा, लेकिन बैंडविड्थ साझा करने से उत्पन्न जैविक विश्वास मानक जारी रहेगा।',
        'afterStopStaking_3': '3. प्रतिबंध समाप्ति के बाद, आपके DPR प्रतिबंध पुरस्कार तत्काल रूप से बंद हो जाएंगे',
        'afterStopStaking_4':
          '4. प्रतिबंध समाप्ति के बाद, तुरंत पुनः प्रतिबंध करने का अवसर नहीं मिलेगा (आगामी घोषणाओं की प्रतीक्षा करनी होगी)',
        'afterStopStaking_5':
          '5. प्रतिबंध समाप्ति के बाद, पहले चरण के 270 पुरस्कार पूरे होने के बाद ही प्रतिबंध समाप्त होगी',
      },
      'btn': {
        'cancel': 'समझे, प्रतिबंध जारी रखें',
        'ok': 'प्रतिबंध समाप्ति',
      },
    },
    staking: {
      'walletInfo': 'वॉलेट जानकारी',
      'campaignLabel': 'स्टेकिंग अभियान प्रकार',
      'campaignText': {
        '0': 'सोने का आदि नोड',
        '1': 'चांदी का आदि नोड',
        '2': 'स्टेकिंग माइनिंग 1.0',
        '3': 'डीपीआरबी माइनिंग',
        '4': 'स्टेकिंग माइनिंग 2.0',
        '7': 'स्टेकिंग माइनिंग 2.0',
        '8': 'स्टेकिंग माइनिंग 2.0',
      },
      'creditLabel': 'वर्तमान क्रेडिट स्कोर',
      'creditMaxLabel': 'क्रेडिट स्कोर की अधिकतम मान्यता',
      'creditLevelLabel': 'क्रेडिट स्तर',
      'stakeAndBurn': 'स्टेक और बर्न',
      'stakeForCredit': 'हिस्सेदारी बढ़ाएँ',
      'dailyReward': 'दैनिक रिवार्ड',
      view: 'जाँच करना',
      'payment': 'भुगतान',
      'insufficientBalance': 'अपर्याप्त शेष',
      'stakeSuccess': 'अनुरोध भेजा गया है, कृपया क्रेडिट स्कोर अपडेट करने की प्रतीक्षा करें।',
      'stakeError': 'अनुरोध भेजने में विफल, कृपया वॉलेट संबंधित जानकारी की जांच करें या सहायता केंद्र से संपर्क करें।',
      'burnForCredit': 'क्रेडिट स्कोर के लिए डीपीआर को बर्न करें',
      'burnSuccess': 'अनुरोध भेजा गया है, कृपया क्रेडिट स्कोर अपडेट करने की प्रतीक्षा करें।',
      'burnError': 'अनुरोध भेजने में विफल, कृपया वॉलेट संबंधित जानकारी की जांच करें या सहायता केंद्र से संपर्क करें।',
    },
  },
  // ChangePasswordData
  ChangePasswordData: {
    'successMsg': 'पासवर्ड सफलतापूर्वक बदल दिया गया',
    'newPasswordMismatch': 'दो बार दर्ज किए गए नए पासवर्ड मेल नहीं खाते',
    'oldPasswordMismatch': 'पुराना पासवर्ड गलत है',
    'samePasswordError': 'नया पासवर्ड पुराने पासवर्ड के समान है',
    'genericError': 'पासवर्ड बदलने में त्रुटि',
    'title': 'पासवर्ड बदलें',
    'oldPwd': 'कृपया पुराना पासवर्ड दर्ज करें',
    'newPwd': 'कृपया नया पासवर्ड दर्ज करें',
    'newPwdAgain': 'नया पासवर्ड फिर से दर्ज करें',
    'okText': 'पुष्टि',
  },
  //dailyTraffic
  dailyTraffic: {
    'historySize': 'दिन',
    'chartTitle': 'ट्रैफ़िक',
    'uptimeChartTitle': 'उपलब्धता समय',
    'hour': 'घंटा',
    'minute': 'मिनट',
    'second': 'सेकंड',
  },
  // flow
  flow: {
    'totalTrafficLabel': 'कुल ट्रैफ़िक',
    'localTraffic': 'स्थानीय ट्रैफ़िक',
    'clientTraffic': 'क्लाइंट ट्रैफ़िक',
    'serverTraffic': 'सर्वर ट्रैफ़िक',
    'upload': 'अपलोड',
    'download': 'डाउनलोड',
    'sessionSpeed': 'सत्र की गति',
  },
  // login
  login: {
    'pageTitle': 'डीपर नेटवर्क में आपका स्वागत है!',
    'username': 'उपयोगकर्ता नाम',
    'password': 'पासवर्ड',
    'btnText': 'लॉग इन करें',
    'successMsg': 'लॉगिन सफलतापूर्वक',
    'missingField': 'उपयोगकर्ता नाम या पासवर्ड अनुपलब्ध है',
    'wrongField': 'गलत उपयोगकर्ता नाम या पासवर्ड',
    'invalidToken': 'अमान्य लॉगिन डेटा',
    'expiredToken': 'लॉगिन डेटा की समय सीमा समाप्त हो गई है',
    'genericError': 'लॉगिन त्रुटि',
    'tooManyAttempts': 'लॉगिन प्रयासों की संख्या अधिक है',
  },
  //notification
  notification: {
    'search': 'खोजें',
    'CP': 'नियंत्रण परत',
    'DP': 'डेटा परत',
    'MP': 'प्रबंध परत',
  },
  // DomainConfig
  domainConfig: {
    'whiteList': 'स्मार्ट रूट सूची',
    'blackList': 'सीधे रूट सूची',
    'domain': 'डोमेन',
    'tunnel': 'टनल',
    'selectTunnel': 'टनल का चयन करें',
    'region': 'क्षेत्र',
    'country': 'देश या क्षेत्र',
    'noTunnel': 'कोई टनल नहीं',
    'convertedTip': 'वेबसाइट पते को स्वचालित रूप से डोमेन में परिवर्तित किया गया है',
    'urlInputPlaceholder': 'उदाहरण के लिए: https://www.google.com या google.com',
    'missingInfoWarning': 'कृपया पूरी जानकारी भरें',
    'invalidDomainError': 'अमान्य डोमेन प्रारूप या डोमेन बहुत लंबा है',
    'whitelistAddSuccess': 'स्मार्ट रूट सूची में सफलतापूर्वक जोड़ा गया',
    'existInBlacklist': 'जोड़ने का आइटम सीधे रूट सूची में मौजूद है',
    'whitelistEntryExist': 'स्मार्ट रूट सूची में पहले से ही जोड़ा हुआ आइटम है',
    'tunnelNotExist': 'टनल मौजूद नहीं है, जोड़ा नहीं जा सकता',
    'whitelistAddError': 'स्मार्ट रूट सूची आइटम जोड़ने में विफल',
    'blacklistAddSuccess': 'सीधे रूट सूची में सफलतापूर्वक जोड़ा गया',
    'existInWhitelist': 'जोड़ने का आइटम स्मार्ट रूट सूची में मौजूद है',
    'blacklistEntryExist': 'सीधे रूट सूची में पहले से ही जोड़ा हुआ आइटम है',
    'blacklistAddError': 'सीधे रूट सूची आइटम जोड़ने में विफल',
    'conflict': 'संघर्ष के कारण आइटम जोड़ा नहीं जा सकता',
    'whitelistDelSuccess': 'स्मार्ट रूट सूची आइटम सफलतापूर्वक हटा दिया गया',
    'domainNotExist': 'चयनित डोमेन मौजूद नहीं है',
    'whitelistDelError': 'स्मार्ट रूट सूची आइटम को हटाने में विफल',
    'blacklistDelSuccess': 'सीधे रूट सूची आइटम सफलतापूर्वक हटा दिया गया',
    'blacklistDelError': 'सीधे रूट सूची आइटम को हटाने में विफल',
    'backupTips':
      'बैकअप टनल सक्रिय है, स्मार्ट रूट मोड में जोड़े गए टनल का उपयोग करने में असमर्थ नोड के लिए सिस्टम डिफ़ॉल्ट बैकअप टनल - {0} का उपयोग करेगा।',
    'tips': {
      'smartRouteList': 'अपनी आवश्यकतानुसार, आप मैन्युअल रूप से वेबसाइट के डोमेन और उसके देश को जोड़ सकते हैं।',
      'directRouteList': 'यहां जोड़े गए डोमेन DPN टनल का उपयोग नहीं करके स्थानीय नेटवर्क का प्रयोग करेंगे।',
    },
  },
  // ipConfig
  ipConfig: {
    'whiteList': 'स्मार्ट आईपी सूची',
    'blackList': 'सीधा आईपी सूची',
    'ip': 'आईपी',
    'tunnel': 'टनल',
    'selectTunnel': 'टनल का चयन करें',
    'region': 'क्षेत्र',
    'country': 'देश या क्षेत्र',
    'noTunnel': 'कोई टनल नहीं',
    'convertedTip': 'वेबसाइट पते को स्वचालित रूप से आईपी में परिवर्तित किया गया है',
    'urlInputPlaceholder': 'उदाहरण के लिए: https://xxx.xxx.xxx.xxx या xxx.xxx.xxx.xxx',
    'missingInfoWarning': 'कृपया पूरी जानकारी भरें',
    'invalidIPError': 'अमान्य आईपी प्रारूप',
    'whitelistAddSuccess': 'स्मार्ट आईपी सूची में सफलतापूर्वक जोड़ा गया',
    'existInBlacklist': 'जोड़ने का आइटम सीधा आईपी सूची में मौजूद है',
    'whitelistEntryExist': 'स्मार्ट आईपी सूची में पहले से ही जोड़ा हुआ आइटम है',
    'tunnelNotExist': 'टनल मौजूद नहीं है, जोड़ा नहीं जा सकता',
    'whitelistAddError': 'स्मार्ट आईपी सूची आइटम जोड़ने में विफल',
    'blacklistAddSuccess': 'सीधा आईपी सूची में सफलतापूर्वक जोड़ा गया',
    'existInWhitelist': 'जोड़ने का आइटम स्मार्ट आईपी सूची में मौजूद है',
    'blacklistEntryExist': 'सीधा आईपी सूची में पहले से ही जोड़ा हुआ आइटम है',
    'blacklistAddError': 'सीधा आईपी सूची आइटम जोड़ने में विफल',
    'whitelistDelSuccess': 'स्मार्ट आईपी सूची आइटम सफलतापूर्वक हटा दिया गया',
    'ipNotExist': 'चयनित आईपी मौजूद नहीं है',
    'whitelistDelError': 'स्मार्ट आईपी सूची आइटम को हटाने में विफल',
    'blacklistDelSuccess': 'सीधा आईपी सूची आइटम सफलतापूर्वक हटा दिया गया',
    'blacklistDelError': 'सीधा आईपी सूची आइटम को हटाने में विफल',
    'backupTips':
      'बैकअप टनल सक्रिय है, स्मार्ट रूट मोड में जोड़े गए टनल का उपयोग करने में असमर्थ नोड के लिए सिस्टम डिफ़ॉल्ट बैकअप टनल - {0} का उपयोग करेगा।',
    'tips': {
      'smartRouteList': 'अपनी आवश्यकतानुसार, आप मैन्युअल रूप से आईपी और उसके देश को जोड़ सकते हैं।',
      'directRouteList': 'यहां जोड़े गए आईपी DPN टनल का उपयोग नहीं करके स्थानीय नेटवर्क का प्रयोग करेंगे।',
    },
  },
  // queryRouting
  queryRouting: {
    'title': 'रूटिंग खोजें',
    'searchLabel': 'रूटिंग खोजें',
    'search': 'खोजें',
    'searchResult': 'खोज परिणाम: ',
    'invalidRoutingError': 'अमान्य रूटिंग प्रारूप',
    'defaultConfig': 'डिफ़ॉल्ट कॉन्फ़िगरेशन',
    'userConfig': 'उपयोगकर्ता कॉन्फ़िगरेशन',
    'routingNotFound': 'रूटिंग नहीं मिला',
    'queryRoutingError': 'रूटिंग का निर्धारण करने में त्रुटि',
    'convertedTip': 'वेबसाइट पते को स्वचालित रूप से डोमेन या आईपी में परिवर्तित किया गया है',
    'urlInputPlaceholder': 'उदाहरण के लिए: google.com या xxx.xxx.xxx.xxx',
  },
  // rustdesk
  rustdesk: {
    'ipLabel': 'आपका आईपी पता है',
    'noPubIp': 'आपका कोई सार्वजनिक आईपी नहीं है।',
    'vpnUse':
      'आप वायरगार्ड वीपीएन पोर्ट फ़ॉरवर्डिंग का उपयोग करके सार्वजनिक आईपी प्राप्त कर सकते हैं। कृपया ध्यान दें कि यह सार्वजनिक आईपी केवल RustDesk सेवा के लिए ही उपयोग किया जा सकता है और यह डीपीएन सेवा प्रदान नहीं कर सकता है।',
    'config': {
      'title': 'RustDesk कॉन्फ़िगरेशन',
      'switch': 'चालू/बंद',
      'forceKey': 'अनुरोधित पब्लिक कुंजी',
      'relay':
        'अंतरवार्ती सर्वर (होस्ट: पोर्ट) की अंग्रेजी में विभाजित, उदाहरण के लिए www.example.com:21116,192.168.1.1:21117',
      'port': 'पोर्ट (2-65532)',
      'saveBtnText': 'बचाना',
      'pubKey': 'पब्लिक कुंजी',
      'emptyPubKey': 'कोई पब्लिक कुंजी नहीं है',
      'resetBtnText': 'पब्लिक कुंजी रीसेट करें',
    },
    'vpn': {
      'title': 'वायरगार्ड वीपीएन पोर्ट फ़ॉरवर्डिंग',
      'switch': 'चालू/बंद',
      'ip': 'वायरगार्ड सर्वर आईपी',
      'port': 'वीपीएन फ़ॉरवर्डिंग पोर्ट',
    },
    'guide': {
      'title': 'कॉन्फ़िगरेशन गाइड',
      'stepOne': 'दिए गए चित्र में दिखाए गए बटन पर क्लिक करें',
      'stepTwoA': 'फ़ॉर्म में उपयुक्त खंड भरें और सहेजें पर क्लिक करें',
      'idAndRelay': 'आईडी/अंतरवार्ती सर्वर',
      'idServer': 'आईडी सर्वर:',
      'relayServer': 'अंतरवार्ती सर्वर:',
      'apiServer': 'एपीआई सर्वर:',
      'serverKey': 'कुंजी:',
      'stepTwoB': 'या आप कॉन्फ़िगरेशन को क्लिपबोर्ड पर कॉपी कर सकते हैं और इसे RustDesk में आयात कर सकते हैं',
      'copyBtnText': 'सर्वर कॉन्फ़िगरेशन क्लिपबोर्ड पर कॉपी करें',
    },
    'success': {
      'setSwitch': 'RustDesk को सफलतापूर्वक टॉगल किया गया',
      'setForced': 'अनुरोधित पब्लिक कुंजी सफलतापूर्वक सेट की गई',
      'setRelayAndPort': 'अंतरवार्ती और पोर्ट सफलतापूर्वक सहेजे गए',
      'resetPubKey': 'पब्लिक कुंजी सफलतापूर्वक रीसेट की गई',
      'setVpnSwitch': 'वायरगार्ड वीपीएन को सफलतापूर्वक टॉगल किया गया',
      'copyToClipboard': 'क्लिपबोर्ड पर कॉपी किया गया',
    },
    'error': {
      'getNetworkAddress': 'आईपी पता प्राप्त करने में विफल',
      'getConfig': 'RustDesk कॉन्फ़िगरेशन प्राप्त करने में विफल',
      'setSwitch': 'RustDesk टॉगल करने में विफल',
      'setForced': 'अनुरोधित पब्लिक कुंजी सेट करने में विफल',
      'invalidPort': 'अमान्य पोर्ट',
      'invalidRelay': 'अमान्य अंतरवार्ती',
      'setRelayAndPort': 'अंतरवार्ती और पोर्ट सहेजने में विफल',
      'resetPubKey': 'पब्लिक कुंजी रीसेट करने में विफल',
      'getVpnConfig': 'वायरगार्ड वीपीएन कॉन्फ़िगरेशन प्राप्त करने में विफल',
      'setVpnSwitch': 'वायरगार्ड वीपीएन टॉगल करने में विफल',
      'copyToClipboard': 'क्लिपबोर्ड पर कॉपी करने में विफल',
      'getVersion': 'संस्करण संख्या प्राप्त करने में विफल',
      'getHbbrLog': 'HBBR लॉग प्राप्त करने में विफल',
      'getHbbsLog': 'HBBS लॉग प्राप्त करने में विफल',
    },
  },
  // sharing
  sharing: {
    'sharingTitle': 'साझा करना: ',
    'btSharingTitle': 'बिट टोरेंट साझा करना: ',
    'enabled': 'सक्षम',
    'disabled': 'अक्षम',
    'sharingTrafficLimit': 'मासिक साझा ट्रैफिक सीमा',
    'sharingBandwidthLimit': 'साझा बैंडविड्थ सीमा',
    'unlimited': 'असीमित',
    'limited': 'सीमित',
    'setLabel': 'सेट करें',
    'checkStartEndTime': 'कृपया प्रारंभ समय और समाप्ति समय का चयन करें',
    'time': 'समय',
    'record': 'रिकॉर्ड',
    'startTime': 'प्रारंभ समय',
    'endTime': 'समाप्ति समय',
    'keyword': 'कीवर्ड',
    'search': 'खोजें',
    'webToSharingTitle': 'वेब फ़िल्टर नियम लागू करें',
    webToSharingDesc: 'डिवाइस के अंतर्निहित DNS विज्ञापन फ़िल्टरिंग और कस्टम DNS फ़िल्टरिंग नियम लागू करें',
    'yes': 'हाँ',
    'no': 'नहीं',
    'download': 'सादा लॉग',
    'export': 'एन्क्रिप्टेड लॉग',
    'success': {
      enableTrafficLimit: 'मासिक साझाकरण ट्रैफ़िक सीमा सक्षम की गई। कृपया सीमा निर्धारित करें!',
      'setSharingTrafficLimit': 'साझा ट्रैफिक सीमा सेट करने में सफल',
      'enableBandwidthLimit': 'साझा बैंडविड्थ सीमा सक्षम हो गई है. कृपया 15Mbps और 1024Mbps के बीच सीमा सेट करें!',
      'setSharingBandwidthLimit': 'साझा बैंडविड्थ सीमा सेट करने में सफल',
    },
    'errors': {
      'getSharingState': 'साझा स्थिति प्राप्त करने में विफल',
      'switchSharingState': 'साझा स्थिति परिवर्तन करने में विफल',
      'exceedsLimit': 'साझा सक्षम करने में विफल, ट्रैफिक सीमा से अधिक हो गई है',
      'getSharingTrafficLimit': 'साझा ट्रैफिक सीमा प्राप्त करने में विफल',
      'setSharingTrafficLimit': 'साझा ट्रैफिक सीमा सेट करने में विफल',
      'invalidTrafficLimit': 'यातायात सीमा एक धनात्मक संख्या होनी चाहिए',
      'setSharingBandwidthLimit': 'साझा बैंडविड्थ सीमा सेट करने में विफल',
      'invalidBandwidthLimit': 'बैंडविड्थ सीमा 15Mbps और 1024Mbps के बीच होनी चाहिए',
      'getSharingFilterConfig': 'साझा फ़िल्टर कॉन्फ़िगरेशन प्राप्त करने में विफल',
      'setSharingFilterConfig': 'साझा फ़िल्टर कॉन्फ़िगरेशन सेट करने में विफल',
    },
  },
  // system
  system: {
    'hardwareInfo': 'हार्डवेयर जानकारी',
    'softwareInfo': 'सॉफ़्टवेयर जानकारी',
    'networkAddress': 'नेटवर्क पता',
    'sessionInfo': 'सत्र जानकारी',
    'upgradeInfo': 'अपग्रेड जानकारी',
    'sn': 'एसएन',
    'cpu': 'सीपीयू',
    'cores': 'कोर',
    'memory': 'उपयोग किया गया/उपलब्ध',
    'systemVersion': 'सिस्टम संस्करण',
    'appidSignVersion': 'ट्रैफिक प्रोटोकॉल पहचान फ़ीचर लाइब्रेरी',
    'urlFilterSignVersion': 'वेब फ़िल्टर फ़ीचर लाइब्रेरी',
    'https': 'HTTPS',
    'dns': 'DNS',
    'rules': 'नियम',
    'ip': 'आईपी पता',
    'routerMac': 'राउटर मैक',
    'gatewayMac': 'गेटवे मैक',
    'maxSession': 'अधिकतम सत्र',
    'currentSession': 'वर्तमान सत्र',
    'tcpUdpIcmp': 'TCP/UDP/ICMP/TUNNEL',
    'autoUpdate': 'स्वचालित अपडेट',
    'manualUpgrade': 'मैन्युअल अपग्रेड',
    'latestVersion': 'नवीनतम संस्करण',
    'upgrading': 'अपग्रेड हो रहा है...',
    'systemStatusHistory': 'सिस्टम इतिहासिक स्थिति',
    'cpuUsage': '24 घंटे का सीपीयू उपयोग',
    'memoryUsage': '24 घंटे की मेमोरी उपयोग',
    'off': 'बंद',
    'on': 'चालू',
    'nowCheckVersion': 'वर्तमान में संस्करण की जांच हो रही है',
    'nowLatestVersion': 'वर्तमान में नवीनतम संस्करण है',
    'nowNewTestVersion': 'वर्तमान में नया परीक्षण संस्करण है',
    'upgradeLoadingText': 'फर्मवेयर अपडेट हो रहा है, कृपया पेज को रिफ्रेश न करें, अपडेट पूरा होने की प्रतीक्षा करें~',
    'upgradeSuccess': 'अपग्रेड सफल, पेज को जल्द ही रिफ्रेश किया जाएगा~',
    'upgradeError': {
      'noImage': 'अपग्रेड पैकेज अनुपलब्ध है',
      'invalidImage': 'अमान्य अपग्रेड पैकेज',
      'imageArchMismatch': 'अपग्रेड पैकेज और उपकरण मेल नहीं खाते हैं',
      'noResponse':
        'उपकरण पुनः आरंभ हो गया है, कृपया अपग्रेड सफलता की पुष्टि करने के लिए कुछ समय बाद पेज को रिफ्रेश करें',
      'alreadyLatest': 'वर्तमान संस्करण सबसे नवीन है, अपग्रेड की आवश्यकता नहीं है~',
      'generic': 'अपग्रेड में त्रुटि~',
    },
    'copySuccess': 'कॉपी सफल',
    'copyFail': 'कॉपी विफल',
  },
  // techSupport
  techSupport: {
    'remoteConnection': {
      'title': 'रिमोट कनेक्शन',
      'loading': 'कनेक्शन बनाया जा रहा है...',
      'popupTitle': 'रिमोट कनेक्शन कोड',
      'intro':
        "उपयोग के लिए: <span>जब आपके उपकरण का नेटवर्क सही तरीके से काम कर रहा हो, लेकिन अन्य समस्याएं हों।</span><br />एक 'रिमोट कनेक्शन कोड' स्वयं उत्पन्न करें और इस कोड को हमारी सहायता टीम को भेजें, हमारी तकनीकी टीम आपकी समस्या का त्वरित समाधान करने में मदद करेगी। कनेक्शन कोड की प्रभावीता सुनिश्चित करने के लिए, कृपया छोटी अवधि में इसे बार-बार न उत्पन्न करें।",
      'buttonText': 'कोड उत्पन्न करें',
      'generateFeatureCodeFailure': 'कोड उत्पन्न करने में विफलता',
      'tips':
        'यदि आपको कोई समस्या होती है, तो कृपया हमारे ग्राहक सहायता को विस्तृत जानकारी भेजें। हम आपकी मदद करने के लिए प्रतिबद्ध हैं और सुनिश्चित करेंगे कि आपकी समस्या का समाधान होता है।',
      'confirm': 'कॉपी करें और बंद करें',
      'copySuccess': 'सफलतापूर्वक क्लिपबोर्ड पर कॉपी किया गया',
      'copyFailure': 'क्लिपबोर्ड पर कॉपी करने में विफल',
      'connectFailure': 'रिमोट कनेक्शन विफल',
    },
    'logDownload': {
      'title': 'लॉग डाउनलोड करें',
      'loading': 'डाउनलोड हो रहा है...',
      'intro':
        'उपयोग के लिए: <span>जब आपके उपकरण को बैकएंड पर लॉगिन करने की क्षमता होती है, लेकिन नेटवर्क कनेक्शन में समस्या होती है।</span><br />लॉग फ़ाइल को स्वयं डाउनलोड करें और इसे हमारी सहायता टीम को भेजें, हम आपकी विशेष स्थिति को समझेंगे और इसके आधार पर अधिक प्रभावी मदद प्रदान करेंगे।',
      'buttonText': 'डाउनलोड करें',
      'downloadSuccess': 'सफलतापूर्वक डाउनलोड किया गया',
      'downloadFailure': 'डाउनलोड विफल, कृपया थोड़ी देर बाद पुन: प्रयास करें',
    },
    'supportServices': {
      'title': 'आपकी आवश्यकताओं के लिए सहायता प्राप्त करें',
      'intro': 'कृपया हमें कुछ विवरण बताएं, हम आपको उपयुक्त समाधान प्रदान करेंगे।',
      'buttonText': 'सहायता प्राप्त करें',
    },
    'promiseTips': {
      'title': 'सुरक्षा गारंटी:',
      'intro':
        'कृपया आश्वस्त रहें, हम <span>रिमोट कनेक्शन और लॉग डाउनलोड कार्यक्षमता को सख्ततापूर्वक सीमित करते हैं</span> और उन्हें केवल समस्या निदान और समाधान के लिए ही उपयोग करते हैं, किसी अन्य उद्देश्य के लिए नहीं, जिससे आपकी गोपनीयता और सुरक्षा की सबसे सख्त रक्षा हो।',
    },
  },
  // builtinRules
  'builtinRules': [
    {
      'label': 'HTTPS फ़िल्टरिंग सक्षम करें (HTTP फ़िल्टरिंग शामिल)',
      'sslbypass': 'SSL बाईपास सक्षम करें',
      'value': 'youtube',
    },
  ],
  // customrules
  customrules: [
    {
      'label': 'HTTPS फ़िल्टरिंग सक्षम करें (HTTP फ़िल्टरिंग शामिल)',
      'value': 'youtube',
    },
  ],
  // tproxy
  tproxy: {
    'browserCacheWarning':
      'यदि HTTPS विज्ञापन फ़िल्टरिंग सक्षम होने के बाद विज्ञापनों को ठीक से फ़िल्टर करने में विफल रहता है, तो आपको ब्राउज़र कैश साफ़ करना होगा। प्रमाणपत्र स्थापित किए बिना केवल HTTPS फ़िल्टरिंग सक्षम करने से न केवल विज्ञापनों को फ़िल्टर करना विफल हो जाएगा, बल्कि सूची में वेबसाइटों तक सामान्य पहुंच भी प्रभावित हो सकती है।',
    'triggerRulesTipsTitle': 'सुझाव',
    'buttonAddRule': 'नियम जोड़ें',
    'buttonInstallCertificate': 'सर्टिफ़िकेट स्थापित करें',
    'missingInfoWarning': 'कृपया सही जानकारी देखें',
    'missingInfoSuccess': 'सफलतापूर्वक क्रियान्वित',
    'missingInfoError': 'क्रिया विफल',
    'certificateTip': 'सही उपयोग की सुनिश्चित करने के लिए, कृपया सर्टिफ़िकेट स्थापित करें',
    'remind': 'दोबारा दिखाएँ नहीं',
    'tip': 'सही ढंग से विज्ञापनों को रोकने के लिए निम्नलिखित चरणों का पालन करना आवश्यक है',
    'triggerRulesTips':
      'HTTPS फ़िल्टरिंग सक्षम करने के बाद, लिस्ट में सूचीबद्ध वेबसाइटों के विज्ञापनों को फ़िल्टर करने के लिए आपको अपने कंप्यूटर पर डीपर नेटवर्क का रूट सर्टिफ़िकेट स्थापित करने और विश्वसनीय बनाने की आवश्यकता होगी।',
    'triggerRulesTips2':
      'प्रमाणपत्र स्थापित किए बिना केवल HTTPS फ़िल्टरिंग सक्षम करने से न केवल विज्ञापन फ़िल्टर करने में विफल हो सकते हैं, बल्कि सूची में वेबसाइटों तक सामान्य पहुंच भी प्रभावित हो सकती है।',
    'mobileDownloadCertTips': 'कृपया सफ़ारी ब्राउज़र का उपयोग करके प्रमाणपत्र डाउनलोड और इंस्टॉल करें।',
    'androidTVTip':
      'यदि आप एंड्रॉइड टीवी का उपयोग कर रहे हैं, तो विज्ञापन के बिना वीडियो देखने के लिए कृपया ऐप डाउनलोड करें',
    'errorMessage': {
      'maxLength': 'नियमों की अधिकतम संख्या 1024 है',
      'ruleExisted': 'एक ही डोमेन और अवरोधन नियम व्यक्ति मौजूद है',
    },
    'label': {
      'domain': 'डोमेन',
      'Pattern': 'अवरोधन नियम',
      'ruleType': 'नियम का प्रकार',
      'web': 'वेब ब्राउज़र',
      'app': 'ऐप',
      'all': 'सभी',
      'Rewrite_Pattern': 'अवरोधन नियम व्यक्ति को दोहराएँ',
      'Rewrite_Info': 'अवरोधन नियम जानकारी',
      'Rewrite_Action': 'क्रिया',
      'enable': 'सक्षम करें',
      'operation': 'कार्रवाई',
      'order': 'क्रमांक',
    },
    'operation': {
      'delete': 'हटाएं',
      'edit': 'संपादित करें',
    },
    'modal': {
      'title': 'नियम जोड़ें',
      'confirmText': 'प्रस्तुत करें',
      'cancelText': 'रद्द करें',
    },
    'placeholder': {
      'domain': 'कृपया सही डोमेन दर्ज करें',
      'Pattern': 'कृपया सही अवरोध नियम अभिव्यक्ति दर्ज करें',
      'ruleType': 'कृपया नियम प्रकार चुनें',
      'Rewrite_Pattern': 'कृपया सही पुनर्लेखन नियम अभिव्यक्ति दर्ज करें',
      'Rewrite_Info': 'कृपया पुनर्लेखन नियम जानकारी दर्ज करें',
      'Rewrite_Action': 'कृपया कार्रवाई चुनें',
    },
    'builtinRules': {
      'title': 'विज्ञापन फ़िल्टर',
      'filterListtitle': 'HTTPS फ़िल्टर सूची',
      'platform': 'प्लेटफ़ॉर्म का समर्थन',
      'segOptions': ['सर्टिफिकेट स्थापना ट्यूटोरियल', 'समर्थित सूची'],
    },
    'customerRules': {
      'title': 'अपने HTTPS नियम',
    },
    guide: {
      'buttonDownloadCertificate': 'सर्टिफिकेट डाउनलोड करें',
      'buttonDownloadApp': 'SkyTube डाउनलोड करें',
      'tab': {
        'mobileInstall': 'आईफोन/आईपैड',
        'macInstall': 'मैक ओएस',
        'windowsInstall': 'विंडोज',
        'androidInstall': 'एंड्रॉइड',
        'linuxInstall': 'लिनक्स',
      },
      'mobile': {
        'videoTab': 'वीडियो',
        'textTab': 'टेक्स्ट',
        'step1': {
          'label': 'चरण 1',
        },
        'step2': {
          'label': 'चरण 2',
          'tip': 'अनुमति देने के लिए टैप करें',
        },
        'step3': {
          'label': 'चरण 3',
          'tip': "फ़ोन की 'सेटिंग्स' खोलें, 'डाउनलोड किए गए प्रोफ़ाइल' पर टैप करें",
        },
        'step4': {
          'label': 'चरण 4',
          'tip': 'इंस्टॉल पर टैप करें, फ़ोन पासवर्ड दर्ज करें',
        },
        'step5': {
          'label': 'चरण 5',
          'tip': 'इंस्टॉल पर टैप करें',
        },
        'step6': {
          'label': 'चरण 6',
          'tip': 'स्थापना पूर्ण',
        },
        'step7': {
          'label': 'चरण 7',
          'tip':
            "फ़ोन की 'सेटिंग्स' > 'सामान्य' > 'फ़ोन के बारे में' > 'सर्टिफ़िकेट विश्वास' में जाएं और हाल ही में स्थापित सर्टिफ़िकेट को सक्षम करें",
        },
      },
      'windows': {
        'step1': {
          'label': 'चरण 1',
        },
        'step2': {
          'label': 'चरण 2',
          'tip': "डाउनलोड किए गए सर्टिफ़िकेट पर क्लिक करें, 'सर्टिफ़िकेट स्थापित करें' पर क्लिक करें",
        },
        'step3': {
          'label': 'चरण 3',
          'tip': "'स्थानीय कंप्यूटर' को चुनें, 'अगला' पर क्लिक करें",
        },
        'step4': {
          'label': 'चरण 4',
          'tip': "'सभी सर्टिफ़िकेट को निम्नलिखित संग्रह में रखें' को चुनें, 'ब्राउज़ करें' पर क्लिक करें",
        },
        'step5': {
          'label': 'चरण 5',
          'tip': "'विश्वसनीय रूप से प्रमाणित करने वाले प्रमाणपत्र दाता' को चुनें, 'ठीक है' पर क्लिक करें",
        },
        'step6': {
          'label': 'चरण 6',
          'tip': "'अगला' पर क्लिक करें",
        },
        'step7': {
          'label': 'चरण 7',
          'tip': "'समाप्त' पर क्लिक करें, सर्टिफ़िकेट स्थापना पूर्ण",
        },
      },
      'mac': {
        'step1': {
          'label': 'चरण 1',
        },
        'step2': {
          'label': 'चरण 2',
          'tip':
            "डाउनलोड किए गए प्रमाणपत्र पर क्लिक करें, सिस्टम में 'प्रमाणपत्र जोड़ें' डायलॉग बॉक्स दिखाई देगा और 'कीचेन स्ट्रिंग' में 'लॉगिन' को चुनें और 'जोड़ें' दबाएं।",
        },
        'step3': {
          'label': 'चरण 3',
          'tip': "'कीचेन स्ट्रिंग एक्सेस' विंडो में, साइडबार में 'डिफ़ॉल्ट कीचेन स्ट्रिंग' को 'लॉगिन' का चयन करें।",
        },
        'step4': {
          'label': 'चरण 4',
          'tip':
            "'deeper.network' प्रमाणपत्र ढूंढें और चुनें, दायां तीर पर क्लिक करें और 'प्रोफ़ाइल दिखाएँ' पर क्लिक करें।",
        },
        'step5': {
          'label': 'चरण 5',
          'tip':
            "प्रोफ़ाइल में 'विश्वास करें' पर क्लिक करें और 'सिस्टम डिफ़ॉल्ट का उपयोग करें' को 'हमेशा विश्वास करें' के रूप में चुनें।",
        },
        'step6': {
          'label': 'चरण 6',
          'tip':
            'प्रोफ़ाइल के ऊपरी बाएं कोने में बंद करें, कंप्यूटर पासवर्ड दर्ज करें, सेटिंग अपडेट के लिए अपडेट करें।',
        },
        'step7': {
          'label': 'चरण 7',
          'tip': 'प्रमाणपत्र स्थापित हो गया।',
        },
      },
      'linux': {
        'rootTab': 'सिस्टम रूट सर्टिफिकेट आयात ट्यूटोरियल',
        'root': {
          'step1': {
            'label': 'चरण 1',
          },
          'step2': {
            'label': 'चरण 2',
            'tip':
              '<p>सर्टिफिकेट फ़ाइल को /usr/local/share/ca-certificates/ में कॉपी करें</p>\
              <p>यह निर्देशिका विशेष रूप से स्थानीय रूप से स्थापित अतिरिक्त CA सर्टिफिकेट्स को स्टोर करने के लिए इस्तेमाल की जाती है। सर्टिफिकेट फ़ाइल को PEM प्रारूप में .crt एक्सटेंशन के साथ होना चाहिए।</p>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /usr/local/share/ca-certificates/</code></div>',
          },
          'step3': {
            'label': 'चरण 3',
            'tip':
              '<p>सर्टिफिकेट स्टोर अपडेट करें</p>\
              <p>सर्टिफिकेट स्टोर को अपडेट करने के लिए update-ca-certificates कमांड का उपयोग करें, यह सिस्टम में सर्टिफिकेट्स की विश्वास श्रृंखला को स्वचालित रूप से ताज़ा करेगा।</p>\
              <div class="code"><code>sudo update-ca-certificates</code></div>\
              <p>यह कमांड /usr/local/share/ca-certificates/ निर्देशिका में सर्टिफिकेट फ़ाइलों को पढ़ेगा और उन्हें सिस्टम की विश्वास सूची में जोड़ देगा।</p>\
              <em><em class="warning">ध्यान दें:</em> विभिन्न लिनक्स वितरणों में सर्टिफिकेट स्टोर के स्थान और अपडेट कमांड भिन्न हो सकते हैं।</em><br>\
              <em>उदाहरण के लिए, रेड हैट आधारित सिस्टमों\
              (जैसे कि Fedora, CentOS) पर, आपको सर्टिफिकेट को /etc/pki/ca-trust/source/anchors/ में कॉपी करना पड़ सकता है,</em><br>\
              <em>और फिर सर्टिफिकेट स्टोर अपडेट करने के लिए update-ca-trust कमांड चलाना पड़ सकता है।</em><br>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /etc/pki/ca-trust/source/anchors/</code><br>\
              <code>sudo update-ca-trust</code></div>\
              <p>इन चरणों को चलाने के बाद, आपके द्वारा स्थापित रूट सर्टिफिकेट सिस्टम द्वारा विश्वसनीय होना चाहिए।</p>',
          },
        },
        'chrome': {
          'step1': {
            'label': 'चरण 1',
          },
          'step2': {
            'label': 'चरण 2',
            'tip': "पते में chrome://settings/certificates दर्ज करें, फिर '<b>अथॉरिटीज़</b>' टैब का चयन करें।",
          },
          'step3': {
            'label': 'चरण 3',
            'tip': 'आयात पर क्लिक करें, डाउनलोड किए गए प्रमाणपत्र ढूंढें, ओपन पर क्लिक करें',
          },
          'step4': {
            'label': 'चरण 4',
            'tip':
              "'<b>प्रमाणपत्र अथॉरिटी</b>' विंडो में सभी विकल्पों को चुनें, फिर पुष्टि के लिए ठीक करें और प्रमाणपत्र स्थापना पूर्ण करने के लिए ओके पर क्लिक करें।",
          },
        },
        'firefox': {
          'step1': {
            'label': 'चरण 1',
          },
          'step2': {
            'label': 'चरण 2',
            'tip': 'पते में about:preferences#privacy दर्ज करें, Certificates ढूंढें, View Certificates पर क्लिक करें',
          },
          'step3': {
            'label': 'चरण 3',
            'tip': 'Authorities का चयन करें, Import पर क्लिक करें',
          },
          'step4': {
            'label': 'चरण 4',
            'tip': 'डाउनलोड किए गए प्रमाणपत्र का चयन करें, Open पर क्लिक करें',
          },
          'step5': {
            'label': 'चरण 5',
            'tip': 'सभी को चुनें, OK पर क्लिक करें, प्रमाणपत्र स्थापित हो गया',
          },
        },
      },
      'android': {
        'appTab': 'ऐप',
        'browserTab': 'ब्राउज़र',
        'app': {
          'step1': {
            'label': 'चरण 1',
          },
          'step2': {
            'label': 'चरण 2',
            'tip': 'SkyTube इंस्टॉल करें, विज्ञापनों के बिना वीडियो का आनंद लें',
          },
        },
        'browser': {
          'step1': {
            'label': 'चरण 1',
          },
          'step2': {
            'label': 'चरण 2',
            'tip': "मोबाइल 'सेटिंग्स' खोलें, 'सुरक्षा' ढूंढें",
          },
          'step3': {
            'label': 'चरण 3',
            'tip': "'सुरक्षा' में जाएं, 'एन्क्रिप्शन और क्रेडेंशियल्स' ढूंढें, खोलने पर क्लिक करें",
          },
          'step4': {
            'label': 'चरण 4',
            'tip': 'प्रमाणपत्र स्थापित करें पर क्लिक करें',
          },
          'step5': {
            'label': 'चरण 5',
            'tip': 'CA प्रमाणपत्र का चयन करें',
          },
          'step6': {
            'label': 'चरण 6',
            'tip': 'अभी भी स्थापित करें पर क्लिक करें, मोबाइल पासवर्ड दर्ज करें',
          },
          'step7': {
            'label': 'चरण 7',
            'tip': 'डाउनलोड किए गए प्रमाणपत्र का चयन करें',
          },
          'step8': {
            'label': 'चरण 8',
            'tip': 'प्रमाणपत्र स्थापित हो गया संकेत देता है',
          },
        },
      },
    },
  },
  // SSLBypassCheckbox
  SSLBypassCheckbox: {
    'sslBypass': 'SSL बाईपास चालू करें',
    'sslBypassTips': 'प्रमाणपत्र स्थापित न किए गए उपकरणों को नेटवर्क से कनेक्ट करने की अनुमति देता है।',
    'sslBypassWaring': 'यदि SSL बाईपास सक्षम है, तो विज्ञापन फ़िल्टरिंग में कुछ बातें छूट सकती हैं।',
    'success': 'सफलता',
    'failed': 'विफलता',
  },
  // tunnel
  tunnel: {
    'configuredTunnel': 'कॉन्फ़िगर किया गया टनल',
    countryTooltip:
      'देश का नाम अस्पष्ट रूप से खोजें या देश कोड (बड़े अक्षरों में) दर्ज करें और वांछित देश खोजने के लिए Enter दबाएँ',
    'region': 'क्षेत्र',
    'country': 'देश या क्षेत्र',
    'activeTunnelNum': 'सक्रिय आईपी संख्या',
    'activeIp': 'सक्रिय आईपी',
    'switchNode': 'नोड स्विच करें',
    'switchIpTitle': 'आईपी स्विच करें',
    'initTunnelTitle': 'टनल को पुनः आरंभ करें',
    'initTunnelTitleTips': 'एक टनल में 3 आईपी होते हैं, टनल को रीफ़्रेश करने पर, आपके लिए तीन नए आईपी की जगह दी जाएगी।',
    'selectRegion': 'क्षेत्र चुनें',
    'selectCountry': 'देश या क्षेत्र चुनें',
    'missingInfoWarning': 'कृपया पूरी जानकारी भरें',
    'tunnelAddSuccess': 'टनल सफलतापूर्वक जोड़ा गया, पेज 3 सेकंड में रीफ़्रेश होगा।',
    'tunnelExist': 'टनल पहले से मौजूद है',
    'reachedMax': 'टनल संख्या अधिकतम सीमा तक पहुंच गई है',
    'tunnelConflict': 'जोड़ने की इच्छा रखी टनल और मौजूदा टनल {0} - {1} में टकरा रही है',
    'tunnelAddError': 'टनल जोड़ने में विफल',
    'tunnelDelSuccess': 'टनल सफलतापूर्वक हटा दिया गया, पेज 3 सेकंड में रीफ़्रेश होगा।',
    'tunnelNotExist': 'टनल मौजूद नहीं है',
    'usedByFullMode': 'टनल DPN फुल रूटिंग द्वारा उपयोग में है, हटाया नहीं जा सकता',
    usedByAccessControl: 'हटाई जाने वाली सुरंगों का उपयोग एक्सेस कंट्रोल द्वारा किया जाता है',
    'usedByBackup': 'बैकअप टनल इस टनल का उपयोग कर रहा है, हटाया नहीं जा सकता',
    'tunnelInUse': 'टनल रूटिंग टेबल द्वारा उपयोग में है, हटाया नहीं जा सकता',
    'tunnelDelError': 'टनल हटाने में विफल',
    'switchNodeSuccess': 'नोड सफलतापूर्वक स्विच किया गया, पेज 3 सेकंड में रीफ़्रेश होगा।',
    'noActiveIp': 'सक्रिय आईपी नहीं हैं जिन्हें स्विच किया जा सके',
    'ipNotChanged': 'आईपी बदली नहीं गई',
    'switchNodeError': 'नोड स्विच करने में विफल',
    'refreshLabel': 'रीफ़्रेश',
    'getNewIPLabel': 'नया आईपी प्राप्त करें',
    'refreshError': 'टनल को रीफ़्रेश करने में विफल',
    'refreshSuccess': 'रीफ़्रेश टनल अनुरोध सबमिट किया गया है, पेज 3 सेकंड में रीफ़्रेश होगा।',
    'tips': {
      'refreshTunnel': 'आपके लिए नए सक्रिय टनल को रीफ़्रेश करें।',
      'configuredTunnel':
        'आपकी मांग के अनुसार आपके द्वारा प्राथमिकता दी गई क्षेत्र टनल जोड़ने के बाद, एटमओएस वैश्विक स्तर पर आपकी मांग के अनुसार नोड की खोज करेगा।',
    },
    'nodeLbMode': {
      'label': 'नोड लोड बैलेंसिंग मोड',
      'none': {
        'label': 'सामान्य',
        'desc':
          'टनल के भीतर, सभी नेटवर्क कनेक्शन एक सक्रिय नोड के माध्यम से होते हैं, उपयोगकर्ता किसी भी समय इस नोड को स्विच कर सकता है।',
      },
      'random': {
        'label': 'यादृच्छिक',
        'desc': 'प्रत्येक कनेक्शन के लिए सिस्टम स्वचालित रूप से एक नोड का चयन करेगा।',
      },
      'domain': {
        'label': 'डोमेन ज्ञान',
        'desc':
          'सिस्टम टनल के भीतर कई नोड का उपयोग करके नेटवर्क तक पहुंचेगा, लेकिन सिस्टम एक ही नोड को एक विशेष डोमेन के लिए यात्रा करने के लिए याद रखेगा, जिससे वेबसाइट त्रुटि का जोखिम कम होगा।',
      },
      'rr': {
        'label': 'गोलमाल',
        'desc':
          'प्रत्येक कनेक्शन के समय, सिस्टम कुछ नोडों में से क्रमशः चुनेगा, जिससे आपको बेहतर नेटवर्क तेजी मिल सके।',
      },
      'failure': 'नोड लोड बैलेंसिंग मोड सहेजने में विफल',
      'success': 'नोड लोड बैलेंसिंग मोड सफलतापूर्वक सहेजा गया',
    },
  },
  // userConsent
  userConsent: {
    'notice':
      'ध्यान दें: साझा करने की सुविधा आपके सहमति के नियम और शर्तों और गोपनीयता नीति स्वीकार करने के पहले बंद होती है।',
    'consentStart': 'मैंने Deeper Network की ',
    'termsOfUseNotRead': 'आपने उपयोग की शर्तें और नियम नहीं पढ़ी हैं',
    'termsOfUseLink': 'https://shop.deeper.network/terms-of-service?lang=hi',
    'termsOfUseTitle': 'उपयोग की शर्तें और नियम',
    'consentAnd': ' और ',
    'privacyPolicyNotRead': 'आपने गोपनीयता नीति नहीं पढ़ी है',
    'privacyPolicyLink': 'https://shop.deeper.network/privacy-policy?lang=hi',
    'privacyPolicyTitle': 'गोपनीयता नीति',
    'confirmBtn': 'पुष्टि करें',
    'consentError': 'सहमति पुष्टि विफल',
  },
  // customConfigLang
  customConfigLang: {
    'missingInfoWarning': 'डोमेन गुम है',
    'invalidDomainError': 'अवैध डोमेन प्रारूप या डोमेन बहुत लंबा है',
    'whitelist': 'सफेद सूची',
    'blacklist': 'काली सूची',
    'domain': 'डोमेन',
    'whitelistAddSuccess': 'सफेद सूची में सफलतापूर्वक जोड़ा गया',
    'whitelistEntryExist': 'सफेद सूची में पहले से ही जोड़ी गई आइटम है',
    'reachedWhiteMax': 'सफेद सूची की अधिकतम सीमा तक पहुंच गई है',
    'whitelistAddError': 'सफेद सूची आइटम जोड़ने में विफल',
    'blacklistAddSuccess': 'काली सूची में सफलतापूर्वक जोड़ा गया',
    'blacklistEntryExist': 'काली सूची में पहले से ही जोड़ी गई आइटम है',
    'reachedBlackMax': 'काली सूची की अधिकतम सीमा तक पहुंच गई है',
    'blacklistAddError': 'काली सूची आइटम जोड़ने में विफल',
    'whitelistDelSuccess': 'सफेद सूची आइटम को सफलतापूर्वक हटा दिया गया',
    'whitelistDelError': 'सफेद सूची आइटम को हटाने में विफल',
    'blacklistDelSuccess': 'काली सूची आइटम को सफलतापूर्वक हटा दिया गया',
    'blacklistDelError': 'काली सूची आइटम को हटाने में विफल',
    'import': 'आयात करें',
    'fileButtonText': 'अपलोड करें',
    'fileSizeOverLimit': 'फ़ाइल अपलोड करने का सीमा 2MB से अधिक है',
    'whitelistImportSuccess': 'सफेद सूची को सफलतापूर्वक आयात किया गया, {0} आइटम आयात किए गए हैं',
    'whitelistImportError': 'सफेद सूची को आयात करने में विफल',
    'blacklistImportSuccess': 'काली सूची को सफलतापूर्वक आयात किया गया, {0} आइटम आयात किए गए हैं',
    'blacklistImportError': 'काली सूची को आयात करने में विफल',
    'noValidEntry': 'अपलोड की गई फ़ाइल में कोई भी वैध डोमेन नहीं है',
    'zeroImported': 'कोई भी डोमेन आइटम आयात नहीं किया गया',
  },
  // defaultConfigLang
  defaultConfigLang: {
    'browserCacheWarning':
      'यदि डीएनएस एड फिल्टरिंग सक्रिय नहीं किया जा सकता है तथा एडीएस सही तरीके से फिल्टर नहीं किया जा सकता, ब्राउज़र कैश श शुद्ध करन.',
    'queriesFor24Hours': 'पिछले 24 घंटों में क्वेरी',
    'totalQuery': 'कुल क्वेरी',
    'blockedQuery': 'अवरोधित क्वेरी',
    'blockedPercentage': 'अवरोधित प्रतिशत',
    'ruleTotal': 'नियम',
    'adQuery': 'विज्ञापन क्वेरी अवरोधित',
    'trackerQuery': 'ट्रैकर क्वेरी अवरोधित',
    'maliciousQuery': 'खतरनाक क्वेरी अवरोधित',
    'pornQuery': 'अश्लील क्वेरी अवरोधित',
    'legendTotal': 'कुल',
    'legendBlocked': 'अवरोधित',
    'categoryStateEnabled': 'सक्षम',
    'categoryStateDisabled': 'अक्षम',
    'categoryState2Hours': '2 घंटे के लिए अक्षम',
    'categoryState4Hours': '4 घंटे के लिए अक्षम',
    'categoryState8Hours': '8 घंटे के लिए अक्षम',
    'setCategoryStatesErr': 'फ़िल्टर स्थिति सेट करने में त्रुटि',
    'enableAll': 'सभी फ़िल्टर सक्षम करें',
    'disableAll': 'सभी फ़िल्टर अक्षम करें',
  },
  // wifiSettings
  wifiSettings: {
    'workMode': 'कार्य मोड',
    'currentWorkMode': 'वर्तमान कार्य मोड',
    'upstreamTitle': 'अपस्ट्रीम सेटिंग्स',
    'backHome': 'होम पर वापस जाएं',
    'wire-ap': 'वायर डब्ल्यूएन',
    'wlan-ap': 'वायरलेस डब्ल्यूएन',
    'downstreamTitle': 'वायरलेस लैन',
    'notConnect': 'इंटरनेट से कनेक्ट नहीं है',
    'apConfigWarning':
      'वाई-फाई एसएसआईडी और वाई-फाई पासवर्ड सहेजने के बाद, डीपर डिवाइस के माध्यम से इंटरनेट पर जाने के लिए, आपको डीपर डिवाइस हॉटस्पॉट स्कैन करके पासवर्ड लागू करना होगा',
    'attention': 'ध्यान दें',
    'buttons': {
      'scanWifi': 'स्कैन करें',
      'connectStatus': 'स्थिति',
      add: 'जोड़ें',
      'save': 'सहेजें',
      'cancel': 'रद्द करें',
      'downloadLog': 'लॉग डाउनलोड करें',
    },
    'connect': 'कनेक्ट करें',
    'forget': 'भूल जाएँ',
    'waiting': {
      'content': 'कनेक्ट हो रहा है, थोड़ी समय लग सकता है, कृपया प्रतीक्षा करें...',
      'cancelBtn': 'कनेक्शन रद्द करें',
    },
    'modals': {
      'hiddenWifi': {
        'title': 'छिपी हुई वाई-फाई जोड़ें',
        'okBtn': 'जोड़ें',
      },
      'scanWifi': {
        'title': 'चेतावनी',
        'content': 'नेटवर्क अस्थायी रूप से विच्छेदित होगा',
        'ok': 'ठीक है',
        'cancel': 'रद्द करें',
      },
    },
    'label': {
      'ssid': 'वाई-फाई नाम',
      'password': 'वाई-फाई पासवर्ड',
      'onlyUse': 'केवल उपयोग करें',
      'hidden': 'छिपी हुई एसएसआईडी',
      'freqBand': 'फ्रीक्वेंसी बैंड',
    },
    'placeholder': {
      'ssid': 'कृपया एसएसआईडी दर्ज करें',
      'password': 'कृपया पासवर्ड दर्ज करें',
    },
    'message': {
      'saveSuccess': 'सफलतापूर्वक सहेजा गया',
      'saveError': 'सहेजने में विफल, कृपया पुनः प्रयास करें',
      'connectSuccess': 'कनेक्ट हो गया',
      'forgetSuccess': 'भूल गया',
      'inputError': 'अपने इनपुट की जांच करें',
      'invalidWifiDev': 'वाई-फाई सेटिंग करने के लिए कोई उपयोगी वाई-फाई डिवाइस उपलब्ध नहीं है',
      'illegalWifiDev': 'वाई-फाई सेटिंग केवल AP राउटर मोड और वायरलेस रिले मोड में ही उपयोग की जा सकती है',
      'invalidSsid': 'केवल अक्षर, संख्या, _ और - का समर्थन किया जाता है',
      'invalidPassword': 'कृपया एक वैध पासवर्ड दर्ज करें',
      'notConnect': 'इंटरनेट से कनेक्ट नहीं हुआ',
      'retry': 'कृपया पुनः प्रयास करें',
      'connectionError': 'कनेक्शन विफल',
      'forgetError': 'भूलने में विफल',
      'wifiNotSupported': 'वाई-फाई समर्थित नहीं',
      'networkNotFound': 'नेटवर्क नहीं मिला',
      'pskNotGenerated': 'पासवर्ड उत्पन्न नहीं हुआ',
      'wrongPassword': 'गलत पासवर्ड',
      'timeout': 'कनेक्शन समय सीमा से अधिक',
      'logDownloadError': 'लॉग डाउनलोड विफल',
      'hasBeenConnected': 'कनेक्ट किया गया है।',
      'dhcpSuccess': 'सफलतापूर्वक',
      'dhcpFailure': 'विफल',
    },
    'connectionRequestedTitle': 'कनेक्शन अनुरोध किया गया',
    'connectionRequestedContent':
      "कृपया अपनी कनेक्शन स्थिति को 'स्थिति' विकल्प में जांचें। यदि आप Deeper Connect से डिस्कनेक्ट हो जाते हैं, तो DeeperWiFi से पुनः कनेक्ट करने का प्रयास करें।",
    'gotIpFromDhcpLabel': 'DHCP से IP पता प्राप्त किया',
  },
  // parentalControlData
  parentalControlData: {
    'browserCacheWarning':
      'यदि पैरेंटल कंट्रोल फ़ंक्शन सक्षम है और यह संभव नहीं है कि वयस्क साइटों या सामाजिक मीडिया को रोका जा सके, तो ब्राउज़र कैश को साफ़ करें और फ़िल्टर किए गए उपकरण को पुनः आरंभ करें।',
    'customerRule':
      'यहाँ आप अपने बच्चों के लिए अधिकांश वयस्क और सामाजिक साइटों को ब्लॉक कर सकते हैं। अगर आप अन्य साइटों को भी ब्लॉक करना चाहते हैं, तो आप अतिरिक्त वेबसाइट ब्लॉक करने के लिए अपने व्यक्तिगत डोमेन ब्लॉक कार्यक्षमता का उपयोग कर सकते हैं।',
    'customerRuleClickTips': 'कृपया {0} के लिए व्यक्तिगत डोमेन ब्लॉक पृष्ठ पर जाएं',
    'toCustomerRuleButtonText': 'यहाँ क्लिक करें',
    'parentalControl': 'पैरेंटल कंट्रोल',
    'pornQuery': 'वयस्क साइटों को ब्लॉक करें',
    'socialQuery': 'सामाजिक मीडिया को ब्लॉक करें',
    'legendBlocked': 'ब्लॉक किया गया',
    'socialStateEnabled': 'सक्षम',
    'socialStateDisabled': 'अक्षम',
    'socialState2Hours': '2 घंटे के लिए अक्षम',
    'socialState4Hours': '4 घंटे के लिए अक्षम',
    'socialState8Hours': '8 घंटे के लिए अक्षम',
    'pornStateEnabled': 'सक्षम',
    'pornStateDisabled': 'अक्षम',
    'pornState2Hours': '2 घंटे के लिए अक्षम',
    'pornState4Hours': '4 घंटे के लिए अक्षम',
    'pornState8Hours': '8 घंटे के लिए अक्षम',
    'setCategoryStatesErr': 'फ़िल्टर स्थिति सेट करने में त्रुटि',
  },
  reboot: {
    success: {
      saveConfig: 'सफलतापूर्वक बचाया',
    },
    failure: {
      getConfig: 'स्वत: पुनः बूट कॉन्फ़िगरेशन प्राप्त करने में विफल',
      saveConfig: 'स्वत: पुनः बूट कॉन्फ़िगरेशन सहेजने में विफल',
    },
    buttons: {
      reboot: 'पुनः बूट करने के लिए क्लिक करें',
      save: 'सहेजें',
    },
    week: {
      0: 'हर रोज',
      1: 'सोमवार',
      2: 'मंगलवार',
      3: 'बुधवार',
      4: 'गुरुवार',
      5: 'शुक्रवार',
      6: 'शनिवार',
      7: 'रविवार',
    },
    title: {
      manual: 'अब पुनःचालू करें',
      auto: 'निर्धारित पुनः बूट',
    },
    label: {
      period: 'अवधि',
      time: 'समय',
    },
  },
};
