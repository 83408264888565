import zh_CN from '../language/zh-cn';
import en_US from '../language/en-us';
import de_DE from '../language/de-de';
import ko_KR from '../language/ko-kr';
import fr_CA from '../language/fr-ca';
import es_ES from '../language/es-es';
import pt_PT from '../language/pt-pt';
import ja_JP from '../language/ja-jp';
import zh_TW from '../language/zh-tw';
import ru_RU from '../language/ru-ru';
import en_IN from '../language/en-in';
export default {
  en_us: en_US,
  zh_cn: zh_CN,
  de_DE: de_DE,
  ko_KR: ko_KR,
  fr_CA: fr_CA,
  es_ES: es_ES,
  pt_PT: pt_PT,
  ja_JP: ja_JP,
  zh_TW: zh_TW,
  ru_RU: ru_RU,
  en_IN: en_IN,
};
