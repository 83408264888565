export default {
  type: 'Tchinese',
  internationalize: {
    type: 'Tchinese',
    common: {
      tip_text: '提示',
      search: '査詢',
      add: '添加',
      delete: '删除',
      confirm: '確認',
      cancel: '取消',
      warning: '警告',
      utcDisclaimer: '日期為 UTC',
      toOpen: '去開啟',
    },
    certificateDialog: {
      title: '新的根CA證書',
      content: '根CA證書已更換。請下載並重新安裝。',
      dontShowAgain: '不再顯示',
    },
    request_tip: {
      error: '請求錯誤~',
    },
    header: {
      logout: '退出',
      changePassword: '修改密碼',
      userManual: '用戶手冊',
      defaultAvatar: '默認大頭照',
      'english': '英語',
      'german': '德語',
      'korean': '韓語',
      'french': '法語',
      'spain': '西班牙語',
      'portugal': '葡萄牙語',
      'japanese': '日語',
      'Tchinese': '繁體中文',
      'russian': '俄語',
      'hindi': '印地語',
      'simplechinese': '簡體中文',
    },
    home: {
      name: '首頁',
      children: {},
      content: {
        tipInput: '請輸入',
        ok: '確認',
        cancel: '取消',
        currentStatus: '目前狀態',
        statusMap: {
          Trial: '試用',
          Valid: '有效',
          Expired: '過期',
        },
        btnActive: '啟動',
        btnEdit: '修改',
        expireDate: '失效日期',
        licenseKey: '許可證序號',
      },
    },
    accessControl: {
      name: '存取控制',
    },
    tpProxy: {
      name: '透明代理',
      children: {
        customRules: {
          name: '自定義規則',
        },
        builtinRules: {
          name: '廣告過濾',
        },
        certificate: {
          name: '安裝證書',
        },
      },
    },
    help: {
      name: '幫助',
      children: {
        techSupport: {
          name: '技術支援',
        },
      },
    },
    device: {
      name: '本機相關',
      children: {
        system: {
          name: '系統資訊',
        },
        traffic: {
          name: '流量',
        },
        notification: {
          name: '日誌',
        },
      },
      content: {},
    },
    dpn: {
      name: 'DPN',
      content: {},
      children: {
        smartRoute: {
          name: '模式',
        },
        tunnel: {
          name: '隧道',
        },
        routingTable: {
          name: '智能路由表',
          content: {},
          children: {
            domainConfig: {
              name: '自定義功能變數名稱',
            },
            ipConfig: {
              name: '自定義IP',
            },
            queryRouting: {
              name: '査詢',
            },
          },
        },
      },
    },
    appRelocator: {
      name: '應用重定位',
    },
    adsBlock: {
      name: '廣告攔截',
      children: {
        domainFilter: {
          name: 'DNS 過濾',
        },
        httpsFilter: {
          name: 'HTTPS 過濾',
        },
      },
      httpssucceed: 'HTTPS過濾已成功啟用',
      httpslose: 'HTTPS 過濾已成功停用',
      saverror: '操作失敗',
      usedByAccessControl: 'HTTPS 過濾器由存取控制使用',
    },
    dpr: {
      name: '挖礦',
      children: {
        dpr: {
          name: 'DPR',
          children: {
            stakingTutorial: {
              name: '質押教程',
            },
            wallet: {
              name: '錢包',
              manager: '錢包管理',
              balance: '錢包餘額',
            },
            'Staking Mining': 'Staking 挖礦',
            transaction: {
              id: '交易 ID',
              from: '轉出地址',
              to: '轉入地址',
              time: '轉帳時間 (UTC)',
              rewardTime: '獎勵時間 (UTC)',
              amount: 'DPR數量',
              'Reward in selected period': '選定期間獎勵',
              'Total reward': '總獎勵',
              'Balance Transfer': '普通交易',
              'Staking Reward': 'Staking 獎勵',
              'PoS Reward': 'PoS獎勵',
              'Micro Payment': '微支付交易',
              stakeRelease: '線性釋放交易',
              Transaction: '交易記錄',
              Mining: '挖礦',
              'Traffic Mining': '流量挖礦',
              'Staking Mining': 'Staking 挖礦',
            },
          },
        },
      },
    },
    security: {
      name: '安全相關',
      children: {
        applications: '應用控制',
        traffic: '流量控制',
        webfilter: {
          name: '網頁過濾',
          children: {
            report: {
              name: '默認配置',
            },
            config: {
              name: 'DNS 廣告過濾',
            },
          },
        },
        sslFilter: {
          name: 'SSL過濾',
          children: {
            customDNSrules: {
              name: '自定義 DNS規則',
            },
            customHTTPSRules: {
              name: '自定義 HTTPS規則',
            },
          },
        },
      },
      content: {},
    },
    parentalControl: {
      name: '家長控制',
    },
    regionCountryTexts: {
      'LL': '不走隧道',
      'ANY': '任意國家或地區',
      // regions
      'AMER': '---美洲---',
      'ASIA': '---亞洲---',
      'AFRI': '---非洲---',
      'EURO': '---歐洲---',
      'OCEA': '---大洋洲---',
      'AMN': '北美',
      'AMC': '加勒比海地區',
      'AMM': '中美洲',
      'AMS': '南美',
      'ASC': '中亞',
      'ASE': '東亞',
      'ASW': '西亞',
      'ASS': '南亞',
      'ASD': '東南亞',
      'AFN': '北非',
      'AFM': '中非',
      'AFE': '東非',
      'AFW': '西非',
      'AFS': '南非',
      'EUN': '北歐',
      'EUE': '東歐',
      'EUW': '西歐',
      'EUS': '南歐',
      'OCP': '波利尼西亞',
      'OCA': '纽澳地區',
      'OCM': '美拉尼西亞',
      'OCN': '密克羅尼西亞',
      // countries
      'AD': '安道爾共和國',
      'AE': '阿拉伯聯合大公國',
      'AF': '阿富汗',
      'AG': '安堤瓜及巴爾布達',
      'AI': '安圭拉',
      'AL': '阿爾巴尼亞',
      'AM': '亞美尼亞',
      'AO': '安哥拉',
      'AR': '阿根廷',
      'AS': '美屬薩摩亞',
      'AT': '奧地利',
      'AU': '澳大利亞',
      'AW': '阿魯巴',
      'AX': '奧蘭',
      'AZ': '阿塞拜疆',
      'BA': '波士尼亞和黑塞哥維那',
      'BB': '巴巴多斯',
      'BD': '孟加拉',
      'BE': '比利時',
      'BF': '布吉納法索',
      'BG': '保加利亞',
      'BH': '巴林',
      'BI': '布隆迪',
      'BJ': '貝南',
      'BL': '聖巴泰勒米',
      'BM': '百慕達',
      'BN': '汶萊',
      'BO': '玻利維亞',
      'BQ': '荷蘭加勒比區',
      'BR': '巴西',
      'BS': '巴哈馬',
      'BT': '不丹',
      'BW': '波札那',
      'BY': '白俄羅斯',
      'BZ': '貝裡斯',
      'CA': '加拿大',
      'CC': '科科斯（基林）群島',
      'CD': '剛果（金）',
      'CF': '中非',
      'CG': '剛果（布）',
      'CH': '瑞士',
      'CI': '象牙海岸',
      'CK': '科克群島',
      'CL': '智利',
      'CM': '喀麥隆',
      'CN': '中華人民共和國',
      'CO': '哥倫比亞',
      'CR': '哥斯大黎加',
      'CU': '古巴',
      'CV': '佛得角',
      'CW': '庫拉索',
      'CX': '聖誕島',
      'CY': '賽普勒斯',
      'CZ': '捷克',
      'DE': '德國',
      'DJ': '吉佈提',
      'DK': '丹麥',
      'DM': '多明尼克',
      'DO': '多明尼加',
      'DZ': '阿爾及利亞',
      'EC': '厄瓜多',
      'EE': '愛沙尼亞',
      'EG': '埃及',
      'ER': '厄利垂亞',
      'ES': '西班牙',
      'ET': '衣索比亞',
      'FI': '芬蘭',
      'FJ': '斐濟',
      'FK': '福克蘭群島',
      'FM': '密克羅尼西亞聯邦',
      'FO': '法羅群島',
      'FR': '法國',
      'GA': '加彭',
      'GB': '英國',
      'GD': '格瑞那達',
      'GE': '格魯吉亞',
      'GF': '法屬圭亞那',
      'GG': '根西',
      'GH': '加納',
      'GI': '直布羅陀',
      'GL': '格陵蘭',
      'GM': '岡比亞',
      'GN': '幾內亞',
      'GP': '瓜德羅普',
      'GQ': '赤道幾內亞',
      'GR': '希臘',
      'GS': '南喬治亞和南桑威奇群島',
      'GT': '瓜地馬拉',
      'GU': '關島',
      'GW': '幾內亞·比索',
      'GY': '蓋亞那',
      'HK': '中國香港',
      'HN': '洪都拉斯',
      'HR': '克羅地亞',
      'HT': '海地',
      'HU': '匈牙利',
      'ID': '印尼',
      'IE': '愛爾蘭',
      'IL': '以色列',
      'IM': '曼島',
      'IN': '印度',
      'IO': '英屬印度洋領地',
      'IQ': '伊拉克',
      'IR': '伊朗',
      'IS': '冰島',
      'IT': '義大利',
      'JE': '澤西',
      'JM': '牙買加',
      'JO': '約旦',
      'JP': '日本',
      'KE': '肯亞',
      'KG': '吉爾吉斯',
      'KH': '高棉',
      'KI': '吉裡巴斯',
      'KM': '科摩羅',
      'KN': '聖基茨和尼維斯',
      'KR': '韓國',
      'KW': '科威特',
      'KY': '開曼群島',
      'KZ': '哈薩克共和國',
      'KP': '北韓',
      'LA': '寮國',
      'LB': '黎巴嫩',
      'LC': '聖盧西亞',
      'LI': '列支敦士登',
      'LK': '斯里蘭卡',
      'LR': '賴比瑞亞',
      'LS': '賴索托',
      'LT': '立陶宛',
      'LU': '盧森堡',
      'LV': '拉脫維亞',
      'LY': '利比亞',
      'MA': '利比亞',
      'MC': '摩納哥',
      'MD': '莫爾達瓦',
      'ME': '黑山',
      'MF': '法屬聖馬丁',
      'MG': '馬拉加西',
      'MH': '馬紹爾群島',
      'MK': '北馬其頓',
      'ML': '馬利',
      'MM': '緬甸',
      'MN': '蒙古',
      'MO': '中國澳門',
      'MP': '北馬利安納群島',
      'MQ': '馬提尼克',
      'MR': '茅利塔尼亞',
      'MS': '蒙特塞拉特',
      'MT': '瑪律他',
      'MU': '茅利塔尼亞',
      'MV': '瑪律地夫',
      'MW': '馬拉威',
      'MX': '墨西哥',
      'MY': '馬來西亞',
      'MZ': '莫三比克',
      'NA': '納米比亞',
      'NC': '新赫里多尼亞',
      'NE': '尼日',
      'NF': '諾福克島',
      'NG': '奈及利亞',
      'NI': '尼加拉瓜',
      'NL': '荷蘭',
      'NO': '挪威',
      'NP': '尼泊爾',
      'NR': '瑙魯',
      'NU': '紐埃',
      'NZ': '紐西蘭',
      'OM': '阿曼',
      'PA': '巴拿馬',
      'PE': '秘魯',
      'PF': '法屬玻裏尼西亞',
      'PG': '巴布亞新磯內亞',
      'PH': '菲律賓',
      'PK': '巴基斯坦',
      'PL': '波蘭',
      'PM': '聖皮耶與密克隆',
      'PN': '皮特凱恩群島',
      'PR': '波多黎各',
      'PS': '巴勒斯坦',
      'PT': '葡萄牙',
      'PW': '帕勞',
      'PY': '巴拉圭',
      'QA': '卡達',
      'RE': '留尼汪',
      'RO': '羅馬尼亞',
      'RS': '塞爾維亞',
      'RU': '俄羅斯',
      'RW': '盧安達',
      'SA': '沙烏地阿拉伯',
      'SB': '所羅門群島',
      'SC': '塞席爾',
      'SD': '蘇丹',
      'SE': '瑞典',
      'SG': '新加坡',
      'SH': '聖赫倫那、阿森松和特裡斯坦-達庫尼亞',
      'SI': '斯洛文尼亞',
      'SJ': '斯瓦爾巴和揚馬延',
      'SK': '斯洛文尼亞',
      'SL': '塞拉里昂',
      'SM': '聖馬利諾',
      'SN': '塞內加爾',
      'SO': '索馬利亞',
      'SR': '蘇利南',
      'SS': '南蘇丹',
      'ST': '聖多美及普林西比島',
      'SV': '薩爾瓦多',
      'SX': '荷屬聖馬丁',
      'SY': '敘利亞',
      'SZ': '史瓦帝尼',
      'TC': '特克斯和凱科斯群島',
      'TD': '查德',
      'TF': '法屬南方和南極洲領地',
      'TG': '多哥',
      'TH': '泰國',
      'TJ': '塔吉克斯坦',
      'TK': '多哥',
      'TL': '東帝汶',
      'TM': '土庫曼斯坦',
      'TN': '突尼西亞',
      'TO': '東加',
      'TR': '土耳其',
      'TT': '千裡達托貝哥',
      'TV': '吐瓦魯',
      'TW': '中國臺灣',
      'TZ': '坦尚尼亞',
      'UA': '烏克蘭',
      'UB': '美國西部',
      'UC': '美國中西部',
      'UD': '美國西南部',
      'UE': '美國東北部',
      'UF': '美國東南部',
      'UG': '烏干達',
      'US': '美國',
      'UY': '烏拉圭',
      'UZ': '烏茲別克',
      'VA': '梵蒂岡',
      'VC': '聖文森特和格林納丁斯群島St.Vincent',
      'VE': '委內瑞拉',
      'VG': '英屬維京群島',
      'VI': '美屬維爾京群島',
      'VN': '越南',
      'VU': '瓦努阿圖',
      'WF': '瓦利斯和富圖納',
      'WS': '薩摩亞',
      'XK': '科索沃',
      'YE': '葉門',
      'YT': '馬約特',
      'ZA': '南非',
      'ZM': '尚比亞',
      'ZW': '辛巴威',
    },
    setting: {
      name: '設定',
      children: {
        wifi: {
          name: 'Wi-Fi相關',
        },
        sharing: {
          name: '共享相關',
          children: {
            configure: {
              name: '共享配置',
            },
            blacklist: {
              name: '共享黑名單',
            },
            record: {
              name: '共享日誌',
            },
            traffic: {
              name: '共享流量',
            },
          },
          content: {},
        },
        rustdesk: {
          name: 'RustDesk',
          children: {
            config: {
              name: '配置',
            },
            log: {
              name: '日誌',
            },
          },
        },
        admin: {
          name: '帳戶管理',
          content: {},
          children: {
            changePassword: {
              name: '密碼',
            },
            reboot: {
              name: '重啓',
              content: {
                confirmText: '确认重启?',
                rebootLoading: '設備需要幾分鐘時間重啓，您的網路會暫時斷開...',
              },
            },
          },
        },
      },
    },
  },
  // dashboard
  dashboard: {
    name: '首頁',
    children: {},
    content: {
      backupTunnelLabel: '備用隧道',
      tipInput: '請輸入',
      hotspot: '全球連接',
      realnetworkflow: '即時流量統計',
      memory: '記憶體佔用',
      cpu: 'CPU佔用',
      notification: '系統通知',
      systeminfo: '系統資訊',
      upload: '上傳',
      download: '下載',
      systemuptime: '系統運行時間',
      uploadtotal: '總上傳量',
      downloadtotal: '總下載量',
      softwareVersion: '軟體版本',
      publicIp: '公網IP',
      unit: '組織',
      currentDpnMode: '當前DPN模式',
      ok: '確認',
      cancel: '取消',
      currentStatus: '目前狀態',
      statusMap: {
        Trial: '試用',
        Valid: '有效',
        Expired: '過期',
      },
      btnActive: '啟動',
      btnEdit: '修改',
      expireDate: '失效日期',
      licenseKey: '許可證序號',
      systemTraffic: '實时系統流量統計',
      appTraffic: '實时應用流量統計',
    },
  },
  accessControl: {
    content: {
      banner: {
        title: '存取控制',
        description: '這個開關用於統一控制啟用或停用區域網路設備的各項功能。',
      },
      onlineDevice: '在線設備',
      offlineDevice: '離線設備',
      deviceName: '設備名稱',
      unknownDevice: '未知',
      macAddress: 'MAC地址',
      ipAddress: 'IP地址',
      enableHttpsFilter: '啟用HTTPS過濾',
      routeMode: 'DPN模式',
      routeModeOptions: {
        smart: '智能路由模式',
        full: '全路由',
        disabled: '禁用',
        blackHole: '停用網路',
      },
      remark: '備註',
      pinned: '置頂',
      httpsFilterTip: '開啟HTTPS過濾之後，會過濾所有的廣告',
      routeModeTip: '此選項可以切換DPN的運作模式',
      tunnelTip: '請選擇完整路由隧道',
      success: {
        getList: '刷新設備清單成功',
        httpsFilter: '啟用HTTPS過濾成功',
        routeMode: 'DPN模式切換成功',
        regionCode: 'DPN模式切換成功',
        remark: '備註修改成功',
        pinned: '成功置頂/取消置頂。請刷新頁面',
        deleteDevices: '刪除設備成功',
      },
      failure: {
        'getSwitch': '獲取訪問控制開關失敗',
        'setSwitch': '設置訪問控制開關失敗',
        getList: '獲取設備列表失敗',
        getTunnels: '取得隧道失敗',
        setItem: '設置設備失敗',
        httpsFilter: '禁用HTTPS過濾成功',
        routeMode: 'DPN模式切換失敗',
        regionCode: 'DPN模式切換失敗',
        remark: '備註修改失敗',
        noSuchTunnel: '隧道不存在,請選擇其他隧道',
        pinned: '固定 / 取消固定失敗',
        deleteDevices: '刪除設備失敗',
        macActive: '由於設備處於活動狀態，無法刪除',
      },
      remarkLengthLimit: '長度不超過 10 個字符',
      httpsNotEnabled: 'HTTPS過濾總開關處於關閉狀態，請點擊跳轉到該頁面開啟！',
      tips: {
        opened: '訪問控制開啟的情況下，線上設備優先使用訪問控制中的DPN模式',
      },
    },
  },
  //dpnMode
  dpnMode: {
    title: '選擇DPN模式',
    selectTunnel: '選擇隧道',
    recommended: '推薦',
    apply: '應用',
    missingInfo: '缺少資訊',
    selectionTip: {
      smart: '确认要改成智能路由模式吗？',
      full: '所有流量都將通過所選隧道。 確認要改成全路由模式嗎？',
      disabled: '該模式將暫時關閉DPN網路，確認要改成DPN停用模式嗎？',
    },
    dpnModeShortList: {
      smart: 'DPN智能路由',
      disabled: 'DPN停用',
    },
    dpnModeLongList: {
      smart: '智能路由',
      full: '全路由',
      disabled: '停用',
    },
    killSwitchLabel: '終止開關',
    none: '關閉',
    backupTunnelLabel: '備用隧道',
    backupTunnelTip: '當智能路由模式配置的隧道出現問題時，系統會自動切換到備用隧道，直到默認隧道恢復正常。',
    tips: {
      killSwitch:
        'VPN斷網保護功能是指當你的VPN連接斷開時，它會自動斷開你的電腦、手機或平板電腦等設備與互聯網的連接。 這意味著如果你的VPN停止工作，你的IP地址和其他敏感資訊不會被暴露出來。 囙此，你的互聯網連接的安全性和匿名性不會受到影響。',
      smart: [
        '1.根據AtomOS內建規則，為目前造訪的網站分配智慧匹配節點，確保本地網速的同時，加速存取其他國家和地區的服務',
        '2.DPN智能路由」模式下，不僅免去傳統VPN那樣不斷切換國家的不便，也為用戶提供更快捷的網路瀏覽體驗。',
        '3.Deeper Connect內建一些知名網站和服務的路由規則。 如果您想要自訂智能路由的規則，可以在「應用重定位」和「DPN - 路由表」中進行設定。',
        '4.我們將根據使用者回饋繼續增強內建路由規則，並在「應用重定位」功能中擴大對更多應用程式的支援。',
      ],
      full: [
        '1.所有經過AtomOS處理的網路資料都將使用指定隧道，這種使用模式和傳統的VPN的使用管道類似。',
        '2.注意：採用全路由模式將覆蓋您在“應用重定位”和“DPN-路由表”中的配置。',
        '3.由於去中心化網路的特點，網路中的節點來自世界各地用戶設備，並非高速服務器，囙此您的上網速度可能會受到影響。',
        '4.如果您一定要使用全路由模式並且希望擁有高速的上網體驗，我們將來會考慮為VIP用戶提供特定的高速節點。',
      ],
      disabled: [
        '1.“DPN停用”將會關閉DPN功能，所有經過AtomOS處理的網路資料就會使用本地流量。',
        '2. Deeper Connect的其他網路安全功能依舊會開啟。',
        '3.您在訪問某些網站遇到障礙可以停用DPN來確認是網站的問題還是DPN功能的問題。',
        '4.如果您發現任何DPN功能的問題，歡迎您通過下麵的連結來上報問題： https://support.deeper.network/hc/en-us/requests/new',
      ],
    },
    success: '操作成功',
    failure: '操作失敗!',
  },
  // DefaultadBlock
  DefaultadBlock: {
    browserCacheWarning:
      '如果開啟DNS廣告過濾的情况下無法正常過濾廣告，需要清理一下瀏覽器緩存並且重啓一下被過濾的設備。',
    queriesFor24Hours: '過去24小時的請求',
    totalQuery: '請求總和',
    blockedQuery: '攔截請求',
    blockedPercentage: '攔截百分比',
    ruleTotal: '規則',
    adQuery: '攔截廣告',
    trackerQuery: '攔截追跡',
    maliciousQuery: '攔截惡意',
    pornQuery: '攔截成人片',
    legendTotal: '總和',
    legendBlocked: '攔截',
    categoryStateEnabled: '啟用',
    categoryStateDisabled: '禁用',
    categoryState2Hours: '禁用2小時',
    categoryState4Hours: '禁用4小時',
    categoryState8Hours: '禁用8小時',
    setCategoryStatesErr: '啟用/禁用過濾出錯',
    enableAll: '啟用全部過濾',
    disableAll: '禁用全部過濾',
  },
  // CustomadBlock
  CustomadBlock: {
    missingInfoWarning: '功能變數名稱缺失',
    invalidDomainError: '功能變數名稱格式有誤或功能變數名稱太長',
    whitelist: '白名單',
    blacklist: '黑名單',
    domain: '域名',
    whitelistAddSuccess: '成功添加至白名單',
    whitelistEntryExist: '白名單中已有添加項',
    reachedWhiteMax: '已達白名單上限',
    whitelistAddError: '白名單項添加失敗',
    blacklistAddSuccess: '成功添加至黑名單',
    blacklistEntryExist: '黑名單中已有添加項',
    reachedBlackMax: '已達黑名單上限',
    blacklistAddError: '黑名單項添加失敗',
    whitelistDelSuccess: '白名單項删除成功',
    whitelistDelError: '白名單項删除失敗',
    blacklistDelSuccess: '黑名單項删除成功',
    blacklistDelError: '黑名單項删除失敗',
    import: '導入',
    fileButtonText: '點擊上傳',
    fileSizeOverLimit: '上传文件超过最大尺寸2MB',
    whitelistImportSuccess: '導入白名單成功，已導入{0}項',
    whitelistImportError: '導入白名單失敗',
    blacklistImportSuccess: '導入黑名單成功，已導入{0}項',
    blacklistImportError: '導入黑名單失敗',
    noValidEntry: '上傳文件不含任何有效功能變數名稱',
    zeroImported: '未能導入任何功能變數名稱項',
  },
  // DohGuideComp
  DohGuideComp: {
    closeButton: '關閉',
    guideButton: '點擊查看',
    dohMessageClickTips: '請點擊“查看”以查看如何為您特定的瀏覽器關閉DoH的說明',
    dohMessageTxt: '為使用此功能，{0}。 DoH加密DNS請求，可能會干擾某些功能。',
    dohMessage0: '請在您的瀏覽器中禁用DoH設定',
    guideBoxTitle: 'DoH瀏覽器設定',
    guide: {
      chromeGuideImg: '/dohGuide/chrome-guide-zh.png',
      chrome: [
        {
          label: '步驟1',
          tip: '在地址欄輸入： chrome://settings/security 並按回車鍵。',
        },
        {
          label: '步驟2',
          tip: '找到“高級安全設置”部分',
        },
        {
          label: '步驟3',
          tip: '禁用“使用安全DNS”選項',
        },
      ],
      firefox: [
        {
          label: '步驟1',
          tip: '在地址欄輸入：about:config並按回車鍵。',
        },
        {
          label: '步驟2',
          tip: ' 如果出現警告頁面，請點擊“接受風險並繼續”。',
        },
        {
          label: '步驟3',
          tip: '在蒐索框中輸入：network.trr.mode並按回車鍵。',
        },
        {
          label: '步驟4',
          tip: '按兩下找到的設定項，將數值更改為5（禁用DoH），然後點擊“確定”。',
        },
      ],
      edge: [
        {
          label: '步驟1',
          tip: '在地址欄輸入： edge://settings/privacy 並按回車鍵，並找到“安全”部分。',
        },
        {
          label: '步驟2',
          tip: '關閉“使用安全DNS來確保網路安全”選項。',
        },
      ],
      operaGuideImg: '/dohGuide/opera-guide-zh.png',
      opera: [
        {
          label: '步驟1',
          tip: '在流覽器地址欄中輸入 opera://settings/?search=dns ，並按下回車鍵。',
        },
        {
          label: '步驟2',
          tip: '找到“使用DNS-over-HTTPS而不是系統的DNS設定”選項，確保該選項關閉。',
        },
      ],
    },
  },
  // appRelocatorData
  appRelocatorData: {
    guide: {
      youtube: {
        UA: 'https://www.deeper.network/pages/app-relocator?app=youtube&lang=zh',
      },
      nbaLeaguePass: 'https://www.deeper.network/pages/app-relocator?app=nbaLeaguePass&lang=zh',
      netflix: 'https://www.deeper.network/pages/app-relocator?app=netflix&lang=zh',
      dazn: 'https://www.deeper.network/pages/app-relocator?app=dazn&lang=zh',
      fubo: 'https://www.deeper.network/pages/app-relocator?app=fubo&lang=zh',
      tencentVideo: 'https://www.deeper.network/pages/app-relocator?app=tencentVideo&lang=zh',
    },
    countryLabel: '國家',
    tagLabel: '標籤',
    typeLabel: '類型',
    country: {
      'all-countries': '全世界',
      CN: '中國',
      US: '美國',
      IN: '印度',
      CA: '加拿大',
      GB: '英國',
      NA: '其他',
      NO: '挪威',
      SG: '新加坡',
      JP: '日本',
    },
    tag: {
      'all-tags': '所有標籤',
      nba: 'NBA',
      nfl: 'NFL',
      nhl: 'NHL',
      mlb: 'MLB',
      epl: 'EPL',
      ipl: 'IPL',
    },
    category: {
      'all-category': '全部類型',
      video: '影片',
      music: '音樂',
      im: '即時通訊',
      flightAndHotel: '班機&飯店',
      subscription: '訂閱',
    },
    filter: '篩選',
    reset: '重置',
    submit: '完成',
    subcategory: {
      popular: '熱門',
      sport: '體育',
      movie: '電影',
      tv: '電視',
    },
    app: {
      acornTv: 'Acorn TV',
      amazonPrimeVideo: '亞馬遜Prime影片',
      beInSports: '貝因體育',
      crunchyroll: 'Crunchyroll',
      dazn: 'DAZN',
      disneyPlus: '迪士尼+',
      netflix: '網飛',
      plex: 'Plex',
      roku: 'Roku',
      shudder: 'Shudder',
      zattoo: 'Zattoo',
      discoveryPlus: '探索+',
      paramountPlus: '派拉蒙+',
      sportsnetNow: '羅傑斯體育網',
      tsn: 'TSN',
      bbcIPlayer: '英國廣播公司iPlayer',
      hulu: '互錄',
      itv: '獨立電視網',
      slingTv: 'Sling',
      crackle: 'Crackle',
      pandora: 'Pandora',
      theCw: 'CW電視網',
      abc: '美國廣播公司',
      nbc: '全國廣播公司',
      pluto: 'Pluto TV',
      starz: 'Starz有線臺',
      tbs: '美國電影片道',
      attTv: 'AT&T TV',
      channel4: '第四臺',
      showtimeAnytime: 'Showtime',
      nbcSports: 'NBC體育',
      epixNow: 'Epix Now',
      mgmPlus: 'MGM Plus',
      xumo: 'Xumo',
      channel5: '第五台',
      usaNetwork: 'USA電視臺',
      fxNow: 'FX Now',
      vudu: 'Vudu',
      fubo: '富波電視',
      espn: '娛樂與體育節目電視網',
      tubiTv: 'Tubi',
      ukTvPlay: 'UKTV Play',
      tvPlayer: 'TVPlayer',
      peacock: '孔雀',
      pbs: '公共廣播電視',
      philo: 'Philo',
      vrv: 'VRV',
      showmax: 'Showmax',
      hboMax: 'HBO Max',
      nhl: '全國冰球聯盟',
      dsTv: 'DStv',
      sSportsPlus: 'S Sports +',
      filmrise: 'FilmRise',
      cbsSports: 'CBS體育',
      nowTV: 'Now TV',
      molotovTv: 'Molotov TV',
      mlb: '美國職業棒球大聯盟',
      britBox: 'BritBox',
      stirr: 'Stirr',
      skyGo: 'Sky Go',
      hotstar: '迪士尼+ Hotstar',
      sonyLiv: 'SonyLIV',
      nbaLeaguePass: 'NBA聯盟通行證',
      amcPlus: 'AMC+',
      f1Tv: 'F1TV',
      frndlyTv: 'Frndly TV',
      myTf1: '法國電視一臺',
      qqMusic: 'QQ音樂',
      spotify: 'Spotify',
      tidal: 'Tidal',
      youtube: 'YouTube',
      youTubeMusic: 'YouTube音樂',
      weChat: '微信',
      whatsApp: 'WhatsApp',
      line: 'Line',
      signal: 'Signal',
      agoda: 'Agoda',
      expedia: '智遊網',
      flyScanner: 'Fly Scanner',
      googleFlights: 'Google Flights',
      qqSports: '騰訊體育',
      skySports: '天空體育',
      rakutenTv: '樂天電視',
      fox: 'FOX',
      nflNetwork: 'NFL Network',
      peacocktv: '英超',
      zee5: 'Zee5',
      mxPlayer: 'MX Player',
      tencentVideo: '騰訊影片',
      iqiyi: '愛奇藝',
      mangoTV: '芒果TV',
      migu: '咪咕影片',
      bilibili: '嗶哩嗶哩',
      youku: '優酷',
      altBalaji: 'ALT Balaji',
      willowTv: 'Willow TV',
      foxSports: 'FOX體育',
      crave: 'Crave',
      cbcGem: 'CBC Gem',
      jiocinema: 'JioCinema',
      Telegram: 'Telegram',
      Messenger: 'Messenger',
      ChatGPT: 'ChatGPT',
      CanalPlus: 'CanalPlus',
    },
    tip: {
      video: '常用的當前影片服務隧道',
      music: '常用的當前音樂服務隧道',
      im: '常用的當前即時通訊隧道',
      flightAndHotel: '常用的當前訂機票飯店隧道',
      subscription: '常用的當前網上訂閱服務隧道',
      backupTunnel: '備用隧道已開啟，智能路由模式下添加的隧道如果暫時沒有可用節點，系統將默認使用備用隧道 - {0}',
      fullTunnel: '在全路由模式下，所有應用程序都將默認使用{0}隧道。',
      noTunnelNodes: '節點數為0，無法使用。 請轉至隧道頁面並重繪{0}隧道的節點。',
      noTunnelNodesOkText: '去重繪',
      noTunnelNodesCancelText: '關閉',
      noTunnelAdded: '您尚未添加此隧道。 請轉至隧道頁面並添加{0}隧道以進行使用。',
      noTunnelAddedOkText: '去添加',
      noTunnelAddedCancelText: '取消',
      tunnelDeletedText: '{0} - {1} 隧道已被删除',
      netflix: '解决你使用Netflix時遇到的播放錯誤的問題',
      fubo: 'Fubo TV可以讓你以極低成本觀看觀看英格蘭足球直播',
      tencentVideo: '觀看一場NBA比賽約$1',
      dazn: 'DAZN可以讓你以極低成本觀看NFL、NFL、MLB、NBA、EPL，僅需17.18美元',
      youtube: '如何讓你以極低成本享受Youtube Premium',
    },
    intro: {
      acornTv:
        'Acorn TV提供來自英國和其他地方的世界級懸疑、劇情和喜劇節目的串流服務。 您可以連續觀看經典系列劇集，或在其中發現新的最愛節目。',
      abc: '在abc.com上線上觀看ABC節目。 獲取獨家影片和免費劇集。',
      f1Tv:
        'F1 TV現已在Web、應用程序、Google TV、Apple TV、Roku和Amazon Fire TV上提供。 全程直播所有F1比賽。 直播轉播所有大獎賽的賽道訓練。',
      youtube:
        'YouTube的官方頻道幫助你瞭解全球的最新和熱門內容。 觀看必看的影片，涵蓋音樂、文化和互聯網現象等各個領域。',
      netflix:
        'Netflix是一項流媒體服務，提供了廣泛的屢獲殊榮的電視劇、電影、動漫、紀錄片等內容，可通過數千個互聯網連接設備進行觀看。',
      fubo:
        '在电视上观看Fubo的最简单方法是使用Roku、Apple TV、Amazon Fire TV或Chromecast。只需在您的设备上安装Fubo应用程序即可进行流媒体播放。',
      disneyPlus: '從新發佈的作品到你最喜愛的經典之作，過去、現在和未來都屬於你。 所有你期待的和你未曾預料的',
      crunchyroll:
        '通過Crunchyroll探索最佳的動漫和漫畫流媒體內容。 享受《鬼滅之刃》、《海賊王》、《進擊的巨人》和《我的英雄學院》等熱門作品。',
      hulu:
        '線上觀看電視劇和電影。 流媒體播放《實習醫生格蕾》、《我們這一天》、《鮑勃漢堡店》、《布魯克林九九》、《帝國》、《周六夜現場》等熱門電視劇集，以及流行電影。',
      nbaLeaguePass: 'NBA League Pass*的訂閱為球迷提供了整個賽季和休賽期的比賽直播、點播以及更多內容的存取權限。',
      peacock:
        '獲取Peacock並流媒體直播體育賽事、獨家原創內容、熱門節目和大片電影。 頂級熱門作品、經典收藏、現場精彩以及更多內容，現在在Peacock上流媒體播放。 紀錄片、兒童電影和節目、全天候的Peacock頻道、Hallmark頻道等',
      qqSports:
        '騰訊體育是中國知名的體育入口网站，主要為您提供以下欄目：國內足球、國際足球、NBA、CBA、綜合體育、奧運、直播、彩票、競猜等…',
      rakutenTv:
        '直播電視|免費的前15個頻道·免費|免費觀看熱門電影·商店|熱門電影購買或租賃·類型·免費|最近免費添加的內容·免費',
      espn: '訪問ESPN獲取實时比分、精彩瞬間和體育新聞。 在ESPN+上觀看獨家賽事，並參與幻想體育遊戲',
      beInSports:
        '透過beIN SPORTS USA揭開體育世界的面紗。 獲取主要足球聯賽等的獨家精彩重播、最新新聞、數據、比分和賽程。',
      dazn: 'DAZN是全球首個真正專注於實时體育直播的流媒體平臺…',
      cbsSports:
        '免費的24小時體育新聞網路。 觀看CBS Sports HQ，提供關於比賽的所有重要資訊。 更多精彩集錦、頂級投注和成功的幻想建議。',
      fox:
        '觀看FOX體育，並查看實时比分、賠率、球隊新聞、選手新聞、直播、影片、統計資料、排名和賽程，涵蓋NFL、MLB、NASCAR、WWE、NBA、NHL等內容。',
      nflNetwork: '獲取NFL頻道的資訊、節目更新等！ NFL Network是唯一由NFL擁有和運營的全年運營網路。',
      peacocktv: '在NBC.com上觀看當前和經典NBC節目的完整集數。 此外，您還可以找到剪輯、預告片、照片和獨家線上功能。',
      sportsnetNow:
        '《MLB頂級錶現》·MLBSportsnet影片：藍鳥隊的斯普林格以領先擊球的管道慶祝生日，打出遠離本壘方向的全壘打·MLBSportsnet影片：勇士隊的阿庫尼亞Jr.',
      nbcSports: '獲取NBC Sports的最新體育新聞和比分，保持與時俱進。',
      tsn:
        '請訪問TSN網站以獲取關於澳網、NFL季後賽、超級碗、NHL交易截止日期等的最新體育新聞報導、比分、精彩瞬間和評論。',
      nhl: '官方的國家冰球聯盟（National Hockey League）網站包括新聞、名單、統計資料、賽程、球隊和影片等內容。',
      hotstar:
        'Disney+ Hotstar是印度最大的高級流媒體平臺，擁有超過10萬小時的戲劇和電影資源，涵蓋了17種語言，並提供所有主要體育賽事的直播。',
      disneyPlus:
        '從最新版本到你最喜歡的經典作品，過去、現在和未來都屬於你。 你所期望的一切，以及你所沒有的一切。 ©2023迪士尼。 品牌：漫威，皮克斯，迪士尼，國家地理，星球大戰。',
      skySports:
        '觀看您最喜愛的體育賽事的最佳直播報導：足球、高爾夫、橄欖球、板毬、F1賽車、拳擊、美式足球、籃球，以及最新的體育新聞…',
      sonyLiv:
        '頂級高級流媒體平臺，您可以在此觀看熱門電視節目、電影、體育賽事和網路劇。 盡情享受您最喜愛的直播電影片道的線上觀看體驗。',
      jiocinema:
        '觀看最新的電影、電視節目、Voot節目、現場體育賽事和精彩片段，使用印地語、英語和其他地區語言跨各種類型線上觀看。',
      amazonPrimeVideo:
        '流式傳輸電影、節目、獲獎的亞馬遜原創內容、高級頻道、體育賽事等。 用免費試用來觀看令人上癮的電視劇、大片、熱門節目等。 支持4K+HDR的內容。 熱門劇集和電影。 新發佈的內容。 在任何設備上觀看。',
      zee5:
        '您現在可以以獨家50%的折扣購買ZEE5 ™ 上的您最喜愛的Desi內容。 立即購買，盡情觀看您在ZEE5 ™ 上的最愛內容。 年度套餐享受50%的折扣。 購買連結。 擁有4000部以上的超級爆款電影，1600個以上的節目，以及260部原創作品。 支持18種語言。',
      mxPlayer:
        'MX Player是印度最高級的OTT服務，您可以在其中播放最新的音樂影片，觀看新舊電影、電視節目、喜劇節目，流行歌曲等。',
      tencentVideo:
        '騰訊影片致力於打造中國領先的線上影片媒體平臺，以豐富的內容、極致的觀看體驗、便捷的登入管道、24小時多平臺無縫應用體驗以及快捷分享的產品特性，主要滿足用戶線上…',
      iqiyi:
        '愛奇藝（iQIYI.COM）是擁有海量、優質、高清的網路影片的大型影片網站，專業的網路影片播放平臺。 愛奇藝影視內容豐富多元，涵蓋電影、電視劇、動漫、綜藝、生活、…',
      youku: '優酷-為好內容全力以赴-海量正版高清影片線上觀看。',
      migu:
        '咪咕影片全程直播北京冬奧會等國內外各大體育賽事，CBA直播，亞冠、中超、西甲、德甲、意甲、歐聯、歐冠直播，女排、桌球、滑冰、斯諾克直播，WWE、UFC直播，…',
      mangoTV: '芒果TV-人人都在看的線上影片網站-線上觀看熱門綜藝節目、最新電影和電視劇。',
      nbc: '在NBC.com上觀看當前和經典NBC節目的完整劇集。 此外，您還可以找到片段、預告片、照片和獨家線上內容。',
      foxSports:
        '觀看FOX體育，並查看實时比分、賠率、球隊新聞、選手新聞、直播、影片、統計資料、排名和賽程，涵蓋NFL、MLB、NASCAR、WWE、NBA、NHL等內容。',
      nfl: '獲取NFL頻道的資訊、節目更新等！ NFL Network是唯一由NFL擁有和運營的全年運營網路。',
      willowTv:
        '成功登入後，您可以在Willow上觀看直播、精彩集錦和重播。 Spectrum（斯佩克特魯姆），Optimum（奧普蒂姆），Fios（菲奧斯），Dish（迪什），Optimum TV（奧普蒂姆電視），Xfinity（康卡斯特）和Time Warner（時代華納）等服務商都可使用。',
      mlb: '我們的報導內容包括音訊和影片片段、採訪、統計資料、賽程安排以及獨家報導故事。',
      hboMax:
        'HBO Max是一個獨立的流媒體平臺，將所有HBO內容與更多受歡迎的電視劇、大片電影和全新的Max Originals捆綁在一起，適合所有人觀看。',
      amcPlus:
        '擺脫平凡，今天在AMC+上探索更多獨特、引人入勝的體驗。 獨家內容，扣人心弦的電影和經典系列劇集-在AMC+的史詩系列中找到一切。 優質劇情片。 點播服務。 獨家內容。 獨家電影。 新劇集。 無廣告。',
      attTv: '獲取我們最佳的電視和互聯網優惠，包括DIRECTV和家庭互聯網的優惠，包括AT&T Fiber。',
      britBox:
        '暢享大量英國電視劇的連續播放，包括懸疑、喜劇、劇情、紀錄片、生活方式等多種類型，這是迄今最大的英國電視劇流媒體收藏。',
      crackle:
        '在Crackle上，免費觀看好萊塢電影-未經剪輯和修改。 涵蓋您喜愛的動作、恐怖、科幻、犯罪和喜劇等各種類型。 只需點擊播放即可。',
      discoveryPlus:
        '您可以在移動設備、平板電腦、電腦、遊戲機和連接電視上進行流媒體播放。· 這裡有適合每個人的內容！· discovery+是食品、家居等領域的流媒體服務提供者。',
      mgmPlus: '訂閱MGM+，即可暢享數千部無廣告的電影和電視劇。 隨時取消訂閱。',
      filmrise: '我們積極應對當今不斷變化的行業特性，採用多層次策略，致力於提供最佳的電影和電視內容。',
      frndlyTv:
        '使用Roku、Amazon Fire TV或Frndly TV，您可以觀看40多個直播頻道和點播內容。 其中包括全部三個Hallmark頻道、A&E等。 您還可以在移動設備上隨時隨地觀看。',
      fxNow: '在FX、FXX和FXM網路上觀看FX原創電視節目、電影和直播電視。',
      paramountPlus:
        '在一個平臺上流媒體播放數千集劇集、現場體育賽事和獨家原創內容。 訪問數百部標誌性電影，從屢獲殊榮的經典電影到熱門新片應有盡有。 發現新劇集進行連續觀看。 熱門真人秀系列。 熱門MTV節目。 超過30000集劇集。 突發新聞報導。 熱門CBS節目。',
      pbs:
        'POV電影以其難忘的敘事和及時性而聞名。 立即觀看！ POV是電視上歷史最悠久的獨立紀錄片展示節目。 瞭解更多！ 獨立非虛構作品。 超過500部電影呈現。 難以忘懷的故事。',
      philo:
        '為什麼要為電視付更多錢？ Philo每月僅需25美元，提供直播電視和點播內容。 您可以在所有設備上流媒體播放您喜愛的節目、電影等。',
      plex:
        'Plex是一款免費的串流平台，無需信用卡、訂閱費用或其他隱藏費用。 為保持我們的服務免費，我們包含廣告以實現盈利。',
      pluto:
        '從最新的熱門電影到您喜愛的劇集完整季度，應有盡有。 數百個頻道。 零花費。 適用於所有設備。 立即開始觀看！ 適用於所有設備。 連續觀看。 點播電視和電影。 數百個頻道。 立即流媒體播放。 永不付費。',
      roku:
        'Roku設備使得流媒體電視變得簡單。 從播放機、智慧電視，甚至智能家居產品，讓您的家庭更加安全，找到適合您的完美Roku產品…',
      showtimeAnytime:
        '免費試用SHOWTIME，即可流媒體播放原創系列劇、電影、體育賽事、紀錄片等內容。 此外，您還可以訂購按次付費的拳擊比賽-無需訂閱。',
      shudder:
        '從每月僅4.99美元的套餐開始，您可以在一個平臺上獲得Shudder的全部內容，並觀看《行屍走肉》宇宙、安·賴斯等熱門系列劇。 免費試用Shudder…',
      slingTv:
        'Sling TV是第一個基於應用的電視服務，可讓您通過互聯網流媒體播放直播電視和點播內容。 無論您身在何處，都可以觀看直播節目…',
      starz:
        'STARZ通過大膽的敘事將多樣的視角呈現生活。 注册以流媒體播放原創系列劇、電影、額外內容等，隨時點播，無廣告。',
      stirr:
        '…和娛樂節目。 直播和點播。 STIRR City和National已設定為您的本地頻道。 開始觀看。 00:05。 100多個頻道，超過5000小時的電視和…',
      tbs:
        '《在高地上》（In the Heights）· 《Dynamite》本周节目 · TBS上的MLB季后赛预告 · 美国老爸的多元宇宙（第18季第14集）',
      theCw:
        'CW網路的官方主頁，提供《全美足球隊》（All American）、《超人與洛伊絲》（Superman & Lois）、《行俠仗義》（Walker）、《FBoy Island》等精彩系列劇集、電影、體育賽事等。',
      tubiTv: 'Tubi是領先的免費高級點播影片流媒體應用程序。 我們擁有超過50000部電影和電視節目的最大內容庫，提供最佳的…',
      usaNetwork:
        '您可以在USANetwork.com上觀看當前和經典的USA節目的完整劇集。 此外，您還可以找到劇集片段、預告片、照片和獨家線上特色內容.',
      vudu:
        '使用Vudu租賃、購買和觀看電影和電視節目。 您可以在Vudu應用上線上觀看，或使用您喜愛的連接設備觀看。 無需訂閱，免費註冊。',
      xumo: 'Xumo成立的目的是為整個娛樂行業開發和提供下一代流媒體平臺。 瞭解我們的使命，並參與其中…',
      jiocinema:
        '觀看最新的電影、電視節目、Voot節目、現場體育賽事和精彩片段，使用印地語、英語和其他地區語言跨各種類型線上觀看。',
      altBalaji:
        '線上高清觀看最新的網路劇、原創作品和電影。 可從驚悚、動作、成人、喜劇、家庭劇等多種類型中選擇，並支持多種語言。',
      bilibili:
        '嗶哩嗶哩（bilibili.com）是國內知名的影片彈幕網站，這裡有及時的動漫新番，活躍的ACG氛圍，有創意的Up主。 大家可以在這裡找到許多歡樂。',
      crave: '一個由恐怖電影經典、令人恐懼的電視劇系列和萬聖節劇集精心策劃的合集… 喜劇界中的意外名聲和社會壓力。',
      cbcGem: "在任何設備上觀看Schitt's Creek、Luther、Kim's Convenience等熱門節目以及電影和直播電視。",
      bbcIPlayer: '觀看直播BBC電影片道、欣賞您錯過的電視節目並在BBC iPlayer上觀看獨家內容。',
      channel4: '線上觀看您最喜歡的節目，來自Channel 4、E4和Walter Presents',
      channel5:
        '歡迎來到My5，您可以在這裡追趕和流媒體觀看您最喜歡的來自Channel 5、5STAR、5USA、5SELECT、5ACTION等頻道的劇集合集。',
      dsTv: '最好的電視娛樂節目觸手可及。 觀看最新的體育賽事、電影、連續劇和真人秀，並訪問DStv電視指南。',
      molotovTv: 'Molotov是免費的。 請隨意新增其他功能。· Molotov象徵著電視的未來·電視界的Spotify·電視可以是…',
      myTf1:
        '觀看TF1、TMC、TFX、TF1 Séries Films和LCI等直播節目，超過50個獨家專題頻道以及超過10000小時的影片內容，僅在MYTF1上…',
      sSportsPlus:
        '通過Sports Plus，您可以每月享受超過700小時的現場直播，並獲得從Serie A到La Liga，從Formula 1到NBA，從EuroLeague到UFC的數百種體育內容，一切盡在您的指尖…',
      showmax: 'Showmax的訂閱費用各不相同，因為Showmax提供了各種計畫以滿足不同的偏好和預算，價格僅從R39開始…',
      itv: 'ITVX是英國最新鮮的流媒體服務，提供獨家新節目、大片電影、現場活動和成千上萬部劇集，全部在一個平臺上。',
      pandora: '在全新的Pandora上播放你喜愛的歌曲、專輯、播放清單和播客。 注册訂閱計畫，暢享無廣告、按需播放。',
      qqMusic:
        'QQ音樂是騰訊公司推出的一款網路音樂服務產品，海量音樂線上試聽、新歌熱歌線上首發、歌詞翻譯、手機鈴聲下載、高品質無損音樂試聽、海量無損曲庫、正版音樂下載、空間…',
      spotify: 'Spotify是一種數位音樂服務，讓您可以訪問數百萬首歌曲。',
      tidal: 'TIDAL是一個以藝術家為先、以粉絲為中心的音樂流媒體平臺，為全球音樂社區提供超過1億首高保真音質的歌曲。',
      weChat: '可在各種平臺上使用； 享受群聊； 支持語音、照片、影片和文字消息。',
      whatsApp:
        '使用WhatsApp Messenger與朋友和家人保持聯系。 WhatsApp是免費的，提供簡單、安全、可靠的消息和通話功能，可在…',
      line: '新的通訊應用程序，可以讓您在任何時間和任何地點免費進行語音通話和發送免費消息，全天候24小時！',
      signal: '免費分享文字、語音消息、照片、影片、GIF和檔案。 Signal使用您手機的數據連接，以避免簡訊和彩信費用。',
      agoda:
        '立即預訂，稍後付款，免費取消。 24/7提供您語言的實时客戶服務。 從經濟型到豪華型，飯店和住宅。 您可信賴的房間和班機旅行夥伴。',
      expedia:
        '歡迎來到您的一站式旅行網站，實現您夢寐以求的假期。 將您的住宿與租車或班機捆綁在一起，可以節省更多。 蒐索我們的靈活選項以找到最適合您的…',
      flyScanner:
        '輕鬆獲取從19美元起的折扣班機。 在一次蒐索中比較並預訂，快捷方便。 比較便宜的班機，飯店和租車。 找到數以千計的優惠交易，獲取最好的一個！',
      nowTV:
        '一個NOW會員資格的費用是多少？ NOW會員資格讓您能按照自己的管道觀看想看的內容。 您可以加入娛樂，影院，體育和Hayu。',
      TVPlayer:
        'TVPlayer是最受歡迎的電影片道和高品質點播節目的所在地，這些節目只在TVPlayer上。 觀看40多個現場頻道和數千…',
      ukTvPlay: 'UKTV Play是Dave，Drama，W & Yesterday頻道的點播之家。 觀看直播電視，流媒體和追趕您喜歡的節目。',
      zattoo: '在所有設備上直播和時移您喜歡的電視節目。 最佳的全高清畫面品質。 比有線電視更好。 提供點播和直播電視內容',
      skyGo:
        '通過Sky包在您的手機、平板電腦、筆記型電腦或電腦上，或者通過Apple TV設備，隨時隨地觀看直播、按需節目和追播電視，並且可以輕鬆在不同設備間切換',
      Telegram: 'Telegram旨在提供安全、快速、簡潔的通訊體驗，並以其強大的加密功能和豐富的功能而聞名。',
      Messenger:
        'Messenger是Facebook提供的即時通訊應用程序，它允許用戶通過文字消息、語音通話、視頻通話、發送圖片、表情符號等方式與他人進行溝通。Messenger最初是作為Facebook網站的一個功能，後來發展成為一個獨立的移動應用程序，並提供了更多的功能和服務。',
      ChatGPT:
        'ChatGPT是由OpenAI開發的人工智能語言模型，基於GPT（Generative Pre-trained Transformer）架構。它通過大規模的自然語言處理數據進行訓練，能夠理解和生成自然語言文本。',
      CanalPlus:
        'CANAL+是一家法國的付費電視頻道，它是法國最大的付費電視網絡之一，也是歐洲最重要的付費電視服務提供商之一。該頻道提供了各種各樣的節目，包括電影、體育、新聞、紀錄片、電視劇等',
    },
    selectTunnel: '選擇隧道',
    notSetLabel: '不設定',
    directAccessLabel: '不走隧道',
    otherTunnelLabel: '其他隧道',
    recommendedTunnelLabel: '推薦隧道',
    error: {
      getApps: '獲取應用失敗',
      getTunnels: '獲取隧道清單失敗',
      invalidApp: '無效的應用',
      tunnelNotExist: '隧道不存在',
      set: '設定隧道失敗',
      unset: '取消設定隧道失敗',
    },
    success: {
      set: '設定隧道成功',
      unset: '取消設定隧道成功',
    },
    searchInputPlaceholder: '搜尋所需服務',
    discountIntro: percent => `每月節省 ${percent} 訂閱費`,
    discountPercent: percent => `-${percent} （美元）/月`,
    guideButtonLabel: '查看詳情',
  },
  // betanet
  betanet: {
    bindWalletSuccess: '成功綁定錢包',
    deviceBound: '設備當前已與另一錢包綁定',
    walletTaken: '錢包已被另一設備佔用',
    deviceTypeIncompatible: '設備與錢包不相容',
    invalidMnemonic: '無效的錢包助記詞',
    invalidPrivateKey: '無效的錢包私密金鑰',
    bindWalletError: '綁定錢包失敗',
    notBound: '當前用戶未綁定錢包',
    unbindWalletSuccess: '成功解綁錢包',
    noActiveBinding: '設備未與指定錢包綁定',
    unbindWalletError: '解綁錢包失敗',
    walletManagement: '錢包管理',
    bindWallet: '綁定錢包',
    downloadPrivateKey: '下載私密金鑰',
    downloadKeyError: '下載私密金鑰失敗',
    unbindBtnText: '解綁錢包',
    unbindWarning: '務必確保您已下載私密金鑰，請輸入“我已下載私密金鑰”並繼續。',
    unbindWarningWords: '我已下載私密金鑰',
    generateWallet: '生成錢包',
    verifyMnemonic: '驗證助記詞',
    mnemonicMismatch: '助記詞驗證失敗',
    bindBtn1Text: '綁定錢包（助記詞）',
    bindBtn2Text: '綁定錢包（私密金鑰）',
    dprManagement: 'DPR管理',
    balanceLabel: 'DPR餘額',
    balance: '餘額',
    creditLabel: '信用',
    operationLabel: '操作',
    amtOwingLabel: '系統已為您墊付',
    dailyConsumedLabel: '當日使用流量',
    dailySharedLabel: '當日共享流量',
    deposit: '存入',
    withdraw: '提取',
    publicKey: '公開金鑰',
    mnemonicInstruction: '請記下助記詞. 您需要在下一步中進行驗證.',
    enterMnemonic: '輸入助記詞',
    enterMnemonicTip: '請按順序點擊助記詞。',
    resetMnemonic: '重置',
    uploadPrivateKey: '上傳私密金鑰',
    getWalletError: '獲取錢包資訊失敗',
    deviceWalletMismatch: '設備與錢包不匹配',
    walletNotFound: '未找到錢包',
    generateWalletError: '生成錢包失敗',
    missingMnemonic: '助記詞缺失',
    missingPrivateKeyFile: '私密金鑰檔案缺失',
    privateKeyFileOversize: '私密金鑰檔案超過10KB限額',
    transferTitle: '轉帳',
    transferHistoryTitle: '轉帳記錄',
    transferTo: '收款人',
    transferAmt: '轉帳數額（最多到6比特小數）',
    invalidTo: '無效的收款人',
    invalidAmt: '無效的轉帳數額',
    amtOutOfRange: '轉帳金額必須為正且不得超過100萬',
    transferSuccess: '轉帳成功',
    senderRecipientSame: '發款人不可與收款人相同',
    recipientNotFound: '未找到收款人',
    insufficientFund: '因餘額不足而轉帳失敗',
    transferError: '轉帳失敗',
    votingResNotRetrieved: '未獲取委託結果',
    candidateSelected: '您委託的驗證候選人被選中',
    candidateNotSelected: '您委託的驗證候選人未被選中',
    votingResNotReady: '當前不可査詢委託結果',
    notVoted: '您未委託任何驗證候選人',
    candidateNotFound: '驗證候選人未找到',
    votingResRetrievalErr: '獲取委託結果失敗',
    from: '轉出地址',
    to: '轉入地址',
    type: '類型',
    amount: '數量',
    time: '時間',
    txnTypes: {
      101: '初始獎勵',
      201: '手動轉帳',
      301: '委託報酬',
      401: '微支付',
    },
    walletAddress: '錢包地址',
    notInGenesisNodeList: '創世節點只能綁定在創世礦機上。',
    onChainStatusLabel: '上鏈狀態',
    onChainStatusDesc: {
      unknown: '-',
      onChain: '是',
      notOnChain: '否',
    },
    pwMgmtSection: {
      title: '密碼管理',
      setBtnText: '設置密碼',
      resetBtnText: '重置密碼',
    },
    passwordModal: {
      title: '設定錢包密碼',
      onePlaceholder: '輸入密碼',
      twoPlaceholder: '重複輸入密碼',
      btnText: '確認',
    },
    setPasswordMsg: {
      mismatch: '密碼不匹配',
      weak: '密碼長度必須至少為8個字元，並且包含小寫，大寫和數位字元',
      alreadySet: '錢包密碼已設定過',
      genericErr: '設定錢包密碼失敗',
      success: '設定錢包密碼成功',
    },
    authModal: {
      title: '錢包密碼',
      inputPlaceholder: '輸入錢包密碼',
      btnText: '繼續',
    },
    passwordNotSet: '錢包密碼未設定',
    passwordIncorrect: '錢包密碼錯誤',
    resetModal: {
      disclaimer: '一旦重置錢包密碼，您的設備將與當前綁定的任何錢包解綁. 請輸入“我確認重置錢包密碼”以便繼續',
      consent: '我確認重置錢包密碼',
    },
    resetPasswordMsg: {
      noActiveBinding: '設備未與指定錢包綁定',
      unbindErr: '解綁錢包失敗',
      genericErr: '重置錢包密碼失敗',
      success: '重置錢包密碼成功',
    },
    unstaking: {
      label: '質押資訊',
      getErr: '獲取質押終止選擇失敗',
      alreadyChosen: '您已經選擇過終止質押',
      expired: '質押終止期已結束',
      invalidCreditData: '該錢包未入網',
      phase1IsNotOver: '該錢包第一期質押活動未結束',
      setErr: '設定質押終止選擇失敗',
      announcementTitle: '親愛的staking節點用戶：',
      announcement: {
        continueStaking:
          '質押續約：您的staking活動已自動進入第二期； 第二期質押期為6個月。 基礎質押（Basic Mining）&創世節點（Genesis）的APY於第二個期仍保持不變。',
        duringStaking: '第二期質押期間：您可以隨時點擊該按鈕終止質押； 這個選擇是不可逆！',
        stopStaking: '質押終止：您將進入3個月的線性釋放階段；',
        releaseStaking: '質押釋放：DPR將每天自動釋放到您的DeeperChain錢包。',
      },
      tipTitle: '友情提醒：',
      tip: {
        afterStopStaking_1: '1. 終止質押後，質押獲得的信用將會被抹除（質押期間因頻寬分享積累的信用會被保留）',
        afterStopStaking_2:
          '2. 取消創世節點的質押將失去其創世位置，將永遠失去作為創世節點的機會。 您的設備將無法立即參與基礎質押2.0，但通過頻寬共享產生的有機信用評分將繼續。',
        afterStopStaking_3: '3. 終止質押後，您DPR的質押獎勵將會立即停止',
        afterStopStaking_4: '4. 終止質押後，將無法立即獲得重新質押的機會（需等待後續公告）',
        afterStopStaking_5: '5. 終止質押後，第一期270次獎勵全部達到後才會終止',
      },
      btn: {
        cancel: '明白，保持質押',
        ok: '退出質押',
      },
    },
    staking: {
      walletInfo: '錢包資訊',
      campaignLabel: '質押活動類型',
      campaignText: {
        0: '黃金創世節點',
        1: '白銀創世節點',
        2: '質押挖礦1.0',
        3: 'DPRB 挖礦',
        4: '質押挖礦2.0',
        7: '質押挖礦2.0',
        8: '質押挖礦2.0',
      },
      creditLabel: '現時信用分',
      creditMaxLabel: '信用分最大值',
      creditLevelLabel: '信用等級',
      stakeAndBurn: '質押與燃燒',
      stakeForCredit: '新增質押',
      dailyReward: '日收益',
      view: '查看',
      payment: '需支付',
      insufficientBalance: '餘額不足',
      stakeSuccess: '請求已發送，請耐心等待信用分更新。',
      stakeError: '請求發送失敗，請檢查錢包相關資訊或諮詢客服。',
      burnForCredit: '燃燒DPR補信用分',
      burnSuccess: '請求已發送，請耐心等待信用分更新。',
      burnError: '請求發送失敗，請檢查錢包相關資訊或諮詢客服。',
    },
  },
  // ChangePasswordData
  ChangePasswordData: {
    successMsg: '修改密碼成功',
    newPasswordMismatch: '兩次輸入的新密碼不匹配',
    oldPasswordMismatch: '舊密碼錯誤',
    samePasswordError: '新密碼與舊密碼相同',
    genericError: '修改密碼出錯',
    title: '修改密碼',
    oldPwd: '請輸入舊密碼',
    newPwd: '請輸入新密碼',
    newPwdAgain: '再次輸入新密碼',
    okText: '確認',
  },
  //dailyTraffic
  dailyTraffic: {
    historySize: '天數',
    chartTitle: '流量',
    uptimeChartTitle: '運行時間',
    hour: '小時',
    minute: '分',
    second: '秒',
  },
  // flow
  flow: {
    totalTrafficLabel: '流量總計',
    localTraffic: '本地流量',
    clientTraffic: '用戶端流量',
    serverTraffic: '服務器流量',
    upload: '上傳',
    download: '下載',
    sessionSpeed: '會話速度',
  },
  // login
  login: {
    pageTitle: '歡迎來到Deeper Network！',
    username: '用戶名',
    password: '密碼',
    btnText: '登入',
    successMsg: '登入成功',
    missingField: '用戶名或密碼缺失',
    wrongField: '用戶名或密碼錯誤',
    invalidToken: '無效登入數據',
    expiredToken: '登入數據已過期',
    genericError: '登入錯誤',
    tooManyAttempts: '登入嘗試次數過多',
  },
  //notification
  notification: {
    search: '搜尋',
    CP: '控制層',
    DP: '數據層',
    MP: '管理層',
  },
  // DomainConfig
  domainConfig: {
    whiteList: '智能路由清單',
    blackList: '直達路由清單',
    domain: '域名',
    tunnel: '隧道',
    selectTunnel: '選擇隧道',
    region: '區域',
    country: '國家或地區',
    noTunnel: '不走隧道',
    convertedTip: '網址已自動轉換成功能變數名稱',
    urlInputPlaceholder: '例如： https://www.google.com 或google.com',
    missingInfoWarning: '請填寫完整資訊',
    invalidDomainError: '功能變數名稱格式有誤或功能變數名稱太長',
    whitelistAddSuccess: '成功添加至智能路由清單',
    existInBlacklist: '添加項存在於直達路由清單中',
    whitelistEntryExist: '智能路由清單中已有添加項',
    tunnelNotExist: '隧道不存在，無法添加',
    whitelistAddError: '智能路由列表項添加失敗',
    blacklistAddSuccess: '成功添加至直達路由清單',
    existInWhitelist: '添加項存在於智能路由清單中',
    blacklistEntryExist: '直達路由清單中已有添加項',
    blacklistAddError: '直達路由列表項添加失敗',
    conflict: '由於衝突無法添加項',
    whitelistDelSuccess: '智能路由列表項删除成功',
    domainNotExist: '所選功能變數名稱不存在',
    whitelistDelError: '智能路由列表項删除失敗',
    blacklistDelSuccess: '直達路由列表項删除成功',
    blacklistDelError: '直達路由列表項删除失敗',
    backupTips: '備用隧道已開啟，智能路由模式下添加的隧道如果暫時沒有可用節點，系統將默認使用備用隧道 - {0}。',
    tips: {
      smartRouteList: '根據您的需求，您可以手動添加您想要訪問的功能變數名稱以及對應的國家。',
      directRouteList: '在此處添加的功能變數名稱將不通過DPN隧道，而是直接使用本地網路。',
    },
  },
  // ipConfig
  ipConfig: {
    whiteList: '智慧IP清單',
    blackList: '直達IP清單',
    ip: 'IP',
    tunnel: '隧道',
    selectTunnel: '選擇隧道',
    region: '區域',
    country: '國家或地區',
    noTunnel: '不走隧道',
    convertedTip: '網址已自動轉換成IP',
    urlInputPlaceholder: '例如： https://xxx.xxx.xxx.xxx 或xxx.xxx.xxx.xxx',
    missingInfoWarning: '請填寫完整資訊',
    invalidIPError: 'IP格式有誤',
    whitelistAddSuccess: '成功添加至智慧IP清單',
    existInBlacklist: '添加項存在於直達IP清單中',
    whitelistEntryExist: '智慧IP清單中已有添加項',
    tunnelNotExist: '隧道不存在，無法添加',
    whitelistAddError: '智慧IP列表項添加失敗',
    blacklistAddSuccess: '成功添加至直達IP清單',
    existInWhitelist: '添加項存在於智慧IP清單中',
    blacklistEntryExist: '直達IP清單中已有添加項',
    blacklistAddError: '直達IP列表項添加失敗',
    whitelistDelSuccess: '智慧IP列表項删除成功',
    ipNotExist: '所選IP不存在',
    whitelistDelError: '智慧IP列表項删除失敗',
    blacklistDelSuccess: '直達IP列表項删除成功',
    blacklistDelError: '直達IP列表項删除失敗',
    backupTips: '備用隧道已開啟，智能路由模式下添加的隧道如果暫時沒有可用節點，系統將默認使用備用隧道 - {0}。',
    tips: {
      smartRouteList: '根據您的需求，您可以手動添加您想要訪問的IP以及對應的國家。',
      directRouteList: '在此處添加的IP將不通過DPN隧道，而是直接使用本地網路。',
    },
  },
  // queryRouting
  queryRouting: {
    title: '査詢路由',
    searchLabel: '查詢配置',
    search: '搜尋',
    searchResult: '搜索結果: ',
    invalidRoutingError: '査詢格式有誤',
    defaultConfig: '默認配置',
    userConfig: '用戶配置',
    routingNotFound: '路由未找到',
    queryRoutingError: '査詢路由失敗',
    convertedTip: '網址已自動轉換成功能變數名稱或IP',
    urlInputPlaceholder: '例如：google.com或xxx.xxx.xxx.xxx',
  },
  // rustdesk
  rustdesk: {
    ipLabel: '您的IP地址是',
    noPubIp: '您沒有公共 IP.',
    vpnUse: '您可以使用Wireguard VPN埠轉發來獲得公共IP. 請注意這個public IP只能用於RustDesk服務而不能提供DPN服務.',
    config: {
      title: 'RustDesk配置',
      switch: '開/關',
      forceKey: '要求公開金鑰一致',
      relay: '英文逗號分隔的中繼服務器（host:port），例如www.example.com:21116192.168.1.1:21117',
      port: '埠 (2-65532)',
      saveBtnText: '保存',
      pubKey: '公開金鑰',
      emptyPubKey: '沒有公開金鑰',
      resetBtnText: '重置公開金鑰',
    },
    vpn: {
      title: 'Wireguard VPN 埠轉發',
      switch: '開/關',
      ip: 'Wireguard 服務器 IP',
      port: 'VPN 轉發埠',
    },
    guide: {
      title: '配置教程',
      stepOne: '點擊如圖所示按鈕',
      stepTwoA: '將對應欄目填寫到表單中並點擊保存',
      idAndRelay: 'ID/中繼服務器',
      idServer: 'ID服務器:',
      relayServer: '中繼服務器:',
      apiServer: 'API服務器:',
      serverKey: 'Key:',
      stepTwoB: '或者您還可以複製配置到剪切板並在RustDesk中導入',
      copyBtnText: '複製伺服器配置到剪切板',
    },
    success: {
      setSwitch: '切換RustDesk成功',
      setForced: '要求公開金鑰一致成功',
      setRelayAndPort: '中繼和埠保存成功',
      resetPubKey: '重置公開金鑰成功',
      setVpnSwitch: '切換Wireguard VPN成功',
      copyToClipboard: '複製到剪貼板成功',
    },
    error: {
      getNetworkAddress: '獲取IP失敗',
      getConfig: '獲取RustDesk配置失敗',
      setSwitch: '切換RustDesk失敗',
      setForced: '要求公開金鑰一致失敗',
      invalidPort: '無效埠',
      invalidRelay: '無效中繼',
      setRelayAndPort: '保存中繼和埠失敗',
      resetPubKey: '重置公開金鑰失敗',
      getVpnConfig: '獲取Wireguard VPN配置失敗',
      setVpnSwitch: '切換Wireguard VPN失敗',
      copyToClipboard: '複製到剪貼板失敗',
      getVersion: '獲取版本號失敗',
      getHbbrLog: '獲取HBBR日誌失敗',
      getHbbsLog: '獲取HBBS日誌失敗',
    },
  },
  // sharing
  sharing: {
    sharingTitle: '共享: ',
    btSharingTitle: 'Bit Torrent 共享: ',
    enabled: '啟用',
    disabled: '停止',
    sharingTrafficLimit: '月共享流量上限',
    sharingBandwidthLimit: '共享頻寬限速',
    unlimited: '不限速',
    limited: '限速',
    setLabel: '設定',
    checkStartEndTime: '請選擇開始時間和結束時間',
    time: '時間',
    record: '記錄',
    startTime: '開始時間',
    endTime: '結束時間',
    keyword: '關鍵字',
    search: '蒐索',
    webToSharingTitle: '應用網頁過濾規則',
    webToSharingDesc: '應用程式裝置內建的DNS廣告過濾和自訂DNS過濾規則',
    yes: '是',
    no: '否',
    download: '明文日誌',
    export: '加密日誌',
    success: {
      enableTrafficLimit: '已啟用每月共享流量限制。 請設定限制！',
      setSharingTrafficLimit: '共享流量上限設定成功',
      enableBandwidthLimit: '共享頻寬限速已開啟. 請設定上限於15Mbps和1024Mbps之間！',
      setSharingBandwidthLimit: '共享頻寬限速設定成功',
    },
    errors: {
      getSharingState: '獲取共享狀態失敗',
      switchSharingState: '切換共享狀態失敗',
      exceedsLimit: '啟用共享失敗，已超流量上限',
      getSharingTrafficLimit: '獲取共享流量上限失敗',
      setSharingTrafficLimit: '設定共享流量上限失敗',
      invalidTrafficLimit: '流量限制必須為正數',
      setSharingBandwidthLimit: '設定共享頻寬上限失敗',
      invalidBandwidthLimit: '頻寬上限需在15Mbps和1024Mbps之間',
      getSharingFilterConfig: '獲取共享過濾配置失敗',
      setSharingFilterConfig: '設定共享過濾配置失敗',
    },
  },
  // system
  system: {
    hardwareInfo: '硬體資訊',
    softwareInfo: '軟體資訊',
    networkAddress: '網路位址',
    sessionInfo: '會話資訊',
    upgradeInfo: '升級資訊',
    sn: '序號',
    cpu: 'CPU',
    cores: '核心',
    memory: '已用/可用',
    systemVersion: '系統版本',
    appidSignVersion: '流量協定識別特徵庫',
    urlFilterSignVersion: '網頁過濾特徵庫',
    https: 'HTTPS',
    dns: 'DNS',
    rules: '規則',
    ip: 'IP地址',
    routerMac: '路由器Mac',
    gatewayMac: '閘道Mac',
    maxSession: '最大工作階段數',
    currentSession: '當前工作階段數',
    tcpUdpIcmp: 'TCP/UDP/ICMP/TUNNEL',
    autoUpdate: '自動更新',
    manualUpgrade: '手動陞級',
    latestVersion: '最新版本',
    upgrading: '正在陞級...',
    systemStatusHistory: '系統歷史狀態',
    cpuUsage: '24小時CPU使用率',
    memoryUsage: '24小時記憶體使用率',
    off: '否',
    on: '是',
    nowCheckVersion: '當前正在檢查版本',
    nowLatestVersion: '當前已是最新版本',
    nowNewTestVersion: '當前正在使用版本',
    upgradeLoadingText: '正在更新固件，請不要刷新頁面，耐心等待更新完成~',
    upgradeSuccess: '陞級成功，即將刷新頁面~',
    upgradeError: {
      noImage: '升級包缺失',
      invalidImage: '無效的升級包',
      imageArchMismatch: '升級包與設備不符',
      noResponse: '設備已經重啓，請您稍後刷新頁面來確認陞級是否成功',
      alreadyLatest: '當前版本是最新的，不必陞級~',
      generic: '陞級出錯~',
    },
    copySuccess: '複製成功',
    copyFail: '複製失敗',
  },
  // techSupport
  techSupport: {
    remoteConnection: {
      title: '遠程連接',
      loading: '連接中...',
      popupTitle: '遠程連接碼',
      intro:
        '適用情况：<span>當你的設備網路運行正常，但遇到其他功能問題時。</ span><br />手動生成一個“遠程連接連接碼”，將此連接碼發送給我們的客服團隊，我們的科技團隊會快速定位並幫助您解决問題。 為了確保連接碼的有效性，請避免在短時間內重複生成。',
      buttonText: '生成連接碼',
      generateFeatureCodeFailure: '生成連接碼失敗',
      tips: '如果您遇到任何問題，請將詳細資訊發送給我們的客戶支援。 我們致力於幫助您，並確保您的問題得到解决。',
      confirm: '複製並關閉',
      copySuccess: '成功複製到剪貼板',
      copyFailure: '複製到剪貼板失敗',
      connectFailure: '遠程連接失敗',
    },
    logDownload: {
      title: '下載日誌',
      loading: '下載中...',
      intro:
        '適用情况：<span>當你的設備能够登入後臺，但網路連接出現問題時。</ span><br />手動下載日誌檔並發送給我們的客服團隊，我們會瞭解你面臨的具體情況，並據此提供更有效的幫助。',
      buttonText: '下載',
      downloadSuccess: '下載成功',
      downloadFailure: '下載失敗，請稍後重試',
    },
    supportServices: {
      title: '獲取你所需的協助',
      intro: '請告訴我們一些具體資訊，我們會為你提供適用的解決方案。',
      buttonText: '獲取幫助',
    },
    promiseTips: {
      title: '安全保障承諾：',
      intro:
        '請放心，我們<span>嚴格限制遠程連接和日誌下載功能</span>僅用於問題診斷和解决，絕不用於其他任何目的，確保您的隱私和安全得到最嚴格的保護。',
    },
  },
  // builtinRules
  builtinRules: [
    {
      label: '開啟HTTPS過濾（包含HTTP過濾）',
      sslbypass: '開啟SSL旁路',
      value: 'youtube',
    },
  ],
  // customrules
  customrules: [
    {
      label: '開啟HTTPS過濾（包含HTTP過濾）',
      value: 'youtube',
    },
  ],
  // tproxy
  tproxy: {
    browserCacheWarning:
      '如果開啟https廣告過濾的情况下無法正常過濾廣告，需要清理一下瀏覽器緩存； 僅開啟HTTPS過濾而未安裝證書，不僅不能過濾廣告，還可能影響清單中網站的正常訪問。',
    triggerRulesTipsTitle: '提示',
    buttonAddRule: '添加規則',
    buttonInstallCertificate: '安裝證書',
    missingInfoWarning: '請檢查是否填寫正確',
    missingInfoSuccess: '操作成功',
    missingInfoError: '操作失敗',
    certificateTip: '為了保證正常使用，請確保已經安裝證書',
    remind: '不再提示',
    tip: '需要按照以下步驟，才能正確的攔截廣告',
    triggerRulesTips:
      '啟用HTTPS過濾後，為了使Deeper Connect能對清單內的網站廣告進行過濾，您需要在您的電腦上安裝並信任Deeper Network的根證書。',
    triggerRulesTips2: '僅開啟HTTPS過濾而未安裝證書，不僅不能過濾廣告，還可能影響清單中網站的正常訪問。',
    mobileDownloadCertTips: '請使用Safari瀏覽器下載安裝證書',
    androidTVTip: '如果你正在使用Android TV，請下載APP來進行使用',
    errorMessage: {
      maxLength: '規則最多1024條',
      ruleExisted: '已存在相同的功能變數名稱和攔截規則運算式',
    },
    label: {
      domain: '域名',
      Pattern: '攔截規則運算式',
      ruleType: '規則類型',
      web: '瀏覽器',
      app: 'APP',
      all: '全部',
      Rewrite_Pattern: '複寫規則運算式',
      Rewrite_Info: '複寫規則資訊',
      Rewrite_Action: '行為',
      enable: '開啟',
      operation: '操作',
      order: '序號',
    },
    operation: {
      delete: '删除',
      edit: '編輯',
    },
    modal: {
      title: '添加規則',
      confirmText: '提交',
      cancelText: '取消',
    },
    placeholder: {
      domain: '請輸入正確功能變數名稱',
      Pattern: '請輸入正確攔截規則運算式',
      ruleType: '請選擇規則類型',
      Rewrite_Pattern: '請輸入正確複寫規則運算式',
      Rewrite_Info: '請輸入複寫規則資訊',
      Rewrite_Action: '請選擇行為',
    },
    builtinRules: {
      title: '廣告過濾',
      filterListtitle: 'HTTPS 過濾清單',
      platform: '支持平台',
      segOptions: ['證書安裝教程', '支持清單'],
    },
    customerRules: {
      title: '自定義 HTTPS 規則',
    },
    guide: {
      buttonDownloadCertificate: '點擊下載證書',
      buttonDownloadApp: '點擊下載SkyTube',
      tab: {
        mobileInstall: 'iPhone/iPad',
        macInstall: 'Mac OS',
        windowsInstall: 'Windows',
        androidInstall: 'Android',
        linuxInstall: 'Linux',
      },
      mobile: {
        videoTab: '影片',
        textTab: '文字',
        step1: {
          label: '步驟1',
        },
        step2: {
          label: '步驟2',
          tip: '點擊允許',
        },
        step3: {
          label: '步驟3',
          tip: '打開手機“設定”，點擊“已下載描述檔案”',
        },
        step4: {
          label: '步驟4',
          tip: '點擊安裝，輸入手機密碼',
        },
        step5: {
          label: '步驟5',
          tip: '點擊安裝',
        },
        step6: {
          label: '步驟6',
          tip: '安裝完成',
        },
        step7: {
          label: '步驟7',
          tip: '打開手機“設定”>“通用”>“關於本機”>“證書信任設定”，啟用剛才安裝的證書',
        },
      },
      windows: {
        step1: {
          label: '步驟1',
        },
        step2: {
          label: '步驟2',
          tip: '點擊下載的證書，點擊“安裝證書”',
        },
        step3: {
          label: '步驟3',
          tip: '選擇“存儲位置”為“本地電腦”，點擊“下一步”',
        },
        step4: {
          label: '步驟4',
          tip: '選擇“將所有的證書都放入下列存儲”，點擊“瀏覽”',
        },
        step5: {
          label: '步驟5',
          tip: '選擇“受信任的根證書頒發機构”，點擊“確定”',
        },
        step6: {
          label: '步驟6',
          tip: '點擊“下一步”',
        },
        step7: {
          label: '步驟7',
          tip: '點擊“完成”，證書安裝完成',
        },
      },
      mac: {
        step1: {
          label: '步驟1',
        },
        step2: {
          label: '步驟2',
          tip: '點擊下載的證書，系統會出現“添加證書”彈窗，並在“鑰匙串”中選擇“登入”後按“添加”。',
        },
        step3: {
          label: '步驟3',
          tip: '在“鑰匙串訪問”視窗中，在側欄中“默認鑰匙串”選擇“登入”。',
        },
        step4: {
          label: '步驟4',
          tip: '找到“deeper.network”證書並選中，右鍵點擊“顯示簡介”。',
        },
        step5: {
          label: '步驟5',
          tip: '點擊簡介中的“信任”，將“使用系統默認”勾選為“始終信任”。',
        },
        step6: {
          label: '步驟6',
          tip: '點擊簡介左上角關閉，輸入電腦密碼，點擊更新設定。',
        },
        step7: {
          label: '步驟7',
          tip: '證書安裝完成。',
        },
      },
      linux: {
        rootTab: '系統根證書匯入教程',
        root: {
          step1: {
            label: '步驟1',
          },
          step2: {
            label: '步骤2',
            tip:
              '<p>將證書文件複製到 /usr/local/share/ca-certificates/<p>\
              <p>此目錄專門用於存儲額外本地安裝的 CA 證書。證書文件應為 PEM 格式，並具有 .crt 副檔名。</p>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /usr/local/share/ca-certificates/</code></div>',
          },
          step3: {
            label: '步骤3',
            tip:
              '<p>更新證書存儲庫</p>\
               <p>使用 update-ca-certificates 命令更新證書存儲庫，該命令將自動刷新系統中的證書信任鏈。</p>\
               <div class="code"><code>sudo update-ca-certificates</code></div>\
               <p>此命令讀取 /usr/local/share/ca-certificates/ 目錄中的證書文件，並將其添加到系統的信任列表中。</p>\
               <em><em class="warning">注意：</em>不同的 Linux 發行版可能有不同的證書存儲位置和更新命令。</em><br>\
               <em>例如，在基於 Red Hat 的系統\
               （如 Fedora、CentOS）上，您可能需要將證書複製到 /etc/pki/ca-trust/source/anchors/，</em><br>\
               <em>然後運行 update-ca-trust 命令來更新證書存儲庫。</em><br>\
               <div class="code"><code>sudo cp DeeperNetwork.crt /etc/pki/ca-trust/source/anchors/</code><br>\
               <code>sudo update-ca-trust</code></div>\
               <p>執行這些步驟後，您所安裝的根證書現在應該會被系統信任。</p>',
          },
        },
        chrome: {
          step1: {
            label: '步驟1',
          },
          step2: {
            label: '步驟2',
            tip: '輸入 chrome://settings/certificates 在地址欄，然後選擇“<b>Authorities</b>”選項卡。',
          },
          step3: {
            label: '步驟3',
            tip: '點擊Import，找到剛才下載的證書，點擊Open',
          },
          step4: {
            label: '步驟4',
            tip: '在“<b>證書授權中心</b>”視窗中勾選所有選項，然後點擊確定以完成證書安裝。',
          },
        },
        firefox: {
          step1: {
            label: '步驟1',
          },
          step2: {
            label: '步驟2',
            tip: '在地址欄輸入about:preferences#privacy，找到Certificates，點擊View Certificates',
          },
          step3: {
            label: '步驟3',
            tip: '選中Authorities，點擊Import',
          },
          step4: {
            label: '步驟4',
            tip: '選中剛才下載的證書，點擊Open',
          },
          step5: {
            label: '步驟5',
            tip: '全部勾選，點擊OK，證書安裝完成',
          },
        },
      },
      android: {
        appTab: 'APP',
        browserTab: '瀏覽器',
        app: {
          step1: {
            label: '步驟1',
          },
          step2: {
            label: '步驟2',
            tip: '安裝SkyTube，開始享受沒有廣告打擾的影片',
          },
        },
        browser: {
          step1: {
            label: '步驟1',
          },
          step2: {
            label: '步驟2',
            tip: '打開手機“設定”，找到“安全”',
          },
          step3: {
            label: '步驟3',
            tip: '進入“安全”，找到“加密和憑據”，點擊進入',
          },
          step4: {
            label: '步驟4',
            tip: '點擊安裝證書',
          },
          step5: {
            label: '步驟5',
            tip: '選擇CA證書',
          },
          step6: {
            label: '步驟6',
            tip: '點擊仍然安裝，輸入手機密碼',
          },
          step7: {
            label: '步驟7',
            tip: '選擇下載好的證書',
          },
          step8: {
            label: '步驟8',
            tip: '提示已安裝CA證書，證書安裝成功',
          },
        },
      },
    },
  },
  // SSLBypassCheckbox
  SSLBypassCheckbox: {
    sslBypass: '開啟SSL旁路功能',
    sslBypassTips: '允許沒有安裝證書的設備連接網路。',
    sslBypassWaring: '如果啟用 SSL Bypass，則廣告過濾可能將會略過',
    success: '操作成功',
    failed: '操作失敗',
  },
  // tunnel
  tunnel: {
    configuredTunnel: '自定義隧道',
    countryTooltip: '模糊搜尋國家名稱或輸入國家代碼（大寫），回車即可搜尋所需國家',
    region: '區域',
    country: '國家或地區',
    activeTunnelNum: '活躍IP數',
    activeIp: '活躍IP',
    switchNode: '切換活躍IP',
    switchIpTitle: '切換IP',
    initTunnelTitle: '重新初始化隧道',
    initTunnelTitleTips: '一個隧道有3個IP，重繪隧道，會為您更換三個新的IP。',
    selectRegion: '選擇區域',
    selectCountry: '選擇國家或地區',
    missingInfoWarning: '請填寫完整資訊',
    tunnelAddSuccess: '隧道添加成功，頁面將在3秒內重繪。',
    tunnelExist: '隧道已存在',
    reachedMax: '隧道數量已達上限',
    tunnelConflict: '欲添加隧道與已有隧道{0} - {1}衝突',
    tunnelAddError: '隧道添加失敗',
    tunnelDelSuccess: '隧道删除成功，頁面將在3秒內重繪。',
    tunnelNotExist: '隧道不存在',
    usedByFullMode: '隧道正在被DPN全路由使用中，無法被删除',
    usedByAccessControl: '要刪除的隧道由存取控制使用',
    usedByBackup: '備用隧道正在使用此隧道，無法删除',
    tunnelInUse: '隧道正在被路由表使用中，無法被删除',
    tunnelDelError: '隧道删除失敗',
    switchNodeSuccess: '切換節點成功，頁面將在3秒內重繪。',
    noActiveIp: '無活躍IP可切換',
    ipNotChanged: 'IP未改變',
    switchNodeError: '切換節點失敗',
    refreshLabel: '刷新',
    getNewIPLabel: '獲取新IP',
    refreshError: '重繪隧道失敗',
    refreshSuccess: '已提交重繪隧道請求，頁面將在3秒內重繪。',
    tips: {
      refreshTunnel: '為您重繪新的活躍隧道。',
      configuredTunnel: '手動添加您需要的區域隧道後，AtomOS會在全球範圍內蒐索符合您要求的節點。',
    },
    nodeLbMode: {
      label: '節點選擇模式',
      none: {
        label: '常規',
        desc: '在隧道內，所有網路連接都通過一個活躍節點，用戶可以隨時切換此節點。',
      },
      random: {
        label: '隨機',
        desc: '系統為每個連接自動隨機選擇一個節點。',
      },
      domain: {
        label: '網域感知',
        desc:
          '系統會使用隧道內的多個節點來訪問網路，但是系統會記住並始終將特定功能變數名稱的訪問路由到同一個節點，降低網站訪問錯誤的風險。',
      },
      rr: {
        label: '輪轉',
        desc: '每次連接時，系統會在幾個節點中按順序選擇，助您實現更佳的網路加速。',
      },
      failure: '保存節點負載均衡模式失敗',
      success: '保存節點負載均衡模式成功',
    },
  },
  // userConsent
  userConsent: {
    notice: '注意：共享功能在您同意使用條款和條件及隱私政策之前是關閉的。',
    consentStart: '我已閱讀並同意Deeper Network的',
    termsOfUseNotRead: '您還沒有閱讀使用條款和條件',
    termsOfUseLink: 'https://shop.deeper.network/terms-of-service?lang=tw',
    termsOfUseTitle: '使用條款和條件',
    consentAnd: ' 及 ',
    privacyPolicyNotRead: '您還沒有閱讀隱私政策',
    privacyPolicyLink: 'https://shop.deeper.network/privacy-policy?lang=tw',
    privacyPolicyTitle: '隱私政策',
    confirmBtn: '確認',
    consentError: '確認同意失敗',
  },
  // customConfigLang
  customConfigLang: {
    missingInfoWarning: '功能變數名稱缺失',
    invalidDomainError: '功能變數名稱格式有誤或功能變數名稱太長',
    whitelist: '白名單',
    blacklist: '黑名單',
    domain: '域名',
    whitelistAddSuccess: '成功添加至白名單',
    whitelistEntryExist: '白名單中已有添加項',
    reachedWhiteMax: '已達白名單上限',
    whitelistAddError: '白名單項添加失敗',
    blacklistAddSuccess: '成功添加至黑名單',
    blacklistEntryExist: '黑名單中已有添加項',
    reachedBlackMax: '已達黑名單上限',
    blacklistAddError: '黑名單項添加失敗',
    whitelistDelSuccess: '白名單項删除成功',
    whitelistDelError: '白名單項删除失敗',
    blacklistDelSuccess: '黑名單項删除成功',
    blacklistDelError: '黑名單項删除失敗',
    import: '導入',
    fileButtonText: '點擊上傳',
    fileSizeOverLimit: '上傳文件超過最大尺寸2MB',
    whitelistImportSuccess: '導入白名單成功，已導入{0}項',
    whitelistImportError: '導入白名單失敗',
    blacklistImportSuccess: '導入黑名單成功，已導入{0}項',
    blacklistImportError: '導入黑名單失敗',
    noValidEntry: '上傳文件不含任何有效功能變數名稱',
    zeroImported: '未能導入任何功能變數名稱項',
  },
  // defaultConfigLang
  defaultConfigLang: {
    'browserCacheWarning':
      '如果開啟DNS廣告過濾的情况下無法正常過濾廣告，需要清理一下瀏覽器緩存並且重啓一下被過濾的設備。',
    queriesFor24Hours: '過去24小時的請求',
    totalQuery: '請求總和',
    blockedQuery: '攔截請求',
    blockedPercentage: '攔截百分比',
    ruleTotal: '規則',
    adQuery: '攔截廣告',
    trackerQuery: '攔截追跡',
    maliciousQuery: '攔截惡意',
    pornQuery: '攔截成人片',
    legendTotal: '總和',
    legendBlocked: '攔截',
    categoryStateEnabled: '啟用',
    categoryStateDisabled: '禁用',
    categoryState2Hours: '禁用2小時',
    categoryState4Hours: '禁用4小時',
    categoryState8Hours: '禁用8小時',
    setCategoryStatesErr: '啟用/禁用過濾出錯',
    enableAll: '啟用全部過濾',
    disableAll: '禁用全部過濾',
  },
  // wifiSettings
  wifiSettings: {
    workMode: '工作模式',
    currentWorkMode: '當前工作模式',
    upstreamTitle: '上行設定',
    backHome: '返回首頁',
    'wire-ap': '有線 WAN',
    'wlan-ap': '無線 WAN',
    downstreamTitle: '無線 LAN',
    notConnect: '沒有接入互聯網',
    apConfigWarning: '保存Wi-Fi SSID和Wi-Fi Password後，如果想通過Deeper設備上網，需要重新掃描Deeper設備熱點並應用密碼',
    attention: '注意',
    buttons: {
      scanWifi: '掃描',
      connectStatus: '狀態',
      add: '添加',
      save: '保存',
      cancel: '取消',
      downloadLog: '下載日誌',
    },
    connect: '連接',
    forget: '忘記',
    waiting: {
      content: '正在連接，需要一點時間，請耐心等待...',
      cancelBtn: '取消連接',
    },
    modals: {
      hiddenWifi: {
        title: '添加隱藏Wi-Fi',
        okBtn: '添加',
      },
      scanWifi: {
        title: '警告',
        content: '網路會暫時斷開',
        ok: '確定',
        cancel: '取消',
      },
    },
    label: {
      ssid: 'Wi-Fi名稱',
      password: 'Wi-Fi密碼',
      onlyUse: '僅使用',
      hidden: '隱藏SSID',
      freqBand: '頻帶',
    },
    placeholder: {
      ssid: '請輸入SSID',
      password: '請輸入密碼',
    },
    message: {
      saveSuccess: '保存成功',
      saveError: '保存失敗，請重試',
      connectSuccess: '連接成功',
      forgetSuccess: '忘記成功',
      inputError: '請檢查您的輸入',
      invalidWifiDev: '當前沒有可用的Wi-Fi設備，無法進行Wi-Fi設定',
      illegalWifiDev: 'Wi-Fi設定只可在AP Router Mode和Wireless Relay Mode下使用',
      invalidSsid: '只支持字母，數位，_ 和-',
      invalidPassword: '請輸入可用的密碼',
      notConnect: '沒有連接到互聯網',
      retry: '請重試',
      connectionError: '連接失敗',
      forgetError: '忘記失敗',
      wifiNotSupported: '不支持 Wi-Fi',
      networkNotFound: '未找到網路',
      pskNotGenerated: '未生成金鑰',
      wrongPassword: '密碼錯誤',
      timeout: '連接超時',
      logDownloadError: '下載日誌失敗',
      hasBeenConnected: '已連接。',
      dhcpSuccess: '成功',
      dhcpFailure: '失敗',
    },
    connectionRequestedTitle: '已請求連接',
    connectionRequestedContent:
      '請在“狀態”選項下檢查您的連接狀態。 如果您與Deeper Connect斷開連接，請嘗試重新連接DeeperWiFi。',
    gotIpFromDhcpLabel: '從DHCP獲取IP地址',
  },
  // parentalControlData
  parentalControlData: {
    browserCacheWarning:
      '如果啟用家長控制功能的情况下無法正常攔截成人網站或社交媒體，需要清理一下瀏覽器緩存並且重啓一下被過濾的設備。',
    customerRule: `在這裡，您可以為您的孩子遮罩絕大部分的成人和社交網站。 如果您還想遮罩其他網站，您可以使用自定義功能變數名稱遮罩功能來添加更多的網站。`,
    customerRuleClickTips: '請{0}以訪問自定義功能變數名稱遮罩頁面',
    toCustomerRuleButtonText: '點擊此處',
    parentalControl: '家長控制',
    pornQuery: '攔截成人片',
    socialQuery: '攔截社交媒體',
    legendBlocked: '攔截',
    // social
    socialStateEnabled: '啟用',
    socialStateDisabled: '禁用',
    socialState2Hours: '禁用2小時',
    socialState4Hours: '禁用4小時',
    socialState8Hours: '禁用8小時',

    // porn
    pornStateEnabled: '啟用',
    pornStateDisabled: '禁用',
    pornState2Hours: '禁用2小時',
    pornState4Hours: '禁用4小時',
    pornState8Hours: '禁用8小時',
    setCategoryStatesErr: '啟用/禁用過濾出錯',
  },
  reboot: {
    success: {
      saveConfig: '保存成功',
    },
    failure: {
      getConfig: '獲取自動重啟配置失敗',
      saveConfig: '儲存自動重啟配置失敗',
    },
    buttons: {
      reboot: '點擊重啟',
      save: '保存',
    },
    week: {
      0: '每天',
      1: '星期一',
      2: '星期二',
      3: '星期三',
      4: '星期四',
      5: '星期五',
      6: '星期六',
      7: '星期日',
    },
    title: {
      manual: '立即重啟',
      auto: '定時重啟',
    },
    label: {
      period: '週期',
      time: '時間',
    },
  },
};
