import { SWITCH_LANG } from '../actions/types';

import languageObj from '../utils/languageObj';

const initState = {
  langValue: 'en_us',
  language: languageObj.en_us.internationalize,
};

const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SWITCH_LANG:
      return {
        ...state,
        langValue: action.payload.langValue,
        language: languageObj[action.payload.langValue]
          ? languageObj[action.payload.langValue].internationalize
          : languageObj.en_us.internationalize,
      };
    default:
      return state;
  }
};

export default { initState, reducer };
