export default {
  type: 'german',
  internationalize: {
    type: 'german',
    common: {
      tip_text: 'Hinweis',
      search: 'Suche',
      add: 'Hinzufügen',
      delete: 'Löschen',
      confirm: 'Bestätigen',
      cancel: 'Abbrechen',
      warning: 'Warnung',
      utcDisclaimer: 'Datum in UTC',
      toOpen: 'öffnen',
    },
    certificateDialog: {
      title: 'Neues Root-CA-Zertifikat',
      content: 'Das Root-CA-Zertifikat wurde ersetzt. Bitte laden Sie es erneut herunter und installieren Sie es.',
      dontShowAgain: 'Nicht mehr anzeigen',
    },
    request_tip: {
      error: 'Anforderungsfehler',
    },
    header: {
      logout: 'Abmelden',
      changePassword: 'Passwort ändern',
      userManual: 'Benutzerhandbuch',
      defaultAvatar: 'Standardavatar',
      english: 'Englisch',
      german: 'Deutsch',
      korean: 'Korean',
      french: 'Koreanisch',
      spain: 'Spanien',
      portugal: 'Portugal',
      japanese: 'japanisch',
      Tchinese: 'traditionelles Chinesisch',
      russian: 'Russisch',
      hindi: 'Hindi',
      simplechinese: 'Vereinfachtes Chinesisch',
    },
    home: {
      name: 'Startseite',
      children: {},
      content: {
        tipInput: 'Bitte eingeben',
        ok: 'Bestätigen',
        cancel: 'Abbrechen',
        currentStatus: 'Aktueller Status',
        statusMap: {
          Trial: 'Testversion',
          Valid: 'Gültig',
          Expired: 'Abgelaufen',
        },
        btnActive: 'Aktivieren',
        btnEdit: 'Bearbeiten',
        expireDate: 'Ablaufdatum',
        licenseKey: 'Lizenzschlüssel',
      },
    },
    accessControl: {
      name: 'Zugangskontrolle',
    },
    tpProxy: {
      name: 'Transparenter Proxy',
      children: {
        customRules: { name: 'Benutzerdefinierte Regeln' },
        builtinRules: { name: 'Werbefilter' },
        certificate: { name: 'Zertifikat installieren' },
      },
    },
    help: {
      name: 'Hilfe',
      children: {
        techSupport: { name: 'Technischer Support' },
      },
    },
    device: {
      name: 'Gerätebezogen',
      children: {
        system: { name: 'Systeminformationen' },
        traffic: { name: 'Verkehr' },
        notification: { name: 'Protokoll' },
      },
      content: {},
    },
    dpn: {
      name: 'DPN',
      content: {},
      children: {
        smartRoute: { name: 'Modus' },
        tunnel: { name: 'Tunnel' },
        routingTable: {
          name: 'Intelligente Routing-Tabelle',
          content: {},
          children: {
            domainConfig: { name: 'Benutzerdefinierte Domäne' },
            ipConfig: { name: 'Benutzerdefinierte IP' },
            queryRouting: { name: 'Abfrage' },
          },
        },
      },
    },
    appRelocator: {
      name: 'App-Neupositionierung',
    },
    adsBlock: {
      name: 'Werbeblocker',
      children: {
        domainFilter: {
          name: 'DNS-Filter',
        },
        httpsFilter: {
          name: 'HTTPS-Filter',
        },
      },
      httpssucceed: 'Die HTTPS-Filterung wurde erfolgreich aktiviert',
      httpslose: 'Die HTTPS-Filterung wurde erfolgreich deaktiviert',
      saverror: 'Operation fehlgeschlagen',
      usedByAccessControl: 'Der HTTPS-Filter wird von der Zugriffskontrolle verwendet',
    },
    dpr: {
      name: 'Bergbau',
      children: {
        dpr: {
          name: 'DPR',
          children: {
            stakingTutorial: {
              name: 'Absteck-Tutorial',
            },
            wallet: {
              name: 'Brieftasche',
              manager: 'Brieftaschenmanager',
              balance: 'Brieftaschenguthaben',
            },
            'Staking Mining': 'Staking-Bergbau',
            transaction: {
              id: 'Transaktions-ID',
              from: 'Ausgangsadresse',
              to: 'Zieladresse',
              time: 'Überweisungszeit (UTC)',
              rewardTime: 'Belohnungszeit (UTC)',
              amount: 'DPR-Menge',
              'Reward in selected period': 'Belohnung im ausgewählten Zeitraum',
              'Total reward': 'Gesamtbelohnung',
              'Balance Transfer': 'Normaler Transfer',
              'Staking Reward': 'Staking-Belohnung',
              'PoS Reward': 'PoS-Belohnung',
              'Micro Payment': 'Mikrozahlungstransaktion',
              stakeRelease: 'Lineare Freigabe-Transaktion',
              Transaction: 'Transaktionsaufzeichnung',
              Mining: 'Bergbau',
              'Traffic Mining': 'Verkehrsbergbau',
              'Staking Mining': 'Staking-Bergbau',
            },
          },
        },
      },
    },
    security: {
      name: 'Sicherheitsbezogen',
      children: {
        applications: 'Anwendungssteuerung',
        traffic: 'Verkehrssteuerung',
        webfilter: {
          name: 'Webseitenfilter',
          children: { report: { name: 'Standardkonfiguration' }, config: { name: 'DNS-Anzeigenblockierung' } },
        },
        sslFilter: {
          name: 'SSL-Filter',
          children: {
            customDNSrules: { name: 'Benutzerdefinierte DNS-Regeln' },
            customHTTPSRules: { name: 'Benutzerdefinierte HTTPS-Regeln' },
          },
        },
      },
      content: {},
    },
    parentalControl: {
      name: 'Kindersicherung',
    },
    regionCountryTexts: {
      'LL': 'Nicht durch den Tunnel gehen',
      'ANY': 'Jedes Land oder jede Region',
      // regions
      'AMER': '—Amerika—',
      'ASIA': '—Asien—',
      'AFRI': '—Afrika—',
      'EURO': '—Europa—',
      'OCEA': '—Ozeanien—',
      'AMN': 'Nordamerika',
      'AMC': 'Karibik',
      'AMM': 'Mittelamerika',
      'AMS': 'Südamerika',
      'ASC': 'Zentralasien',
      'ASE': 'Ostasien',
      'ASW': 'Westasien',
      'ASS': 'Südasien',
      'ASD': 'Südostasien',
      'AFN': 'Nordafrika',
      'AFM': 'Zentralafrika',
      'AFE': 'Ostafrika',
      'AFW': 'Westafrika',
      'AFS': 'Südafrika',
      'EUN': 'Nordeuropa',
      'EUE': 'Osteuropa',
      'EUW': 'Westeuropa',
      'EUS': 'Südeuropa',
      'OCP': 'Polynesien',
      'OCA': 'Australien und Neuseeland',
      'OCM': 'Melanesien',
      'OCN': 'Mikronesien',
      // countries
      'AD': 'Andorra',
      'AE': 'Vereinigte Arabische Emirate',
      'AF': 'Afghanistan',
      'AG': 'Antigua und Barbuda',
      'AI': 'Anguilla',
      'AL': 'Albanien',
      'AM': 'Armenien',
      'AO': 'Angola',
      'AR': 'Argentinien',
      'AS': 'Amerikanisch-Samoa',
      'AT': 'Österreich',
      'AU': 'Australien',
      'AW': 'Aruba',
      'AX': 'Åland',
      'AZ': 'Aserbaidschan',
      'BA': 'Bosnien und Herzegowina',
      'BB': 'Barbados',
      'BD': 'Bangladesch',
      'BE': 'Belgien',
      'BF': 'Burkina Faso',
      'BG': 'Bulgarien',
      'BH': 'Bahrain',
      'BI': 'Burundi',
      'BJ': 'Benin',
      'BL': 'Saint-Barthélemy',
      'BM': 'Bermuda',
      'BN': 'Brunei Darussalam',
      'BO': 'Bolivien',
      'BQ': 'Karibische Niederlande',
      'BR': 'Brasilien',
      'BS': 'Bahamas',
      'BT': 'Bhutan',
      'BW': 'Botswana',
      'BY': 'Belarus',
      'BZ': 'Belize',
      'CA': 'Kanada',
      'CC': 'Kokosinseln',
      'CD': 'Kongo (Dem. Rep.)',
      'CF': 'Zentralafrikanische Republik',
      'CG': 'Kongo (Rep.)',
      'CH': 'Schweiz',
      'CI': 'Côte d Ivoire',
      'CK': 'Cookinseln',
      'CL': 'Chile',
      'CM': 'Kamerun',
      'CN': 'Volksrepublik China',
      'CO': 'Kolumbien',
      'CR': 'Costa Rica',
      'CU': 'Kuba',
      'CV': 'Kap Verde',
      'CW': 'Curaçao',
      'CX': 'Weihnachtsinsel',
      'CY': 'Zypern',
      'CZ': 'Tschechien',
      'DE': 'Deutschland',
      'DJ': 'Dschibuti',
      'DK': 'Dänemark',
      'DM': 'Dominica',
      'DO': 'Dominikanische Republik',
      'DZ': 'Algerien',
      'EC': 'Ecuador',
      'EE': 'Estland',
      'EG': 'Ägypten',
      'ER': 'Eritrea',
      'ES': 'Spanien',
      'ET': 'Äthiopien',
      'FI': 'Finnland',
      'FJ': 'Fidschi',
      'FK': 'Falklandinseln',
      'FM': 'Mikronesien',
      'FO': 'Färöer-Inseln',
      'FR': 'Frankreich',
      'GA': 'Gabun',
      'GB': 'Vereinigtes Königreich',
      'GD': 'Grenada',
      'GE': 'Georgien',
      'GF': 'Französisch-Guayana',
      'GG': 'Guernsey',
      'GH': 'Ghana',
      'GI': 'Gibraltar',
      'GL': 'Grönland',
      'GM': 'Gambia',
      'GN': 'Guinea',
      'GP': 'Guadeloupe',
      'GQ': 'Äquatorialguinea',
      'GR': 'Griechenland',
      'GS': 'Südgeorgien und die Südlichen Sandwichinseln',
      'GT': 'Guatemala',
      'GU': 'Guam',
      'GW': 'Guinea-Bissau',
      'GY': 'Guyana',
      'HK': 'Hongkong',
      'HN': 'Honduras',
      'HR': 'Kroatien',
      'HT': 'Haiti',
      'HU': 'Ungarn',
      'ID': 'Indonesien',
      'IE': 'Irland',
      'IL': 'Israel',
      'IM': 'Isle of Man',
      'IN': 'Indien',
      'IO': 'Britisches Territorium im Indischen Ozean',
      'IQ': 'Irak',
      'IR': 'Iran',
      'IS': 'Island',
      'IT': 'Italien',
      'JE': 'Jersey',
      'JM': 'Jamaika',
      'JO': 'Jordanien',
      'JP': 'Japan',
      'KE': 'Kenia',
      'KG': 'Kirgisistan',
      'KH': 'Kambodscha',
      'KI': 'Kiribati',
      'KM': 'Komoren',
      'KN': 'St. Kitts und Nevis',
      'KR': 'Südkorea',
      'KW': 'Kuwait',
      'KY': 'Kaimaninseln',
      'KZ': 'Kasachstan',
      'KP': 'Nordkorea',
      'LA': 'Laos',
      'LB': 'Libanon',
      'LC': 'St. Lucia',
      'LI': 'Liechtenstein',
      'LK': 'Sri Lanka',
      'LR': 'Liberia',
      'LS': 'Lesotho',
      'LT': 'Litauen',
      'LU': 'Luxemburg',
      'LV': 'Lettland',
      'LY': 'Libyen',
      'MA': 'Marokko',
      'MC': 'Monaco',
      'MD': 'Moldawien',
      'ME': 'Montenegro',
      'MF': 'St. Martin',
      'MG': 'Madagaskar',
      'MH': 'Marshallinseln',
      'MK': 'Nordmazedonien',
      'ML': 'Mali',
      'MM': 'Myanmar',
      'MN': 'Mongolei',
      'MO': 'Macao',
      'MP': 'Nördliche Marianen',
      'MQ': 'Martinique',
      'MR': 'Mauretanien',
      'MS': 'Montserrat',
      'MT': 'Malta',
      'MU': 'Mauretanien',
      'MV': 'Malediven',
      'MW': 'Malawi',
      'MX': 'Mexiko',
      'MY': 'Malaysia',
      'MZ': 'Mosambik',
      'NA': 'Namibia',
      'NC': 'Neukaledonien',
      'NE': 'Niger',
      'NF': 'Norfolk Island',
      'NG': 'Nigeria',
      'NI': 'Nicaragua',
      'NL': 'Niederlande',
      'NO': 'Norwegen',
      'NP': 'Nepal',
      'NR': 'Nauru',
      'NU': 'Niue',
      'NZ': 'Neuseeland',
      'OM': 'Oman',
      'PA': 'Panama',
      'PE': 'Peru',
      'PF': 'Französisch-Polynesien',
      'PG': 'Papua Neuguinea',
      'PH': 'die Philippinen',
      'PK': 'Pakistan',
      'PL': 'Polen',
      'PM': 'Saint Pierre und Miquelon',
      'PN': 'Pitcairninseln',
      'PR': 'Puerto Rico',
      'PS': 'Palästina',
      'PT': 'Portugal',
      'PW': 'PalauCity in Italy',
      'PY': 'Paraguay',
      'QA': 'Katar',
      'RE': 'Insel Réunion',
      'RO': 'Rumänien',
      'RS': 'Serbien',
      'RU': 'Russland',
      'RW': 'Ruanda',
      'SA': 'Saudi Arabien',
      'SB': 'Salomonen',
      'SC': 'Seychellen',
      'SD': 'Sudan',
      'SE': 'Schweden',
      'SG': 'Singapur',
      'SH': 'St. Helena, Ascension und Tristan da Cunha',
      'SI': 'Slowenien',
      'SJ': 'Svalbard und Jan Mayen',
      'SK': 'Slowenien',
      'SL': 'sierra leone',
      'SM': 'San Marino',
      'SN': 'Senegal',
      'SO': 'Somalia',
      'SR': 'Suriname',
      'SS': 'Südsudan',
      'ST': 'Sao Tome und Principe',
      'SV': 'El Salvador',
      'SX': 'Sint Maarten',
      'SY': 'Syrien',
      'SZ': 'Eswatini',
      'TC': 'Turks- und Caicosinseln',
      'TD': 'Tschad',
      'TF': 'Französisches Süd- und Antarktisgebiet',
      'TG': 'Togo',
      'TH': 'Thailand',
      'TJ': 'Tadschikistan',
      'TK': 'Togo',
      'TL': 'Timor-Leste',
      'TM': 'Turkmenistan',
      'TN': 'Tunesien',
      'TO': 'Tonga',
      'TR': 'Türkiye',
      'TT': 'Trinidad und Tobago',
      'TV': 'TuvaluCity in Italy',
      'TW': 'Taiwan, China',
      'TZ': 'Tansania',
      'UA': 'Ukraine',
      'UB': 'US Westen',
      'UC': 'US Mittlerer Westen',
      'UD': 'US Südwesten',
      'UE': 'US Nordosten',
      'UF': 'US Südosten',
      'UG': 'Uganda',
      'US': 'Vereinigte Staaten',
      'UY': 'Uruguay',
      'UZ': 'Usbekistan',
      'VA': 'Vatikan',
      'VC': 'St. Vincent und die Grenadinen',
      'VE': 'Venezuela',
      'VG': 'Britische Jungferninseln',
      'VI': 'Amerikanische Jungferninseln',
      'VN': 'Vietnam',
      'VU': 'Vanuatu',
      'WF': 'Wallis und Futuna',
      'WS': 'Samoa',
      'XK': 'Kosovo',
      'YE': 'Jemen',
      'YT': 'Mayotte',
      'ZA': 'Südafrika',
      'ZM': 'Sambia',
      'ZW': 'Zimbabwe',
    },
    setting: {
      name: 'einrichten',
      'children': {
        'wifi': {
          'name': 'Wi-Fi bezogen',
        },
        'sharing': {
          'name': 'Sharing bezogen',
          'children': {
            'configure': {
              'name': 'Freigabekonfiguration',
            },
            'blacklist': {
              'name': 'Schwarze Liste teilen',
            },
            'record': {
              'name': 'Freigabeprotokoll',
            },
            'traffic': {
              'name': 'Freigabe des Datenverkehrs',
            },
          },
          'content': {},
        },
        'rustdesk': {
          'name': 'RustDesk',
          'children': {
            'config': {
              'name': 'Configuration',
            },
            'log': {
              'name': 'Log',
            },
          },
        },
        'admin': {
          'name': 'Kontoverwaltung',
          'content': {},
          'children': {
            'changePassword': {
              'name': 'Passwort',
            },
            'reboot': {
              'name': 'Neustart',
              'content': {
                'confirmText': 'Confirm reboot?',
                'rebootLoading':
                  'The device will take a few minutes to restart, and your network will be temporarily disconnected...',
              },
            },
          },
        },
      },
    },
  },
  // dashboard
  dashboard: {
    'name': 'Startseite',
    'children': {},
    'content': {
      'backupTunnelLabel': 'Sicherungstunnleiste',
      'tipInput': 'Bitte geben Sie ein',
      'hotspot': 'Global-Verbindung',
      'realnetworkflow': 'Echtzeit-Netzwerkverkehrstatistik',
      'memory': 'Speicherbelegung',
      'cpu': 'CPU-Belegung',
      'notification': 'Systembenachrichtigungen',
      'systeminfo': 'Systeminformationen',
      'upload': 'Hochladen',
      'download': 'Herunterladen',
      'systemuptime': 'Systembetriebszeit',
      'uploadtotal': 'Gesamt-Hochladen',
      'downloadtotal': 'Gesamt-Herunterladen',
      'softwareVersion': 'Softwareversion',
      'publicIp': 'Öffentliche IP-Adresse',
      'unit': 'Einheit',
      'currentDpnMode': 'Aktiver DPN-Modus',
      'ok': 'Bestätigen',
      'cancel': 'Abbrechen',
      'currentStatus': 'Aktueller Status',
      'statusMap': {
        'Trial': 'Probierphase',
        'Valid': 'Gültig',
        'Expired': 'Ablaufen',
      },
      'btnActive': 'Aktivieren',
      'btnEdit': 'Bearbeiten',
      'expireDate': 'Ablaufdatum',
      'licenseKey': 'Lizenzschlüssel',
      'systemTraffic': 'Echtzeit-System-Netzwerkverkehrstatistik',
      'appTraffic': 'Echtzeit-App-Netzwerkverkehrstatistik',
    },
  },
  accessControl: {
    'content': {
      banner: {
        title: 'Zugangskontrolle',
        description:
          'Dieser Schalter dient zur einheitlichen Steuerung der Aktivierung bzw. Deaktivierung verschiedener Funktionen des LAN-Geräts.',
      },
      'onlineDevice': 'Online-Gerät',
      'offlineDevice': 'Offline-Gerät',
      'deviceName': 'Gerätename',
      'unknownDevice': 'Unbekannt',
      'macAddress': 'MAC-Adresse',
      'ipAddress': 'IP-Adresse',
      'enableHttpsFilter': 'HTTPS-Filter aktivieren',
      'routeMode': 'DPN-Modus',
      'routeModeOptions': {
        'smart': 'Intelligenter Routing-Modus',
        'full': 'Volles Routing',
        'disabled': 'Deaktiviert',
        blackHole: 'Schwarzes Loch',
      },
      'remark': 'Anmerkung',
      'pinned': 'An die Spitze anheften',
      'httpsFilterTip': 'Nach der Aktivierung des HTTPS-Filters werden alle Werbungen gefiltert.',
      'routeModeTip': 'Mit dieser Option kann der Betriebsmodus von DPN geändert werden.',
      'tunnelTip': 'Bitte wählen Sie einen Tunnel für volles Routing aus.',
      'success': {
        getList: 'Geräteliste erfolgreich aktualisiert',
        'httpsFilter': 'HTTPS-Filter erfolgreich umgeschaltet',
        'routeMode': 'DPN-Modus erfolgreich umgeschaltet',
        'regionCode': 'DPN-Modus erfolgreich umgeschaltet',
        'remark': 'Anmerkung erfolgreich gespeichert',
        'pinned': 'Erfolgreich angeheftet / gelöst. Bitte aktualisieren',
        'deleteDevices': 'Gerät erfolgreich gelöscht',
      },
      'failure': {
        'getSwitch': 'Fehler beim Abrufen des Zugriffskontrollschalters',
        'setSwitch': 'Fehler beim Einstellen des Zugriffskontrollschalters',
        'getList': 'Fehler beim Abrufen der Zugriffskontrollliste',
        'getTunnels': 'Fehler beim Abrufen der Tunnel',
        'setItem': 'Fehler beim Festlegen des Zugriffskontrollelements',
        'httpsFilter': 'Fehler beim Umschalten des HTTPS-Filters',
        'routeMode': 'Fehler beim Umschalten des DPN-Modus',
        'regionCode': 'Fehler beim Umschalten des DPN-Modus',
        'remark': 'Fehler beim Speichern der Anmerkung',
        'noSuchTunnel': 'Der Tunnel existiert nicht. Bitte wählen Sie einen anderen Tunnel',
        'pinned': 'Anheften / Lösen fehlgeschlagen',
        'deleteDevices': 'Gerät konnte nicht gelöscht werden',
        'macActive': 'Gerät konnte nicht gelöscht werden, da es aktiv ist',
      },
      'remarkLengthLimit': 'Länge nicht mehr als 10 Zeichen',
      'httpsNotEnabled':
        'Der Hauptschalter für die HTTPS-Filterung ist ausgeschaltet. Klicken Sie hier, um diese Seite zu öffnen und ihn einzuschalten!',
      'tips': {
        'opened':
          'Wenn die Zugangskontrolle aktiviert ist, verwenden Online-Geräte bei der Zugangskontrolle bevorzugt den DPN-Modus.',
      },
    },
  },
  //dpnMode
  dpnMode: {
    'title': 'Wählen Sie den DPN-Modus',
    'selectTunnel': 'Wählen Sie den Tunnel',
    'recommended': 'Empfohlen',
    'apply': 'Anwenden',
    'missingInfo': 'Fehlende Informationen',
    'selectionTip': {
      'smart': 'Möchten Sie den Smart Routing-Modus bestätigen?',
      'full': 'Alle Datenverkehr wird durch das ausgewählte Tunnel. Möchten Sie den Full Routing-Modus bestätigen?',
      'disabled':
        'Dieser Modus wird der DPN-Netzwerk temporär einstellen. Möchten Sie den DPN-Deaktivierungsmodus bestätigen?',
    },
    dpnModeShortList: {
      'smart': 'DPN-Smart Routing',
      'disabled': 'DPN-Deaktivierung',
    },
    dpnModeLongList: {
      'smart': 'Smart Routing',
      'full': 'Vollständiges Routing',
      'disabled': 'Deaktivierung',
    },
    'killSwitchLabel': 'Ausschalte-Schalter',
    'none': 'Aus',
    'backupTunnelLabel': 'Sicherungstunnle',
    'backupTunnelTip':
      'Wenn bei der Konfiguration des Smart Routing-Modus ein Problem mit dem Tunnel auftritt, wird der System automatisch auf den Sicherungstunnleum wechseln, bis die Standardtunnel-Verbindung wieder hergestellt ist.',
    tips: {
      killSwitch:
        'A VPN kill switch is a feature that automatically disconnects your computer, phone, or tablet from the internet if your VPN connection drops out. This means there’s no chance that your IP address and other sensitive information could be exposed if your VPN stops working. So the security and anonymity of your internet connection won’t be compromised.',
      smart: [
        '1. Gemäß den integrierten Regeln von AtomOS werden intelligente Matching Nodes der Webseite zugewiesen, auf die zugegriffen werden soll, was die lokale Netzwerkgeschwindigkeit gewährleistet und gleichzeitig den Zugang zu Diensten in anderen Ländern und Regionen beschleunigt.',
        '2. Im intelligenten Routing-Modus kann es nicht nur die Mühe traditioneller VPNs ersparen, die ständig Länder wechseln, sondern den Benutzern auch einen schnelleren Netzwerkzugriff ermöglichen.',
        '3. Deeper Connect verfügt über integrierte Routing-Regeln für bekannte Websites und Dienste. Wenn Sie die Regeln für intelligentes Routing anpassen möchten, können Sie diese in "Application Relocation" und "DPN Routing Table" konfigurieren.',
        '4. Wir werden unsere integrierten Routing-Regeln weiter verbessern und mehr Anwendungen im Bereich "Application Relocation" basierend auf Nutzerfeedback unterstützen.',
      ],
      full: [
        '1. Alle von AtomOS verarbeiteten Netzwerkdaten verwenden den angegebenen Tunnel, der dem traditionellen VPN-Nutzungsmuster ähnelt.',
        '2. Hinweis: Die Verwendung des vollständigen Routing-Modus überschreibt Ihre Konfiguration in "Application Relocation" und "DPN Routing Table".',
        '3. Aufgrund der Eigenschaften dezentraler Netzwerke kommen die Knoten im Netzwerk von Benutzergeräten auf der ganzen Welt, nicht von Hochgeschwindigkeitsservern, so dass Ihre Internetgeschwindigkeit beeinträchtigt werden kann.',
        '4. Wenn Sie darauf bestehen, den vollen Routing-Modus zu verwenden und ein Hochgeschwindigkeits-Internet-Erlebnis haben möchten, werden wir in Zukunft in Erwägung ziehen, spezielle Hochgeschwindigkeits-Knoten für VIP-Benutzer bereitzustellen.',
      ],
      disabled: [
        '1. Die DPN-Deaktivierung deaktiviert die DPN-Funktion und alle von AtomOS verarbeiteten Netzwerkdaten verwenden lokalen Datenverkehr.',
        '2. Die anderen Netzwerksicherheitsfunktionen von Deeper Connect sind weiterhin aktiviert.',
        '3. Wenn Sie beim Zugriff auf bestimmte Websites auf Hindernisse stoßen, können Sie DPN deaktivieren, um zu bestätigen, ob es sich um ein Problem mit der Website oder der DPN-Funktionalität handelt.',
        '4. Wenn Sie Probleme mit der DPN-Funktionalität finden, melden Sie das Problem bitte über den folgenden Link: https://support.deeper.network/hc/en-us/requests/new',
      ],
    },
    success: 'Betrieb erfolgreich',
    failure: 'Operation fehlgeschlagen！',
  },
  // DefaultadBlock
  DefaultadBlock: {
    browserCacheWarning:
      'Wenn die DNS-Anzeigenfilterung aktiviert ist und Anzeigen nicht ordnungsgemäß gefiltert werden können, müssen Sie den Browser-Cache bereinigen und das gefilterte Gerät neu starten.',
    queriesFor24Hours: 'Anfragen der letzten 24-Stunden',
    totalQuery: 'Anfrage insgesamt',
    blockedQuery: 'Abfanganfragen',
    blockedPercentage: 'Abfangprozentualer Anteil',
    ruleTotal: 'Regel',
    adQuery: 'Intercept-Anzeigen',
    trackerQuery: 'Abfangen und Verfolgen',
    maliciousQuery: 'Abwehr bösartiger Angriffe',
    pornQuery: 'Filme für Erwachsene abfangen',
    legendTotal: 'insgesamt',
    legendBlocked: 'Abfangen',
    categoryStateEnabled: 'Aktivieren',
    categoryStateDisabled: 'Aktivieren',
    categoryState2Hours: 'Zwei Stunden lang behindert',
    categoryState4Hours: 'Behindert für vier Stunden',
    categoryState8Hours: 'Acht Stunden lang behindert',
    setCategoryStatesErr: 'Fehler beim Aktivieren/Deaktivieren der Filterung',
    enableAll: 'Alle Filter aktivieren',
    disableAll: 'Alle Filter deaktivieren',
  },
  // CustomadBlock
  CustomadBlock: {
    missingInfoWarning: 'Fehler: Domain fehlt',
    invalidDomainError: 'Fehler: Ungültiger Domain oder Domain ist zu lang',
    whitelist: 'Whiteliste',
    blacklist: 'Blackliste',
    domain: 'Domain',
    whitelistAddSuccess: 'Erfolgreich hinzugefügt zur Whiteliste',
    whitelistEntryExist: 'Die Whiteliste enthält bereits diesen Eintrag',
    reachedWhiteMax: 'Die Whiteliste ist auf ihrem Maximalwert erreicht',
    whitelistAddError: 'Fehler beim Hinzufügen der Whiteliste',
    blacklistAddSuccess: 'Erfolgreich hinzugefügt zur Blackliste',
    blacklistEntryExist: 'Die Blackliste enthält bereits diesen Eintrag',
    reachedBlackMax: 'Die Blackliste ist auf ihrem Maximalwert erreicht',
    blacklistAddError: 'Fehler beim Hinzufügen der Blackliste',
    whitelistDelSuccess: 'Erfolgreich von der Whiteliste entfernt',
    whitelistDelError: 'Fehler beim Entfernen der Whiteliste',
    blacklistDelSuccess: 'Erfolgreich von der Blackliste entfernt',
    blacklistDelError: 'Fehler beim Entfernen der Blackliste',
    import: 'Importieren',
    fileButtonText: 'Datei hochladen',
    fileSizeOverLimit: 'Das hochgeladene Datei ist größer als das maximale 2MB-Größenlimit',
    whitelistImportSuccess: 'Erfolgreich importiert, {0} Einträge importiert',
    whitelistImportError: 'Fehler beim Importieren der Whiteliste',
    blacklistImportSuccess: 'Erfolgreich importiert, {0} Einträge importiert',
    blacklistImportError: 'Fehler beim Importieren der Blackliste',
    noValidEntry: 'Keine gültigen Einträge in der hochgeladenen Datei',
    zeroImported: 'Keine Einträge importiert',
  },
  // DohGuideComp
  DohGuideComp: {
    closeButton: 'Schließen',
    guideButton: 'Klicken Sie hier, um zu sehen',
    dohMessageClickTips:
      'Bitte klicken Sie auf "Anzeigen", um Anweisungen zur Schließung von DoH für Ihr spezifisches Browser zu sehen.',
    dohMessageTxt:
      'Um diese Funktion nutzen zu können, {0}. DoH verschlüsselt DNS-Anfragen und kann einige Funktionen stören.',
    dohMessage0: 'Bitte deaktivieren Sie in Ihrem Browser die DoH-Einstellungen.',
    guideBoxTitle: 'DoH-Browsereinstellungen',
    guide: {
      chromeGuideImg: '/dohGuide/chrome-guide-zh.png',
      chrome: [
        {
          label: 'Schritt 1',
          tip: 'Geben Sie im Adressfeld "chrome://settings/security" ein und drücken Sie Enter.',
        },
        {
          label: 'Schritt 2',
          tip: 'Finden Sie die "Sehr geschützte Einstellungen"-Abschnitt.',
        },
        {
          label: 'Schritt 3',
          tip: 'Deaktivieren Sie das Kontrollkästchen "Verwenden von sicherem DNS".',
        },
      ],
      firefox: [
        {
          label: 'Schritt 1',
          tip: 'Geben Sie im Adressfeld "about:config" ein und drücken Sie Enter.',
        },
        {
          label: 'Schritt 2',
          tip: 'Wenn eine Warnungsseite angezeigt wird, klicken Sie auf "Risiko akzeptieren und weiter".',
        },
        {
          label: 'Schritt 3',
          tip: 'Geben Sie in der Suchleiste "network.trr.mode" ein und drücken Sie Enter.',
        },
        {
          label: 'Schritt 4',
          tip:
            'Doppelklicken Sie auf die gefundene Einstellung, ändern Sie den Wert auf 5 (DoH deaktivieren), und klicken Sie dann auf "OK".',
        },
      ],
      edge: [
        {
          label: 'Schritt 1',
          tip:
            'Geben Sie im Adressfeld "edge://settings/privacy" ein und drücken Sie Enter, finden Sie dann den Abschnitt "Sicherheit".',
        },
        {
          label: 'Schritt 2',
          tip: 'Deaktivieren Sie das Kontrollkästchen "Verwenden von sicherem DNS zur Sicherstellung des Netzwerks".',
        },
      ],
      operaGuideImg: '/dohGuide/opera-guide-zh.png',
      opera: [
        {
          label: 'Schritt 1',
          tip: 'Geben Sie in der Adressleiste des Browsers "opera://settings/?search=DNS" ein und drücken Sie Enter.',
        },
        {
          label: 'Schritt 2',
          tip:
            'Finden Sie die Option "Verwenden von DNS-over-HTTPS anstatt der System-DNS-Einstellungen" und sicherstellen Sie, dass diese aktiviert ist.',
        },
      ],
    },
  },
  // appRelocatorData
  appRelocatorData: {
    guide: {
      youtube: {
        UA: 'https://www.deeper.network/pages/app-relocator?app=youtube&lang=zh',
      },
      nbaLeaguePass: 'https://www.deeper.network/pages/app-relocator?app=nbaLeaguePass&lang=zh',
      netflix: 'https://www.deeper.network/pages/app-relocator?app=netflix&lang=zh',
      dazn: 'https://www.deeper.network/pages/app-relocator?app=dazn&lang=zh',
      fubo: 'https://www.deeper.network/pages/app-relocator?app=fubo&lang=zh',
      tencentVideo: 'https://www.deeper.network/pages/app-relocator?app=tencentVideo&lang=zh',
    },
    countryLabel: 'Land',
    tagLabel: 'Etikett',
    typeLabel: 'Typ',
    country: {
      'all-countries': 'Weltweit',
      CN: 'CN',
      US: 'US',
      IN: 'IN',
      CA: 'CA',
      GB: 'GB',
      NA: 'Others',
      NO: 'NO',
      SG: 'SG',
      JP: 'JP',
    },
    tag: {
      'all-tags': 'Alle Tags',
      nba: 'NBA',
      nfl: 'NFL',
      nhl: 'NHL',
      mlb: 'MLB',
      epl: 'EPL',
      ipl: 'IPL',
    },
    category: {
      'all-category': 'Alle Kategorien',
      video: 'Video',
      music: 'Musik',
      im: 'Sofortnachrichten',
      flightAndHotel: 'Flug und Hotel',
      subscription: 'Abonnement',
    },
    filter: 'Filter',
    reset: 'Zurücksetzen',
    submit: 'Abschließen',
    subcategory: { popular: 'Beliebt', sport: 'Sport', movie: 'Film', tv: 'Fernsehen' },
    app: {
      acornTv: 'Acorn TV',
      amazonPrimeVideo: 'Amazon Prime Video',
      beInSports: 'BeIN Sports',
      crunchyroll: 'Crunchyroll',
      dazn: 'DAZN',
      disneyPlus: 'Disney+',
      netflix: 'Netflix',
      plex: 'Plex',
      roku: 'Roku',
      shudder: 'Shudder',
      zattoo: 'Zattoo',
      discoveryPlus: 'Discovery+',
      paramountPlus: 'Paramount+',
      sportsnetNow: 'Sportsnet Now',
      tsn: 'TSN',
      bbcIPlayer: 'BBC iPlayer',
      hulu: 'Hulu',
      itv: 'ITV',
      slingTv: 'Sling TV',
      crackle: 'Crackle',
      pandora: 'Pandora',
      theCw: 'The CW',
      abc: 'ABC',
      nbc: 'NBC',
      pluto: 'Pluto TV',
      starz: 'Starz',
      tbs: 'TBS',
      attTv: 'AT&T TV',
      channel4: 'Channel 4',
      showtimeAnytime: 'Showtime Anytime',
      nbcSports: 'NBC Sports',
      epixNow: 'Epix Now',
      mgmPlus: 'MGM+',
      xumo: 'Xumo',
      channel5: 'Channel 5',
      usaNetwork: 'USA Network',
      fxNow: 'FX Now',
      vudu: 'Vudu',
      fubo: 'Fubo TV',
      espn: 'ESPN',
      tubiTv: 'Tubi TV',
      ukTvPlay: 'UKTV Play',
      tvPlayer: 'TVPlayer',
      peacock: 'Peacock',
      pbs: 'PBS',
      philo: 'Philo',
      vrv: 'VRV',
      showmax: 'Showmax',
      hboMax: 'HBO Max',
      nhl: 'NHL',
      dsTv: 'DStv',
      sSportsPlus: 'S Sports+',
      filmrise: 'FilmRise',
      cbsSports: 'CBS Sports',
      nowTV: 'Now TV',
      molotovTv: 'Molotov TV',
      mlb: 'MLB',
      britBox: 'BritBox',
      stirr: 'Stirr',
      skyGo: 'Sky Go',
      hotstar: 'Disney+ Hotstar',
      sonyLiv: 'SonyLIV',
      nbaLeaguePass: 'NBA Saison Pass',
      amcPlus: 'AMC+',
      f1Tv: 'F1 TV',
      frndlyTv: 'Frndly TV',
      myTf1: 'MYTF1',
      qqMusic: 'QQ Music',
      spotify: 'Spotify',
      tidal: 'Tidal',
      youtube: 'YouTube',
      youTubeMusic: 'YouTube Music',
      weChat: 'WeChat',
      whatsApp: 'WhatsApp',
      line: 'Line',
      signal: 'Signal',
      agoda: 'Agoda',
      expedia: 'Expedia',
      flyScanner: 'Fly Scanner',
      googleFlights: 'Google Flights',
      qqSports: 'QQ Sports',
      skySports: 'Sky Sports',
      rakutenTv: 'Rakuten TV',
      fox: 'FOX',
      nflNetwork: 'NFL Network',
      peacocktv: 'Peacock TV',
      zee5: 'Zee5',
      mxPlayer: 'MX Player',
      tencentVideo: 'Tencent Video',
      iqiyi: 'IQIYI Inc - ADR',
      mangoTV: 'Mango TV',
      migu: 'Migu',
      bilibili: 'BiliBili',
      youku: 'YouKu',
      altBalaji: 'ALT Balaji',
      willowTv: 'Willow TV',
      foxSports: 'Fox Sports',
      crave: 'Crave',
      cbcGem: 'CBC Gem',
      jiocinema: 'JioCinema',
      Telegram: 'Telegram',
      Messenger: 'Messenger',
      ChatGPT: 'ChatGPT',
      CanalPlus: 'CanalPlus',
    },
    tip: {
      video: 'Video-Tunnel, der derzeit häufig verwendet wird',
      music: 'Musik-Tunnel, der derzeit häufig verwendet wird',
      im: 'Sofortnachrichten-Tunnel, der derzeit häufig verwendet wird',
      flightAndHotel: 'Flug- und Hotel-Tunnel, der derzeit häufig verwendet wird',
      subscription: 'Abonnement-Tunnel, der derzeit häufig verwendet wird',
      backupTunnel:
        'Der Backup-Tunnel wurde aktiviert. Wenn der in der intelligenten Routing-Modus hinzugefügte Tunnel vorübergehend keine verfügbaren Knoten hat, wird das System den Backup-Tunnel standardmäßig verwenden - {0}',
      fullTunnel: 'Im Vollrouting-Modus werden alle Anwendungen standardmäßig den {0}-Tunnel verwenden.',
      noTunnelNodes:
        'Die Anzahl der Knoten beträgt 0 und kann nicht verwendet werden. Gehen Sie zur Tunnel-Seite und aktualisieren Sie die Knoten des {0}-Tunnels.',
      noTunnelNodesOkText: 'Aktualisieren',
      noTunnelNodesCancelText: 'Schließen',
      noTunnelAdded:
        'Sie haben diesen Tunnel noch nicht hinzugefügt. Gehen Sie zur Tunnel-Seite und fügen Sie den {0}-Tunnel hinzu, um ihn zu verwenden.',
      noTunnelAddedOkText: 'Hinzufügen',
      noTunnelAddedCancelText: 'Abbrechen',
      tunnelDeletedText: '{0} - {1} Tunnel wurde gelöscht',
      netflix: 'Beheben Sie Wiedergabefehler, die bei der Verwendung von Netflix auftreten',
      fubo: 'Fubo TV ermöglicht es Ihnen, englischen Fußball live zu einem sehr günstigen Preis zu sehen',
      tencentVideo: 'Sehen Sie sich ein NBA-Spiel für etwa 1 US-Dollar an',
      dazn:
        'DAZN ermöglicht es Ihnen, NFL, NFL, MLB, NBA, EPL zu einem sehr günstigen Preis von nur 17,18 US-Dollar zu sehen',
      youtube: 'Wie Sie YouTube Premium zu einem sehr günstigen Preis genießen können',
    },
    intro: {
      acornTv:
        'Acorn TV bietet Streaming-Dienste für erstklassige Krimi-, Drama- und Comedy-Programme aus Großbritannien und anderen Ländern. Sie können klassische Serien in Folge ansehen oder Ihre neuen Lieblingsprogramme entdecken.',
      abc: 'Sehen Sie sich ABC-Programme online auf abc.com an. Erhalten Sie exklusive Videos und kostenlose Episoden.',
      f1Tv:
        'F1 TV ist jetzt auf Web, App, Google TV, Apple TV, Roku und Amazon Fire TV verfügbar. Überträgt alle F1-Rennen live. Überträgt alle Streckentrainings der Grand Prix live.',
      youtube:
        'Der offizielle Kanal von YouTube hilft Ihnen, die neuesten und beliebtesten Inhalte weltweit zu entdecken. Sehen Sie sich unbedingt erforderliche Videos an, die verschiedene Bereiche wie Musik, Kultur und Internetphänomene abdecken.',
      netflix:
        'Netflix ist ein Streaming-Service, der eine breite Palette preisgekrönter TV-Serien, Filme, Cartoons, Dokumentationen und mehr bietet und auf Tausenden von Internetverbindungsgeräten angesehen werden kann.',
      fubo:
        'Der einfachste Weg, Fubo auf Ihrem Fernseher zu sehen, besteht darin, Roku, Apple TV, Amazon Fire TV oder Chromecast zu verwenden. Installieren Sie einfach die Fubo-App auf Ihrem Gerät, um Streaming abzuspielen.',
      disneyPlus:
        'Von neuen Veröffentlichungen bis hin zu Ihren Lieblingsklassikern - Vergangenheit, Gegenwart und Zukunft gehören Ihnen. Alles, was Sie erwarten, und das, was Sie nicht erwartet haben',
      crunchyroll:
        'Erkunden Sie mit Crunchyroll die besten Anime- und Manga-Streaming-Inhalte. Genießen Sie beliebte Werke wie Demon Slayer, One Piece, Attack on Titan und My Hero Academia.',
      hulu:
        "Sehen Sie sich TV-Serien und Filme online an. Streamen Sie beliebte TV-Serien wie Grey's Anatomy, This Is Us, Bob's Burgers, Brooklyn Nine-Nine, Empire, Saturday Night Live und beliebte Filme.",
      nbaLeaguePass:
        'Das Abonnement von NBA Saison Pass* bietet Fans Zugang zu Live- und On-Demand-Spielen sowie mehr Inhalten während der gesamten Saison und der Nebensaison.',
      peacock:
        'Holen Sie sich Peacock und streamen Sie Live-Sportveranstaltungen, exklusive Originalinhalte, beliebte Shows und Blockbuster-Filme. Top-Picks, klassische Sammlungen, Live-Events und mehr, jetzt auf Peacock streamen. Dokumentationen, Kinderfilme und -programme, Peacock-Kanäle rund um die Uhr, Hallmark-Kanäle und mehr',
      qqSports:
        'Tencent Sports ist ein bekanntes Sportportal in China, das hauptsächlich folgende Kategorien anbietet: Inlandsfußball, internationaler Fußball, NBA, CBA, Sport, Olympische Spiele, Live-Übertragungen, Lotterien, Wetten usw.',
      rakutenTv:
        'Live-TV | Kostenlose ersten 15 Kanäle · Kostenlos | Kostenlose Anzeige von beliebten Filmen · Geschäft | Kauf oder Miete von beliebten Filmen · Typ | Kostenlos | Zuletzt hinzugefügte Inhalte · Kostenlos',
      espn:
        'Besuchen Sie ESPN, um Echtzeit-Ergebnisse, Highlights und Sportnachrichten zu erhalten. Sehen Sie sich exklusive Veranstaltungen auf ESPN+ an und nehmen Sie an Fantasy-Sportspielen teil.',
      beInSports:
        'BeIN SPORTS USA enthüllt die Welt des Sports. Erhalten Sie exklusive Wiederholungen von Top-Fußballligen und mehr, die neuesten Nachrichten, Daten, Ergebnisse und Spielpläne.',
      dazn:
        'DAZN ist die weltweit erste Streaming-Plattform, die sich ausschließlich auf Live-Sportübertragungen konzentriert…',
      cbsSports:
        'Kostenloses 24-Stunden-Sportnachrichtennetzwerk. Sehen Sie CBS Sports HQ, um alle wichtigen Informationen zu Spielen zu erhalten. Weitere Highlights, Top-Wetten und erfolgreiche Fantasy-Tipps.',
      fox:
        'Schauen Sie sich FOX Sports an und sehen Sie sich Echtzeit-Ergebnisse, Quoten, Teamnachrichten, Spielernews, Live-Streams, Videos, Statistiken, Ranglisten und Spielpläne an, die NFL, MLB, NASCAR, WWE, NBA, NHL und mehr abdecken.',
      nflNetwork:
        'Erhalten Sie Informationen zum NFL-Kanal, Programmupdates und mehr! NFL Network ist das einzige ganzjährig betriebene Netzwerk, das im Besitz und Betrieb der NFL ist.',
      peacocktv:
        'Sehen Sie sich auf NBC.com die vollständigen Folgen aktueller und klassischer NBC-Shows an. Außerdem finden Sie Clips, Trailer, Fotos und exklusive Online-Funktionen.',
      sportsnetNow:
        "MLB Top Performances · MLBSportsnet Video: Blue Jays' Springer celebrates birthday with leadoff home run hit far from home plate · MLBSportsnet Video: Warriors' Arcidiacono Jr.",
      nbcSports:
        'Erhalten Sie die neuesten Sportnachrichten und Ergebnisse von NBC Sports und bleiben Sie auf dem Laufenden.',
      tsn:
        'Besuchen Sie die TSN-Website, um die neuesten Sportnachrichten, Ergebnisse, Highlights und Kommentare zu den Australian Open, NFL-Playoffs, Super Bowl, NHL-Handelsfristen und mehr zu erhalten.',
      nhl:
        'Die offizielle Website der National Hockey League (NHL) umfasst Neuigkeiten, Listen, Statistiken, Zeitpläne, Teams und Videos.',
      hotstar:
        'Disney+Hotstar ist die größte Premium-Streaming-Plattform Indiens mit über 100000 Stunden Drama- und Filmressourcen, die 17-Sprachen abdeckt und Live-Übertragungen aller großen Sportereignisse bereitstellt。',
      disneyPlus:
        'Von neuen Veröffentlichungen bis zu Ihren Lieblingsklassikern gehören Vergangenheit, Gegenwart und Zukunft Ihnen. Alles, was du erwartet hast, und alles, was du nicht getan hast. © 2023 Disney. Marken: Marvel, Pixar, Disney, National Geographic, Star Wars.',
      skySports:
        'Sehen Sie die beste Live-Berichterstattung Ihrer Lieblingssportereignisse: Fußball, Golf, Rugby, Cricket, F1-Rennen, Boxen, American Football, Basketball und die neuesten Sportnachrichten',
      sonyLiv:
        'Top-Tier erweiterte Streaming-Plattform, auf der Sie beliebte TV-Shows, Filme, Sportveranstaltungen und Web-Dramen ansehen können. Genießen Sie das Online-Erlebnis Ihres Lieblings-Live-TV-Senders in vollen Zügen.',
      jiocinema:
        'Sehen Sie sich die neuesten Filme, TV-Shows, Voot-Shows, Live-Sportveranstaltungen und aufregende Clips online in verschiedenen Genres in Hindi, Englisch und anderen regionalen Sprachen an.',
      amazonPrimeVideo:
        'Streaming von Filmen, Programmen, preisgekrönten Amazon Originalinhalten, Premium-Kanälen, Sportveranstaltungen usw. Nutze kostenlose Testversionen, um süchtig machende TV-Dramen, Blockbuster, beliebte Programme und vieles mehr zu sehen. Unterstützt 4K+HDR-Inhalte. Beliebte TV-Serien und Filme. Neu veröffentlichte Inhalte. Beobachten Sie auf jedem Gerät.',
      zee5:
        'Sie können jetzt Ihre Lieblings-Desi-Inhalte auf ZEE5™ mit einem exklusiven Rabatt von 50% kaufen. Kaufen Sie jetzt und genießen Sie Ihre Lieblingsinhalte auf ZEE5™. Jahresabonnement mit 50% Rabatt. Kauf-Link. Über 4000 Super-Hit-Filme, über 1600 Programme und 260 Originalinhalte. Unterstützt 18 Sprachen. ',
      mxPlayer:
        'MX Player ist der führende OTT-Dienst in Indien, auf dem Sie die neuesten Musikvideos abspielen, alte und neue Filme, TV-Sendungen, Comedy-Shows, Pop-Songs und vieles mehr ansehen können. ',
      tencentVideo:
        'Tencent Video ist bestrebt, die führende Online-Video-Medienplattform in China zu schaffen, die Benutzern reichhaltige Inhalte, ultimative Seherlebnisse, bequeme Anmeldeoptionen, nahtlose Anwendungserfahrungen rund um die Uhr und schnelle Freigabe von Produktmerkmalen bietet. Es erfüllt hauptsächlich die Anforderungen der Benutzer online ... ',
      iqiyi:
        'IQIYI. COM ist eine große Video-Website mit einem massiven, qualitativ hochwertigen und hochauflösenden Netzwerk von Videos und einer professionellen Netzwerk-Video-Wiedergabeplattform. IQiyis Film- und Fernsehinhalte sind reich und vielfältig und umfassen Filme, TV-Dramen, Anime, Varieté-Shows, das tägliche Leben',
      youku: 'Youku.Go All Out for Good Content – Watch Massive Original HD Videos Online.',
      migu:
        'Migu Video überträgt die wichtigsten nationalen und internationalen Sportereignisse wie die Olympischen Winterspiele von Peking, CBA, AFC Champions League, Chinese Super League, La Liga, Bundesliga, Serie A, Europa League und Champions League sowie Live-Übertragungen von Frauenvolleyball, Tischtennis, Eislaufen, Snooker, WWE und UFC',
      mangoTV:
        'Mango TV - The Online Video Website Everyone is Watching - Watch Popular Variety Shows, Latest Movies, and TV Dramas Online.',
      nbc:
        'Sehen Sie sich die komplette Serie aktueller und klassischer NBC-Programme auf NBC.com an. Darüber hinaus finden Sie auch Clips, Trailer, Fotos und exklusive Online-Inhalte.',
      foxSports:
        'Sehen Sie sich FOX Sports an und sehen Sie sich Echtzeit-Ergebnisse, Quoten, Team-Nachrichten, Spieler-Nachrichten, Live-Übertragungen, Videos, Statistiken, Rankings und Zeitpläne an, einschließlich NFL, MLB, NASCAR, WWE, NBA, NHL und mehr.',
      'nfl':
        'Erhalten Sie Informationen zum NFL-Kanal, Programmupdates und mehr! Das NFL Network ist das einzige ganzjährig betriebene Netzwerk, das von der NFL besessen und betrieben wird.',
      'willowTv':
        'Nach erfolgreicher Anmeldung können Sie auf Willow Live-Übertragungen, Highlights und Wiederholungen ansehen. Dienstleister wie Spectrum (Spectrum), Optimum (Optimum), Fios (Fios), Dish (Dish), Optimum TV (Optimum TV), Xfinity (Comcast) und Time Warner (Time Warner) sind alle verfügbar.',
      'mlb':
        'Unsere Berichterstattung umfasst Audio- und Videoclips, Interviews, Statistiken, Spielpläne und exklusive Reportagegeschichten.',
      'hboMax':
        'HBO Max ist eine eigenständige Streaming-Plattform, die alle HBO-Inhalte mit weiteren beliebten TV-Serien, Blockbuster-Filmen und brandneuen Max Originals kombiniert und für jeden geeignet ist.',
      'amcPlus':
        'Entkommen Sie dem Gewöhnlichen und entdecken Sie heute auf AMC+ einzigartige, fesselnde Erlebnisse. Exklusiver Inhalt, herzzerreißende Filme und klassische Serien - auf AMC+ finden Sie alles in epischen Serien. Hochwertige Dramen. On-Demand-Service. Exklusiver Inhalt. Exklusive Filme. Neue Serien. Keine Werbung.',
      'attTv':
        'Erhalten Sie unsere besten TV- und Internet-Angebote, einschließlich Angeboten für DIRECTV und Heim-Internet, einschließlich AT&T Fiber.',
      'britBox':
        'Genießen Sie das kontinuierliche Abspielen einer großen Anzahl britischer Fernsehsendungen, darunter Krimis, Komödien, Dramen, Dokumentationen, Lebensstile und vieles mehr. Dies ist die bisher größte Sammlung britischer Fernseh-Streaming-Medien.',
      'crackle':
        'Auf Crackle können Sie Hollywood-Filme kostenlos und ungeschnitten ansehen. Es umfasst eine Vielzahl von Genres wie Action, Horror, Science-Fiction, Kriminalität und Komödie. Klicken Sie einfach auf Play.',
      'discoveryPlus':
        'Sie können auf Mobilgeräten, Tablets, Computern, Spielekonsolen und Fernsehgeräten streamen. Hier gibt es für jeden etwas! discovery+ ist ein Streaming-Service-Anbieter für Bereiche wie Lebensmittel und Wohnen.',
      'mgmPlus':
        'Abonnieren Sie MGM+, um Tausende von werbefreien Filmen und Fernsehsendungen zu genießen. Jederzeit kündbar.',
      'filmrise':
        'Wir reagieren aktiv auf die sich ständig ändernden Branchenmerkmale und setzen mehrstufige Strategien ein, um die besten Filme und Fernsehinhalte bereitzustellen.',
      'frndlyTv':
        'Mit Roku, Amazon Fire TV oder Frndly TV können Sie über 40 Live-Kanäle und On-Demand-Inhalte ansehen. Dazu gehören alle drei Hallmark-Kanäle, A&E und mehr. Sie können auch jederzeit und überall auf mobilen Geräten ansehen.',
      'fxNow': 'Sehen Sie sich auf den FX-, FXX- und FXM-Netzwerken FX-Original-TV-Shows, Filme und Live-TV an.',
      paramountPlus:
        'Streaming von tausenden Episoden, Live-Sportveranstaltungen und exklusiven Originalinhalten auf einer Plattform. Besuchen Sie Hunderte von ikonischen Filmen, die von preisgekrönten klassischen Filmen bis hin zu beliebten neuen Filmen reichen. Entdeckung neuer Episoden für kontinuierliche Wiedergabe. Beliebte Reality Show Serie. Beliebte MTV-Programme. Über 30000 Episoden. Aktuelle Nachrichten. Beliebte CBS-Programme.',
      'pbs':
        'POV-Filme sind bekannt für ihre unvergessliche Erzählung und Aktualität. Schauen Sie jetzt! POV ist das am längsten laufende unabhängige Dokumentarfilm-Showcase-Programm im Fernsehen. Erfahren Sie mehr! Unabhängige Non-Fiction-Arbeiten. Über 500 Filme präsentiert. Unvergessliche Geschichten.',
      'philo':
        'Warum mehr für Fernsehen bezahlen? Philo bietet Live-TV und On-Demand-Inhalte für nur 25 US-Dollar pro Monat. Streamen Sie Ihre Lieblingssendungen, Filme und mehr auf allen Geräten.',
      'plex':
        'Plex ist eine kostenlose Streaming-App ohne Kreditkarte, Abonnementgebühren oder versteckte Gebühren. Um unseren Service kostenlos zu halten, enthalten wir Anzeigen zur Gewinnerzielung.',
      'pluto':
        'Von den neuesten Blockbuster-Filmen bis hin zu vollständigen Staffeln Ihrer Lieblingsserie haben Sie alles. Hunderte von Kanälen. Keine Kosten. Für alle Geräte. Beginnen Sie jetzt mit dem Ansehen! Für alle Geräte. Kontinuierliches Ansehen. On-Demand-TV und Filme. Hunderte von Kanälen. Sofortiges Streaming. Nie bezahlen.',
      'roku':
        'Roku-Geräte machen das Streaming-Fernsehen einfach. Von Playern und Smart-TVs bis hin zu Smart-Home-Produkten finden Sie das perfekte Roku-Produkt für Ihr Zuhause und machen es sicherer.',
      'showtimeAnytime':
        'Probieren Sie SHOWTIME kostenlos aus und streamen Sie Original-Serien, Filme, Sportveranstaltungen, Dokumentationen und mehr. Sie können auch Pay-per-View-Boxkämpfe bestellen - kein Abonnement erforderlich.',
      'shudder':
        'Ab einem monatlichen Preis von nur 4,99 US-Dollar erhalten Sie auf einer Plattform Zugriff auf alle Inhalte von Shudder und können beliebte Serien wie The Walking Dead Universe, Ann Lai und mehr ansehen. Probieren Sie Shudder kostenlos aus...',
      'slingTv':
        'Sling TV ist der erste App-basierte TV-Service, mit dem Sie Live-TV und On-Demand-Inhalte über das Internet streamen können. Sehen Sie Live-Programme, egal wo Sie sich befinden...',
      'starz':
        'STARZ präsentiert das Leben aus verschiedenen Perspektiven mit kühner Erzählung. Registrieren Sie sich, um Original-Serien, Filme, zusätzliche Inhalte und mehr zu streamen, jederzeit on demand, ohne Werbung.',
      'stirr':
        '...und Unterhaltungsprogramme. Live- und On-Demand. STIRR City und National sind als Ihre lokalen Kanäle festgelegt. Fangen Sie an zu sehen. 00:05. Über 100 Kanäle, über 5000 Stunden Fernsehen und...',
      'tbs':
        'In the Heights · Dynamite diese Woche auf TBS · MLB-Playoff-Vorschau auf TBS · Amerikanischer Vater des multiversums (Staffel 18, Folge 14)',
      'theCw':
        'Die offizielle Website des CW-Netzwerks bietet spannende Serien, Filme, Sportveranstaltungen und mehr wie All American, Superman & Lois, Walker, FBoy Island und mehr.',
      tubiTv:
        'Tubi ist eine führende kostenlose Premium-Video-Streaming-App. Wir haben die größte Content-Bibliothek mit über 50.000 Filmen und TV-Sendungen und bieten das Beste...',
      usaNetwork:
        'Sie können auf USANetwork.com die vollständigen Episoden der aktuellen und klassischen USA-Programme ansehen. Darüber hinaus finden Sie Ausschnitte aus den Programmen, Trailer, Fotos und exklusive Online-Features.',
      vudu:
        'Mieten, kaufen und ansehen von Filmen und TV-Sendungen mit Vudu. Sie können online auf der Vudu-App oder mit Ihrem bevorzugten verbundenen Gerät ansehen. Kein Abonnement erforderlich, kostenlose Registrierung.',
      xumo:
        'Xumo wurde gegründet, um die nächste Generation von Streaming-Plattformen für die gesamte Unterhaltungsbranche zu entwickeln und bereitzustellen. Erfahren Sie mehr über unsere Mission und wie Sie daran teilnehmen können...',
      jiocinema:
        'Xumo wurde gegründet, um die nächste Generation von Streaming-Plattformen für die gesamte Unterhaltungsbranche zu entwickeln und bereitzustellen. Erfahren Sie mehr über unsere Mission und wie Sie daran teilnehmen können...',
      altBalaji:
        'Sehen Sie sich die neuesten Webserien, Originalarbeiten und Filme in HD-Qualität online an. Wählen Sie aus verschiedenen Genres wie Thriller, Action, Erwachsene, Komödie, Familien-Drama und mehr und unterstützen Sie mehrere Sprachen.',
      bilibili:
        'Bilibili (bilibili.com) ist eine bekannte chinesische Video-Danmaku-Website. Hier finden Sie rechtzeitige neue Anime-Sendungen, eine aktive ACG-Atmosphäre und kreative Uploader. Hier finden Sie viele Freude.',
      crave:
        'Eine Sammlung, die sorgfältig aus Horrorfilmklassikern, beängstigenden TV-Serien und Halloween-Episoden zusammengestellt wurde ... unerwarteter Ruhm in der Comedy-Szene und sozialer Druck.',
      cbcGem:
        "Sehen Sie sich auf jedem Gerät beliebte Sendungen wie Schitt's Creek, Luther, Kim's Convenience und mehr an und streamen Sie Filme und Live-TV.",
      bbcIPlayer:
        "Sehen Sie sich auf jedem Gerät beliebte Sendungen wie Schitt's Creek, Luther, Kim's Convenience und mehr an und streamen Sie Filme und Live-TV.",
      channel4: 'Schauen Sie sich Ihre Lieblingssendungen online an, von Channel 4, E4 und Walter Presents',
      channel5:
        'Willkommen bei My5, wo Sie Ihre Lieblingssendungen von Channel 5, 5STAR, 5USA, 5SELECT, 5ACTION und mehr verfolgen und streamen können.',
      dsTv:
        'Die besten TV-Unterhaltungsprogramme sind in Reichweite. Sehen Sie sich die neuesten Sportveranstaltungen, Filme, Serien und Reality-Shows an und greifen Sie auf den DStv TV Guide zu.',
      molotovTv:
        'Molotov ist kostenlos. Fügen Sie gerne weitere Funktionen hinzu. Molotov steht für die Zukunft des Fernsehens. Spotify der Fernsehwelt. Fernsehen kann ...',
      myTf1:
        'Sehen Sie sich Live-Programme wie TF1, TMC, TFX, TF1 Séries Films und LCI an, sowie über 50 exklusive Themenkanäle und über 10.000 Stunden Videomaterial, nur auf MYTF1 ...',
      sSportsPlus:
        'Mit Sports Plus können Sie jeden Monat über 700 Stunden Live-Streaming genießen und Hunderte von Sportinhalten von Serie A bis La Liga, von Formula 1 bis NBA, von EuroLeague bis UFC erhalten. Alles an Ihren Fingerspitzen ...',
      showmax:
        'SDie Abonnementgebühren von Showmax variieren je nach Plan, da Showmax verschiedene Pläne anbietet, um unterschiedlichen Vorlieben und Budgets gerecht zu werden. Der Preis beginnt bei nur R39 ...',
      itv:
        'ITVX ist der neueste Streaming-Service in Großbritannien und bietet exklusive neue Programme, Blockbuster-Filme, Live-Events und Tausende von Episoden auf einer Plattform.',
      pandora:
        'Spielen Sie Ihre Lieblingssongs, Alben, Wiedergabelisten und Podcasts auf der brandneuen Pandora ab. Registrieren Sie sich für ein Abonnement, um werbefreie und bedarfsgerechte Wiedergabe zu genießen.',
      qqMusic:
        'QQ Music ist ein Netzwerk-Musik-Service-Produkt von Tencent. Es bietet eine massive Online-Musik-Testversion, neue und beliebte Songs werden online veröffentlicht, Texte werden übersetzt, Telefon Klingeltöne werden heruntergeladen, hochwertige verlustfreie Musik-Testversion, eine massive verlustfreie Musikbibliothek, echte Musik-Downloads und Speicherplatz',
      spotify: 'Spotify ist ein digitaler Musikdienst, mit dem Sie auf Millionen von Songs zugreifen können.',
      tidal:
        'TIDAL ist eine Musik-Streaming-Plattform, die Künstler und Fans priorisiert und über 100 Millionen High-Fidelity-Songs für globale Musik-Communities bereitstellt.',
      weChat:
        'Kann auf verschiedenen Plattformen verwendet werden; Genießen Sie Gruppenchat; Unterstützt Sprach-, Foto-, Video- und Textnachrichten.',
      whatsApp:
        'Bleiben Sie mit Freunden und Familie in Kontakt mit WhatsApp Messenger. WhatsApp ist kostenlos und bietet einfache, sichere und zuverlässige Nachrichten- und Anruffunktionen, die über',
      line:
        'Eine neue Kommunikationsanwendung, mit der Sie jederzeit und überall kostenlose Sprachanrufe tätigen und kostenlose Nachrichten senden können, rund um die Uhr!',
      signal:
        'Kostenlose Freigabe von Text, Sprachnachrichten, Fotos, Videos, GIFs und Dateien. Signal nutzt die Datenverbindung Ihres Telefons, um SMS- und MMS-Gebühren zu vermeiden.',
      agoda:
        'Buchen Sie jetzt, zahlen Sie später, kostenlose Stornierung. 24/7 Echtzeit-Kundenservice in Ihrer Sprache. Von Economy bis Luxus, Hotels und Residenzen. Vertrauenswürdige Zimmer- und Flugreisepartner.',
      expedia:
        'Willkommen auf Ihrer One-Stop-Reisewebsite für Ihren Traumurlaub. Durch das Bündeln Ihrer Unterkunft mit Mietwagen oder Flügen können Sie mehr sparen. Suchen Sie nach unseren flexiblen Optionen, um das Beste für Sie zu finden...',
      flyScanner:
        'Erhalten Sie mühelos Rabattflüge ab 19 US-Dollar. Vergleichen und buchen Sie in einem Suchvorgang schnell und bequem. Vergleichen Sie günstige Flüge, Hotels und Mietwagen. Finden Sie Tausende von Angeboten und erhalten Sie das Beste!',
      nowTV:
        'Wie viel kostet eine NOW-Mitgliedschaft? Eine NOW-Mitgliedschaft ermöglicht es Ihnen, Inhalte auf Ihre Art und Weise anzusehen. Sie können sich für Unterhaltung, Kino, Sport und Hayu anmelden.',
      TVPlayer:
        'TVPlayer ist der Ort für die beliebtesten Fernsehsender und hochwertigen On-Demand-Programme, die nur auf TVPlayer verfügbar sind. Schauen Sie sich über 40 Live-Kanäle und Tausende von...',
      ukTvPlay:
        'UKTV Play ist das Zuhause von Dave, Drama, W & Yesterday-Kanälen. Schauen Sie Live-TV, Stream und fangen Sie Ihre Lieblingssendungen auf.',
      zattoo:
        'Streamen und zeitversetzt Ihre Lieblingsfernsehsendungen auf allen Geräten. Beste Full-HD-Bildqualität. Besser als Kabel-TV. Bietet On-Demand- und Live-TV-Inhalte.',
      skyGo:
        'Mit Sky Go können Sie Live-TV, On-Demand-Programme und Catch-Up-TV jederzeit und überall auf Ihrem Handy, Tablet, Laptop oder PC oder über Apple TV-Geräte ansehen und problemlos zwischen verschiedenen Geräten wechseln.',
      Telegram:
        'Telegram zielt darauf ab, eine sichere, schnelle und prägnante Kommunikationserfahrung zu bieten und ist bekannt für seine leistungsstarken Verschlüsselungsfunktionen und reichhaltigen Funktionen.',
      Messenger:
        'Messenger ist eine Instant-Messaging-Anwendung von Facebook, die es Benutzern ermöglicht, mit anderen über Textnachrichten, Sprachanrufe, Videoanrufe, das Senden von Bildern, Emojis usw. zu kommunizieren. Messenger war ursprünglich ein Feature der Facebook-Website, wurde später zu einer eigenständigen mobilen Anwendung entwickelt und bietet mehr Funktionen und Dienstleistungen.',
      ChatGPT:
        'ChatGPT ist ein künstliches Intelligenz-Sprachmodell, das von OpenAI entwickelt wurde und auf der GPT (Generative Pretrained Transformer) Architektur basiert. Es wird durch großangelegte Daten zur Verarbeitung natürlicher Sprache trainiert und kann natürlichen Sprachtext verstehen und generieren.',
      CanalPlus:
        'CANAL+ ist ein französischer Pay-TV-Sender, einer der größten Pay-TV-Netzwerke in Frankreich und einer der wichtigsten Pay-TV-Dienstleister in Europa. Der Sender bietet eine Vielzahl von Programmen, darunter Filme, Sport, Nachrichten, Dokumentationen, TV-Dramen usw.',
    },
    'selectTunnel': 'Wählen Sie den Tunnel',
    notSetLabel: 'Nicht eingestellt',
    'directAccessLabel': 'Kein Tunnel',
    'otherTunnelLabel': 'Andere Tunnel',
    'recommendedTunnelLabel': 'Empfohlener Tunnel',
    'error': {
      'getApps': 'Abrufen von Apps fehlgeschlagen',
      'getTunnels': 'Abrufen der Tunnel-Liste fehlgeschlagen',
      'invalidApp': 'Ungültige App',
      'tunnelNotExist': 'Tunnel existiert nicht',
      'set': 'Fehler beim Einstellen des Tunnels',
      'unset': 'Fehler beim Aufheben der Tunnel-Einstellung',
    },
    'success': {
      'set': 'Tunnel erfolgreich eingestellt',
      'unset': 'Tunnel-Einstellung erfolgreich aufgehoben',
    },
    searchInputPlaceholder: 'Suchen Sie nach den Dienstleistungen, die Sie benötigen',
    discountIntro: percent => `Monatliche Ersparnisse ${percent} Abonnementgebühr`,
    discountPercent: percent => `-${percent} (USD)/Monat`,
    guideButtonLabel: 'Details anzeigen',
  },
  // betanet
  betanet: {
    bindWalletSuccess: 'Erfolgreich gebundene Geldbörse',
    deviceBound: 'Das Gerät ist derzeit an eine andere Wallet gebunden',
    walletTaken: 'Die Wallet ist bereits von einem anderen Gerät belegt',
    deviceTypeIncompatible: 'Gerät inkompatibel mit Wallet',
    invalidMnemonic: 'Ungültiger Wallet-Speicher',
    invalidPrivateKey: 'Ungültiger privater Wallet-Schlüssel',
    bindWalletError: 'Bindung der Geldbörse fehlgeschlagen',
    notBound: 'Der aktuelle Benutzer ist nicht an ein Wallet gebunden',
    unbindWalletSuccess: 'Erfolgreich entbundene Geldbörse',
    noActiveBinding: 'Das Gerät ist nicht an die angegebene Wallet gebunden',
    unbindWalletError: 'Binden von Geldbörse fehlgeschlagen',
    walletManagement: 'Wallet Management',
    bindWallet: 'Bind Wallet',
    downloadPrivateKey: 'Download Private Key',
    downloadKeyError: 'Fehler beim Herunterladen des Privaten Schlüssels',
    unbindBtnText: 'Entfernen Sie Ihr Wallet',
    unbindWarning:
      'Bitte stellen Sie sicher, dass Sie das Private Schlüssel heruntergeladen haben. Geben Sie "Ich habe das Private Schlüssel heruntergeladen" ein und kehren Sie zurück.',
    unbindWarningWords: 'Ich habe das Private Schlüssel heruntergeladen',
    generateWallet: 'Wallet generieren',
    verifyMnemonic: 'Überprüfen der Mnemonik',
    mnemonicMismatch: 'Fehler bei der Überprüfung der Mnemonik',
    bindBtn1Text: 'Wallet binden (Mnemonic)',
    bindBtn2Text: 'Wallet binden (Private Schlüssel)',
    dprManagement: 'DPR-Verwaltung',
    balanceLabel: 'DPR-Guthaben',
    balance: 'Balance',
    creditLabel: 'Kredit',
    operationLabel: 'Operation',
    amtOwingLabel: 'Das System hat für Sie eingezahlt',
    dailyConsumedLabel: 'Der tägliche Verbrauch',
    dailySharedLabel: 'Der tägliche Teilungsverkehr',
    deposit: 'Einzahlen',
    withdraw: 'Abheben',
    publicKey: 'Öffentliche Schlüssel',
    mnemonicInstruction: 'Notieren Sie bitte die Mnemonik. Sie müssen sie im nächsten Schritt verifizieren.',
    enterMnemonic: 'Trennen Sie die Mnemonik ein',
    enterMnemonicTip: 'Bitte tippen Sie die Mnemonik in Reihenfolge ein.',
    resetMnemonic: 'Zurücksetzen',
    uploadPrivateKey: 'Privater Schlüssel hochladen',
    getWalletError: 'Fehler beim Abrufen der Wallet-Informationen',
    deviceWalletMismatch: 'Gerät und Wallet stimmen nicht überein',
    walletNotFound: 'Wallet nicht gefunden',
    generateWalletError: 'Fehler beim Generieren des Wallets',
    missingMnemonic: 'Mnemonik fehlt',
    missingPrivateKeyFile: 'Privater Schlüsseldatei fehlt',
    privateKeyFileOversize: 'Privater Schlüsseldatei ist über das 10KB-Limit',
    transferTitle: 'Überweisung',
    transferHistoryTitle: 'Überweisungsverlauf',
    transferTo: 'Empfänger',
    transferAmt: 'Überweisungsbetrag (bis zu 6 Stellen Nachkommastellen)',
    invalidTo: 'Ungültiger Empfänger',
    invalidAmt: 'Ungültiger Überweisungsbetrag',
    amtOutOfRange: 'Der Überweisungsbetrag muss positiv sein und nicht größer als 1 Million sein.',
    transferSuccess: 'Überweisung erfolgreich',
    senderRecipientSame: 'Sender kann nicht der Empfänger sein',
    recipientNotFound: 'Empfänger nicht gefunden',
    insufficientFund: 'Überweisung abgebrochen, aufgrund von insufficientem Guthaben',
    transferError: 'Fehler bei der Überweisung',
    votingResNotRetrieved: 'Der Überweisungsergebnis wurde nicht abgerufen',
    candidateSelected: 'Der von Ihnen verantwortliche Prüfkandidat wurde ausgewählt',
    candidateNotSelected: 'Der von Ihnen verantwortliche Prüfkandidat wurde nicht ausgewählt',
    votingResNotReady: 'Der Überweisungsergebnis ist derzeit nicht abrufbar',
    notVoted: 'Sie haben keinen Prüfkandidat verantwortlich gemacht',
    candidateNotFound: 'Der Prüfkandidat wurde nicht gefunden',
    votingResRetrievalErr: 'Fehler beim Abrufen des Überweisungsergebnisses',
    from: 'Ausgangsadresse',
    to: 'Zieladresse',
    type: 'Typ',
    amount: 'Menge',
    time: 'Zeit',
    txnTypes: {
      101: 'Erste Belohnung',
      201: 'Manuelle Umlauf',
      301: 'Auftragsleistung',
      401: 'Micropayment',
    },
    walletAddress: 'Wallet-Adresse',
    notInGenesisNodeList: 'Die Genesis-Node kann nur auf Genesis-Minern gebunden werden.',
    onChainStatusLabel: 'Übertragungsstatus',
    onChainStatusDesc: {
      unknown: '-',
      onChain: 'Ja',
      notOnChain: 'Nein',
    },
    pwMgmtSection: {
      title: 'Passwortverwaltung',
      setBtnText: 'Passwort einstellen',
      resetBtnText: 'Passwort zurücksetzen',
    },
    passwordModal: {
      title: 'Wallet-Passwort einstellen',
      onePlaceholder: 'Passwort eingeben',
      twoPlaceholder: 'Passwort wiederholen',
      btnText: 'Bestätigen',
    },
    setPasswordMsg: {
      mismatch: 'Passwörter stimmen nicht überein',
      weak:
        'Das Passwort muss mindestens aus 8 Zeichen bestehen, und darf Kleinbuchstaben, Großbuchstaben und Zahlen enthalten',
      alreadySet: 'Das Wallet-Passwort ist bereits eingestellt',
      genericErr: 'Fehler beim Einstellen des Wallet-Passworts',
      success: 'Wallet-Passwort erfolgreich eingestellt',
    },
    authModal: {
      title: 'Wallet-Passwort',
      inputPlaceholder: 'Wallet-Passwort eingeben',
      btnText: 'Weiter',
    },
    passwordNotSet: 'Das Walletpasswort ist noch nicht eingestellt',
    passwordIncorrect: 'Das Passwort für das Wallet ist falsch',
    resetModal: {
      disclaimer:
        'Einmal das Passwort des Wallets zurückgesetzt, wird Ihre Geräte unabhängig von jedem aktiven Wallet gekoppelt. Geben Sie "Ich bestätigen, das Passwort des Wallets zurückzusetzen" um fortzufahren',
      consent: 'Ich bestätigen, das Passwort des Wallets zurückzusetzen',
    },
    resetPasswordMsg: {
      noActiveBinding: 'Die Geräte ist mit dem angegebenen Wallet nicht verbunden',
      unbindErr: 'Fehler beim Entfernen des Wallets',
      genericErr: 'Fehler beim Zurücksetzen des Walletpassworts',
      success: 'Das Passwort des Wallets wurde erfolgreich zurückgesetzt',
    },
    unstaking: {
      label: 'Verpflichtungserklärung',
      getErr: 'Auswahl der Verpfändungsbeendigung fehlgeschlagen',
      alreadyChosen: 'Sie haben beschlossen, das Pfand zu beenden',
      expired: 'Die Kündigungsfrist des Pfandes ist abgelaufen',
      invalidCreditData: 'Die Wallet ist nicht mit dem Internet verbunden',
      phase1IsNotOver: 'Die erste Phase der Wallet Verpfändung Aktivität ist nicht beendet',
      setErr: 'Einstellung der Verpfändungsbeendigungsauswahl fehlgeschlagen',
      announcementTitle: 'Sehr geehrter Stapelknotenbenutzer:',
      announcement: {
        continueStaking:
          'Verlängerung der Verpflichtungserklärung: Ihre Einsatzaktivität ist automatisch in die zweite Phase eingetreten; Die zweite Verpfändungsfrist beträgt sechs Monate. Die APY für Basic Mining und Genesis bleibt in der zweiten Periode unverändert.',
        duringStaking:
          'Während der zweiten Verpfändungsfrist: Sie können diese Schaltfläche jederzeit anklicken, um die Verpfändung zu kündigen; Diese Entscheidung ist unumkehrbar!',
        stopStaking: 'Kündigung der Verpflichtung: Sie treten in eine lineare Freigabephase von drei Monaten ein;',
        releaseStaking:
          'Verpflichtungsermächtigung: DPR wird täglich automatisch auf deine DeeperChain Wallet freigegeben.',
      },
      tipTitle: 'Freundliche Erinnerung:',
      tip: {
        afterStopStaking_1:
          '1) Nach Beendigung der Verpfändung wird das aus der Verpfändung erhaltene Guthaben gelöscht (das Guthaben, das aufgrund der Bandbreitenteilung während des Verpfändungszeitraums angesammelt wurde, wird beibehalten)',
        afterStopStaking_2:
          '2) Die Kündigung der Verpfändung eines Genesis-Knotens führt dazu, dass seine Genesis-Position verloren geht und dauerhaft die Möglichkeit verloren wird, ein Genesis-Knoten zu sein. Ihr Gerät kann nicht sofort an Basic Pledge 2.0 teilnehmen, aber die organische Gutschrift, die durch die gemeinsame Nutzung der Bandbreite generiert wird, wird fortgesetzt.',
        afterStopStaking_3: '3) Nach Beendigung der Verpfändung erlischt die Verpfändungsbelohnung Ihres DVR sofort.',
        afterStopStaking_4:
          '4) Nach Beendigung der Verpfändung gibt es keine sofortige Gelegenheit zur erneuten Verpfändung (Warten auf weitere Ankündigung)',
        afterStopStaking_5:
          '5) Nach Beendigung der Verpfändung endet die erste Periode der 270-Prämien erst, nachdem alle Prämien erreicht wurden.',
      },
      btn: {
        cancel: 'Verstanden, halten Sie das Versprechen.',
        ok: 'Rücknahme der Verpfändung',
      },
    },
    staking: {
      'walletInfo': 'Wallet-Informationen',
      'campaignLabel': 'Aktionsbezeichnung',
      'campaignText': {
        '0': 'Goldener Genesis-Node',
        '1': 'Silberner Genesis-Node',
        '2': 'Staking-Mining 1.0',
        '3': 'Mining von DPRB',
        '4': 'Staking-Mining 2.0',
        '7': 'Staking-Mining 2.0',
        '8': 'Staking-Mining 2.0',
      },
      'creditLabel': 'Aktueller Kreditpunkt',
      'creditMaxLabel': 'Höchstwert des Kreditpunkts',
      'creditLevelLabel': 'Kreditschwelle',
      'stakeAndBurn': 'Beteiligung und Verbrauch',
      'stakeForCredit': 'Erhöhung der Beteiligung',
      'dailyReward': 'Tägliche Belohnung',
      view: 'Überprüfen',
      'payment': 'Zahlung erforderlich',
      'insufficientBalance': 'Ungültiger Bestand',
      'stakeSuccess': 'Anfrage gesendet, bitte warten Sie auf die Aktualisierung des Kreditpunkts.',
      'stakeError':
        'Anfrage fehlgeschlagen, bitte überprüfen Sie Ihre Wallet-Informationen oder kontaktieren Sie den Kundenservice.',
      'burnForCredit': 'Verbrauch von DPR zur Erhöhung des Kreditpunkts',
      'burnSuccess': 'Anfrage gesendet, bitte warten Sie auf die Aktualisierung des Kreditpunkts.',
      'burnError':
        'Anfrage fehlgeschlagen, bitte überprüfen Sie Ihre Wallet-Informationen oder kontaktieren Sie den Kundenservice.',
    },
  },
  // ChangePasswordData
  ChangePasswordData: {
    'successMsg': 'Das Passwort wurde erfolgreich geändert',
    'newPasswordMismatch': 'Die beiden eingegebenen neuen Passwörter stimmen nicht überein',
    'oldPasswordMismatch': 'Das alte Passwort ist falsch',
    'samePasswordError': 'Das neue Passwort ist identisch mit dem alten',
    'genericError': 'Es ist ein Fehler aufgetreten bei der Änderung des Passworts',
    'title': 'Passwort ändern',
    'oldPwd': 'Bitte geben Sie das alte Passwort ein',
    'newPwd': 'Bitte geben Sie das neue Passwort ein',
    'newPwdAgain': 'Nochmals das neue Passwort eingeben',
    'okText': 'Bestätigen',
  },
  //dailyTraffic
  dailyTraffic: {
    historySize: 'Tage',
    chartTitle: 'Traffic',
    uptimeChartTitle: 'Uptime',
    hour: 'Stunde',
    minute: 'Minute',
    second: 'Sekunde',
  },
  // flow
  flow: {
    'totalTrafficLabel': 'Gesamtverkehr',
    'localTraffic': 'Lokale Verbindungen',
    'clientTraffic': 'Clientverbindungen',
    'serverTraffic': 'Serververbindungen',
    'upload': 'Hochladen',
    'download': 'Herunterladen',
    'sessionSpeed': 'Sitzungsgeschwindigkeit',
  },
  // login
  login: {
    'pageTitle': 'Willkommen bei Deeper Network!',
    'username': 'Benutzername',
    'password': 'Passwort',
    'btnText': 'Anmelden',
    'successMsg': 'Anmeldung erfolgreich',
    'missingField': 'Benutzername oder Passwort fehlt',
    'wrongField': 'Benutzername oder Passwort falsch',
    'invalidToken': 'Ungültige Anmeldedaten',
    'expiredToken': 'Anmeldedaten sind abgelaufen',
    'genericError': 'Anmeldefehler',
    'tooManyAttempts': 'Zu viele Anmeldeversuche',
  },
  //notification
  notification: {
    search: 'Suche',
    CP: 'Steuerungsebene',
    DP: 'Datenbasis',
    MP: 'Verwaltungsebene',
  },
  // DomainConfig
  domainConfig: {
    whiteList: 'Intelligente Routing-Liste',
    blackList: 'Liste direkter Weiterleitungen',
    domain: 'Domain name',
    tunnel: 'Tunnel',
    selectTunnel: 'Select a tunnel',
    region: 'Region',
    country: 'Land oder Region',
    noTunnel: 'Nicht durch den Tunnel gehen',
    convertedTip: 'Die Website wurde automatisch in einen Domainnamen umgewandelt',
    urlInputPlaceholder: 'Zum Beispiel: https://www.google.com Oder google.com',
    missingInfoWarning: 'Bitte geben Sie vollständige Informationen an',
    invalidDomainError: 'Domain name format incorrect or too long',
    whitelistAddSuccess: 'Erfolgreich zur Smart Routing Liste hinzugefügt',
    existInBlacklist: 'Das hinzugefügte Element existiert in der direkten Routingliste',
    whitelistEntryExist: 'Es gibt bereits einen Eintrag in der Smart Routing Liste',
    tunnelNotExist: 'Der Tunnel existiert nicht und kann nicht hinzugefügt werden',
    whitelistAddError: 'Intelligent routing list item addition failed',
    blacklistAddSuccess: 'Erfolgreich zur direkten Routingliste hinzugefügt',
    existInWhitelist: 'Das hinzugefügte Element existiert in der Smart Routing-Liste',
    blacklistEntryExist: 'Ein Eintrag wurde bereits zur direkten Routingliste hinzugefügt',
    blacklistAddError: 'Failed to add direct routing list entry',
    conflict: 'Element kann aufgrund von Konflikten nicht hinzugefügt werden',
    whitelistDelSuccess: 'Das Smart Routing Listenelement wurde erfolgreich gelöscht',
    domainNotExist: 'Der ausgewählte Domainname existiert nicht',
    whitelistDelError: 'Intelligent routing list item deletion failed',
    blacklistDelSuccess: 'Das direkte Routing-Listenelement wurde erfolgreich gelöscht',
    blacklistDelError: 'Failed to delete direct routing list entry',
    backupTips:
      'Der Backup-Tunnel wurde geöffnet. Wenn im intelligenten Routing-Modus keine verfügbaren Knoten für den Tunnel hinzugefügt wurden, verwendet das System standardmäßig den Backup-Tunnel "{0}',
    tips: {
      smartRouteList:
        'Je nach Bedarf können Sie den Domainnamen und das entsprechende Land, auf das Sie zugreifen möchten, manuell hinzufügen.',
      directRouteList:
        'Der hier hinzugefügte Domainname wird nicht durch den DPN-Tunnel geführt, sondern direkt im lokalen Netzwerk verwendet.',
    },
  },
  // ipConfig
  ipConfig: {
    whiteList: 'Smart IP List',
    blackList: 'Direct IP List',
    ip: 'IP',
    tunnel: 'Tunnel',
    selectTunnel: 'Tunnel auswählen',
    region: 'Region',
    country: 'Land oder Region',
    noTunnel: 'Nicht durch den Tunnel gehen',
    convertedTip: 'Die Website wurde automatisch in eine IP-Adresse umgewandelt',
    urlInputPlaceholder: 'Zum Beispiel: https://xxx.xxx.xxx.xxx Oder xxx.xxx.xxx.xxx.xxx',
    missingInfoWarning: 'Bitte geben Sie vollständige Informationen an',
    invalidIPError: 'IP Adresse Format ist falsch',
    whitelistAddSuccess: 'Erfolgreich zur Smart IP List hinzugefügt',
    existInBlacklist: 'Das hinzugefügte Element existiert in der direkten IP Liste',
    whitelistEntryExist: 'Es gibt bereits einen Eintrag in der Smart IP Liste',
    tunnelNotExist: 'Der Tunnel existiert nicht und kann nicht hinzugefügt werden',
    whitelistAddError: 'Hinzufügen intelligenter IP-Listenelemente fehlgeschlagen',
    blacklistAddSuccess: 'Erfolgreich in die Liste der direkten IP aufgenommen',
    existInWhitelist: 'Das Zusatzelement befindet sich in der Smart IP Liste',
    blacklistEntryExist: 'Es gibt bereits Einträge in der direkten IP-Liste',
    blacklistAddError: 'Hinzufügen von direktem IP-Listenelement fehlgeschlagen',
    whitelistDelSuccess: 'Smart IP Listenelement erfolgreich gelöscht',
    ipNotExist: 'Die gewählte IP existiert nicht',
    whitelistDelError: 'Intelligentes Löschen von IP-Listenelementen fehlgeschlagen',
    blacklistDelSuccess: 'Direktes IP-Listenelement erfolgreich gelöscht',
    blacklistDelError: 'Direktes Löschen von IP-Listenelementen fehlgeschlagen',
    backupTips:
      'Der Sicherungstunnel wurde geöffnet. Wenn im intelligenten Routing-Modus keine verfügbaren Knoten für den Tunnel hinzugefügt wurden, verwendet das System standardmäßig den Sicherungstunnel {0}.',
    tips: {
      smartRouteList:
        'Je nach Bedarf können Sie die IP, auf die Sie zugreifen möchten, und das entsprechende Land manuell hinzufügen.',
      directRouteList:
        'Die hier hinzugefügte IP wird nicht durch den DPN-Tunnel passieren, sondern direkt das lokale Netzwerk nutzen.',
    },
  },
  // queryRouting
  queryRouting: {
    title: 'Suche Routing',
    searchLabel: 'Suche Konfiguration',
    search: 'Suchen',
    searchResult: 'Ergebnis der Suche: ',
    invalidRoutingError: 'Fehler bei der Suchformate',
    defaultConfig: 'Standardkonfiguration',
    userConfig: 'Benutzerkonfiguration',
    routingNotFound: 'Routing nicht gefunden',
    queryRoutingError: 'Fehler bei der Routing-Abfrage',
    convertedTip: 'URL wurde automatisch in ein Domänenname oder IP umgewandelt',
    urlInputPlaceholder: 'z.B. google.com oder xxx.xxx.xxx.xxx',
  },
  // rustdesk
  rustdesk: {
    ipLabel: 'Ihre IP-Adresse ist',
    noPubIp: 'Sie haben keine öffentliche IP.',
    vpnUse:
      'Sie können die Wireguard VPN-Portweiterleitung verwenden, um eine öffentliche IP zu erhalten. Beachten Sie, dass diese public IP nur für den RustDesk-Service benutzt werden kann und kein DPN-Service anbietet.',
    config: {
      title: 'RustDesk Konfiguration',
      switch: 'Ein/Aus',
      forceKey: 'Erforderlichkeit der Public Key',
      relay: 'Englische Kommata getrennte Relay-Server (host:port), z.B. www.example.com:21116,192.168.1.1:21117',
      port: 'Port (2-65532)',
      saveBtnText: 'Speichern',
      pubKey: 'Public Key',
      emptyPubKey: 'Keine Public Key',
      resetBtnText: 'Public Key zurücksetzen',
    },
    vpn: {
      title: 'Wireguard VPN-Portweiterleitung',
      switch: 'Ein/Aus',
      ip: 'Wireguard Server IP',
      port: 'VPN-Weiterleitungsport',
    },
    guide: {
      title: 'Einrichtungsanleitung',
      stepOne: 'Klicken Sie auf das Bild unten',
      stepTwoA: 'Füllen Sie das Formular mit den entsprechenden Werten aus und klicken Sie auf Speichern',
      idAndRelay: 'ID/Relay-Server',
      idServer: 'ID Server:',
      relayServer: 'Relay-Server:',
      apiServer: 'API Server:',
      serverKey: 'Key:',
      stepTwoB: 'Oder Sie können die Konfiguration in die Zwischenablage kopieren und in RustDesk importieren.',
      copyBtnText: 'Serverkonfiguration in die Zwischenablage kopieren',
    },
    success: {
      setSwitch: 'RustDesk umschalten erfolgreich',
      setForced: 'Erforderlichkeit der Public Key erfolgreich',
      setRelayAndPort: 'Relay und Port speichern erfolgreich',
      resetPubKey: 'Public Key zurücksetzen erfolgreich',
      setVpnSwitch: 'Wireguard VPN umschalten erfolgreich',
      copyToClipboard: 'In die Zwischenablage kopieren erfolgreich',
    },
    error: {
      getNetworkAddress: 'IP-Adresse abrufen fehlgeschlagen',
      getConfig: 'RustDesk-Konfiguration abrufen fehlgeschlagen',
      setSwitch: 'RustDesk umschalten fehlgeschlagen',
      setForced: 'Fehler bei der Anforderung der gleichen öffentlichen Schlüssel',
      invalidPort: 'Ungültiger Port',
      invalidRelay: 'Ungültiges Relay',
      setRelayAndPort: 'Relay und Port speichern fehlgeschlagen',
      resetPubKey: 'Öffentlichen Schlüssel zurücksetzen fehlgeschlagen',
      getVpnConfig: 'Wireguard VPN-Konfiguration abrufen fehlgeschlagen',
      setVpnSwitch: 'Wireguard VPN umschalten fehlgeschlagen',
      copyToClipboard: 'In die Zwischenablage kopieren fehlgeschlagen',
      getVersion: 'Versionsnummer abrufen fehlgeschlagen',
      getHbbrLog: 'HBBR-Protokoll abrufen fehlgeschlagen',
      getHbbsLog: 'HBBS-Protokoll abrufen fehlgeschlagen',
    },
  },
  // sharing
  sharing: {
    sharingTitle: 'Teilen: ',
    btSharingTitle: 'Bit Torrent Teilen: ',
    enabled: 'Aktivieren',
    disabled: 'Beenden',
    sharingTrafficLimit: 'Monatliches geteiltes Verkehrslimit',
    sharingBandwidthLimit: 'Geschwindigkeitsbegrenzung für gemeinsame Bandbreite',
    unlimited: 'Unbegrenzte Geschwindigkeit',
    limited: 'Geschwindigkeitsbegrenzung',
    setLabel: 'einrichten',
    checkStartEndTime: 'Bitte Start- und Endzeiten auswählen',
    time: 'Zeit',
    record: 'Aufzeichnung',
    startTime: 'Startzeit',
    endTime: 'Endzeit',
    keyword: 'Stichwort',
    search: 'Suche',
    webToSharingTitle: 'Filterregeln für Webseiten anwenden',
    webToSharingDesc:
      'Wenden Sie die integrierte DNS-Werbefilterung und die benutzerdefinierten DNS-Filterregeln des Geräts an',
    yes: 'ja',
    no: 'nein',
    download: 'Textprotokoll löschen',
    export: 'Protokolle verschlüsseln',
    success: {
      enableTrafficLimit: 'Monatliches Sharing-Traffic-Limit aktiviert. Bitte legen Sie das Limit fest!',
      setSharingTrafficLimit: 'Freigegebene Datenverkehrslimits erfolgreich festgelegt',
      enableBandwidthLimit:
        'Geschwindigkeitsbegrenzung für gemeinsame Bandbreite wurde aktiviert Bitte setzen Sie die Obergrenze zwischen 15Mbps und 1024Mbps!',
      setSharingBandwidthLimit: 'Tempolimit für gemeinsame Bandbreite erfolgreich festgelegt',
    },
    errors: {
      getSharingState: 'Abrufen des Freigabestatus fehlgeschlagen',
      switchSharingState: 'Wechsel des Freigabestatus fehlgeschlagen',
      exceedsLimit: 'Freigabe aktivieren fehlgeschlagen, Traffic Limit überschritten',
      getSharingTrafficLimit: 'Das maximale gemeinsame Datenverkehrslimit konnte nicht abgerufen werden',
      setSharingTrafficLimit: 'Festlegen des Freigabeverkehrslimits fehlgeschlagen',
      invalidTrafficLimit: 'Das Verkehrslimit muss eine positive Zahl sein',
      setSharingBandwidthLimit: 'Festlegen der gemeinsamen Bandbreitenlimits fehlgeschlagen',
      invalidBandwidthLimit: 'Die Bandbreitenlimits muss zwischen 15Mbps und 1024Mbps liegen',
      getSharingFilterConfig: 'Abrufen der gemeinsamen Filterkonfiguration fehlgeschlagen',
      setSharingFilterConfig: 'Einstellung der gemeinsamen Filterkonfiguration fehlgeschlagen',
    },
  },
  // system
  system: {
    hardwareInfo: 'Hardware-Informationen',
    softwareInfo: 'Software-Informationen',
    networkAddress: 'Netzwerkadresse',
    sessionInfo: 'Sitzungsinformationen',
    upgradeInfo: 'Upgrade-Informationen',
    sn: 'Seriennummer',
    cpu: 'CPU',
    cores: 'Kerne',
    memory: 'Verwendet / Verfügbar',
    systemVersion: 'Systemversion',
    appidSignVersion: 'Traffic Protocol Identification Feature Library',
    urlFilterSignVersion: 'Web Filtering Feature Library',
    https: 'HTTPS',
    dns: 'DNS',
    rules: 'Regeln',
    ip: 'IP-Adresse',
    routerMac: 'Router-MAC',
    gatewayMac: 'Gateway-MAC',
    maxSession: 'Maximale Sitzungen',
    currentSession: 'Aktuelle Sitzungen',
    tcpUdpIcmp: 'TCP/UDP/ICMP/TUNNEL',
    autoUpdate: 'Automatisches Update',
    manualUpgrade: 'Manuelles Upgrade',
    latestVersion: 'Neueste Version',
    upgrading: 'Lade Datei... Bitte warten Sie, bis das Upgrade fertig ist.',
    systemStatusHistory: 'Systemhistorische Status',
    cpuUsage: '24-Stunden CPU-Nutzungsrate',
    memoryUsage: '24-Stunden Speichernutzungsrate',
    off: 'Nein',
    on: 'Ja',
    nowCheckVersion: 'Die aktuelle Version wird geprüft...',
    nowLatestVersion: 'Die aktuelle Version ist die neueste. Keine Aktualisierung notwendig.',
    nowNewTestVersion: 'Die aktuelle Version wird verwendet. Keine Aktualisierung notwendig.',
    upgradeLoadingText:
      'Das Firmware wird aktualisiert. Bitte laden Sie die Seite nicht neu, sondern warten Sie, bis das Upgrade vollständig ist.',
    upgradeSuccess: 'Die Aktualisierung war erfolgreich. Die Seite wird nun neu geladen.',
    upgradeError: {
      noImage: 'Upgradepaket fehlt.',
      invalidImage: 'Ungültiger Upgradepaket.',
      imageArchMismatch: 'Upgradepaket passt nicht zum Gerät zu.',
      noResponse:
        'Das Gerät hat beendet und neu gestartet. Bitte warten Sie einen Moment, um sicherzustellen, ob die Aktualisierung erfolgreich war.',
      alreadyLatest: 'Die aktuelle Version ist die neueste. Keine Aktualisierung notwendig.',
      generic: 'Ein Fehler ist aufgetreten bei der Aktualisierung.',
    },
    copySuccess: 'Kopieren erfolgreich.',
    copyFail: 'Kopieren fehlgeschlagen.',
  },
  // techSupport
  techSupport: {
    'remoteConnection': {
      'title': 'Fernverbindung',
      'loading': 'Lade...',
      'popupTitle': 'Fernverbindungscode',
      'intro':
        "Anwendungsfälle:<br />Wenn deine Gerätenetzwerkfunktionen normal sind, aber andere Funktionsprobleme auftreten.<br />Erstelle manuell einen 'Fernverbindungscode' und sende ihn unsere Kundenservice-Team, damit unser Technikerteam die Probleme schnell lokalisieren und Ihnen helfen kann. Um sicherzustellen, dass der Code gültig ist, vermeide die Wiederholung des Generierens innerhalb kürzester Zeit.",
      'buttonText': 'Generieren',
      'generateFeatureCodeFailure': 'Fehler beim Generieren des Codes',
      'tips':
        'Wenn Sie irgendwelche Probleme haben, senden Sie uns Details zur Kundensupport. Wir streben danach, Ihnen zu helfen und sicherzustellen, dass Ihr Problem gelöst wird.',
      'confirm': 'Kopieren und schließen',
      'copySuccess': 'Erfolgreich in die Zwischenablage kopiert',
      'copyFailure': 'Kopieren in die Zwischenablage fehlgeschlagen',
      'connectFailure': 'Fehler bei der Fernverbindung',
    },
    'logDownload': {
      'title': 'Log-Download',
      'loading': 'Lade...',
      'intro':
        'Anwendungsfälle:<br />Wenn Ihr Gerät zum Back-End bereits angemeldet ist, aber Netzwerkverbindungsfehler auftreten.<br />Manuelles Herunterladen von Logfiles und Senden an unseren Kundenservice-Team, damit wir Ihren bestimmten Situation verstehen und daraufhin eine bessere Unterstützung geben können.',
      'buttonText': 'Herunterladen',
      'downloadSuccess': 'Download erfolgreich',
      'downloadFailure': 'Download fehlgeschlagen, bitte versuchen Sie es später noch einmal.',
    },
    'supportServices': {
      'title': 'Holen Sie dir die Unterstützung Sie benötigen',
      'intro': 'Geben Sie uns einige spezifische Informationen an, wir werden für Sie eine passende Lösung anbieten.',
      'buttonText': 'Hilfe bekommen',
    },
    'promiseTips': {
      'title': 'Sicherheitsversprechen:',
      'intro':
        'Seitensicherheit:<br />Wir <span>beschränken die Funktionen für Fernverbindung und Log-Download</span> nur auf Problemdiagnose und Lösung, nicht auf andere Zwecke, um sicherzustellen, dass Ihre Privatsphäre und Sicherheit am stärksten geschützt werden.',
    },
  },
  // builtinRules
  builtinRules: [
    {
      label: 'Aktiviere HTTPS-Filterung (einschließlich HTTP-Filterung)',
      sslbypass: 'Aktiviere SSL-Bypass',
      value: 'youtube',
    },
  ],
  // customrules
  customrules: [
    {
      label: 'Aktiviere HTTPS-Filterung (einschließlich HTTP-Filterung)',
      value: 'youtube',
    },
  ],
  // tproxy
  tproxy: {
    browserCacheWarning:
      'Wenn Sie HTTPS-Werbesperre aktivieren, müssen Sie den Browsercache leeren, falls die Werbesperre nicht richtig funktioniert. Nur dann kann diese Funktion einwandfrei die Werbesperre durchführen.',
    triggerRulesTipsTitle: 'Hinweis',
    buttonAddRule: 'Regel hinzufügen',
    buttonInstallCertificate: 'Zertifikat installieren',
    missingInfoWarning: 'Bitte prüfen Sie ob alle Informationen korrekt eingegeben sind',
    missingInfoSuccess: 'Erfolg',
    missingInfoError: 'Fehler',
    certificateTip:
      'Um sicherzustellen, dass der Dienst reibungslos funktioniert, müssen Sie sichergestellt haben, dass Sie das Zertifikat installiert und vertraut haben.',
    remind: 'Nicht mehr anzeigen',
    tip: 'Um Werbesperren richtig zu blockieren, müssen Sie folgende Schritte durchführen.',
    triggerRulesTips:
      'Nachdem Sie HTTPS-Filterung aktiviert haben, um Deeper Connect für die Werbesperre von Websites in Ihrer Liste zu sorgen, müssen Sie das Stammzertifikat von Deeper Network auf Ihrem Computer installieren und vertrauen.',
    triggerRulesTips2:
      'Nur dann kann diese Funktion einwandfrei die Werbesperre durchführen, wenn Sie das Zertifikat installiert und vertraut haben. Ohne das Zertifikat können Sie die Websites in der Liste nicht richtig aufrufen.',
    mobileDownloadCertTips: 'Bitte benutzen Sie Safari zum Herunterladen des Zertifikats',
    androidTVTip: 'Wenn Sie Android TV verwenden, laden Sie bitte die App herunter, um mit ihr zu arbeiten.',
    errorMessage: {
      maxLength: 'Die maximale Anzahl von Regeln beträgt 1024.',
      ruleExisted: 'Eine Regel mit diesem Domänennamen und der gleichen Ausdrucksweise existiert bereits.',
    },
    label: {
      domain: 'Domäne',
      Pattern: 'Regelausdruck zur Abfangsfilterung',
      ruleType: 'Regeltyp',
      web: 'Webbrowser',
      app: 'APP',
      all: 'Alle',
      Rewrite_Pattern: 'Umbenennungsregelausdruck',
      Rewrite_Info: 'Umbenennungsregelinformationen',
      Rewrite_Action: 'Handlung',
      enable: 'Aktivieren',
      operation: 'Operation',
      order: 'Nummer',
    },
    operation: {
      delete: 'Löschen',
      edit: 'Bearbeiten',
    },
    modal: {
      title: 'Regel hinzufügen',
      confirmText: 'Absenden',
      cancelText: 'Abbrechen',
    },
    placeholder: {
      domain: 'Bitte geben Sie den korrekten Domänennamen ein',
      Pattern: 'Bitte geben Sie den korrekten Ausdruck für die Abfangsfilterung ein',
      ruleType: 'Bitte wählen Sie den richtigen Regeltyp aus',
      Rewrite_Pattern: 'Bitte geben Sie den korrekten Umbenennungsausdruck ein',
      Rewrite_Info: 'Bitte geben Sie die Informationen zur Umbenennungsregel ein',
      Rewrite_Action: 'Bitte wählen Sie die Aktion aus',
    },
    builtinRules: {
      title: 'Werbesperre',
      filterListtitle: 'HTTPS-Filterliste',
      platform: 'Unterstützte Plattformen',
      segOptions: ['Einrichtungsleitfaden für Zertifikat', 'Unterstützte Liste'],
    },
    customerRules: {
      title: 'Benutzerdefinierte HTTPS-Regeln',
    },
    guide: {
      buttonDownloadCertificate: 'Klicken Sie hier, um das Zertifikat herunterzuladen',
      buttonDownloadApp: 'Klicken Sie, um SkyTube herunterzuladen',
      tab: {
        mobileInstall: 'iPhone/iPad',
        macInstall: 'Mac OS',
        windowsInstall: 'Windows',
        androidInstall: 'Android',
        linuxInstall: 'Linux',
      },
      mobile: {
        videoTab: 'Video',
        textTab: 'Zeichen',
        step1: {
          label: 'Schritt 1',
        },
        step2: {
          label: 'Schritt 2',
          tip: 'Klicken Sie auf "Einladung zulässiger Apps" zu akzeptieren',
        },
        step3: {
          label: 'Schritt 3',
          tip:
            'Öffnen Sie den "Einstellungen" Ihres Smartphones und klicken Sie auf "Heruntergeladene Beschreibungsdateien"',
        },
        step4: {
          label: 'Schritt 4',
          tip: 'Klicken Sie auf "Installieren", geben Sie das Passwort Ihres Smartphones ein',
        },
        step5: {
          label: 'Schritt 5',
          tip: 'Klicken Sie auf "Installieren", geben Sie das Passwort Ihres Smartphones ein',
        },
        step6: {
          label: 'Schritt 6',
          tip: 'Installation abgeschlossen',
        },
        step7: {
          label: 'Schritt 7',
          tip:
            'Öffnen Sie die Einstellungen Ihres Smartphones > "Geräte" > "Über dieses Gerät" > "Zertifikatsvertrauenseinstellungen" und aktivieren Sie das neu installierte Zertifikat',
        },
      },
      windows: {
        step1: {
          label: 'Schritt 1',
        },
        step2: {
          label: 'Schritt 2',
          tip: 'Klicken Sie auf das heruntergeladene Zertifikat und auf "Zertifikat installieren"',
        },
        step3: {
          label: 'Schritt 3',
          tip: 'Wählen Sie "Speicherort" als "Lokaler Computer" aus und klicken Sie auf "Weiter"',
        },
        step4: {
          label: 'Schritt 4',
          tip: 'Wählen Sie "Alle Zertifikate in diese Speicheroption einbeziehen" und klicken Sie auf "Durchsuchen"',
        },
        step5: {
          label: 'Schritt 5',
          tip: 'Wählen Sie "Vertrauenswürdige Stammzertifizierungsstelle" aus und klicken Sie auf "OK"',
        },
        step6: {
          label: 'Schritt 6',
          tip: 'Klicken Sie auf "Weiter"',
        },
        step7: {
          label: 'Schritt 7',
          tip: 'Klicken Sie auf "Fertig stellen", das Installieren des Zertifikats ist abgeschlossen',
        },
      },
      mac: {
        step1: {
          label: 'Schritt 1',
        },
        step2: {
          label: 'Schritt 2',
          tip:
            'Klicken Sie auf das heruntergeladene Zertifikat, dann wird eine "Zertifikat hinzufügen"-Box auftauchen. Wählen Sie im "Keychain" den "Anmeldestatus" aus und klicken Sie auf "Hinzufügen".',
        },
        step3: {
          label: 'Schritt 3',
          tip:
            'Im "Keychain Access"-Fenster wechseln Sie im linken Menü auf "Standardkeychain" und wählen Sie den "Anmeldestatus" aus.',
        },
        step4: {
          label: 'Schritt 4',
          tip:
            'Finden Sie das Zertifikat "deeper.network", markieren Sie es und klicken Sie mit Rechtsklick auf "Eigenschaften anzeigen".',
        },
        step5: {
          label: 'Schritt 5',
          tip:
            'Klicken Sie in der Eigenschaftenseite auf "Vertrauen", legen Sie die Option "Immer vertrauen" bei "Verwenden von Standardwerten des Systems" aktiviert.',
        },
        step6: {
          label: 'Schritt 6',
          tip:
            'Schließen Sie die Eigenschaftenseite oben links, geben Sie Ihr Computerpasswort ein und klicken Sie auf "Aktualisieren".',
        },
        step7: {
          label: 'Schritt 7',
          tip: 'Das Installieren des Zertifikats ist abgeschlossen.',
        },
      },
      linux: {
        rootTab: 'Anleitung zum Importieren von Systemstammzertifikaten',
        root: {
          step1: {
            label: 'Schritt 1',
          },
          step2: {
            label: 'Schritt 2',
            tip:
              '<p>Kopieren Sie die Zertifikatsdatei nach /usr/local/share/ca-certificates/</p>\
              <p>Dieses Verzeichnis ist speziell für die Speicherung von zusätzlich lokal installierten CA-Zertifikaten vorgesehen. Die Zertifikatsdatei sollte eine PEM-Format Datei mit der Erweiterung .crt sein.</p>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /usr/local/share/ca-certificates/</code></div>',
          },
          step3: {
            label: 'Schritt 3',
            tip:
              '<p>Aktualisieren Sie den Zertifikatsspeicher</p>\
              <p>Verwenden Sie den Befehl update-ca-certificates, um den Zertifikatsspeicher zu aktualisieren, was automatisch die Vertrauenskette der Zertifikate im System auffrischt.</p>\
              <div class="code"><code>sudo update-ca-certificates</code></div>\
              <p>Dieser Befehl liest die Zertifikatsdateien im Verzeichnis /usr/local/share/ca-certificates/ und fügt sie der Vertrauensliste des Systems hinzu.</p>\
              <em><em class="warning">Hinweis:</em> Verschiedene Linux-Distributionen haben möglicherweise unterschiedliche Speicherorte für Zertifikate und Aktualisierungsbefehle.</em><br>\
              <em>Zum Beispiel, auf Red Hat-basierten Systemen\
              (wie Fedora, CentOS) müssen Sie vielleicht das Zertifikat nach /etc/pki/ca-trust/source/anchors/ kopieren,</em><br>\
              <em>und dann den Befehl update-ca-trust ausführen, um den Zertifikatsspeicher zu aktualisieren.</em><br>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /etc/pki/ca-trust/source/anchors/</code><br>\
              <code>sudo update-ca-trust</code></div>\
              <p>Nach Durchführung dieser Schritte sollte das von Ihnen installierte Root-Zertifikat nun vom System vertraut werden.</p>',
          },
        },
        chrome: {
          step1: {
            label: 'Schritt 1',
          },
          step2: {
            label: 'Schritt 2',
            tip:
              'Geben Sie in der Adressleiste chrome://settings/certificates ein und wählen Sie die "Autoritäten"-Option aus.',
          },
          step3: {
            label: 'Schritt 3',
            tip:
              'Klicken Sie auf Importieren, suchen Sie nach dem heruntergeladeten Zertifikat und klicken Sie auf Öffnen.',
          },
          step4: {
            label: 'Schritt 4',
            tip:
              'Überprüfen Sie alle Optionen im Fenster "Certificate Authorization Center" und klicken Sie auf OK, um die Zertifikatinstallation abzuschließen.',
          },
        },
        firefox: {
          step1: {
            label: 'Schritt 1',
          },
          step2: {
            label: 'Schritt 2',
            tip:
              'Geben Sie in der Adressleiste about:preferences#privacy ein und suchen Sie nach "Certificates", klicken Sie auf "View Certificates".',
          },
          step3: {
            label: 'Schritt 3',
            tip: 'Wählen Sie "Authorities" aus, klicken Sie auf "Importieren".',
          },
          step4: {
            label: 'Schritt 4',
            tip: 'Wählen Sie das heruntergeladete Zertifikat aus, klicken Sie auf "Öffnen".',
          },
          step5: {
            label: 'Schritt 5',
            tip: 'Alle Punkte aktivieren, klicken Sie auf OK, das Installieren des Zertifikats ist abgeschlossen.',
          },
        },
      },
      android: {
        appTab: 'APP',
        browserTab: 'Browser',
        app: {
          step1: {
            label: 'Schritt 1',
          },
          step2: {
            label: 'Schritt 2',
            tip: 'Installieren Sie SkyTube, um Videos ohne Werbung zu genießen.',
          },
        },
        browser: {
          step1: {
            label: 'Schritt 1',
          },
          step2: {
            label: 'Schritt 2',
            tip: 'Öffne die "Einstellungen" deiner Mobilentelefonie und suche nach "Sicherheit".',
          },
          step3: {
            label: 'Schritt 3',
            tip:
              'Gehe in die "Sicherheit" und finde "Verschlüsselung und Anmeldeinformationen", klicke auf das entsprechende Menüelement.',
          },
          step4: {
            label: 'Schritt 4',
            tip: 'Klicke auf "Zertifikat installieren".',
          },
          step5: {
            label: 'Schritt 5',
            tip: 'Wähle den CA-Zertifikat aus.',
          },
          step6: {
            label: 'Schritt 6',
            tip: 'Klicke auf "Installieren stattdessen" und gebe dein Passwort ein.',
          },
          step7: {
            label: 'Schritt 7',
            tip: 'Wähle den heruntergeladeten Zertifikat aus.',
          },
          step8: {
            label: 'Schritt 8',
            tip: 'Hinweis: Der CA-Zertifikat wurde erfolgreich installiert.',
          },
        },
      },
    },
  },
  // SSLBypassCheckbox
  SSLBypassCheckbox: {
    sslBypass: 'SSL-Bypass-Funktion aktivieren',
    sslBypassTips: 'Erlauben Sie Geräten ohne installierte Zertifikate, sich mit dem Netzwerk zu verbinden.',
    sslBypassWaring:
      'Wenn die SSL-Bypass-Funktion aktiviert ist, kann es zu Auslassungen bei der Werbefilterung kommen.',
    success: 'Betrieb erfolgreich',
    failed: 'Operation fehlgeschlagen',
  },
  // tunnel
  tunnel: {
    configuredTunnel: 'Anpassungstunnle',
    countryTooltip:
      'Führen Sie eine Fuzzy-Suche nach dem Ländernamen durch oder geben Sie den Ländercode (in Großbuchstaben) ein und drücken Sie die Eingabetaste, um nach dem gewünschten Land zu suchen',
    region: 'Region',
    country: 'Land oder Region',
    activeTunnelNum: 'Aktive IP-Anzahl',
    activeIp: 'Aktive IP',
    switchNode: 'Sichern Sie die Aktive IP',
    switchIpTitle: 'IP wechseln',
    initTunnelTitle: 'Neuinitialisieren Sie das Tunnel',
    initTunnelTitleTips:
      'Ein Tunnel hat drei IPs, wenn Sie das Tunnel neu initialisieren, werden drei neue IPs für Sie generiert.',
    selectRegion: 'Wählen Sie eine Region',
    selectCountry: 'Wählen Sie ein Land oder Region aus',
    missingInfoWarning: 'Bitte geben Sie vollständige Informationen ein.',
    tunnelAddSuccess: 'Tunnel hinzugeschickt, Seite wird in 3 Sekunden neu geladen.',
    tunnelExist: 'Tunnel existiert bereits',
    reachedMax: 'Die Anzahl der Tunnels hat den Maximalwert erreicht.',
    tunnelConflict: 'Das Tunnel, das Sie hinzufügen möchten, konflikt mit dem bestehenden Tunnel {0} - {1}.',
    tunnelAddError: 'Fehler beim Hinzufügen des Tunnels.',
    tunnelDelSuccess: 'Tunnel erfolgreich gelöscht, Seite wird in 3 Sekunden neu geladen.',
    tunnelNotExist: 'Tunnel existiert nicht.',
    usedByFullMode: 'Dieser Tunnel wird von DPN im Vollroutenmodus verwendet und kann nicht gelöscht werden.',
    usedByAccessControl: 'Zu löschende Tunnel werden von Access Control verwendet',
    usedByBackup: 'Dieser Tunnel wird von einem Sicherungstunnle verwendet und kann nicht gelöscht werden.',
    tunnelInUse: 'Dieser Tunnel wird von der Routingtabelle verwendet und kann nicht gelöscht werden.',
    tunnelDelError: 'Fehler beim Löschen des Tunnels.',
    switchNodeSuccess: 'Schaltpunkt erfolgreich geändert, Seite wird in 3 Sekunden neu geladen.',
    noActiveIp: 'Keine aktiven IPs zum Wechseln verfügbar.',
    ipNotChanged: 'IP wurde nicht geändert.',
    switchNodeError: 'Fehler beim Wechseln des Schalterpunkts.',
    refreshLabel: 'Aktualisieren',
    getNewIPLabel: 'Neue IP bekommen',
    refreshError: 'Fehler beim Aktualisieren des Tunnels.',
    refreshSuccess:
      'Das Anfordern der Aktualisierung des Tunnels wurde angefordert, Seite wird in 3 Sekunden neu geladen.',
    tips: {
      refreshTunnel: 'Zur Aktualisierung neuer aktiven Tunnels.',
      configuredTunnel:
        'Nachdem Sie manuell die Regionstunnle, die Sie benötigen, hinzugefügt haben, sucht AtomOS weltweit nach Knoten, die Ihren Anforderungen entsprechen.',
    },
    nodeLbMode: {
      label: 'Auswahlmodus für den Knoten',
      none: {
        label: 'Normal',
        desc:
          'In einem Tunnel sind alle Netzwerkverbindungen durch einen aktiellen Knoten übertragbar, der immer kann gewechselt werden.',
      },
      random: {
        label: 'Zufällig',
        desc: 'Der System wählt automatisch zufällig für jede Verbindung einen Knoten aus.',
      },
      domain: {
        label: 'Domänennachverhalten',
        desc:
          'Der System verwendet mehrere Knoten im Tunnel zur Internetverbindung, aber behält er sich jedoch immer an, dass Verbindungen mit bestimmten Domänennamen immer zu demselben Knoten geleitet werden, um die Risiko von Website-Fehlern zu reduzieren.',
      },
      rr: {
        label: 'Round Robin',
        desc:
          'Bei jeder Verbindung wählt der System in Reihenfolge aus einigen Knoten ab, um eine bessere Netzwerkgeschwindigkeit zu erreichen.',
      },
      failure: 'Das Speichern des Loadbalancing-Modus für den Knoten fehlgeschlagen',
      success: 'Das Speichern des Loadbalancing-Modus für den Knoten erfolgreich',
    },
  },
  // userConsent
  userConsent: {
    notice:
      'Hinweis: Die Freigabefunktion ist bis Sie den Nutzungsbedingungen und die Datenschutzrichtlinie zustimmen, geschlossen.',
    consentStart: 'Ich habe die Deeper Network-',
    termsOfUseNotRead: 'Sie haben noch nicht die Nutzungsbedingungen gelesen',
    termsOfUseLink: 'https://shop.deeper.network/terms-of-service?lang=de',
    termsOfUseTitle: 'Nutzungsbedingungen',
    consentAnd: ' und ',
    privacyPolicyNotRead: 'Sie haben noch nicht die Datenschutzrichtlinie gelesen',
    privacyPolicyLink: 'https://shop.deeper.network/privacy-policy?lang=de',
    privacyPolicyTitle: 'Datenschutzrichtlinie',
    confirmBtn: 'Bestätigen',
    consentError: 'Zustimmung bestätigen fehlgeschlagen',
  },
  // customConfigLang
  customConfigLang: {
    missingInfoWarning: 'Fehler: Domain fehlt',
    invalidDomainError: 'Fehler: Ungültiger Domain oder Domain ist zu lang',
    whitelist: 'Whiteliste',
    blacklist: 'Blackliste',
    domain: 'Domain',
    whitelistAddSuccess: 'Erfolgreich hinzugefügt zur Whiteliste',
    whitelistEntryExist: 'Die Whiteliste enthält bereits diesen Eintrag',
    reachedWhiteMax: 'Die Whiteliste ist auf ihrem Maximalwert erreicht',
    whitelistAddError: 'Fehler beim Hinzufügen der Whiteliste',
    blacklistAddSuccess: 'Erfolgreich hinzugefügt zur Blackliste',
    blacklistEntryExist: 'Die Blackliste enthält bereits diesen Eintrag',
    reachedBlackMax: 'Die Blackliste ist auf ihrem Maximalwert erreicht',
    blacklistAddError: 'Fehler beim Hinzufügen der Blackliste',
    whitelistDelSuccess: 'Erfolgreich von der Whiteliste entfernt',
    whitelistDelError: 'Fehler beim Entfernen der Whiteliste',
    blacklistDelSuccess: 'Erfolgreich von der Blackliste entfernt',
    blacklistDelError: 'Fehler beim Entfernen der Blackliste',
    import: 'Importieren',
    fileButtonText: 'Datei hochladen',
    fileSizeOverLimit: 'Das hochgeladene Datei ist größer als das maximale 2MB-Größenlimit',
    whitelistImportSuccess: 'Erfolgreich importiert, {0} Einträge importiert',
    whitelistImportError: 'Fehler beim Importieren der Whiteliste',
    blacklistImportSuccess: 'Erfolgreich importiert, {0} Einträge importiert',
    blacklistImportError: 'Fehler beim Importieren der Blackliste',
    noValidEntry: 'Keine gültigen Einträge in der hochgeladenen Datei',
    zeroImported: 'Keine Einträge importiert',
  },
  // defaultConfigLang
  defaultConfigLang: {
    browserCacheWarning:
      'Wenn die DNS-Anzeigenfilterung aktiviert ist und Anzeigen nicht ordnungsgemäß gefiltert werden können, müssen Sie den Browser-Cache bereinigen und das gefilterte Gerät neu starten.',
    queriesFor24Hours: 'Anfragen der letzten 24-Stunden',
    totalQuery: 'Anfrage insgesamt',
    blockedQuery: 'Abfanganfragen',
    blockedPercentage: 'Abfangprozentualer Anteil',
    ruleTotal: 'Regel',
    adQuery: 'Intercept-Anzeigen',
    trackerQuery: 'Abfangen und Verfolgen',
    maliciousQuery: 'Abwehr bösartiger Angriffe',
    pornQuery: 'Filme für Erwachsene abfangen',
    legendTotal: 'insgesamt',
    legendBlocked: 'Abfangen',
    categoryStateEnabled: 'Aktivieren',
    categoryStateDisabled: 'Aktivieren',
    categoryState2Hours: 'Zwei Stunden lang behindert',
    categoryState4Hours: 'Behindert für vier Stunden',
    categoryState8Hours: 'Acht Stunden lang behindert',
    setCategoryStatesErr: 'Fehler beim Aktivieren/Deaktivieren der Filterung',
    enableAll: 'Alle Filter aktivieren',
    disableAll: 'Alle Filter deaktivieren',
  },
  // wifiSettings
  wifiSettings: {
    'workMode': 'Arbeitsmodus',
    'currentWorkMode': 'Aktueller Arbeitsmodus',
    'upstreamTitle': 'Hochstreckeneinstellungen',
    'backHome': 'Zurück zur Startseite',
    'wire-ap': 'Kabel-WAN',
    'wlan-ap': 'WLAN-WAN',
    'downstreamTitle': 'Unterfährbandbreite',
    'notConnect': 'Keine Internetverbindung',
    'apConfigWarning':
      'Nachdem Sie den Wi-Fi-SSID und das Wi-Fi-Passwort gespeichert haben, müssen Sie die Deeper-Geräte-Hotspot neu scannen und das Passwort anwenden, wenn Sie per Deeper-Gerät surfen möchten.',
    'attention': 'Achtung',
    buttons: {
      'scanWifi': 'Scannen',
      'connectStatus': 'Status',
      add: 'Hinzufügen',
      'save': 'Speichern',
      'cancel': 'Abbrechen',
      'downloadLog': 'Download Log',
    },
    connect: 'verbinden',
    forget: 'vergessen',
    waiting: {
      content: 'Verbinden, es kann einige Zeit dauern, bitte haben Sie Geduld und warten Sie',
      cancelBtn: 'Verbindung abbrechen',
    },
    modals: {
      hiddenWifi: {
        title: 'Verstecktes WLAN hinzufügen',
        okBtn: 'Hinzufügen',
      },
      scanWifi: {
        title: 'Warnung',
        content: 'Das Netzwerk wird vorübergehend getrennt',
        ok: 'bestätigen',
        cancel: 'Abbrechen',
      },
    },
    label: {
      ssid: 'Wi-Fi Name',
      password: 'Wi-Fi Passwort',
      onlyUse: 'Nur verwenden',
      hidden: 'SSID ausblenden',
      freqBand: 'Frequenzband',
    },
    placeholder: {
      ssid: 'Bitte geben Sie SSID ein',
      password: 'Bitte geben Sie das Passwort ein',
    },
    message: {
      'saveSuccess': 'Erfolgreich gespeichert',
      'saveError': 'Fehler beim Speichern, bitte versuchen Sie es erneut.',
      'connectSuccess': 'Verbindung erfolgreich',
      'forgetSuccess': 'Vergessen erfolgreich',
      'inputError': 'Bitte überprüfen Sie Ihre Eingabe.',
      'invalidWifiDev': 'Kein gültiger Wi-Fi-Gerät verfügbar, keine Wi-Fi-Einstellungen möglich.',
      'illegalWifiDev': 'Wi-Fi-Einstellungen sind nur in AP Router Mode und Wireless Relay Mode verfügbar.',
      'invalidSsid': 'Nur Buchstaben, Zahlen, _ und - unterstützt.',
      'invalidPassword': 'Bitte geben Sie ein gültiges Passwort ein.',
      'notConnect': 'Keine Verbindung zum Internet.',
      'retry': 'Versuchen Sie es noch einmal.',
      'connectionError': 'Verbindungsfehler.',
      'forgetError': 'Vergessen fehlgeschlagen.',
      'wifiNotSupported': 'Wi-Fi wird nicht unterstützt.',
      'networkNotFound': 'Netzwerk nicht gefunden.',
      'pskNotGenerated': 'Schlüssel wurde nicht generiert.',
      'wrongPassword': 'Falsches Passwort.',
      'timeout': 'Zeitüberschreitung bei der Verbindung.',
      'logDownloadError': 'Fehler beim Herunterladen des Logs.',
      'hasBeenConnected': 'Bereits verbonden.',
      'dhcpSuccess': 'Erfolg',
      'dhcpFailure': 'Fehler',
    },
    connectionRequestedTitle: 'Verbindungsanfrage',
    connectionRequestedContent:
      'Bitte überprüfen Sie Ihre Verbindungsstatus unter der Option "Status". Wenn Sie sich von Deeper Connect abmelden, versuchen Sie es erneut, DeeperWiFi mitzugeben.',
    gotIpFromDhcpLabel: 'IP-Adresse von DHCP bekommen',
  },
  // parentalControlData
  parentalControlData: {
    browserCacheWarning:
      'Wenn Sie die Elternkontrolle aktiviert haben und diese nicht reibungslos blockiert, können Sie die Browser-Cache-Daten löschen und den Filtergeräten neu starten.',
    customerRule:
      'Hier können Sie die meisten pornografischen und sozialen Websites für Ihre Kinder blockieren. Wenn Sie andere Websites blockieren möchten, können Sie die Funktion zur Blockierung benutzerdefinierter Domänen verwenden, um weitere Websites hinzuzufügen.',
    customerRuleClickTips: 'Bitte {0}, um zu den Seiten der benutzerdefinierten Domänenblockierung zu gelangen',
    toCustomerRuleButtonText: 'Klicken Sie hier',
    parentalControl: 'Elterliche Kontrolle',
    pornQuery: 'Blockieren von Erwachseneninhalten',
    socialQuery: 'Blockieren von sozialen Medien',
    legendBlocked: 'Blockiert',
    // social
    socialStateEnabled: 'Aktivieren',
    socialStateDisabled: 'Deaktiviert',
    socialState2Hours: 'Zwei Stunden lang behindert',
    socialState4Hours: 'Behindert für vier Stunden',
    socialState8Hours: 'Acht Stunden lang behindert',

    // porn
    pornStateEnabled: 'Aktivieren',
    pornStateDisabled: 'Deaktiviert',
    pornState2Hours: 'Zwei Stunden lang behindert',
    pornState4Hours: 'Behindert für vier Stunden',
    pornState8Hours: 'Acht Stunden lang behindert',
    setCategoryStatesErr: 'Fehler beim Aktivieren/Deaktivieren der Filterung',
  },
  reboot: {
    success: {
      saveConfig: 'Erfolgreich gespeichert',
    },
    failure: {
      getConfig: 'Fehler beim Abrufen der Auto-Neustart-Konfiguration',
      saveConfig: 'Fehler beim Speichern der Auto-Neustart-Konfiguration',
    },
    buttons: {
      reboot: 'Klicken Sie zum Neustarten',
      save: 'Speichern',
    },
    week: {
      0: 'Jeden Tag',
      1: 'Montag',
      2: 'Dienstag',
      3: 'Mittwoch',
      4: 'Donnerstag',
      5: 'Freitag',
      6: 'Samstag',
      7: 'Sonntag',
    },
    title: {
      manual: 'Jetzt neustarten',
      auto: 'Geplanter Neustart',
    },
    label: {
      period: 'Zeitraum',
      time: 'Zeit',
    },
  },
};
