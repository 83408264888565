import axios from 'axios';
import to from 'await-to-js';
import crypto from 'crypto';

import publicKey from '../keys/encryption-public.js';
import privateKey from '../keys/decryption-private';

export const setAuthToken = function (token) {
  if (token) {
    // apply authorization token to every request if logged in
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    // delete auth header
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const encryptWithPublicKey = function (string) {
  if (string) {
    const encrypted = crypto.publicEncrypt(publicKey, Buffer.from(string));
    return encrypted.toString('base64');
  }
};

export const decryptWithPriKey = async string => {
  const decrypted = crypto.privateDecrypt(privateKey, Buffer.from(string, 'base64'));
  return decrypted.toString('utf8');
};

export const decryptByChunk = async string => {
  const chunks = string.split(',');
  const decryptedChunks = [];

  for (const chunk of chunks) {
    const decryptedChunk = await decryptWithPriKey(chunk, privateKey);
    decryptedChunks.push(decryptedChunk);
  }

  return decryptedChunks.join('');
};

export const validateToken = async () => {
  await to(axios.post('/api/admin/validateToken'));
};

export const JWT_TOKEN_KEY = 'deeperDeviceLoginToken';
