import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import Login from './store_login.js';
import License from './store_license';
import DpnMode from './store_dpn.js';

const middleware = [thunk];

const initState = {
  Login: Login.initState,
  License: License.initState,
  DpnMode: DpnMode.initState,
};

const reducers = {
  Login: Login.reducer,
  License: License.reducer,
  DpnMode: DpnMode.reducer,
};

const store = createStore(
  combineReducers(reducers),
  initState,
  compose(
    applyMiddleware(...middleware),
    (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose
  )
);

export default store;
