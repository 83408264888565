export default {
  'internationalize': {
    'type': 'french',
    'common': {
      'tip_text': 'Conseil',
      'search': 'Rechercher',
      'add': 'AJOUTER',
      'delete': 'SUPPRIMER',
      'confirm': "d'accord",
      'cancel': 'Annuler',
      'warning': 'Avertissement',
      'utcDisclaimer': 'Les dates sont en UTC',
      'toOpen': 'Ouvrir',
    },
    certificateDialog: {
      title: 'Nouveau certificat racine CA',
      content: "Le certificat racine CA a été remplacé. Veuillez le télécharger à nouveau et l'installer.",
      dontShowAgain: 'Ne plus afficher',
    },
    'header': {
      'logout': 'Déconnexion',
      'changePassword': 'Changer le mot de passe',
      'userManual': "Manuel d'utilisateur",
      'defaultAvatar': 'Avatar par défaut',
      'english': 'Anglais',
      'german': 'Allemand',
      'korean': 'Coréen',
      'french': 'Français',
      'spain': 'Espagnol',
      'portugal': 'Portugais',
      'japanese': 'Japonais',
      'Tchinese': 'Chinois traditionnel',
      'russian': 'Russe',
      'hindi': 'Hindi',
      'simplechinese': 'Chinois simplifié',
    },
    'home': {
      'name': 'Tableau de bord',
      'children': {},
      'content': {
        'tipInput': 'Veuillez entrer ',
        'ok': 'ok',
        'cancel': 'annuler',
        'currentStatus': 'Statut actuel',
        'statusMap': {
          'Trial': 'Essai',
          'Valid': 'Valide',
          'Expired': 'Expiré',
        },
        'btnActive': 'Activer',
        'btnEdit': 'Modifier',
        'expireDate': "Date d'expiration",
        'licenseKey': 'Clé de licence',
      },
    },
    accessControl: {
      name: "Contrôle d'accès",
    },
    'tpProxy': {
      'name': 'Proxy Transparent',
      'children': {
        'customRules': {
          'name': 'Règles Personnalisées',
        },
        'builtinRules': {
          'name': 'Filtre de Publicités',
        },
        'certificate': {
          'name': 'Installer le Certificat',
        },
      },
    },
    'setting': {
      'name': 'Paramètre',
      'children': {
        'wifi': {
          'name': 'Wi-Fi',
        },
        'sharing': {
          'name': 'Partage',
          'children': {
            'configure': {
              'name': 'Config de Partage',
            },
            'blacklist': {
              'name': 'Partage de la liste noire',
            },
            'record': {
              'name': 'Journal de Partage',
            },
            'traffic': {
              'name': 'Trafic de Partage',
            },
          },
          'content': {},
        },
        'rustdesk': {
          'name': 'RustDesk',
          'children': {
            'config': {
              'name': 'Config',
            },
            'log': {
              'name': 'Journal',
            },
          },
        },
        'admin': {
          'name': 'Administration',
          'content': {},
          'children': {
            'changePassword': {
              'name': 'Mot de passe',
            },
            'reboot': {
              'name': 'Redémarrage',
              'content': {
                'confirmText': 'Confirmer le redémarrage ?',
                'rebootLoading':
                  'Votre appareil aura besoin de quelques minutes pour redémarrer, pendant lesquelles votre connexion réseau sera temporairement interrompue.',
              },
            },
          },
        },
      },
    },
    'help': {
      'name': 'Aide',
      'children': {
        'techSupport': {
          'name': 'Support Technique',
        },
      },
    },
    'device': {
      'name': 'Appareil',
      'children': {
        'system': {
          'name': 'Système',
        },
        'traffic': {
          'name': 'Trafic',
        },
        'notification': {
          'name': 'Journal',
        },
      },
      'content': {},
    },
    'dpn': {
      'name': 'DPN',
      'content': {},
      'children': {
        'smartRoute': {
          'name': 'Mode',
        },
        'tunnel': {
          'name': 'Tunnel',
        },
        'routingTable': {
          'name': 'Routage Intelligent',
          'content': {},
          'children': {
            'domainConfig': {
              'name': 'Config de Domaine',
            },
            'ipConfig': {
              'name': 'Config IP',
            },
            'queryRouting': {
              'name': 'Requête',
            },
          },
        },
      },
    },
    'appRelocator': {
      'name': "Déplaceur d'App",
    },
    'adsBlock': {
      'name': 'Bloqueur de Pub',
      'children': {
        'domainFilter': {
          'name': 'Filtre DNS',
        },
        'httpsFilter': {
          'name': 'Filtre HTTPS',
        },
      },
      httpssucceed: 'Le filtrage HTTPS a été activé avec succès',
      httpslose: 'Le filtrage HTTPS a été désactivé avec succès',
      saverror: "L'opération a échoué",
      usedByAccessControl: "Le filtre HTTPS est utilisé par le contrôle d'accès",
    },
    'wallets': {
      'name': 'Portefeuilles',
      'children': {
        'wallets': 'Portefeuilles',
        'keys': 'Clés',
        'funds': 'Fonds',
      },
      'content': {},
    },
    'dpr': {
      'name': 'Minage',
      'children': {
        'dpr': {
          'name': 'DPR',
          'children': {
            'stakingTutorial': {
              'name': 'Tutoriel de Staking',
            },
            'wallet': {
              'name': 'Portefeuille',
              'manager': 'Gestionnaire de Portefeuille',
              'balance': 'Solde du Portefeuille',
            },
            'Staking Mining': 'Minage de Staking',
            'transaction': {
              'id': 'ID de transaction',
              'from': 'de',
              'to': 'à',
              'time': 'heure (UTC)',
              'rewardTime': 'heure de récompense (UTC)',
              'amount': 'montant de DPR',
              'Reward in selected period': 'Récompense dans la période sélectionnée',
              'Total reward': 'Récompense totale',
              'Balance Transfer': 'Transfert de Solde',
              'Staking Reward': 'Récompense de Staking',
              'PoS Reward': 'Récompense PoS',
              'Micro Payment': 'Micropaiements',
              'stakeRelease': 'Libération de Stake',
              'Transaction': 'Transactions',
              'Mining': 'Minage',
              'Traffic Mining': 'Minage de Trafic',
              'Staking Mining': 'Minage de Staking',
            },
          },
        },
      },
    },
    'security': {
      'name': 'Sécurité',
      'children': {
        'applications': 'Contrôle des Apps',
        'traffic': 'Façonnage du Trafic',
        'webfilter': {
          'name': 'Filtre Web',
          'children': {
            'report': {
              'name': 'Tableau de bord',
            },
            'config': {
              'name': 'Règles DNS personnalisées',
            },
          },
        },
        'sslFilter': {
          'name': 'Filtre SSL',
          'children': {
            'customDNSrules': {
              'name': 'Règles DNS personnalisées',
            },
            'customHTTPSRules': {
              'name': 'Règles HTTPS personnalisées',
            },
          },
        },
      },
      'content': {},
    },
    'parentalControl': {
      'name': 'Contrôle Parental',
    },
    'regionCountryTexts': {
      'LL': 'Accès Direct',
      'ANY': "N'importe quel pays",
      // regions
      'AMER': '---Amérique---',
      'ASIA': '---Asie---',
      'AFRI': '---Afrique---',
      'EURO': '---Europe---',
      'OCEA': '---Océanie---',
      'AMN': 'Amérique du Nord',
      'AMC': 'les Caraïbes',
      'AMM': 'Amérique Centrale',
      'AMS': 'Amérique du Sud',
      'ASC': 'Asie Centrale',
      'ASE': "Asie de l'Est",
      'ASW': "Asie de l'Ouest",
      'ASS': 'Asie du Sud',
      'ASD': 'Asie du Sud-Est',
      'AFN': 'Afrique du Nord',
      'AFM': 'Afrique Centrale',
      'AFE': "Afrique de l'Est",
      'AFW': "Afrique de l'Ouest",
      'AFS': 'Afrique du Sud',
      'EUN': 'Europe du Nord',
      'EUE': "Europe de l'Est",
      'EUW': "Europe de l'Ouest",
      'EUS': 'Europe du Sud',
      'OCP': 'Polynésie',
      'OCA': 'Australie et Nouvelle-Zélande',
      'OCM': 'Mélanésie',
      'OCN': 'Micronésie',
      // countries
      'AD': 'Andorre',
      'AE': 'Émirats Arabes Unis',
      'AF': 'Afghanistan',
      'AG': 'Antigua-et-Barbuda',
      'AI': 'Anguilla',
      'AL': 'Albanie',
      'AM': 'Arménie',
      'AO': 'Angola',
      'AR': 'Argentine',
      'AS': 'Samoa Américaines',
      'AT': 'Autriche',
      'AU': 'Australie',
      'AW': 'Aruba',
      'AX': 'Îles Åland',
      'AZ': 'Azerbaïdjan',
      'BA': 'Bosnie-Herzégovine',
      'BB': 'Barbade',
      'BD': 'Bangladesh',
      'BE': 'Belgique',
      'BF': 'Burkina Faso',
      'BG': 'Bulgarie',
      'BH': 'Bahreïn',
      'BI': 'Burundi',
      'BJ': 'Bénin',
      'BL': 'Saint-Barthélemy',
      'BM': 'Bermudes',
      'BN': 'Brunei Darussalam',
      'BO': 'Bolivie',
      'BQ': 'Bonaire, Saint-Eustache et Saba',
      'BR': 'Brésil',
      'BS': 'Bahamas',
      'BT': 'Bhoutan',
      'BW': 'Botswana',
      'BY': 'Biélorussie',
      'BZ': 'Belize',
      'CA': 'Canada',
      'CC': 'Îles Cocos (Keeling)',
      'CD': 'République Démocratique du Congo',
      'CF': 'République Centrafricaine',
      'CG': 'Congo',
      'CH': 'Suisse',
      'CI': "Côte d'Ivoire",
      'CK': 'Îles Cook',
      'CL': 'Chili',
      'CM': 'Cameroun',
      'CN': 'Chine',
      'CO': 'Colombie',
      'CR': 'Costa Rica',
      'CU': 'Cuba',
      'CV': 'Cap-Vert',
      'CW': 'Curaçao',
      'CX': 'Île Christmas',
      'CY': 'Chypre',
      'CZ': 'Tchéquie',
      'DE': 'Allemagne',
      'DJ': 'Djibouti',
      'DK': 'Danemark',
      'DM': 'Dominique',
      'DO': 'République Dominicaine',
      'DZ': 'Algérie',
      'EC': 'Équateur',
      'EE': 'Estonie',
      'EG': 'Égypte',
      'ER': 'Érythrée',
      'ES': 'Espagne',
      'ET': 'Éthiopie',
      'FI': 'Finlande',
      'FJ': 'Fidji',
      'FK': 'Îles Falkland (Malouines)',
      'FM': 'Micronésie (États fédérés de)',
      'FO': 'Îles Féroé',
      'FR': 'France',
      'GA': 'Gabon',
      'GB': "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
      'GD': 'Grenade',
      'GE': 'Géorgie',
      'GF': 'Guyane Française',
      'GG': 'Guernesey',
      'GH': 'Ghana',
      'GI': 'Gibraltar',
      'GL': 'Groenland',
      'GM': 'Gambie',
      'GN': 'Guinée',
      'GP': 'Guadeloupe',
      'GQ': 'Guinée Équatoriale',
      'GR': 'Grèce',
      'GS': 'Géorgie du Sud et les Îles Sandwich du Sud',
      'GT': 'Guatemala',
      'GU': 'Guam',
      'GW': 'Guinée-Bissau',
      'GY': 'Guyana',
      'HK': 'Hong Kong (Chine)',
      'HN': 'Honduras',
      'HR': 'Croatie',
      'HT': 'Haïti',
      'HU': 'Hongrie',
      'ID': 'Indonésie',
      'IE': 'Irlande',
      'IL': 'Israël',
      'IM': 'Île de Man',
      'IN': 'Inde',
      'IO': "Territoire Britannique de l'Océan Indien",
      'IQ': 'Irak',
      'IR': 'Iran',
      'IS': 'Islande',
      'IT': 'Italie',
      'JE': 'Jersey',
      'JM': 'Jamaïque',
      'JO': 'Jordanie',
      'JP': 'Japon',
      'KE': 'Kenya',
      'KG': 'Kirghizistan',
      'KH': 'Cambodge',
      'KI': 'Kiribati',
      'KM': 'Comores',
      'KN': 'Saint-Kitts-et-Nevis',
      'KR': 'Corée du Sud',
      'KW': 'Koweït',
      'KY': 'Îles Caïmans',
      'KZ': 'Kazakhstan',
      'KP': 'Corée du Nord',
      'LA': 'République Démocratique Populaire Lao',
      'LB': 'Liban',
      'LC': 'Sainte-Lucie',
      'LI': 'Liechtenstein',
      'LK': 'Sri Lanka',
      'LR': 'Libéria',
      'LS': 'Lesotho',
      'LT': 'Lituanie',
      'LU': 'Luxembourg',
      'LV': 'Lettonie',
      'LY': 'Libye',
      'MA': 'Maroc',
      'MC': 'Monaco',
      'MD': 'Moldavie',
      'ME': 'Monténégro',
      'MF': 'Saint-Martin (partie française)',
      'MG': 'Madagascar',
      'MH': 'Îles Marshall',
      'MK': 'Macédoine du Nord',
      'ML': 'Mali',
      'MM': 'Myanmar',
      'MN': 'Mongolie',
      'MO': 'Macao (Chine)',
      'MP': 'Îles Mariannes du Nord',
      'MQ': 'Martinique',
      'MR': 'Mauritanie',
      'MS': 'Montserrat',
      'MT': 'Malte',
      'MU': 'Maurice',
      'MV': 'Maldives',
      'MW': 'Malawi',
      'MX': 'Mexique',
      'MY': 'Malaisie',
      'MZ': 'Mozambique',
      'NA': 'Namibie',
      'NC': 'Nouvelle-Calédonie',
      'NE': 'Niger',
      'NF': 'Île Norfolk',
      'NG': 'Nigeria',
      'NI': 'Nicaragua',
      'NL': 'Pays-Bas',
      'NO': 'Norvège',
      'NP': 'Népal',
      'NR': 'Nauru',
      'NU': 'Niue',
      'NZ': 'Nouvelle-Zélande',
      'OM': 'Oman',
      'PA': 'Panama',
      'PE': 'Pérou',
      'PF': 'Polynésie Française',
      'PG': 'Papouasie-Nouvelle-Guinée',
      'PH': 'Philippines',
      'PK': 'Pakistan',
      'PL': 'Pologne',
      'PM': 'Saint-Pierre-et-Miquelon',
      'PN': 'Pitcairn',
      'PR': 'Puerto Rico',
      'PS': 'État de Palestine',
      'PT': 'Portugal',
      'PW': 'Palaos',
      'PY': 'Paraguay',
      'QA': 'Qatar',
      'RE': 'Réunion',
      'RO': 'Roumanie',
      'RS': 'Serbie',
      'RU': 'Fédération de Russie',
      'RW': 'Rwanda',
      'SA': 'Arabie Saoudite',
      'SB': 'Îles Salomon',
      'SC': 'Seychelles',
      'SD': 'Soudan',
      'SE': 'Suède',
      'SG': 'Singapour',
      'SH': 'Sainte-Hélène, Ascension et Tristan da Cunha',
      'SI': 'Slovénie',
      'SJ': 'Svalbard et Jan Mayen',
      'SK': 'Slovaquie',
      'SL': 'Sierra Leone',
      'SM': 'Saint-Marin',
      'SN': 'Sénégal',
      'SO': 'Somalie',
      'SR': 'Suriname',
      'SS': 'Soudan du Sud',
      'ST': 'Sao Tomé-et-Principe',
      'SV': 'El Salvador',
      'SX': 'Saint-Martin (partie néerlandaise)',
      'SY': 'République Arabe Syrienne',
      'SZ': 'Eswatini',
      'TC': 'Îles Turques-et-Caïques',
      'TD': 'Tchad',
      'TF': 'Territoires Austraux Français',
      'TG': 'Togo',
      'TH': 'Thaïlande',
      'TJ': 'Tadjikistan',
      'TK': 'Togo',
      'TL': 'Timor-Leste',
      'TM': 'Turkménistan',
      'TN': 'Tunisie',
      'TO': 'Tonga',
      'TR': 'Turquie',
      'TT': 'Trinité-et-Tobago',
      'TV': 'Tuvalu',
      'TW': 'Taïwan (Chine)',
      'TZ': 'Tanzanie, République-Unie de',
      'UA': 'Ukraine',
      'UB': 'Ouest des États-Unis',
      'UC': 'Midwest des États-Unis',
      'UD': 'Sud-ouest des États-Unis',
      'UE': 'Nord-est des États-Unis',
      'UF': 'Sud-est des États-Unis',
      'UG': 'Ouganda',
      'US': "États-Unis d'Amérique",
      'UY': 'Uruguay',
      'UZ': 'Ouzbékistan',
      'VA': 'Saint-Siège',
      'VC': 'Saint-Vincent-et-les-Grenadines',
      'VE': 'Venezuela',
      'VG': 'Îles Vierges (britanniques)',
      'VI': 'Îles Vierges (américaines)',
      'VN': 'Vietnam',
      'VU': 'Vanuatu',
      'WF': 'Wallis et Futuna',
      'WS': 'Samoa',
      'XK': 'Kosovo',
      'YE': 'Yémen',
      'YT': 'Mayotte',
      'ZA': 'Afrique du Sud',
      'ZM': 'Zambie',
      'ZW': 'Zimbabwe',
    },
  },
  'type': 'french',
  'request_tip': {
    'error': 'Erreur de requête~',
  },
  // dashboard
  'dashboard': {
    'name': 'Tableau de bord',
    'children': {},
    'content': {
      'backupTunnelLabel': 'Tunnel de Sauvegarde',
      'tipInput': 'Veuillez entrer ',
      'hotspot': 'Connexion Mondiale',
      'realnetworkflow': 'Statistiques de trafic en temps réel',
      'memory': 'Mémoire',
      'cpu': 'CPU',
      'notification': 'Notifications',
      'systeminfo': 'Infos Système',
      'upload': 'Téléversement',
      'download': 'Téléchargement',
      'systemuptime': 'Temps de fonctionnement du système',
      'uploadtotal': 'Total des Téléversements',
      'downloadtotal': 'Total des Téléchargements',
      'softwareVersion': 'Version du Logiciel',
      'publicIp': 'IP Publique',
      'unit': 'Unité',
      'currentDpnMode': 'Mode DPN actuel',
      'ok': 'ok',
      'cancel': 'annuler',
      'currentStatus': 'Statut actuel',
      'statusMap': {
        'Trial': 'Essai',
        'Valid': 'Valide',
        'Expired': 'Expiré',
      },
      'btnActive': 'Activer',
      'btnEdit': 'Modifier',
      'expireDate': "Date d'expiration",
      'licenseKey': 'Clé de licence',
      'systemTraffic': 'Statistiques de trafic système en temps réel',
      'appTraffic': "Statistique de trafic d'application en temps réel",
    },
  },
  accessControl: {
    'content': {
      banner: {
        title: "Contrôle d'accès",
        description:
          "Ce commutateur est utilisé pour contrôler uniformément l'activation ou la désactivation de diverses fonctions du périphérique LAN.",
      },
      'onlineDevice': 'Appareil en ligne',
      'offlineDevice': 'Appareil hors ligne',
      'deviceName': "Nom de l'appareil",
      unknownDevice: 'Inconnu',
      'macAddress': 'Adresse MAC',
      'ipAddress': 'Adresse IP',
      'enableHttpsFilter': 'Activer le filtre HTTPS',
      'routeMode': 'Mode DPN',
      'routeModeOptions': {
        'smart': 'Mode de routage intelligent',
        'full': 'Routage complet',
        'disabled': 'Désactivé',
        blackHole: 'Trou noir',
      },
      'remark': 'Remarque',
      pinned: 'Épingler en haut',
      'httpsFilterTip': 'Une fois le filtre HTTPS activé, toutes les publicités seront filtrées.',
      'routeModeTip': 'Cette option permet de changer le mode de fonctionnement du DPN.',
      'tunnelTip': 'Sélectionnez un tunnel de routage complet.',
      'success': {
        getList: 'La liste des appareils a été actualisée avec succès',
        'httpsFilter': 'Filtre HTTPS activé correctement',
        'routeMode': 'Mode DPN changé correctement',
        'regionCode': 'Mode DPN changé correctement',
        'remark': 'Remarque enregistrée correctement',
        pinned: 'Épinglé / Désépinglé avec succès. Veuillez rafraîchir',
        'deleteDevices': "Suppression réussie de l'appareil",
      },
      'failure': {
        'getSwitch': "Échec de l'obtention du commutateur de contrôle d'accès",
        'setSwitch': "Échec de la configuration du commutateur de contrôle d'accès",
        'getList': "Échec de la récupération de la liste de contrôle d'accès",
        'getTunnels': 'Échec de la récupération des tunnels',
        'setItem': "Échec de l'affectation de l'élément de contrôle d'accès",
        'httpsFilter': "Échec de l'activation du filtre HTTPS",
        'routeMode': 'Échec du changement du mode DPN',
        'regionCode': 'Échec du changement du mode DPN',
        'remark': "Échec de l'enregistrement de la remarque",
        'noSuchTunnel': "Le tunnel n'existe pas, veuillez en choisir un autre",
        pinned: "Échec de l'épinglage / du désépinglage",
        'deleteDevices': "Échec de la suppression de l'appareil",
        'macActive': "Impossible de supprimer car l'appareil est actif",
      },
      'remarkLengthLimit': 'La longueur ne doit pas dépasser 10 caractères',
      'httpsNotEnabled':
        "L'interrupteur principal du filtrage HTTPS est désactivé. Cliquez ici pour accéder à cette page et l'activer !",
      'tips': {
        'opened':
          "Lorsque le contrôle d'accès est activé, les appareils en ligne utilisent préférentiellement le mode DPN dans le contrôle d'accès.",
      },
    },
  },
  //dpnMode
  'dpnMode': {
    'title': 'Sélectionnez le mode DPN',
    'selectTunnel': 'Sélectionnez le tunnel',
    'recommended': 'Recommandé',
    'apply': 'Appliquer',
    'missingInfo': 'Informations manquantes',
    'selectionTip': {
      'smart': 'Voulez-vous passer en mode route intelligente?',
      'full': 'Tout le trafic passera par le tunnel sélectionné. Voulez-vous passer en mode Route Complète?',
      'disabled': 'Aucun trafic ne passera par DPN. Voulez-vous passer en mode DPN désactivé?',
    },
    'dpnModeShortList': {
      'smart': 'Route intelligente DPN',
      'disabled': 'DPN désactivé',
    },
    'dpnModeLongList': {
      'smart': 'Route Intelligente',
      'full': 'Route Complète',
      'disabled': 'Désactivé',
    },
    'killSwitchLabel': "Bouton d'arrêt d'urgence",
    'none': 'Désactiver',
    'backupTunnelLabel': 'Tunnel de secours',
    'backupTunnelTip':
      "En cas de problème avec le tunnel configuré en mode de routage intelligent, le système basculera automatiquement sur le tunnel de secours jusqu'à ce que le tunnel par défaut reprenne un fonctionnement normal.",
    'tips': {
      'killSwitch':
        "Un kill switch VPN est une fonctionnalité qui déconnecte automatiquement votre ordinateur, téléphone ou tablette d'Internet si votre connexion VPN est interrompue. Cela signifie qu'il n'y a aucune chance que votre adresse IP et d'autres informations sensibles soient exposées si votre VPN cesse de fonctionner. Ainsi, la sécurité et l'anonymat de votre connexion Internet ne seront pas compromis.",
      'smart': [
        "1. Selon les règles intégrées dans AtomOS, des nœuds de correspondance intelligente sont attribués aux sites Web actuellement consultés, assurant la vitesse du réseau local tout en accélérant l'accès aux services dans d'autres pays et régions.",
        "2. En mode 'Route intelligente DPN', cela élimine non seulement la nécessité de changer constamment de pays comme les VPN traditionnels, mais cela fournit également aux utilisateurs une expérience de navigation réseau plus rapide.",
        "3. Deeper Connect a des règles de routage intégrées pour certains sites Web et services bien connus. Si vous souhaitez personnaliser les règles pour le routage intelligent, vous pouvez les configurer dans 'Déplaceur d'App' et 'DPN - Routage'.",
        "4. Nous continuerons à améliorer nos règles de routage intégrées en fonction des retours des utilisateurs et à étendre le support pour plus d'applications dans la fonctionnalité 'Déplaceur d'App'.",
      ],
      'full': [
        "1. Toutes les données réseau traitées par AtomOS seront acheminées via un tunnel spécifié, similaire au schéma d'utilisation des VPN traditionnels.",
        "2. Remarque : Adopter le mode 'Route Complète DPN' remplacera les configurations que vous avez effectuées dans 'Déplaceur d'App' et 'DPN - Routage'.",
        "3. En raison de la nature décentralisée du réseau, les nœuds du réseau proviennent d'appareils utilisateurs du monde entier, plutôt que de serveurs à haute vitesse. Par conséquent, votre vitesse Internet peut être affectée.",
        "4. Si vous insistez pour utiliser le mode 'Route Complète DPN' et désirez une expérience Internet à haute vitesse, nous envisagerons de fournir des nœuds à haute vitesse spécifiques pour les utilisateurs VIP à l'avenir.",
      ],
      'disabled': [
        "1. Le mode 'DPN désactivé' éteindra la fonctionnalité DPN, faisant en sorte que toutes les données réseau traitées par AtomOS utilisent le trafic local.",
        '2. Les autres fonctionnalités de sécurité réseau de Deeper Connect resteront activées.',
        "3. Si vous rencontrez des obstacles lors de l'accès à certains sites Web, vous pouvez désactiver DPN pour déterminer si le problème vient du site Web ou de la fonctionnalité DPN.",
        "4. Si vous rencontrez des problèmes avec la fonctionnalité DPN, n'hésitez pas à les signaler en utilisant le lien suivant : https://support.deeper.network/hc/en-us/requests/new",
      ],
    },
    'success': 'Opération réussie',
    'failure': "Échec de l'opération !",
  },
  // DefaultadBlock
  'DefaultadBlock': {
    'browserCacheWarning':
      "Si les publicités ne peuvent pas être correctement filtrées lorsque le filtrage DNS est activé, vous devez vider le cache du navigateur et redémarrer l'appareil filtré.",
    'queriesFor24Hours': 'Requêtes des dernières 24 heures',
    'totalQuery': 'Total des Requêtes',
    'blockedQuery': 'Requêtes Bloquées',
    'blockedPercentage': 'Pourcentage de blocage',
    'ruleTotal': 'Règles',
    'adQuery': 'Publicités Bloquées',
    'trackerQuery': 'Traceurs Bloqués',
    'maliciousQuery': 'Malveillants Bloqués',
    'pornQuery': 'Pornographie Bloquée',
    'legendTotal': 'Total',
    'legendBlocked': 'Bloqué',
    'categoryStateEnabled': 'activé',
    'categoryStateDisabled': 'désactivé',
    'categoryState2Hours': 'désactivé pour 2 heures',
    'categoryState4Hours': 'désactivé pour 4 heures',
    'categoryState8Hours': 'désactivé pour 8 heures',
    'setCategoryStatesErr': "Erreur lors de l'activation/désactivation du filtre",
    'enableAll': 'Activer tous les filtres',
    'disableAll': 'Désactiver tous les filtres',
  },
  // CustomadBlock
  'CustomadBlock': {
    'missingInfoWarning': 'Domaine manquant',
    'invalidDomainError': 'Format de domaine invalide ou domaine trop long',
    'whitelist': 'Liste blanche',
    'blacklist': 'Liste noire',
    'domain': 'Domaine',
    'whitelistAddSuccess': 'Ajouté à la liste blanche avec succès',
    'whitelistEntryExist': 'Entrée déjà dans la liste blanche',
    'reachedWhiteMax': 'Nombre maximum atteint pour la liste blanche',
    'whitelistAddError': "Échec de l'ajout à la liste blanche",
    'blacklistAddSuccess': 'Ajouté à la liste noire avec succès',
    'blacklistEntryExist': 'Entrée déjà dans la liste noire',
    'reachedBlackMax': 'Nombre maximum atteint pour la liste noire',
    'blacklistAddError': "Échec de l'ajout à la liste noire",
    'whitelistDelSuccess': 'Entrées de la liste blanche supprimées avec succès',
    'whitelistDelError': 'Échec de la suppression des entrées de la liste blanche',
    'blacklistDelSuccess': 'Entrées de la liste noire supprimées avec succès',
    'blacklistDelError': 'Échec de la suppression des entrées de la liste noire',
    'import': 'Importer',
    'fileButtonText': 'Cliquez pour Télécharger',
    'fileSizeOverLimit': 'Le fichier téléchargé dépasse la limite de 2MB',
    'whitelistImportSuccess': 'Liste blanche importée avec succès, {0} entrées importées',
    'whitelistImportError': "Échec de l'importation de la liste blanche",
    'blacklistImportSuccess': 'Liste noire importée avec succès, {0} entrées importées',
    'blacklistImportError': "Échec de l'importation de la liste noire",
    'noValidEntry': 'Aucune entrée de domaine valide dans le fichier téléchargé',
    'zeroImported': "Échec de l'importation de toute entrée de domaine",
  },
  // DohGuideComp
  'DohGuideComp': {
    'closeButton': 'Fermer',
    'guideButton': 'Voir',
    'guideBoxTitle': 'Paramètre du navigateur DoH',
    'dohMessageClickTips':
      'Cliquez sur "Voir" pour voir les instructions sur comment désactiver DoH pour votre navigateur spécifique.',
    'dohMessageTxt':
      'Pour utiliser cette fonctionnalité, {0}. DoH chiffre les requêtes DNS et peut causer des interférences avec certaines fonctionnalités.',
    'dohMessage0': 'veuillez désactiver le paramètre DoH dans votre navigateur',
    'guide': {
      'chromeGuideImg': '/dohGuide/chrome-guide-fr.png',
      'chrome': [
        {
          'label': 'Étape 1',
          'tip': "Tapez chrome://settings/security dans la barre d'adresse et appuyez sur Entrée.",
        },
        { 'label': 'Étape 2', 'tip': 'Localisez la section "Paramètres de sécurité avancés".' },
        { 'label': 'Étape 3', 'tip': 'Désactivez l\'option "Utiliser un DNS sécurisé".' },
      ],
      'firefox': [
        {
          'label': 'Étape 1',
          'tip': "Tapez about:config dans la barre d'adresse et appuyez sur Entrée.",
        },
        {
          'label': 'Étape 2',
          'tip': 'Si une page d\'avertissement apparaît, cliquez sur "Accepter le Risque et Continuer".',
        },
        { 'label': 'Étape 3', 'tip': 'Tapez network.trr.mode dans la boîte de recherche et appuyez sur Entrée.' },
        {
          'label': 'Étape 4',
          'tip':
            'Double-cliquez sur le résultat de la recherche, changez la valeur à 5 (désactiver DoH), puis cliquez sur "OK".',
        },
      ],
      'edge': [
        {
          'label': 'Étape 1',
          'tip':
            'Tapez edge://settings/privacy dans la barre d\'adresse et appuyez sur Entrée. Puis trouvez la section "Sécurité".',
        },
        {
          'label': 'Étape 2',
          'tip':
            'Éteignez l\'option "Utiliser un DNS sécurisé pour se protéger contre les activités malveillantes et la tromperie".',
        },
      ],
      'operaGuideImg': '/dohGuide/opera-guide-fr.png',
      'opera': [
        {
          'label': 'Étape 1',
          'tip': "Entrez opera://settings/?search=DNS dans la barre d'adresse du navigateur et appuyez sur Entrée.",
        },
        {
          'label': 'Étape 2',
          'tip':
            'Trouvez l\'option "Utiliser le DNS-over-HTTPS au lieu des paramètres DNS du système" et assurez-vous qu\'elle est éteinte.',
        },
      ],
    },
  },
  // appRelocatorData
  'appRelocatorData': {
    'guide': {
      'youtube': {
        'UA': 'https://www.deeper.network/pages/app-relocator?app=youtube&lang=fr',
      },
      'nbaLeaguePass': 'https://www.deeper.network/pages/app-relocator?app=nbaLeaguePass&lang=fr',
      'netflix': 'https://www.deeper.network/pages/app-relocator?app=netflix&lang=fr',
      'dazn': 'https://www.deeper.network/pages/app-relocator?app=dazn&lang=fr',
      'fubo': 'https://www.deeper.network/pages/app-relocator?app=fubo&lang=fr',
      'tencentVideo': 'https://www.deeper.network/pages/app-relocator?app=tencentVideo&lang=fr',
    },
    'countryLabel': 'Pays',
    'tagLabel': 'Étiquette',
    'typeLabel': 'Type',
    'country': {
      'all-countries': 'Tout le Monde',
      'CN': 'CN',
      'US': 'US',
      'IN': 'IN',
      'CA': 'CA',
      'GB': 'GB',
      'NA': 'Autres',
      'NO': 'NO',
      'SG': 'SG',
      'JP': 'JP',
    },
    'tag': {
      'all-tags': 'Tous les Tags',
      'nba': 'NBA',
      'nfl': 'NFL',
      'nhl': 'NHL',
      'mlb': 'MLB',
      'epl': 'EPL',
      'ipl': 'IPL',
    },
    'category': {
      'all-category': 'Tous les Types',
      'video': 'Vidéo',
      'music': 'Musique',
      'im': 'Messagerie Instantanée',
      'flightAndHotel': 'Vol & Hôtel',
      'subscription': 'Abonnement',
    },
    'subcategory': {
      'popular': 'Populaire',
      'sport': 'Sport',
      'movie': 'Film',
      'tv': 'Télévision',
    },
    'filter': 'Filtrer',
    'reset': 'Réinitialiser',
    'submit': 'Soumettre',
    'app': {
      'acornTv': 'Acorn TV',
      'amazonPrimeVideo': 'Amazon Prime Vidéo',
      'beInSports': 'beIN Sports',
      'crunchyroll': 'Crunchyroll',
      'dazn': 'DAZN',
      'disneyPlus': 'Disney+',
      'netflix': 'Netflix',
      'plex': 'Plex',
      'roku': 'Roku',
      'shudder': 'Shudder',
      'zattoo': 'Zattoo',
      'discoveryPlus': 'Discovery Plus',
      'paramountPlus': 'Paramount +',
      'sportsnetNow': 'Sportsnet NOW',
      'tsn': 'TSN',
      'bbcIPlayer': 'BBC iPlayer',
      'hulu': 'Hulu',
      'itvx': 'ITVX',
      'slingTv': 'Sling',
      'crackle': 'Crackle',
      'pandora': 'Pandora',
      'theCw': 'The CW',
      'abc': 'ABC',
      'nbc': 'NBC',
      'pluto': 'Pluto TV',
      'starz': 'Starz',
      'tbs': 'TBS',
      'attTv': 'AT&T TV',
      'channel4': 'Channel 4',
      'showtimeAnytime': 'Showtime',
      'nbcSports': 'NBC Sports',
      'epixNow': 'Epix Now',
      'mgmPlus': 'MGM Plus',
      'xumo': 'Xumo',
      'channel5': 'Channel 5',
      'usaNetwork': 'USANetwork',
      'fxNow': 'FX Now',
      'vudu': 'Vudu',
      'fubo': 'Fubo TV',
      'espn': 'ESPN',
      'tubiTv': 'Tubi',
      'ukTvPlay': 'UKTV Play',
      'tvPlayer': 'TVPlayer',
      'peacock': 'Peacock',
      'pbs': 'PBS',
      'philo': 'Philo',
      'vrv': 'VRV',
      'showmax': 'Showmax',
      'hboMax': 'HBO Max',
      'nhl': 'NHL',
      'dsTv': 'DStv',
      'sSportsPlus': 'S Sports +',
      'filmrise': 'FilmRise',
      'cbsSports': 'CBS Sports',
      'nowTV': 'Now TV',
      'molotovTv': 'Molotov TV',
      'mlb': 'MLB',
      'britBox': 'BritBox',
      'stirr': 'Stirr',
      'skyGo': 'Sky Go',
      'hotstar': 'Disney+ Hotstar',
      'sonyLiv': 'SonyLIV',
      'nbaLeaguePass': 'NBA League Pass',
      'amcPlus': 'AMC+',
      'f1Tv': 'F1TV',
      'frndlyTv': 'Frndly TV',
      'myTf1': 'MYTF1',
      'qqMusic': 'QQ Music',
      'spotify': 'Spotify',
      'tidal': 'Tidal',
      'youtube': 'YouTube',
      'youTubeMusic': 'YouTube Music',
      'weChat': 'WeChat',
      'whatsApp': 'WhatsApp',
      'line': 'Line',
      'signal': 'Signal',
      'agoda': 'Agoda',
      'expedia': 'Expedia',
      'flyScanner': 'Fly Scanner',
      'googleFlights': 'Google Flights',
      'qqSports': 'QQ Sports',
      'skySports': 'Sky Sports',
      'rakutenTv': 'Rakuten TV',
      'fox': 'FOX',
      'nflNetwork': 'NFL Network',
      'peacocktv': 'Peacock TV',
      'zee5': 'Zee5',
      'mxPlayer': 'MX Player',
      'tencentVideo': 'Tencent Video',
      'iqiyi': 'IQIYI Inc - ADR',
      'mangoTV': 'Mango TV',
      'migu': 'Migu',
      'bilibili': 'BiliBili',
      'youku': 'YouKu',
      'altBalaji': 'ALT Balaji',
      'willowTv': 'Willow TV',
      'foxSports': 'Fox Sports',
      'crave': 'Crave',
      'cbcGem': 'CBC Gem',
      'jiocinema': 'JioCinema',
      'Telegram': 'Télégramme',
      'Messenger': 'Messager',
      'ChatGPT': 'ChatGPT',
      'CanalPlus': 'CanalPlus',
    },
    'tip': {
      'video': 'Tunnel populaire pour le service vidéo actuel',
      'music': 'Tunnel populaire pour le service de musique actuel',
      'im': 'Tunnel populaire pour la messagerie instantanée actuelle',
      'flightAndHotel': 'Tunnel populaire pour le service actuel de vol et hôtel',
      'subscription': "Tunnel populaire pour l'abonnement actuel",
      'backupTunnel':
        "En mode Routage Intelligent, si aucun nœud n'est disponible dans le tunnel actuel, le système basculera vers le tunnel de secours situé dans le {0}",
      'fullTunnel': 'En mode Routage Complet, toutes les applications utiliseront par défaut le tunnel {0}.',
      'noTunnelNodes':
        'Le nombre de nœuds est zéro, ne peut pas être utilisé, veuillez aller à la page du tunnel et rafraîchir les nœuds pour le tunnel {0}.',
      'noTunnelNodesOkText': 'Rafraîchir',
      'noTunnelNodesCancelText': 'Fermer',
      'noTunnelAdded':
        "Vous n'avez pas encore ajouté ce tunnel. Pour l'utiliser, veuillez aller à la page du tunnel et ajouter le tunnel {0}.",
      'noTunnelAddedOkText': 'Ajouter',
      'noTunnelAddedCancelText': 'Annuler',
      'tunnelDeletedText': 'Le tunnel {0} - {1} a été supprimé',
      'netflix': "Résolution des problèmes d'erreur de lecture rencontrés lors de l'utilisation de Netflix",
      'fubo': 'Fubo TV vous permet de regarder des flux en direct de la Premier League à très faible coût.',
      'tencentVideo': 'Regarder un match de la NBA coûte environ 1 $.',
      'dazn': 'DAZN vous permet de regarder NFL, NFL, MLB, NBA, EPL à très faible coût, seulement 17,18 $.',
      'youtube': 'Comment profiter de YouTube Premium à très faible coût.',
    },
    'intro': {
      'acornTv':
        "Acorn TV diffuse des mystères, des drames et des comédies de classe mondiale venant de Grande-Bretagne et d'ailleurs. Regardez en binge-watch une série classique ou découvrez votre nouvelle émission favorite parmi ...",
      'abc':
        "Regardez les émissions d'ABC en ligne sur abc.com. Obtenez des vidéos exclusives et des épisodes gratuits.",
      'f1Tv':
        'F1 TV est maintenant disponible sur le Web, les applications, Google TV, Apple TV, Roku et Amazon Fire TV. TOUT F1 EN DIRECT. TOUT F1 EN DIRECT. Suivez en direct toutes les séances sur la piste du GP ...',
      'youtube':
        'La chaîne officielle de YouTube vous aide à découvrir ce qui est nouveau et tendance dans le monde. Regardez des vidéos incontournables, de la musique à la culture en passant par les phénomènes Internet.',
      'netflix':
        "Netflix est un service de streaming qui propose une grande variété de séries TV primées, de films, d'animes, de documentaires et plus encore sur des milliers d'appareils connectés à Internet ...",
      'fubo':
        "Le moyen le plus simple de regarder Fubo sur votre téléviseur est avec un Roku, Apple TV, Amazon Fire TV ou Chromecast. Installez simplement l'application Fubo sur votre appareil pour diffuser sur ...",
      'disneyPlus':
        'Des nouvelles sorties, à vos classiques préférés, le passé, le présent et le futur sont à vous. Tout ce que vous attendiez, et tout ce que vous ne vous attendiez pas. © 2023 Disney. Marques : Marvel, Pixar, Disney, National Geographic, Star Wars.',
      'crunchyroll':
        "Explorez le meilleur de l'anime et du manga en streaming avec Crunchyroll. Profitez de titres à succès tels que Demon Slayer, One Piece, Attack on Titan et My Hero Academia ...",
      'hulu':
        "Regardez des émissions de télévision et des films en ligne. Diffusez des épisodes de Grey's Anatomy, This Is Us, Bob's Burgers, Brooklyn Nine-Nine, Empire, SNL et des films populaires sur ...",
      'nbaLeaguePass':
        'Un abonnement à NBA League Pass* donne aux fans un accès aux matchs, en direct et à la demande, et plus encore pour toute la saison et la hors-saison.',
      'qqSports':
        'Tencent Sports est un site web de portail sportif bien connu en Chine, fournissant principalement les sections suivantes : football national, football international, NBA, CBA…',
      'skySports':
        'Regardez la meilleure couverture en direct de vos sports préférés : Football, Golf, Rugby, ipl, F1, Boxe, NFL, NBA, plus les dernières nouvelles sportives, …',
      'rakutenTv':
        'TV EN DIRECT | Top 15 chaînes gratuites · GRATUIT | Meilleurs films gratuits · STORE | Films les plus chauds à acheter ou à louer · Genres · GRATUIT | Ajouts récents gratuits · GRATUIT | Rakuten',
      'espn':
        'Visitez ESPN pour les scores en direct, les moments forts et les actualités sportives. Diffusez des jeux exclusifs sur ESPN+ et jouez à des sports fantastiques.',
      'beInSports':
        'Découvrez le monde du sport avec beIN SPORTS USA. Obtenez des moments forts exclusifs, les dernières nouvelles, les statistiques, les scores et les calendriers pour les principales ligues de football et plus encore.',
      'dazn': 'DAZN est le premier service de streaming sportif en direct vraiment dédié ...',
      'cbsSports':
        "Réseau d'informations sportives gratuit 24 heures sur 24. Regardez CBS Sports HQ. Tout ce qui compte dans le jeu. Plus de moments forts, les meilleurs paris, et des conseils gagnants pour les jeux fantastiques.",
      'fox':
        'Regardez FOX Sports et consultez les scores en direct, les cotes, les nouvelles des équipes, les nouvelles des joueurs, les flux, les vidéos, les statistiques, les classements & les calendriers couvrant la NFL, la MLB, la NASCAR, la WWE, la NBA, la NHL, ...',
      'nflNetwork':
        "Obtenez des informations sur la chaîne NFL, des mises à jour des émissions et plus encore ! NFL Network est le seul réseau fonctionnant toute l'année possédé et exploité par la NFL.",
      'peacock':
        "Obtenez Peacock et diffusez des sports en direct, des Originaux exclusifs, des émissions à succès et des films à succès. Des grands succès, des classiques, de l'action EN DIRECT et bien plus encore, en streaming maintenant sur Peacock. Documentaires. Films & Émissions pour enfants. Chaînes Peacock 24/7. Hallmark Channel.",
      'nbcSports': 'Restez à jour avec les dernières actualités sportives et les scores de NBC Sports.',
      'sportsnetNow':
        "Meilleures actions de la MLB · Vidéo Sportsnet des Blue Jays de Toronto célébrant l'anniversaire de Springer de manière spectaculaire avec un coup de circuit oppo-taco en tête de lice · Vidéo Sportsnet d'Acuna Jr. des Braves.",
      'tsn':
        "Visitez TSN pour obtenir les dernières actualités sportives, les scores, les moments forts et les commentaires pour l'Open d'Australie, les playoffs de la NFL, le Super Bowl, la date limite des échanges de la NHL",
      'nhl':
        'Le site officiel de la Ligue nationale de hockey, incluant des nouvelles, des listes de joueurs, des statistiques, des calendriers, des équipes et des vidéos.',
      'hotstar':
        'Disney+ Hotstar est la plus grande plateforme de streaming premium en Inde avec plus de 100000 heures de drame et de films en 17 langues, et la couverture de chaque grand événement ...',
      'espn':
        'Visitez ESPN pour les scores en direct, les moments forts et les actualités sportives. Diffusez des jeux exclusifs sur ESPN+ et jouez à des sports fantastiques.',
      'sonyLiv':
        'Plateforme de streaming premium où vous pouvez regarder des émissions de télévision populaires, des films, des sports, des séries Web. Profitez de vos chaînes de télévision en direct préférées en ligne.',
      'jiocinema':
        "Regardez les derniers films, émissions de télévision, émissions Voot, sports en direct et moments forts en ligne en hindi, anglais et d'autres langues régionales dans divers genres, …",
      'amazonPrimeVideo':
        "Regardez des films, des émissions, des Amazon Originals primés, des chaînes premium, des sports et plus encore. Regardez des émissions de télévision captivantes, des films à succès, des émissions à succès et plus encore avec un essai gratuit. Contenu compatible 4K+HDR. Émissions et films à succès. Nouveautés. Regardez sur n'importe quel appareil.",
      'zee5':
        'Votre contenu Desi préféré sur ZEE5™ à un prix exclusif de 50 % de réduction. Achetez maintenant. Regardez votre contenu préféré sur ZEE5™. Obtenez 50 % de réduction sur le pack annuel. Achetez maintenant. Plus de 4000 films à succès. Plus de 1600 émissions. Plus de 260 originaux. Disponible en 18 langues.',
      'mxPlayer':
        'MX Player est le service OTT le plus premium en Inde où vous pouvez jouer les derniers clips musicaux, regarder de vieux et nouveaux films, émissions de télévision, émissions de télévision comiques, chansons tendance, ...',
      'tencentVideo':
        "Tencent Video est dédié à la création de la principale plateforme de médias vidéo en ligne en Chine. Avec son contenu riche, son expérience de visionnage ultime, ses méthodes de connexion pratiques, son expérience d'application multiplateforme sans interruption 24 heures sur 24 et ses fonctionnalités de partage rapide, il répond principalement aux besoins de streaming vidéo en ligne des utilisateurs.",
      'iqiyi':
        "iQIYI (iQIYI.COM) est un site web de streaming vidéo à grande échelle qui offre une vaste collection de vidéos en ligne de haute qualité et haute définition. C'est une plateforme professionnelle pour le streaming de divers types de contenus, y compris des films, des émissions de télévision, des animations, des spectacles de variétés, des programmes de style de vie et plus encore. iQIYI propose une large gamme d'options de divertissement pour répondre aux différentes préférences du public.",
      'youku':
        "Youku - Dédié à l'Excellence du Contenu - Regardez une Vaste Collection de Vidéos HD Sous Licence de Haute Qualité en Ligne.",
      'mangoTV':
        'Mango TV - Le Site de Vidéo en Ligne que Tout le Monde Regarde - Regardez des Émissions de Variétés Populaires, les Derniers Films et Dramas Télévisés en Ligne.',
      'migu':
        "Migu Video propose du streaming en direct des principaux événements sportifs nationaux et internationaux, y compris les Jeux Olympiques d'hiver de Pékin. Il offre des diffusions en direct de la CBA (Chinese Basketball Association), de la Ligue des champions de l'AFC, de la Super League chinoise, de La Liga, de la Bundesliga, de la Serie A, de l'UEFA Europa League, de l'UEFA Champions League, ainsi que des couvertures en direct de volley-ball féminin, de tennis de table, de patinage artistique, de snooker, de WWE, d'UFC, et plus encore.",
      'bilibili':
        "Bilibili (bilibili.com) est un site web vidéo danmaku renommé en Chine, offrant des sorties d'anime en temps opportun, une communauté ACG dynamique et du contenu créatif de créateurs de contenu talentueux. C'est un lieu où les utilisateurs peuvent trouver joie et divertissement.",
      'nbc':
        'Regardez des épisodes complets des émissions actuelles et classiques de NBC en ligne. Plus trouvez des clips, des aperçus, des photos et des fonctionnalités exclusives en ligne sur NBC.com.',
      'foxSports':
        'Regardez FOX Sports et consultez les scores en direct, les cotes, les nouvelles des équipes, les nouvelles des joueurs, les flux, les vidéos, les statistiques, les classements et les calendriers couvrant la NFL, la MLB, la NASCAR, la WWE, la NBA, la NHL...',
      'nfl':
        "Obtenez des informations sur la chaîne NFL, des mises à jour des émissions et plus encore ! NFL Network est le seul réseau fonctionnant toute l'année possédé et exploité par la NFL.",
      'willowTv':
        'Vous pouvez regarder le streaming en direct, les moments forts et les rediffusions sur Willow une fois que vous êtes connecté avec succès. Spectrum Optimum Fios Dish Optimum TV Xfinity. * Time Warner …',
      'mlb':
        'La couverture comprend des clips audio et vidéo, des interviews, des statistiques, des calendriers et des histoires exclusives.',
      'disneyPlus':
        'Des nouvelles sorties, à vos classiques préférés, le passé, le présent et le futur sont à vous. Tout ce que vous attendiez, et tout ce que vous ne vous attendiez pas. © 2023 Disney. Marques : Marvel, Pixar, Disney, National Geographic, Star Wars.',
      'hboMax':
        'HBO Max est une plateforme de streaming autonome qui regroupe tout HBO avec encore plus de favoris télévisés, des films à succès et de nouveaux Max Originals pour tout le monde …',
      'amcPlus':
        "Évadez-vous de l'ordinaire et découvrez plus d'expériences uniques et captivantes sur AMC+ aujourd'hui. Contenu exclusif, films captivants et séries intemporelles - trouvez tout cela sur AMC+ Epic Series. Dramas de qualité. À la demande. Contenu exclusif. Films exclusifs. Nouveaux épisodes. Sans publicités.",
      'attTv':
        'Profitez de nos meilleures offres pour la télévision et Internet, y compris des offres pour DIRECTV et Internet à domicile, y compris AT&T Fiber.',
      'britBox':
        'Binge mystery, comédie, drame, documentaires, style de vie et plus encore, à partir de la plus grande collection de streaming de télévision britannique jamais vue.',
      'crackle':
        "Sur Crackle, regardez des films hollywoodiens gratuitement, non coupés et non édités. De vos genres favoris comme Action, Horreur, Sci-Fi, Crime et Comédie. Il suffit d'appuyer sur Play.",
      'discoveryPlus':
        'Regardez sur votre appareil mobile, tablette, ordinateur, console de jeu et télévision connectée. · Il y en a pour tous les goûts ! · discovery+ est la maison de streaming de Food, Home, …',
      'mgmPlus':
        'Obtenez MGM+ et accédez à des milliers de films et séries télévisées, sans publicité. Annulez à tout moment.',
      'filmrise':
        "Nous embrassons la nature de l'industrie d'aujourd'hui, en constante évolution, en appliquant une stratégie à plusieurs niveaux pour offrir le meilleur du film et de la télévision.",
      'frndlyTv':
        "Regardez plus de 40 chaînes en direct, ainsi qu'à la demande, sur votre Roku, Amazon Fire TV ou en déplacement avec Frndly TV. Comprend toutes les 3 chaînes Hallmark, A&E, …",
      'fxNow': 'Regardez des émissions TV originales FX, des films et la TV en direct sur les réseaux FX, FXX et FXM.',
      'paramountPlus':
        "Regardez des milliers d'épisodes, des sports en direct et des originaux exclusifs, le tout en un seul endroit. Accédez à des centaines de films iconiques, des classiques primés aux nouveautés à succès. Trouvez de nouvelles séries à regarder en binge. Séries de réalité à succès. Émissions MTV à succès. Plus de 30 000 épisodes. Actualités de dernière heure. Émissions CBS à succès.",
      'pbs':
        'Les films POV sont connus pour leur narration inoubliable et leur actualité. Regardez maintenant! POV est la vitrine la plus ancienne de la télévision pour les documentaires indépendants. En savoir plus! Non-fiction indépendante. Plus de 500 films présentés. Histoires inoubliables.',
      'philo':
        'Pourquoi payer plus pour la télévision ? Philo offre la télévision en direct et du contenu à la demande pour seulement 25 $/mois. Regardez vos émissions, films et plus encore sur tous vos appareils.',
      'plex':
        "Plex est une application de streaming gratuite pour tout le monde, sans cartes de crédit, coûts d'abonnement ou frais cachés. Pour garder notre service gratuit, nous incluons des publicités qui monétisent tout...",
      'pluto':
        'Tout, des derniers films à succès aux saisons complètes de vos émissions préférées. Des centaines de chaînes. Zéro dollar. Tous les appareils. Commencez à regarder maintenant! Tous les appareils. Binge Watch. TV et films à la demande. Des centaines de chaînes. Regardez maintenant. Jamais payer.',
      'roku':
        'Les appareils Roku rendent le streaming TV facile. Des lecteurs, des téléviseurs intelligents, et même des produits pour la maison intelligente qui rendent votre maison sécurisée, trouvez le produit Roku parfait...',
      'showtimeAnytime':
        'Essayez SHOWTIME gratuitement et diffusez des séries originales, des films, des sports, des documentaires, et plus encore. De plus, commandez des combats à la carte - aucune abonnement nécessaire.',
      'shudder':
        'Avec des plans à partir de seulement 4,99 $/mois, obtenez tout Shudder plus des séries à succès de The Walking Dead Universe, Anne Rice et plus encore, le tout en un seul endroit. Essayez Shudder gratuitement...',
      'slingTv':
        'Sling TV est le premier service de télévision basé sur une application vous permettant de diffuser la télévision en direct et du contenu à la demande sur Internet. Regardez des émissions en direct où que vous soyez, à...',
      'starz':
        'STARZ donne vie à des perspectives diverses à travers une narration audacieuse. Inscrivez-vous pour diffuser des séries originales, des films, des extras, et plus encore, à la demande et sans publicités.',
      'stirr':
        '... & Divertissement. en direct et à la demande. STIRR City, National a été défini comme votre chaîne locale. Commencez à regarder. 00:05. Plus de 100 chaînes, plus de 5 000 heures de télévision et...',
      'tbs':
        "In the Heights · Cette semaine sur Dynamite · Aperçu des séries éliminatoires de la MLB sur tbs · S18 | E14 Multivers de la folie d'American Dad.",
      'theCw':
        'Maison officielle du réseau CW, avec All American, Superman & Lois, Walker, FBoy Island, séries de streaming premium, films, sports et plus encore.',
      'tubiTv':
        'Tubi est la principale application de streaming vidéo à la demande gratuite et premium. Nous avons la plus grande bibliothèque de contenu avec plus de 50 000 films et émissions de télévision, le meilleur...',
      'usaNetwork':
        'Regardez des épisodes complets des émissions actuelles et classiques de USA en ligne. Plus trouvez des clips, des aperçus, des photos et des fonctionnalités exclusives en ligne sur USANetwork.com.',
      'vudu':
        "Louez, achetez et regardez des films et des émissions de télévision avec Vudu. Regardez en ligne ou sur votre appareil connecté préféré avec l'application Vudu. Pas d'abonnement, inscription gratuite.",
      'xumo':
        "Xumo a été formé pour développer et proposer une plateforme de streaming de nouvelle génération pour toute l'industrie du divertissement. Découvrez notre mission et engagez-vous avec...",
      'jiocinema':
        'Regardez les derniers films, émissions de télévision, émissions Voot, sports en direct et moments forts en ligne en hindi, anglais et autres langues régionales à travers les genres, …',
      'altBalaji':
        'Regardez les dernières séries Web, Originaux et Films en HD en ligne. Choisissez parmi des genres comme Thriller, Action, Adulte, Comédie, Drame Familial et plus en plusieurs langues...',
      'crave':
        "Une collection soigneusement sélectionnée de classiques de films d'horreur, de séries télévisées terrifiantes et d'épisodes d'Halloween. ... La célébrité inattendue et les pressions sociétales dans le monde de la comédie.",
      'cbcGem':
        "Regardez des émissions populaires comme Schitt's Creek, Luther, Kim's Convenience, ainsi que des films et la télévision en direct sur n'importe quel appareil.",
      'bbcIPlayer':
        'Regardez en direct les chaînes de télévision de la BBC, profitez des programmes que vous avez manqués et découvrez des contenus exclusifs sur BBC iPlayer.',
      'channel4': 'Regardez vos émissions préférées en ligne, de Channel 4, E4 et Walter Presents.',
      'channel5':
        'Bienvenue sur My5, où vous pouvez rattraper et diffuser des séries de vos émissions préférées de Channel 5, 5STAR, 5USA, 5SELECT, 5ACTION et plus encore.',
      'dsTv':
        'Le meilleur du divertissement télévisé à portée de main. Regardez les derniers sports, films, séries et émissions de réalité et accédez au guide TV de DStv.',
      'molotovTv':
        "Molotov est gratuit. N'hésitez pas à ajouter des fonctionnalités supplémentaires. · Molotov symbolise l'avenir de la télévision · Le Spotify de la télévision · La télévision peut-elle être…",
      'myTf1':
        'Regardez en direct TF1, TMC, TFX, TF1 Séries Films et LCI, plus de 50 chaînes thématiques en exclu, et plus de 10 000 heures de vidéos, uniquement sur MYTF1 ...',
      'sSportsPlus':
        "De la Serie A à LaLiga, de la Formule 1 à la NBA, de l'EuroLeague à l'UFC, plus de 700 heures de diffusion en direct par mois et des centaines de contenus sportifs avec S Sport Plus à portée de main...",
      'showmax':
        'Combien coûte un abonnement Showmax ? Showmax propose une variété de plans pour répondre à différentes préférences et budgets, avec des prix à partir de seulement R39 par ...',
      'itv':
        'ITVX est le service de streaming le plus frais du Royaume-Uni, avec des émissions exclusives, des films à succès, des événements en direct et des milliers de coffrets, le tout en un seul endroit.',
      'pandora':
        "Écoutez les chansons, albums, playlists et podcasts que vous aimez sur le tout nouveau Pandora. Inscrivez-vous à un plan d'abonnement pour diffuser sans publicité et à la demande.",
      'qqMusic':
        'QQ Music est un produit de service de musique basé sur le web lancé par Tencent, offrant une large gamme de fonctionnalités, y compris le streaming de musique en ligne, des sorties exclusives de chansons nouvelles et populaires, la traduction de paroles, les téléchargements de sonneries mobiles, le streaming de musique sans perte de haute qualité, une vaste bibliothèque de musique sans perte, des téléchargements de musique légaux et un espace personnel...',
      'spotify': 'Spotify est un service de musique numérique qui vous donne accès à des millions de chansons.',
      'tidal':
        "TIDAL est une plateforme de streaming de musique axée sur l'artiste et le fan, qui offre plus de 100 millions de chansons en qualité sonore HiFi à la communauté musicale mondiale.",
      'weChat':
        'Disponible pour toutes sortes de plateformes ; profitez du chat de groupe ; supporte les messages vocaux, photos, vidéos et textes.',
      'whatsApp':
        'Utilisez WhatsApp Messenger pour rester en contact avec vos amis et votre famille. WhatsApp est gratuit et offre des messages et des appels simples, sécurisés et fiables, disponibles sur ...',
      'line':
        "Nouvelle application de communication qui vous permet de passer des appels vocaux GRATUITS et d'envoyer des messages GRATUITS où que vous soyez, 24 heures sur 24 !",
      'signal':
        'Partagez des textes, des messages vocaux, des photos, des vidéos, des GIFs et des fichiers gratuitement. Signal utilise la connexion de données de votre téléphone afin que vous puissiez éviter les frais de SMS et MMS.',
      'agoda':
        'Réservez maintenant et payez plus tard, annulation gratuite. Service client en direct 24h/24 et 7j/7 dans votre langue. Du budget au luxe, hôtels et maisons. Votre partenaire de voyage de confiance pour les chambres et les vols.',
      'expedia':
        'Votre site de voyage tout-en-un pour vos vacances de rêve. Combinez votre séjour avec une location de voiture ou un vol et économisez davantage. Recherchez nos options flexibles pour correspondre...',
      'flyScanner':
        "Obtenez des vols à prix réduit à partir de 19 $. Comparez et réservez en une recherche. C'est rapide et facile. Comparez les vols, hôtels et locations de voiture pas chers. Trouvez des milliers d'offres et obtenez la meilleure.",
      'nowTV':
        'Combien coûte une adhésion à NOW ? Une adhésion à NOW vous permet de regarder ce que vous voulez, selon vos conditions. Vous pouvez rejoindre avec Divertissement, Cinéma, Sports et Hayu.',
      'tvPlayer':
        'TVPlayer est le foyer des chaînes de télévision les plus populaires et des programmes à la demande de qualité premium, exclusifs à TVPlayer. Regardez plus de 40 chaînes en direct et des milliers...',
      'ukTvPlay':
        'UKTV Play est le foyer à la demande des chaînes Dave, Drama, W et Yesterday. Regardez la télévision en direct, diffusez des séries et rattrapez vos émissions préférées.',
      'zattoo':
        "Diffusez vos programmes de télévision préférés en direct et en différé sur tous les appareils. Meilleure qualité d'image en Full-HD. Mieux que la télévision par câble. Contenu à la demande + télévision en direct.",
      'skyGo':
        'Diffusez en direct, à la demande et rattrapez la télévision de votre package Sky sur votre téléphone, tablette, ordinateur portable ou ordinateur, ou appareil Apple TV, et basculez entre eux facilement.',
      'Telegram':
        'Telegram vise à fournir une expérience de communication sécurisée, rapide et concise, et est connu pour ses puissantes fonctionnalités de chiffrement et ses fonctions riches.',
      'Messenger':
        "Messenger est une application de messagerie instantanée fournie par Facebook, qui permet aux utilisateurs de communiquer avec d'autres par des messages texte, des appels vocaux, des appels vidéo, l'envoi d'images, d'emojis, etc. Messenger était initialement une fonctionnalité du site Web de Facebook, plus tard développé en une application mobile autonome, et fournit plus de fonctionnalités et de services.",
      'ChatGPT':
        "ChatGPT est un modèle de langage d'intelligence artificielle développé par OpenAI, basé sur l'architecture GPT (Generative Pre-trained Transformer). Il est formé à travers des données de traitement du langage naturel à grande échelle et peut comprendre et générer du texte en langage naturel.",
      'CanalPlus':
        "CANAL+ est une chaîne de télévision payante française, l'un des plus grands réseaux de télévision payante en France, et l'un des fournisseurs de services de télévision payante les plus importants en Europe. La chaîne propose une variété de programmes, y compris des films, du sport, des nouvelles, des documentaires, des drames télévisés, etc.",
    },
    'selectTunnel': 'Sélectionnez le tunnel',
    notSetLabel: 'Pas encore défini',
    'directAccessLabel': 'Accès Direct',
    'otherTunnelLabel': 'Autre Tunnel',
    'recommendedTunnelLabel': 'Tunnel Recommandé',
    'error': {
      'getApps': "Échec de l'obtention des applications",
      'getTunnels': "Échec de l'obtention de la liste des tunnels",
      'invalidApp': 'Application invalide',
      'tunnelNotExist': "Le tunnel n'existe pas",
      'set': 'Échec de la configuration du tunnel',
      'unset': 'Échec de la désactivation du tunnel',
    },
    'success': {
      'set': 'Tunnel configuré avec succès',
      'unset': 'Tunnel désactivé avec succès',
    },
    'searchInputPlaceholder': 'Recherchez les applications dont vous avez besoin !',
    'discountIntro': "percent => `Économisez ${percent} sur les frais d'abonnement mensuels`",
    'discountPercent': 'percent => `-${percent} ($)/mois`',
    'guideButtonLabel': 'En savoir plus',
  },
  // betanet
  'betanet': {
    'bindWalletSuccess': 'Portefeuille lié avec succès',
    'deviceBound': "L'appareil est actuellement lié à un autre portefeuille",
    'walletTaken': 'Le portefeuille est déjà pris par un autre appareil',
    'deviceTypeIncompatible': "L'appareil est incompatible avec le portefeuille",
    'invalidMnemonic': 'Mnémonique de portefeuille invalide',
    'invalidPrivateKey': 'Clé privée de portefeuille invalide',
    'bindWalletError': 'Échec de la liaison du portefeuille',
    'notBound': "Aucun portefeuille lié à l'utilisateur actuel",
    'unbindWalletSuccess': 'Portefeuille délié avec succès',
    'noActiveBinding': "L'appareil n'est pas lié au portefeuille spécifié",
    'unbindWalletError': 'Échec du déliage du portefeuille',
    'walletManagement': 'Gestion du portefeuille',
    'bindWallet': 'Lier le portefeuille',
    'downloadPrivateKey': 'Télécharger la clé privée',
    'downloadKeyError': 'Échec du téléchargement de la clé privée',
    'unbindBtnText': 'Délier le portefeuille',
    'unbindWarning':
      'Assurez-vous d\'avoir téléchargé la clé privée. Veuillez taper "J\'ai téléchargé la clé privée" et continuer.',
    'unbindWarningWords': "J'ai téléchargé la clé privée",
    'generateWallet': 'Générer un portefeuille',
    'verifyMnemonic': 'Vérifier le mnémonique',
    'mnemonicMismatch': 'Échec de la vérification du mnémonique',
    'bindBtn1Text': 'Lier le portefeuille (mnémonique)',
    'bindBtn2Text': 'Lier le portefeuille (clé privée)',
    'dprManagement': 'Gestion du DPR',
    'balanceLabel': 'Solde DPR',
    'balance': 'Solde',
    'creditLabel': 'Crédit',
    'operationLabel': 'Opérations',
    'amtOwingLabel': 'Le système a payé pour vous',
    'dailyConsumedLabel': 'Trafic quotidien consommé',
    'dailySharedLabel': 'Trafic quotidien partagé',
    'deposit': 'Déposer',
    'withdraw': 'Retirer',
    'publicKey': 'Clé publique',
    'mnemonicInstruction': "Veuillez noter le mnémonique. Vous devrez le vérifier à l'étape suivante.",
    'enterMnemonic': 'Entrez le mnémonique',
    'enterMnemonicTip': "Sélectionnez chaque mot dans l'ordre où il vous a été présenté.",
    'resetMnemonic': 'réinitialiser',
    'uploadPrivateKey': 'Téléverser la clé privée',
    'getWalletError': "Échec de l'obtention des informations du portefeuille",
    'deviceWalletMismatch': "Incompatibilité entre l'appareil et le portefeuille",
    'walletNotFound': 'Portefeuille non trouvé',
    'generateWalletError': 'Échec de la génération du portefeuille',
    'missingMnemonic': 'Mnémonique manquant',
    'missingPrivateKeyFile': 'Fichier de clé privée manquant',
    'privateKeyFileOversize': 'Le fichier de la clé privée dépasse la limite de 10 Ko',
    'transferTitle': 'Transfert',
    'transferHistoryTitle': 'Historique des transferts',
    'transferTo': 'Clé publique du destinataire',
    'transferAmt': "Montant du transfert (jusqu'à 6 décimales)",
    'invalidTo': 'Destinataire invalide',
    'invalidAmt': 'Montant du transfert invalide',
    'amtOutOfRange': 'Le montant du transfert doit être positif et plafonné à 1 million',
    'transferSuccess': 'Fonds transférés avec succès',
    'senderRecipientSame': "L'expéditeur ne peut pas être le même que le destinataire",
    'recipientNotFound': 'Destinataire non trouvé',
    'insufficientFund': 'Transfert échoué en raison de fonds insuffisants',
    'transferError': 'Échec du transfert des fonds',
    'votingResNotRetrieved': "Le résultat du mandat n'a pas été récupéré.",
    'candidateSelected': 'Le candidat au validateur que vous avez mandaté a été sélectionné.',
    'candidateNotSelected': "Le candidat au validateur que vous avez mandaté n'a pas été sélectionné.",
    'votingResNotReady': "Le résultat du mandat n'est pas disponible pour le moment.",
    'notVoted': "Vous n'avez pas mandaté un candidat validateur.",
    'candidateNotFound': 'Candidat validateur non trouvé',
    'votingResRetrievalErr': 'Échec de la récupération du résultat du mandat',
    'from': 'de',
    'to': 'à',
    'type': 'type',
    'amount': 'montant',
    'time': 'temps',
    'txnTypes': {
      '101': 'récompense initiale',
      '201': 'transfert manuel',
      '301': "récompense d'engagement",
      '401': 'micro paiement',
    },
    'walletAddress': 'Adresse du portefeuille',
    'notInGenesisNodeList': "Le nœud de genèse ne peut être lié qu'à la machine minière de genèse.",
    'onChainStatusLabel': 'Statut sur la chaîne',
    'onChainStatusDesc': {
      'unknown': '-',
      'onChain': 'Oui',
      'notOnChain': 'Non',
    },
    'pwMgmtSection': {
      'title': 'Gestion du mot de passe',
      'setBtnText': 'Définir le mot de passe',
      'resetBtnText': 'Réinitialiser le mot de passe',
    },
    'passwordModal': {
      'title': 'Définir le mot de passe du portefeuille',
      'onePlaceholder': 'Entrer le mot de passe',
      'twoPlaceholder': 'Répéter le mot de passe',
      'btnText': 'Confirmer',
    },
    'setPasswordMsg': {
      'mismatch': 'Les mots de passe ne correspondent pas',
      'weak':
        'Le mot de passe doit comporter au moins 8 caractères et contenir des caractères minuscules, majuscules et numériques',
      'alreadySet': 'Le mot de passe du portefeuille a déjà été défini',
      'genericErr': 'Échec de la définition du mot de passe du portefeuille',
      'success': 'Mot de passe du portefeuille défini avec succès',
    },
    'authModal': {
      'title': 'Mot de passe du portefeuille',
      'inputPlaceholder': 'Entrer le mot de passe du portefeuille',
      'btnText': 'Continuer',
    },
    'passwordNotSet': 'Mot de passe du portefeuille non défini',
    'passwordIncorrect': 'Mot de passe du portefeuille incorrect',
    'resetModal': {
      'disclaimer':
        'Une fois le mot de passe du portefeuille réinitialisé, votre appareil sera déconnecté de tout portefeuille auquel il est actuellement lié. Veuillez taper "Je confirme la réinitialisation du mot de passe du portefeuille" et continuer.',
      'consent': 'Je confirme la réinitialisation du mot de passe du portefeuille',
    },
    'resetPasswordMsg': {
      'noActiveBinding': "L'appareil n'est pas lié au portefeuille spécifié",
      'unbindErr': 'Échec du déliage du portefeuille',
      'genericErr': 'Échec de la réinitialisation du mot de passe du portefeuille',
      'success': 'Mot de passe du portefeuille réinitialisé avec succès',
    },
    'unstaking': {
      'label': 'Infos sur le Staking',
      'getErr': "Échec de l'obtention du choix de déstake",
      'alreadyChosen': 'Vous avez déjà choisi de déstaker',
      'expired': 'La période de déstake est terminée',
      'invalidCreditData': "Ce portefeuille n'est pas placé sur la Deeper Chain.",
      'phase1IsNotOver': "La période de staking de ce portefeuille n'est pas encore expirée.",
      'setErr': 'Échec du réglage du choix de déstake',
      'announcementTitle': 'Termes et conditions du staking',
      'announcement': {
        'continueStaking':
          'Renouvellement du staking : Votre stake a été automatiquement inscrit pour le deuxième terme, qui sera une période de staking non verrouillée de six mois. Le Basic Mining & Genesis APY resteront les mêmes pour le deuxième terme.',
        'duringStaking':
          'Déstake : Vous pouvez cliquer sur le bouton de déstake à tout moment pendant votre 2ème terme. Cette action est irréversible !',
        'stopStaking':
          'Distribution : Une fois déstaké, vous entrerez dans une phase de libération/distribution linéaire de 3 mois. $DPR sera automatiquement distribué quotidiennement à votre portefeuille Deeper Chain.',
        'releaseStaking': '',
      },
      'tipTitle': 'Rappels importants :',
      'tip': {
        'afterStopStaking_1':
          "1) Les nœuds Genesis qui déstake perdront leur statut Genesis et perdront à jamais l'opportunité d'être un nœud Genesis. Votre appareil ne pourra pas participer immédiatement au basic mining 2.0 mais l'accumulation organique de score de crédit via le partage de bande passante continuera.",
        'afterStopStaking_2': '2) Une fois déstaké, vous ne recevrez plus de récompenses de minage $DPR immédiatement.',
        'afterStopStaking_3':
          '3) Une fois déstaké, le crédit staké sera déduit de votre score de crédit. Les crédits organiques gagnés par le partage de bande passante, le cas échéant, resteront.',
        'afterStopStaking_4':
          "4) Une fois déstaké, l'utilisateur ne peut pas restaker immédiatement (attendre les annonces futures)",
        'afterStopStaking_5':
          "5) Une fois déstaké, le premier terme ne se terminera pas avant qu'un total de 270 récompenses ne soit distribué.",
        'afterStopStaking_6':
          "6) Si vous avez besoin d'informations sur le minage, veuillez consulter notre blog Medium pour des ressources supplémentaires.",
        'mediumText1': 'Veuillez cliquer ',
        'mediumText2': 'Deeper Network Basic Mining 2.0',
        'mediumText3': ' pour plus de détails',
        'mediumLink':
          'https://deeper-network.medium.com/deeper-network-basic-mining-2-0-mining-updates-for-genesis-and-basic-mining-2ec2f112cfd4',
      },
      'btn': {
        'cancel': 'Compris, continuer le staking',
        'ok': 'Déstaker',
      },
    },
    'staking': {
      'walletInfo': 'Informations sur le portefeuille',
      'campaignLabel': 'Type de Staking',
      'campaignText': {
        '0': 'Nœud Genesis Or',
        '1': 'Nœud Genesis Argent',
        '2': 'Mining de Base 1.0',
        '3': 'Mining DPRB',
        '4': 'Mining de Base 2.0',
        '7': 'Mining de Base 2.0',
        '8': 'Mining de Base 2.0',
      },
      'creditLabel': 'Crédit Actuel',
      'creditMaxLabel': 'Crédit Maximum',
      'creditLevelLabel': 'Niveau de Crédit',
      'stakeAndBurn': 'Staker et Brûler',
      'stakeForCredit': 'Augmenter le Stake',
      'dailyReward': 'Récompense Quotidienne',
      view: 'Vérifier',
      'payment': 'Paiement',
      'insufficientBalance': 'Solde insuffisant',
      'stakeSuccess': 'La demande a été envoyée. Veuillez patienter patiemment pour la mise à jour du crédit.',
      'stakeError':
        "Échec de l'envoi de la demande. Veuillez vérifier les informations relatives au portefeuille ou consulter le service client.",
      'burnForCredit': 'Brûler des DPR pour le Crédit',
      'burnSuccess': 'La demande a été envoyée. Veuillez patienter patiemment pour la mise à jour du crédit.',
      'burnError':
        "Échec de l'envoi de la demande. Veuillez vérifier les informations relatives au portefeuille ou consulter le service client.",
    },
  },
  // ChangePasswordData
  'ChangePasswordData': {
    'successMsg': 'Mot de passe changé avec succès',
    'newPasswordMismatch': 'Le nouveau mot de passe et la confirmation du nouveau mot de passe ne correspondent pas',
    'oldPasswordMismatch': "L'ancien mot de passe ne correspond pas à l'enregistrement",
    'samePasswordError': "Le nouveau mot de passe est le même que l'ancien",
    'genericError': 'Erreur lors du changement de mot de passe',
    'title': 'Changer le mot de passe',
    'oldPwd': "Veuillez entrer l'ancien mot de passe",
    'newPwd': 'Veuillez entrer le nouveau mot de passe',
    'newPwdAgain': 'Entrez à nouveau le nouveau mot de passe',
    'okText': 'OK',
  },
  // dailyTraffic
  'dailyTraffic': {
    'historySize': 'Nombre de jours',
    'chartTitle': 'Trafic',
    'uptimeChartTitle': 'Temps de fonctionnement',
    'hour': 'h',
    'minute': 'm',
    'second': 's',
  },
  // flow
  'flow': {
    'totalTrafficLabel': 'Trafic Total',
    'localTraffic': 'Trafic Local',
    'clientTraffic': 'Trafic Client',
    'serverTraffic': 'Trafic Serveur',
    'upload': 'Téléchargement (Upload)',
    'download': 'Téléchargement (Download)',
    'sessionSpeed': 'Vitesse de Session',
  },
  // login
  'login': {
    'pageTitle': 'Bienvenue sur A Deeper Network!',
    'username': "nom d'utilisateur",
    'password': 'mot de passe',
    'btnText': 'connexion',
    'successMsg': 'Connexion réussie',
    'missingField': "Nom d'utilisateur ou mot de passe manquant",
    'wrongField': "Nom d'utilisateur ou mot de passe incorrect",
    'invalidToken': 'Données de connexion invalides',
    'expiredToken': 'Données de connexion expirées',
    'genericError': 'Erreur de connexion',
    'tooManyAttempts': 'Trop de tentatives de connexion',
  },
  //notification
  'notification': {
    'search': 'Recherche',
    'CP': 'Plan de Contrôle',
    'DP': 'Plan de Données',
    'MP': 'Plan de Gestion',
  },
  // DomainConfig
  'domainConfig': {
    'whiteList': 'Liste de Route Intelligente',
    'blackList': 'Liste de Route Directe',
    'domain': 'Domaine',
    'tunnel': 'Tunnel',
    'selectTunnel': 'Sélectionnez le tunnel',
    'region': 'Région',
    'country': 'Pays',
    'noTunnel': 'Accès Direct',
    'convertedTip': "L'URL a été automatiquement convertie en nom de domaine.",
    'urlInputPlaceholder': 'ex : https://www.google.com ou google.com',
    'missingInfoWarning': 'Informations manquantes',
    'invalidDomainError': 'Le format du domaine est invalide ou le domaine est trop long',
    'whitelistAddSuccess': 'Ajouté à la Liste de Route Intelligente avec succès',
    'existInBlacklist':
      "Échec de l'ajout à la Liste de Route Intelligente car il existe dans la Liste de Route Directe",
    'whitelistEntryExist': 'Entrée déjà présente dans la Liste de Route Intelligente',
    'tunnelNotExist': "Échec de l'ajout à la Liste de Route Intelligente car le tunnel n'existe pas",
    'whitelistAddError': "Échec de l'ajout à la Liste de Route Intelligente",
    'blacklistAddSuccess': 'Ajouté à la Liste de Route Directe avec succès',
    'existInWhitelist':
      "Échec de l'ajout à la Liste de Route Directe car il existe dans la Liste de Route Intelligente",
    'blacklistEntryExist': 'Entrée déjà présente dans la Liste de Route Directe',
    'blacklistAddError': "Échec de l'ajout à la Liste de Route Directe",
    'conflict': "Échec de l'ajout de l'entrée en raison d'un conflit",
    'whitelistDelSuccess': 'Entrées de la Liste de Route Intelligente supprimées avec succès',
    'domainNotExist': "Le(s) domaine(s) sélectionné(s) n'existent pas",
    'whitelistDelError': 'Échec de la suppression des entrées de la Liste de Route Intelligente',
    'blacklistDelSuccess': 'Entrées de la Liste de Route Directe supprimées avec succès',
    'blacklistDelError': 'Échec de la suppression des entrées de la Liste de Route Directe',
    'backupTips':
      'Le tunnel de secours est maintenant actif. En mode de routage intelligent, si le tunnel ajouté manque temporairement de nœuds disponibles, le système utilisera par défaut le tunnel de secours - {0}.',
    'tips': {
      'smartRouteList':
        'Selon vos besoins, vous pouvez ajouter manuellement les noms de domaine que vous souhaitez accéder avec leurs pays correspondants.',
      'directRouteList':
        'Les noms de domaine ajoutés ici ne passeront pas par le tunnel DPN mais seront directement accessibles en utilisant le réseau local.',
    },
  },
  // ipConfig
  'ipConfig': {
    'whiteList': "Liste d'IP Intelligente",
    'blackList': "Liste d'IP Directe",
    'ip': 'IP',
    'tunnel': 'Tunnel',
    'selectTunnel': 'Sélectionnez le tunnel',
    'region': 'Région',
    'country': 'Pays',
    'noTunnel': 'Pas de Tunnel',
    'convertedTip': "L'URL a été automatiquement convertie en IP",
    'urlInputPlaceholder': 'ex : https://xxx.xxx.xxx.xxx ou xxx.xxx.xxx.xxx',
    'missingInfoWarning': 'Informations manquantes',
    'invalidIPError': "Format d'IP invalide",
    'whitelistAddSuccess': "Ajouté à la Liste d'IP Intelligente avec succès",
    'existInBlacklist': "Échec de l'ajout à la Liste d'IP Intelligente car elle existe dans la Liste d'IP Directe",
    'whitelistEntryExist': "Entrée déjà présente dans la Liste d'IP Intelligente",
    'tunnelNotExist': "Échec de l'ajout à la Liste d'IP Intelligente car le tunnel n'existe pas",
    'whitelistAddError': "Échec de l'ajout à la Liste d'IP Intelligente",
    'blacklistAddSuccess': "Ajouté à la Liste d'IP Directe avec succès",
    'existInWhitelist': "Échec de l'ajout à la Liste d'IP Directe car elle existe dans la Liste d'IP Intelligente",
    'blacklistEntryExist': "Entrée déjà présente dans la Liste d'IP Directe",
    'blacklistAddError': "Échec de l'ajout à la Liste d'IP Directe",
    'whitelistDelSuccess': "Entrées de la Liste d'IP Intelligente supprimées avec succès",
    'ipNotExist': "L'(Les) IP sélectionnée(s) n'existe(nt) pas",
    'whitelistDelError': "Échec de la suppression des entrées de la Liste d'IP Intelligente",
    'blacklistDelSuccess': "Entrées de la Liste d'IP Directe supprimées avec succès",
    'blacklistDelError': "Échec de la suppression des entrées de la Liste d'IP Directe",
    'backupTips':
      'Le tunnel de secours est maintenant actif. En mode de routage intelligent, si le tunnel ajouté manque temporairement de nœuds disponibles, le système utilisera par défaut le tunnel de secours - {0}.',
    'tips': {
      'smartRouteList':
        'Selon vos besoins, vous pouvez ajouter manuellement les adresses IP que vous souhaitez accéder avec leurs pays correspondants.',
      'directRouteList':
        'Les adresses IP ajoutées ici ne passeront pas par le tunnel DPN mais seront directement accessibles en utilisant le réseau local.',
    },
  },
  // queryRouting
  'queryRouting': {
    'title': 'Recherche de Routage',
    'searchLabel': 'Configuration de Recherche',
    'search': 'Rechercher',
    'searchResult': 'Résultat de la recherche : ',
    'invalidRoutingError': 'Le format de routage est invalide',
    'defaultConfig': 'Config par Défaut',
    'userConfig': 'Config Utilisateur',
    'routingNotFound': 'Routage non trouvé',
    'queryRoutingError': 'Échec de la recherche de routage',
    'convertedTip': "L'URL a été automatiquement convertie en nom de domaine ou en IP.",
    'urlInputPlaceholder': 'ex : google.com ou xxx.xxx.xxx.xxx',
  },
  // rustdesk
  'rustdesk': {
    'ipLabel': 'Votre adresse IP est',
    'noPubIp': "Vous n'avez pas d'IP publique.",
    'vpnUse':
      'Vous pouvez utiliser le port de transfert VPN Wireguard pour obtenir une IP publique. Veuillez noter que cette IP publique ne peut être utilisée que pour les services RustDesk et ne peut pas être utilisée pour fournir des services DPN.',
    'config': {
      'title': 'Configuration de RustDesk',
      'switch': 'Activer/Désactiver',
      'forceKey': 'Forcer le client à utiliser la même clé publique',
      'relay': 'Serveurs relais séparés par des virgules (hôte:port), par ex. www.example.com:21116,192.168.1.1:21117',
      'port': 'Port (2-65532)',
      'saveBtnText': 'Sauvegarder',
      'pubKey': 'Clé publique',
      'emptyPubKey': 'Pas de clé publique',
      'resetBtnText': 'Réinitialiser la clé publique',
    },
    'vpn': {
      'title': 'Transfert de port VPN Wireguard',
      'switch': 'Activer/Désactiver',
      'ip': 'IP du serveur Wireguard',
      'port': 'Port de transfert VPN',
    },
    'guide': {
      'title': 'Guide de configuration',
      'stepOne': 'Cliquez sur les boutons comme indiqué',
      'stepTwoA': 'Copiez le champ correspondant dans le formulaire puis cliquez sur sauvegarder',
      'idAndRelay': 'ID/Serveur relais',
      'idServer': 'Serveur ID :',
      'relayServer': 'Serveur relais :',
      'apiServer': 'Serveur API :',
      'serverKey': 'Clé :',
      'stepTwoB': "Ou vous pouvez copier la configuration dans le presse-papiers et l'importer dans RustDesk",
      'copyBtnText': 'Copier la conf du serveur dans le presse-papiers',
    },
    'success': {
      'setSwitch': 'RustDesk basculé avec succès',
      'setForced': 'Client forcé à utiliser la même clé publique avec succès',
      'setRelayAndPort': 'Relais et port enregistrés avec succès',
      'resetPubKey': 'Clé publique réinitialisée avec succès',
      'setVpnSwitch': 'VPN Wireguard basculé avec succès',
      'copyToClipboard': 'Copié dans le presse-papiers avec succès',
    },
    'error': {
      'getNetworkAddress': "Échec de l'obtention de l'IP",
      'getConfig': 'Échec du chargement de la configuration RustDesk',
      'setSwitch': 'Échec de la bascule de RustDesk',
      'setForced': 'Échec de la contrainte du client à utiliser la même clé publique',
      'invalidPort': 'Port invalide',
      'invalidRelay': 'Relais invalide',
      'setRelayAndPort': "Échec de l'enregistrement du relais et du port",
      'resetPubKey': 'Échec de la réinitialisation de la clé publique',
      'getVpnConfig': "Échec de l'obtention de la configuration VPN Wireguard",
      'setVpnSwitch': 'Échec de la bascule du VPN Wireguard',
      'copyToClipboard': 'Échec de la copie dans le presse-papiers',
      'getVersion': "Échec de l'obtention de la version",
      'getHbbrLog': "Échec de l'obtention du journal HBBR",
      'getHbbsLog': "Échec de l'obtention du journal HBBS",
    },
  },
  // sharing
  'sharing': {
    'sharingTitle': 'Partage : ',
    'btSharingTitle': 'Partage Bit Torrent : ',
    'enabled': 'Activé',
    'disabled': 'Désactivé',
    'sharingTrafficLimit': 'Limite de trafic de partage mensuelle',
    'sharingBandwidthLimit': 'Limite de bande passante de partage',
    'unlimited': 'Illimité',
    'limited': 'Limité',
    'setLabel': 'Définir',
    'checkStartEndTime': "Veuillez sélectionner l'heure de début et de fin",
    'time': 'temps',
    'record': 'enregistrement',
    'startTime': 'heure de début',
    'endTime': 'heure de fin',
    'keyword': 'mot-clé',
    'search': 'Rechercher',
    'webToSharingTitle': 'Appliquer les règles de filtre Web',
    webToSharingDesc:
      "Appliquer le filtrage des publicités DNS intégré à l'appareil et les règles de filtrage DNS personnalisées",
    'yes': 'Oui',
    'no': 'Non',
    'download': 'Journaux en texte clair',
    'export': 'Journaux chiffrés',
    'success': {
      enableTrafficLimit: "Limite mensuelle de trafic de partage activée. S'il vous plaît, fixez la limite !",
      'setSharingTrafficLimit': 'Limite de trafic de partage définie avec succès',
      'enableBandwidthLimit':
        'Limite de bande passante de partage activée. Veuillez définir la limite entre 15Mbps et 1024Mbps !',
      'setSharingBandwidthLimit': 'Limite de bande passante de partage définie avec succès',
    },
    'errors': {
      'getSharingState': "Échec de l'obtention de l'état de partage",
      'switchSharingState': "Échec du changement de l'état de partage",
      'exceedsLimit': "Échec de l'activation du partage car le trafic mensuel a dépassé la limite",
      'getSharingTrafficLimit': "Échec de l'obtention de la limite de trafic de partage",
      'setSharingTrafficLimit': 'Échec de la définition de la limite de trafic de partage',
      'invalidTrafficLimit': 'La limite de trafic doit être un nombre positif',
      'setSharingBandwidthLimit': 'Échec de la définition de la limite de bande passante de partage',
      'invalidBandwidthLimit': 'La limite de bande passante doit être comprise entre 15Mbps et 1024Mbps',
      'getSharingFilterConfig': "Échec de l'obtention de la configuration du filtre de partage",
      'setSharingFilterConfig': 'Échec de la définition de la configuration du filtre de partage',
    },
  },
  // system
  'system': {
    'hardwareInfo': 'Informations sur le matériel',
    'softwareInfo': 'Informations sur le logiciel',
    'networkAddress': 'Adresse réseau',
    'sessionInfo': 'Informations sur la session',
    'upgradeInfo': 'Mise à jour du firmware',
    'sn': 'SN',
    'cpu': 'CPU',
    'cores': 'Cœurs',
    'memory': 'Utilisation/Total',
    'systemVersion': 'Version du système',
    'appidSignVersion': "Bibliothèque de fonctionnalités d'identification du protocole de trafic",
    'urlFilterSignVersion': 'Bibliothèque de fonctionnalités de filtrage Web',
    'https': 'HTTPS',
    'dns': 'DNS',
    'rules': 'Règles',
    'ip': 'IP',
    'routerMac': 'Mac du routeur',
    'gatewayMac': 'Mac de la passerelle',
    'maxSession': 'Nombre maximal de sessions',
    'currentSession': 'Nombre de sessions actuelles',
    'tcpUdpIcmp': 'TCP/UDP/ICMP/TUNNEL',
    'autoUpdate': 'Mise à jour automatique',
    'manualUpgrade': 'Mise à jour manuelle',
    'upgrading': 'Mise à jour en cours...',
    'latestVersion': 'Dernière version',
    'systemStatusHistory': "Historique de l'état du système",
    'cpuUsage': 'Utilisation du CPU sur 24 heures',
    'memoryUsage': 'Utilisation de la mémoire sur 24 heures',
    'off': 'Éteint',
    'on': 'Activé',
    'nowCheckVersion': 'Vérification de la version en cours',
    'nowLatestVersion': 'Version la plus récente',
    'nowNewTestVersion': 'Version actuellement utilisée',
    'upgradeLoadingText':
      'Mise à jour du firmware en cours. Cela peut prendre plusieurs minutes. Veuillez ne pas rafraîchir la page avant la fin de la mise à jour.',
    'upgradeSuccess': 'mise à jour réussie, rafraîchissement de la page bientôt',
    'upgradeError': {
      'noImage': 'Image de mise à jour manquante',
      'invalidImage': 'Image de mise à jour invalide',
      'imageArchMismatch': "Incompatibilité entre l'image de mise à jour et l'architecture de l'appareil",
      'noResponse':
        "L'appareil a redémarré. Veuillez rafraîchir la page plus tard pour vérifier si la mise à jour a réussi.",
      'alreadyLatest': 'la version actuelle est récente, pas besoin de mise à jour',
      'generic': 'Erreur de mise à jour',
    },
    'copySuccess': 'Copie réussie',
    'copyFail': 'Échec de la copie',
  },
  // techSupport
  'techSupport': {
    'remoteConnection': {
      'title': 'Connexion à distance',
      'loading': 'Connexion en cours...',
      'popupTitle': 'Code de connexion à distance',
      'intro':
        "Scénario applicable : <span>Lorsque le réseau de votre appareil fonctionne normalement, mais que vous rencontrez des problèmes avec d'autres fonctionnalités</span>.<br/>Générez manuellement un \"Code de connexion à distance\" et envoyez-le à notre équipe de service client. Notre équipe technique localisera rapidement le problème et vous aidera à le résoudre. Veuillez noter qu'il est conseillé de ne pas générer le code plusieurs fois dans un court laps de temps pour maintenir l'efficacité de la fonction.",
      'buttonText': 'Générer un code',
      'generateFeatureCodeFailure': 'Échec de la génération du code.',
      'tips':
        'Si vous rencontrez des problèmes, veuillez envoyer les détails à notre support client. Nous nous engageons à vous aider et à résoudre vos problèmes.',
      'confirm': 'Copier et fermer',
      'copySuccess': 'Copié avec succès dans le presse-papiers',
      'copyFailure': 'Échec de la copie dans le presse-papiers',
      'connectFailure': 'Échec de la connexion',
    },
    'logDownload': {
      'title': 'Téléchargement de logs',
      'loading': 'Téléchargement en cours...',
      'intro':
        'Scénario applicable : <span>Lorsque votre appareil peut accéder au backend mais rencontre des problèmes de réseau</span><br />.Veuillez télécharger manuellement les fichiers de log et les envoyer à notre équipe de service client. Nous comprendrons votre situation spécifique et fournirons une assistance plus efficace.',
      'buttonText': 'Télécharger',
      'downloadSuccess': 'Téléchargement réussi',
      'downloadFailure': 'Échec du téléchargement, veuillez réessayer plus tard',
    },
    'supportServices': {
      'title': "Obtenez l'aide dont vous avez besoin.",
      'intro': 'Donnez-nous quelques détails et nous vous proposerons la meilleure solution.',
      'buttonText': "Obtenir de l'aide",
    },
    'promiseTips': {
      'title': "Promesse d'assurance de sécurité",
      'intro':
        "Soyez assuré, nous limitons strictement <span>l'utilisation des fonctionnalités de connexion à distance et de téléchargement de logs uniquement pour le diagnostic et la résolution de problèmes</span>, et jamais à d'autres fins, garantissant que votre vie privée et votre sécurité sont protégées avec la plus grande rigueur.",
    },
  },
  // builtinRules
  'builtinRules': [
    {
      'label': 'Activer le filtrage HTTPS (inclut le filtrage HTTP)',
      'sslbypass': 'Activer le contournement SSL',
      'value': 'youtube',
    },
  ],
  // customrules
  'customrules': [
    {
      'label': 'Activer le filtrage HTTPS (inclut le filtrage HTTP)',
      'value': 'youtube',
    },
  ],
  // tproxy
  'tproxy': {
    'browserCacheWarning':
      "Si le filtrage des publicités HTTPS échoue après son activation, vous devez vider le cache de votre navigateur. Activer le filtrage HTTPS sans installer le certificat ne filtrera pas seulement les publicités, mais peut également affecter l'accès normal aux sites web de la liste.",
    'triggerRulesTipsTitle': 'Conseils',
    'buttonAddRule': 'Ajouter une règle',
    'buttonInstallCertificate': 'Installer le certificat',
    'missingInfoWarning': 'Veuillez vérifier vos informations saisies',
    'missingInfoSuccess': 'Opération réussie',
    'missingInfoError': "Échec de l'opération",
    'certificateTip': 'Pour assurer une utilisation normale, veuillez vous assurer que le certificat a été installé',
    'remind': 'Ne plus rappeler',
    'tip': 'Vous devez suivre les étapes ci-dessous pour bloquer correctement les publicités',
    'triggerRulesTips':
      'Après avoir activé le filtrage HTTPS, pour que Deeper Connect filtre les publicités sur les sites web listés, vous devez installer et faire confiance au certificat racine de Deeper Network sur votre ordinateur.',
    'triggerRulesTips2':
      "Activer simplement le filtrage HTTPS sans installer le certificat peut non seulement échouer à filtrer les publicités, mais aussi affecter l'accès normal aux sites web de la liste.",
    'mobileDownloadCertTips': 'Veuillez télécharger et installer le certificat en utilisant le navigateur Safari.',
    'androidTVTip': "Si vous utilisez Android TV, veuillez télécharger l'APP pour regarder la vidéo sans publicités",
    'errorMessage': {
      'maxLength': 'Le nombre maximal de règles est de 1024',
      'ruleExisted': 'domaine et motif déjà existants',
    },
    'label': {
      'domain': 'Domaine',
      'Pattern': 'Motif',
      'ruleType': 'Type de règle',
      'web': 'Navigateur',
      'app': 'APP',
      'all': 'TOUT',
      'Rewrite_Pattern': 'Réécrire le motif',
      'Rewrite_Info': 'Informations de réécriture',
      'Rewrite_Action': 'Action',
      'enable': 'Activer',
      'operation': 'Opération',
      'order': "Numéro d'ordre",
    },
    'operation': {
      'delete': 'Supprimer',
      'edit': 'Modifier',
    },
    'modal': {
      'title': 'Ajouter une règle',
      'confirmText': 'Soumettre',
      'cancelText': 'Annuler',
    },
    'placeholder': {
      'domain': 'Veuillez saisir un domaine correct',
      'Pattern': 'Veuillez saisir un motif correct',
      'ruleType': 'Veuillez sélectionner un type de règle',
      'Rewrite_Pattern': 'Veuillez saisir un motif de réécriture correct',
      'Rewrite_Info': 'Veuillez saisir des informations de réécriture',
      'Rewrite_Action': 'Veuillez sélectionner une action',
    },
    'builtinRules': {
      'title': 'Filtre de publicités',
      'filterListtitle': 'Liste de filtrage HTTPS',
      'platform': 'Plateforme de support',
      'segOptions': ["Tutoriel d'installation du certificat", 'Liste de support'],
    },
    'customerRules': {
      'title': 'Règles HTTPS personnalisées',
    },
    'guide': {
      'buttonDownloadCertificate': 'Cliquez pour télécharger le certificat',
      'buttonDownloadApp': 'Cliquez pour télécharger SkyTube',
      'tab': {
        'mobileInstall': 'iPhone/iPad',
        'macInstall': 'MacOS',
        'windowsInstall': 'Windows',
        'androidInstall': 'Android',
        'linuxInstall': 'Linux',
      },
      'mobile': {
        'videoTab': 'Vidéo',
        'textTab': 'Texte',
        'step1': {
          'label': 'étape 1',
        },
        'step2': {
          'label': 'étape 2',
          'tip': 'Cliquez sur Autoriser',
        },
        'step3': {
          'label': 'étape 3',
          'tip': 'Ouvrez les Paramètres de l\'iPhone, cliquez sur "Profil téléchargé"',
        },
        'step4': {
          'label': 'étape 4',
          'tip': 'Cliquez sur Installer, entrez le mot de passe du téléphone',
        },
        'step5': {
          'label': 'étape 5',
          'tip': 'Cliquez sur Installer',
        },
        'step6': {
          'label': 'étape 6',
          'tip': 'Installation complète',
        },
        'step7': {
          'label': 'étape 7',
          'tip':
            'Ouvrez les "Paramètres" du téléphone > "Général" > "À propos" > "Paramètres de confiance des certificats", activez le certificat installé.',
        },
      },
      'mac': {
        'step1': {
          'label': 'étape 1',
        },
        'step2': {
          'label': 'étape 2',
          'tip':
            'Cliquez sur le certificat téléchargé, le système affichera une fenêtre pop-up "Ajouter un certificat", sélectionnez "Connexion" dans le "Trousseau" puis appuyez sur "Ajouter".',
        },
        'step3': {
          'label': 'étape 3',
          'tip':
            'Dans la fenêtre "Accès au trousseau", choisissez "Connexion" dans le "Trousseau par défaut" dans la barre latérale.',
        },
        'step4': {
          'label': 'étape 4',
          'tip':
            'Trouvez le certificat "deeper.network" et sélectionnez-le, puis faites un clic droit et choisissez "Obtenir des informations".',
        },
        'step5': {
          'label': 'étape 5',
          'tip':
            'Cliquez sur "Confiance" dans les informations, changez "Utiliser les paramètres système par défaut" en "Toujours faire confiance".',
        },
        'step6': {
          'label': 'étape 6',
          'tip':
            'Cliquez dans le coin supérieur gauche pour fermer les informations, entrez le mot de passe, et cliquez sur "Mettre à jour les paramètres".',
        },
        'step7': {
          'label': 'étape 7',
          'tip': 'Installation du certificat terminée.',
        },
      },
      'windows': {
        'step1': {
          'label': 'étape 1',
        },
        'step2': {
          'label': 'étape 2',
          'tip': 'Cliquez sur le certificat téléchargé, cliquez sur "Installer le certificat"',
        },
        'step3': {
          'label': 'étape 3',
          'tip': 'Sélectionnez "Emplacement du magasin" comme "Machine locale", cliquez sur "Suivant"',
        },
        'step4': {
          'label': 'étape 4',
          'tip': 'Sélectionnez "Placer tous les certificats dans le magasin suivant", cliquez sur "Parcourir"',
        },
        'step5': {
          'label': 'étape 5',
          'tip': 'Sélectionnez "Autorités de certification racines de confiance" et cliquez sur "OK"',
        },
        'step6': {
          'label': 'étape 6',
          'tip': 'Cliquez sur "Terminer", l\'installation du certificat est terminée',
        },
      },
      'linux': {
        rootTab: "Tutoriel d'importation du certificat racine du système",
        root: {
          step1: {
            label: 'étape 1',
          },
          step2: {
            label: 'étape 2',
            tip:
              '<p>Copiez le fichier de certificat dans /usr/local/share/ca-certificates/</p>\
              <p>Ce répertoire est spécifiquement utilisé pour stocker les certificats CA supplémentaires installés localement. Le fichier de certificat doit être au format PEM avec une extension .crt.</p>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /usr/local/share/ca-certificates/</code></div>',
          },
          step3: {
            label: 'étape 3',
            tip:
              '<p>Mise à jour du magasin de certificats</p>\
              <p>Utilisez la commande update-ca-certificates pour mettre à jour le magasin de certificats, cela rafraîchira automatiquement la chaîne de confiance des certificats dans le système.</p>\
              <div class="code"><code>sudo update-ca-certificates</code></div>\
              <p>Cette commande lit les fichiers de certificat dans le répertoire /usr/local/share/ca-certificates/ et les ajoute à la liste de confiance du système.</p>\
              <em><em class="warning">Attention :</em> Les différentes distributions Linux peuvent avoir des emplacements de magasin de certificats et des commandes de mise à jour différents.</em><br>\
              <em>Par exemple, sur les systèmes basés sur Red Hat\
              (comme Fedora, CentOS), vous pourriez avoir besoin de copier le certificat dans /etc/pki/ca-trust/source/anchors/,</em><br>\
              <em>puis exécuter la commande update-ca-trust pour mettre à jour le magasin de certificats.</em><br>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /etc/pki/ca-trust/source/anchors/</code><br>\
              <code>sudo update-ca-trust</code></div>\
              <p>Après avoir exécuté ces étapes, le certificat racine que vous avez installé devrait être approuvé par le système.</p>',
          },
        },
        'chrome': {
          'step1': {
            'label': 'étape 1',
          },
          'step2': {
            'label': 'étape 2',
            'tip':
              'Entrez chrome://settings/certificates dans la barre d\'adresse, puis sélectionnez l\'onglet "<b>Autorités</b>".',
          },
          'step3': {
            'label': 'étape 3',
            'tip': 'Cliquez sur Importer, trouvez le certificat téléchargé, cliquez sur Ouvrir',
          },
          'step4': {
            'label': 'étape 4',
            'tip':
              'Cochez toutes les options et cliquez sur Ok dans la fenêtre "<b>Autorité de certification</b>" pour terminer l\'installation du certificat.',
          },
        },
        'firefox': {
          'step1': {
            'label': 'étape 1',
          },
          'step2': {
            'label': 'étape 2',
            'tip':
              "Entrez 'about:preferences#privacy' dans la barre d'adresse, trouvez 'Certificats', puis cliquez sur 'Voir les certificats'",
          },
          'step3': {
            'label': 'étape 3',
            'tip': 'Sélectionnez Autorités, cliquez sur Importer',
          },
          'step4': {
            'label': 'étape 4',
            'tip': 'Sélectionnez le certificat que vous venez de télécharger et cliquez sur Ouvrir',
          },
          'step5': {
            'label': 'étape 5',
            'tip': "Cochez toutes les options, cliquez sur OK, l'installation du certificat est terminée",
          },
        },
      },
      'android': {
        'appTab': 'APP',
        'browserTab': 'Navigateur',
        'app': {
          'step1': {
            'label': 'étape 1',
          },
          'step2': {
            'label': 'étape 2',
            'tip': "Installez l'application téléchargée et profitez de la vidéo sans publicités",
          },
        },
        'browser': {
          'step1': {
            'label': 'étape 1',
          },
          'step2': {
            'label': 'étape 2',
            'tip': 'Ouvrez les "Paramètres" du téléphone et trouvez "Sécurité"',
          },
          'step3': {
            'label': 'étape 3',
            'tip': 'Entrez dans "Sécurité", trouvez "Chiffrement & Identifiants", cliquez pour entrer',
          },
          'step4': {
            'label': 'étape 4',
            'tip': 'Cliquez sur "Installer un certificat"',
          },
          'step5': {
            'label': 'étape 5',
            'tip': 'Cliquez sur "Certificat CA"',
          },
          'step6': {
            'label': 'étape 6',
            'tip': 'cliquez sur "Installer quand même", puis entrez votre mot de passe de téléphone',
          },
          'step7': {
            'label': 'étape 7',
            'tip': 'Sélectionnez le certificat téléchargé',
          },
          'step8': {
            'label': 'étape 8',
            'tip':
              'Message indiquant que le certificat CA a été installé et que le certificat est installé avec succès',
          },
        },
      },
    },
  },
  // SSLBypassCheckbox
  'SSLBypassCheckbox': {
    'sslBypass': 'Activer le contournement SSL',
    'sslBypassTips': 'Permettre aux appareils sans certificats de se connecter au réseau.',
    'sslBypassWaring': 'Si le contournement SSL est activé, le filtrage des publicités peut être manqué.',
    'success': 'Opération réussie',
    'failed': "Échec de l'opération",
  },
  // tunnel
  'tunnel': {
    'configuredTunnel': 'Tunnel configuré',
    countryTooltip:
      'Recherche floue du nom du pays ou entrez le code du pays (en majuscules) et appuyez sur Entrée pour rechercher le pays souhaité',
    'region': 'Région',
    'country': 'Pays',
    'activeTunnelNum': "Nombre d'IP actives",
    'activeIp': 'IP active',
    'switchNode': "Changer l'IP active",
    'switchIpTitle': "Changer l'IP",
    'initTunnelTitle': 'Réinitialiser le tunnel',
    'initTunnelTitleTips':
      'Un tunnel a 3 adresses IP. Lorsque vous rafraîchissez le tunnel, il passera à 3 nouvelles adresses IP pour vous.',
    'selectRegion': 'Sélectionner la région',
    'selectCountry': 'Sélectionner le pays',
    'missingInfoWarning': 'Informations manquantes',
    'tunnelAddSuccess': 'Tunnel ajouté avec succès. La page se rafraîchira dans 3 secondes.',
    'tunnelExist': 'Le tunnel existe déjà',
    'reachedMax': 'Nombre maximum de tunnels atteint',
    'tunnelConflict': 'Le tunnel à ajouter est en conflit avec le tunnel {0} - {1}',
    'tunnelAddError': "Échec de l'ajout du tunnel",
    'tunnelDelSuccess': 'Tunnels supprimés avec succès. La page se rafraîchira dans 3 secondes.',
    'tunnelNotExist': "Le(s) tunnel(s) n'existe(nt) pas",
    'usedByFullMode': 'Le(s) tunnel(s) à supprimer sont utilisés par la route complète DPN',
    usedByAccessControl: "Le(s) tunnel(s) à supprimer sont utilisés par le contrôle d'accès",
    'usedByBackup': 'Le tunnel de sauvegarde utilise ce tunnel, donc il ne peut pas être supprimé.',
    'tunnelInUse': 'Le(s) tunnel(s) à supprimer sont utilisés par la table de routage',
    'tunnelDelError': 'Échec de la suppression des tunnels',
    'switchNodeSuccess': 'Nœud changé avec succès. La page se rafraîchira dans 3 secondes.',
    'noActiveIp': 'Aucune IP active disponible pour le changement',
    'ipNotChanged': 'IP non modifiée',
    'switchNodeError': "Échec du changement d'IP",
    'refreshLabel': 'Rafraîchir',
    'getNewIPLabel': 'Obtenir de nouvelles IP',
    'refreshError': 'Échec du rafraîchissement du tunnel',
    'refreshSuccess': 'Demande de rafraîchissement du tunnel soumise. La page se rafraîchira dans 3 secondes.',
    'tips': {
      'refreshTunnel': 'Rafraîchir de nouveaux tunnels actifs pour vous.',
      'configuredTunnel':
        "Après avoir manuellement ajouté les tunnels régionaux dont vous avez besoin, AtomOS recherchera des nœuds qui répondent à vos exigences à l'échelle mondiale.",
    },
    'nodeLbMode': {
      'label': 'Mode de sélection de nœud',
      'none': {
        'label': 'Régulier',
        'desc':
          'Dans ce mode de tunnel, toutes les connexions réseau passent par un nœud actif. Les utilisateurs peuvent changer ce nœud à tout moment.',
      },
      'random': {
        'label': 'Aléatoire',
        'desc': 'Le système sélectionne automatiquement un nœud au hasard pour chaque connexion.',
      },
      'domain': {
        'label': 'Sensibilisé au domaine',
        'desc':
          "Le système utilise plusieurs nœuds dans le tunnel pour accéder au réseau. Cependant, il se souvient et achemine systématiquement l'accès à des noms de domaine spécifiques à travers le même nœud, réduisant ainsi le risque d'erreurs dans l'accès au site Web.",
      },
      'rr': {
        'label': 'Round-Robin',
        'desc':
          'À chaque connexion, le système sélectionne séquentiellement parmi un ensemble de nœuds, vous aidant à obtenir une meilleure accélération du réseau.',
      },
      'failure': "Échec de la sauvegarde du mode d'équilibrage de charge des nœuds",
      'success': "Mode d'équilibrage de charge des nœuds enregistré avec succès",
    },
  },
  // userConsent
  'userConsent': {
    'notice':
      "Avis : Le partage est désactivé avant que vous acceptiez les TERMES ET CONDITIONS D'UTILISATION et la POLITIQUE DE CONFIDENTIALITÉ.",
    'consentStart': "J'ai lu et j'accepte les ",
    'termsOfUseNotRead': "Vous n'avez pas lu les TERMES ET CONDITIONS D'UTILISATION",
    'termsOfUseLink': 'https://shop.deeper.network/terms-of-service?lang=fr',
    'termsOfUseTitle': "TERMES ET CONDITIONS D'UTILISATION",
    'consentAnd': ' et ',
    'privacyPolicyNotRead': "Vous n'avez pas lu la POLITIQUE DE CONFIDENTIALITÉ",
    'privacyPolicyLink': 'https://shop.deeper.network/privacy-policy?lang=fr',
    'privacyPolicyTitle': 'POLITIQUE DE CONFIDENTIALITÉ',
    'confirmBtn': 'Confirmer',
    'consentError': 'Échec de la confirmation du consentement',
  },
  // customConfigLang
  'customConfigLang': {
    'missingInfoWarning': 'Domaine manquant',
    'invalidDomainError': 'Format de domaine invalide ou domaine trop long',
    'whitelist': 'Liste blanche',
    'blacklist': 'Liste noire',
    'domain': 'Domaine',
    'whitelistAddSuccess': 'Ajouté à la liste blanche avec succès',
    'whitelistEntryExist': 'Entrée déjà dans la liste blanche',
    'reachedWhiteMax': "Nombre maximum d'entrées dans la liste blanche atteint",
    'whitelistAddError': "Échec de l'ajout à la liste blanche",
    'blacklistAddSuccess': 'Ajouté à la liste noire avec succès',
    'blacklistEntryExist': 'Entrée déjà dans la liste noire',
    'reachedBlackMax': "Nombre maximum d'entrées dans la liste noire atteint",
    'blacklistAddError': "Échec de l'ajout à la liste noire",
    'whitelistDelSuccess': 'Entrées de la liste blanche supprimées avec succès',
    'whitelistDelError': 'Échec de la suppression des entrées de la liste blanche',
    'blacklistDelSuccess': 'Entrées de la liste noire supprimées avec succès',
    'blacklistDelError': 'Échec de la suppression des entrées de la liste noire',
    'import': 'Importer',
    'fileButtonText': 'Cliquez pour télécharger',
    'fileSizeOverLimit': 'Le fichier téléchargé dépasse la limite de 2MB',
    'whitelistImportSuccess': 'Liste blanche importée avec succès, {0} entrées importées',
    'whitelistImportError': "Échec de l'importation de la liste blanche",
    'blacklistImportSuccess': 'Liste noire importée avec succès, {0} entrées importées',
    'blacklistImportError': "Échec de l'importation de la liste noire",
    'noValidEntry': 'Aucune entrée de domaine valide dans le fichier téléchargé',
    'zeroImported': "Échec de l'importation de toute entrée de domaine",
  },
  // defaultConfigLang
  'defaultConfigLang': {
    'browserCacheWarning':
      "Si les publicités ne peuvent pas être correctement filtrées lorsque le filtrage des publicités DNS est activé, vous devez vider le cache du navigateur et redémarrer l'appareil filtré.",
    'queriesFor24Hours': 'Requêtes des dernières 24 heures',
    'totalQuery': 'Requêtes totales',
    'blockedQuery': 'Requêtes bloquées',
    'blockedPercentage': 'Pourcentage de blocage',
    'ruleTotal': 'Règles',
    'adQuery': 'Publicités bloquées',
    'trackerQuery': 'Traqueurs bloqués',
    'maliciousQuery': 'Malveillants bloqués',
    'pornQuery': 'Pornographie bloquée',
    'legendTotal': 'Total',
    'legendBlocked': 'Bloqué',
    'categoryStateEnabled': 'activé',
    'categoryStateDisabled': 'désactivé',
    'categoryState2Hours': 'désactivé pour 2 heures',
    'categoryState4Hours': 'désactivé pour 4 heures',
    'categoryState8Hours': 'désactivé pour 8 heures',
    'setCategoryStatesErr': "Erreur lors de l'activation/désactivation du filtre",
    'enableAll': 'Activer tous les filtres',
    'disableAll': 'Désactiver tous les filtres',
  },
  // wifiSettings
  'wifiSettings': {
    'workMode': 'Mode de fonctionnement',
    'currentWorkMode': 'Mode de fonctionnement détecté',
    'wire-ap': 'WAN filaire',
    'wlan-ap': 'WAN sans fil',
    'notConnect': "Le réseau n'est pas connecté",
    'upstreamTitle': 'Paramètres amont',
    'downstreamTitle': 'LAN sans fil',
    'backHome': 'Retour au tableau de bord',
    'apConfigWarning':
      "Après avoir enregistré le nouveau SSID Wi-Fi et le mot de passe Wi-Fi, si vous souhaitez accéder à Internet via l'appareil Deeper, vous devez rescaner le hotspot de l'appareil Deeper et appliquer le nouveau mot de passe",
    'attention': 'Attention',
    'buttons': {
      'scanWifi': 'Scanner',
      'connectStatus': 'Statut',
      add: 'Ajouter',
      'save': 'enregistrer',
      'cancel': 'annuler',
      'downloadLog': 'Télécharger le journal',
    },
    'connect': 'connecter',
    'forget': 'oublier',
    'waiting': {
      'content': 'Connexion en cours, cela peut prendre un moment, veuillez patienter...',
      'cancelBtn': 'Annuler la connexion',
    },
    'modals': {
      'hiddenWifi': {
        'title': 'Ajouter un Wi-Fi caché',
        'okBtn': 'ajouter',
      },
      'scanWifi': {
        'title': 'Avertissement',
        'content': 'Le réseau sera déconnecté pendant un moment',
        'ok': 'OK',
        'cancel': 'Annuler',
      },
    },
    'label': {
      'ssid': 'SSID Wi-Fi',
      'password': 'Mot de passe Wi-Fi',
      'hidden': 'Cacher SSID',
      'onlyUse': 'Utiliser uniquement',
      'freqBand': 'Bande de fréquence',
    },
    'placeholder': {
      'ssid': 'veuillez entrer le SSID',
      'password': 'veuillez entrer le mot de passe',
    },
    'message': {
      'saveSuccess': 'enregistré avec succès',
      'saveError': "échec de l'enregistrement, veuillez réessayer",
      'connectSuccess': 'Connecté avec succès',
      'forgetSuccess': 'Oublié avec succès',
      'inputError': 'Veuillez vérifier votre saisie',
      'invalidWifiDev': 'Sans appareil Wi-Fi valide, les paramètres Wi-Fi ne peuvent pas être effectués',
      'illegalWifiDev': "La configuration Wi-Fi n'est disponible qu'en mode routeur AP et en mode relais sans fil",
      'invalidSsid': 'supporte uniquement les lettres, les chiffres, _ et -',
      'invalidPassword': "le mot de passe doit comporter au moins 8 caractères et ne peut pas contenir d'espaces",
      'notConnect': "Votre réseau n'est pas connecté",
      'retry': 'veuillez réessayer',
      'connectionError': 'Échec de la connexion',
      'forgetError': "Échec de l'oubli",
      'wifiNotSupported': 'Wi-Fi non supporté',
      'networkNotFound': 'Réseau introuvable',
      'pskNotGenerated': 'Clé non générée',
      'wrongPassword': 'Mauvais mot de passe',
      'timeout': 'Délai de connexion dépassé',
      'logDownloadError': 'Échec du téléchargement du journal',
      'hasBeenConnected': 'a été connecté.',
      'dhcpSuccess': 'Réussi',
      'dhcpFailure': 'Échoué',
    },
    'connectionRequestedTitle': 'Connexion demandée',
    'connectionRequestedContent':
      'Veuillez vérifier votre état de connexion sous l\'onglet "Statut". Si vous êtes déconnecté de Deeper Connect, veuillez essayer de vous reconnecter à DeeperWiFi.',
    'gotIpFromDhcpLabel': 'IP obtenue de DHCP',
  },
  // parentalControlData
  'parentalControlData': {
    'browserCacheWarning':
      "Si les sites pour adultes ou les réseaux sociaux ne peuvent pas être correctement bloqués lorsque les contrôles parentaux sont activés, vous devez vider le cache du navigateur et redémarrer l'appareil filtré.",
    'customerRule':
      "Ici, vous pouvez bloquer la grande majorité des sites pour adultes et des réseaux sociaux pour vos enfants. Si vous souhaitez bloquer d'autres sites, vous pouvez utiliser la fonction de blocage de domaine personnalisé pour ajouter plus de sites.",
    'customerRuleClickTips': 'Veuillez {0} pour accéder à la page de blocage de domaine personnalisé.',
    'toCustomerRuleButtonText': 'cliquez ici',
    'parentalControl': 'Contrôle parental',
    'pornQuery': 'Pornographie bloquée',
    'socialQuery': 'Réseaux sociaux bloqués',
    'legendBlocked': 'Bloqué',

    // social
    'socialStateEnabled': 'bloquer les réseaux sociaux',
    'socialStateDisabled': 'débloquer les réseaux sociaux',
    'socialState2Hours': 'débloquer les réseaux sociaux pour 2 heures',
    'socialState4Hours': 'débloquer les réseaux sociaux pour 4 heures',
    'socialState8Hours': 'débloquer les réseaux sociaux pour 8 heures',
    // porn
    'pornStateEnabled': 'bloquer la pornographie',
    'pornStateDisabled': 'débloquer la pornographie',
    'pornState2Hours': 'débloquer la pornographie pour 2 heures',
    'pornState4Hours': 'débloquer la pornographie pour 4 heures',
    'pornState8Hours': 'débloquer la pornographie pour 8 heures',
    'setCategoryStatesErr': "Erreur lors de l'activation/désactivation du filtre",
  },
  reboot: {
    success: {
      saveConfig: 'Enregistré avec succès',
    },
    failure: {
      getConfig: 'Échec de la récupération de la configuration de redémarrage automatique',
      saveConfig: "Échec de l'enregistrement de la configuration de redémarrage automatique",
    },
    buttons: {
      reboot: 'Cliquez pour redémarrer',
      save: 'Enregistrer',
    },
    week: {
      0: 'Tous les jours',
      1: 'Lundi',
      2: 'Mardi',
      3: 'Mercredi',
      4: 'Jeudi',
      5: 'Vendredi',
      6: 'Samedi',
      7: 'Dimanche',
    },
    title: {
      manual: 'Redémarrer maintenant',
      auto: 'Redémarrage programmé',
    },
    label: {
      period: 'Période',
      time: 'Heure',
    },
  },
};
