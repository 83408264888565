const key = `-----BEGIN RSA PRIVATE KEY-----
Proc-Type: 4,ENCRYPTED
DEK-Info: AES-256-CBC,4D1FCC3ED72C767A0040CEA44C9CE2F8

nLeRRSMOdOOMctZG09HC7twdOBHKy25/AQiLAXx9QKeRAW0UJU0z9vstBAiKstiK
HM3gJqrxeEVJVyeqO4xq0HEaku4B/7ypdewOebac5k7b6tV5ZFPKsdb3j2Xy+D8b
BLocKzfkXCYgTlAvn2OOK21XjwG1YHAHxMFH/CrREQUOsV5Zh5fIsK12tMM7Us7F
9fLu/2ROoNrfDWZePXbGmDbwKK3U9JL1zxo188X1reWgs34Emlz/U9uSrfxWGgMH
hdFkD3Y31DTmeY1nyEMdnv84deAFFJSf5Ox/HLjDzBAPE2J+ppYq0Ye+wJa6odvF
47hzJitJkTLBP1EzR+HKMqINJTnYfxkKN3xJU7bAbU+H9j/t/KoZ9oK+Tr5Na8nR
RBj6qgymFgDlwOn41aG3hHAV47v1CLJyXZf6ATIV85Q=
-----END RSA PRIVATE KEY-----`;

const passphrase = 'T/fBgCpn0+jQoPMUwf9J';

module.exports = {
  key,
  passphrase,
};
