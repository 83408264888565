import { SEARCH_LICENSE_SUCCESS } from '../actions/types';

const initState = {
  status: '', // 3中状态：试用、有效、过期
  validDate: '', // 过期时间：使用、有效有
  licenseKey: '',
};

const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SEARCH_LICENSE_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        expireDate: action.payload.expireDate,
        licenseKey: action.payload.licenseKey,
      };
    default:
      return state;
  }
};

export default { initState, reducer };
