export default {
  type: 'portugal',
  internationalize: {
    type: 'portugal',
    common: {
      tip_text: 'Dica',
      search: 'Pesquisar',
      add: 'Adicionar',
      delete: 'Excluir',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
      warning: 'Aviso',
      utcDisclaimer: 'Data é UTC',
      toOpen: 'Abrir',
    },
    certificateDialog: {
      title: 'Novo Certificado Root CA',
      content: 'O Certificado Root CA foi substituído. Por favor, faça o download novamente e instale.',
      dontShowAgain: 'Não mostrar novamente',
    },
    request_tip: {
      error: 'Erro na solicitação~',
    },
    header: {
      logout: 'Sair',
      changePassword: 'Mudar a senha',
      userManual: 'Manual do utilizador',
      defaultAvatar: 'Avatar predefinido',
      'english': 'Inglês',
      'german': 'Alemão',
      'korean': 'Coreano',
      'french': 'Francês',
      'spain': 'Espanhol',
      'portugal': 'Português',
      'japanese': 'Japonês',
      'Tchinese': 'Chinês tradicional',
      'russian': 'Russo',
      'hindi': 'Hindi',
      'simplechinese': 'Chinês simplificado',
    },
    home: {
      name: 'Página inicial',
      children: {},
      content: {
        tipInput: 'Por favor, digite',
        ok: 'Confirmar',
        cancel: 'Cancelar',
        currentStatus: 'Status atual',
        statusMap: {
          Trial: 'Teste',
          Valid: 'Válido',
          Expired: 'Expirado',
        },
        btnActive: 'Ativar',
        btnEdit: 'Editar',
        expireDate: 'Data de expiração',
        licenseKey: 'Número da licença',
      },
    },
    accessControl: {
      name: 'Controle de acesso',
    },
    tpProxy: {
      name: 'Proxy transparente',
      children: {
        customRules: {
          name: 'Regras personalizadas',
        },
        builtinRules: {
          name: 'Filtro de anúncios',
        },
        certificate: {
          name: 'Instalar certificado',
        },
      },
    },
    help: {
      name: 'Ajuda',
      children: {
        techSupport: {
          name: 'Suporte técnico',
        },
      },
    },
    device: {
      name: 'Relacionado ao dispositivo',
      children: {
        system: {
          name: 'Informações do sistema',
        },
        traffic: {
          name: 'Tráfego',
        },
        notification: {
          name: 'Registro',
        },
      },
      content: {},
    },
    dpn: {
      name: 'DPN',
      content: {},
      children: {
        smartRoute: {
          name: 'Modo',
        },
        tunnel: {
          name: 'Túnel',
        },
        routingTable: {
          name: 'Tabela de roteamento inteligente',
          content: {},
          children: {
            domainConfig: {
              name: 'Domínio personalizado',
            },
            ipConfig: {
              name: 'IP personalizado',
            },
            queryRouting: {
              name: 'Consulta',
            },
          },
        },
      },
    },
    appRelocator: {
      name: 'Transferência de Aplicações',
    },
    adsBlock: {
      name: 'Bloqueio de anúncios',
      children: {
        domainFilter: {
          name: 'Filtro DNS',
        },
        httpsFilter: {
          name: 'Filtro HTTPS',
        },
      },
      httpssucceed: 'A filtragem HTTPS foi ativada com sucesso',
      httpslose: 'A filtragem HTTPS foi desativada com sucesso',
      saverror: 'Operação falhou',
      usedByAccessControl: 'O filtro HTTPS é usado pelo Controle de Acesso',
    },
    dpr: {
      name: 'Mineração',
      children: {
        dpr: {
          name: 'DPR',
          children: {
            stakingTutorial: {
              name: 'Tutorial de Staking',
            },
            wallet: {
              name: 'Carteira',
              manager: 'Gerenciamento de Carteira',
              balance: 'Saldo da Carteira',
            },
            'Staking Mining': 'Mineração de Staking',
            transaction: {
              id: 'ID da Transação',
              from: 'Endereço de Remetente',
              to: 'Endereço de Destinatário',
              time: 'Horário da Transferência (UTC)',
              rewardTime: 'Horário da Recompensa (UTC)',
              amount: 'Quantidade de DPR',
              'Reward in selected period': 'Recompensa no período selecionado',
              'Total reward': 'Recompensa Total',
              'Balance Transfer': 'Transferência de Saldo',
              'Staking Reward': 'Recompensa de Staking',
              'PoS Reward': 'Recompensa de PoS',
              'Micro Payment': 'Micro Pagamento',
              stakeRelease: 'Liberação de Staking',
              Transaction: 'Registro de Transações',
              Mining: 'Mineração',
              'Traffic Mining': 'Mineração de Tráfego',
              'Staking Mining': 'Mineração de Staking',
            },
          },
        },
      },
    },
    security: {
      name: 'Segurança',
      children: {
        applications: 'Controle de Aplicações',
        traffic: 'Controle de Tráfego',
        webfilter: {
          name: 'Filtro da Web',
          children: {
            report: {
              name: 'Configuração Padrão',
            },
            config: {
              name: 'Filtro de Anúncios DNS',
            },
          },
        },
        sslFilter: {
          name: 'Filtro SSL',
          children: {
            customDNSrules: {
              name: 'Regras de DNS Personalizadas',
            },
            customHTTPSRules: {
              name: 'Regras de HTTPS Personalizadas',
            },
          },
        },
      },
      content: {},
    },
    parentalControl: {
      name: 'Controle Parental',
    },
    regionCountryTexts: {
      'LL': 'Não utilizar túnel',
      'ANY': 'Qualquer país ou região',
      // regions
      'AMER': '---América---',
      'ASIA': '---Ásia---',
      'AFRI': '---África---',
      'EURO': '---Europa---',
      'OCEA': '---Oceania---',
      'AMN': 'América do Norte',
      'AMC': 'Caribe',
      'AMM': 'América Central',
      'AMS': 'América do Sul',
      'ASC': 'Ásia Central',
      'ASE': 'Ásia Oriental',
      'ASW': 'Ásia Ocidental',
      'ASS': 'Ásia do Sul',
      'ASD': 'Sudeste Asiático',
      'AFN': 'Norte da África',
      'AFM': 'África Central',
      'AFE': 'África Oriental',
      'AFW': 'África Ocidental',
      'AFS': 'África do Sul',
      'EUN': 'Norte da Europa',
      'EUE': 'Leste da Europa',
      'EUW': 'Oeste da Europa',
      'EUS': 'Sul da Europa',
      'OCP': 'Polinésia',
      'OCA': 'Austrália e Nova Zelândia',
      'OCM': 'Melanésia',
      'OCN': 'Micronésia',
      // countries
      'AD': 'Andorra',
      'AE': 'Emirados Árabes Unidos',
      'AF': 'Afeganistão',
      'AG': 'Antígua e Barbuda',
      'AI': 'Anguilla',
      'AL': 'Albânia',
      'AM': 'Armênia',
      'AO': 'Angola',
      'AR': 'Argentina',
      'AS': 'Samoa Americana',
      'AT': 'Áustria',
      'AU': 'Austrália',
      'AW': 'Aruba',
      'AX': 'Ilhas Åland',
      'AZ': 'Azerbaijão',
      'BA': 'Bósnia e Herzegovina',
      'BB': 'Barbados',
      'BD': 'Bangladesh',
      'BE': 'Bélgica',
      'BF': 'Burquina Faso',
      'BG': 'Bulgária',
      'BH': 'Bahrein',
      'BI': 'Burundi',
      'BJ': 'Benin',
      'BL': 'São Bartolomeu',
      'BM': 'Bermudas',
      'BN': 'Brunei',
      'BO': 'Bolívia',
      'BQ': 'Caribe Neerlandês',
      'BR': 'Brasil',
      'BS': 'Bahamas',
      'BT': 'Butão',
      'BW': 'Botsuana',
      'BY': 'Bielorrússia',
      'BZ': 'Belize',
      'CA': 'Canadá',
      'CC': 'Ilhas Cocos (Keeling)',
      'CD': 'Congo-Kinshasa',
      'CF': 'República Centro-Africana',
      'CG': 'Congo-Brazzaville',
      'CH': 'Suíça',
      'CI': 'Costa do Marfim',
      'CK': 'Ilhas Cook',
      'CL': 'Chile',
      'CM': 'Camarões',
      'CN': 'China',
      'CO': 'Colômbia',
      'CR': 'Costa Rica',
      'CU': 'Cuba',
      'CV': 'Cabo Verde',
      'CW': 'Curaçao',
      'CX': 'Ilha Christmas',
      'CY': 'Chipre',
      'CZ': 'República Tcheca',
      'DE': 'Alemanha',
      'DJ': 'Djibuti',
      'DK': 'Dinamarca',
      'DM': 'Dominica',
      'DO': 'República Dominicana',
      'DZ': 'Argélia',
      'EC': 'Equador',
      'EE': 'Estônia',
      'EG': 'Egito',
      'ER': 'Eritreia',
      'ES': 'Espanha',
      'ET': 'Etiópia',
      'FI': 'Finlândia',
      'FJ': 'Fiji',
      'FK': 'Ilhas Malvinas (Falkland)',
      'FM': 'Micronésia',
      'FO': 'Ilhas Faroe',
      'FR': 'França',
      'GA': 'Gabão',
      'GB': 'Reino Unido',
      'GD': 'Granada',
      'GE': 'Geórgia',
      'GF': 'Guiana Francesa',
      'GG': 'Guernsey',
      'GH': 'Gana',
      'GI': 'Gibraltar',
      'GL': 'Groenlândia',
      'GM': 'Gâmbia',
      'GN': 'Guiné',
      'GP': 'Guadalupe',
      'GQ': 'Guiné Equatorial',
      'GR': 'Grécia',
      'GS': 'Ilhas Geórgia do Sul e Sandwich do Sul',
      'GT': 'Guatemala',
      'GU': 'Guam',
      'GW': 'Guiné-Bissau',
      'GY': 'Guiana',
      'HK': 'Hong Kong, RAE da China',
      'HN': 'Honduras',
      'HR': 'Croácia',
      'HT': 'Haiti',
      'HU': 'Hungria',
      'ID': 'Indonésia',
      'IE': 'Irlanda',
      'IL': 'Israel',
      'IM': 'Ilha de Man',
      'IN': 'Índia',
      'IO': 'Território Britânico do Oceano Índico',
      'IQ': 'Iraque',
      'IR': 'Irã',
      'IS': 'Islândia',
      'IT': 'Itália',
      'JE': 'Jersey',
      'JM': 'Jamaica',
      'JO': 'Jordânia',
      'JP': 'Japão',
      'KE': 'Quênia',
      'KG': 'Quirguistão',
      'KH': 'Camboja',
      'KI': 'Kiribati',
      'KM': 'Comores',
      'KN': 'São Cristóvão e Nevis',
      'KR': 'Coreia do Sul',
      'KW': 'Kuwait',
      'KY': 'Ilhas Cayman',
      'KZ': 'Cazaquistão',
      'KP': 'Coreia do Norte',
      'LA': 'Laos',
      'LB': 'Líbano',
      'LC': 'Santa Lúcia',
      'LI': 'Liechtenstein',
      'LK': 'Sri Lanka',
      'LR': 'Libéria',
      'LS': 'Lesoto',
      'LT': 'Lituânia',
      'LU': 'Luxemburgo',
      'LV': 'Letônia',
      'LY': 'Líbia',
      'MA': 'Marrocos',
      'MC': 'Mônaco',
      'MD': 'Moldávia',
      'ME': 'Montenegro',
      'MF': 'São Martinho (parte francesa)',
      'MG': 'Madagascar',
      'MH': 'Ilhas Marshall',
      'MK': 'Macedônia do Norte',
      'ML': 'Mali',
      'MM': 'Mianmar',
      'MN': 'Mongólia',
      'MO': 'Macau',
      'MP': 'Ilhas Marianas do Norte',
      'MQ': 'Martinica',
      'MR': 'Mauritânia',
      'MS': 'Montserrat',
      'MT': 'Malta',
      'MU': 'Maurício',
      'MV': 'Maldivas',
      'MW': 'Malawi',
      'MX': 'México',
      'MY': 'Malásia',
      'MZ': 'Moçambique',
      'NA': 'Namíbia',
      'NC': 'Nova Caledônia',
      'NE': 'Níger',
      'NF': 'Ilha Norfolk',
      'NG': 'Nigéria',
      'NI': 'Nicarágua',
      'NL': 'Países Baixos',
      'NO': 'Noruega',
      'NP': 'Nepal',
      'NR': 'Nauru',
      'NU': 'Niue',
      'NZ': 'Nova Zelândia',
      'OM': 'Omã',
      'PA': 'Panamá',
      'PE': 'Peru',
      'PF': 'Polinésia Francesa',
      'PG': 'Papua-Nova Guiné',
      'PH': 'Filipinas',
      'PK': 'Paquistão',
      'PL': 'Polônia',
      'PM': 'Saint Pierre e Miquelon',
      'PN': 'Ilhas Pitcairn',
      'PR': 'Porto Rico',
      'PS': 'Territórios Palestinos',
      'PT': 'Portugal',
      'PW': 'Palau',
      'PY': 'Paraguai',
      'QA': 'Catar',
      'RE': 'Reunião',
      'RO': 'Romênia',
      'RS': 'Sérvia',
      'RU': 'Rússia',
      'RW': 'Ruanda',
      'SA': 'Arábia Saudita',
      'SB': 'Ilhas Salomão',
      'SC': 'Seychelles',
      'SD': 'Sudão',
      'SE': 'Suécia',
      'SG': 'Singapura',
      'SH': 'Santa Helena, Ascensão e Tristão da Cunha',
      'SI': 'Eslovênia',
      'SJ': 'Svalbard e Jan Mayen',
      'SK': 'Eslováquia',
      'SL': 'Serra Leoa',
      'SM': 'San Marino',
      'SN': 'Senegal',
      'SO': 'Somália',
      'SR': 'Suriname',
      'SS': 'Sudão do Sul',
      'ST': 'São Tomé e Príncipe',
      'SV': 'El Salvador',
      'SX': 'Sint Maarten',
      'SY': 'Síria',
      'SZ': 'Suazilândia',
      'TC': 'Ilhas Turks e Caicos',
      'TD': 'Chade',
      'TF': 'Terras Austrais e Antárticas Francesas',
      'TG': 'Togo',
      'TH': 'Tailândia',
      'TJ': 'Tajiquistão',
      'TK': 'Tokelau',
      'TL': 'Timor-Leste',
      'TM': 'Turcomenistão',
      'TN': 'Tunísia',
      'TO': 'Tonga',
      'TR': 'Turquia',
      'TT': 'Trinidad e Tobago',
      'TV': 'Tuvalu',
      'TW': 'Taiwan',
      'TZ': 'Tanzânia',
      'UA': 'Ucrânia',
      'UB': 'Oeste dos EUA',
      'UC': 'Centro-Oeste dos EUA',
      'UD': 'Sudoeste dos EUA',
      'UE': 'Nordeste dos EUA',
      'UF': 'Sudeste dos EUA',
      'UG': 'Uganda',
      'US': 'Estados Unidos',
      'UY': 'Uruguai',
      'UZ': 'Uzbequistão',
      'VA': 'Vaticano',
      'VC': 'São Vicente e Granadinas',
      'VE': 'Venezuela',
      'VG': 'Ilhas Virgens Britânicas',
      'VI': 'Ilhas Virgens Americanas',
      'VN': 'Vietnã',
      'VU': 'Vanuatu',
      'WF': 'Wallis e Futuna',
      'WS': 'Samoa',
      'XK': 'Kosovo',
      'YE': 'Iêmen',
      'YT': 'Mayotte',
      'ZA': 'África do Sul',
      'ZM': 'Zâmbia',
      'ZW': 'Zimbábue',
    },
    setting: {
      'name': 'Configurações',
      'children': {
        'wifi': {
          'name': 'Wi-Fi relacionado',
        },
        'sharing': {
          'name': 'Compartilhamento relacionado',
          'children': {
            'configure': {
              'name': 'Configuração de compartilhamento',
            },
            'blacklist': {
              'name': 'Compartilhando lista negra',
            },
            'record': {
              'name': 'Registro de compartilhamento',
            },
            'traffic': {
              'name': 'Tráfego de compartilhamento',
            },
          },
          'content': {},
        },
        'rustdesk': {
          'name': 'RustDesk',
          'children': {
            'config': {
              'name': 'Configuração',
            },
            'log': {
              'name': 'Registro',
            },
          },
        },
        'admin': {
          'name': 'Gerenciamento de conta',
          'content': {},
          'children': {
            'changePassword': {
              'name': 'Senha',
            },
            'reboot': {
              'name': 'Reiniciar',
              'content': {
                'confirmText': 'Confirmar reinicialização?',
                'rebootLoading':
                  'O dispositivo precisará reiniciar por alguns minutos. Sua conexão de rede será temporariamente interrompida...',
              },
            },
          },
        },
      },
    },
  },
  // dashboard
  dashboard: {
    name: 'Página inicial',
    children: {},
    content: {
      backupTunnelLabel: 'Túnel de backup',
      tipInput: 'Digite aqui',
      hotspot: 'Conexão global',
      realnetworkflow: 'Estatísticas de tráfego em tempo real',
      memory: 'Uso de memória',
      cpu: 'Uso da CPU',
      notification: 'Notificação do sistema',
      systeminfo: 'Informações do sistema',
      upload: 'Upload',
      download: 'Download',
      systemuptime: 'Tempo de atividade do sistema',
      uploadtotal: 'Total de upload',
      downloadtotal: 'Total de download',
      softwareVersion: 'Versão do software',
      publicIp: 'IP público',
      unit: 'Unidade',
      currentDpnMode: 'Modo DPN atual',
      ok: 'Confirmar',
      cancel: 'Cancelar',
      currentStatus: 'Status atual',
      statusMap: {
        Trial: 'Teste',
        Valid: 'Válido',
        Expired: 'Expirado',
      },
      btnActive: 'Ativar',
      btnEdit: 'Editar',
      expireDate: 'Data de expiração',
      licenseKey: 'Número da licença',
      systemTraffic: 'Estatísticas de tráfego do sistema em tempo real',
      appTraffic: 'Estatísticas de tráfego do aplicativo em tempo real',
    },
  },
  accessControl: {
    'content': {
      banner: {
        title: 'Controle de acesso',
        description:
          'Esta chave é usada para controlar uniformemente a ativação ou desativação de várias funções do dispositivo LAN.',
      },
      'onlineDevice': 'Dispositivo online',
      'offlineDevice': 'Dispositivo offline',
      'deviceName': 'Nome do dispositivo',
      unknownDevice: 'Desconhecido',
      'macAddress': 'Endereço MAC',
      'ipAddress': 'Endereço IP',
      'enableHttpsFilter': 'Ativar filtro HTTPS',
      'routeMode': 'Modo DPN',
      'routeModeOptions': {
        'smart': 'Modo de roteamento inteligente',
        'full': 'Roteamento completo',
        'disabled': 'Desativado',
        blackHole: 'Buraco negro',
      },
      'remark': 'Observação',
      pinned: 'Fixar no topo',
      'httpsFilterTip': 'Após ativar o filtro HTTPS, todos os anúncios serão bloqueados.',
      'routeModeTip': 'Essa opção permite alterar o modo de operação do DPN.',
      'tunnelTip': 'Selecione um túnel de roteamento completo.',
      'success': {
        getList: 'Lista de dispositivos atualizada com sucesso',
        'httpsFilter': 'Filtro HTTPS ativado com sucesso',
        'routeMode': 'Modo DPN alterado com sucesso',
        'regionCode': 'Modo DPN alterado com sucesso',
        'remark': 'Observação salva com sucesso',
        pinned: 'Fixado / Desafixado com sucesso. Por favor, atualize',
        'deleteDevices': 'Exclusão bem-sucedida do dispositivo',
      },
      'failure': {
        'getSwitch': 'Falha ao obter o interruptor de controle de acesso',
        'setSwitch': 'Falha ao configurar o interruptor de controle de acesso',
        'getList': 'Falha ao obter a lista de controle de acesso',
        'getTunnels': 'Falha ao obter os túneis',
        'setItem': 'Falha ao definir o item de controle de acesso',
        'httpsFilter': 'Falha ao ativar o filtro HTTPS',
        'routeMode': 'Falha ao alterar o modo DPN',
        'regionCode': 'Falha ao alterar o modo DPN',
        'remark': 'Falha ao salvar a observação',
        'noSuchTunnel': 'O túnel não existe, escolha outro túnel',
        pinned: 'Falha ao fixar / desafixar',
        'deleteDevices': 'Falha ao excluir o dispositivo',
        'macActive': 'Não é possível excluir pois o dispositivo está ativo',
      },
      'remarkLengthLimit': 'O comprimento não deve exceder 10 caracteres',
      'httpsNotEnabled':
        'O interruptor principal do filtro HTTPS está desligado. Clique aqui para acessar esta página e ligá-lo!',
      'tips': {
        'opened':
          'Quando o controle de acesso está ativado, os dispositivos online usam preferencialmente o modo DPN no controle de acesso.',
      },
    },
  },
  //dpnMode
  dpnMode: {
    title: 'Selecionar modo DPN',
    selectTunnel: 'Selecionar túnel',
    recommended: 'Recomendado',
    apply: 'Aplicar',
    missingInfo: 'Informações faltando',
    selectionTip: {
      smart: 'Tem certeza de que deseja mudar para o modo de roteamento inteligente?',
      full:
        'Todo o tráfego será roteado pelo túnel selecionado. Tem certeza de que deseja mudar para o modo de roteamento completo?',
      disabled: 'Este modo desativará temporariamente a rede DPN. Tem certeza de que deseja desativar o DPN?',
    },
    dpnModeShortList: {
      smart: 'Roteamento inteligente DPN',
      disabled: 'DPN desativado',
    },
    dpnModeLongList: {
      smart: 'Roteamento inteligente',
      full: 'Roteamento completo',
      disabled: 'Desativado',
    },
    killSwitchLabel: 'Interruptor de desativação',
    none: 'Desligado',
    backupTunnelLabel: 'Túnel de backup',
    backupTunnelTip:
      'Quando ocorrerem problemas com o túnel configurado no modo de roteamento inteligente DPN, o sistema mudará automaticamente para o túnel de backup até que o túnel padrão seja restaurado.',
    tips: {
      killSwitch:
        'A proteção contra desconexão VPN é um recurso que desconecta automaticamente seu dispositivo, como computador, smartphone ou tablet, da Internet quando sua conexão VPN é interrompida. Isso significa que, se sua VPN parar de funcionar, seu endereço IP e outras informações sensíveis não serão expostos. Portanto, a segurança e o anonimato de sua conexão com a Internet não serão comprometidos.',
      smart: [
        '1. Com base nas regras incorporadas no AtomOS, os nós inteligentes são selecionados para os sites que você está acessando no momento, garantindo velocidade de acesso local e acelerando o acesso a serviços em outros países/regiões.',
        '2. No modo de roteamento inteligente, você não precisa se preocupar em trocar constantemente de país como nos VPNs tradicionais, e ainda terá uma experiência de acesso à Internet mais rápida.',
        '3. O Deeper Connect possui regras de roteamento incorporadas para alguns sites e serviços conhecidos. Se você deseja personalizar as regras de roteamento inteligente, pode fazer isso nas opções "Redirecionamento de aplicativos" e "Tabela de roteamento DPN".',
        '4. Continuaremos adicionando mais regras de roteamento incorporadas com base no feedback dos usuários e oferecendo suporte a mais aplicativos na opção "Redirecionamento de aplicativos".',
      ],
      full: [
        '1. Todo o tráfego de rede processado pelo AtomOS será direcionado através do túnel especificado, semelhante ao uso tradicional de VPNs.',
        '2. Observação: ao usar o modo de roteamento completo, as configurações em "Redirecionamento de aplicativos" e "Tabela de roteamento DPN" serão substituídas.',
        '3. Devido à natureza da rede descentralizada, os nós na rede são dispositivos de usuários em todo o mundo, não servidores de alta velocidade. Portanto, sua velocidade de navegação na Internet pode ser afetada.',
        '4. Se você deseja usar o modo de roteamento completo e ter uma experiência de navegação rápida, consideraremos fornecer nós de alta velocidade específicos para usuários VIP no futuro.',
      ],
      disabled: [
        '1. O modo "DPN desativado" desativará a funcionalidade DPN, fazendo com que todo o tráfego de rede processado pelo AtomOS seja transmitido através do tráfego local.',
        '2. Outros recursos de segurança de rede do Deeper Connect continuarão ativados.',
        '3. Você pode desativar o DPN temporariamente para verificar se um determinado site está com problemas de acesso ou se o problema está relacionado à funcionalidade DPN.',
        '4. Se você encontrar qualquer problema com a funcionalidade DPN, sinta-se à vontade para relatar o problema através do seguinte link: https://support.deeper.network/hc/en-us/requests/new',
      ],
    },
    success: 'Operação bem-sucedida',
    failure: 'Falha na operação!',
  },
  // DefaultadBlock
  DefaultadBlock: {
    browserCacheWarning:
      'Se você ativou o bloqueio de anúncios DNS e não consegue filtrar os anúncios corretamente, limpe o cache do navegador e reinicie o dispositivo que está sendo filtrado.',
    queriesFor24Hours: 'Consultas nas últimas 24 horas',
    totalQuery: 'Total de consultas',
    blockedQuery: 'Consultas bloqueadas',
    blockedPercentage: 'Porcentagem de bloqueio',
    ruleTotal: 'Total de regras',
    adQuery: 'Anúncios bloqueados',
    trackerQuery: 'Rastreadores bloqueados',
    maliciousQuery: 'Consultas maliciosas bloqueadas',
    pornQuery: 'Conteúdo adulto bloqueado',
    legendTotal: 'Total',
    legendBlocked: 'Bloqueado',
    categoryStateEnabled: 'Ativado',
    categoryStateDisabled: 'Desativado',
    categoryState2Hours: 'Desativado por 2 horas',
    categoryState4Hours: 'Desativado por 4 horas',
    categoryState8Hours: 'Desativado por 8 horas',
    setCategoryStatesErr: 'Erro ao definir estados de filtragem',
    enableAll: 'Ativar todas as filtragens',
    disableAll: 'Desativar todas as filtragens',
  },
  // CustomadBlock
  CustomadBlock: {
    missingInfoWarning: 'Informações em falta',
    invalidDomainError: 'Formato de domínio inválido ou domínio muito longo',
    whitelist: 'Lista branca',
    blacklist: 'Lista negra',
    domain: 'Domínio',
    whitelistAddSuccess: 'Adicionado à lista branca com sucesso',
    whitelistEntryExist: 'Entrada já existente na lista branca',
    reachedWhiteMax: 'Limite máximo da lista branca atingido',
    whitelistAddError: 'Falha ao adicionar entrada à lista branca',
    blacklistAddSuccess: 'Adicionado à lista negra com sucesso',
    blacklistEntryExist: 'Entrada já existente na lista negra',
    reachedBlackMax: 'Limite máximo da lista negra atingido',
    blacklistAddError: 'Falha ao adicionar entrada à lista negra',
    whitelistDelSuccess: 'Entrada da lista branca excluída com sucesso',
    whitelistDelError: 'Falha ao excluir entrada da lista branca',
    blacklistDelSuccess: 'Entrada da lista negra excluída com sucesso',
    blacklistDelError: 'Falha ao excluir entrada da lista negra',
    import: 'Importar',
    fileButtonText: 'Clique para fazer upload',
    fileSizeOverLimit: 'O arquivo enviado excede o tamanho máximo de 2MB',
    whitelistImportSuccess: 'Lista branca importada com sucesso, {0} entradas importadas',
    whitelistImportError: 'Falha ao importar lista branca',
    blacklistImportSuccess: 'Lista negra importada com sucesso, {0} entradas importadas',
    blacklistImportError: 'Falha ao importar lista negra',
    noValidEntry: 'O arquivo enviado não contém nenhum domínio válido',
    zeroImported: 'Nenhuma entrada foi importada',
  },
  // DohGuideComp
  DohGuideComp: {
    closeButton: 'Fechar',
    guideButton: 'Clique para visualizar',
    dohMessageClickTips:
      'Clique em "Visualizar" para ver as instruções específicas para desativar o DoH no seu navegador',
    dohMessageTxt:
      'Para usar esse recurso, {0}. O DoH criptografa as solicitações DNS, o que pode interferir em algumas funcionalidades.',
    dohMessage0: 'Desative a configuração DoH no seu navegador',
    guideBoxTitle: 'Configurações de DoH do navegador',
    guide: {
      chromeGuideImg: '/dohGuide/chrome-guide-pt.png',
      chrome: [
        {
          label: 'Passo 1',
          tip: 'Digite chrome://settings/security na barra de endereço e pressione Enter.',
        },
        {
          label: 'Passo 2',
          tip: 'Localize a seção "Configurações de segurança avançadas".',
        },
        {
          label: 'Passo 3',
          tip: 'Desative a opção "Usar DNS seguro".',
        },
      ],
      firefox: [
        {
          label: 'Passo 1',
          tip: 'Digite about:config na barra de endereço e pressione Enter.',
        },
        {
          label: 'Passo 2',
          tip: 'Se uma página de aviso for exibida, clique em "Aceitar o risco e continuar".',
        },
        {
          label: 'Passo 3',
          tip: 'Digite network.trr.mode na caixa de pesquisa e pressione Enter.',
        },
        {
          label: 'Passo 4',
          tip:
            'Dê um duplo clique na opção encontrada e altere o valor para 5 (desativar DoH), em seguida, clique em "OK".',
        },
      ],
      edge: [
        {
          label: 'Passo 1',
          tip: 'Digite edge://settings/privacy na barra de endereço e pressione Enter, e localize a seção "Segurança".',
        },
        {
          label: 'Passo 2',
          tip: 'Desative a opção "Usar DNS seguro para ajudar a proteger a rede".',
        },
      ],
      operaGuideImg: '/dohGuide/opera-guide-pt.png',
      opera: [
        {
          label: 'Passo 1',
          tip: 'Digite opera://settings/?search=DNS na barra de endereço do navegador e pressione Enter.',
        },
        {
          label: 'Passo 2',
          tip:
            'Localize a opção "Usar DNS-over-HTTPS em vez das configurações de DNS do sistema" e verifique se ela está desativada.',
        },
      ],
    },
  },
  // appRelocatorData
  appRelocatorData: {
    guide: {
      youtube: {
        UA: 'https://www.deeper.network/pages/app-relocator?app=youtube&lang=zh',
      },
      nbaLeaguePass: 'https://www.deeper.network/pages/app-relocator?app=nbaLeaguePass&lang=zh',
      netflix: 'https://www.deeper.network/pages/app-relocator?app=netflix&lang=zh',
      dazn: 'https://www.deeper.network/pages/app-relocator?app=dazn&lang=zh',
      fubo: 'https://www.deeper.network/pages/app-relocator?app=fubo&lang=zh',
      tencentVideo: 'https://www.deeper.network/pages/app-relocator?app=tencentVideo&lang=zh',
    },
    countryLabel: 'País',
    tagLabel: 'Etiqueta',
    typeLabel: 'Tipo',
    country: {
      'all-countries': 'Todo o mundo',
      CN: 'China',
      US: 'Estados Unidos',
      IN: 'Índia',
      CA: 'Canadá',
      GB: 'Reino Unido',
      NA: 'Outros',
      NO: 'Noruega',
      SG: 'Singapura',
      JP: 'Japão',
    },
    tag: {
      'all-tags': 'Todas as tags',
      nba: 'NBA',
      nfl: 'NFL',
      nhl: 'NHL',
      mlb: 'MLB',
      epl: 'EPL',
      ipl: 'IPL',
    },
    category: {
      'all-category': 'Todas as categorias',
      video: 'Vídeo',
      music: 'Música',
      im: 'Mensagens instantâneas',
      flightAndHotel: 'Voos e hotéis',
      subscription: 'Assinatura',
    },
    filter: 'Filtrar',
    reset: 'Redefinir',
    submit: 'Concluir',
    subcategory: {
      popular: 'Popular',
      sport: 'Esporte',
      movie: 'Filme',
      tv: 'TV',
    },
    app: {
      acornTv: 'Acorn TV',
      amazonPrimeVideo: 'Amazon Prime Video',
      beInSports: 'BeIN Sports',
      crunchyroll: 'Crunchyroll',
      dazn: 'DAZN',
      disneyPlus: 'Disney+',
      netflix: 'Netflix',
      plex: 'Plex',
      roku: 'Roku',
      shudder: 'Shudder',
      zattoo: 'Zattoo',
      discoveryPlus: 'Discovery+',
      paramountPlus: 'Paramount+',
      sportsnetNow: 'Sportsnet Now',
      tsn: 'TSN',
      bbcIPlayer: 'BBC iPlayer',
      hulu: 'Hulu',
      itv: 'ITV',
      slingTv: 'Sling TV',
      crackle: 'Crackle',
      pandora: 'Pandora',
      theCw: 'The CW',
      abc: 'ABC',
      nbc: 'NBC',
      pluto: 'Pluto TV',
      starz: 'Starz',
      tbs: 'TBS',
      attTv: 'AT&T TV',
      channel4: 'Channel 4',
      showtimeAnytime: 'Showtime Anytime',
      nbcSports: 'NBC Sports',
      epixNow: 'Epix Now',
      mgmPlus: 'MGM+',
      xumo: 'Xumo',
      channel5: 'Channel 5',
      usaNetwork: 'USA Network',
      fxNow: 'FX Now',
      vudu: 'Vudu',
      fubo: 'Fubo TV',
      espn: 'ESPN',
      tubiTv: 'Tubi TV',
      ukTvPlay: 'UKTV Play',
      tvPlayer: 'TVPlayer',
      peacock: 'Peacock',
      pbs: 'PBS',
      philo: 'Philo',
      vrv: 'VRV',
      showmax: 'Showmax',
      hboMax: 'HBO Max',
      nhl: 'NHL',
      dsTv: 'DStv',
      sSportsPlus: 'S Sports+',
      filmrise: 'FilmRise',
      cbsSports: 'CBS Sports',
      nowTV: 'Now TV',
      molotovTv: 'Molotov TV',
      mlb: 'MLB',
      britBox: 'BritBox',
      stirr: 'Stirr',
      skyGo: 'Sky Go',
      hotstar: 'Disney+ Hotstar',
      sonyLiv: 'SonyLIV',
      nbaLeaguePass: 'NBA League Pass',
      amcPlus: 'AMC+',
      f1Tv: 'F1 TV',
      frndlyTv: 'Frndly TV',
      myTf1: 'MyTF1',
      qqMusic: 'QQ Music',
      spotify: 'Spotify',
      tidal: 'Tidal',
      youtube: 'YouTube',
      youTubeMusic: 'YouTube Music',
      weChat: 'WeChat',
      whatsApp: 'WhatsApp',
      line: 'Line',
      signal: 'Signal',
      agoda: 'Agoda',
      expedia: 'Expedia',
      flyScanner: 'Fly Scanner',
      googleFlights: 'Google Flights',
      qqSports: 'QQ Sports',
      skySports: 'Sky Sports',
      rakutenTv: 'Rakuten TV',
      fox: 'FOX',
      nflNetwork: 'NFL Network',
      peacocktv: 'Peacock TV',
      zee5: 'Zee5',
      mxPlayer: 'MX Player',
      tencentVideo: 'Tencent Video',
      iqiyi: 'iQiyi',
      mangoTV: 'Mango TV',
      migu: 'Migu Video',
      bilibili: 'Bilibili',
      youku: 'Youku',
      altBalaji: 'ALT Balaji',
      willowTv: 'Willow TV',
      foxSports: 'FOX Sports',
      crave: 'Crave',
      cbcGem: 'CBC Gem',
      jiocinema: 'JioCinema',
      Telegram: 'Telegram',
      Messenger: 'Messenger',
      ChatGPT: 'ChatGPT',
      CanalPlus: 'CanalPlus',
    },
    tip: {
      video: 'Túnel de serviço de vídeo comumente usado atualmente',
      music: 'Túnel de serviço de música comumente usado atualmente',
      im: 'Túnel de serviço de mensagens instantâneas comumente usado atualmente',
      flightAndHotel: 'Túnel de serviço de reserva de voos e hotéis comumente usado atualmente',
      subscription: 'Túnel de serviço de assinatura online comumente usado atualmente',
      backupTunnel:
        'O túnel de backup está ativado. Se nenhum nó disponível for encontrado para o túnel adicionado no modo de roteamento inteligente, o sistema usará o túnel de backup por padrão - {0}',
      fullTunnel: 'No modo de roteamento completo, todos os aplicativos usarão por padrão o túnel {0}.',
      noTunnelNodes:
        'Nenhum nó disponível para o túnel. Vá para a página de túneis e atualize os nós para o túnel {0}.',
      noTunnelNodesOkText: 'Atualizar',
      noTunnelNodesCancelText: 'Fechar',
      noTunnelAdded:
        'Você ainda não adicionou este túnel. Vá para a página de túneis e adicione o túnel {0} para usá-lo.',
      noTunnelAddedOkText: 'Adicionar',
      noTunnelAddedCancelText: 'Cancelar',
      tunnelDeletedText: 'O túnel {0} - {1} foi excluído',
      netflix: 'Resolva problemas de reprodução ao usar o Netflix',
      fubo: 'Assista aos jogos de futebol da Premier League a um custo muito baixo com o Fubo TV',
      tencentVideo: 'Assista a um jogo da NBA por apenas $1',
      dazn: 'Assista à NFL, NFL, MLB, NBA, EPL a um custo muito baixo com o DAZN, por apenas $17,18',
      youtube: 'Como aproveitar o YouTube Premium a um custo muito baixo',
    },
    intro: {
      'acornTv':
        'Acorn TV oferece serviço de streaming de programas de suspense, drama e comédia de classe mundial do Reino Unido e de outros lugares. Você pode assistir a séries clássicas sem interrupção ou descobrir novos programas favoritos.',
      'abc': 'Assista aos programas da ABC online em abc.com. Acesse vídeos exclusivos e séries gratuitas.',
      'f1Tv':
        'F1 TV agora está disponível na Web, aplicativo, Google TV, Apple TV, Roku e Amazon Fire TV. Transmissão ao vivo de todas as corridas da F1. Replays ao vivo dos treinos de todas as corridas.',
      'youtube':
        'O canal oficial do YouTube ajuda você a descobrir o conteúdo mais recente e popular do mundo todo. Assista a vídeos essenciais que abrangem música, cultura e fenômenos da internet, entre outros temas.',
      'netflix':
        'Netflix é um serviço de streaming que oferece uma ampla variedade de séries premiadas, filmes, animes, documentários e muito mais, disponíveis em milhares de dispositivos conectados à internet.',
      'fubo':
        'A maneira mais fácil de assistir ao Fubo na TV é usando Roku, Apple TV, Amazon Fire TV ou Chromecast. Basta instalar o aplicativo Fubo em seu dispositivo para fazer streaming.',
      'disneyPlus':
        'Desde lançamentos até clássicos favoritos, o passado, presente e futuro são seus. Tudo o que você espera e o que você nunca imaginou.',
      'crunchyroll':
        'Explore os melhores conteúdos de anime e mangá em streaming no Crunchyroll. Desfrute de séries populares como "Demon Slayer", "One Piece", "Attack on Titan" e "My Hero Academia".',
      'hulu':
        'Assista a séries de TV e filmes online no Hulu. Transmita programas de TV populares como "Grey\'s Anatomy", "This Is Us", "Bob\'s Burgers", "Brooklyn Nine-Nine", "Empire" e "Saturday Night Live", além de filmes populares.',
      'nbaLeaguePass':
        'A assinatura do NBA League Pass* oferece aos fãs acesso à transmissão ao vivo, vídeos sob demanda e mais conteúdo durante toda a temporada e o período de entressafra.',
      'peacock':
        'Obtenha o Peacock e transmita eventos esportivos ao vivo, conteúdo original exclusivo, programas populares e filmes de sucesso. Transmita agora os principais títulos, coleções clássicas, momentos emocionantes ao vivo e muito mais no Peacock. Documentários, filmes e programas infantis, canais Peacock 24 horas, canal Hallmark, etc.',
      'qqSports':
        'QQ Sports é um portal esportivo conhecido na China, que oferece seções como futebol nacional, futebol internacional, NBA, CBA, esportes gerais, Olimpíadas, transmissões ao vivo, loterias, apostas, etc.',
      'rakutenTv':
        'TV ao vivo | 15 primeiros canais gratuitos · Grátis | Assista a filmes populares gratuitamente · Loja | Compre ou alugue filmes populares · Gêneros · Grátis | Conteúdo adicionado recentemente grátis · Grátis',
      'espn':
        'Acesse a ESPN para obter resultados ao vivo, destaques emocionantes e notícias esportivas. Assista a eventos exclusivos na ESPN+ e participe de jogos de fantasia esportiva.',
      'beInSports':
        'Descubra o mundo dos esportes com a beIN SPORTS USA. Tenha acesso a replays exclusivos, últimas notícias, dados, pontuações e programação de grandes ligas de futebol, entre outros.',
      'dazn':
        'DAZN é a primeira plataforma de streaming dedicada exclusivamente à transmissão ao vivo de esportes em tempo real em todo o mundo...',
      'cbsSports':
        'Rede de notícias esportivas 24 horas gratuita. Assista ao CBS Sports HQ para obter todas as informações importantes sobre os jogos. Mais destaques incríveis, principais apostas e dicas de fantasia de sucesso.',
      'fox':
        'Assista ao FOX Sports e confira resultados ao vivo, probabilidades, notícias de equipes, notícias de jogadores, transmissões ao vivo, vídeos, estatísticas, classificações e programação, cobrindo NFL, MLB, NASCAR, WWE, NBA, NHL, etc.',
      'nflNetwork':
        'Obtenha informações e atualizações do canal NFL! A NFL Network é a única rede operada e de propriedade da NFL que funciona o ano todo.',
      'peacocktv':
        'Assista a todos os episódios completos dos programas atuais e clássicos da NBC em NBC.com. Além disso, você encontrará clipes, trailers, fotos e recursos online exclusivos.',
      'sportsnetNow':
        'MLB Top Plays - Vídeo da Sportsnet: Springer, dos Blue Jays, comemora seu aniversário com um home run liderado · Vídeo da Sportsnet: Akunya Jr., dos Warriors, com um home run para longe do campo.',
      'nbcSports': 'Obtenha as últimas notícias e resultados esportivos da NBC Sports, mantendo-se atualizado.',
      tsn:
        'Por favor, visite o site da TSN para obter as últimas notícias esportivas, pontuações, momentos emocionantes e comentários sobre o Aberto da Austrália, os playoffs da NFL, o Super Bowl, a data limite de negociação da NHL, etc.',
      nhl:
        'O site oficial da National Hockey League (Liga Nacional de Hóquei) inclui notícias, listas, estatísticas, calendários, equipes e vídeos, entre outros conteúdos.',
      hotstar:
        'Disney+ Hotstar é a maior plataforma de streaming premium da Índia, com mais de 100.000 horas de conteúdo de teatro e filmes em 17 idiomas, além de transmissões ao vivo de todos os principais eventos esportivos.',
      disneyPlus:
        'Desde novos lançamentos até seus clássicos favoritos, o passado, o presente e o futuro são seus. Tudo o que você esperava e tudo o que você não esperava. © 2023 Disney. Marcas: Marvel, Pixar, Disney, National Geographic, Star Wars.',
      skySports:
        'Assista às melhores coberturas ao vivo de seus eventos esportivos favoritos: futebol, golfe, rugby, críquete, Fórmula 1, boxe, futebol americano, basquete, além das últimas notícias esportivas...',
      sonyLiv:
        'Plataforma de streaming premium de primeira classe, onde você pode assistir a programas de TV populares, filmes, eventos esportivos e séries online. Desfrute da experiência de assistir aos seus canais de TV ao vivo favoritos online.',
      jiocinema:
        'Assista aos últimos filmes, programas de TV, programas Voot, eventos esportivos ao vivo e clipes emocionantes, com opções de idioma em hindi, inglês e outros idiomas regionais, em uma variedade de gêneros online.',
      amazonPrimeVideo:
        'Transmita filmes, programas, conteúdo original premiado da Amazon, canais premium, eventos esportivos e muito mais. Assista a séries de TV viciantes, filmes populares e programas de sucesso com um teste gratuito. Conteúdo em 4K+HDR. Séries e filmes populares. Conteúdo recém-lançado. Assista em qualquer dispositivo.',
      zee5:
        'Agora você pode comprar seu conteúdo Desi favorito no ZEE5™ com um exclusivo desconto de 50%. Compre agora e aproveite seus conteúdos favoritos no ZEE5™. Desconto de 50% no pacote anual. Link de compra. Mais de 4.000 filmes de sucesso, mais de 1.600 programas e 260 originais. Suporta 18 idiomas.',
      mxPlayer:
        'O MX Player é o serviço OTT premium mais popular da Índia, onde você pode reproduzir os vídeos musicais mais recentes, assistir a filmes antigos e novos, programas de TV, programas de comédia, músicas populares, etc.',
      tencentVideo:
        'A Tencent Video está empenhada em criar a principal plataforma de vídeo online da China, oferecendo conteúdo rico, experiência de visualização suprema, métodos de login convenientes, experiência perfeita em várias plataformas 24 horas por dia e recursos de compartilhamento rápido.',
      iqiyi:
        'iQIYI.COM é um grande site de vídeos online com uma enorme quantidade de vídeos de alta qualidade em HD. É uma plataforma profissional de reprodução de vídeos online. A iQIYI oferece uma ampla variedade de conteúdo de filmes, séries de TV, desenhos animados, programas de variedades, estilo de vida, ...',
      youku: 'Youku - Comprometido com o melhor conteúdo - Assista a vídeos em HD genuínos em massa.',
      migu:
        'O Migu Video transmite ao vivo todos os principais eventos esportivos nacionais e internacionais, incluindo os Jogos Olímpicos de Inverno de Pequim, transmissões ao vivo da CBA, Liga dos Campeões, La Liga, Bundesliga, Serie A, Europa League, Champions League, vôlei feminino, tênis de mesa, patinação no gelo, sinuca, WWE, UFC, ...',
      mangoTV:
        'Mango TV - O site de vídeos online que todos estão assistindo - Assista a programas de variedades populares, filmes mais recentes e dramas de TV online.',
      nbc:
        'Assista a episódios completos dos programas atuais e clássicos da NBC em NBC.com. Além disso, você também encontrará clipes, trailers, fotos e conteúdo exclusivo online.',
      foxSports:
        'Assista à FOX Sports e confira pontuações ao vivo, probabilidades, notícias de equipes, notícias de jogadores, transmissões ao vivo, vídeos, estatísticas, classificações e calendários, abrangendo NFL, MLB, NASCAR, WWE, NBA, NHL, entre outros conteúdos.',
      nfl:
        'Obtenha informações sobre o canal da NFL, atualizações de programas e muito mais! A NFL Network é a única rede operada e de propriedade da NFL que opera o ano todo.',
      willowTv:
        'Após fazer login com sucesso, você pode assistir a transmissões ao vivo, destaques emocionantes e replays no Willow TV. O serviço está disponível para provedores como Spectrum, Optimum, Fios, Dish, Optimum TV, Xfinity e Time Warner.',
      mlb:
        'Nosso conteúdo inclui clipes de áudio e vídeo, entrevistas, estatísticas, programação de jogos e histórias exclusivas de reportagens.',
      hboMax:
        'HBO Max é uma plataforma de streaming independente que combina todo o conteúdo da HBO com mais séries populares, filmes de sucesso e novas produções originais da Max, adequada para todos os públicos.',
      amcPlus:
        'Escape do comum e explore experiências únicas e envolventes no AMC+. Conteúdo exclusivo, filmes emocionantes e séries clássicas - encontre tudo isso nas séries épicas do AMC+. Filmes de alta qualidade. Serviço sob demanda. Conteúdo exclusivo. Filmes exclusivos. Novas séries. Sem anúncios.',
      attTv:
        'Obtenha nossas melhores ofertas de TV e Internet, incluindo promoções para DIRECTV e Internet residencial, incluindo AT&T Fiber.',
      britBox:
        'Desfrute de uma ampla seleção de dramas de TV britânicos para reprodução contínua, incluindo suspense, comédia, drama, documentários, estilo de vida e muito mais. Esta é a maior coleção de streaming de dramas de TV britânicos até o momento.',
      crackle:
        'Assista a filmes de Hollywood gratuitamente no Crackle - sem edições ou modificações. Cobrindo uma variedade de gêneros como ação, terror, ficção científica, crime e comédia que você adora. Basta clicar para reproduzir.',
      discoveryPlus:
        'Você pode fazer streaming em dispositivos móveis, tablets, computadores, consoles de jogos e TVs conectadas. Aqui você encontrará conteúdo para todos! A discovery+ é um provedor de serviços de streaming nas áreas de comida, casa e muito mais.',
      mgmPlus: 'Assine a MGM+ e aproveite milhares de filmes e séries sem anúncios. Cancele a qualquer momento.',
      filmrise:
        'Estamos ativamente respondendo às características em constante mudança da indústria hoje, adotando uma estratégia em camadas para fornecer o melhor conteúdo de filmes e TV.',
      frndlyTv:
        'Com Roku, Amazon Fire TV ou Frndly TV, você pode assistir a mais de 40 canais ao vivo e conteúdos sob demanda, incluindo todos os três canais Hallmark, A&E e outros. Você também pode assistir em dispositivos móveis a qualquer hora, em qualquer lugar.',
      fxNow: 'Assista a programas de TV originais, filmes e TV ao vivo da FX, FXX e FXM Networks.',
      paramountPlus:
        'Faça streaming de milhares de episódios de séries, eventos esportivos ao vivo e conteúdo original exclusivo em uma única plataforma. Acesse centenas de filmes icônicos, desde clássicos premiados até os lançamentos mais populares. Descubra novas séries para assistir em sequência. Reality shows populares. Programas populares da MTV. Mais de 30.000 episódios de séries. Cobertura de notícias de última hora. Programas populares da CBS.',
      pbs:
        'Os filmes POV são conhecidos por suas narrativas memoráveis e atualidade. Assista agora! O POV é o programa de documentários independentes mais antigo da televisão. Saiba mais! Trabalhos independentes de não ficção. Mais de 500 filmes apresentados. Histórias inesquecíveis.',
      philo:
        'Por que pagar mais pela TV? Philo custa apenas $25 por mês e oferece TV ao vivo e conteúdo sob demanda. Transmita seus programas e filmes favoritos em todos os dispositivos.',
      plex:
        'O Plex é um aplicativo de streaming gratuito, sem necessidade de cartão de crédito, taxas de assinatura ou custos ocultos. Para manter nosso serviço gratuito, incluímos anúncios para obter receita.',
      pluto:
        'Desde os filmes mais recentes e populares até temporadas completas de suas séries favoritas, temos tudo. Centenas de canais. Sem custo. Disponível em todos os dispositivos. Comece a assistir agora! Disponível em todos os dispositivos. Assista em sequência. TV e filmes sob demanda. Centenas de canais. Streaming imediato. Sem pagar nunca.',
      roku:
        'Os dispositivos Roku tornam a TV por streaming fácil. De players a TVs inteligentes e até produtos para casa inteligente, encontre o Roku perfeito para você e sua casa, tornando-a mais segura.',
      showtimeAnytime:
        'Experimente o SHOWTIME gratuitamente e faça streaming de séries originais, filmes, eventos esportivos, documentários e muito mais. Além disso, você pode comprar lutas de boxe pay-per-view sem assinatura.',
      shudder:
        'Comece com o plano mensal de apenas $4.99 e obtenha acesso a todo o conteúdo do Shudder em uma única plataforma, incluindo séries populares como o universo de "The Walking Dead" e Ann Rice. Experimente o Shudder gratuitamente...',
      slingTv:
        'O Sling TV é o primeiro serviço de TV baseado em aplicativo que permite transmitir TV ao vivo e conteúdo sob demanda pela internet. Assista a programas ao vivo onde quer que esteja...',
      starz:
        'O STARZ apresenta uma variedade de perspectivas de vida através de narrativas ousadas. Inscreva-se para fazer streaming de séries originais, filmes, conteúdo adicional e assista sob demanda, sem anúncios.',
      stirr:
        '...e programas de entretenimento. Transmissões ao vivo e conteúdo sob demanda. STIRR City e National estão configurados como seus canais locais. Comece a assistir. 00:05. Mais de 100 canais, mais de 5000 horas de TV e...',
      tbs:
        'In the Heights · Dynamite desta semana · Prévia dos playoffs da MLB na TBS · Multiverso do American Dad (18ª temporada, episódio 14)',
      theCw:
        'Página oficial da CW Network, oferecendo séries emocionantes como All American, Superman & Lois, Walker, FBoy Island e muito mais, além de filmes, eventos esportivos e muito mais.',
      tubiTv:
        'O Tubi é o principal aplicativo de streaming de vídeo sob demanda gratuito. Temos a maior biblioteca de conteúdo com mais de 50.000 filmes e programas de TV, oferecendo o melhor...',
      usaNetwork:
        'Assista a episódios completos dos programas atuais e clássicos da USA em USANetwork.com. Além disso, você encontrará clipes de episódios, trailers, fotos e conteúdo exclusivo online.',
      vudu:
        'Alugue, compre e assista a filmes e programas de TV no Vudu. Você pode assistir online no aplicativo Vudu ou em seus dispositivos conectados favoritos. Sem assinatura, registro gratuito.',
      xumo:
        'A Xumo foi criada para desenvolver e fornecer a próxima geração de plataformas de streaming para toda a indústria de entretenimento. Conheça nossa missão e participe...',
      jiocinema:
        'Assista aos filmes mais recentes, programas de TV, programas do Voot, eventos esportivos ao vivo e clipes emocionantes, tudo online, em vários idiomas, incluindo hindi, inglês e outros.',
      altBalaji:
        'Assista a web séries, obras originais e filmes em alta definição online. Escolha entre uma variedade de gêneros, incluindo suspense, ação, adulto, comédia, drama familiar e muito mais, com suporte para vários idiomas.',
      bilibili:
        'Bilibili (bilibili.com) é um site de vídeo de bala popular na China, com os últimos lançamentos de animes, uma atmosfera ativa da cultura ACG e criadores de conteúdo criativos. Aqui você pode encontrar muita diversão.',
      crave:
        'Uma coleção cuidadosamente selecionada de clássicos de filmes de terror, séries de TV assustadoras e séries de Halloween... uma reputação inesperada no mundo da comédia e pressões sociais.',
      cbcGem:
        "Assista a programas populares como Schitt's Creek, Luther, Kim's Convenience e muito mais, além de filmes e TV ao vivo em qualquer dispositivo.",
      bbcIPlayer:
        'Assista aos canais de TV ao vivo da BBC, aproveite os programas de TV que você perdeu e assista a conteúdo exclusivo na BBC iPlayer.',
      channel4: 'Assista aos seus programas favoritos online do Channel 4, E4 e Walter Presents.',
      'channel5':
        'Bem-vindo ao My5, onde você pode acompanhar e transmitir suas séries favoritas dos canais Channel 5, 5STAR, 5USA, 5SELECT, 5ACTION e muito mais.',
      'dsTv':
        'A melhor programação de entretenimento televisivo ao seu alcance. Assista aos últimos eventos esportivos, filmes, séries e reality shows, e acesse o guia de TV da DStv.',
      'molotovTv':
        'Molotov é gratuito. Sinta-se à vontade para adicionar recursos extras. · Molotov representa o futuro da TV · O Spotify da televisão · A TV pode ser...',
      'myTf1':
        'Assista à programação ao vivo da TF1, TMC, TFX, TF1 Séries Films, LCI e mais de 50 canais exclusivos, além de mais de 10.000 horas de conteúdo em vídeo, apenas no MYTF1...',
      'sSportsPlus':
        'Com o Sports Plus, você pode desfrutar de mais de 700 horas de transmissões ao vivo por mês e ter acesso a centenas de conteúdos esportivos, desde a Serie A até a La Liga, da Fórmula 1 à NBA, da EuroLeague ao UFC, tudo na ponta dos seus dedos...',
      'showmax':
        'As assinaturas do Showmax têm preços diferentes, pois o Showmax oferece uma variedade de planos para atender a diferentes preferências e orçamentos, com preços a partir de R$39...',
      'itv':
        'O ITVX é o mais novo serviço de streaming do Reino Unido, oferecendo programas exclusivos, filmes de sucesso, eventos ao vivo e milhares de séries, tudo em uma única plataforma.',
      'pandora':
        'Toque suas músicas, álbuns, listas de reprodução e podcasts favoritos no novo Pandora. Assine um plano de assinatura para desfrutar de reprodução sob demanda sem anúncios.',
      'qqMusic':
        'QQ Music é um serviço de música online lançado pela Tencent, oferecendo um vasto catálogo de músicas para audição online, lançamentos de novas músicas, tradução de letras, downloads de toques para celular, audição de músicas de alta qualidade sem perdas, um grande acervo de músicas sem perdas, downloads de música oficial, espaço...',
      'spotify': 'O Spotify é um serviço de música digital que permite acessar milhões de músicas.',
      'tidal':
        'O TIDAL é uma plataforma de streaming de música centrada nos artistas e nos fãs, oferecendo mais de 100 milhões de músicas em alta qualidade.',
      'weChat':
        'Use em várias plataformas, desfrute de bate-papos em grupo e suporte para mensagens de voz, fotos, vídeos e texto.',
      'whatsApp':
        'Mantenha contato com amigos e familiares usando o WhatsApp Messenger. O WhatsApp é gratuito e oferece mensagens e chamadas simples, seguras e confiáveis, disponíveis em...',
      'line':
        'Um novo aplicativo de comunicação que permite fazer chamadas de voz gratuitas e enviar mensagens gratuitas a qualquer hora e em qualquer lugar, 24 horas por dia!',
      'signal':
        'Compartilhe mensagens de texto, mensagens de voz, fotos, vídeos, GIFs e arquivos gratuitamente. O Signal usa a conexão de dados do seu telefone para evitar taxas de SMS e MMS.',
      'agoda':
        'Reserve agora, pague depois, cancele gratuitamente. Atendimento ao cliente em tempo real, 24 horas por dia, no idioma de sua preferência. De hotéis econômicos a luxuosos. Seu parceiro confiável para acomodações e viagens de avião.',
      'expedia':
        'Bem-vindo ao seu site de viagens completo para realizar as férias dos seus sonhos. Combine sua acomodação com aluguel de carros ou voos e economize ainda mais. Pesquise nossas opções flexíveis para encontrar o que melhor se adequa a você...',
      'flyScanner':
        'Encontre voos com desconto a partir de US$19 com facilidade. Compare e reserve em uma única pesquisa, rápido e conveniente. Compare voos baratos, hotéis e aluguel de carros. Encontre milhares de ofertas e obtenha a melhor delas!',
      'nowTV':
        'Qual é o custo de uma assinatura NOW? A assinatura NOW permite que você assista ao conteúdo que deseja do seu jeito. Você pode aderir aos planos de entretenimento, cinema, esportes e Hayu.',
      'TVPlayer':
        'O TVPlayer é o lugar mais popular para canais de TV e programas sob demanda de alta qualidade, exclusivos do TVPlayer. Assista a mais de 40 canais ao vivo e milhares de...',
      'ukTvPlay':
        'O UKTV Play é o lar sob demanda dos canais Dave, Drama, W & Yesterday. Assista a programas de TV ao vivo, transmita e acompanhe seus favoritos.',
      'zattoo':
        'Transmita e grave seus programas de TV favoritos ao vivo em todos os dispositivos. Melhor qualidade de imagem em alta definição total. Melhor do que a TV a cabo. Oferece conteúdo sob demanda e TV ao vivo.',
      'skyGo':
        'Assista a transmissões ao vivo, programas sob demanda e conteúdo gravado em seu celular, tablet, laptop ou computador usando o pacote Sky, ou através de dispositivos Apple TV, e troque facilmente entre dispositivos.',
      'Telegram':
        'O Telegram visa fornecer uma experiência de comunicação segura, rápida e concisa, e é conhecido por seus poderosos recursos de criptografia e funções ricas.',
      'Messenger':
        'O Messenger é uma aplicação de mensagens instantâneas fornecida pelo Facebook, que permite aos usuários se comunicarem com outros através de mensagens de texto, chamadas de voz, chamadas de vídeo, envio de imagens, emojis, etc. O Messenger inicialmente era um recurso do site do Facebook, depois se desenvolveu em uma aplicação móvel independente e oferece mais recursos e serviços.',
      'ChatGPT':
        'O ChatGPT é um modelo de linguagem de inteligência artificial desenvolvido pela OpenAI, baseado na arquitetura GPT (Generative Pre-trained Transformer). É treinado através de dados de processamento de linguagem natural em larga escala e pode entender e gerar texto em linguagem natural.',
      'CanalPlus':
        'O CANAL+ é um canal de TV paga francês, uma das maiores redes de TV paga na França e um dos mais importantes provedores de serviços de TV paga na Europa. O canal oferece uma variedade de programas, incluindo filmes, esportes, notícias, documentários, dramas de TV, etc.',
    },
    'selectTunnel': 'Selecionar túnel',
    notSetLabel: 'Não configurado',
    'directAccessLabel': 'Acesso direto',
    'otherTunnelLabel': 'Outro túnel',
    'recommendedTunnelLabel': 'Túnel recomendado',
    'error': {
      'getApps': 'Falha ao obter aplicativos',
      'getTunnels': 'Falha ao obter lista de túneis',
      'invalidApp': 'Aplicativo inválido',
      'tunnelNotExist': 'Túnel não existe',
      'set': 'Falha ao definir túnel',
      'unset': 'Falha ao cancelar a definição do túnel',
    },
    'success': {
      'set': 'Túnel definido com sucesso',
      'unset': 'Definição do túnel cancelada com sucesso',
    },
    'searchInputPlaceholder': 'Pesquise o serviço que você precisa',
    discountIntro: percent => `Economize  ${percent} na assinatura mensalna assinatura mensal`,
    discountPercent: percent => `-${percent} (USD)/mês`,
    guideButtonLabel: 'Ver detalhes',
  },
  // betanet
  betanet: {
    bindWalletSuccess: 'Carteira vinculada com sucesso',
    deviceBound: 'O dispositivo já está vinculado a outra carteira',
    walletTaken: 'A carteira já está sendo usada por outro dispositivo',
    deviceTypeIncompatible: 'O dispositivo não é compatível com a carteira',
    invalidMnemonic: 'Palavra-chave da carteira inválida',
    invalidPrivateKey: 'Chave privada da carteira inválida',
    bindWalletError: 'Falha ao vincular a carteira',
    notBound: 'O usuário atual não possui uma carteira vinculada',
    unbindWalletSuccess: 'Carteira desvinculada com sucesso',
    noActiveBinding: 'O dispositivo não está vinculado à carteira especificada',
    unbindWalletError: 'Falha ao desvincular a carteira',
    walletManagement: 'Gestão de carteira',
    bindWallet: 'Vincular carteira',
    downloadPrivateKey: 'Baixar chave privada',
    downloadKeyError: 'Falha ao baixar a chave privada',
    unbindBtnText: 'Desvincular carteira',
    unbindWarning:
      'Certifique-se de que você tenha baixado a chave privada. Por favor, digite "Eu baixei a chave privada" para continuar.',
    unbindWarningWords: 'Eu baixei a chave privada',
    generateWallet: 'Gerar carteira',
    verifyMnemonic: 'Verificar palavra-chave',
    mnemonicMismatch: 'Falha na verificação da palavra-chave',
    bindBtn1Text: 'Vincular carteira (palavra-chave)',
    bindBtn2Text: 'Vincular carteira (chave privada)',
    dprManagement: 'Gestão de DPR',
    balanceLabel: 'Saldo DPR',
    balance: 'Saldo',
    creditLabel: 'Crédito',
    operationLabel: 'Operação',
    amtOwingLabel: 'O sistema pagou por você',
    dailyConsumedLabel: 'Uso diário de dados',
    dailySharedLabel: 'Dados compartilhados diariamente',
    deposit: 'Depositar',
    withdraw: 'Retirar',
    publicKey: 'Chave pública',
    mnemonicInstruction: 'Anote as palavras-chave. Você precisará verificá-las na próxima etapa.',
    enterMnemonic: 'Digite as palavras-chave',
    enterMnemonicTip: 'Clique nas palavras-chave na ordem correta.',
    resetMnemonic: 'Redefinir',
    uploadPrivateKey: 'Enviar chave privada',
    getWalletError: 'Falha ao obter informações da carteira',
    deviceWalletMismatch: 'O dispositivo não corresponde à carteira',
    walletNotFound: 'Carteira não encontrada',
    generateWalletError: 'Falha ao gerar a carteira',
    missingMnemonic: 'Palavras-chave ausentes',
    missingPrivateKeyFile: 'Arquivo de chave privada ausente',
    privateKeyFileOversize: 'O tamanho do arquivo de chave privada excede o limite de 10KB',
    transferTitle: 'Transferência',
    transferHistoryTitle: 'Histórico de transferências',
    transferTo: 'Destinatário',
    transferAmt: 'Valor da transferência (até 6 casas decimais)',
    invalidTo: 'Destinatário inválido',
    invalidAmt: 'Valor de transferência inválido',
    amtOutOfRange: 'O valor da transferência deve ser positivo e não pode exceder 1 milhão',
    transferSuccess: 'Transferência bem-sucedida',
    senderRecipientSame: 'O remetente não pode ser o mesmo que o destinatário',
    recipientNotFound: 'Destinatário não encontrado',
    insufficientFund: 'Falha na transferência devido a saldo insuficiente',
    transferError: 'Falha na transferência',
    votingResNotRetrieved: 'Não foi possível obter os resultados da votação',
    candidateSelected: 'O candidato de validação selecionado é aquele em quem você votou',
    candidateNotSelected: 'O candidato de validação selecionado não é aquele em quem você votou',
    votingResNotReady: 'Os resultados da votação não estão disponíveis no momento',
    notVoted: 'Você não votou em nenhum candidato de validação',
    candidateNotFound: 'Candidato de validação não encontrado',
    votingResRetrievalErr: 'Falha ao obter os resultados da votação',
    from: 'De',
    to: 'Para',
    type: 'Tipo',
    amount: 'Quantidade',
    time: 'Hora',
    txnTypes: {
      101: 'Recompensa Inicial',
      201: 'Transferência Manual',
      301: 'Recompensa de Delegação',
      401: 'Micro Pagamento',
    },
    walletAddress: 'Endereço da Carteira',
    notInGenesisNodeList: 'Apenas os nós Genesis podem ser vinculados a mineradores Genesis.',
    onChainStatusLabel: 'Status na Blockchain',
    onChainStatusDesc: {
      unknown: '-',
      onChain: 'Sim',
      notOnChain: 'Não',
    },
    pwMgmtSection: {
      title: 'Gerenciamento de Senha',
      setBtnText: 'Definir Senha',
      resetBtnText: 'Redefinir Senha',
    },
    passwordModal: {
      title: 'Definir Senha da Carteira',
      onePlaceholder: 'Digite a senha',
      twoPlaceholder: 'Digite a senha novamente',
      btnText: 'Confirmar',
    },
    setPasswordMsg: {
      mismatch: 'Senhas não correspondem',
      weak: 'A senha deve ter pelo menos 8 caracteres e incluir letras minúsculas, letras maiúsculas e números',
      alreadySet: 'A senha da carteira já foi definida',
      genericErr: 'Falha ao definir a senha da carteira',
      success: 'Senha da carteira definida com sucesso',
    },
    authModal: {
      title: 'Senha da Carteira',
      inputPlaceholder: 'Digite a senha da carteira',
      btnText: 'Continuar',
    },
    passwordNotSet: 'Senha da carteira não definida',
    passwordIncorrect: 'Senha da carteira incorreta',
    resetModal: {
      disclaimer:
        'Ao redefinir a senha da carteira, seu dispositivo será desvinculado de qualquer carteira atualmente vinculada. Digite "Eu confirmo a redefinição da senha da carteira" para continuar',
      consent: 'Eu confirmo a redefinição da senha da carteira',
    },
    resetPasswordMsg: {
      noActiveBinding: 'O dispositivo não está vinculado a nenhuma carteira especificada',
      unbindErr: 'Falha ao desvincular a carteira',
      genericErr: 'Falha ao redefinir a senha da carteira',
      success: 'Senha da carteira redefinida com sucesso',
    },
    unstaking: {
      label: 'Informações de Staking',
      getErr: 'Falha ao obter seleção de término de Staking',
      alreadyChosen: 'Você já selecionou o término do Staking',
      expired: 'O período de término do Staking expirou',
      invalidCreditData: 'Esta carteira não está conectada à rede',
      phase1IsNotOver: 'A primeira fase de Staking desta carteira não terminou',
      setErr: 'Falha ao definir a seleção de término do Staking',
      announcementTitle: 'Caro usuário de nó de Staking:',
      announcement: {
        continueStaking:
          'Continuar Staking: Suas atividades de Staking foram automaticamente transferidas para a segunda fase; A segunda fase de Staking dura 6 meses. O APY de Mineração Básica (Basic Mining) e Nó Genesis (Genesis) permanece o mesmo na segunda fase.',
        duringStaking:
          'Durante a segunda fase de Staking: Você pode clicar neste botão a qualquer momento para terminar o Staking; Essa escolha é irreversível!',
        stopStaking: 'Término do Staking: Você entrará em uma fase de liberação linear de 3 meses;',
        releaseStaking:
          'Liberação do Staking: DPR será liberado automaticamente para sua carteira DeeperChain todos os dias.',
      },
      tipTitle: 'Lembrete amigável:',
      tip: {
        afterStopStaking_1:
          '1. Após o término do Staking, os créditos obtidos pelo Staking serão apagados (os créditos acumulados de compartilhamento de largura de banda durante o período de Staking serão preservados)',
        afterStopStaking_2:
          '2. O cancelamento do Staking de um Nó Genesis resultará na perda de sua posição como Nó Genesis, perdendo permanentemente a oportunidade de ser um Nó Genesis. Seu dispositivo não poderá participar imediatamente do Staking Básico 2.0, mas a pontuação de crédito orgânico gerada pelo compartilhamento de largura de banda continuará.',
        afterStopStaking_3:
          '3. Após o término do Staking, suas recompensas de Staking DPR serão interrompidas imediatamente',
        afterStopStaking_4:
          '4. Após o término do Staking, você não poderá reiniciar o Staking imediatamente (aguarde anúncios futuros)',
        afterStopStaking_5:
          '5. Após o término do Staking, ele só será encerrado após o término de todas as 270 recompensas da primeira fase',
      },
      btn: {
        cancel: 'Entendi, continuar o Staking',
        ok: 'Sair do Staking',
      },
    },
    staking: {
      walletInfo: 'Informações da Carteira',
      campaignLabel: 'Tipo de Atividade de Staking',
      campaignText: {
        0: 'Nó Genesis Ouro',
        1: 'Nó Genesis Prata',
        2: 'Staking 1.0',
        3: 'Mineração DPRB',
        4: 'Staking 2.0',
        7: 'Staking 2.0',
        8: 'Staking 2.0',
      },
      creditLabel: 'Pontuação de Crédito Atual',
      creditMaxLabel: 'Pontuação de Crédito Máxima',
      creditLevelLabel: 'Nível de Crédito',
      stakeAndBurn: 'Staking e Queima',
      stakeForCredit: 'Aumentar Staking',
      dailyReward: 'Recompensa Diária',
      view: 'Visualizar',
      payment: 'Pagamento',
      insufficientBalance: 'Saldo Insuficiente',
      stakeSuccess: 'Solicitação enviada. Aguarde a atualização da pontuação de crédito.',
      stakeError:
        'Falha ao enviar a solicitação. Verifique as informações da carteira ou entre em contato com o suporte.',
      burnForCredit: 'Queimar DPR para aumentar a pontuação de crédito',
      burnSuccess: 'Solicitação enviada. Aguarde a atualização da pontuação de crédito.',
      burnError:
        'Falha ao enviar a solicitação. Verifique as informações da carteira ou entre em contato com o suporte.',
    },
  },
  // ChangePasswordData
  ChangePasswordData: {
    successMsg: 'Senha alterada com sucesso',
    newPasswordMismatch: 'As novas senhas digitadas não correspondem',
    oldPasswordMismatch: 'Senha antiga incorreta',
    samePasswordError: 'A nova senha é igual à senha antiga',
    genericError: 'Erro ao alterar a senha',
    title: 'Alterar Senha',
    oldPwd: 'Digite a senha antiga',
    newPwd: 'Digite a nova senha',
    newPwdAgain: 'Digite a nova senha novamente',
    okText: 'Confirmar',
  },
  //dailyTraffic
  dailyTraffic: {
    historySize: 'Dias',
    chartTitle: 'Tráfego',
    uptimeChartTitle: 'Tempo de Atividade',
    hour: 'Hora',
    minute: 'Minuto',
    second: 'Segundo',
  },
  // flow
  flow: {
    totalTrafficLabel: 'Tráfego Total',
    localTraffic: 'Tráfego Local',
    clientTraffic: 'Tráfego do Cliente',
    serverTraffic: 'Tráfego do Servidor',
    upload: 'Upload',
    download: 'Download',
    sessionSpeed: 'Velocidade da Sessão',
  },
  // login
  login: {
    pageTitle: 'Bem-vindo à Deeper Network!',
    username: 'Nome de usuário',
    password: 'Senha',
    btnText: 'Entrar',
    successMsg: 'Login bem-sucedido',
    missingField: 'Campo de usuário ou senha em falta',
    wrongField: 'Usuário ou senha incorretos',
    invalidToken: 'Dados de login inválidos',
    expiredToken: 'Dados de login expirados',
    genericError: 'Erro de login',
    tooManyAttempts: 'Muitas tentativas de login',
  },
  //notification
  notification: {
    search: 'Pesquisar',
    CP: 'Camada de Controle',
    DP: 'Camada de Dados',
    MP: 'Camada de Gerenciamento',
  },
  // DomainConfig
  domainConfig: {
    whiteList: 'Lista de Roteamento Inteligente',
    blackList: 'Lista de Roteamento Direto',
    domain: 'Domínio',
    tunnel: 'Túnel',
    selectTunnel: 'Selecionar Túnel',
    region: 'Região',
    country: 'País ou Região',
    noTunnel: 'Sem Túnel',
    convertedTip: 'O URL foi convertido automaticamente para um domínio',
    urlInputPlaceholder: 'Por exemplo: https://www.google.com ou google.com',
    missingInfoWarning: 'Por favor, preencha todas as informações',
    invalidDomainError: 'Formato de domínio inválido ou domínio muito longo',
    whitelistAddSuccess: 'Adicionado com sucesso à lista de roteamento inteligente',
    existInBlacklist: 'O item adicionado já existe na lista de roteamento direto',
    whitelistEntryExist: 'O item já existe na lista de roteamento inteligente',
    tunnelNotExist: 'O túnel não existe, não é possível adicionar',
    whitelistAddError: 'Falha ao adicionar item à lista de roteamento inteligente',
    blacklistAddSuccess: 'Adicionado com sucesso à lista de roteamento direto',
    existInWhitelist: 'O item adicionado já existe na lista de roteamento inteligente',
    blacklistEntryExist: 'O item já existe na lista de roteamento direto',
    blacklistAddError: 'Falha ao adicionar item à lista de roteamento direto',
    conflict: 'Não é possível adicionar o item devido a conflito',
    whitelistDelSuccess: 'Item removido com sucesso da lista de roteamento inteligente',
    domainNotExist: 'O domínio selecionado não existe',
    whitelistDelError: 'Falha ao remover item da lista de roteamento inteligente',
    blacklistDelSuccess: 'Item removido com sucesso da lista de roteamento direto',
    blacklistDelError: 'Falha ao remover item da lista de roteamento direto',
    backupTips:
      'O túnel de backup está ativado. Se o túnel adicionado no modo de roteamento inteligente não tiver nós disponíveis temporariamente, o sistema usará o túnel de backup por padrão - {0}.',
    tips: {
      smartRouteList:
        'De acordo com suas necessidades, você pode adicionar manualmente os domínios que deseja acessar, juntamente com os respectivos países.',
      directRouteList: 'Os domínios adicionados aqui não passarão pelo túnel DPN, mas usarão diretamente a rede local.',
    },
  },
  // ipConfig
  ipConfig: {
    whiteList: 'Lista de IP Inteligente',
    blackList: 'Lista de IP Direto',
    ip: 'IP',
    tunnel: 'Túnel',
    selectTunnel: 'Selecionar Túnel',
    region: 'Região',
    country: 'País ou Região',
    noTunnel: 'Sem Túnel',
    convertedTip: 'O URL foi convertido automaticamente para um IP',
    urlInputPlaceholder: 'Por exemplo: https://xxx.xxx.xxx.xxx ou xxx.xxx.xxx.xxx',
    missingInfoWarning: 'Por favor, preencha todas as informações',
    invalidIPError: 'Formato de IP inválido',
    whitelistAddSuccess: 'Adicionado com sucesso à lista de IP inteligente',
    existInBlacklist: 'O item adicionado já existe na lista de IP direto',
    whitelistEntryExist: 'O item já existe na lista de IP inteligente',
    tunnelNotExist: 'O túnel não existe, não é possível adicionar',
    whitelistAddError: 'Falha ao adicionar item à lista de IP inteligente',
    blacklistAddSuccess: 'Adicionado com sucesso à lista de IP direto',
    existInWhitelist: 'O item adicionado já existe na lista de IP inteligente',
    blacklistEntryExist: 'O item já existe na lista de IP direto',
    blacklistAddError: 'Falha ao adicionar item à lista de IP direto',
    whitelistDelSuccess: 'Item removido com sucesso da lista de IP inteligente',
    ipNotExist: 'O IP selecionado não existe',
    whitelistDelError: 'Falha ao remover item da lista de IP inteligente',
    blacklistDelSuccess: 'Item removido com sucesso da lista de IP direto',
    blacklistDelError: 'Falha ao remover item da lista de IP direto',
    backupTips:
      'O túnel de backup está ativado. Se o túnel adicionado no modo de roteamento inteligente não tiver nós disponíveis temporariamente, o sistema usará o túnel de backup por padrão - {0}.',
    tips: {
      smartRouteList:
        'De acordo com suas necessidades, você pode adicionar manualmente os IPs que deseja acessar, juntamente com os respectivos países.',
      directRouteList: 'Os IPs adicionados aqui não passarão pelo túnel DPN, mas usarão diretamente a rede local.',
    },
  },
  // queryRouting
  queryRouting: {
    title: 'Consultar Roteamento',
    searchLabel: 'Configuração de Consulta',
    search: 'Buscar',
    searchResult: 'Resultado da Busca: ',
    invalidRoutingError: 'Formato de consulta inválido',
    defaultConfig: 'Configuração Padrão',
    userConfig: 'Configuração do Usuário',
    routingNotFound: 'Roteamento não encontrado',
    queryRoutingError: 'Falha ao consultar o roteamento',
    convertedTip: 'O URL foi convertido automaticamente para um domínio ou IP',
    urlInputPlaceholder: 'Por exemplo: google.com ou xxx.xxx.xxx.xxx',
  },
  // rustdesk
  rustdesk: {
    ipLabel: 'Seu endereço IP é',
    noPubIp: 'Você não possui um IP público.',
    vpnUse:
      'Você pode usar o redirecionamento de porta Wireguard VPN para obter um IP público. Por favor, note que este IP público só pode ser usado para o serviço RustDesk e não fornece serviços DPN.',
    config: {
      title: 'Configuração do RustDesk',
      switch: 'Ligar/Desligar',
      forceKey: 'Exigir chave pública',
      relay:
        'Servidores de retransmissão separados por vírgula (host:port), por exemplo, www.example.com:21116,192.168.1.1:21117',
      port: 'Porta (2-65532)',
      saveBtnText: 'Salvar',
      pubKey: 'Chave pública',
      emptyPubKey: 'Nenhuma chave pública',
      resetBtnText: 'Redefinir chave pública',
    },
    vpn: {
      title: 'Redirecionamento de Porta Wireguard VPN',
      switch: 'Ligar/Desligar',
      ip: 'IP do servidor Wireguard',
      port: 'Porta de redirecionamento VPN',
    },
    guide: {
      title: 'Tutorial de Configuração',
      stepOne: 'Clique no botão mostrado na imagem',
      stepTwoA: 'Preencha as respectivas colunas no formulário e clique em Salvar',
      idAndRelay: 'ID/Servidor de retransmissão',
      idServer: 'Servidor ID:',
      relayServer: 'Servidor de retransmissão:',
      apiServer: 'Servidor API:',
      serverKey: 'Chave:',
      stepTwoB: 'Ou você pode copiar a configuração para a área de transferência e importá-la no RustDesk',
      copyBtnText: 'Copiar configuração do servidor para a área de transferência',
    },
    success: {
      setSwitch: 'RustDesk alternado com sucesso',
      setForced: 'Chave pública exigida com sucesso',
      setRelayAndPort: 'Relé e porta salvos com sucesso',
      resetPubKey: 'Chave pública redefinida com sucesso',
      setVpnSwitch: 'Wireguard VPN alternado com sucesso',
      copyToClipboard: 'Copiado para a área de transferência com sucesso',
    },
    error: {
      getNetworkAddress: 'Falha ao obter endereço IP',
      getConfig: 'Falha ao obter configuração do RustDesk',
      setSwitch: 'Falha ao alternar o RustDesk',
      setForced: 'Falha ao exigir chave pública',
      invalidPort: 'Porta inválida',
      invalidRelay: 'Relé inválido',
      setRelayAndPort: 'Falha ao salvar relé e porta',
      resetPubKey: 'Falha ao redefinir chave pública',
      getVpnConfig: 'Falha ao obter configuração Wireguard VPN',
      setVpnSwitch: 'Falha ao alternar o Wireguard VPN',
      copyToClipboard: 'Falha ao copiar para a área de transferência',
      getVersion: 'Falha ao obter número da versão',
      getHbbrLog: 'Falha ao obter log HBBR',
      getHbbsLog: 'Falha ao obter log HBBS',
    },
  },
  // sharing
  sharing: {
    sharingTitle: 'Compartilhamento: ',
    btSharingTitle: 'Compartilhamento BitTorrent: ',
    enabled: 'Ativado',
    disabled: 'Desativado',
    sharingTrafficLimit: 'Limite de tráfego compartilhado mensal',
    sharingBandwidthLimit: 'Limite de largura de banda compartilhada',
    unlimited: 'Sem limite',
    limited: 'Limitado',
    setLabel: 'Configurar',
    checkStartEndTime: 'Selecione o horário de início e término',
    time: 'Tempo',
    record: 'Registro',
    startTime: 'Hora de início',
    endTime: 'Hora de término',
    keyword: 'Palavra-chave',
    search: 'Pesquisar',
    webToSharingTitle: 'Regras de filtro de página da web do aplicativo',
    webToSharingDesc:
      'Aplique a filtragem de anúncios DNS integrada do dispositivo e as regras personalizadas de filtragem de DNS',
    yes: 'Sim',
    no: 'Não',
    download: 'Registro em texto simples',
    export: 'Registro criptografado',
    success: {
      enableTrafficLimit: 'Limite de tráfego de compartilhamento mensal ativado. Por favor, defina o limite!',
      setSharingTrafficLimit: 'Limite de tráfego compartilhado configurado com sucesso',
      enableBandwidthLimit:
        'Limite de largura de banda compartilhada ativado. Por favor, defina o limite entre 15Mbps e 1024Mbps!',
      setSharingBandwidthLimit: 'Limite de largura de banda compartilhada configurado com sucesso',
    },
    errors: {
      getSharingState: 'Falha ao obter estado de compartilhamento',
      switchSharingState: 'Falha ao alternar o estado de compartilhamento',
      exceedsLimit: 'Falha ao ativar o compartilhamento, excedeu o limite de tráfego',
      getSharingTrafficLimit: 'Falha ao obter limite de tráfego compartilhado',
      setSharingTrafficLimit: 'Falha ao configurar limite de tráfego compartilhado',
      invalidTrafficLimit: 'O limite de tráfego precisa ser um número positivo',
      setSharingBandwidthLimit: 'Falha ao configurar limite de largura de banda compartilhada',
      invalidBandwidthLimit: 'Limite de largura de banda deve estar entre 15Mbps e 1024Mbps',
      getSharingFilterConfig: 'Falha ao obter configuração de filtro de compartilhamento',
      setSharingFilterConfig: 'Falha ao configurar configuração de filtro de compartilhamento',
    },
  },
  // system
  system: {
    hardwareInfo: 'Informações de hardware',
    softwareInfo: 'Informações de software',
    networkAddress: 'Endereço de rede',
    sessionInfo: 'Informações da sessão',
    upgradeInfo: 'Informações de atualização',
    sn: 'Número de série',
    cpu: 'CPU',
    cores: 'Núcleos',
    memory: 'Usado/Disponível',
    systemVersion: 'Versão do sistema',
    appidSignVersion: 'Biblioteca de identificação de protocolo de tráfego',
    urlFilterSignVersion: 'Biblioteca de filtragem de página da web',
    https: 'HTTPS',
    dns: 'DNS',
    rules: 'Regras',
    ip: 'Endereço IP',
    routerMac: 'Mac do roteador',
    gatewayMac: 'Mac do gateway',
    maxSession: 'Número máximo de sessões',
    currentSession: 'Sessões atuais',
    tcpUdpIcmp: 'TCP/UDP/ICMP/TUNNEL',
    autoUpdate: 'Atualização automática',
    manualUpgrade: 'Atualização manual',
    latestVersion: 'Versão mais recente',
    upgrading: 'Atualizando...',
    systemStatusHistory: 'Histórico de status do sistema',
    cpuUsage: 'Uso de CPU nas últimas 24 horas',
    memoryUsage: 'Uso de memória nas últimas 24 horas',
    off: 'Desligado',
    on: 'Ligado',
    nowCheckVersion: 'Verificando a versão atual',
    nowLatestVersion: 'Você já possui a versão mais recente',
    nowNewTestVersion: 'Você está usando a versão de teste mais recente',
    upgradeLoadingText:
      'Atualizando o firmware, por favor, não atualize a página e aguarde pacientemente a conclusão da atualização~',
    upgradeSuccess: 'Atualização concluída com sucesso, a página será atualizada em breve~',
    upgradeError: {
      noImage: 'Pacote de atualização ausente',
      invalidImage: 'Pacote de atualização inválido',
      imageArchMismatch: 'Pacote de atualização incompatível com o dispositivo',
      noResponse:
        'O dispositivo foi reiniciado, por favor, atualize a página mais tarde para confirmar se a atualização foi bem-sucedida',
      alreadyLatest: 'Você já possui a versão mais recente, não é necessário atualizar~',
      generic: 'Erro durante a atualização~',
    },
    copySuccess: 'Copiado com sucesso',
    copyFail: 'Falha ao copiar',
  },
  // techSupport
  techSupport: {
    remoteConnection: {
      title: 'Conexão Remota',
      loading: 'Conectando...',
      popupTitle: 'Código de Conexão Remota',
      intro:
        'Cenário de uso: <span>Quando sua rede de dispositivo está funcionando corretamente, mas você está enfrentando problemas em outras funcionalidades.</span><br />Gere manualmente um "Código de Conexão Remota" e envie-o para nossa equipe de suporte ao cliente. Nossa equipe técnica irá identificar e ajudar a resolver seus problemas rapidamente. Para garantir a eficácia do código de conexão, evite gerá-lo repetidamente em um curto período de tempo.',
      buttonText: 'Gerar Código de Conexão',
      generateFeatureCodeFailure: 'Falha ao gerar o código de conexão',
      tips:
        'Se você encontrar algum problema, envie detalhes para nossa equipe de suporte. Estamos comprometidos em ajudar e garantir que seus problemas sejam resolvidos.',
      confirm: 'Copiar e Fechar',
      copySuccess: 'Copiado com sucesso para a área de transferência',
      copyFailure: 'Falha ao copiar para a área de transferência',
      connectFailure: 'Falha na conexão remota',
    },
    logDownload: {
      title: 'Download de Logs',
      loading: 'Baixando...',
      intro:
        'Cenário de uso: <span>Quando você consegue fazer login na interface do dispositivo, mas está enfrentando problemas de conexão de rede.</span><br />Baixe manualmente o arquivo de log e envie-o para nossa equipe de suporte ao cliente. Isso nos ajudará a entender sua situação específica e fornecer assistência mais eficaz.',
      buttonText: 'Baixar',
      downloadSuccess: 'Download concluído com sucesso',
      downloadFailure: 'Falha no download. Por favor, tente novamente mais tarde',
    },
    supportServices: {
      title: 'Obter a assistência que você precisa',
      intro: 'Forneça algumas informações específicas e nós forneceremos a solução adequada para você.',
      buttonText: 'Obter Ajuda',
    },
    promiseTips: {
      title: 'Compromisso de Segurança:',
      intro:
        'Fique tranquilo, nós <span>restringimos rigorosamente as funções de conexão remota e download de logs</span> apenas para fins de diagnóstico e solução de problemas, nunca para qualquer outro propósito, garantindo a máxima proteção à sua privacidade e segurança.',
    },
  },
  // builtinRules
  builtinRules: [
    {
      label: 'Ativar filtro HTTPS (incluindo filtro HTTP)',
      sslbypass: 'Ativar bypass de SSL',
      value: 'youtube',
    },
  ],
  // customrules
  customrules: [
    {
      label: 'Ativar filtro HTTPS (incluindo filtro HTTP)',
      value: 'youtube',
    },
  ],
  // tproxy
  tproxy: {
    browserCacheWarning:
      'Se o filtro HTTPS estiver ativado e não estiver filtrando anúncios corretamente, limpe o cache do seu navegador; Se apenas o filtro HTTPS estiver ativado sem o certificado instalado, não apenas não filtrará anúncios, mas também pode afetar o acesso normal aos sites na lista.',
    triggerRulesTipsTitle: 'Dicas',
    buttonAddRule: 'Adicionar regra',
    buttonInstallCertificate: 'Instalar certificado',
    missingInfoWarning: 'Verifique se as informações estão preenchidas corretamente',
    missingInfoSuccess: 'Operação concluída com sucesso',
    missingInfoError: 'Falha na operação',
    certificateTip: 'Para garantir o uso adequado, certifique-se de ter instalado o certificado',
    remind: 'Não mostrar novamente',
    tip: 'Siga as etapas abaixo para bloquear anúncios corretamente',
    triggerRulesTips:
      'Após ativar o filtro HTTPS, para que o Deeper Connect possa filtrar anúncios nos sites da lista, você precisa instalar e confiar no certificado raiz da Deeper Network em seu computador.',
    triggerRulesTips2:
      'Se apenas o filtro HTTPS estiver ativado sem o certificado instalado, não apenas não filtrará anúncios, mas também pode afetar o acesso normal aos sites na lista.',
    mobileDownloadCertTips: 'Use o navegador Safari para baixar e instalar o certificado',
    androidTVTip: 'Se você estiver usando uma TV Android, faça o download do aplicativo para usar',
    errorMessage: {
      maxLength: 'Máximo de 1024 regras permitidas',
      ruleExisted: 'Já existe uma regra de bloqueio com o mesmo domínio e expressão',
    },
    label: {
      domain: 'Domínio',
      Pattern: 'Expressão da regra de bloqueio',
      ruleType: 'Tipo de regra',
      web: 'Navegador',
      app: 'Aplicativo',
      all: 'Todos',
      Rewrite_Pattern: 'Expressão da regra de reescrita',
      Rewrite_Info: 'Informações da regra de reescrita',
      Rewrite_Action: 'Ação',
      enable: 'Ativar',
      operation: 'Operação',
      order: 'Ordem',
    },
    operation: {
      delete: 'Excluir',
      edit: 'Editar',
    },
    modal: {
      title: 'Adicionar regra',
      confirmText: 'Enviar',
      cancelText: 'Cancelar',
    },
    placeholder: {
      domain: 'Insira um domínio válido',
      Pattern: 'Insira uma expressão de regra de bloqueio válida',
      ruleType: 'Selecione um tipo de regra',
      Rewrite_Pattern: 'Insira uma expressão de regra de reescrita válida',
      Rewrite_Info: 'Insira as informações da regra de reescrita',
      Rewrite_Action: 'Selecione uma ação',
    },
    builtinRules: {
      title: 'Filtro de anúncios',
      filterListtitle: 'Lista de Filtro HTTPS',
      platform: 'Plataforma suportada',
      segOptions: ['Tutorial de instalação do certificado', 'Lista de suporte'],
    },
    customerRules: {
      title: 'Regras HTTPS personalizadas',
    },
    guide: {
      buttonDownloadCertificate: 'Clique para baixar o certificado',
      buttonDownloadApp: 'Clique para baixar o SkyTube',
      tab: {
        mobileInstall: 'iPhone/iPad',
        macInstall: 'Mac OS',
        windowsInstall: 'Windows',
        androidInstall: 'Android',
        linuxInstall: 'Linux',
      },
      mobile: {
        videoTab: 'Vídeo',
        textTab: 'Texto',
        step1: {
          label: 'Passo 1',
        },
        step2: {
          label: 'Passo 2',
          tip: 'Clique em Permitir',
        },
        step3: {
          label: 'Passo 3',
          tip: 'Abra as "Configurações" do seu telefone, toque em "Perfil Baixado"',
        },
        step4: {
          label: 'Passo 4',
          tip: 'Toque em Instalar e insira a senha do seu telefone',
        },
        step5: {
          label: 'Passo 5',
          tip: 'Toque em Instalar',
        },
        step6: {
          label: 'Passo 6',
          tip: 'Instalação concluída',
        },
        step7: {
          label: 'Passo 7',
          tip:
            'Abra "Configurações" > "Geral" > "Sobre" > "Confiar em Certificados", ative o certificado instalado anteriormente',
        },
      },
      windows: {
        step1: {
          label: 'Passo 1',
        },
        step2: {
          label: 'Passo 2',
          tip: 'Clique no certificado baixado e em "Instalar Certificado"',
        },
        step3: {
          label: 'Passo 3',
          tip: 'Selecione "Local Machine" para o "Local de Armazenamento" e clique em "Avançar"',
        },
        step4: {
          label: 'Passo 4',
          tip: 'Selecione "Colocar todos os certificados no seguinte armazenamento" e clique em "Procurar"',
        },
        step5: {
          label: 'Passo 5',
          tip: 'Selecione "Autoridades de Certificação Raiz Confiáveis" e clique em "OK"',
        },
        step6: {
          label: 'Passo 6',
          tip: 'Clique em "Avançar"',
        },
        step7: {
          label: 'Passo 7',
          tip: 'Clique em "Concluir", a instalação do certificado está concluída',
        },
      },
      mac: {
        step1: {
          label: 'Passo 1',
        },
        step2: {
          label: 'Passo 2',
          tip:
            'Clique no certificado baixado, o sistema exibirá uma janela "Adicionar Certificado" e selecione "Login" em "Chaveiro"',
        },
        step3: {
          label: 'Passo 3',
          tip: 'Na janela "Acesso a Chaveiro", selecione "Chaveiro Padrão" em "Barra Lateral"',
        },
        step4: {
          label: 'Passo 4',
          tip: 'Encontre o certificado "deeper.network" e clique com o botão direito para exibir "Mostrar Informações"',
        },
        step5: {
          label: 'Passo 5',
          tip: 'Clique em "Confiar" nas informações e marque "Sempre Confiar" para "Usar Sistema Padrão"',
        },
        step6: {
          label: 'Passo 6',
          tip: 'Feche a janela de informações, digite a senha do seu computador e clique em "Atualizar Configurações"',
        },
        step7: {
          label: 'Passo 7',
          tip: 'A instalação do certificado está concluída',
        },
      },
      linux: {
        rootTab: 'Tutorial de importação de certificado raiz do sistema',
        root: {
          step1: {
            label: 'Passo 1',
          },
          step2: {
            label: 'Passo 2',
            tip:
              '<p>Copie o arquivo do certificado para /usr/local/share/ca-certificates/<p>\
              <p>Este diretório é especificamente para armazenar certificados CA instalados localmente. O arquivo do certificado deve estar em formato PEM com a extensão .crt.</p>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /usr/local/share/ca-certificates/</code></div>',
          },
          step3: {
            label: 'Passo 3',
            tip:
              '<p>Actualizar el almacenamiento de certificados</p>\
              <p>Utilice el comando update-ca-certificates para actualizar el almacenamiento de certificados, esto actualizará automáticamente la cadena de confianza de los certificados en el sistema.</p>\
              <div class="code"><code>sudo update-ca-certificates</code></div>\
              <p>Este comando leerá los archivos de certificado en el directorio /usr/local/share/ca-certificates/ y los agregará a la lista de confianza del sistema.</p>\
              <em><em class="warning">Nota:</em> Diferentes distribuciones de Linux pueden tener diferentes ubicaciones de almacenamiento de certificados y comandos de actualización.</em><br>\
              <em>Por ejemplo, en los sistemas basados en Red Hat\
              (como Fedora, CentOS), es posible que necesite copiar el certificado a /etc/pki/ca-trust/source/anchors/,</em><br>\
              <em>y luego ejecute el comando update-ca-trust para actualizar el almacenamiento de certificados.</em><br>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /etc/pki/ca-trust/source/anchors/</code><br>\
              <code>sudo update-ca-trust</code></div>\
              <p>Después de ejecutar estos pasos, el certificado raíz que instaló debería haber sido confiado por el sistema.</p>',
          },
        },
        chrome: {
          step1: {
            label: 'Passo 1',
          },
          step2: {
            label: 'Passo 2',
            tip: 'Digite chrome://settings/certificates na barra de endereço e selecione a guia "<b>Autoridades</b>"',
          },
          step3: {
            label: 'Passo 3',
            tip: 'Clique em Importar, encontre o certificado baixado anteriormente e clique em Abrir',
          },
          step4: {
            label: 'Passo 4',
            tip:
              'Na janela "<b>Autoridades de Certificação</b>", marque todas as opções e clique em OK para concluir a instalação do certificado',
          },
        },
        firefox: {
          step1: {
            label: 'Passo 1',
          },
          step2: {
            label: 'Passo 2',
            tip:
              'Digite about:preferences#privacy na barra de endereço, encontre Certificados e clique em Ver Certificados',
          },
          step3: {
            label: 'Passo 3',
            tip: 'Selecione Autoridades e clique em Importar',
          },
          step4: {
            label: 'Passo 4',
            tip: 'Selecione o certificado baixado anteriormente e clique em Abrir',
          },
          step5: {
            label: 'Passo 5',
            tip: 'Marque todas as opções e clique em OK, a instalação do certificado está concluída',
          },
        },
      },
      android: {
        appTab: 'APP',
        browserTab: 'Navegador',
        app: {
          step1: {
            label: 'Passo 1',
          },
          step2: {
            label: 'Passo 2',
            tip: 'Instale o SkyTube e comece a desfrutar de vídeos sem interrupções de anúncios',
          },
        },
        browser: {
          step1: {
            label: 'Passo 1',
          },
          step2: {
            label: 'Passo 2',
            tip: 'Abra as "Configurações" do seu telefone e encontre "Segurança"',
          },
          step3: {
            label: 'Passo 3',
            tip: 'Acesse "Segurança" e encontre "Credenciais de Confiança e Criptografia", toque para entrar',
          },
          step4: {
            label: 'Passo 4',
            tip: 'Toque em Instalar Certificado',
          },
          step5: {
            label: 'Passo 5',
            tip: 'Selecione o certificado CA',
          },
          step6: {
            label: 'Passo 6',
            tip: 'Toque em Instalar mesmo assim e insira a senha do seu telefone',
          },
          step7: {
            label: 'Passo 7',
            tip: 'Selecione o certificado baixado',
          },
          step8: {
            label: 'Passo 8',
            tip: 'Aparecerá a mensagem "Certificado CA instalado com êxito"',
          },
        },
      },
    },
  },
  // SSLBypassCheckbox
  SSLBypassCheckbox: {
    sslBypass: 'Ativar desvio de SSL',
    sslBypassTips: 'Permite que dispositivos sem certificado se conectem à rede.',
    sslBypassWaring: 'Se o desvio de SSL estiver ativado, a filtragem de anúncios pode ser menos eficaz.',
    success: 'Operação bem-sucedida',
    failed: 'Operação falhou',
  },
  // tunnel
  tunnel: {
    configuredTunnel: 'Túnel personalizado',
    countryTooltip:
      'Faça uma busca difusa pelo nome do país ou digite o código do país (em letras maiúsculas) e pressione Enter para procurar o país desejado',
    region: 'Região',
    country: 'País ou região',
    activeTunnelNum: 'Número de IPs ativos',
    activeIp: 'IP ativo',
    switchNode: 'Trocar IP ativo',
    switchIpTitle: 'Trocar IP',
    initTunnelTitle: 'Reinicializar túnel',
    initTunnelTitleTips: 'Um túnel possui 3 IPs. Ao atualizar o túnel, serão fornecidos três novos IPs.',
    selectRegion: 'Selecione a região',
    selectCountry: 'Selecione o país ou região',
    missingInfoWarning: 'Preencha todas as informações',
    tunnelAddSuccess: 'Túnel adicionado com sucesso. A página será atualizada em 3 segundos.',
    tunnelExist: 'Túnel já existe',
    reachedMax: 'Número máximo de túneis alcançado',
    tunnelConflict: 'O túnel que você deseja adicionar entra em conflito com o túnel existente {0} - {1}',
    tunnelAddError: 'Erro ao adicionar túnel',
    tunnelDelSuccess: 'Túnel excluído com sucesso. A página será atualizada em 3 segundos.',
    tunnelNotExist: 'Túnel não existe',
    usedByFullMode: 'O túnel está sendo usado pelo DPN em modo de roteamento completo e não pode ser excluído',
    usedByAccessControl: 'Os túneis a serem excluídos são usados pelo Controle de Acesso',
    usedByBackup: 'O túnel de backup está usando este túnel e não pode ser excluído',
    tunnelInUse: 'O túnel está sendo usado pela tabela de roteamento e não pode ser excluído',
    tunnelDelError: 'Erro ao excluir túnel',
    switchNodeSuccess: 'Nó trocado com sucesso. A página será atualizada em 3 segundos.',
    noActiveIp: 'Não há IP ativo para trocar',
    ipNotChanged: 'IP não foi alterado',
    switchNodeError: 'Erro ao trocar o nó',
    refreshLabel: 'Atualizar',
    getNewIPLabel: 'Obter novo IP',
    refreshError: 'Falha ao atualizar o túnel',
    refreshSuccess: 'Solicitação de atualização de túnel enviada com sucesso. A página será atualizada em 3 segundos.',
    tips: {
      refreshTunnel: 'Atualize o túnel com novos IPs ativos.',
      configuredTunnel:
        'Após adicionar manualmente o túnel de região desejado, o AtomOS pesquisará nós que atendam aos seus requisitos em todo o mundo.',
    },
    nodeLbMode: {
      label: 'Modo de seleção de nó',
      none: {
        label: 'Normal',
        desc:
          'Dentro do túnel, todas as conexões de rede passam por um nó ativo e você pode trocar o nó a qualquer momento.',
      },
      random: {
        label: 'Aleatório',
        desc: 'O sistema seleciona automaticamente um nó aleatório para cada conexão.',
      },
      domain: {
        label: 'Percepção de domínio',
        desc:
          'O sistema usa vários nós dentro do túnel para acessar a rede, mas sempre roteia o acesso a um domínio específico para o mesmo nó, reduzindo o risco de erros de acesso ao site.',
      },
      rr: {
        label: 'Round-robin',
        desc:
          'A cada conexão, o sistema seleciona em sequência entre vários nós, proporcionando um melhor desempenho de rede.',
      },
      failure: 'Falha ao salvar o modo de balanceamento de carga do nó',
      success: 'Modo de balanceamento de carga do nó salvo com sucesso',
    },
  },
  // userConsent
  userConsent: {
    notice:
      'Aviso: O recurso de compartilhamento está desativado até que você concorde com os Termos de Uso e a Política de Privacidade.',
    consentStart: 'Li e concordo com os ',
    termsOfUseNotRead: 'Você ainda não leu os Termos de Uso',
    termsOfUseLink: 'https://shop.deeper.network/terms-of-service?lang=pt',
    termsOfUseTitle: 'Termos de Uso',
    consentAnd: ' e ',
    privacyPolicyNotRead: 'Você ainda não leu a Política de Privacidade',
    privacyPolicyLink: 'https://shop.deeper.network/privacy-policy?lang=pt',
    privacyPolicyTitle: 'Política de Privacidade',
    confirmBtn: 'Confirmar',
    consentError: 'Falha ao confirmar o consentimento',
  },
  // customConfigLang
  customConfigLang: {
    missingInfoWarning: 'Falta de informações de domínio',
    invalidDomainError: 'Formato de domínio inválido ou domínio muito longo',
    whitelist: 'Lista branca',
    blacklist: 'Lista negra',
    domain: 'Domínio',
    whitelistAddSuccess: 'Adicionado à lista branca com sucesso',
    whitelistEntryExist: 'Entrada já existe na lista branca',
    reachedWhiteMax: 'Limite máximo da lista branca alcançado',
    whitelistAddError: 'Falha ao adicionar entrada à lista branca',
    blacklistAddSuccess: 'Adicionado à lista negra com sucesso',
    blacklistEntryExist: 'Entrada já existe na lista negra',
    reachedBlackMax: 'Limite máximo da lista negra alcançado',
    blacklistAddError: 'Falha ao adicionar entrada à lista negra',
    whitelistDelSuccess: 'Entrada da lista branca excluída com sucesso',
    whitelistDelError: 'Falha ao excluir entrada da lista branca',
    blacklistDelSuccess: 'Entrada da lista negra excluída com sucesso',
    blacklistDelError: 'Falha ao excluir entrada da lista negra',
    import: 'Importar',
    fileButtonText: 'Clique para enviar',
    fileSizeOverLimit: 'O arquivo enviado excede o tamanho máximo de 2MB',
    whitelistImportSuccess: 'Lista branca importada com sucesso. Foram importadas {0} entradas',
    whitelistImportError: 'Falha ao importar lista branca',
    blacklistImportSuccess: 'Lista negra importada com sucesso. Foram importadas {0} entradas',
    blacklistImportError: 'Falha ao importar lista negra',
    noValidEntry: 'O arquivo enviado não contém nenhum domínio válido',
    zeroImported: 'Nenhuma entrada foi importada',
  },
  // defaultConfigLang
  defaultConfigLang: {
    'browserCacheWarning':
      'Se a filtragem de anúncios DNS estiver ativada e os anúncios não puderem ser filtrados corretamente, é necessário limpar o cache do navegador e reiniciar o dispositivo filtrado.',
    queriesFor24Hours: 'Consultas nas últimas 24 horas',
    totalQuery: 'Total de consultas',
    blockedQuery: 'Consultas bloqueadas',
    blockedPercentage: 'Porcentagem de bloqueio',
    ruleTotal: 'Total de regras',
    adQuery: 'Consultas de anúncios bloqueadas',
    trackerQuery: 'Consultas de rastreadores bloqueadas',
    maliciousQuery: 'Consultas maliciosas bloqueadas',
    pornQuery: 'Consultas de conteúdo adulto bloqueadas',
    legendTotal: 'Total',
    legendBlocked: 'Bloqueado',
    categoryStateEnabled: 'Ativado',
    categoryStateDisabled: 'Desativado',
    categoryState2Hours: 'Desativado por 2 horas',
    categoryState4Hours: 'Desativado por 4 horas',
    categoryState8Hours: 'Desativado por 8 horas',
    setCategoryStatesErr: 'Erro ao definir estados de categoria',
    enableAll: 'Ativar todas as filtragens',
    disableAll: 'Desativar todas as filtragens',
  },
  // wifiSettings
  wifiSettings: {
    workMode: 'Modo de trabalho',
    currentWorkMode: 'Modo de trabalho atual',
    upstreamTitle: 'Configuração de upstream',
    backHome: 'Voltar para a página inicial',
    'wire-ap': 'WAN com fio',
    'wlan-ap': 'WAN sem fio',
    downstreamTitle: 'LAN sem fio',
    notConnect: 'Não conectado à internet',
    apConfigWarning:
      'Após salvar o SSID e a senha do Wi-Fi, se você quiser usar a internet através do dispositivo Deeper, será necessário escanear novamente o hotspot do dispositivo Deeper e aplicar a senha.',
    attention: 'Atenção',
    buttons: {
      scanWifi: 'Escanear',
      connectStatus: 'Status',
      add: 'Adicionar',
      save: 'Salvar',
      cancel: 'Cancelar',
      downloadLog: 'Baixar logs',
    },
    connect: 'Conectar',
    forget: 'Esquecer',
    waiting: {
      content: 'Conectando, isso pode levar algum tempo. Por favor, aguarde...',
      cancelBtn: 'Cancelar conexão',
    },
    modals: {
      hiddenWifi: {
        title: 'Adicionar Wi-Fi oculto',
        okBtn: 'Adicionar',
      },
      scanWifi: {
        title: 'Aviso',
        content: 'A conexão será temporariamente interrompida',
        ok: 'OK',
        cancel: 'Cancelar',
      },
    },
    label: {
      ssid: 'Nome do Wi-Fi (SSID)',
      password: 'Senha do Wi-Fi',
      onlyUse: 'Apenas usar',
      hidden: 'SSID oculto',
      freqBand: 'Faixa de frequência',
    },
    placeholder: {
      ssid: 'Digite o nome do Wi-Fi',
      password: 'Digite a senha',
    },
    message: {
      saveSuccess: 'Salvo com sucesso',
      saveError: 'Falha ao salvar. Por favor, tente novamente',
      connectSuccess: 'Conectado com sucesso',
      forgetSuccess: 'Esquecido com sucesso',
      inputError: 'Verifique sua entrada',
      invalidWifiDev: 'Não há dispositivos Wi-Fi disponíveis para configuração',
      illegalWifiDev: 'A configuração do Wi-Fi só pode ser usada no modo AP Router e Wireless Relay',
      invalidSsid: 'Apenas letras, números, _ e - são suportados',
      invalidPassword: 'Digite uma senha válida',
      notConnect: 'Não conectado à internet',
      retry: 'Tente novamente',
      connectionError: 'Falha na conexão',
      forgetError: 'Falha ao esquecer',
      wifiNotSupported: 'Wi-Fi não suportado',
      networkNotFound: 'Rede não encontrada',
      pskNotGenerated: 'Chave PSK não gerada',
      wrongPassword: 'Senha incorreta',
      timeout: 'Tempo limite de conexão excedido',
      logDownloadError: 'Falha ao baixar logs',
      hasBeenConnected: 'Conectado.',
      dhcpSuccess: 'Sucesso',
      dhcpFailure: 'Falha',
    },
    connectionRequestedTitle: 'Solicitação de conexão recebida',
    connectionRequestedContent:
      'Verifique o status da sua conexão na opção "Status". Se você estiver desconectado do Deeper Connect, tente se reconectar ao DeeperWiFi.',
    gotIpFromDhcpLabel: 'Obteve endereço IP do DHCP',
  },
  // parentalControlData
  parentalControlData: {
    browserCacheWarning:
      'Se o controle dos pais estiver ativado e não for possível bloquear sites adultos ou de mídia social, você precisa limpar o cache do navegador e reiniciar o dispositivo filtrado.',
    customerRule:
      'Aqui você pode bloquear a maioria dos sites adultos e de mídia social para seus filhos. Se você quiser bloquear outros sites, você pode usar a função de bloqueio de domínio personalizado para adicionar mais sites.',
    customerRuleClickTips: 'Por favor, {0} para acessar a página de bloqueio de domínio personalizado',
    toCustomerRuleButtonText: 'clique aqui',
    parentalControl: 'Controle dos pais',
    pornQuery: 'Bloquear conteúdo adulto',
    socialQuery: 'Bloquear mídia social',
    legendBlocked: 'Bloqueado',
    // social
    socialStateEnabled: 'Ativado',
    socialStateDisabled: 'Desativado',
    socialState2Hours: 'Desativado por 2 horas',
    socialState4Hours: 'Desativado por 4 horas',
    socialState8Hours: 'Desativado por 8 horas',

    // porn
    pornStateEnabled: 'Ativado',
    pornStateDisabled: 'Desativado',
    pornState2Hours: 'Desativado por 2 horas',
    pornState4Hours: 'Desativado por 4 horas',
    pornState8Hours: 'Desativado por 8 horas',
    setCategoryStatesErr: 'Erro ao definir estados de categoria',
  },
  reboot: {
    success: {
      saveConfig: 'Salvo com sucesso',
    },
    failure: {
      getConfig: 'Falha ao obter a configuração de reinicialização automática',
      saveConfig: 'Falha ao salvar a configuração de reinicialização automática',
    },
    buttons: {
      reboot: 'Clique para reiniciar',
      save: 'Salvar',
    },
    week: {
      0: 'Todos os dias',
      1: 'Segunda-feira',
      2: 'Terça-feira',
      3: 'Quarta-feira',
      4: 'Quinta-feira',
      5: 'Sexta-feira',
      6: 'Sábado',
      7: 'Domingo',
    },
    title: {
      manual: 'Reinicie agora',
      auto: 'Reinicialização agendada',
    },
    label: {
      period: 'Período',
      time: 'Hora',
    },
  },
};
