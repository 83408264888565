export default {
  'internationalize': {
    'type': 'japanese',
    'common': {
      'tip_text': 'ヒント',
      'search': '検索',
      'add': '追加',
      'delete': '削除',
      'confirm': '確認',
      'cancel': 'キャンセル',
      'warning': '警告',
      'utcDisclaimer': '日付はUTCです',
      'toOpen': '開く',
    },
    certificateDialog: {
      title: '新しいルートCA証明書',
      content: 'ルートCA証明書が交換されました。再ダウンロードしてインストールしてください。',
      dontShowAgain: '再表示しない',
    },
    'header': {
      'logout': 'ログアウト',
      'changePassword': 'パスワード変更',
      'userManual': 'ユーザーマニュアル',
      'defaultAvatar': 'デフォルトのアバター',
      'english': '英語',
      'german': 'ドイツ語',
      'korean': '韓国語',
      'french': 'フランス語',
      'spain': 'スペイン語',
      'portugal': 'ポルトガル語',
      'japanese': '日本語',
      'Tchinese': '繁体字中国語',
      'russian': 'ロシア語',
      'hindi': 'ヒンディー語',
      'simplechinese': '簡体字中国語',
    },
    'home': {
      'name': 'ダッシュボード',
      'children': {},
      'content': {
        'tipInput': '入力してください',
        'ok': 'OK',
        'cancel': 'キャンセル',
        'currentStatus': '現在の状態',
        'statusMap': {
          'Trial': '試用期間',
          'Valid': '有効',
          'Expired': '期限切れ',
        },
        'btnActive': 'アクティベート',
        'btnEdit': '編集',
        'expireDate': '有効期限',
        'licenseKey': 'ライセンスキー',
      },
    },
    accessControl: {
      name: 'アクセス制御',
    },
    'tpProxy': {
      'name': '透明プロキシ',
      'children': {
        'customRules': {
          'name': 'カスタムルール',
        },
        'builtinRules': {
          'name': '広告フィルター',
        },
        'certificate': {
          'name': '証明書のインストール',
        },
      },
    },
    'setting': {
      'name': '設定',
      'children': {
        'wifi': {
          'name': 'Wi-Fi',
        },
        'sharing': {
          'name': '共有',
          'children': {
            'configure': {
              'name': '共有設定',
            },
            'blacklist': {
              'name': 'ブラックリストの共有',
            },
            'record': {
              'name': '共有ログ',
            },
            'traffic': {
              'name': '共有トラフィック',
            },
          },
          'content': {},
        },
        'rustdesk': {
          'name': 'RustDesk',
          'children': {
            'config': {
              'name': '設定',
            },
            'log': {
              'name': 'ログ',
            },
          },
        },
        'admin': {
          'name': '管理',
          'content': {},
          'children': {
            'changePassword': {
              'name': 'パスワード',
            },
            'reboot': {
              'name': '再起動',
              'content': {
                'confirmText': '再起動しますか？',
                'rebootLoading': 'デバイスの再起動には数分かかります。この間、ネットワーク接続が一時的に中断されます。',
              },
            },
          },
        },
      },
    },
    'help': {
      'name': 'ヘルプ',
      'children': {
        'techSupport': {
          'name': 'テクニカルサポート',
        },
      },
    },
    'device': {
      'name': 'デバイス',
      'children': {
        'system': {
          'name': 'システム',
        },
        'traffic': {
          'name': 'トラフィック',
        },
        'notification': {
          'name': 'ログ',
        },
      },
      'content': {},
    },
    'dpn': {
      'name': 'DPN',
      'content': {},
      'children': {
        'smartRoute': {
          'name': 'モード',
        },
        'tunnel': {
          'name': 'トンネル',
        },
        'routingTable': {
          'name': 'スマートルーティング',
          'content': {},
          'children': {
            'domainConfig': {
              'name': 'ドメイン設定',
            },
            'ipConfig': {
              'name': 'IP設定',
            },
            'queryRouting': {
              'name': 'クエリ',
            },
          },
        },
      },
    },
    'appRelocator': {
      'name': 'アプリ移動ツール',
    },
    'adsBlock': {
      'name': '広告ブロッカー',
      'children': {
        'domainFilter': {
          'name': 'DNSフィルター',
        },
        'httpsFilter': {
          'name': 'HTTPSフィルター',
        },
      },
      httpssucceed: 'HTTPS フィルタリングが正常に有効になりました',
      httpslose: 'HTTPS フィルタリングが正常に無効になりました',
      saverror: '操作に失敗しました',
      usedByAccessControl: 'HTTPS フィルターはアクセス制御によって使用されます',
    },
    'wallets': {
      'name': 'Wallets',
      'children': {
        'wallets': 'Wallets',
        'keys': 'Keys',
        'funds': 'Funds',
      },
      'content': {},
    },
    'dpr': {
      'name': 'マイニング',
      'children': {
        'dpr': {
          'name': 'DPR',
          'children': {
            'stakingTutorial': {
              'name': 'ステーキングチュートリアル',
            },
            'wallet': {
              'name': 'ウォレット',
              'manager': 'ウォレットマネージャー',
              'balance': 'ウォレット残高',
            },
            'Staking Mining': 'ステーキングマイニング',
            'transaction': {
              'id': 'トランザクションID',
              'from': '送信元',
              'to': '送信先',
              'time': '時間 (UTC)',
              'rewardTime': '報酬時間 (UTC)',
              'amount': 'DPR数量',
              'Reward in selected period': '選択期間の報酬',
              'Total reward': '総報酬',
              'Balance Transfer': 'バランストランスファー',
              'Staking Reward': 'ステーキング報酬',
              'PoS Reward': 'PoS報酬',
              'Micro Payment': 'マイクロペイメント',
              'stakeRelease': 'ステーク解放',
              'Transaction': 'トランザクション',
              'Mining': 'マイニング',
              'Traffic Mining': 'トラフィックマイニング',
              'Staking Mining': 'ステーキングマイニング',
            },
          },
        },
      },
    },
    'security': {
      'name': 'セキュリティ',
      'children': {
        'applications': 'アプリ制御',
        'traffic': 'トラフィック整形',
        'webfilter': {
          'name': 'ウェブフィルター',
          'children': {
            'report': {
              'name': 'ダッシュボード',
            },
            'config': {
              'name': 'カスタマイズDNSルール',
            },
          },
        },
        'sslFilter': {
          'name': 'SSLフィルター',
          'children': {
            'customDNSrules': {
              'name': 'カスタマイズDNSルール',
            },
            'customHTTPSRules': {
              'name': 'カスタマイズHTTPSルール',
            },
          },
        },
      },
      'content': {},
    },
    'parentalControl': {
      'name': 'ペアレンタルコントロール',
    },
    'regionCountryTexts': {
      'LL': 'ダイレクトアクセス',
      'ANY': '任意の国',
      // regions
      'AMER': '---アメリカ---',
      'ASIA': '---アジア---',
      'AFRI': '---アフリカ---',
      'EURO': '---ヨーロッパ---',
      'OCEA': '---オセアニア---',
      'AMN': '北アメリカ',
      'AMC': 'カリブ海',
      'AMM': '中央アメリカ',
      'AMS': '南アメリカ',
      'ASC': '中央アジア',
      'ASE': '東アジア',
      'ASW': '西アジア',
      'ASS': '南アジア',
      'ASD': '東南アジア',
      'AFN': '北アフリカ',
      'AFM': '中部アフリカ',
      'AFE': '東アフリカ',
      'AFW': '西アフリカ',
      'AFS': '南アフリカ',
      'EUN': '北ヨーロッパ',
      'EUE': '東ヨーロッパ',
      'EUW': '西ヨーロッパ',
      'EUS': '南ヨーロッパ',
      'OCP': 'ポリネシア',
      'OCA': 'オーストラリア & ニュージーランド',
      'OCM': 'メラネシア',
      'OCN': 'ミクロネシア',
      // countries
      'AD': 'アンドラ',
      'AE': 'アラブ首長国連邦',
      'AF': 'アフガニスタン',
      'AG': 'アンティグア・バーブーダ',
      'AI': 'アンギラ',
      'AL': 'アルバニア',
      'AM': 'アルメニア',
      'AO': 'アンゴラ',
      'AR': 'アルゼンチン',
      'AS': 'アメリカ領サモア',
      'AT': 'オーストリア',
      'AU': 'オーストラリア',
      'AW': 'アルバ',
      'AX': 'オーランド諸島',
      'AZ': 'アゼルバイジャン',
      'BA': 'ボスニア・ヘルツェゴビナ',
      'BB': 'バルバドス',
      'BD': 'バングラデシュ',
      'BE': 'ベルギー',
      'BF': 'ブルキナファソ',
      'BG': 'ブルガリア',
      'BH': 'バーレーン',
      'BI': 'ブルンジ',
      'BJ': 'ベナン',
      'BL': 'サン・バルテルミー',
      'BM': 'バミューダ',
      'BN': 'ブルネイ・ダルサラーム',
      'BO': 'ボリビア',
      'BQ': 'ボネール、シント・ユースタティウスおよびサバ',
      'BR': 'ブラジル',
      'BS': 'バハマ',
      'BT': 'ブータン',
      'BW': 'ボツワナ',
      'BY': 'ベラルーシ',
      'BZ': 'ベリーズ',
      'CA': 'カナダ',
      'CC': 'ココス（キーリング）諸島',
      'CD': 'コンゴ民主共和国',
      'CF': '中央アフリカ共和国',
      'CG': 'コンゴ共和国',
      'CH': 'スイス',
      'CI': 'コートジボワール',
      'CK': 'クック諸島',
      'CL': 'チリ',
      'CM': 'カメルーン',
      'CN': '中国',
      'CO': 'コロンビア',
      'CR': 'コスタリカ',
      'CU': 'キューバ',
      'CV': 'カーボベルデ',
      'CW': 'キュラソー',
      'CX': 'クリスマス島',
      'CY': 'キプロス',
      'CZ': 'チェコ',
      'DE': 'ドイツ',
      'DJ': 'ジブチ',
      'DK': 'デンマーク',
      'DM': 'ドミニカ国',
      'DO': 'ドミニカ共和国',
      'DZ': 'アルジェリア',
      'EC': 'エクアドル',
      'EE': 'エストニア',
      'EG': 'エジプト',
      'ER': 'エリトリア',
      'ES': 'スペイン',
      'ET': 'エチオピア',
      'FI': 'フィンランド',
      'FJ': 'フィジー',
      'FK': 'フォークランド諸島（マルビナス）',
      'FM': 'ミクロネシア連邦',
      'FO': 'フェロー諸島',
      'FR': 'フランス',
      'GA': 'ガボン',
      'GB': 'イギリス',
      'GD': 'グレナダ',
      'GE': 'ジョージア',
      'GF': 'フランス領ギアナ',
      'GG': 'ガーンジー',
      'GH': 'ガーナ',
      'GI': 'ジブラルタル',
      'GL': 'グリーンランド',
      'GM': 'ガンビア',
      'GN': 'ギニア',
      'GP': 'グアドループ',
      'GQ': '赤道ギニア',
      'GR': 'ギリシャ',
      'GS': '南ジョージア島・南サンドイッチ諸島',
      'GT': 'グアテマラ',
      'GU': 'グアム',
      'GW': 'ギニアビサウ',
      'GY': 'ガイアナ',
      'HK': '香港（中国）',
      'HN': 'ホンジュラス',
      'HR': 'クロアチア',
      'HT': 'ハイチ',
      'HU': 'ハンガリー',
      'ID': 'インドネシア',
      'IE': 'アイルランド',
      'IL': 'イスラエル',
      'IM': 'マン島',
      'IN': 'インド',
      'IO': '英領インド洋地域',
      'IQ': 'イラク',
      'IR': 'イラン',
      'IS': 'アイスランド',
      'IT': 'イタリア',
      'JE': 'ジャージー',
      'JM': 'ジャマイカ',
      'JO': 'ヨルダン',
      'JP': '日本',
      'KE': 'ケニア',
      'KG': 'キルギス',
      'KH': 'カンボジア',
      'KI': 'キリバス',
      'KM': 'コモロ',
      'KN': 'セントクリストファー・ネイビス',
      'KR': '韓国',
      'KW': 'クウェート',
      'KY': 'ケイマン諸島',
      'KZ': 'カザフスタン',
      'KP': '北朝鮮',
      'LA': 'ラオス人民民主共和国',
      'LB': 'レバノン',
      'LC': 'セントルシア',
      'LI': 'リヒテンシュタイン',
      'LK': 'スリランカ',
      'LR': 'リベリア',
      'LS': 'レソト',
      'LT': 'リトアニア',
      'LU': 'ルクセンブルグ',
      'LV': 'ラトビア',
      'LY': 'リビア',
      'MA': 'モロッコ',
      'MC': 'モナコ',
      'MD': 'モルドバ共和国',
      'ME': 'モンテネグロ',
      'MF': 'サン・マルタン（フランス領）',
      'MG': 'マダガスカル',
      'MH': 'マーシャル諸島',
      'MK': '北マケドニア',
      'ML': 'マリ',
      'MM': 'ミャンマー',
      'MN': 'モンゴル',
      'MO': 'マカオ（中国）',
      'MP': '北マリアナ諸島',
      'MQ': 'マルティニーク',
      'MR': 'モーリタニア',
      'MS': 'モントセラト',
      'MT': 'マルタ',
      'MU': 'モーリシャス',
      'MV': 'モルディブ',
      'MW': 'マラウイ',
      'MX': 'メキシコ',
      'MY': 'マレーシア',
      'MZ': 'モザンビーク',
      'NA': 'ナミビア',
      'NC': 'ニューカレドニア',
      'NE': 'ニジェール',
      'NF': 'ノーフォーク島',
      'NG': 'ナイジェリア',
      'NI': 'ニカラグア',
      'NL': 'オランダ',
      'NO': 'ノルウェー',
      'NP': 'ネパール',
      'NR': 'ナウル',
      'NU': 'ニウエ',
      'NZ': 'ニュージーランド',
      'OM': 'オマーン',
      'PA': 'パナマ',
      'PE': 'ペルー',
      'PF': 'フランス領ポリネシア',
      'PG': 'パプアニューギニア',
      'PH': 'フィリピン',
      'PK': 'パキスタン',
      'PL': 'ポーランド',
      'PM': 'サンピエール島・ミクロン島',
      'PN': 'ピトケアン諸島',
      'PR': 'プエルトリコ',
      'PS': 'パレスチナ',
      'PT': 'ポルトガル',
      'PW': 'パラオ',
      'PY': 'パラグアイ',
      'QA': 'カタール',
      'RE': 'レユニオン',
      'RO': 'ルーマニア',
      'RS': 'セルビア',
      'RU': 'ロシア連邦',
      'RW': 'ルワンダ',
      'SA': 'サウジアラビア',
      'SB': 'ソロモン諸島',
      'SC': 'セーシェル',
      'SD': 'スーダン',
      'SE': 'スウェーデン',
      'SG': 'シンガポール',
      'SH': 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
      'SI': 'スロベニア',
      'SJ': 'スバールバル諸島とヤンマイエン島',
      'SK': 'スロバキア',
      'SL': 'シエラレオネ',
      'SM': 'サンマリノ',
      'SN': 'セネガル',
      'SO': 'ソマリア',
      'SR': 'スリナム',
      'SS': '南スーダン',
      'ST': 'サントメ・プリンシペ',
      'SV': 'エルサルバドル',
      'SX': 'シント・マールテン（オランダ領）',
      'SY': 'シリア・アラブ共和国',
      'SZ': 'エスワティニ',
      'TC': 'タークス・カイコス諸島',
      'TD': 'チャド',
      'TF': 'フランス領南方・南極地域',
      'TG': 'トーゴ',
      'TH': 'タイ',
      'TJ': 'タジキスタン',
      'TK': 'トーゴ',
      'TL': '東ティモール',
      'TM': 'トルクメニスタン',
      'TN': 'チュニジア',
      'TO': 'トンガ',
      'TR': 'トルコ',
      'TT': 'トリニダード・トバゴ',
      'TV': 'ツバル',
      'TW': '台湾（中国）',
      'TZ': 'タンザニア',
      'UA': 'ウクライナ',
      'UB': 'アメリカ西部',
      'UC': 'アメリカ中西部',
      'UD': 'アメリカ南西部',
      'UE': 'アメリカ北東部',
      'UF': 'アメリカ南東部',
      'UG': 'ウガンダ',
      'US': 'アメリカ合衆国',
      'UY': 'ウルグアイ',
      'UZ': 'ウズベキスタン',
      'VA': 'バチカン市国',
      'VC': 'セントビンセントおよびグレナディーン諸島',
      'VE': 'ベネズエラ',
      'VG': '英領ヴァージン諸島',
      'VI': '米領ヴァージン諸島',
      'VN': 'ベトナム',
      'VU': 'バヌアツ',
      'WF': 'ワリス・フツナ',
      'WS': 'サモア',
      'XK': 'コソボ',
      'YE': 'イエメン',
      'YT': 'マヨット',
      'ZA': '南アフリカ',
      'ZM': 'ザンビア',
      'ZW': 'ジンバブエ',
    },
  },
  'type': 'japanese',
  'request_tip': {
    'error': 'request Error~',
  },
  // dashboard
  'dashboard': {
    'name': 'ダッシュボード',
    'children': {},
    'content': {
      'backupTunnelLabel': 'バックアップトンネル',
      'tipInput': '入力してください',
      'hotspot': 'グローバル接続',
      'realnetworkflow': 'リアルタイムトラフィック統計',
      'memory': 'メモリ',
      'cpu': 'CPU',
      'notification': '通知',
      'systeminfo': 'システム情報',
      'upload': 'アップロード',
      'download': 'ダウンロード',
      'systemuptime': 'システム稼働時間',
      'uploadtotal': 'アップロード総量',
      'downloadtotal': 'ダウンロード総量',
      'softwareVersion': 'ソフトウェアバージョン',
      'publicIp': 'パブリックIP',
      'unit': 'ユニット',
      'currentDpnMode': '現在のDPNモード',
      'ok': 'OK',
      'cancel': 'キャンセル',
      'currentStatus': '現在のステータス',
      'statusMap': {
        'Trial': 'トライアル',
        'Valid': '有効',
        'Expired': '期限切れ',
      },
      'btnActive': 'アクティブ化',
      'btnEdit': '編集',
      'expireDate': '有効期限',
      'licenseKey': 'ライセンスキー',
      'systemTraffic': 'リアルタイムシステムトラフィック統計',
      'appTraffic': 'リアルタイムアプリケーショントラフィック統計',
    },
  },
  accessControl: {
    'content': {
      banner: {
        title: 'アクセス制御',
        description: 'LANデバイスの各種機能の有効・無効を一括制御するスイッチです。',
      },
      'onlineDevice': 'オンラインデバイス',
      'offlineDevice': 'オフラインデバイス',
      'deviceName': 'デバイス名',
      unknownDevice: '未知',
      'macAddress': 'MAC アドレス',
      'ipAddress': 'IP アドレス',
      'enableHttpsFilter': 'HTTPS フィルターの有効化',
      'routeMode': 'DPN モード',
      'routeModeOptions': {
        'smart': 'スマート ルーティング モード',
        'full': 'フル ルーティング',
        'disabled': '無効',
        blackHole: 'ブラックホール',
      },
      'remark': 'メモ',
      pinned: 'トップにピン留め',
      'httpsFilterTip': 'HTTPS フィルターを有効にすると、すべての広告がフィルターされます。',
      'routeModeTip': 'このオプションを使用すると、DPN の動作モードを変更できます。',
      'tunnelTip': 'フル ルーティング トンネルを選択してください。',
      'success': {
        getList: 'デバイスリストが正常に更新されました',
        'httpsFilter': 'HTTPS フィルターの有効化に成功しました。',
        'routeMode': 'DPN モードの変更に成功しました。',
        'regionCode': 'DPN モードの変更に成功しました。',
        'remark': 'メモの保存に成功しました。',
        pinned: 'ピン留め/ピン留め解除が成功しました。リフレッシュしてください',
        'deleteDevices': 'デバイスの削除に成功しました',
      },
      'failure': {
        'getSwitch': 'アクセス制御スイッチの取得に失敗しました',
        'setSwitch': 'アクセス制御スイッチの設定に失敗しました',
        'getList': 'アクセス制御リストの取得に失敗しました。',
        'getTunnels': 'トンネルの取得に失敗しました。',
        'setItem': 'アクセス制御項目の設定に失敗しました。',
        'httpsFilter': 'HTTPS フィルターの有効化に失敗しました。',
        'routeMode': 'DPN モードの変更に失敗しました。',
        'regionCode': 'DPN モードの変更に失敗しました。',
        'remark': 'メモの保存に失敗しました。',
        'noSuchTunnel': 'トンネルは存在しません。別のトンネルを選択してください',
        pinned: 'ピン留め/ピン留め解除に失敗しました',
        'deleteDevices': 'デバイスの削除に失敗しました',
        'macActive': 'デバイスがアクティブなため削除できません',
      },
      'remarkLengthLimit': '長さは 10 文字を超えてはなりません。',
      'httpsNotEnabled':
        'HTTPS フィルターのメイン スイッチがオフになっています。このページにアクセスしてオンにしてください！',
      'tips': {
        'opened':
          'アクセス制御がオンになっている場合、オンライン デバイスはアクセス制御で DPN モードを優先的に使用します。',
      },
    },
  },
  //dpnMode
  'dpnMode': {
    'title': 'DPNモードを選択',
    'selectTunnel': 'トンネルを選択',
    'recommended': '推奨',
    'apply': '適用',
    'missingInfo': '情報不足',
    'selectionTip': {
      'smart': 'スマートルートモードに切り替えますか？',
      'full': '全てのトラフィックが選択したトンネルを経由します。フルルートモードに切り替えますか？',
      'disabled': 'DPNを通じてトラフィックは流れません。DPN無効モードに切り替えますか？',
    },
    'dpnModeShortList': {
      'smart': 'DPNスマートルート',
      'disabled': 'DPN無効',
    },
    'dpnModeLongList': {
      'smart': 'スマートルート',
      'full': 'フルルート',
      'disabled': '無効',
    },
    'killSwitchLabel': 'キルスイッチ',
    'none': '無効化',
    'backupTunnelLabel': 'バックアップトンネル',
    'backupTunnelTip':
      'スマートルーティングモードで設定されたトンネルに問題が発生した場合、デフォルトのトンネルが正常に動作するまで、システムは自動的にバックアップトンネルに切り替わります。',
    'tips': {
      'killSwitch':
        'VPNキルスイッチは、VPN接続が切断された場合に自動的にコンピュータ、電話、またはタブレットをインターネットから切断する機能です。これにより、VPNが機能しなくなった場合にIPアドレスやその他の機密情報が露出する可能性がありません。そのため、インターネット接続のセキュリティと匿名性が損なわれることはありません。',
      'smart': [
        '1. AtomOSの内蔵ルールに基づいて、現在アクセスしているウェブサイトにインテリジェントマッチングノードを割り当て、地元のネットワーク速度を確保しつつ、他国および地域のサービスへのアクセスを加速します。',
        '2. 「DPNスマートルート」モードでは、従来のVPNのように国を頻繁に切り替える手間をなくすだけでなく、ユーザーにより高速なネットワークブラウジング体験を提供します。',
        '3. Deeper Connectには、いくつかの有名なウェブサイトやサービスのための内蔵ルーティングルールがあります。スマートルーティングのためのルールをカスタマイズしたい場合は、「App Relocator」と「DPN - Routing」で設定できます。',
        '4. ユーザーのフィードバックに基づいて内蔵ルーティングルールを強化し続け、「App Relocator」機能でより多くのアプリケーションをサポートする予定です。',
      ],
      'full': [
        '1. AtomOSを介して処理されるすべてのネットワークデータは、指定されたトンネルを経由してルーティングされます。これは、従来のVPNの使用パターンと似ています。',
        '2. 注意：「DPNフルルート」モードを採用すると、「App Relocator」と「DPN - Routing」で行った設定が上書きされます。',
        '3. ネットワークの分散化の性質上、ネットワーク内のノードは高速サーバーではなく、世界中のユーザーデバイスから来ています。その結果、インターネット速度に影響を及ぼす可能性があります。',
        '4. 「DPNフルルート」モードを使用し、高速インターネット体験を望む場合、将来的にVIPユーザー向けに特定の高速ノードを提供することを検討します。',
      ],
      'disabled': [
        '1.「DPN無効」モードはDPN機能をオフにし、AtomOSを通じて処理される全てのネットワークデータがローカルトラフィックを使用するようになります。',
        '2. Deeper Connectの他のネットワークセキュリティ機能は有効のままです。',
        '3. 特定のウェブサイトへのアクセスに障害がある場合、DPNを無効にして、問題がウェブサイトにあるのかDPN機能にあるのかを判断できます。',
        '4. DPN機能に問題がある場合は、以下のリンクから報告してください: https://support.deeper.network/hc/en-us/requests/new',
      ],
    },
    'success': '操作成功',
    'failure': '操作失敗！',
  },
  // DefaultadBlock
  'DefaultadBlock': {
    'browserCacheWarning':
      'DNS広告フィルタリングが有効な場合に広告が適切にフィルタリングされない場合は、ブラウザのキャッシュをクリアしてフィルタリングされたデバイスを再起動する必要があります。',
    'queriesFor24Hours': '過去24時間のクエリ',
    'totalQuery': '総クエリ数',
    'blockedQuery': 'ブロックされたクエリ',
    'blockedPercentage': 'ブロックされた割合',
    'ruleTotal': 'ルール総数',
    'adQuery': 'ブロックされた広告',
    'trackerQuery': 'ブロックされたトラッカー',
    'maliciousQuery': 'ブロックされた悪意あるクエリ',
    'pornQuery': 'ブロックされたポルノ',
    'legendTotal': '総数',
    'legendBlocked': 'ブロックされた',
    'categoryStateEnabled': 'オン',
    'categoryStateDisabled': 'オフ',
    'categoryState2Hours': '2時間無効',
    'categoryState4Hours': '4時間無効',
    'categoryState8Hours': '8時間無効',
    'setCategoryStatesErr': 'フィルタの有効化/無効化エラー',
    'enableAll': '全てのフィルタを有効化',
    'disableAll': '全てのフィルタを無効化',
  },
  // CustomadBlock
  'CustomadBlock': {
    'missingInfoWarning': 'ドメインが不足',
    'invalidDomainError': 'ドメインの形式が無効、またはドメインが長すぎます',
    'whitelist': 'ホワイトリスト',
    'blacklist': 'ブラックリスト',
    'domain': 'ドメイン',
    'whitelistAddSuccess': 'ホワイトリストに正常に追加されました',
    'whitelistEntryExist': 'ホワイトリストに既に存在するエントリ',
    'reachedWhiteMax': 'ホワイトリストの最大値に達しました',
    'whitelistAddError': 'ホワイトリストへの追加に失敗しました',
    'blacklistAddSuccess': 'ブラックリストに正常に追加されました',
    'blacklistEntryExist': 'ブラックリストに既に存在するエントリ',
    'reachedBlackMax': 'ブラックリストの最大値に達しました',
    'blacklistAddError': 'ブラックリストへの追加に失敗しました',
    'whitelistDelSuccess': 'ホワイトリストのエントリが正常に削除されました',
    'whitelistDelError': 'ホワイトリストのエントリ削除に失敗しました',
    'blacklistDelSuccess': 'ブラックリストのエントリが正常に削除されました',
    'blacklistDelError': 'ブラックリストのエントリ削除に失敗しました',
    'import': 'インポート',
    'fileButtonText': 'アップロードするにはクリック',
    'fileSizeOverLimit': 'アップロードされたファイルが2MBの制限を超えています',
    'whitelistImportSuccess': 'ホワイトリストが正常にインポートされました。{0}エントリがインポートされました',
    'whitelistImportError': 'ホワイトリストのインポートに失敗しました',
    'blacklistImportSuccess': 'ブラックリストが正常にインポートされました。{0}エントリがインポートされました',
    'blacklistImportError': 'ブラックリストのインポートに失敗しました',
    'noValidEntry': 'アップロードされたファイルに有効なドメインエントリがありません',
    'zeroImported': 'ドメインエントリーのインポートに失敗しました',
  },
  // DohGuideComp
  'DohGuideComp': {
    'closeButton': '閉じる',
    'guideButton': '表示',
    'guideBoxTitle': 'DoHブラウザ設定',
    'dohMessageClickTips': '「表示」をクリックして、特定のブラウザでDoHをオフにする方法の指示をご覧ください。',
    'dohMessageTxt':
      'この機能を利用するためには、{0}。DoHはDNSリクエストを暗号化し、特定の機能に干渉する可能性があります。',
    'dohMessage0': 'ブラウザのDoH設定を無効にしてください',
    'guide': {
      'chromeGuideImg': '/dohGuide/chrome-guide-en.png',
      'chrome': [
        {
          'label': 'ステップ 1',
          'tip': 'アドレスバーに chrome://settings/security と入力して Enter キーを押します。',
        },
        { 'label': 'ステップ 2', 'tip': '「詳細なセキュリティ設定」セクションを探します。' },
        { 'label': 'ステップ 3', 'tip': '「セキュア DNS の使用」オプションを無効にします。' },
      ],
      'firefox': [
        {
          'label': 'ステップ 1',
          'tip': 'アドレスバーに about:config と入力して Enter キーを押します。',
        },
        { 'label': 'ステップ 2', 'tip': '警告ページが表示された場合は、「リスクを受け入れて続行」をクリックします。' },
        { 'label': 'ステップ 3', 'tip': '検索ボックスに network.trr.mode と入力して Enter キーを押します。' },
        {
          'label': 'ステップ 4',
          'tip': '検索結果をダブルクリックし、値を 5 (DoH を無効化) に変更して「OK」をクリックします。',
        },
      ],
      'edge': [
        {
          'label': 'ステップ 1',
          'tip':
            'アドレスバーに edge://settings/privacy と入力して Enter キーを押します。次に「セキュリティ」セクションを見つけます。',
        },
        {
          'label': 'ステップ 2',
          'tip': '「悪意のある活動や欺瞞から保護するためにセキュア DNS を使用する」オプションをオフにします。',
        },
      ],
      'operaGuideImg': '/dohGuide/opera-guide-en.png',
      'opera': [
        {
          'label': 'ステップ 1',
          'tip': 'ブラウザのアドレスバーに opera://settings/?search=DNS と入力して Enter キーを押します。',
        },
        {
          'label': 'ステップ 2',
          'tip':
            '「システムの DNS 設定の代わりに DNS-over-HTTPS を使用する」オプションを見つけ、オフにしていることを確認します。',
        },
      ],
    },
  },
  // appRelocatorData
  'appRelocatorData': {
    'guide': {
      'youtube': {
        'UA': 'https://www.deeper.network/pages/app-relocator?app=youtube&lang=en',
      },
      'nbaLeaguePass': 'https://www.deeper.network/pages/app-relocator?app=nbaLeaguePass&lang=en',
      'netflix': 'https://www.deeper.network/pages/app-relocator?app=netflix&lang=en',
      'dazn': 'https://www.deeper.network/pages/app-relocator?app=dazn&lang=en',
      'fubo': 'https://www.deeper.network/pages/app-relocator?app=fubo&lang=en',
      'tencentVideo': 'https://www.deeper.network/pages/app-relocator?app=tencentVideo&lang=en',
    },
    'countryLabel': '国',
    'tagLabel': 'タグ',
    'typeLabel': 'タイプ',
    'country': {
      'all-countries': '全世界',
      'CN': '中国',
      'US': 'アメリカ',
      'IN': 'インド',
      'CA': 'カナダ',
      'GB': 'イギリス',
      'NA': 'その他',
      'NO': 'ノルウェー',
      'SG': 'シンガポール',
      'JP': '日本',
    },
    'tag': {
      'all-tags': '全てのタグ',
      'nba': 'NBA',
      'nfl': 'NFL',
      'nhl': 'NHL',
      'mlb': 'MLB',
      'epl': 'EPL',
      'ipl': 'IPL',
    },
    'category': {
      'all-category': '全てのタイプ',
      'video': 'ビデオ',
      'music': '音楽',
      'im': 'インスタントメッセージ',
      'flightAndHotel': 'フライト & ホテル',
      'subscription': 'サブスクリプション',
    },
    'subcategory': {
      'popular': '人気',
      'sport': 'スポーツ',
      'movie': '映画',
      'tv': 'テレビ',
    },
    'filter': 'フィルタ',
    'reset': 'リセット',
    'submit': '送信',
    'app': {
      'acornTv': 'アコーンTV',
      'amazonPrimeVideo': 'アマゾンプライムビデオ',
      'beInSports': 'beINスポーツ',
      'crunchyroll': 'クランチロール',
      'dazn': 'DAZN',
      'disneyPlus': 'ディズニープラス',
      'netflix': 'ネットフリックス',
      'plex': 'プレックス',
      'roku': 'ロク',
      'shudder': 'シャダー',
      'zattoo': 'ザットゥ',
      'discoveryPlus': 'ディスカバリープラス',
      'paramountPlus': 'パラマウントプラス',
      'sportsnetNow': 'スポーツネットナウ',
      'tsn': 'TSN',
      'bbcIPlayer': 'BBC iプレイヤー',
      'hulu': 'フールー',
      'itv': 'ITVX',
      'slingTv': 'スリングTV',
      'crackle': 'クラックル',
      'pandora': 'パンドラ',
      'theCw': 'ザ CW',
      'abc': 'ABC',
      'nbc': 'NBC',
      'pluto': 'プルートTV',
      'starz': 'スターズ',
      'tbs': 'TBS',
      'attTv': 'AT&T TV',
      'channel4': 'チャンネル4',
      'showtimeAnytime': 'ショータイム',
      'nbcSports': 'NBCスポーツ',
      'epixNow': 'エピックスナウ',
      'mgmPlus': 'MGMプラス',
      'xumo': 'ズーモ',
      'channel5': 'チャンネル5',
      'usaNetwork': 'USAネットワーク',
      'fxNow': 'FXナウ',
      'vudu': 'ヴードゥ',
      'fubo': 'フボTV',
      'espn': 'ESPN',
      'tubiTv': 'トゥビ',
      'ukTvPlay': 'UKTVプレイ',
      'tvPlayer': 'TVプレイヤー',
      'peacock': 'ピーコック',
      'pbs': 'PBS',
      'philo': 'フィロ',
      'vrv': 'VRV',
      'showmax': 'ショーマックス',
      'hboMax': 'HBOマックス',
      'nhl': 'NHL',
      'dsTv': 'DStv',
      'sSportsPlus': 'Sスポーツプラス',
      'filmrise': 'フィルムライズ',
      'cbsSports': 'CBSスポーツ',
      'nowTV': 'ナウTV',
      'molotovTv': 'モロトフTV',
      'mlb': 'MLB',
      'britBox': 'ブリットボックス',
      'stirr': 'スター',
      'skyGo': 'スカイゴー',
      'hotstar': 'ディズニーホットスター',
      'sonyLiv': 'ソニーリブ',
      'nbaLeaguePass': 'NBAリーグパス',
      'amcPlus': 'AMCプラス',
      'f1Tv': 'F1TV',
      'frndlyTv': 'フレンドリーTV',
      'myTf1': 'MYTF1',
      'qqMusic': 'QQミュージック',
      'spotify': 'スポティファイ',
      'tidal': 'タイダル',
      'youtube': 'ユーチューブ',
      'youTubeMusic': 'ユーチューブミュージック',
      'weChat': '微信',
      'whatsApp': 'ワッツアップ',
      'line': 'ライン',
      'signal': 'シグナル',
      'agoda': 'アゴダ',
      'expedia': 'エクスペディア',
      'flyScanner': 'フライスキャナー',
      'googleFlights': 'グーグルフライツ',
      'qqSports': 'QQスポーツ',
      'skySports': 'スカイスポーツ',
      'rakutenTv': '楽天TV',
      'fox': 'FOX',
      'nflNetwork': 'NFLネットワーク',
      'peacocktv': 'ピーコックTV',
      'zee5': 'Zee5',
      'mxPlayer': 'MXプレイヤー',
      'tencentVideo': 'テンセントビデオ',
      'iqiyi': 'IQIYI Inc - ADR',
      'mangoTV': 'マンゴTV',
      'migu': 'Migu',
      'bilibili': 'ビリビリ',
      'youku': 'ユーコー',
      'altBalaji': 'ALTバラジ',
      'willowTv': 'ウィローTV',
      'foxSports': 'フォックススポーツ',
      'crave': 'クレイヴ',
      'cbcGem': 'CBCジェム',
      'jiocinema': 'ジオシネマ',
      'Telegram': 'テレグラム',
      'Messenger': 'メッセンジャー',
      'ChatGPT': 'チャットGPT',
      'CanalPlus': 'カナルプラス',
    },
    'tip': {
      'video': '現在のビデオサービスに人気のトンネル',
      'music': '現在の音楽サービスに人気のトンネル',
      'im': '現在のインスタントメッセージに人気のトンネル',
      'flightAndHotel': '現在のフライト＆ホテルサービスに人気のトンネル',
      'subscription': '現在のサブスクリプションに人気のトンネル',
      'backupTunnel':
        'スマートルーティングモードでは、現在のトンネルで利用可能なノードがない場合、システムは {0} にあるバックアップトンネルに切り替わります。',
      'fullTunnel': 'フルルーティングモードでは、すべてのアプリケーションはデフォルトで {0} トンネルを使用します。',
      'noTunnelNodes':
        '{0} トンネルのノード数がゼロで、使用できません。トンネルページに行ってノードを更新してください。',
      'noTunnelNodesOkText': '更新',
      'noTunnelNodesCancelText': '閉じる',
      'noTunnelAdded':
        'このトンネルはまだ追加していません。使用するには、トンネルページに行って {0} トンネルを追加してください。',
      'noTunnelAddedOkText': '追加',
      'noTunnelAddedCancelText': 'キャンセル',
      'tunnelDeletedText': '{0} - {1} トンネルは削除されました',
      'netflix': 'Netflixを使用中に遭遇する再生エラー問題を解決します',
      'fubo': 'Fubo TVでは、非常に安いコストでプレミアリーグのライブストリームを視聴できます。',
      'tencentVideo': 'NBAのゲームを視聴するのに約$1かかります。',
      'dazn': 'DAZNでは、非常に安いコストでNFL、MLB、NBA、EPLを視聴できます。たったの$17.18。',
      'youtube': '非常に安いコストでYouTubeプレミアムを楽しむ方法。',
    },
    'intro': {
      'acornTv':
        'Acorn TVは、イギリスおよびその他の国々からの一流のミステリー、ドラマ、コメディをストリーミングします。古典的なシリーズを一気見するか、お気に入りの新しい番組を見つけてください...',
      'abc': 'abc.comでABCのショーをオンラインで視聴。独占動画と無料エピソードを楽しめます。',
      'f1Tv':
        'F1 TVは現在、Web、アプリ、Google TV、Apple TV、Roku、Amazon Fire TVで利用可能です。すべてのF1をライブで。GPのすべてのトラックセッションをライブストリーム...',
      'youtube':
        'YouTubeの公式チャンネルが、世界で新しいトレンドを発見するお手伝いをします。必見の動画、音楽から文化、インターネット現象まで。',
      'netflix':
        'Netflixは、さまざまな受賞歴のあるテレビ番組、映画、アニメ、ドキュメンタリーなどを数千のインターネット接続デバイスで提供するストリーミングサービスです。',
      'fubo':
        'Fuboをテレビで視聴する最も簡単な方法は、Roku、Apple TV、Amazon Fire TV、またはChromecastを使用することです。デバイスにFuboアプリをインストールして...',
      'disneyPlus':
        '新作からお気に入りのクラシック、過去、現在、未来まで。期待通りのものと、期待していなかったもの。© 2023 Disney。ブランド：マーベル、ピクサー、ディズニー、ナショナルジオグラフィック、スターウォーズ。',
      'crunchyroll':
        'Crunchyrollでアニメとマンガのストリーミングを楽しむ。鬼滅の刃、ワンピース、進撃の巨人、僕のヒーローアカデミアなどの人気タイトルを楽しめます...',
      'hulu':
        'Huluでテレビ番組と映画をオンラインで視聴。グレイズ・アナトミー、This Is Us、ボブズ・バーガー、ブルックリン・ナイン-ナイン、エンパイア、SNLなどのエピソードや人気映画をストリーミング...',
      'nbaLeaguePass':
        'NBAリーグパスのサブスクリプションでは、ファンはシーズンとオフシーズンを通じて、試合をライブおよびオンデマンドで、さらに多くのコンテンツにアクセスできます。',
      'qqSports':
        'Tencent Sportsは、中国の有名なスポーツポータルウェブサイトで、主に次のセクションを提供しています：国内サッカー、国際サッカー、NBA、CBA…',
      'skySports':
        'お気に入りのスポーツの最高のライブカバレッジを視聴：サッカー、ゴルフ、ラグビー、ipl、F1、ボクシング、NFL、NBA、最新のスポーツニュースなど...',
      'rakutenTv':
        'ライブTV | 無料でトップ15チャンネル · 無料 | 無料でトップムービー · ストア | 購入またはレンタルする最新の映画 · ジャンル · 無料 | 最近追加された無料コンテンツ · 無料 | 楽天の無料コンテンツ',
      'espn':
        'ライブスコア、ハイライト、スポーツニュースについてはESPNを訪れてください。ESPN+で独占ゲームをストリームし、ファンタジースポーツを楽しめます。',
      'beInSports':
        'beIN SPORTS USAでスポーツの世界を開拓。主要なサッカーリーグなどの独占的なハイライト、最新のニュース、統計、スコア、スケジュールを入手。',
      'dazn': 'DAZNは、世界初の本当に専門的なライブスポーツストリーミングです...',
      'cbsSports':
        '無料の24時間スポーツニュースネットワーク。CBS Sports HQを見る。ゲームについて重要なすべてを提供。より多くのハイライト、トップベット、勝利のファンタジーアドバイス。',
      'fox':
        'FOX Sportsを見て、ライブスコア、オッズ、チームニュース、プレイヤーニュース、ストリーム、動画、統計、順位表、スケジュールをチェック。NFL、MLB、NASCAR、WWE、NBA、NHLなどをカバー。',
      'nflNetwork':
        'NFLチャンネル情報、番組の更新などを入手！NFLネットワークは、NFLが所有および運営する唯一の通年ネットワークです。',
      'peacock':
        'Peacockを入手し、ライブスポーツ、独占オリジナル、人気のあるショー、大ヒット映画をストリーム。トップヒット、クラシックなお気に入り、LIVEアクションなど、Peacockで今すぐストリーミング。ドキュメンタリー。子供向け映画＆ショー。24時間Peacockチャンネル。ホールマークチャンネル。',
      'nbcSports': 'NBC Sportsから最新のスポーツニュースとスコアを入手。',
      'sportsnetNow':
        'MLBトッププレイ · MLBSportsnet VideoBlue JaysのSpringerは、リードオフのoppo-tacoダイナーで誕生日を大いに祝う · MLBSportsnet VideoBravesのAcuna Jr.',
      'tsn':
        'TSNを訪れて、最新のスポーツニュース、スコア、ハイライト、解説を入手。オーストラリアオープン、NFLプレーオフ、スーパーボウル、NHLトレードデッドラインなど。',
      'nhl': '公式のナショナルホッケーリーグウェブサイト。ニュース、ロスター、統計、スケジュール、チーム、動画を含む。',
      'hotstar':
        'Disney+ Hotstarは、インド最大のプレミアムストリーミングプラットフォームで、17言語で100,000時間以上のドラマと映画、および主要なイベントのカバレッジがあります。',
      'espn':
        'ESPNを訪れてライブスコア、ハイライト、スポーツニュースをチェック。ESPN+で独占ゲームをストリームし、ファンタジースポーツを楽しめます。',
      'sonyLiv':
        '人気のTV番組、映画、スポーツ、ウェブシリーズを視聴できるトッププレミアムストリーミングプラットフォーム。お気に入りのライブTVチャンネルをオンラインで楽しめます。',
      'jiocinema':
        '最新の映画、テレビ番組、Vootショー、ライブスポーツ、ハイライトをヒンディー語、英語、他の地域言語でオンラインで視聴。ジャンルを問わず...',
      'amazonPrimeVideo':
        '映画、番組、受賞歴のあるAmazonオリジナル、プレミアムチャンネル、スポーツなどをストリーム。無料トライアルで見逃せないTV、大ヒット映画、ヒットショーなどを視聴。4K+HDR互換コンテンツ。ヒットショーと映画。新作。どのデバイスでも視聴可能。',
      'zee5':
        'ZEE5™でお気に入りのデシコンテンツを独占的な50％割引で。今すぐ購入。ZEE5™でお気に入りのコンテンツを視聴。年間パックで50％オフ。今すぐ購入。4000+の大ヒット映画。1600+のショー。260+のオリジナル作品。18言語で利用可能。',
      'mxPlayer':
        'MX Playerは、最新のミュージックビデオを再生したり、古い＆新しい映画、テレビ番組、コメディ番組、トレンドソングを見たりできる、インドの最もプレミアムなOTTサービスです。',
      'tencentVideo':
        'Tencent Videoは、中国をリードするオンラインビデオメディアプラットフォームを作ることに専念しています。豊富なコンテンツ、究極の視聴体験、便利なログイン方法、24時間シームレスなマルチプラットフォームアプリケーション体験、迅速な共有機能を提供し、主にユーザーのオンラインビデオストリーミングニーズに対応しています。',
      'iqiyi':
        'iQIYI（iQIYI.COM）は、高品質で高解像度のオンライン動画を大量に提供する大規模なビデオストリーミングウェブサイトです。映画、テレビ番組、アニメーション、バラエティ番組、ライフスタイル番組など、さまざまなコンテンツをストリーミングするためのプロフェッショナルなプラットフォームです。iQIYIは、さまざまな視聴者の好みに合わせた多様なエンターテインメントオプションを提供します。',
      'youku': '優酷 - 優れたコンテンツに特化 - オンラインで高品質のライセンスHD動画を大量に視聴。',
      'mangoTV':
        'Mango TV - みんなが見ているオンラインビデオウェブサイト - 人気のバラエティ番組、最新の映画、テレビドラマをオンラインで視聴。',
      'migu':
        'Migu Videoは、北京冬季オリンピックを含む国内外の主要なスポーツイベントのライブストリーミングを提供します。CBA（中国バスケットボール協会）、AFCチャンピオンズリーグ、中国スーパーリーグ、ラ・リーガ、ブンデスリーガ、セリエA、UEFAヨーロッパリーグ、UEFAチャンピオンズリーグなどのライブ中継、女子バレーボール、卓球、フィギュアスケート、スヌーカー、WWE、UFCなどのライブカバレッジを提供します。',
      'bilibili':
        'Bilibili（bilibili.com）は、中国で有名なビデオ弾幕ウェブサイトで、タイムリーなアニメのリリース、活気に満ちたACGコミュニティ、才能あるコンテンツクリエイターからのクリエイティブなコンテンツを提供します。ユーザーが喜びとエンターテインメントを見つける場所です。',
      'nbc':
        'NBC.comで現在放送中およびクラシックなNBCの番組の全エピソードをオンラインで視聴。クリップ、プレビュー、写真、およびNBC.comでの独占オンライン機能も。',
      'foxSports':
        'FOX Sportsを視聴し、ライブスコア、オッズ、チームニュース、プレイヤーニュース、ストリーム、動画、統計、順位表、スケジュールをチェック。NFL、MLB、NASCAR、WWE、NBA、NHLなどをカバー。',
      'nfl':
        'NFLチャンネル情報、番組の更新などを入手！NFLネットワークは、NFLが所有および運営する唯一の通年ネットワークです。',
      'willowTv':
        '正常にログインすると、Willowでライブストリーミング、ハイライト、リプレイを視聴できます。Spectrum Optimum Fios Dish Optimum TV Xfinity。* Time Warner …',
      'mlb': 'オーディオとビデオクリップ、インタビュー、統計、スケジュール、独占ストーリーなどを含むカバレッジ。',
      'disneyPlus':
        '新作からお気に入りのクラシックまで、過去、現在、未来はあなたのものです。期待したこと、期待していなかったこと、すべてがここに。© 2023 Disney。ブランド：マーベル、ピクサー、ディズニー、ナショナルジオグラフィック、スターウォーズ。',
      'hboMax':
        'HBO Maxは、HBO全体をまとめた独立したストリーミングプラットフォームで、さらに多くのテレビのお気に入り、大ヒット映画、新しいMaxオリジナルを全員のために提供しています…',
      'amcPlus':
        'AMC+で、普通を抜け出し、今日のユニークで魅力的な体験を発見しましょう。独占コンテンツ、魅力的な映画、タイムレスなシリーズ - すべてAMC+のエピックシリーズに。プレミアムドラマ。オンデマンド。独占コンテンツ。独占映画。新エピソード。広告なし。',
      'attTv':
        'TVとインターネットの最高のお得な情報を入手しましょう。DIRECTVや自宅のインターネット、AT&Tファイバーのオファーを含みます。',
      'britBox':
        'これまでで最大の英国TVのストリーミングコレクションから、ミステリー、コメディ、ドラマ、ドキュメンタリー、ライフスタイルなどを楽しむ。',
      'crackle':
        'Crackleで、お気に入りのジャンルのハリウッド映画を無料で視聴しましょう - カットなし、編集なし。アクション、ホラー、SF、犯罪、コメディなど。再生ボタンを押すだけです。',
      'discoveryPlus':
        'モバイルデバイス、タブレット、コンピューター、ゲームコンソール、接続されたTVでストリーム。· 誰にでも何かがあります！· discovery+はフード、ホームのストリーミングホームです…',
      'mgmPlus': 'MGM+を入手し、広告なしで1000以上の映画やTVシリーズを楽しむ。いつでもキャンセル可能。',
      'filmrise':
        '今日の常に変化する業界の性質を受け入れ、映画やテレビのベストを提供するために多段階の戦略を適用しています。',
      'frndlyTv':
        'Roku、Amazon Fire TVで、または外出先でFrndly TVで40以上のライブチャンネル、オンデマンドを視聴。3つのホールマークチャンネル、A&Eなどを含む…',
      'fxNow': 'FX、FXX、FXMネットワークでFXのオリジナルTV番組、映画、ライブTVを視聴。',
      'paramountPlus':
        '数千のエピソード、ライブスポーツ、独占オリジナルが一か所でストリーミング。受賞歴のあるクラシックからヒットリリースまで、数百の象徴的な映画にアクセス。新しいショーを見つけて一気見。ヒットリアリティシリーズ。ヒットMTVショー。30,000以上のエピソード。速報。ヒットCBSショー。',
      'pbs':
        'POVフィルムは、忘れられないストーリーテリングと時宜を得た内容で知られています。今すぐ視聴！POVはテレビで最も長く続く独立したドキュメンタリーのショーケースです。詳細を学ぶ！独立したノンフィクション。500本以上のフィルムを提供。忘れられない物語。',
      'philo':
        'テレビにもっと払う必要はありませんか？Philoは月額$25でライブTVとオンデマンドコンテンツを提供します。好きなショー、映画などをすべてのデバイスでストリーム。',
      'plex':
        'Plexは無料のストリーミングアプリで、クレジットカードや購読コスト、隠れた料金は必要ありません。無料のサービスを維持するために広告を含みます…',
      'pluto':
        '最新のヒット映画からお気に入りの番組のフルシーズンまで。数百のチャンネル。ゼロのドル。すべてのデバイス。今すぐ視聴を開始！すべてのデバイス。一気見。オンデマンドTVと映画。数百のチャンネル。今すぐストリーム。支払い不要。',
      'roku':
        'Rokuデバイスは、ストリーミングTVを簡単にします。プレーヤー、スマートTV、安心を感じるスマートホーム製品から、あなたにぴったりのRoku製品を見つけてください…',
      'showtimeAnytime':
        'SHOWTIMEを無料で試し、オリジナルシリーズ、映画、スポーツ、ドキュメンタリーなどをストリーム。さらに、購読不要でペイパービューの試合を注文。',
      'shudder':
        '月額わずか$4.99から、ShudderとThe Walking Dead Universe、アン・ライスなどのヒットシリーズをすべて一か所で。Shudderを無料で試してみてください…',
      'slingTv':
        'Sling TVは、インターネット上でライブテレビとオンデマンドコンテンツをストリームする最初のアプリベースのTVサービスです。どこにいても、ライブショーを視聴…',
      'starz':
        'STARZは、大胆なストーリーテリングを通じて多様な視点を生き生きとさせます。オリジナルシリーズ、映画、特典などをオンデマンドで、広告なしでストリーミングするためにサインアップ。',
      'stirr':
        '... & エンターテイメント。ライブおよびオンデマンド。STIRR City, Nationalがあなたのローカルチャンネルとして設定されています。視聴を開始。00:05。100以上のチャンネル、5,000時間以上のテレビと…',
      'tbs':
        'In the Heights · 今週のDynamite · MLBポストシーズンのtbsプレビュー · S18 | E14 アメリカンダッドネスのマルチバース。',
      'theCw':
        'The CWネットワークの公式ホーム。All American、Superman & Lois、Walker、FBoy Island、プレミアムストリーミングシリーズ、映画、スポーツなどを特集。',
      'tubiTv':
        'Tubiは、無料のプレミアム、オンデマンドビデオストリーミングアプリのリーダーです。50,000本以上の映画とテレビ番組を含む、最大のコンテンツライブラリを有しており、最高の…',
      'usaNetwork':
        'USAネットワークの現在放送中およびクラシックな番組の全エピソードをオンラインで視聴。さらに、クリップ、プレビュー、写真、USANetwork.comの独占オンライン機能も見つけよう。',
      'vudu':
        'Vuduで映画やテレビ番組をレンタル、購入、視聴。Vuduアプリでお気に入りの接続デバイスでオンラインで視聴。購読不要、無料サインアップ。',
      'xumo':
        'Xumoは、エンターテインメント業界全体の次世代ストリーミングプラットフォームを開発し提供するために設立されました。私たちのミッションについて学び、関与してください…',
      'jiocinema':
        '最新の映画、テレビ番組、Voot番組、ライブスポーツ、ハイライトをヒンディー語、英語、その他の地域言語でジャンルを問わずオンラインで視聴…',
      'altBalaji':
        '最新のウェブシリーズ、オリジナル、映画をHDオンラインで視聴。スリラー、アクション、アダルト、コメディ、ファミリードラマなどのジャンルから選択。複数言語で提供…',
      'crave':
        'ホラー映画のクラシック、恐怖のTVシリーズ、ハロウィーンエピソードのキュレーションされたコレクション。…コメディ界での思わぬ名声と社会的プレッシャー。',
      'cbcGem': "Schitt's Creek、Luther、Kim's Convenienceなどの人気番組や映画、ライブTVをあらゆるデバイスで視聴。",
      'bbcIPlayer': 'BBCのライブTVチャンネルを視聴し、見逃した番組を楽しむ。BBC iPlayerで独占コンテンツを視聴。',
      'channel4': 'Channel 4、E4、Walter Presentsのお気に入りの番組をオンラインで視聴。',
      'channel5':
        'My5へようこそ。Channel 5, 5STAR, 5USA, 5SELECT, 5ACTIONなどのお気に入りの番組の見逃し配信やボックスセットをストリーミングできます。',
      'dsTv':
        '指先で最高のテレビエンターテイメント。最新のスポーツ、映画、シリーズ、リアリティ番組を視聴し、DStvのTVガイドにアクセス。',
      'molotovTv':
        'Molotovは無料です。追加機能を自由に追加してください。· Molotovはテレビの未来を象徴しています · TVのSpotify · テレビは…',
      'myTf1':
        'TF1, TMC, TFX, TF1 Séries Films、LCIをライブで視聴。50以上の専門チャンネルと1万時間以上のビデオをMYTF1で独占視聴…',
      'sSportsPlus':
        'Serie AからLaLigaまで、Formula 1からNBAまで、EuroLeagueからUFCまで、毎月700時間以上のライブ放送と何百ものスポーツコンテンツをS Sport Plusで…',
      'showmax':
        'Showmaxのサブスクリプションはいくらですか？Showmaxはさまざまな好みや予算に合わせたプランを提供しており、価格は月額R39から始まります…',
      'itv':
        'ITVXは、英国で最も新しいストリーミングサービスです。独占的な新番組、大ヒット映画、ライブイベント、何千ものボックスセットが一か所に。',
      'pandora':
        'すべて新しいPandoraで好きな曲、アルバム、プレイリスト、ポッドキャストを再生。広告なしでオンデマンドでストリームするためのサブスクリプションプランにサインアップ。',
      'qqMusic':
        'QQ Musicは、テンセントによって開始されたウェブベースの音楽サービス製品で、オンライン音楽ストリーミング、新曲および人気曲の独占リリース、歌詞翻訳、モバイル着信音ダウンロード、高品質なロスレス音楽ストリーミング、広範囲のロスレス音楽ライブラリ、合法音楽ダウンロード、個人スペースなど幅広い機能を提供しています…',
      'spotify': 'Spotifyは、何百万もの曲にアクセスできるデジタル音楽サービスです。',
      'tidal':
        'TIDALは、アーティストを最優先にし、ファンを中心に据えた音楽ストリーミングプラットフォームで、全世界の音楽コミュニティにHiFi音質の100万曲以上を提供します。',
      'weChat':
        'あらゆるプラットフォームで利用可能；グループチャットを楽しむ；音声、写真、ビデオ、テキストメッセージに対応。',
      'whatsApp':
        'WhatsApp Messengerを使用して、友人や家族と連絡を取り合いましょう。WhatsAppは無料で、シンプルで安全、信頼できるメッセージングと通話を提供し、…で利用可能です。',
      'line': '新しいコミュニケーションアプリで、無料の音声通話やメッセージを、いつでもどこでも24時間利用できます！',
      'signal':
        'テキスト、音声メッセージ、写真、動画、GIF、ファイルを無料で共有。Signalは電話のデータ接続を使用するので、SMSやMMSの料金を避けることができます。',
      'agoda':
        '今すぐ予約し、後で支払い、無料キャンセル。24時間対応の多言語カスタマーサービス。予算に合わせた豪華なホテルやホーム。お部屋とフライトの信頼できる旅行パートナー。',
      'expedia':
        'あなたの夢の休暇のためのワンストップトラベルサイト。滞在とレンタカーやフライトを組み合わせるとさらに節約できます。柔軟なオプションをマッチするための検索…',
      'flyScanner':
        '19ドルからの割引航空券を入手。1つの検索で比較＆予約。迅速かつ簡単。格安航空券、ホテル、レンタカーを比較。数千のお得な情報を見つけ、最適なものを選びましょう！',
      'nowTV':
        'NOWメンバーシップの費用はいくらですか？NOWメンバーシップでは、エンターテイメント、シネマ、スポーツ、Hayuで、あなたの条件に合わせて見たいものを視聴できます。',
      'tvPlayer':
        'TVPlayerは、最も人気のあるTVチャンネルと、TVPlayer独占のプレミアムオンデマンド番組が揃っています。40以上のライブチャンネルと数千の…を視聴。',
      'ukTvPlay':
        'UKTV Playは、Dave、Drama、W、Yesterdayチャンネルのオンデマンドホームです。ライブTVを見たり、ボックスセットをストリーミングしたり、お気に入りの番組をキャッチアップ。',
      'zattoo':
        'すべてのデバイスでお気に入りのテレビ番組をライブおよびタイムシフトでストリーム。フルHDで最高の画質。ケーブルテレビよりも優れています。オンデマンド+ライブTVコンテンツ。',
      'skyGo':
        'Skyパッケージのライブ、オンデマンド、キャッチアップTVをスマホ、タブレット、ラップトップ、コンピューター、またはApple TVデバイスでストリームし、簡単に切り替えられます。',
      Telegram:
        'Telegramは、安全で高速な簡潔な通信体験を提供することを目指しており、強力な暗号化機能と豊富な機能で知られています。',
      Messenger:
        'Messengerは、Facebookが提供するインスタントメッセージングアプリケーションで、ユーザーがテキストメッセージ、音声通話、ビデオ通話、写真の送信、絵文字などを通じて他の人とコミュニケーションを取ることができます。Messengerは最初はFacebookのウェブサイトの機能で、後に独立したモバイルアプリケーションに発展し、より多くの機能とサービスを提供します。',
      ChatGPT:
        'ChatGPTは、OpenAIによって開発された人工知能言語モデルで、GPT（Generative Pre-trained Transformer）アーキテクチャに基づいています。大規模な自然言語処理データを通じて訓練され、自然言語テキストを理解し生成することができます。',
      CanalPlus:
        'CANAL+は、フランスの有料テレビチャンネルで、フランス最大の有料テレビネットワークの一つであり、ヨーロッパで最も重要な有料テレビサービスプロバイダーの一つです。チャンネルは映画、スポーツ、ニュース、ドキュメンタリー、テレビドラマなど、さまざまなプログラムを提供しています。',
    },
    'selectTunnel': 'トンネルを選択',
    notSetLabel: '設定されていません',
    'directAccessLabel': 'ダイレクトアクセス',
    'otherTunnelLabel': 'その他のトンネル',
    'recommendedTunnelLabel': '推奨トンネル',
    'error': {
      'getApps': 'アプリ取得に失敗',
      'getTunnels': 'トンネルリストの取得に失敗',
      'invalidApp': '無効なアプリ',
      'tunnelNotExist': 'トンネルが存在しない',
      'set': 'トンネル設定に失敗',
      'unset': 'トンネル解除に失敗',
    },
    'success': {
      'set': 'トンネル設定に成功',
      'unset': 'トンネル解除に成功',
    },
    'searchInputPlaceholder': '必要なアプリを検索してください！',
    'discountIntro': 'percent => `月額サブスクリプション料金が${percent}割引`',
    'discountPercent': 'percent => `-${percent} ($)/月`',
    'guideButtonLabel': '詳しくはこちら',
  },
  // betanet
  'betanet': {
    'bindWalletSuccess': 'ウォレットが正常にバインドされました',
    'deviceBound': 'デバイスは現在他のウォレットにバインドされています',
    'walletTaken': 'ウォレットは他のデバイスによってすでに使用されています',
    'deviceTypeIncompatible': 'デバイスはウォレットと互換性がありません',
    'invalidMnemonic': '無効なウォレットニーモニック',
    'invalidPrivateKey': '無効なウォレットプライベートキー',
    'bindWalletError': 'ウォレットのバインドに失敗しました',
    'notBound': '現在のユーザーにバインドされたウォレットはありません',
    'unbindWalletSuccess': 'ウォレットが正常にアンバインドされました',
    'noActiveBinding': '指定されたウォレットにバインドされていないデバイス',
    'unbindWalletError': 'ウォレットのアンバインドに失敗しました',
    'walletManagement': 'ウォレット管理',
    'bindWallet': 'ウォレットをバインド',
    'downloadPrivateKey': '秘密キーをダウンロードする',
    'downloadKeyError': 'プライベートキーのダウンロードに失敗しました',
    'unbindBtnText': 'ウォレットのバインド解除',
    'unbindWarning':
      'プライベートキーをダウンロードしたことを確認してください。「プライベートキーをダウンロードしました」と入力して続行してください。',
    'unbindWarningWords': 'プライベートキーをダウンロードしました',
    'generateWallet': 'ウォレットを生成',
    'verifyMnemonic': 'ニーモニックを確認',
    'mnemonicMismatch': 'ニーモニックの確認に失敗しました',
    'bindBtn1Text': 'ウォレットをバインド（ニーモニック）',
    'bindBtn2Text': 'ウォレットをバインド（プライベートキー）',
    'dprManagement': 'DPR管理',
    'balanceLabel': 'DPR残高',
    'balance': '残高',
    'creditLabel': 'クレジット',
    'operationLabel': '操作',
    'amtOwingLabel': 'システムがあなたのために支払った金額',
    'dailyConsumedLabel': '日ごとの消費トラフィック',
    'dailySharedLabel': '日ごとの共有トラフィック',
    'deposit': 'デポジット',
    'withdraw': '引き出し',
    'publicKey': '公開キー',
    'mnemonicInstruction': 'ニーモニックをメモしてください。次のステップで確認が必要になります。',
    'enterMnemonic': 'ニーモニックを入力',
    'enterMnemonicTip': '提示された順序で各単語を選択してください。',
    'resetMnemonic': 'リセット',
    'uploadPrivateKey': 'プライベートキーをアップロード',
    'getWalletError': 'ウォレット情報の取得に失敗しました',
    'deviceWalletMismatch': 'デバイスとウォレットの不一致',
    'walletNotFound': 'ウォレットが見つかりません',
    'generateWalletError': 'ウォレットの生成に失敗しました',
    'missingMnemonic': 'ニーモニックが不足しています',
    'missingPrivateKeyFile': 'プライベートキーのファイルが不足しています',
    'privateKeyFileOversize': 'プライベートキーのファイルサイズが10KBの上限を超えています',
    'transferTitle': '送金',
    'transferHistoryTitle': '送金履歴',
    'transferTo': '受取人の公開キー',
    'transferAmt': '送金額（小数点以下6桁まで）',
    'invalidTo': '無効な受取人',
    'invalidAmt': '無効な送金額',
    'amtOutOfRange': '送金額は正で、100万を上限とする必要があります',
    'transferSuccess': '資金の送金に成功しました',
    'senderRecipientSame': '送信者は受取人と同一にできません',
    'recipientNotFound': '受取人が見つかりません',
    'insufficientFund': '資金不足により送金に失敗しました',
    'transferError': '資金の送金に失敗しました',
    'votingResNotRetrieved': '信託結果がまだ取得されていません。',
    'candidateSelected': '信託したバリデータ候補が選ばれました。',
    'candidateNotSelected': '信託したバリデータ候補は選ばれませんでした。',
    'votingResNotReady': '信託結果は現時点で利用できません。',
    'notVoted': 'バリデータ候補に信託していません。',
    'candidateNotFound': 'バリデータ候補が見つかりません',
    'votingResRetrievalErr': '信託結果の取得に失敗しました',
    'from': 'から',
    'to': 'まで',
    'type': 'タイプ',
    'amount': '金額',
    'time': '時間',
    'txnTypes': {
      '101': '初期報酬',
      '201': '手動転送',
      '301': '委託報酬',
      '401': 'マイクロペイメント',
    },
    'walletAddress': 'ウォレットアドレス',
    'notInGenesisNodeList': 'ジェネシスノードはジェネシスマイニングマシンにのみバインドできます。',
    'onChainStatusLabel': 'オンチェーンステータス',
    'onChainStatusDesc': {
      'unknown': '-',
      'onChain': 'はい',
      'notOnChain': 'いいえ',
    },
    'pwMgmtSection': {
      'title': 'パスワード管理',
      'setBtnText': 'パスワードを設定',
      'resetBtnText': 'パスワードをリセット',
    },
    'passwordModal': {
      'title': 'ウォレットパスワードを設定',
      'onePlaceholder': 'パスワードを入力',
      'twoPlaceholder': 'パスワードを再度入力',
      'btnText': '確認',
    },
    'setPasswordMsg': {
      'mismatch': 'パスワードが一致しません',
      'weak': 'パスワードは8文字以上で、小文字、大文字、数字を含む必要があります',
      'alreadySet': 'ウォレットパスワードはすでに設定されています',
      'genericErr': 'ウォレットパスワードの設定に失敗しました',
      'success': 'ウォレットパスワードの設定に成功しました',
    },
    'authModal': {
      'title': 'ウォレットパスワード',
      'inputPlaceholder': 'ウォレットパスワードを入力',
      'btnText': '続行',
    },
    'passwordNotSet': 'ウォレットパスワードが設定されていません',
    'passwordIncorrect': 'ウォレットパスワードが正しくありません',
    'resetModal': {
      'disclaimer':
        'ウォレットパスワードをリセットすると、現在バインドされているウォレットからデバイスが切断されます。「ウォレットパスワードをリセットすることを確認します」と入力して続行してください。',
      'consent': 'ウォレットパスワードをリセットすることを確認します',
    },
    'resetPasswordMsg': {
      'noActiveBinding': 'デバイスは指定されたウォレットにバインドされていません',
      'unbindErr': 'ウォレットのアンバインドに失敗しました',
      'genericErr': 'ウォレットパスワードのリセットに失敗しました',
      'success': 'ウォレットパスワードのリセットに成功しました',
    },
    'unstaking': {
      'label': 'ステーキング情報',
      'getErr': 'アンステーキング選択の取得に失敗しました',
      'alreadyChosen': 'すでにアンステーキングを選択しています',
      'expired': 'アンステーキング期間が終了しました',
      'invalidCreditData': 'このウォレットはDeeper Chainに配置されていません。',
      'phase1IsNotOver': 'このウォレットのステーキング期間はまだ終了していません。',
      'setErr': 'アンステーキング選択の設定に失敗しました',
      'announcementTitle': 'ステーキング利用規約',
      'announcement': {
        'continueStaking':
          'ステーキング更新：ステークは自動的に第二期間に入り、6か月の非ロックステーキング期間になります。基本マイニング＆ジェネシスAPYは第二期間も同じです。',
        'duringStaking':
          'アンステーキング：2期目中はいつでもアンステークボタンをクリックしてアンステーキングできます。このアクションは取り消し不可です！',
        'stopStaking':
          '配布：アンステークすると、3か月の線形リリース/配布フェーズに入ります。$DPRはDeeper Chainウォレットに自動的に毎日配布されます。',
        'releaseStaking': '',
      },
      'tipTitle': '重要なリマインダー：',
      'tip': {
        'afterStopStaking_1':
          '1) アンステーキングしたジェネシスノードはジェネシスステータスを失い、永遠にジェネシスノードになる機会を失います。お使いのデバイスはすぐに基本マイニング2.0に参加できませんが、帯域幅の共有による有機的なクレジットスコアの蓄積は続きます。',
        'afterStopStaking_2': '2) アンステークすると、$DPRマイニング報酬は即座に受け取れなくなります。',
        'afterStopStaking_3':
          '3) アンステークすると、ステークしたクレジットはクレジットスコアから差し引かれます。帯域幅の共有から得られた有機的なクレジット（もしあれば）は残ります。',
        'afterStopStaking_4':
          '4) アンステークした後、すぐに再ステークすることはできません（将来の発表を待つ必要があります）',
        'afterStopStaking_5': '5) アンステークしても、最初の期間は合計270の報酬が配布されるまで終了しません。',
        'afterStopStaking_6':
          '6) マイニングに関する情報が必要な場合は、さらなる情報源のために当社のMediumブログをご覧ください。',
        'mediumText1': '詳細はこちらをクリックしてください ',
        'mediumText2': 'Deeper Network Basic Mining 2.0',
        'mediumText3': 'の詳細',
        'mediumLink':
          'https://deeper-network.medium.com/deeper-network-basic-mining-2-0-mining-updates-for-genesis-and-basic-mining-2ec2f112cfd4',
      },
      'btn': {
        'cancel': '了解しました、ステーキングを続行',
        'ok': 'アンステーク',
      },
    },
    'staking': {
      'walletInfo': 'ウォレット情報',
      'campaignLabel': 'ステーキングタイプ',
      'campaignText': {
        '0': 'ジェネシスノード ゴールド',
        '1': 'ジェネシスノード シルバー',
        '2': 'ベーシックマイニング 1.0',
        '3': 'DPRBマイニング',
        '4': 'ベーシックマイニング 2.0',
        '7': 'ベーシックマイニング 2.0',
        '8': 'ベーシックマイニング 2.0',
      },
      'creditLabel': '現在のクレジット',
      'creditMaxLabel': '最大クレジット',
      'creditLevelLabel': 'クレジット階層',
      'stakeAndBurn': 'ステークとバーン',
      'stakeForCredit': 'ステークの増加',
      'dailyReward': '日次報酬',
      view: 'チェック',
      'payment': '支払い',
      'insufficientBalance': '残高不足',
      'stakeSuccess': 'リクエストが送信されました。クレジットの更新を待ってください。',
      'stakeError':
        'リクエストの送信に失敗しました。ウォレット関連情報を確認するか、カスタマーサービスに相談してください。',
      'burnForCredit': 'クレジットのためのDPRをバーン',
      'burnSuccess': 'リクエストが送信されました。クレジットの更新を待ってください。',
      'burnError':
        'リクエストの送信に失敗しました。ウォレット関連情報を確認するか、カスタマーサービスに相談してください。',
    },
  },
  // ChangePasswordData
  'ChangePasswordData': {
    'successMsg': 'パスワードが正常に変更されました',
    'newPasswordMismatch': '新しいパスワードと確認用の新しいパスワードが一致しません',
    'oldPasswordMismatch': '古いパスワードが記録と一致しません',
    'samePasswordError': '新しいパスワードが古いパスワードと同じです',
    'genericError': 'パスワード変更エラー',
    'title': 'パスワード変更',
    'oldPwd': '古いパスワードを入力してください',
    'newPwd': '新しいパスワードを入力してください',
    'newPwdAgain': '新しいパスワードを再度入力してください',
    'okText': 'OK',
  },
  // dailyTraffic
  'dailyTraffic': {
    'historySize': '日数',
    'chartTitle': 'トラフィック',
    'uptimeChartTitle': '稼働時間',
    'hour': 'h',
    'minute': 'm',
    'second': 's',
  },
  // flow
  'flow': {
    'totalTrafficLabel': '総トラフィック',
    'localTraffic': 'ローカルトラフィック',
    'clientTraffic': 'クライアントトラフィック',
    'serverTraffic': 'サーバートラフィック',
    'upload': 'アップロード',
    'download': 'ダウンロード',
    'sessionSpeed': 'セッション速度',
  },
  // login
  'login': {
    'pageTitle': 'ディーパーネットワークへようこそ！',
    'username': 'ユーザー名',
    'password': 'パスワード',
    'btnText': 'ログイン',
    'successMsg': 'ログイン成功',
    'missingField': 'ユーザー名またはパスワードが不足しています',
    'wrongField': 'ユーザー名またはパスワードが間違っています',
    'invalidToken': '無効なログインデータ',
    'expiredToken': 'ログインデータの期限切れ',
    'genericError': 'ログインエラー',
    'tooManyAttempts': 'ログイン試行回数が多すぎます',
  },
  //notification
  'notification': {
    'search': '検索',
    'CP': 'コントロールプレーン',
    'DP': 'データプレーン',
    'MP': 'マネジメントプレーン',
  },
  // DomainConfig
  'domainConfig': {
    'whiteList': 'スマートルートリスト',
    'blackList': 'ダイレクトルートリスト',
    'domain': 'ドメイン',
    'tunnel': 'トンネル',
    'selectTunnel': 'トンネルを選択',
    'region': '地域',
    'country': '国',
    'noTunnel': 'ダイレクトアクセス',
    'convertedTip': 'URLは自動的にドメイン名に変換されました。',
    'urlInputPlaceholder': '例：https://www.google.com または google.com',
    'missingInfoWarning': '情報が不足しています',
    'invalidDomainError': 'ドメインの形式が無効、またはドメインが長すぎます',
    'whitelistAddSuccess': 'スマートルートリストに正常に追加されました',
    'existInBlacklist': 'ダイレクトルートリストに存在するため、スマートルートリストに追加できませんでした',
    'whitelistEntryExist': 'エントリはすでにスマートルートリストに存在します',
    'tunnelNotExist': 'トンネルが存在しないため、スマートルートリストに追加できませんでした',
    'whitelistAddError': 'スマートルートリストに追加できませんでした',
    'blacklistAddSuccess': 'ダイレクトルートリストに正常に追加されました',
    'existInWhitelist': 'スマートルートリストに存在するため、ダイレクトルートリストに追加できませんでした',
    'blacklistEntryExist': 'エントリはすでにダイレクトルートリストに存在します',
    'blacklistAddError': 'ダイレクトルートリストに追加できませんでした',
    'conflict': '競合のためエントリを追加できませんでした',
    'whitelistDelSuccess': 'スマートルートリストのエントリが正常に削除されました',
    'domainNotExist': '選択したドメインは存在しません',
    'whitelistDelError': 'スマートルートリストのエントリの削除に失敗しました',
    'blacklistDelSuccess': 'ダイレクトルートリストのエントリが正常に削除されました',
    'blacklistDelError': 'ダイレクトルートリストのエントリの削除に失敗しました',
    'backupTips':
      'バックアップトンネルが現在アクティブです。スマートルーティングモードでは、追加されたトンネルが一時的に利用可能なノードを欠いている場合、システムはデフォルトで待機トンネル - {0}を使用します。',
    'tips': {
      'smartRouteList': 'ニーズに応じて、アクセスしたいドメイン名とそれに対応する国を手動で追加できます。',
      'directRouteList':
        'ここに追加されたドメイン名はDPNトンネルを通らず、ローカルネットワークを使用して直接アクセスされます。',
    },
  },
  // ipConfig
  'ipConfig': {
    'whiteList': 'スマートIPリスト',
    'blackList': 'ダイレクトIPリスト',
    'ip': 'IP',
    'tunnel': 'トンネル',
    'selectTunnel': 'トンネルを選択',
    'region': '地域',
    'country': '国',
    'noTunnel': 'トンネルなし',
    'convertedTip': 'URLは自動的にIPに変換されました',
    'urlInputPlaceholder': '例：https://xxx.xxx.xxx.xxx または xxx.xxx.xxx.xxx',
    'missingInfoWarning': '情報が不足しています',
    'invalidIPError': 'IP形式が無効です',
    'whitelistAddSuccess': 'スマートIPリストに正常に追加されました',
    'existInBlacklist': 'ダイレクトIPリストに存在するため、スマートIPリストに追加できませんでした',
    'whitelistEntryExist': 'エントリはすでにスマートIPリストに存在します',
    'tunnelNotExist': 'トンネルが存在しないため、スマートIPリストに追加できませんでした',
    'whitelistAddError': 'スマートIPリストに追加できませんでした',
    'blacklistAddSuccess': 'ダイレクトIPリストに正常に追加されました',
    'existInWhitelist': 'スマートIPリストに存在するため、ダイレクトIPリストに追加できませんでした',
    'blacklistEntryExist': 'エントリはすでにダイレクトIPリストに存在します',
    'blacklistAddError': 'ダイレクトIPリストに追加できませんでした',
    'whitelistDelSuccess': 'スマートIPリストのエントリが正常に削除されました',
    'ipNotExist': '選択したIPは存在しません',
    'whitelistDelError': 'スマートIPリストのエントリの削除に失敗しました',
    'blacklistDelSuccess': 'ダイレクトIPリストのエントリが正常に削除されました',
    'blacklistDelError': 'ダイレクトIPリストのエントリの削除に失敗しました',
    'backupTips':
      'バックアップトンネルが現在アクティブです。スマートルーティングモードでは、追加されたトンネルが一時的に利用可能なノードを欠いている場合、システムはデフォルトで待機トンネル - {0}を使用します。',
    'tips': {
      'smartRouteList': 'ニーズに応じて、アクセスしたいIPアドレスとそれに対応する国を手動で追加できます。',
      'directRouteList':
        'ここに追加されたIPアドレスはDPNトンネルを通らず、ローカルネットワークを使用して直接アクセスされます。',
    },
  },
  // queryRouting
  'queryRouting': {
    'title': 'クエリルーティング',
    'searchLabel': 'クエリ設定',
    'search': '検索',
    'searchResult': '検索結果：',
    'invalidRoutingError': 'ルーティング形式が無効です',
    'defaultConfig': 'デフォルト設定',
    'userConfig': 'ユーザー設定',
    'routingNotFound': 'ルーティングが見つかりません',
    'queryRoutingError': 'ルーティングの問い合わせに失敗しました',
    'convertedTip': 'URLは自動的にドメイン名またはIPに変換されました。',
    'urlInputPlaceholder': '例：google.com または xxx.xxx.xxx.xxx',
  },
  // rustdesk
  'rustdesk': {
    'ipLabel': 'あなたのIPアドレスは',
    'noPubIp': '公開IPがありません。',
    'vpnUse':
      'Wireguard VPNポートフォワードを使用して公開IPを取得できます。この公開IPはRustDeskサービスのみに使用でき、DPNサービスには使用できないことに注意してください。',
    'config': {
      'title': 'RustDesk設定',
      'switch': 'オン/オフ',
      'forceKey': 'クライアントに同じ公開キーの使用を強制する',
      'relay': 'カンマ区切りのリレーサーバー（ホスト：ポート）、例えば www.example.com:21116,192.168.1.1:21117',
      'port': 'ポート（2-65532）',
      'saveBtnText': '保存',
      'pubKey': '公開キー',
      'emptyPubKey': '公開キーなし',
      'resetBtnText': '公開キーをリセット',
    },
    'vpn': {
      'title': 'Wireguard VPNポートフォワード',
      'switch': 'オン/オフ',
      'ip': 'WireguardサーバーIP',
      'port': 'VPNフォワードポート',
    },
    'guide': {
      'title': '設定ガイド',
      'stepOne': '示された通りにボタンをクリック',
      'stepTwoA': '対応するフィールドをフォームにコピーして保存を押す',
      'idAndRelay': 'ID/リレーサーバー',
      'idServer': 'IDサーバー：',
      'relayServer': 'リレーサーバー：',
      'apiServer': 'APIサーバー：',
      'serverKey': 'キー：',
      'stepTwoB': 'または、設定をクリップボードにコピーしてRustDeskでインポートすることができます',
      'copyBtnText': 'サーバー設定をクリップボードにコピー',
    },
    'success': {
      'setSwitch': 'RustDeskを正常に切り替えました',
      'setForced': 'クライアントが同じ公開キーを使用するように正常に強制しました',
      'setRelayAndPort': 'リレーとポートを正常に保存しました',
      'resetPubKey': '公開キーを正常にリセットしました',
      'setVpnSwitch': 'Wireguard VPNを正常に切り替えました',
      'copyToClipboard': 'クリップボードに正常にコピーしました',
    },
    'error': {
      'getNetworkAddress': 'IPの取得に失敗しました',
      'getConfig': 'RustDesk設定のロードに失敗しました',
      'setSwitch': 'RustDeskの切り替えに失敗しました',
      'setForced': 'クライアントが同じ公開キーを使用するように強制することに失敗しました',
      'invalidPort': '無効なポート',
      'invalidRelay': '無効なリレー',
      'setRelayAndPort': 'リレーとポートの保存に失敗しました',
      'resetPubKey': '公開キーのリセットに失敗しました',
      'getVpnConfig': 'Wireguard VPN設定の取得に失敗しました',
      'setVpnSwitch': 'Wireguard VPNの切り替えに失敗しました',
      'copyToClipboard': 'クリップボードへのコピーに失敗しました',
      'getVersion': 'バージョンの取得に失敗しました',
      'getHbbrLog': 'HBBRログの取得に失敗しました',
      'getHbbsLog': 'HBBSログの取得に失敗しました',
    },
  },
  // sharing
  'sharing': {
    'sharingTitle': '共有：',
    'btSharingTitle': 'Bit Torrent共有：',
    'enabled': '有効',
    'disabled': '無効',
    'sharingTrafficLimit': '月間共有トラフィック制限',
    'sharingBandwidthLimit': '共有帯域幅制限',
    'unlimited': '無制限',
    'limited': '制限あり',
    'setLabel': '設定',
    'checkStartEndTime': '開始時間と終了時間を選択してください',
    'time': '時間',
    'record': '記録',
    'startTime': '開始時間',
    'endTime': '終了時間',
    'keyword': 'キーワード',
    'search': '検索',
    'webToSharingTitle': 'ウェブフィルタールールの適用',
    webToSharingDesc: 'デバイスの組み込み DNS 広告フィルタリングとカスタム DNS フィルタリング ルールを適用します。',
    'yes': 'はい',
    'no': 'いいえ',
    'download': 'プレーンテキストログ',
    'export': '暗号化ログ',
    'success': {
      enableTrafficLimit: '毎月の共有トラフィック制限が有効になっています。 制限を設定してください！',
      'setSharingTrafficLimit': '共有トラフィック制限を正常に設定しました',
      'enableBandwidthLimit': '共有帯域幅制限が有効です。制限を15Mbpsから1024Mbpsの間で設定してください！',
      'setSharingBandwidthLimit': '共有帯域幅制限を正常に設定しました',
    },
    'errors': {
      'getSharingState': '共有状態の取得に失敗しました',
      'switchSharingState': '共有状態の切り替えに失敗しました',
      'exceedsLimit': '月間トラフィックが制限を超えたため、共有を有効にできませんでした',
      'getSharingTrafficLimit': '共有トラフィック制限の取得に失敗しました',
      'setSharingTrafficLimit': '共有トラフィック制限の設定に失敗しました',
      'invalidTrafficLimit': 'トラフィック制限は正の数である必要があります',
      'setSharingBandwidthLimit': '共有帯域幅制限の設定に失敗しました',
      'invalidBandwidthLimit': '帯域幅制限は15Mbpsから1024Mbpsの間でなければなりません',
      'getSharingFilterConfig': '共有フィルター設定の取得に失敗しました',
      'setSharingFilterConfig': '共有フィルター設定の設定に失敗しました',
    },
  },
  // system
  'system': {
    'hardwareInfo': 'ハードウェア情報',
    'softwareInfo': 'ソフトウェア情報',
    'networkAddress': 'ネットワークアドレス',
    'sessionInfo': 'セッション情報',
    'upgradeInfo': 'ファームウェア更新',
    'sn': 'SN',
    'cpu': 'CPU',
    'cores': 'コア',
    'memory': '使用量/合計',
    'systemVersion': 'システムバージョン',
    'appidSignVersion': 'トラフィックプロトコル識別機能ライブラリ',
    'urlFilterSignVersion': 'ウェブフィルタリング機能ライブラリ',
    'https': 'HTTPS',
    'dns': 'DNS',
    'rules': 'ルール',
    'ip': 'IP',
    'routerMac': 'ルーターMac',
    'gatewayMac': 'ゲートウェイMac',
    'maxSession': '最大セッション数',
    'currentSession': '現在のセッション数',
    'tcpUdpIcmp': 'TCP/UDP/ICMP/TUNNEL',
    'autoUpdate': '自動更新',
    'manualUpgrade': '手動アップグレード',
    'upgrading': 'アップグレード中...',
    'latestVersion': '最新バージョン',
    'systemStatusHistory': 'システムステータス履歴',
    'cpuUsage': '24時間のCPU使用率',
    'memoryUsage': '24時間のメモリ使用率',
    'off': 'オフ',
    'on': 'オン',
    'nowCheckVersion': '現在バージョン確認中',
    'nowLatestVersion': '最新バージョン',
    'nowNewTestVersion': '現在使用中のバージョン',
    'upgradeLoadingText':
      'ファームウェアを更新しています。これには数分かかる場合があります。更新が完了するまでページを更新しないでください。',
    'upgradeSuccess': 'アップグレード成功、まもなくページをリフレッシュします',
    'upgradeError': {
      'noImage': 'アップグレードイメージがありません',
      'invalidImage': '無効なアップグレードイメージ',
      'imageArchMismatch': 'アップグレードイメージとデバイスアーキテクチャの不一致',
      'noResponse':
        'デバイスが再起動しました。アップグレードが成功したかどうかを確認するために、後でページを更新してください。',
      'alreadyLatest': '現在のバージョンは最新です、アップグレードの必要はありません',
      'generic': 'アップグレードエラー',
    },
    'copySuccess': 'コピー成功',
    'copyFail': 'コピー失敗',
  },
  // techSupport
  'techSupport': {
    'remoteConnection': {
      'title': 'リモート接続',
      'loading': '接続中...',
      'popupTitle': 'リモート接続コード',
      'intro':
        '適用シナリオ： <span>デバイスのネットワークが正常に機能しているが、他の機能で問題が発生した場合</span><br/>「リモート接続コード」を手動で生成し、カスタマーサービスチームに送信してください。技術チームが迅速に問題を特定し、解決のお手伝いをします。効果的なコードを維持するため、短時間で複数回生成することはお控えください。',
      'buttonText': 'コードを生成',
      'generateFeatureCodeFailure': 'コードの生成に失敗しました。',
      'tips':
        '問題が発生した場合は、詳細をカスタマーサポートに送信してください。問題が解決されるまで、私たちは尽力してお手伝いします。',
      'confirm': 'コピーして閉じる',
      'copySuccess': 'クリップボードに正常にコピーされました',
      'copyFailure': 'クリップボードへのコピーに失敗しました',
      'connectFailure': '接続に失敗しました',
    },
    'logDownload': {
      'title': 'ログダウンロード',
      'loading': 'ダウンロード中...',
      'intro':
        '適用シナリオ： <span>デバイスがバックエンドにアクセスできるが、ネットワークの問題が発生している場合</span><br />ログファイルを手動でダウンロードし、カスタマーサービスチームに送信してください。状況を理解し、より効果的な支援を提供します。',
      'buttonText': 'ダウンロード',
      'downloadSuccess': 'ダウンロード成功',
      'downloadFailure': 'ダウンロードに失敗しました。後で再試行してください',
    },
    'supportServices': {
      'title': '必要なサポートを受けましょう。',
      'intro': 'いくつかの詳細を教えていただければ、最適な解決策を提供します。',
      'buttonText': 'サポートを受ける',
    },
    'promiseTips': {
      'title': 'セキュリティ保証の約束',
      'intro':
        '安心してください、リモート接続とログダウンロード機能の使用は、<span>問題の診断と解決のためだけに厳格に制限</span>されており、他の目的で決して使用されません。プライバシーとセキュリティは最大限に保護されています。',
    },
  },
  // builtinRules
  'builtinRules': [
    {
      'label': 'HTTPSフィルタリングを有効にする（HTTPフィルターを含む）',
      'sslbypass': 'SSLバイパスを有効にする',
      'value': 'youtube',
    },
  ],
  // customrules
  'customrules': [
    {
      'label': 'HTTPSフィルタリングを有効にする（HTTPフィルターを含む）',
      'value': 'youtube',
    },
  ],
  // tproxy
  'tproxy': {
    'browserCacheWarning':
      'HTTPS広告フィルタリングが有効になっても正しく広告をフィルタリングできない場合は、ブラウザのキャッシュをクリアする必要があります。証明書をインストールせずにHTTPSフィルタリングのみを有効にすると、広告がフィルタリングされないだけでなく、リスト上のウェブサイトへの正常なアクセスにも影響を与える可能性があります。',
    'triggerRulesTipsTitle': 'ヒント',
    'buttonAddRule': 'ルール追加',
    'buttonInstallCertificate': '証明書をインストール',
    'missingInfoWarning': '入力内容を確認してください',
    'missingInfoSuccess': '操作成功',
    'missingInfoError': '操作失敗',
    'certificateTip': '正常な使用を保証するために、証明書がインストールされていることを確認してください',
    'remind': '再度通知しない',
    'tip': '広告を正しくブロックするには、以下の手順に従う必要があります',
    'triggerRulesTips':
      'HTTPSフィルタリングを有効にした後、リストされているウェブサイトの広告をDeeper Connectでフィルタリングするためには、コンピューターにDeeper Networkのルート証明書をインストールして信頼する必要があります。',
    'triggerRulesTips2':
      '証明書をインストールせずにHTTPSフィルタリングを単に有効にするだけでは、広告をフィルタリングすることはできないだけでなく、リスト上のウェブサイトへの正常なアクセスにも影響を与える可能性があります。',
    'mobileDownloadCertTips': 'Safariブラウザを使用して証明書をダウンロードしてインストールしてください。',
    'androidTVTip': 'Android TVを使用している場合は、アプリをダウンロードして広告なしで動画を視聴してください',
    'errorMessage': {
      'maxLength': 'ルールの最大数は1024です',
      'ruleExisted': 'ドメインとパターンはすでに存在しています',
    },
    'label': {
      'domain': 'ドメイン',
      'Pattern': 'パターン',
      'ruleType': 'ルールタイプ',
      'web': 'ブラウザ',
      'app': 'アプリ',
      'all': '全て',
      'Rewrite_Pattern': '書き換えパターン',
      'Rewrite_Info': '書き換え情報',
      'Rewrite_Action': 'アクション',
      'enable': '有効',
      'operation': '操作',
      'order': 'オーダー番号',
    },
    'operation': {
      'delete': '削除',
      'edit': '編集',
    },
    'modal': {
      'title': 'ルール追加',
      'confirmText': '提出',
      'cancelText': 'キャンセル',
    },
    'placeholder': {
      'domain': '正しいドメインを入力してください',
      'Pattern': '正しいパターンを入力してください',
      'ruleType': 'ルールタイプを選択してください',
      'Rewrite_Pattern': '正しい書き換えパターンを入力してください',
      'Rewrite_Info': '書き換え情報を入力してください',
      'Rewrite_Action': 'アクションを選択してください',
    },
    'builtinRules': {
      'title': '広告フィルタ',
      'filterListtitle': 'HTTPSフィルターリスト',
      'platform': 'サポートプラットフォーム',
      'segOptions': ['証明書インストールチュートリアル', 'サポートリスト'],
    },
    'customerRules': {
      'title': 'カスタマイズHTTPSルール',
    },
    'guide': {
      'buttonDownloadCertificate': '証明書をダウンロードするをクリック',
      'buttonDownloadApp': 'SkyTubeをダウンロードするをクリック',
      'tab': {
        'mobileInstall': 'iPhone/iPad',
        'macInstall': 'MacOS',
        'windowsInstall': 'Windows',
        'androidInstall': 'Android',
        'linuxInstall': 'Linux',
      },
      'mobile': {
        'videoTab': 'ビデオ',
        'textTab': 'テキスト',
        'step1': {
          'label': 'ステップ1',
        },
        'step2': {
          'label': 'ステップ2',
          'tip': '「許可」をクリック',
        },
        'step3': {
          'label': 'ステップ3',
          'tip': 'iPhoneの設定を開き、「プロファイルがダウンロードされました」をクリック',
        },
        'step4': {
          'label': 'ステップ4',
          'tip': '「インストール」をクリックし、電話のパスワードを入力',
        },
        'step5': {
          'label': 'ステップ5',
          'tip': '「インストール」をクリック',
        },
        'step6': {
          'label': 'ステップ6',
          'tip': 'インストール完了',
        },
        'step7': {
          'label': 'ステップ7',
          'tip':
            '携帯電話の「設定」>「一般」>「情報」>「証明書の信頼設定」を開き、インストールされた証明書を有効にする。',
        },
      },
      'mac': {
        'step1': {
          'label': 'ステップ1',
        },
        'step2': {
          'label': 'ステップ2',
          'tip':
            'ダウンロードした証明書をクリックし、システムが「証明書を追加」のポップアップウィンドウを表示し、「キーチェーン」で「ログイン」を選択してから「追加」を押す。',
        },
        'step3': {
          'label': 'ステップ3',
          'tip': '「キーチェーンアクセス」ウィンドウで、サイドバーの「デフォルトキーチェーン」で「ログイン」を選択。',
        },
        'step4': {
          'label': 'ステップ4',
          'tip': '「deeper.network」の証明書を見つけて選択し、右クリックして「情報を見る」を選択。',
        },
        'step5': {
          'label': 'ステップ5',
          'tip': '情報で「信頼」をクリックし、「システムのデフォルトを使用する」を「常に信頼する」に変更。',
        },
        'step6': {
          'label': 'ステップ6',
          'tip': '左上の角をクリックして情報を閉じ、パスワードを入力し、「設定を更新」をクリック。',
        },
        'step7': {
          'label': 'ステップ7',
          'tip': '証明書のインストールが完了。',
        },
      },
      'windows': {
        'step1': {
          'label': 'ステップ1',
        },
        'step2': {
          'label': 'ステップ2',
          'tip': 'ダウンロードした証明書をクリックし、「証明書をインストール」をクリック',
        },
        'step3': {
          'label': 'ステップ3',
          'tip': '「ストアの場所」を「ローカルマシン」に選択し、「次へ」をクリック',
        },
        'step4': {
          'label': 'ステップ4',
          'tip': '「以下のストアにすべての証明書を配置する」を選択し、「参照」をクリック',
        },
        'step5': {
          'label': 'ステップ5',
          'tip': '「信頼されたルート証明機関」を選択し、「OK」をクリック',
        },
        'step6': {
          'label': 'ステップ6',
          'tip': '「完了」をクリックし、証明書のインストールが完了',
        },
      },
      'linux': {
        rootTab: 'システムルート証明書のインポートチュートリアル',
        root: {
          step1: {
            label: '步骤1',
          },
          step2: {
            label: '步骤2',
            tip:
              '<p>/usr/local/share/ca-certificates/に証明書ファイルをコピーします<p>\
              <p>このディレクトリは、追加のローカルにインストールされた CA 証明書を保管するために専用のものです。証明書ファイルは、.crt 拡張子を持つ PEM 形式のファイルである必要があります。</p>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /usr/local/share/ca-certificates/</code></div>',
          },
          step3: {
            label: '步骤3',
            tip:
              '<p>証明書ストアを更新する</p>\
              <p>update-ca-certificates コマンドを使用して証明書ストアを更新します。これにより、システム内の証明書の信頼チェーンが自動的に更新されます。</p>\
              <div class="code"><code>sudo update-ca-certificates</code></div>\
              <p>このコマンドは /usr/local/share/ca-certificates/ ディレクトリ内の証明書ファイルを読み取り、それらをシステムの信頼リストに追加します。</p>\
              <em><em class="warning">注意：</em>Linuxディストリビューションによって、証明書ストアの場所と更新コマンドが異なる場合があります。</em><br>\
              <em>例えば、Red Hatベースのシステム\
              （例えばFedora、CentOS）では、証明書を /etc/pki/ca-trust/source/anchors/ にコピーし、</em><br>\
              <em>update-ca-trust コマンドを実行して証明書ストアを更新する必要があるかもしれません。</em><br>\
              <div class="code"><code>sudo cp DeeperNetwork.crt /etc/pki/ca-trust/source/anchors/</code><br>\
              <code>sudo update-ca-trust</code></div>\
              <p>これらのステップを実行した後、インストールしたルート証明書はシステムによって信頼されるはずです。</p>',
          },
        },
        'chrome': {
          'step1': {
            'label': 'ステップ1',
          },
          'step2': {
            'label': 'ステップ2',
            'tip': 'アドレスバーにchrome://settings/certificatesを入力し、「<b>認証局</b>」タブを選択。',
          },
          'step3': {
            'label': 'ステップ3',
            'tip': '「インポート」をクリックし、ダウンロードしたばかりの証明書を探し、「開く」をクリック',
          },
          'step4': {
            'label': 'ステップ4',
            'tip':
              '「<b>証明書局</b>」ウィンドウで全てのオプションにチェックを入れ、「OK」をクリックして証明書のインストールを完了。',
          },
        },
        'firefox': {
          'step1': {
            'label': 'ステップ1',
          },
          'step2': {
            'label': 'ステップ2',
            'tip': "アドレスバーに'about:preferences#privacy'を入力し、「証明書」を見つけ、「証明書を表示」をクリック",
          },
          'step3': {
            'label': 'ステップ3',
            'tip': '「認証局」を選択し、「インポート」をクリック',
          },
          'step4': {
            'label': 'ステップ4',
            'tip': 'ダウンロードしたばかりの証明書を選択し、「開く」をクリック',
          },
          'step5': {
            'label': 'ステップ5',
            'tip': '全てのオプションにチェックを入れ、「OK」をクリックし、証明書のインストールが完了',
          },
        },
      },
      'android': {
        'appTab': 'アプリ',
        'browserTab': 'ブラウザ',
        'app': {
          'step1': {
            'label': 'ステップ1',
          },
          'step2': {
            'label': 'ステップ2',
            'tip': 'ダウンロードしたアプリをインストールし、広告なしで動画を楽しむ',
          },
        },
        'browser': {
          'step1': {
            'label': 'ステップ1',
          },
          'step2': {
            'label': 'ステップ2',
            'tip': '携帯電話の「設定」を開き、「セキュリティ」を探す',
          },
          'step3': {
            'label': 'ステップ3',
            'tip': '「セキュリティ」に入り、「暗号化と資格情報」を探し、クリックして入る',
          },
          'step4': {
            'label': 'ステップ4',
            'tip': '「証明書をインストール」をクリック',
          },
          'step5': {
            'label': 'ステップ5',
            'tip': '「CA証明書」をクリック',
          },
          'step6': {
            'label': 'ステップ6',
            'tip': '「とにかくインストール」をクリックし、携帯電話のパスワードを入力',
          },
          'step7': {
            'label': 'ステップ7',
            'tip': 'ダウンロードした証明書を選択',
          },
          'step8': {
            'label': 'ステップ8',
            'tip': 'CA証明書がインストールされたことを示すプロンプトが表示され、証明書のインストールが成功',
          },
        },
      },
    },
  },
  // SSLBypassCheckbox
  'SSLBypassCheckbox': {
    'sslBypass': 'SSLバイパスを有効にする',
    'sslBypassTips': '証明書がないデバイスがネットワークに接続することを許可します。',
    'sslBypassWaring': 'SSLバイパスが有効になっている場合、広告フィルタリングが見逃される可能性があります。',
    'success': '操作成功',
    'failed': '操作失敗',
  },
  // tunnel
  'tunnel': {
    'configuredTunnel': '設定済みトンネル',
    countryTooltip: '国名のあいまい検索、または国コード (大文字) を入力して Enter キーを押して、目的の国を検索します。',
    'region': '地域',
    'country': '国',
    'activeTunnelNum': 'アクティブIPの数',
    'activeIp': 'アクティブIP',
    'switchNode': 'アクティブIPを切り替える',
    'switchIpTitle': 'IPを切り替える',
    'initTunnelTitle': 'トンネルを再初期化する',
    'initTunnelTitleTips':
      'トンネルには3つのIPアドレスがあります。トンネルをリフレッシュすると、3つの新しいIPアドレスに切り替わります。',
    'selectRegion': '地域を選択する',
    'selectCountry': '国を選択する',
    'missingInfoWarning': '情報不足',
    'tunnelAddSuccess': 'トンネルが正常に追加されました。3秒以内にページが更新されます。',
    'tunnelExist': 'トンネルは既に存在します',
    'reachedMax': 'トンネルの最大数に達しました',
    'tunnelConflict': '追加されるトンネルはトンネル{0} - {1}と衝突しています',
    'tunnelAddError': 'トンネルの追加に失敗しました',
    'tunnelDelSuccess': 'トンネルが正常に削除されました。3秒以内にページが更新されます。',
    'tunnelNotExist': 'トンネルが存在しません',
    'usedByFullMode': '削除されるトンネルはDPNフルルートで使用されています',
    usedByAccessControl: '削除されるトンネルはアクセス コントロールによって使用されています',
    'usedByBackup': 'バックアップトンネルがこのトンネルを使用しているため、削除できません。',
    'tunnelInUse': '削除されるトンネルはルーティングテーブルで使用されています',
    'tunnelDelError': 'トンネルの削除に失敗しました',
    'switchNodeSuccess': 'ノードが正常に切り替えられました。3秒以内にページが更新されます。',
    'noActiveIp': '切り替えるためのアクティブIPがありません',
    'ipNotChanged': 'IPは変更されていません',
    'switchNodeError': 'IPの切り替えに失敗しました',
    'refreshLabel': 'リフレッシュ',
    'getNewIPLabel': '新しいIPを取得する',
    'refreshError': 'トンネルのリフレッシュに失敗しました',
    'refreshSuccess': 'トンネルのリフレッシュリクエストが送信されました。3秒以内にページが更新されます。',
    'tips': {
      'refreshTunnel': '新しいアクティブトンネルをリフレッシュします。',
      'configuredTunnel':
        '必要な地域のトンネルを手動で追加した後、AtomOSはグローバルにあなたの要件を満たすノードを検索します。',
    },
    'nodeLbMode': {
      'label': 'ノード選択モード',
      'none': {
        'label': '通常',
        'desc':
          'このトンネルモードでは、すべてのネットワーク接続が1つのアクティブノードを通過します。ユーザーはいつでもこのノードを切り替えることができます。',
      },
      'random': {
        'label': 'ランダム',
        'desc': 'システムは各接続に対してランダムにノードを自動選択します。',
      },
      'domain': {
        'label': 'ドメイン認識',
        'desc':
          'システムはトンネル内の複数のノードを使用してネットワークにアクセスします。しかし、特定のドメイン名へのアクセスは同じノードを通じて一貫してルーティングされ、ウェブサイトへのアクセスエラーのリスクを低下させます。',
      },
      'rr': {
        'label': 'ラウンドロビン',
        'desc': '各接続において、システムは一連のノードから順番に選択し、より良いネットワーク加速を実現します。',
      },
      'failure': 'ノード負荷分散モードの保存に失敗しました',
      'success': 'ノード負荷分散モードが正常に保存されました',
    },
  },
  // userConsent
  'userConsent': {
    'notice': '注意：利用規約とプライバシーポリシーに同意するまで、共有は無効です。',
    'consentStart': 'Deeper Networkの',
    'termsOfUseNotRead': '利用規約を読んでいません',
    'termsOfUseLink': 'https://shop.deeper.network/terms-of-service?lang=ja',
    'termsOfUseTitle': '利用規約',
    'consentAnd': 'および',
    'privacyPolicyNotRead': 'プライバシーポリシーを読んでいません',
    'privacyPolicyLink': 'https://shop.deeper.network/privacy-policy?lang=ja',
    'privacyPolicyTitle': 'プライバシーポリシー',
    'confirmBtn': '確認',
    'consentError': '同意の確認に失敗しました',
  },
  // customConfigLang
  'customConfigLang': {
    'missingInfoWarning': 'ドメインが不足しています',
    'invalidDomainError': 'ドメイン形式が無効、またはドメインが長すぎます',
    'whitelist': 'ホワイトリスト',
    'blacklist': 'ブラックリスト',
    'domain': 'ドメイン',
    'whitelistAddSuccess': 'ホワイトリストに正常に追加されました',
    'whitelistEntryExist': 'エントリは既にホワイトリストに存在します',
    'reachedWhiteMax': 'ホワイトリストの最大数に達しました',
    'whitelistAddError': 'ホワイトリストへの追加に失敗しました',
    'blacklistAddSuccess': 'ブラックリストに正常に追加されました',
    'blacklistEntryExist': 'エントリは既にブラックリストに存在します',
    'reachedBlackMax': 'ブラックリストの最大数に達しました',
    'blacklistAddError': 'ブラックリストへの追加に失敗しました',
    'whitelistDelSuccess': 'ホワイトリストのエントリが正常に削除されました',
    'whitelistDelError': 'ホワイトリストのエントリの削除に失敗しました',
    'blacklistDelSuccess': 'ブラックリストのエントリが正常に削除されました',
    'blacklistDelError': 'ブラックリストのエントリの削除に失敗しました',
    'import': 'インポート',
    'fileButtonText': 'アップロードするをクリック',
    'fileSizeOverLimit': 'アップロードされたファイルが2MBの制限を超えています',
    'whitelistImportSuccess': 'ホワイトリストが正常にインポートされました、{0} エントリがインポートされました',
    'whitelistImportError': 'ホワイトリストのインポートに失敗しました',
    'blacklistImportSuccess': 'ブラックリストが正常にインポートされました、{0} エントリがインポートされました',
    'blacklistImportError': 'ブラックリストのインポートに失敗しました',
    'noValidEntry': 'アップロードされたファイルに有効なドメインエントリがありません',
    'zeroImported': 'ドメインエントリのインポートに失敗しました',
  },
  // defaultConfigLang
  'defaultConfigLang': {
    'browserCacheWarning':
      'DNS広告フィルタリングが有効になっている場合に広告が適切にフィルタリングされない場合は、ブラウザのキャッシュをクリアしてフィルタリングされたデバイスを再起動する必要があります。',
    'queriesFor24Hours': '過去24時間のクエリ',
    'totalQuery': '総クエリ数',
    'blockedQuery': 'ブロックされたクエリ',
    'blockedPercentage': '诘まった割合',
    'ruleTotal': 'ルール',
    'adQuery': 'ブロックされた広告',
    'trackerQuery': 'ブロックされたトラッカー',
    'maliciousQuery': 'ブロックされた悪意のあるもの',
    'pornQuery': 'ブロックされたポルノ',
    'legendTotal': '総計',
    'legendBlocked': 'ブロックされた',
    'categoryStateEnabled': 'オン',
    'categoryStateDisabled': 'オフ',
    'categoryState2Hours': '2時間無効',
    'categoryState4Hours': '4時間無効',
    'categoryState8Hours': '8時間無効',
    'setCategoryStatesErr': 'フィルタの有効化/無効化エラー',
    'enableAll': 'すべてのフィルタを有効にする',
    'disableAll': 'すべてのフィルタを無効にする',
  },
  // wifiSettings
  'wifiSettings': {
    'workMode': '作業モード',
    'currentWorkMode': '検出された作業モード',
    'wire-ap': '有線WAN',
    'wlan-ap': '無線WAN',
    'notConnect': 'ネットワークは接続されていません',
    'upstreamTitle': 'アップストリーム設定',
    'downstreamTitle': 'ワイヤレスLAN',
    'backHome': 'ダッシュボードに戻る',
    'apConfigWarning':
      '新しいWi-Fi SSIDとWi-Fiパスワードを保存した後、Deeperデバイスを通じてインターネットにアクセスしたい場合は、Deeperデバイスのホットスポットを再スキャンし、新しいパスワードを適用する必要があります',
    'attention': '注意',
    'buttons': {
      'scanWifi': 'スキャン',
      'connectStatus': 'ステータス',
      add: '追加',
      'save': '保存',
      'cancel': 'キャンセル',
      'downloadLog': 'ログをダウンロード',
    },
    'connect': '接続',
    'forget': '忘れる',
    'waiting': {
      'content': '接続中です。しばらく時間がかかる場合がありますので、お待ちください...',
      'cancelBtn': '接続をキャンセル',
    },
    'modals': {
      'hiddenWifi': {
        'title': '隠れたWi-Fiを追加',
        'okBtn': '追加',
      },
      'scanWifi': {
        'title': '警告',
        'content': 'ネットワークがしばらく切断されます',
        'ok': 'OK',
        'cancel': 'キャンセル',
      },
    },
    'label': {
      'ssid': 'Wi-Fi SSID',
      'password': 'Wi-Fiパスワード',
      'hidden': 'SSIDを隠す',
      'onlyUse': 'のみ使用',
      'freqBand': '周波数帯',
    },
    'placeholder': {
      'ssid': 'SSIDを入力してください',
      'password': 'パスワードを入力してください',
    },
    'message': {
      'saveSuccess': '正常に保存されました',
      'saveError': '保存に失敗しました。再試行してください',
      'connectSuccess': '正常に接続されました',
      'forgetSuccess': '正常に忘れられました',
      'inputError': '入力内容を確認してください',
      'invalidWifiDev': '有効なWi-Fiデバイスがないため、Wi-Fi設定は実行できません',
      'illegalWifiDev': 'Wi-Fi設定はAPルーターモードとワイヤレスリレーモードでのみ利用可能です',
      'invalidSsid': '文字、数字、_、-のみをサポートします',
      'invalidPassword': 'パスワードは最低8文字で、空白を含むことはできません',
      'notConnect': 'ネットワークは接続されていません',
      'retry': '再試行してください',
      'connectionError': '接続に失敗しました',
      'forgetError': '忘れることに失敗しました',
      'wifiNotSupported': 'Wi-Fiはサポートされていません',
      'networkNotFound': 'ネットワークが見つかりません',
      'pskNotGenerated': 'キーが生成されていません',
      'wrongPassword': 'パスワードが間違っています',
      'timeout': '接続がタイムアウトしました',
      'logDownloadError': 'ログのダウンロードに失敗しました',
      'hasBeenConnected': '接続されています。',
      'dhcpSuccess': '成功しました',
      'dhcpFailure': '失敗しました',
    },
    'connectionRequestedTitle': '接続リクエスト',
    'connectionRequestedContent':
      '「ステータス」タブで接続状況を確認してください。Deeper Connectから切断された場合は、DeeperWiFiに再接続してください。',
    'gotIpFromDhcpLabel': 'DHCPからIPを取得',
  },
  // parentalControlData
  'parentalControlData': {
    'browserCacheWarning':
      'ペアレンタルコントロールが有効になっている場合にアダルトサイトやソーシャルメディアが適切にブロックできない場合は、ブラウザのキャッシュをクリアしてフィルタリングされたデバイスを再起動する必要があります。',
    'customerRule':
      'ここでは、子供たちのためにアダルトサイトやソーシャルサイトの大部分をブロックすることができます。他のブロックしたいウェブサイトがある場合は、カスタムドメインブロック機能を使用してさらにサイトを追加できます。',
    'customerRuleClickTips': 'カスタムドメインブロックページにアクセスするには{0}してください。',
    'toCustomerRuleButtonText': 'ここをクリック',
    'parentalControl': 'ペアレンタルコントロール',
    'pornQuery': 'ブロックされたポルノ',
    'socialQuery': 'ブロックされたソーシャルメディア',
    'legendBlocked': 'ブロックされた',

    // social
    'socialStateEnabled': 'ソーシャルメディアをブロック',
    'socialStateDisabled': 'ソーシャルメディアのブロックを解除',
    'socialState2Hours': '2時間の間、ソーシャルメディアのブロックを解除',
    'socialState4Hours': '4時間の間、ソーシャルメディアのブロックを解除',
    'socialState8Hours': '8時間の間、ソーシャルメディアのブロックを解除',
    // porn
    'pornStateEnabled': 'ポルノをブロック',
    'pornStateDisabled': 'ポルノのブロックを解除',
    'pornState2Hours': '2時間の間、ポルノのブロックを解除',
    'pornState4Hours': '4時間の間、ポルノのブロックを解除',
    'pornState8Hours': '8時間の間、ポルノのブロックを解除',
    'setCategoryStatesErr': 'フィルタの有効化/無効化エラー',
  },
  reboot: {
    success: {
      saveConfig: '正常に保存',
    },
    failure: {
      getConfig: '自動再起動の設定の取得に失敗しました',
      saveConfig: '自動再起動の設定の保存に失敗しました',
    },
    buttons: {
      reboot: 'クリックして再起動',
      save: '保存',
    },
    week: {
      0: '毎日',
      1: '月曜日',
      2: '火曜日',
      3: '水曜日',
      4: '木曜日',
      5: '金曜日',
      6: '土曜日',
      7: '日曜日',
    },
    title: {
      manual: '今すぐ再起動',
      auto: 'スケジュールされた再起動',
    },
    label: {
      period: '期間',
      time: '時間',
    },
  },
};
