import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { Router, Route, IndexRoute, browserHistory, Redirect } from 'react-router';
import axios from 'axios';
import to from 'await-to-js';
import { findLanguage } from '@/utils/languageType';
import { validateToken } from '@/utils/authUtils';
import './global.less';
import store from './store/store.js';
import registerServiceWorker from './registerServiceWorker';
import Loading from '@/components/Loading/Loading';

const SESSION_ERROR_QUERY_PARAM = 'sessionError';

const App = Loadable({
  loader: () => import('./app.js'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('@/containers/dashboard/dashboard.js'),
  loading: Loading,
});

const AccessControl = Loadable({
  loader: () => import('@/containers/accessControl'),
  loading: Loading,
});

const System = Loadable({
  loader: () => import('@/containers/system/index.js'),
  loading: Loading,
});

const Flow = Loadable({
  loader: () => import('@/containers/flow/index.js'),
  loading: Loading,
});

const Notification = Loadable({
  loader: () => import('@/containers/notification/index'),
  loading: Loading,
});

const DpnMode = Loadable({
  loader: () => import('@/containers/dpnMode/index'),
  loading: Loading,
});

const ConfiguredTunnel = Loadable({
  loader: () => import('@/containers/tunnel/index'),
  loading: Loading,
});

const DomainConfig = Loadable({
  loader: () => import('@/containers/routingTable/DomainConfig/index'),
  loading: Loading,
});

const IPConfig = Loadable({
  loader: () => import('@/containers/routingTable/IPConfig/index'),
  loading: Loading,
});

const QueryRouting = Loadable({
  loader: () => import('@/containers/routingTable/queryRouting/index'),
  loading: Loading,
});

const AppRelocator = Loadable({
  loader: () => import('@/containers/appRelocator'),
  loading: Loading,
});

const WebFilterConfig = Loadable({
  loader: () => import('@/containers/webFilter/configure'),
  loading: Loading,
});

const BuiltinRules = Loadable({
  loader: () => import('@/containers/tproxy/BuiltinRules/BuiltinRules'),
  loading: Loading,
});

const CustomRules = Loadable({
  loader: () => import('@/containers/tproxy/CustomRules/CustomRules'),
  loading: Loading,
});

const WifiSettings = Loadable({
  loader: () => import('@/containers/wifiSettings/'),
  loading: Loading,
});

const WalletManagement = Loadable({
  loader: () => import('@/containers/betanet/walletManagement'),
  loading: Loading,
});

const WalletBalance = Loadable({
  loader: () => import('@/containers/betanet/walletBalance'),
  loading: Loading,
});

const MiningPoC = Loadable({
  loader: () => import('@/containers/miningPoC/miningPoC'),
  loading: Loading,
});

const Transaction = Loadable({
  loader: () => import('@/containers/transaction/transaction'),
  loading: Loading,
});

const SharingConfiguration = Loadable({
  loader: () => import('@/containers/sharing/configure'),
  loading: Loading,
});

const SharingBlacklist = Loadable({
  loader: () => import('@/containers/sharing/blacklist'),
  loading: Loading,
});

const SharingRecord = Loadable({
  loader: () => import('@/containers/sharing/SharingRecord'),
  loading: Loading,
});

const DailyTraffic = Loadable({
  loader: () => import('@/containers/dailyTraffic/'),
  loading: Loading,
});

const RustdeskConfig = Loadable({
  loader: () => import('@/containers/rustdesk/config'),
  loading: Loading,
});

const RustdeskLog = Loadable({
  loader: () => import('@/containers/rustdesk/log'),
  loading: Loading,
});

const AdBlockDnsFilter = Loadable({
  loader: () => import('@/containers/adBlock/dnsFilter'),
  loading: Loading,
});

const ParentalControl = Loadable({
  loader: () => import('@/containers/parentalControl/index.js'),
  loading: Loading,
});

const ChangePassword = Loadable({
  loader: () => import('@/containers/changePassword/index.js'),
  loading: Loading,
});

const UserConsent = Loadable({
  loader: () => import('@/containers/userConsent/userConsent'),
  loading: Loading,
});

const Login = Loadable({
  loader: () => import('@/containers/login/login'),
  loading: Loading,
});

const TechSupport = Loadable({
  loader: () => import('@/containers/techSupport/index'),
  loading: Loading,
});

const Reboot = Loadable({
  loader: () => import('./containers/reboot/index.jsx'),
  loading: Loading,
});

import windowResize from '@/utils/windowResize';

window.$isMobile = function () {
  return window.innerWidth < 1200;
};

windowResize();

import { message } from 'antd';
const authUtils = require('./utils/authUtils');
const util = require('./utils/utils');

async function onNetworkEnter(_nextState, _replace, next) {
  attachToken();
  await to(axios.post('/api/admin/enterNetwork'));
  next();
}

async function onConsentEnter(_nextState, _replace, next) {
  attachToken();
  await to(axios.post('/api/admin/enterConsent'));
  next();
}

async function onLoginEnter(nextState, _replace, next) {
  if (nextState.location.query[SESSION_ERROR_QUERY_PARAM]) {
    const state = store.getState();
    const { type } = state.Login.language;
    const errorKey = nextState.location.query[SESSION_ERROR_QUERY_PARAM];
    if (findLanguage(type).login[errorKey]) {
      message.error(findLanguage(type).login[errorKey]);
    }
  }

  next();
}

async function onAppEnter(_nextState, _replace, next) {
  attachToken();
  await validateToken();
  await fetchDpnMode();
  next();
}

function attachToken() {
  const token = window.localStorage.getItem(authUtils.JWT_TOKEN_KEY);
  if (token) {
    authUtils.setAuthToken(token);
  }
}

const languageVal = localStorage[util.APP_LANG_KEY] || getUserLang();
util.setAppLang(languageVal);

function getUserLang() {
  if (/^zh\b/.test(navigator.language)) {
    return 'zh_cn';
  } else {
    return 'en_us';
  }
}

axios.interceptors.response.use(handleSuccessRes, handleErrorRes);

async function fetchDpnMode() {
  let dpnModeVal = 'disabled';
  const [err, res] = await to(
    axios.get('/api/smartRoute/getDpnMode', {
      timeout: 3000,
    })
  );
  if (!err) {
    dpnModeVal = res.data.dpnMode;
  }
  util.setDPNMode(dpnModeVal);
}

/* status code 2xx */
function handleSuccessRes(response) {
  return response;
}

/* status codes outside 2xx */
function handleErrorRes(error) {
  if (error.response?.data?.notAuthenticated) {
    let path = `/login?from=${window.location.pathname}`;
    if (error.response.data.reason) {
      path = `${path}&${SESSION_ERROR_QUERY_PARAM}=${error.response.data.reason}`;
    }

    window.location.replace(path);
  } else if (error.response?.data?.notConnected) {
    window.location.replace(`/network?from=${window.location.pathname}`);
  } else if (error.response?.data?.notConsented) {
    window.location.replace(`/userConsent?from=${window.location.pathname}`);
  }

  return Promise.reject(error);
}
const routes = (
  <Provider store={store}>
    <Router history={browserHistory}>
      <Route path="/" onEnter={onAppEnter} component={App}>
        <IndexRoute component={Dashboard} />
        <Route path="/accessControl" component={AccessControl} />
        <Route path="/system" component={System} />
        <Route path="/flow" component={Flow} />
        <Route path="/notification" component={Notification} />
        <Route path="/smartRoute" component={DpnMode} />
        <Route path="/configuredTunnel" component={ConfiguredTunnel} />
        <Route path="/domainConfig" component={DomainConfig} />
        <Route path="/ipConfig" component={IPConfig} />
        <Route path="/queryRouting" component={QueryRouting} />
        <Route path="/appRelocator" component={AppRelocator} />
        <Route path="/webFilterConfig" component={WebFilterConfig} />
        <Route path="/adsBlockDnsFilter" component={AdBlockDnsFilter} />
        <Route path="/adsBlockSslFilter" component={BuiltinRules} />
        <Route path="/customRules" component={CustomRules} />
        <Route path="/parentalControl" component={ParentalControl} />
        <Route path="/wifiSettings" component={WifiSettings} />
        <Route path="/walletManagement" component={WalletManagement} />
        <Route path="/walletBalance" component={WalletBalance} />
        <Route path="/miningpoc" component={MiningPoC} />
        <Route path="/transaction" component={Transaction} />
        <Route path="/sharingConfig" component={SharingConfiguration} />
        <Route path="/sharingBlacklist" component={SharingBlacklist} />
        <Route path="/sharingRecord" component={SharingRecord} />
        <Route path="/sharingTraffic" component={DailyTraffic} />
        <Route path="/rustdeskConfig" component={RustdeskConfig} />
        <Route path="/rustdeskLog" component={RustdeskLog} />
        <Route path="/changePassword" component={ChangePassword} />
        <Route path="/techSupport" component={TechSupport} />
        <Route path="/reboot" component={Reboot} />
      </Route>
      <Route path="/network" onEnter={onNetworkEnter} component={WifiSettings} />
      <Route path="/userConsent" onEnter={onConsentEnter} component={UserConsent} />
      <Route path="/login" onEnter={onLoginEnter} component={Login} />
      <Redirect from="*" to="/" />
    </Router>
  </Provider>
);

ReactDOM.render(routes, document.getElementById('root'));
registerServiceWorker();
